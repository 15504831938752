/* eslint-disable @typescript-eslint/indent */
import { CircularProgress } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { size } from 'lodash';
import React, { useEffect } from 'react';
import { PayloadAC } from 'typesafe-actions';
import Satellite from '../../../../../assets/satelite.svg';
import InvitesTable, { HeaderProps } from '../../../../../components/InvitesTable/';
import SacretEmpty from '../../../../../components/SacretEmpty';
import LocalStorage from '../../../../../data/LocalStorage';
import { GetFriendsInvitationsPaginatedRequest } from '../../../../../data/repositories/invites/typedef';
import { USER_ID } from '../../../../../utils/constants/localStorageKeys';
import { MyInvitesTableState } from './state/initialState';

const styles = () =>
  createStyles({
    circularProgress: {
      position: 'absolute',
      left: '50%',
      top: '50%',
    },
  });

const useStyles = makeStyles(styles);

type Props = MyInvitesTableState & {
  asyncGetFriendsInvitationsPaginated: PayloadAC<
    'invites/get_friends_invitations',
    GetFriendsInvitationsPaginatedRequest
  >;
};

const headers: HeaderProps[] = [
  { name: 'Email', align: 'left' },
  { name: 'Invitation date & time', align: 'left' },
  { name: 'Registration date & time', align: 'left' },
  { name: 'Status', align: 'left' },
];

const MyInvitesTable: React.FC<Props> = (props: Props): JSX.Element => {
  const classes = useStyles();
  const accountId = LocalStorage.get(USER_ID);

  const { invites, count, asyncGetFriendsInvitationsPaginated, inProgress, page, appliedFilters } =
    props;

  useEffect(() => {
    accountId && asyncGetFriendsInvitationsPaginated({ accountId, page, perPage: 10 });
  }, []);

  const isEmpty = size(invites) == 0;

  return (
    <div>
      {inProgress && !invites ? (
        <CircularProgress color="secondary" className={classes.circularProgress} size={60} />
      ) : (
        <div>
          {!isEmpty ? (
            <InvitesTable
              invites={invites}
              headers={headers}
              filter={appliedFilters}
              page={page}
              inProgress={inProgress}
              totalInvites={count}
              accountId={accountId}
            />
          ) : (
            <SacretEmpty
              image={Satellite}
              title="Yikes, You don't have any invites"
              text=""
              hasButton={false}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default MyInvitesTable;
