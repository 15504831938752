import { Card, CardContent, InputLabel, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { membershipTitle } from '../../App/Home/HomeHeader/UserEmailAndMembership/UserEmailAndMembership';
import { Colors } from '../../ui/style/colors';

const styles = (theme: Theme) =>
  createStyles({
    card: {
      boxShadow: '0 10px 30px 0 rgba(132,141,182,0.25)',
      verticalAlign: 'sub',
      padding: 20,
    },
    inputFields: {
      marginTop: 5,
      height: 44,
      boxSizing: 'border-box',
      border: `1px solid ${theme.palette.secondary.light}`,
      borderRadius: 4,
      backgroundColor: `${Colors.white}`,
      paddingLeft: 12,
    },
    labels: {
      fontWeight: 'bold',
      color: theme.palette.primary.light,
      marginTop: 20,
      marginBottom: 8,
    },
    values: {
      color: theme.palette.primary.main,
      marginTop: 10,
      marginBottom: 8,
      overflowWrap: 'break-word',
    },
    firstLetterUppercase: {
      '&:first-letter': {
        textTransform: 'uppercase',
      },
    },
    changePasswordLabel: {
      color: theme.palette.secondary.light,
      fontSize: 14,
      fontWeight: 800,
      letterSpacing: 1,
      textTransform: 'uppercase',
    },
    button: {
      marginTop: 40,
      textTransform: 'none',
      fontSize: 16,
      fontWeight: 600,
      letterSpacing: 1,
      textAlign: 'center',
      backgroundColor: `${Colors.blueCharcoal}`,
    },
    inputError: {
      color: `${Colors.red}`,
      marginTop: 10,
    },
  });

const useStyles = makeStyles(styles);
type ProfileInfoShort = {
  firstName: string;
  lastName: string;
  middleName?: string;
  gender?: string;
  email?: string;
  membershipLevel?: string;
  slcAddress?: string;
};

const ProfileInfoShort: React.FC<ProfileInfoShort> = ({
  firstName,
  lastName,
  email,
  gender,
  membershipLevel,
  middleName,
  slcAddress,
}) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography className={classes.changePasswordLabel} component="p">
          Personal information
        </Typography>

        <InputLabel className={classes.labels}>First name</InputLabel>
        <InputLabel className={`${classes.values} ${classes.firstLetterUppercase}`}>
          {firstName}
        </InputLabel>

        {middleName && (
          <>
            <InputLabel className={classes.labels}>Middle name</InputLabel>
            <InputLabel className={`${classes.values} ${classes.firstLetterUppercase}`}>
              {middleName}
            </InputLabel>
          </>
        )}

        <InputLabel className={classes.labels}>Last name</InputLabel>
        <InputLabel className={`${classes.values} ${classes.firstLetterUppercase}`}>
          {lastName}
        </InputLabel>

        {gender && (
          <>
            <InputLabel className={classes.labels}>Gender</InputLabel>
            <InputLabel className={`${classes.values} ${classes.firstLetterUppercase}`}>
              {gender}
            </InputLabel>
          </>
        )}

        {email && (
          <>
            <InputLabel className={classes.labels}>Email</InputLabel>
            <InputLabel className={classes.values}>{email}</InputLabel>
          </>
        )}

        {membershipLevel && (
          <>
            <InputLabel className={classes.labels}>Membership level</InputLabel>
            <InputLabel className={classes.values}>{membershipTitle(membershipLevel)}</InputLabel>
          </>
        )}

        {slcAddress && (
          <>
            <InputLabel className={classes.labels}>SLC Address</InputLabel>
            <InputLabel className={classes.values}>{slcAddress}</InputLabel>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default ProfileInfoShort;
