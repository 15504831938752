export const WIRE_EUR_DECIMALS = 2;
export const SELL_SLC_DECIMALS = 2;

export const WIRE_EUR_MIN_FRACTION = 2;
export const WIRE_EUR_MAX_FRACTION = 2;

export const WIRE_EUR_TRANSACTIONS_MIN_FRACTION = 4;
export const WIRE_EUR_TRANSACTIONS_MAX_FRACTION = 4;

export const CRYPTO_MIN_FRACTION = 6;
export const CRYPTO_MAX_FRACTION = 6;

export const CRYPTO_CHART_INFO_DECIMALS = 2; //market cap, volume & 24h change decimals

export const WALLET_COINS = ['ETH', 'BTC', 'LTC', 'BCH', 'WALLET_EUR', 'USDT'];
export const ALL_COINS = ['SLC', ...WALLET_COINS];
export const COINS_ORDER = ['SLC', 'WALLET_EUR', 'BTC', 'ETH', 'USDT', 'LTC', 'BCH']; //the order in which the coins are displayed
export const COMMING_SOON = [''];

export const ONLY_LEGACY_ADDRESS_SUPPORTED_COINS = ['BCH']; //user can only use legacy address for withdraw
export const ONLY_KYC_REQUIRED_COINS = ['WALLET_EUR', 'WIRE_EUR']; // For users without membership and KYC, notify them to complete KYC to use these currencies

export const ALLOWED_CRYPTO_FOR_MEMBERS = ['SLC', 'ETH', 'BTC', 'LTC', 'BCH', 'WALLET_EUR', 'USDT'];
export const ALLOWED_CRYPTO_FOR_NON_MEMBERS = ['SLC', 'WALLET_EUR'];
export const ALLOWED_CRYPTO_FOR_NO_KYC_USERS = ['SLC'];

export const WIRE_EUR_TRANSACTION_FEE = 5;
export const CRYPTO_TRANSACTION_FEE = 0.02;
