import { createReducer } from 'typesafe-actions';
import {
  CLEAR_ALL_FILTERS,
  GET_FRIENDS_INVITATIONS,
  GET_FRIENDS_INVITATIONS_FAIL,
  GET_FRIENDS_INVITATIONS_SUCCESS,
  GET_TOTAL_FRIENDS_INVITATIONS,
  GET_TOTAL_FRIENDS_INVITATIONS_SUCCESS,
  GET_TOTAL_FRIENDS_INVITATION_FAIL,
  SET_INVITES_TABLE_PAGE,
  TOGGLE_FILTER,
} from './actions';
import { initialState } from './initialState';

export default createReducer(initialState, {
  [GET_FRIENDS_INVITATIONS]: state => ({
    ...state,
    inProgress: true,
  }),
  [GET_FRIENDS_INVITATIONS_SUCCESS]: (state, { payload }) => ({
    ...state,
    invites: payload.mail_invites,
    count: payload.count,
    inProgress: false,
    fail: false,
  }),
  [GET_FRIENDS_INVITATIONS_FAIL]: (state, { payload }) => ({
    ...state,
    inProgress: false,
    fail: true,
    ...(payload && payload.response ? { error: payload.response.data } : {}),
  }),
  [GET_TOTAL_FRIENDS_INVITATIONS]: state => ({
    ...state,
    inProgress: true,
  }),
  [GET_TOTAL_FRIENDS_INVITATIONS_SUCCESS]: (state, { payload }) => ({
    ...state,
    totalInvited: payload.totalInvited,
    totalPending: payload.totalPending,
    totalAccepted: payload.totalAccepted,
    inProgress: false,
    fail: false,
  }),
  [GET_TOTAL_FRIENDS_INVITATION_FAIL]: (state, { payload }) => ({
    ...state,
    inProgress: false,
    fail: true,
    ...(payload && payload.response ? { error: payload.response.data } : {}),
  }),

  [SET_INVITES_TABLE_PAGE]: (state, { payload }) => ({
    ...state,
    page: payload,
  }),
  [TOGGLE_FILTER]: (state, { payload }) => {
    return {
      ...state,
      appliedFilters: { ...state.appliedFilters, [payload.filterType]: payload.value },
    };
  },
  [CLEAR_ALL_FILTERS]: state => ({
    ...state,
    appliedFilters: new Map(),
  }),
});
