import { GetUserInfoResponse } from '../../../../data/repositories/account/typedefs';

export type ExchangeAuthCodeState = {
  inProgress: boolean;
  fail: boolean;
  error: Error | null;
  userInfo: GetUserInfoResponse;
};

export const initialState: ExchangeAuthCodeState = {
  inProgress: false,
  fail: false,
  error: null,
  userInfo: {
    affiliate_package: {
      affiliate_package_identifier: '',
      affiliate_package_level: null,
      cadence: '',
      expires_at: null,
      expires_at_string: '',
      is_expired: false,
      user_identifier: '',
    },
    email: '',
    first_name: '',
    has_2fa: null,
    has_kyc: null,
    has_pair_user: null,
    id: '',
    kyc_status: '',
    last_name: '',
    partnership: null,
    roles: null,
    type: '',
  },
};

export type ExchangeAuthCodeRequest = { code: string; status: string };
