import { Grid, Typography } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';
import { DrawerState } from '../../../App/Home/HomeDrawer/state/initialState';
import { getDrawerMenuStateSelector } from '../../../App/Home/HomeDrawer/state/selectors';
import { AppState } from '../../../App/state/initialState';
import { Colors } from '../../../ui/style/colors';
import CoinIconCell from '../../CoinIconCell';

const styles = (theme: Theme) =>
  createStyles({
    symbol: {
      color: `${Colors.blueCharcoal}`,
      fontSize: 14,
      fontWeight: 600,
    },
    value: {
      color: theme.palette.primary.light,
      fontSize: 16,
      textAlign: 'right',
      visibility: 'visible',
      opacity: 1,
      transition: 'visibility 0s, opacity 0.5s linear',
    },
    valueNone: {
      visibility: 'hidden',
      opacity: 0,
    },
    listitem: {
      marginBottom: 20,
      paddingLeft: 0,
      paddingRight: 0,
    },
    wrapper: {
      width: '100%',
    },
  });

const useStyles = makeStyles(styles);
const mapStateToProps = (state: AppState) => {
  const drawerState = getDrawerMenuStateSelector(state);
  return { drawerState };
};
export type CoinPrice = {
  symbol: string;
  name: string;
  value: string;
};

type Props = {
  coin: CoinPrice;
  drawerState: DrawerState;
};

const CoinPricesCollapseListItem: React.FC<Props> = ({ coin, drawerState }: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <ListItem button className={classes.listitem}>
      <Grid container justifyContent="space-between" wrap="nowrap" className={classes.wrapper}>
        <Grid item xs={4} sm={2} md={5} className={classes.symbol}>
          <CoinIconCell
            hideSymbol={drawerState.isHovering || drawerState.isOpen ? false : true}
            symbol={coin.symbol}
          />
        </Grid>

        <Typography
          className={
            drawerState.isHovering || drawerState.isOpen ? classes.value : classes.valueNone
          }
        >
          € {coin.value}
        </Typography>
      </Grid>
    </ListItem>
  );
};

export default connect(mapStateToProps)(CoinPricesCollapseListItem);
