import {
  membershipStateObject,
  orderPaymentInfoObject,
} from '../../../Settings/Membership/state/initialState';

export type ExchangeCurrencyRequestState = {
  amount?: string;
  get?: string;
  firstName?: string;
  lastName?: string;
  creditNumber?: string;
  expDate?: string;
  cvc?: string;
  step: any;
  isBtnDisabled?: boolean;
  rerender?: number;
  packages?: [];
  transaction_hash?: string;
  ether_address?: string;
  beneficiary?: string;
  beneficiary_address?: string;
  iban?: string;
  bic?: string;
  time?: number;
  eth_price?: number;
  allowed_amount_to_sell_in_eurocents?: 0;
  allowed_amount_to_sell_in_slc?: 0;
  sold_in_eurocents?: 0;
  sold_in_slc?: number;
  total_amount_to_sell_in_eurocents?: number;
  total_amount_to_sell_in_slc?: number;
  other_info?: string;
  sellFee?: string;
};

export type ExchangeCurrencyState = ExchangeCurrencyRequestState & {
  inProgress: boolean;
  two_factor: string;
  packagesInProgress: boolean;
  fail: boolean;
  error: Error | null;
  selectedOption: string;
  selectedTab: number;
  packageName: string;
  order_proof: string;
  membershipState?: membershipStateObject;
  order_payment_info: orderPaymentInfoObject;
  fetchMembershipProgress: boolean;
};

export const initialState: ExchangeCurrencyState = {
  time: 0,
  inProgress: false,
  two_factor: '',
  transaction_hash: '',
  packagesInProgress: false,
  fail: false,
  error: null,
  step: 0,
  amount: '',
  get: '',
  firstName: '',
  ether_address: '',
  beneficiary: '',
  beneficiary_address: '',
  iban: '',
  bic: '',
  lastName: '',
  creditNumber: '',
  expDate: '',
  cvc: '',
  selectedOption: 'ETH',
  selectedTab: 0,
  isBtnDisabled: false,
  rerender: 0,
  packageName: '',
  packages: [],
  order_proof: '',
  allowed_amount_to_sell_in_eurocents: 0,
  allowed_amount_to_sell_in_slc: 0,
  sold_in_eurocents: 0,
  sold_in_slc: 0,
  total_amount_to_sell_in_eurocents: 0,
  total_amount_to_sell_in_slc: 0,
  other_info: '',
  membershipState: {
    created_at: '',
    affiliate_package_identifier: '',
    affiliate_package_level: 0,
    cadence: '',
    expires_at: 0,
    expires_at_string: '',
    is_expired: false,
    user_identifier: '',
    allowed_amount_to_sell_in_eurocents: 0,
    allowed_amount_to_sell_in_slc: 0,
    sold_in_eurocents: 0,
    sold_in_slc: 0,
    weekly_sell_limit: 0,
    sell_high_limit: 0,
    sell_low_limit: 0,
  },
  order_payment_info: {
    id: '',
    account_id: '',
    ether_address: {
      String: '',
      Valid: false,
    },
    beneficiary: {
      String: '',
      Valid: false,
    },
    beneficiary_address: {
      String: '',
      Valid: false,
    },
    iban: {
      String: '',
      Valid: false,
    },
    bic: {
      String: '',
      Valid: false,
    },
    created_at: '',
    updated_at: {
      Time: '',
      Valid: false,
    },
    other_info: {
      String: '',
      Valid: false,
    },
  },
  sellFee: '',
  fetchMembershipProgress: false,
};
