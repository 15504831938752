import { all } from 'redux-saga/effects';
import changePassword from '../SettingsChangePassword/state/saga';
import enableTwoFactorAuth from '../SettingsTwoFactorAuth/state/saga';
import profileInfo from '../Membership/state/saga';
import currencyRate from '../CurrencySettings/state/saga';
import feeRate from '../FeeSettings/state/saga';
import depositFiat from '../FiatDeposit/state/saga';

export default function* () {
  yield all([
    changePassword(),
    enableTwoFactorAuth(),
    profileInfo(),
    currencyRate(),
    feeRate(),
    depositFiat(),
  ]);
}
