import React from 'react';
import * as yup from 'yup';
import useInvestCurrencyState from '../useInvestCurrencyState';
import InvestCurrencyDetails from './InvestCurrencyDetails';

const ExchangeCurrencyDetailsStep = () => {
  const {
    investCurrency: {
      membershipState: { buy_limit_low, buy_limit_high, eur_price },
      selectedOption,
    },
  } = useInvestCurrencyState();
  const isEurSelectedOption = selectedOption === 'WIRE_EUR';
  const slcToEur = (value: number) => (value * eur_price) / 100;
  const minValue = buy_limit_low / eur_price || 0;
  const maxValue =
    (buy_limit_high - (isEurSelectedOption ? 500 : buy_limit_high * 0.02)) / eur_price || Infinity;

  // If WIRE_EUR is selected option, transform value to EUR
  const calulateValue = (value: number) =>
    Number((isEurSelectedOption ? slcToEur(value) : value).toFixed(2));
  const objectScheme = {};
  const buyLimitLowInEur = selectedOption === 'WALLET_EUR' ? buy_limit_low / 100 : 0;
  return (
    <InvestCurrencyDetails
      initValues={{
        amount: '',
        get: '',
        isBtnDisabled: true,
        step: 1,
        beneficiary: '',
        beneficiary_address: '',
        other_info: '',
        bic: '',
        iban: '',
      }}
      submitText={'Continue'}
      validationSchema={{
        amount: yup
          .number()
          .required('Required')
          .notOneOf([0], 'Value can not be 0')
          .min(
            calulateValue(minValue),
            `Must be greater or equal to 
            ${calulateValue(minValue)}
            `,
          )
          .max(
            calulateValue(maxValue),
            `Must be lower or equal to 
            ${calulateValue(maxValue)}
            `,
          ),
        get: yup
          .number()
          .positive('Value can not be 0 or negative number')
          .moreThan(
            yup.ref('get'),
            ({ value }) => buyLimitLowInEur > value && `Can't be less than ${buyLimitLowInEur}`,
          ),
        ...objectScheme,
      }}
    />
  );
};
export default ExchangeCurrencyDetailsStep;
