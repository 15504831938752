/* eslint-disable */
import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import { KycUpdateAplicantRequest } from '../../../../../data/repositories/infoDialog/typedefs';

export const IS_INFO_DIALOG_OPEN = 'infoDialog/is_info_dialog_open';
export const SET_INFO_DIALOG_FIELDS = 'infoDialog/set_info_dialog_fields';
export const SET_INFO_DIALOG_FAIL = 'infoDialog/set_info_dialog_fail';
export const SET_INFO_DIALOG_SUCCESS = 'infoDialog/set_info_dialog_success';
export const KYC_UPDATE_APLICANT = 'infoDialog/update_aplicant';
export const KYC_UPDATE_APLICANT_SUCCESS = 'infoDialog/update_aplicant/success';
export const KYC_UPDATE_APLICANT_FAIL = 'infoDialog/update_aplicant/fail';

export const isInfoDialogOpen = createStandardAction(IS_INFO_DIALOG_OPEN)<boolean>();
export const setInfoDialogFields = createAsyncAction(
  SET_INFO_DIALOG_FIELDS,
  SET_INFO_DIALOG_SUCCESS,
  SET_INFO_DIALOG_FAIL,
)<any, string, Error | string>();

export const asyncKycUpdateAplicant = createAsyncAction(
  KYC_UPDATE_APLICANT,
  KYC_UPDATE_APLICANT_SUCCESS,
  KYC_UPDATE_APLICANT_FAIL,
)<KycUpdateAplicantRequest, any, Error>();
