import { createReducer } from 'typesafe-actions';
import { handleMinMaxRange } from '../../../../../utils/helper/utils';
import {
  GET_WALLET_TRANSFERS,
  GET_WALLET_TRANSFERS_FAILED,
  GET_WALLET_TRANSFERS_SUCCESS,
  SET_TRANSFERS_TABLE,
} from '../../../state/actions';
import {
  GET_TERM_SAVINGS_DETAILS,
  GET_TERM_SAVINGS_DETAILS_FAIL,
  GET_TERM_SAVINGS_DETAILS_SUCCESS,
} from '../AffiliatePremiumTable/state/actions';
import {
  CLEAR_ALL_FILTERS,
  CLEAR_TRANSACTION_HASH_DIALOG_STATE,
  EXPORT_TRANSACTIONS,
  EXPORT_TRANSACTIONS_FAIL,
  EXPORT_TRANSACTIONS_SUCCESS,
  EXPORT_USERS,
  EXPORT_USERS_FAIL,
  EXPORT_USERS_SUCCESS,
  GET_SWAP_INFO,
  GET_SWAP_INFO_FAIL,
  GET_SWAP_INFO_SUCCESS,
  GET_TRANSFERS,
  GET_TRANSFERS_FAIL,
  GET_TRANSFERS_FOR_COIN,
  GET_TRANSFERS_FOR_COIN_SUCCESS,
  GET_TRANSFERS_SET_LOADING,
  GET_TRANSFERS_SUCCESS,
  IS_TRANSACTION_HASH_DIALOG_OPEN,
  SAVE_TRANSFERS,
  SET_TRANSACTION_HASH_DIALOG_TX_ID,
  TOGGLE_FILTER,
} from './actions';
import { initialState, transactionHashDialogInitailState } from './initialState';

export default createReducer(initialState, {
  [GET_TRANSFERS]: state => ({
    ...state,
    inProgress: true,
    loadingDone: true,
  }),
  [GET_TRANSFERS_SUCCESS]: state => ({
    ...state,
    fail: false,
    inProgress: false,
  }),
  [GET_TRANSFERS_FAIL]: (state, { payload }) => ({
    ...state,
    inProgress: false,
    fail: true,
    ...(payload && payload.response ? { error: payload.response.data } : {}),
  }),
  [GET_TRANSFERS_FOR_COIN]: state => ({
    ...state,
    inProgress: true,
  }),
  [GET_TRANSFERS_FOR_COIN_SUCCESS]: (state, { payload }) => ({
    ...state,
    fail: false,
    inProgress: false,
    singleCoinTransactions: { ...payload },
  }),
  [GET_TRANSFERS_FAIL]: (state, { payload }) => ({
    ...state,
    inProgress: false,
    fail: true,
    ...(payload && payload.response ? { error: payload.response.data } : {}),
  }),
  [SAVE_TRANSFERS]: (state, { payload }) => ({
    ...state,
    transactions: { ...payload },
  }),
  [GET_TRANSFERS_SET_LOADING]: (state, { payload }) => ({
    ...state,
    inProgress: payload,
  }),
  [TOGGLE_FILTER]: (state, { payload }) => {
    let objectRange = {};
    if (payload.filterType === 'valueRange') {
      objectRange = handleMinMaxRange(state, payload);
      if (!objectRange['maxValue'] && !objectRange['minValue']) {
        payload.value = undefined;
      }
    }

    return {
      ...state,
      walletTransfersState: {
        ...state.walletTransfersState,
        page: 0,
      },
      appliedFilters: {
        ...state.appliedFilters,
        [payload.filterType]: payload.value,
        ...objectRange,
      },
    };
  },
  [CLEAR_ALL_FILTERS]: state => ({
    ...state,
    appliedFilters: new Map(),
  }),
  [EXPORT_USERS]: state => ({
    ...state,
    exportInProgress: true,
  }),
  [EXPORT_USERS_SUCCESS]: state => ({
    ...state,
    exportInProgress: false,
  }),
  [EXPORT_USERS_FAIL]: (state, { payload }) => ({
    ...state,
    exportInProgress: false,
    error: payload,
  }),
  [EXPORT_TRANSACTIONS]: state => ({
    ...state,
    exportInProgress: true,
  }),
  [EXPORT_TRANSACTIONS_SUCCESS]: state => ({
    ...state,
    exportInProgress: false,
  }),
  [EXPORT_TRANSACTIONS_FAIL]: (state, { payload }) => ({
    ...state,
    exportInProgress: false,
    error: payload,
  }),
  [GET_TERM_SAVINGS_DETAILS]: state => ({
    ...state,
    termSavingsDetails: {
      description: '',
      earned_interest: 0,
      maturity_date: '',
      amount: 0,
      start_date: '',
    },
  }),
  [GET_TERM_SAVINGS_DETAILS_SUCCESS]: (state, { payload }) => ({
    ...state,
    termSavingsDetails: { ...payload },
  }),
  [GET_TERM_SAVINGS_DETAILS_FAIL]: (state, { payload }) => ({
    ...state,
    termSavingsDetails: {
      description: '',
      earned_interest: 0,
      maturity_date: '',
      amount: 0,
      start_date: '',
    },
    error: payload,
  }),

  [SET_TRANSFERS_TABLE]: (state, { payload }) => ({
    ...state,
    walletTransfersState: {
      ...state.walletTransfersState,
      page: payload,
    },
  }),

  [GET_WALLET_TRANSFERS]: state => ({
    ...state,
    error: null,
    walletTransfersState: {
      ...state.walletTransfersState,
      inProgress: true,
    },
  }),

  [GET_WALLET_TRANSFERS_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      walletTransfersState: {
        ...state.walletTransfersState,
        inProgress: false,
        totalRows: payload.bitgo_tx_count,
        totalSum: { ...payload.bitgo_tx_sums },
        walletTransfers: { ...payload.bitgo_tx_logs },
      },
    };
  },

  [GET_WALLET_TRANSFERS_FAILED]: (state, { payload }) => ({
    ...state,
    error: payload,
    walletTransfersState: {
      ...state.walletTransfersState,
      inProgress: false,
    },
  }),
  [IS_TRANSACTION_HASH_DIALOG_OPEN]: (state, { payload }) => {
    return {
      ...state,
      transactionHashDialog: { ...state.transactionHashDialog, open: payload },
    };
  },
  [SET_TRANSACTION_HASH_DIALOG_TX_ID]: (state, { payload }) => {
    return {
      ...state,
      transactionHashDialog: { ...state.transactionHashDialog, selectedTXHashId: payload },
    };
  },
  [CLEAR_TRANSACTION_HASH_DIALOG_STATE]: state => {
    return {
      ...state,
      transactionHashDialog: {
        ...state.transactionHashDialog,
        ...transactionHashDialogInitailState,
      },
    };
  },

  [GET_SWAP_INFO]: state => ({
    ...state,
    transactionHashDialog: { ...state.transactionHashDialog, inProgress: true },
  }),

  [GET_SWAP_INFO_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      transactionHashDialog: {
        ...state.transactionHashDialog,
        inProgress: false,
        success: true,
        fail: false,
        isNewRegisteredUser: false,
        ...payload,
      },
    };
  },

  [GET_SWAP_INFO_FAIL]: (state, { payload }) => ({
    ...state,
    transactionHashDialog: {
      ...state.transactionHashDialog,
      inProgress: false,
      success: false,
      fail: true,
      isNewRegisteredUser: payload.response.status === 409,
    },
  }),
});
