import { createReducer } from 'typesafe-actions';
import { CALCULATE_IF_IS_MOBILE_DEVICE, SET_DRAWER_HOVER, SET_DRAWER_MENU_STATE } from './actions';
import { DrawerState, initialState } from './initialState';

export default createReducer(initialState, {
  [SET_DRAWER_MENU_STATE]: (state: DrawerState, { payload }) => ({
    ...state,
    isOpen: payload,
  }),
  [CALCULATE_IF_IS_MOBILE_DEVICE]: (state: DrawerState, { payload }) => ({
    ...state,
    isMobileDevice: payload <= 1280 ? true : false,
  }),
  [SET_DRAWER_HOVER]: (state: DrawerState, { payload }) => ({
    ...state,
    isHovering: payload,
  }),
});
