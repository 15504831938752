import { createReducer } from 'typesafe-actions';
import {
  GET_WALLET_BALANCE,
  GET_WALLET_BALANCE_FAIL,
  GET_WALLET_BALANCE_SUCCESS,
  GET_ENTIRE_WALLET_BALANCES_SUCCESS,
  GET_ENTIRE_WALLET_BALANCES_FAIL,
  IS_INVEST_DIALOG_OPEN,
  GET_TERM_SAVING_TYPES_SUCCESS,
  GET_TERM_SAVING_TYPES_FAIL,
  SET_TERM_SAVING_PACKAGE,
  DEPOSIT_TERM_SAVING_FAIL,
  DEPOSIT_TERM_SAVING,
  DEPOSIT_TERM_SAVING_SUCCESS,
  GET_CRYPTO_CHART_DATA,
  GET_CRYPTO_CHART_DATA_SUCCESS,
  GET_CRYPTO_CHART_DATA_FAIL,
  SET_SELECTED_COIN,
  GET_TABLE_CHART,
  GET_TABLE_CHART_SUCCESS,
  GET_TABLE_CHART_FAIL,
} from './actions';
import { initialState } from './initialState';

export default createReducer(initialState, {
  [GET_WALLET_BALANCE]: state => ({
    ...state,
    componentState: { ...state.componentState, coinsTableInProgress: true },
  }),
  [GET_WALLET_BALANCE_SUCCESS]: (state, { payload }) => ({
    ...state,
    componentState: { ...state.componentState, coinsTableInProgress: false },
    wallets: payload,
  }),
  [GET_WALLET_BALANCE_FAIL]: (state, { payload }) => ({
    ...state,
    componentState: {
      ...state.componentState,
      coinsTableInProgress: false,
      fail: true,
      ...(payload ? { error: payload.message } : {}),
    },
  }),
  [GET_ENTIRE_WALLET_BALANCES_SUCCESS]: (state, { payload }) => ({
    ...state,
    entireWalletBalance: payload,
  }),
  [GET_ENTIRE_WALLET_BALANCES_FAIL]: state => ({
    ...state,
    fail: true,
  }),
  [IS_INVEST_DIALOG_OPEN]: (state, { payload }) => ({
    ...state,
    investDialogState: { ...state.investDialogState, investDialogOpened: payload },
  }),
  [GET_TERM_SAVING_TYPES_SUCCESS]: (state, { payload }) => ({
    ...state,
    investDialogState: { ...state.investDialogState, termPackageTypes: payload },
  }),
  [GET_TERM_SAVING_TYPES_FAIL]: state => ({
    ...state,
    componentState: { ...state.investDialogState, termPackageTypes: [] },
  }),
  [SET_TERM_SAVING_PACKAGE]: (state, { payload }) => ({
    ...state,
    investDialogState: { ...state.investDialogState, selectedPackage: payload },
  }),
  [DEPOSIT_TERM_SAVING]: state => ({
    ...state,
    investDialogState: { ...state.investDialogState, errorMessage: '', inProgress: true },
  }),
  [DEPOSIT_TERM_SAVING_SUCCESS]: state => ({
    ...state,
    investDialogState: { ...state.investDialogState, investDialogOpened: false, inProgress: false },
  }),
  [DEPOSIT_TERM_SAVING_FAIL]: (state, { payload }) => ({
    ...state,
    investDialogState: { ...state.investDialogState, errorMessage: payload, inProgress: false },
  }),
  [GET_CRYPTO_CHART_DATA]: state => ({
    ...state,
    chart: { ...state.chart, inProgress: true, fail: false },
  }),
  [GET_CRYPTO_CHART_DATA_SUCCESS]: (state, { payload }) => ({
    ...state,
    chart: {
      ...state.chart,
      inProgress: false,
      data: payload.data,
      selectedPeriodFilter: payload.period,
    },
  }),
  [GET_CRYPTO_CHART_DATA_FAIL]: (state, { payload }) => ({
    ...state,
    chart: {
      ...state.chart,
      inProgress: false,
      fail: true,
      ...(payload ? { error: payload.message } : {}),
    },
  }),
  [SET_SELECTED_COIN]: (state, { payload }) => ({
    ...state,
    chart: {
      ...state.chart,
      selectedCoin: payload,
      selectedPeriodFilter: initialState.chart.selectedPeriodFilter,
    },
  }),
  [GET_TABLE_CHART]: state => ({
    ...state,
    tableChart: {
      ...state.tableChart,
      inProgress: true,
      fail: false,
    },
  }),
  [GET_TABLE_CHART_SUCCESS]: (state, { payload }) => ({
    ...state,
    tableChart: {
      ...state.tableChart,
      inProgress: false,
      fail: false,
      data: payload.responseData,
      selectedPeriodFilter: payload.responsePeriod,
      error: '',
      isFallback: payload.isFallback,
    },
  }),
  [GET_TABLE_CHART_FAIL]: (state, { payload }) => ({
    ...state,
    tableChart: {
      ...state.tableChart,
      inProgress: false,
      fail: true,
      error: payload.message,
    },
  }),
});
