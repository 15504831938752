import { Button, Grid, Input, Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { Colors } from '../../ui/style/colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    copyToClipbord: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      borderRadius: 6,
      boxShadow: '0 2px 10px 0 rgba(132,141,182,0.25)',
      fontSize: 5,
      color: theme.palette.primary.light,
    },
    copyToClipbordInput: {
      color: theme.palette.primary.light,
      fontSize: 14,
      paddingLeft: 12,
    },
    copyToClipbordInputHidden: {
      position: 'absolute',
      width: '1000px',
      left: '-10000px',
      opacity: 0,
      pointerEvents: 'none',
    },
    copyToClipbordButton: {
      borderRadius: '0 6px 6px 0',
      backgroundColor: `${Colors.havelockBlue}`,
      boxShadow: '0 2px 10px 0 rgba(132,141,182,0.25)',
      color: `${Colors.white}`,
      height: 38,
      float: 'right',
      whiteSpace: 'nowrap',
      textTransform: 'none',
      width: '50%',
      marginRight: '1px',
    },
    copyToClipboardButtonFirst: {
      borderRadius: '0',
      backgroundColor: `${Colors.havelockBlue}`,
      boxShadow: '0 2px 10px 0 rgba(132,141,182,0.25)',
      color: `${Colors.white}`,
      height: 38,
      float: 'right',
      textTransform: 'none',
      borderRight: `1px solid ${Colors.white}`,
      width: '50%',
    },
    copyGrid: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  }),
);

type Props = {
  code: string;
  link?: string;
};

const CopyToClipboard = ({ code, link }: Props) => {
  const classes = useStyles();
  const [text, setText] = useState('Copy');
  const [textLink, setTextLink] = useState('Copy link');

  const copyClipboard = (flag: number) => {
    const copyText = document.getElementById('affiliateCode') as HTMLInputElement;
    const copyLink = document.getElementById('affiliateLink') as HTMLInputElement;
    if (flag) copyLink.select();
    else copyText.select();
    document.execCommand('Copy');
    copyText.blur();
    copyLink && copyLink.blur();
    if (flag) setTextLink('Copied!');
    else setText('Copied!');
    setTimeout(() => {
      setText('Copy');
      setTextLink('Copy link');
    }, 1000); // 1s
  };

  return (
    <Paper className={classes.copyToClipbord}>
      <Grid item xs>
        <Input
          id="affiliateCode"
          value={code}
          className="root"
          disableUnderline={true}
          fullWidth
          readOnly
          classes={{ input: classes.copyToClipbordInput }}
        />
        {link && (
          <Input
            className="root"
            classes={{ input: classes.copyToClipbordInputHidden }}
            value={link}
            id={'affiliateLink'}
          />
        )}
      </Grid>
      <Grid className={classes.copyGrid} item xs="auto">
        <Button
          id="copyToClipbordButton"
          variant="contained"
          size="small"
          className={link ? classes.copyToClipboardButtonFirst : classes.copyToClipbordButton}
          onClick={() => copyClipboard(0)}
        >
          {text}
        </Button>
        {link && (
          <Button
            id="copyToClipbordButton"
            variant="contained"
            size="small"
            className={classes.copyToClipbordButton}
            onClick={() => copyClipboard(1)}
          >
            {textLink}
          </Button>
        )}
      </Grid>
    </Paper>
  );
};

export default CopyToClipboard;
