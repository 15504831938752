import { Cycle } from '../../../../../../data/repositories/affiliate/typedefs';
import { TransactionsFilter } from '../../../../../../data/repositories/transaction/typedef';

export type MerchantsReferralStats = {
  totalReferrals: number | null;
  directReferrals: number | null;
};

export type AffiliateMerchantsTableState = {
  referrals: Map<string, any> | null;
  inProgress: boolean;
  fail: boolean;
  error: Error | null;
  errorMessage: string;
  isErrorModalOpen: boolean;
  stats: MerchantsReferralStats;
  page: number;
  perPage: number;
  cycles: Cycle[];
  appliedFilters: TransactionsFilter;
  order_type: string;
};

export const initialState: AffiliateMerchantsTableState = {
  referrals: null,
  inProgress: false,
  fail: false,
  error: null,
  errorMessage: '',
  isErrorModalOpen: false,
  appliedFilters: new Map<string, string[]>(),
  page: 0,
  perPage: 10,
  cycles: [],
  stats: {
    totalReferrals: null,
    directReferrals: null,
  },
  order_type: 'buy',
};
