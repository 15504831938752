import { connect } from 'react-redux';
import { AppState } from '../../../state/initialState';
import { setCurrentHomePage } from '../../state/common/actions';
import {
  asyncGetCryptoChartData,
  getAllCoins,
  getWalletBalance,
  setSelectedCoin,
} from './state/actions';
import {
  getComponentStateSelector,
  getDashboardCurrenciesSymbolsSelector,
  getCryptoChartsStateSelector,
} from './state/selectors';
import Dashboard from './Dashboard';
import { membershipStateSelector } from '../Exchange/state/selectors';

const mapDispatchToProps = {
  getWalletBalance: getWalletBalance,
  getAllCoins: getAllCoins,
  setCurrentHomePage: setCurrentHomePage,
  asyncGetCryptoChartData: asyncGetCryptoChartData.request,
  setSelectedCoin: setSelectedCoin,
};
const mapStateToProps = (state: AppState) => {
  const walletsSymbols = getDashboardCurrenciesSymbolsSelector(state);
  const componentState = getComponentStateSelector(state);
  const membershipLevel = membershipStateSelector(state);
  const cryptoChartData = getCryptoChartsStateSelector(state);
  return {
    walletsSymbols,
    componentState,
    membershipLevel,
    cryptoChartData,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
