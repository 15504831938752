import { connect } from 'react-redux';
import { setCurrentHomePage } from '../../state/common/actions';
import Admin from './Admin';
import { AppState } from '../../../state/initialState';
import { userStateSelector } from '../../../state/selectors';

const mapDispatchToProps = {
  setCurrentHomePage: setCurrentHomePage,
};
const mapStateToProps = (state: AppState) => {
  const user = userStateSelector(state);
  return { user };
};
export default connect(mapStateToProps, mapDispatchToProps)(Admin);
