/* eslint-disable */
import { isEmpty, pickBy } from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';
import { Transaction } from '../../../../../data/repositories/transaction/typedef';
import { Wallet } from '../../../../../data/repositories/wallet/typedefs';
import { AppState } from '../../../../state/initialState';
import { walletStateSelector } from '../../Wallet/state/selectors';

const getTransfersState = ({ home: { transactions } }: AppState) => transactions;

const getTransactionsState = ({
  home: {
    transactions: { transactions },
  },
}: AppState) => transactions;

const getTransactionsIds = ({
  home: {
    transactions: { transactions },
  },
}: AppState) => Object.keys(transactions);

const getSingleCoinTransactionsIds = ({
  home: {
    transactions: { singleCoinTransactions },
  },
}: AppState) => Object.keys(singleCoinTransactions);

const getAppliedFiltersState = ({
  home: {
    earnings: { appliedFilters },
  },
}: AppState) => appliedFilters;
const getAppliedFiltersIds = ({
  home: {
    earnings: { appliedFilters },
  },
}: AppState) => Object.keys(appliedFilters);

export const getTransactionsStateSelector = createSelector(
  getTransactionsState,
  transactions => transactions,
);

export const getTransactionsInProgressSelector = createSelector(
  getTransfersState,
  transactions => transactions.inProgress,
);

export const getTransactionsErrorSelector = createSelector(
  getTransfersState,
  transactions => ({ error: transactions.error, loadingDone: transactions.loadingDone }),
);

export const getTransfersStateSelector = createSelector(
  getTransfersState,
  transfers => transfers,
);

export const getTransactionsIdsSelector = createSelector(
  getTransactionsIds,
  transactionsIds => transactionsIds,
);

export const getSingleCoinTransactionsIdsSelector = createSelector(
  getSingleCoinTransactionsIds,
  transactionsIds => transactionsIds,
);

export const getTransactionById = (transactionId: string) =>
  createSelector(
    getTransactionsState,
    transactions => {
      const t = new Map<string, Transaction>(Object.entries(transactions));
      const transaction = t.get(transactionId);
      return transaction;
    },
  );

export const expensiveGetTransactionById = (transactionId: string) => {
  return createSelector(
    getTransactionById(transactionId),
    walletStateSelector,
    (transaction, wallets) => {
      if (transaction !== undefined && wallets !== undefined) {
        const myAddress = getAddressForSymbol(transaction, wallets);
        return myAddress
          ? { ...transaction, type: calculateType(transaction, myAddress) }
          : transaction;
      }
    },
  );
};

export const calculateType = (transaction: Transaction, myAddress: string) => {
  if (transaction.type === 'transfer') {
    return transaction.transfers.filter(
      ({ transfer_type, address }) => address === myAddress && transfer_type === 'input',
    ).length > 0
      ? 'withdrawal'
      : 'deposit';
  }

  return transaction.type;
};

export const getAppliedFiltersIdsSelector = createSelector(
  getAppliedFiltersIds,
  appliedFiltersIds => appliedFiltersIds,
);

const getAllAppliedFiltersSelector = createSelector(
  getAppliedFiltersState,
  appliedFilters => appliedFilters,
);

export const getAppliedFiltersSelector = createSelector(
  getAllAppliedFiltersSelector,
  getAppliedFiltersIds,
  (appliedFilters, appliedFiltersIds) => {
    const af = new Map<string, string[]>(Object.entries(appliedFilters));
    let appliedFiltersNonEmpty = new Map<string, string[]>();
    for (const index in appliedFiltersIds) {
      const filter = af.get(appliedFiltersIds[index]);
      if (!isEmpty(filter) && filter) {
        appliedFiltersNonEmpty.set(appliedFiltersIds[index], filter);
      }
    }

    return appliedFiltersNonEmpty;
  },
);

export const filteredTransactionsSelector = createSelector(
  walletStateSelector,
  getAppliedFiltersSelector,
  getTransactionsStateSelector,
  (wallets, appliedFilters, transactions) => {
    return pickBy(transactions, (value: Transaction, key: string) =>
      compareFilter(wallets, value, key, appliedFilters),
    );
  },
);

const getAddressForSymbol = (transaction: Transaction, wallets: Map<string, Wallet>) => {
  const symbol = transaction.transfers[0].symbol;

  const walletsMap = new Map<string, Wallet>(Object.entries(wallets));
  const wallet = walletsMap.get(symbol);
  const address = wallet && wallet.address;
  return address;
};

const compareFilter = (
  wallets: Map<string, Wallet>,
  value: Transaction,
  key: string,
  appliedFilters: Map<string, string[]>,
) => {
  const coinsFilter = appliedFilters.get('coins');
  const transactionTypeFilter = appliedFilters.get('transactionType');
  const valueRange = appliedFilters.get('valueRange');
  const dateFilters = appliedFilters.get('dateFilter');
  const searchInput = appliedFilters.get('searchInput');

  if (
    (!coinsFilter || coinsFilter.length == 0) &&
    (!transactionTypeFilter || transactionTypeFilter.length == 0) &&
    (!valueRange || valueRange.length == 0) &&
    (!dateFilters || dateFilters.length == 0) &&
    (!searchInput || searchInput[0].length == 0)
  ) {
    return true;
  }
  if (coinsFilter && coinsFilter.indexOf(value.transfers[0].symbol) > -1) {
    return true;
  }

  const myAddress = getAddressForSymbol(value, wallets);
  if (
    transactionTypeFilter &&
    myAddress &&
    transactionTypeFilter.indexOf(calculateType(value, myAddress)) > -1
  ) {
    return true;
  }
  if (
    valueRange &&
    Math.abs(parseFloat(value.transfers[0].value.replace(/,/g, ''))) > parseFloat(valueRange[0]) &&
    Math.abs(parseFloat(value.transfers[0].value.replace(/,/g, ''))) < parseFloat(valueRange[1])
  ) {
    return true;
  }
  if (
    dateFilters &&
    moment(value.transfers[0].created_at).diff(moment(dateFilters[0], 'DD/MM/YYYY')) >= 0 &&
    moment(value.transfers[0].created_at).diff(moment(dateFilters[1], 'DD/MM/YYYY')) <= 0
  ) {
    return true;
  }
  if (searchInput && value.transfers[0].transfer_id.includes(searchInput[0])) {
    return true;
  }
  return false;
};

export const filteredTransactionsIdsSelector = createSelector(
  filteredTransactionsSelector,
  filteredTransactions => Object.keys(filteredTransactions),
);

export const getAreAnyFiltersApplied = createSelector(
  getAppliedFiltersState,
  getAppliedFiltersIds,
  (appliedFilters, appliedFiltersIds) => {
    let applied = false;
    for (const index in appliedFiltersIds) {
      const af = new Map<string, string[]>(Object.entries(appliedFilters));
      applied = applied || !isEmpty(af.get(appliedFiltersIds[index]));
    }
    return applied;
  },
);
