import { connect } from 'react-redux';
import { AppState } from '../../../../state/initialState';
import ProductsTable from './ProductsTable';
import {
  productTableStateSelector,
  getAllAppliedFiltersSelector,
  getAllAppliedTabSelector,
} from './state/selectors';

const mapStateToProps = (state: AppState) => ({
  ...productTableStateSelector(state),
  appliedFilters: getAllAppliedFiltersSelector(state),
  appliedTab: getAllAppliedTabSelector(state),
});

export default connect(mapStateToProps, {})(ProductsTable);
