import { Cycle, Referral } from '../../../../../../data/repositories/affiliate/typedefs';

export type MerchantsReferralStats = {
  totalReferrals: number | null;
  directReferrals: number | null;
  totalEarned?: number | null;
  totalAmount?: number | null;
};

export type AffiliateMerchantsTableState = {
  referrals: Map<string, Referral> | null;
  transactions: Map<string, any> | null;
  inProgress: boolean;
  fail: boolean;
  error: Error | null;
  stats: MerchantsReferralStats;
  page: number;
  perPage: number;
  cycles: Cycle[];
};

export const initialState: AffiliateMerchantsTableState = {
  referrals: null,
  transactions: null,
  inProgress: false,
  fail: false,
  error: null,
  page: 0,
  perPage: 10,
  cycles: [],
  stats: {
    totalReferrals: null,
    directReferrals: null,
    totalEarned: null,
    totalAmount: null,
  },
};
