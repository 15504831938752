import { createSelector } from 'reselect';
import { AppState } from '../../../../../state/initialState';

const getAffiliateMerchantsTableState = ({
  home: {
    affiliate: { affiliateMerchantsTable },
  },
}: AppState) => affiliateMerchantsTable;

export const affiliateMerchantsTableStateSelector = createSelector(
  getAffiliateMerchantsTableState,
  affiliateMerchantsTable => affiliateMerchantsTable,
);

export const getAffiliateMerchantsTableReferralsSelector = createSelector(
  affiliateMerchantsTableStateSelector,
  affiliateMerchantsTable => affiliateMerchantsTable.referrals,
);

export const getAffiliateMerchantsTableReferralsStatsSelector = createSelector(
  affiliateMerchantsTableStateSelector,
  affiliateMerchantsTable => affiliateMerchantsTable.stats,
);

export const getMerchantsCyclesSelector = createSelector(
  affiliateMerchantsTableStateSelector,
  affiliateMerchantsTable => affiliateMerchantsTable.cycles,
);
