import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { Colors } from '../../../../../ui/style/colors';
import theme from '../../../../theme';
import SVG from 'react-inlinesvg';
import KycIcon from '../../../../../assets/kyc.svg';
import VerifyAccountButton from '../../../../../components/VerifyAccountButton';
import CoinCard from './CoinSlider/CoinSlider';

const useStyles = makeStyles({
  walletPaper: {
    borderRadius: '3px',
    backgroundColor: `${Colors.white}`,
    boxShadow: '0 10px 30px 0 rgba(132,141,182,0.25)',
    overflow: 'hidden',
    margin: '38px 24px 0 24px',
    minWidth: 240,
    minHeight: 'calc(100% - 24px)',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },
  wrapper: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  kycIcon: {
    marginTop: 24,
    width: 80,
    height: 80,
  },
  verifyButton: {
    margin: 20,
  },
  kycText: {
    padding: 30,
    fontSize: 20,
    fontWeight: 'bold',
    color: `${Colors.wildBlueYonder}`,
  },
  coinsText: {
    paddingTop: 50,
    fontSize: 20,
    color: `${Colors.wildBlueYonder}`,
  },
});

const KycPage = () => {
  const classes = useStyles();
  return (
    <>
      <Paper id="scrollable" className={classes.walletPaper}>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          className={classes.wrapper}
        >
          <SVG src={KycIcon} className={classes.kycIcon} />
          <Grid container direction="column" alignItems="center" justifyContent="center">
            <Typography align="center" className={classes.kycText}>
              Hey, you need to verify your identity in order to use wallet.
              <br /> Please click on button below and you will be redirected to KYC query.
            </Typography>
            <VerifyAccountButton className={classes.verifyButton} />
          </Grid>
        </Grid>
        <Typography align="center" className={classes.coinsText}>
          Available coins on our platform:
        </Typography>
        <CoinCard />
      </Paper>
    </>
  );
};

export default KycPage;
