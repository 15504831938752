import { Product, ProductDetail, ProductInsertRequest } from './typedef';
import Http from '../../Http';

const insertProduct = async (data: ProductInsertRequest, endpoint: string): Promise<any> =>
  (await Http.post(endpoint, data)).data;

const allProducts = async (endpoint: string): Promise<Product[]> => (await Http.get(endpoint)).data;

const investedProducts = async (endpoint: string): Promise<any[]> =>
  (await Http.get(endpoint)).data;

const editProduct = async (data: ProductDetail, endpoint: string): Promise<any> =>
  (await Http.post(endpoint, data)).data;

const publishProduct = async (endpoint: string): Promise<any> => (await Http.post(endpoint)).data;

const investProduct = async (data: any, endpoint: string): Promise<any> =>
  (await Http.post(endpoint, data)).data;

const exportProducts = async (endpoint: string): Promise<any> => (await Http.get(endpoint)).data;

const singleProduct = async (endpoint: string): Promise<Product[]> =>
  (await Http.get(endpoint)).data;

export default {
  investedProducts,
  investProduct,
  insertProduct,
  allProducts,
  editProduct,
  publishProduct,
  exportProducts,
  singleProduct,
};
