import { all, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import LocalStorage from '../../../../../../data/LocalStorage';
import api from '../../../../../../data/repositories/admin';
import { AllTransactionsStats } from '../../../../../../data/repositories/admin/typedefs';
import moment from 'moment';
import {
  GET_USER_TRANSACTION_HISTORY_PAGINATED_ENDPOINT,
  EXPORT_USER_TRANSACTION_HISTORY,
  GET_USER_TRASNACTION_HISTORY_TRANSFERS_PAGINATED,
  EXPORT_USER_TRANSFERS_HISTORY,
} from '../../../../../../utils/constants/api';
import { USER_ID } from '../../../../../../utils/constants/localStorageKeys';
import {
  asyncGetAllTransactionsPaginated,
  asyncGetAllTransactionsStats,
  GET_ALL_TRANSACTIONS_PAGINATED,
  setAllTransactionsTablePage,
  asyncExportAllTransactions,
  EXPORT_ALL_TRANSACTIONS,
  getWalletTransfersPaginated,
  GET_WALLET_TRANSFERS_PAGINATED,
  setWalletTransfersTable,
  EXPORT_WALLET_TRANSFERS,
  asyncExportWalletTransfers,
} from './actions';

import { getCoinDecimalsSelector } from '../../../Wallet/state/selectors';
import { saveAs } from 'file-saver';

const getFilters = (action: any) => ({
  origin:
    action.payload.appliedFilters && action.payload.appliedFilters.transactionType
      ? action.payload.appliedFilters.transactionType
      : null,
  amountFrom:
    action.payload.appliedFilters && action.payload.appliedFilters.valueRange
      ? action.payload.appliedFilters.valueRange[0] !== ''
        ? action.payload.appliedFilters.valueRange[0] * 10000000
        : -100000 * 10000000
      : null,
  amountTo:
    action.payload.appliedFilters && action.payload.appliedFilters.valueRange
      ? action.payload.appliedFilters.valueRange[1] !== ''
        ? action.payload.appliedFilters.valueRange[1] * 10000000
        : 100000 * 10000000
      : null,
  dateFrom:
    action.payload.appliedFilters &&
    action.payload.appliedFilters.dateFilter &&
    action.payload.appliedFilters.dateFilter.length
      ? moment(action.payload.appliedFilters.dateFilter[0], 'DD/MM/YYYY').format(
          'YYYY-MM-DD HH:mm:ss',
        )
      : null,
  dateTo:
    action.payload.appliedFilters &&
    action.payload.appliedFilters.dateFilter &&
    action.payload.appliedFilters.dateFilter.length
      ? moment(action.payload.appliedFilters.dateFilter[1], 'DD/MM/YYYY').endOf('day').toDate() >
        moment().toDate()
        ? moment().format('YYYY-MM-DD HH:mm:ss')
        : moment(action.payload.appliedFilters.dateFilter[1], 'DD/MM/YYYY')
            .endOf('day')
            .format('YYYY-MM-DD HH:mm:ss')
      : null,
  tx_status:
    action.payload.appliedFilters && action.payload.appliedFilters.status
      ? action.payload.appliedFilters.status
      : null,
});
const getTransferFilters = (appliedFilters: any, multiplier: number) => ({
  origin: appliedFilters && appliedFilters.transferType ? appliedFilters.transferType : null,
  symbol: appliedFilters && appliedFilters.coinSymbol ? appliedFilters.coinSymbol : null,
  amountFrom:
    appliedFilters && appliedFilters.valueRange
      ? appliedFilters.valueRange[0] !== ''
        ? appliedFilters.valueRange[0] * multiplier
        : -10000000000
      : null,
  amountTo:
    appliedFilters && appliedFilters.valueRange
      ? appliedFilters.valueRange[1] !== ''
        ? appliedFilters.valueRange[1] * multiplier
        : 10000000000
      : null,
  dateFrom:
    appliedFilters && appliedFilters.dateFilter && appliedFilters.dateFilter.length
      ? moment(appliedFilters.dateFilter[0], 'DD/MM/YYYY').format('YYYY-MM-DD HH:mm:ss')
      : null,
  dateTo:
    appliedFilters && appliedFilters.dateFilter && appliedFilters.dateFilter.length
      ? moment(appliedFilters.dateFilter[1], 'DD/MM/YYYY').endOf('day').toDate() > moment().toDate()
        ? moment().format('YYYY-MM-DD HH:mm:ss')
        : moment(appliedFilters.dateFilter[1], 'DD/MM/YYYY')
            .endOf('day')
            .format('YYYY-MM-DD HH:mm:ss')
      : null,
  tx_status: appliedFilters && appliedFilters.status ? appliedFilters.status : null,
});

const getMultiplierValue = (appliedFilters: any, decimals: any) => {
  let multiplier = 1;
  if (!!appliedFilters && appliedFilters.coinSymbol !== null) {
    multiplier = Math.pow(10, decimals);
  }
  return multiplier;
};

const mapURL = (params: any) => {
  let newParams = {};
  Object.keys(params).forEach(key => {
    if (params[key] !== null) newParams = { ...newParams, [key]: params[key].toString() };
  });
  return new URLSearchParams(newParams).toString();
};

export function* getAllTransactionsPaginated$(
  action: ReturnType<typeof asyncGetAllTransactionsPaginated.request>,
) {
  try {
    const userId = LocalStorage.get(USER_ID);

    const filter = getFilters(action);

    const referrals: any = yield api.getUserTransactionHistoryPaginated(
      GET_USER_TRANSACTION_HISTORY_PAGINATED_ENDPOINT(
        userId || '',
        action.payload.logAccountId,
        action.payload.page,
        action.payload.perPage,
        mapURL(filter) && '&' + mapURL(filter),
      ),
    );

    const stats: AllTransactionsStats = {
      totalReferrals: referrals.transaction_count,
      directReferrals: null,
    };

    yield put(asyncGetAllTransactionsStats.success(stats));

    const result: any = {};
    referrals.transaction_logs.map((referral: { id: string | number }) => {
      result[referral.id] = referral;
    });

    yield put(setAllTransactionsTablePage(action.payload.page));
    yield put(asyncGetAllTransactionsPaginated.success(result));
  } catch (error) {
    yield put(
      asyncGetAllTransactionsPaginated.failure(
        error && error.message ? error.message : 'Something went wrong! Please try again later.',
      ),
    );
  }
}

export function* exportTransactions$(
  action: ReturnType<typeof asyncExportAllTransactions.request>,
) {
  try {
    const accountId = LocalStorage.get(USER_ID);
    const filter = getFilters(action);

    const response = yield api.exportTransactions(
      EXPORT_USER_TRANSACTION_HISTORY(
        accountId || '',
        action.payload.user_email,
        mapURL(filter) && '&' + mapURL(filter),
      ),
    );
    const csvData = new Blob([response], { type: 'text/csv;charset=utf-8;' });

    saveAs(csvData, `Transactions-${action.payload.user_name}.csv`);

    yield put(asyncExportAllTransactions.success());
  } catch (error) {
    yield put(
      asyncExportAllTransactions.failure(
        error && error.message ? error.message : 'Something went wrong! Please try again later.',
      ),
    );
  }
}

export function* getWalletTransfers$(
  action: ReturnType<typeof getWalletTransfersPaginated.request>,
) {
  try {
    const userId = LocalStorage.get(USER_ID);
    const { page, perPage, appliedFilters, logAccountId } = action.payload;
    const decimals: ReturnType<typeof getCoinDecimalsSelector> = yield select(
      getCoinDecimalsSelector(appliedFilters && appliedFilters.coinSymbol),
    );

    const multiplier = getMultiplierValue(appliedFilters, decimals);
    const filter = getTransferFilters(appliedFilters, multiplier);

    const walletTransfers = yield api.getWalletTransfersPaginated(
      GET_USER_TRASNACTION_HISTORY_TRANSFERS_PAGINATED(
        userId || '',
        logAccountId,
        page,
        perPage,
        mapURL(filter) && '&' + mapURL(filter),
      ),
    );

    yield put(setWalletTransfersTable(page));
    yield put(getWalletTransfersPaginated.success(walletTransfers));
  } catch (error) {
    yield put(
      getWalletTransfersPaginated.failure(
        error && error.message ? error.message : 'Something went wrong! Please try again later.',
      ),
    );
  }
}

export function* exportWalletTransfers$(
  action: ReturnType<typeof asyncExportWalletTransfers.request>,
) {
  try {
    const accountId = LocalStorage.get(USER_ID);
    const { user_email, user_name, appliedFilters } = action.payload;
    const decimals: ReturnType<typeof getCoinDecimalsSelector> = yield select(
      getCoinDecimalsSelector(appliedFilters.coinSymbol),
    );

    const multiplier = getMultiplierValue(appliedFilters, decimals);
    const filter = getTransferFilters(appliedFilters, multiplier);
    const response = yield api.exportTransactions(
      EXPORT_USER_TRANSFERS_HISTORY(
        accountId || '',
        user_email,
        mapURL(filter) && '&' + mapURL(filter),
      ),
    );
    const csvData = new Blob([response], { type: 'text/csv;charset=utf-8;' });

    saveAs(csvData, `Transfers-${user_name}.csv`);

    yield put(asyncExportWalletTransfers.success());
  } catch (error) {
    yield put(
      asyncExportWalletTransfers.failure(
        error && error.message ? error.message : 'Something went wrong! Please try again later.',
      ),
    );
  }
}

export default function* () {
  yield all([
    takeEvery(GET_ALL_TRANSACTIONS_PAGINATED, getAllTransactionsPaginated$),
    takeEvery(EXPORT_ALL_TRANSACTIONS, exportTransactions$),
    takeLatest(GET_WALLET_TRANSFERS_PAGINATED, getWalletTransfers$),
    takeEvery(EXPORT_WALLET_TRANSFERS, exportWalletTransfers$),
  ]);
}
