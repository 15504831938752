import { connect } from 'react-redux';
import AppliedFiltersChips from './AppliedFiltersChips';
import { asyncGetMerchantsReferralsPaginated } from '../../AffiliateMerchantsTable/state/actions';
import { AppState } from '../../../../../state/initialState';
import { getAppliedFiltersIdsSelector, getAreAnyFiltersApplied } from '../../state/selectors';

const mapDispatchToProps = {
  asyncGetMerchantsReferralsPaginated: asyncGetMerchantsReferralsPaginated.request,
};

const mapStateToProps = (state: AppState) => {
  const appliedFiltersIds = getAppliedFiltersIdsSelector(state);
  const areFiltersApplied = getAreAnyFiltersApplied(state);
  return { appliedFiltersIds, areFiltersApplied };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppliedFiltersChips);
