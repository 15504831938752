import { createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import { Colors } from '../../ui/style/colors';
import { REDIRECT_TO_KYC } from '../../utils/constants/routes';
import CustomButtonPrimary from '../CustomButtons/CustomButtonPrimary';
import LocalStorage from '../../data/LocalStorage';
import { ACCESS_TOKEN } from '../../utils/constants/localStorageKeys';

let token = LocalStorage.get(ACCESS_TOKEN);

setTimeout(()=>{
  const tokenStorage = LocalStorage.get(ACCESS_TOKEN);
  if (tokenStorage) token = tokenStorage
}, 1000)

const style = createStyles({
  button: {
    backgroundColor: `${Colors.havelockBlue}`,
    boxSizing: 'border-box',
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 0.88,
    marginRight: 20,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: `${Colors.havelockBlue}`,
    },
  },
});

const useClasses = makeStyles(style);

type Props = {
  className?: string;
};

const VerifyAccountButton = ({ className }: Props) => {
  const classes = useClasses();
  return (
    <CustomButtonPrimary
      variant="contained"
      className={`${classes.button} ${className}`}
      onClick={() =>
        (window.location.href = `${REDIRECT_TO_KYC}?token=${token}&from_url=${window.location.origin}`)
        }>
      Verify your account
    </CustomButtonPrimary>
  );
};

export default VerifyAccountButton;
