import { AnyAction } from 'redux';
import { all, put, takeEvery } from 'redux-saga/effects';
import LocalStorage from '../../../../../../data/LocalStorage';
import api from '../../../../../../data/repositories/account';
import { USER_ID } from '../../../../../../utils/constants/localStorageKeys';
import {
  getProfileInfoFail,
  getProfileInfoSuccess,
  GET_PROFILE_INFO,
  updatePaymentInfoFail,
  updatePaymentInfoSuccess,
  UPDATE_PAYMENT_INFO,
} from './actions';

export function* getProfileInfo$() {
  try {
    const user_id = LocalStorage.get(USER_ID);

    const response = yield api.getProfileInfo(user_id || '');

    yield put(getProfileInfoSuccess(response));
  } catch (error) {
    yield put(getProfileInfoFail(error));
  }
}

export function* updatePaymentInfo$(action: AnyAction) {
  try {
    const userId = LocalStorage.get(USER_ID);
    const response = yield api.updatePaymentInfo(userId || '', action.payload);

    yield put(updatePaymentInfoSuccess(response));
  } catch (error) {
    yield put(updatePaymentInfoFail(error));
  }
}

export default function* () {
  yield all([
    takeEvery(GET_PROFILE_INFO, getProfileInfo$),
    takeEvery(UPDATE_PAYMENT_INFO, updatePaymentInfo$),
  ]);
}
