import React from 'react';
import { Redirect } from 'react-router';
import 'swiper/swiper-bundle.css';
import LocalStorage from '../../data/LocalStorage';
import { ACCESS_TOKEN } from '../../utils/constants/localStorageKeys';
import './Dashboard.scss';
import DashboardContent from './DashboardContent';
import DashboardFooter from './DashboardFooter';
import DashboardHeader from './DashboardHeader';
import './js/scripts.js';

const Dashboard = (): JSX.Element => {
  const version = process.env.REACT_APP_VERSION;

  if (LocalStorage.get(ACCESS_TOKEN)) {
    return <Redirect to="/dashboard" />;
  }
  return (
    <div className="dashboard">
      <DashboardHeader />
      <input type="hidden" id="slaff_fe_version" value={version} />
      <DashboardContent />
      <DashboardFooter />
    </div>
  );
};

export default Dashboard;
