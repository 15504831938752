import {
  initialState as productsPurchaseHistory,
  ProductsPurchaseHistoryState,
} from '../ProductsPurchaseHistory/state/initialState';
import {
  initialState as transactionGrouping,
  TransactionGroupingState,
} from '../TransactionGrouping/state/initialState';
import {
  initialState as userEarningsTable,
  UserEarningsTableState,
} from '../UserEarningsTable/state/initialState';
import {
  initialState as userTransactionHistory,
  UserTransactionHistoryState,
} from '../UserTransactionHistory/state/initialState';

import {
  initialState as changeUserPassword,
  ChangeUserPasswordState,
} from '../ChangeUserPassword/state/initialState';

export type AdminState = {
  userEarningsTable: UserEarningsTableState;
  productsPurchaseHistory: ProductsPurchaseHistoryState;
  transactionGrouping: TransactionGroupingState;
  userTransactionHistory: UserTransactionHistoryState;
  changeUserPassword: ChangeUserPasswordState;
};

export const initialState: AdminState = {
  userEarningsTable,
  productsPurchaseHistory,
  transactionGrouping,
  userTransactionHistory,
  changeUserPassword,
};
