import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { PayloadAC } from 'typesafe-actions';
import Satelite from '../../../../assets/satelite.svg';
import { ReactComponent as SuccessIconSvg } from '../../../../assets/success@1.5x.svg';
import CustomButtonPrimary from '../../../../components/CustomButtons/CustomButtonPrimary';
import CustomButtonSecondary from '../../../../components/CustomButtons/CustomButtonSecondary';
import { Colors } from '../../../../ui/style/colors';
import { SUPPORT_EMAIL } from '../../../../utils/constants/info';
import SeacrhUserDialog from '../Admin/UserTransactionHistory/SeacrhUserDialog/index';
import ProductDetailsDialog from '../Product/ProductDetailsDialog/index';
import KycDialog from './KycDialog/index';
import KycRedirectionDialog from './KycRedirectionDialog';
import KycRestrictionDialog from './KycRestrictionDialog';
import KycExpiredDocDialog from './KycExpiredDocDialog';
import { InfoDialogState } from './state/initialState';
import SwapSLTtoSLCDialog from './SwapSLTtoSLCDialog/index';
import AffiliatePackageStatusDialog from './AffiliatePackageStatusDialog/AffiliatePackageStatusDialog';

const styles = (theme: Theme) =>
  createStyles({
    dialog: {
      height: 'center',
      width: 'center',
      borderRadius: 6,
      padding: '40px',
      '@media(max-width:480px)': {
        padding: 10,
      },
    },
    dialogTitle: {
      padding: 0,
    },
    dialogTittleText: {
      color: `${Colors.blueCharcoal}`,
      fontSize: 18,
      fontWeight: 600,
      letterSpacing: 1.13,
      display: 'flex',
      alignItems: 'center',
      marginBottom: 30,
    },
    content: {
      minWidth: 300,
      padding: 0,
    },
    closeButton: {
      position: 'absolute',
      right: 5,
      top: 5,
      color: theme.palette.primary.light,
    },
    button: {
      fontSize: 14,
      marginTop: 30,
      textTransform: 'none',
    },
    icon: { marginTop: 29, marginBottom: 24 },
  });
const useStyles = makeStyles(styles);

type Props = {
  isInfoDialogOpen: PayloadAC<'infoDialog/is_info_dialog_open', boolean>;
  infoDialogState: InfoDialogState;
};

const InfoDialog: React.FC<Props> = ({ isInfoDialogOpen, infoDialogState }: Props) => {
  const classes = useStyles();
  const {
    dialogProps,
    dialogType,
    open,
    fail,
    errorMessage,
    successMessage,
    success,
    title,
    hideCloseButton,
    showDialogContent,
  } = infoDialogState;

  const onExit = () => {
    isInfoDialogOpen(false);
  };
  const dialogTypes: any = {
    KycDialog: KycDialog,
    SeacrhUserDialog: SeacrhUserDialog,
    ProductDetailsDialog: ProductDetailsDialog,
    KycRedirectionDialog: KycRedirectionDialog,
    SwapSLTtoSLCDialog: SwapSLTtoSLCDialog,
    KycRestrictionDialog: KycRestrictionDialog,
    KycExpiredDocDialog: KycExpiredDocDialog,
    AffiliatePackageStatusDialog: AffiliatePackageStatusDialog,
  };
  const ActiveModal = dialogTypes[dialogType];

  return (
    <Dialog
      maxWidth={dialogProps && dialogProps.maxWidth ? dialogProps.maxWidth : 'sm'}
      open={open}
      classes={{ paper: classes.dialog }}
      className={dialogProps && dialogProps.className ? dialogProps.className : ''}
    >
      <DialogTitle disableTypography className={classes.dialogTitle}>
        {!hideCloseButton && (
          <IconButton className={classes.closeButton} onClick={onExit}>
            <CloseIcon />
          </IconButton>
        )}

        <Grid
          container
          direction="column"
          item
          xs={12}
          alignItems="center"
          className={dialogProps && dialogProps.dialogTittleWrapperClass}
        >
          {fail && !showDialogContent && (
            <img className={classes.icon} src={Satelite} alt="ilustration" />
          )}
          {success && <SuccessIconSvg className={classes.icon} />}
          <Typography className={classes.dialogTittleText}>
            {fail
              ? errorMessage
                ? errorMessage
                : 'Something went wrong!'
              : success
              ? successMessage
                ? successMessage
                : 'Success!'
              : title}
          </Typography>
        </Grid>
      </DialogTitle>
      {showDialogContent && (
        <DialogContent classes={{ root: classes.content }}>
          {fail ? (
            <Grid container direction="column" item xs={12} alignItems="center">
              Please, contact&nbsp;
              <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
              <CustomButtonPrimary
                variant="contained"
                className={classes.button}
                fullWidth
                onClick={() => window.location.reload(false)}
              >
                Try again
              </CustomButtonPrimary>
              <CustomButtonSecondary
                variant="contained"
                className={classes.button}
                fullWidth
                onClick={() => {
                  localStorage.clear();
                  window.location.href =
                  `${process.env.REACT_APP_IAM_ROUTE}/logout?from_url=${window.location.origin}`;
                }}
              >
                Logout
              </CustomButtonSecondary>
              {}
            </Grid>
          ) : (
            !success &&
            ActiveModal && <ActiveModal {...dialogProps} infoDialogState={infoDialogState} />
          )}
        </DialogContent>
      )}
    </Dialog>
  );
};

export default InfoDialog;
