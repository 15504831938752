import { combineReducers } from 'redux';
import admin from '../HomeContent/Admin/state/reducers';
import affiliate from '../HomeContent/Affiliate/state/reducer';
import earnings from '../HomeContent/Earnings/state/reducers';
import orders from '../HomeContent/Orders/state/reducers';
import exchange from '../HomeContent/Exchange/state/reducer';
import settings from '../HomeContent/Settings/state/reducer';
import transactions from '../HomeContent/Transactions/state/reducers';
import wallets from '../HomeContent/Wallet/state/reducers';
import product from '../HomeContent/Product/state/reducers';
import common from './common/reducers';
import infoDialog from '../HomeContent/InfoDialog/state/reducers';
import dashboard from '../HomeContent/Dashboard/state/reducers';
import drawer from '../HomeDrawer/state/reducers';
import invites from '../HomeContent/Invites/state/reducers';

export default combineReducers({
  common,
  wallets,
  settings,
  affiliate,
  transactions,
  exchange,
  earnings,
  orders,
  product,
  infoDialog,
  admin,
  dashboard,
  drawer,
  invites,
});
