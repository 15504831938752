import { Tab, Tabs } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { Colors } from '../../../../../ui/style/colors';
import WalletDrawerItem from './WalletDrawerItem';

type StyleProps = {
  walletsToDisplay: number;
  tabMarginBottom: number;
  tabBorderWidth: number;
};

const styles = (theme: Theme) =>
  createStyles({
    rootTab: {
      padding: '10px 20px',
      minWidth: '100%',
      marginBottom: (props: StyleProps) => props.tabMarginBottom,
      background: `${Colors.white}`,
      borderRadius: '3px',
      boxShadow: '0 1px 5px 0 rgba(132,141,182,0.25)',
      transition: 'all 0.3s',
      border: (props: StyleProps) => `${props.tabBorderWidth}px solid transparent`,
      '&:hover:not(.Mui-selected):not(:disabled)': {
        boxShadow: '0 10px 30px 0 rgba(132,141,182,0.25)',
        transform: 'translateY(-2px)',
      },
    },
    wrapperTab: {
      height: (props: StyleProps) =>
        `calc(100% / ${props.walletsToDisplay} - ${props.tabMarginBottom}px)`,
      '&:first-child': {
        marginTop: (props: StyleProps) => props.tabBorderWidth,
      },
      '&:last-child': {
        marginBottom: 0,
        maxWidth: '30%',
      },
      [theme.breakpoints.down('sm')]: {
        margin: 5,
        flex: '1 0 25%',
        minWidth: 'auto',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        height: 'auto!important',
        padding: '10px 5px',
        '&:last-child': {
          marginBottom: 5,
        },
      },
    },
    selectedTab: {
      boxShadow: '0 10px 30px 0 rgba(132,141,182,0.25)',
      transform: 'translateY(-2px)',
      border: (props: StyleProps) => `${props.tabBorderWidth}px solid ${Colors.mediumTurquoise}`,
      cursor: 'default',
    },
    flexContainer: {
      height: '100%',
      marginLeft: 15,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        margin: 0,
      },
    },
    scrollable: {
      [theme.breakpoints.down('sm')]: {
        margin: '24px!important',
      },
    },
    tabs: {
      height: '100%',
    },
    tabIndicator: {
      height: (props: StyleProps) =>
        `calc(100% / ${props.walletsToDisplay} - ${props.tabMarginBottom})`,
      left: 0,
      transition: 'all 0.2s',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    wrapper: {
      alignItems: 'flex-start',
    },
  });

const useStyles = makeStyles(styles);

type Props = {
  wallets?: any;
  handleChange: any;
  value: any;
};

const WalletDrawer = ({ wallets, handleChange, value }: Props) => {
  const styleProps = { walletsToDisplay: 6, tabMarginBottom: 5, tabBorderWidth: 3 };
  const classes = useStyles(styleProps);

  function additionalProps(index: any) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }
  return (
    <Tabs
      classes={{
        flexContainer: classes.flexContainer,
        indicator: classes.tabIndicator,
        scrollable: classes.scrollable,
      }}
      orientation="vertical"
      variant="scrollable"
      value={value}
      onChange={handleChange}
      aria-label="Vertical tabs example"
      className={classes.tabs}
    >
      {wallets.map((wallet: any, index: any) => {
        return (
          <Tab
            classes={{
              wrapper: classes.wrapper,
              root: classes.rootTab,
              wrapped: classes.wrapperTab,
              selected: classes.selectedTab,
            }}
            disableRipple
            wrapped={true}
            label={<WalletDrawerItem wallet={wallet} />}
            key={wallet.symbol}
            style={{ pointerEvents: 'auto' }}
            {...additionalProps(index)}
          />
        );
      })}
    </Tabs>
  );
};

export default WalletDrawer;
