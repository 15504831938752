import { find } from 'lodash';
import moment from 'moment';
import { all, put, takeEvery } from 'redux-saga/effects';
import LocalStorage from '../../../../../data/LocalStorage';
import apiAccount from '../../../../../data/repositories/account';
import dashboardApi from '../../../../../data/repositories/dashboard';
import {
  ChartData,
  CryptoNonFormatedResponse,
  CryptoNonFormatedResponseFallback,
  exchangeRateHistoryResponse,
  GetCryptoChartRequest,
} from '../../../../../data/repositories/dashboard/typedefs';
import api from '../../../../../data/repositories/wallet';
import {
  Coin,
  GetAllCoinsResponse,
  GetWalletBalanceResponse,
  Wallet,
} from '../../../../../data/repositories/wallet/typedefs';
import chartUtils from '../../../../../utils/chartUtils';
import { GET_WALLET_ENDPOINT } from '../../../../../utils/constants/api';
import { USER_ID } from '../../../../../utils/constants/localStorageKeys';
import { getUserAccount } from '../../../../state/user/actions';
import {
  getCoinPricesFail,
  getCoinPricesSuccess,
  GET_COIN_PRICES,
  setCoinPricesInProgress,
} from '../../../state/common/actions';
import { getProfileInfoFail, getProfileInfoSuccess } from '../../Settings/Membership/state/actions';
import {
  asyncDepositTermSaving,
  asyncGetCryptoChartData,
  asyncGetEntiretWalletBalances,
  asyncGetTableChart,
  asyncGetTermSavingTypes,
  DEPOSIT_TERM_SAVING,
  getWalletBalanceFail,
  getWalletBalanceSuccess,
  GET_ALL_COINS,
  GET_CRYPTO_CHART_DATA,
  GET_ENTIRE_WALLET_BALANCES,
  GET_TABLE_CHART,
  GET_TERM_SAVING_TYPES,
  GET_WALLET_BALANCE,
  saveAllCoins,
} from './actions';

export function* getWalletBalance$() {
  try {
    const userId = LocalStorage.get(USER_ID);
    const response: GetWalletBalanceResponse = yield api.getWallet(
      GET_WALLET_ENDPOINT(userId ? userId : ''),
    );

    const result = response.reduce(
      (accumulator, wallet) => ({ ...accumulator, [wallet.symbol]: wallet }),
      new Map<string, Wallet>(),
    );
    yield put(getWalletBalanceSuccess(result));
  } catch (error) {
    yield put(getWalletBalanceFail(error));
  }
}

export function* getAllCoins$() {
  try {
    const response: GetAllCoinsResponse = api.allCoins;

    // Remove all coins except ETH, SLC, BTC
    const result = response.reduce(
      (accumulator, coin) => ({ ...accumulator, [coin.symbol]: coin }),
      new Map<string, Coin>(),
    );

    yield put(saveAllCoins(result));
  } catch (error) {}
}

//TODO@all this is the main cause of problems... refactor...
export function* getCoinPrices$() {
  yield put(setCoinPricesInProgress(true));
  try {
    const userId = LocalStorage.get(USER_ID);
    if (userId) {
      const response2 = yield api.getUser(userId);
      yield put(getUserAccount(response2));
    }
    const response = yield api.getCoinPrices();
    yield put(getCoinPricesSuccess(response));
  } catch (error) {
    yield put(getCoinPricesFail(error));
  }
  try {
    const userId = LocalStorage.get(USER_ID);
    const response = yield apiAccount.getProfileInfo(userId || '');

    yield put(getProfileInfoSuccess(response));
  } catch (error) {
    yield put(getProfileInfoFail(error.message));
  }
  yield put(setCoinPricesInProgress(false));
}

export function* getEntireWalletBalance$() {
  try {
    const userId = yield LocalStorage.get(USER_ID);
    if (userId) {
      const response: GetWalletBalanceResponse = yield api.getWallet(
        GET_WALLET_ENDPOINT(userId ? userId : ''),
      );
      const result = response.reduce(
        (accumulator, wallet) => ({ ...accumulator, [wallet.symbol]: wallet }),
        new Map<string, Wallet>(),
      );
      yield put(getWalletBalanceSuccess(result));
      const slcResponse = find(response, ({ symbol }) => symbol === 'SLC');
      const { decimals, name, network, symbol, ...wallet } = slcResponse;
      yield put(
        asyncGetEntiretWalletBalances.success({
          currency: { decimals, name, network, symbol },
          wallet,
        }),
      );
    } else {
      throw new Error('No user id found!');
    }
  } catch (error) {
    yield put(asyncGetEntiretWalletBalances.failure(error));
  }
}

export function* getTermSavingTypes$() {
  try {
    const accountId = yield LocalStorage.get(USER_ID);
    const response = yield api.getTermSavingTypes(accountId);

    yield put(asyncGetTermSavingTypes.success(response));
  } catch (error) {
    yield put(asyncGetTermSavingTypes.failure(error));
  }
}

export function* depositTermSaving$(action: ReturnType<typeof asyncDepositTermSaving.request>) {
  try {
    const accountId = yield LocalStorage.get(USER_ID);
    const { amount, term_saving_type_name, balance_status } = action.payload;

    yield api.depositTermSaving(accountId, {
      balance_status,
      amount,
      term_saving_type_name,
    });
    yield put({ type: GET_ENTIRE_WALLET_BALANCES });
    yield put(asyncDepositTermSaving.success());
  } catch (error) {
    if (error.response.status == 409) {
      yield put(asyncDepositTermSaving.failure('Insufficient funds'));
    } else {
      yield put(asyncDepositTermSaving.failure('Something went wrong'));
    }
  }
}

const formatSLCChartResults = (
  exchangeRateHstory: exchangeRateHistoryResponse[],
  selectedPeriod: GetCryptoChartRequest,
) => {
  /**
   * Get past date from now regarding to selected period filter
   */
  const targetDay = chartUtils.getPastDateFromNow(selectedPeriod);

  /**Get exact date and time for yesterday in order to calculate 24h change
   */
  const dayBefore = chartUtils.getPastDateFromNow('day');

  let dailyChange;
  const SLCResult = exchangeRateHstory.filter((item: any) => {
    if (chartUtils.isInDateRange(dayBefore, item.created_at, item.ended_at)) {
      dailyChange = chartUtils.calculatePercentageIncrease(
        item.exchange_rate,
        exchangeRateHstory[exchangeRateHstory.length - 1].exchange_rate,
      );
    }

    /**Last item in BE response represents current SLC value and doesn't have end date */
    if (!item.ended_at) {
      return true;
    }

    return moment(item.created_at) > targetDay || moment(item.ended_at) > targetDay;
  });
  if (SLCResult.length === 1 && selectedPeriod === 'week') {
    const lastSLCRate = SLCResult[SLCResult.length - 1];

    /**Add today SLC rate value */
    SLCResult.push({
      created_at: String(new Date()),
      ended_at: String(new Date()),
      exchange_rate: lastSLCRate.exchange_rate,
      currency: lastSLCRate.currency,
      symbol: lastSLCRate.symbol,
    });
  }

  /**Format prices and dates for Chart */
  const prices: any = {};
  Object.entries(SLCResult).map(([, coin]) => (prices[coin.created_at] = coin.exchange_rate));

  return { prices, dailyChange };
};

export function* getCryptoChartData$(action: ReturnType<typeof asyncGetCryptoChartData.request>) {
  try {
    const accountId = yield LocalStorage.get(USER_ID);
    const selectedPeriod = action.payload;
    const response: CryptoNonFormatedResponse[] = yield dashboardApi.getCryptoChartData(
      accountId,
      selectedPeriod,
    );

    const result: { [coin: string]: ChartData } = {};

    Object.entries(response).map(([key, coin]) => {
      const symbolName = chartUtils.getCoinSymbolName(key);
      if (!symbolName) {
        throw Error('Invalid coin');
      }
      result[symbolName] = chartUtils.formatData(coin, selectedPeriod);
    });

    /**Get SLC exchange rate history */
    const exchangeRateHstory: exchangeRateHistoryResponse[] =
      yield dashboardApi.exchangeRateHistory(accountId);

    const { prices, dailyChange } = formatSLCChartResults(
      exchangeRateHstory.reverse(),
      selectedPeriod,
    );

    /**Add SLC to result object in order to save state */
    result['SLC'] = {
      prices: chartUtils.formatChartValues(prices, selectedPeriod, false),
      dailyChange,
      volume: '',
      marketCap: '',
    };

    yield put(asyncGetCryptoChartData.success({ data: result, period: selectedPeriod }));
  } catch (error) {
    yield put(asyncGetCryptoChartData.failure(error));
  }
}

export function* getTableChart$(action: ReturnType<typeof asyncGetTableChart.request>) {
  const accountId = yield LocalStorage.get(USER_ID);
  const selectedPeriod = action.payload;
  try {
    const response: CryptoNonFormatedResponse[] = yield dashboardApi.getCryptoChartData(
      accountId,
      selectedPeriod,
    );

    const result: { [coin: string]: ChartData } = {};

    Object.entries(response).map(([key, coin]) => {
      const symbolName = chartUtils.getCoinSymbolName(key);
      if (!symbolName) {
        throw Error('Invalid coin');
      }
      result[symbolName] = chartUtils.formatData(coin, selectedPeriod, true);
    });

    const exchangeRateHstory: exchangeRateHistoryResponse[] =
      yield dashboardApi.exchangeRateHistory(accountId);
    const { prices, dailyChange } = formatSLCChartResults(
      exchangeRateHstory.reverse(),
      selectedPeriod,
    );

    result['SLC'] = {
      prices: chartUtils.formatChartValues(prices, selectedPeriod, false),
      dailyChange,
      volume: '',
      marketCap: '',
    };

    yield put(
      asyncGetTableChart.success({
        responseData: result,
        responsePeriod: selectedPeriod,
        isFallback: false,
      }),
    );
  } catch (error) {
    try {
      const response: CryptoNonFormatedResponseFallback[] =
        yield dashboardApi.getCryptoChartDataFallback(accountId);
      const result: { [coin: string]: any } = {};

      Object.entries(response).map(([key, coin]) => {
        const symbolName = chartUtils.getCoinSymbolName(key);
        if (!symbolName) {
          throw Error('Invalid coin');
        }
        result[symbolName] = chartUtils.formatFallbackData(coin);
      });

      const exchangeRateHstory: exchangeRateHistoryResponse[] =
        yield dashboardApi.exchangeRateHistory(accountId);
      const { prices, dailyChange } = formatSLCChartResults(
        exchangeRateHstory.reverse(),
        selectedPeriod,
      );

      result['SLC'] = {
        prices: chartUtils.formatChartValues(prices, selectedPeriod, false),
        dailyChange,
        volume: '',
        marketCap: '',
      };

      yield put(
        asyncGetTableChart.success({
          responseData: result,
          responsePeriod: selectedPeriod,
          isFallback: true,
        }),
      );
    } catch (error) {
      yield put(asyncGetTableChart.failure(error));
    }
  }
}

export default function* () {
  yield all([
    takeEvery(GET_WALLET_BALANCE, getWalletBalance$),
    takeEvery(GET_ALL_COINS, getAllCoins$),
    takeEvery(GET_COIN_PRICES, getCoinPrices$),
    takeEvery(GET_ENTIRE_WALLET_BALANCES, getEntireWalletBalance$),
    takeEvery(GET_TERM_SAVING_TYPES, getTermSavingTypes$),
    takeEvery(DEPOSIT_TERM_SAVING, depositTermSaving$),
    takeEvery(GET_CRYPTO_CHART_DATA, getCryptoChartData$),
    takeEvery(GET_TABLE_CHART, getTableChart$),
  ]);
}
