/* eslint-disable */
import { Button, CircularProgress, DialogContent, Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace';
import Big from 'big.js';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { EmptyAC, PayloadAC } from 'typesafe-actions';
import CustomButtonPrimary from '../../../../../../components/CustomButtons/CustomButtonPrimary';
import {
  CreateRevolutCounterpartyData,
  Wallet,
  WithdrawCurrencyRequest,
} from '../../../../../../data/repositories/wallet/typedefs';
import { Colors } from '../../../../../../ui/style/colors';
import balanceUtils from '../../../../../../utils/balanceUtils';
import { getCurrencySymbolName, jwtDecode } from '../../../../../../utils/helper/utils';
import { EURWithdrawAddressType } from '../../../../state/common/initialState';
import { CREATE_REVOLUT_COUNTERPARTY } from '../../state/actions';

const styles = (theme: Theme) =>
  createStyles({
    confirmLabel: {
      color: theme.palette.primary.light,
      fontSize: 16,
      fontWeight: 600,
      textAlign: 'center',
    },
    ammount: {
      color: `${Colors.blueCharcoal}`,
      fontSize: 28,
      fontWeight: 600,
      textAlign: 'center',
      marginTop: 20,
      marginBottom: 8,
    },
    button: {
      fontSize: 14,
      marginTop: 30,
      marginBottom: 20,
      textTransform: 'none',
    },
    currencyAddress: {
      color: `${Colors.blueCharcoal}`,
      fontSize: 16,
      fontWeight: 600,
      marginTop: 8,
      textAlign: 'center',
    },
    backButton: {
      background: 'none',
      border: 'none',
      marginTop: 30,
      marginBottom: 30,
      fontSize: 14,
      textTransform: 'none',
    },
    circularProgress: {
      marginTop: 30,
      marginBottom: 30,
      display: 'flex',
      justifyContent: 'center',
    },
    error: {
      color: `${Colors.red}`,
      fontSize: 14,
      marginTop: 10,
      textAlign: 'center',
    },
    link: {
      fontSize: 14,
      display: 'flex',
      justifyContent: 'center',
      marginTop: 10,
      marginBottom: 10,
    },
    feeInfo: {
      fontSize: 18,
      fontWeight: 600,
      color: `${Colors.mediumTurquoise}`,
      margin: 0,
      lineHeight: 1.3,
      textAlign: 'center',
    },
  });
const useStyles = makeStyles(styles);

type Props = {
  wallet?: Wallet;
  selectedCoin: string;
  withdrawCurrency: PayloadAC<'wallet/withdraw_currency', WithdrawCurrencyRequest>;
  amount: string;
  address: string | EURWithdrawAddressType;
  openWithdrawDialog: PayloadAC<'wallet/open_withdraw_dialog', number>;
  isTwoFactorAuthEnabled: EmptyAC<'settings/is_two_factor_auth_enabled'>;
  clearWithdrawState: EmptyAC<'wallet/clear_withdraw_state'>;
  step: number;
  enabled: boolean | undefined;
  withdrawError: Error | null;
  withdrawInProgress: boolean;
  asyncCreateRevolutCounterparty: PayloadAC<
    typeof CREATE_REVOLUT_COUNTERPARTY,
    CreateRevolutCounterpartyData
  >;
};

const WithdrawCoinsConfirmDialog = ({
  wallet,
  selectedCoin,
  withdrawCurrency,
  amount,
  address,
  step,
  openWithdrawDialog,
  isTwoFactorAuthEnabled,
  clearWithdrawState,
  enabled,
  withdrawInProgress,
  withdrawError,
  asyncCreateRevolutCounterparty,
}: Props): JSX.Element => {
  const classes = useStyles();
  const { first_name, last_name }: any = jwtDecode(localStorage.getItem('iam_access_token'));

  useEffect(() => {
    isTwoFactorAuthEnabled();
    clearWithdrawState();
  }, []);

  const handleConfirmWithdrawCurrency = () => {
    if (enabled) openWithdrawDialog(3);
    else {
      const amountToWithdraw = Number(amount) * Math.pow(10, wallet ? wallet.decimals : 0);

      if (typeof address !== 'string') {
        asyncCreateRevolutCounterparty({
          amount: amountToWithdraw,
          ...address,
          individual_name: {
            first_name,
            last_name,
          },
        });
      } else {
        withdrawCurrency({ address: address, amount: amountToWithdraw });
      }
      openWithdrawDialog(4);
    }
  };
  const walletBalanceBig = Big(
    wallet
      ? wallet.balance
        ? balanceUtils.formatBalanceToBigWithDecimals(wallet.balance, wallet.decimals)
        : '0'
      : '0',
  );
  const walletBalanceString = balanceUtils.formatBigToStringWithCommasAndRound(
    walletBalanceBig,
    2,
    4,
  );
  const amountToWithdrawBig = Big(amount !== '' ? amount : '0');
  const isWithdrawAllowed =
    amountToWithdrawBig.gt(0) && Big(walletBalanceString).gte(amountToWithdrawBig);

  const handleBack = () => {
    openWithdrawDialog(step - 1);
  };

  const handleButtonDisabled = () => {
    if (withdrawError) {
      return JSON.stringify(withdrawError).includes('Two factor');
    }
  };

  const billingFields = ['iban', 'bic', 'ifsc', 'sort_code', 'routing_number', 'clabe', 'bsb_code'];

  const getBillingFieldName = (field: string) => {
    if (field === 'bic') {
      return 'BIC/SWIFT';
    }
    return field.replace('_', ' ').toUpperCase();
  };

  const getAddress = () => {
    if (typeof address !== 'string') {
      return Object.entries(address).map(
        ([key, value]) =>
          billingFields.includes(key) &&
          value !== '' && (
            <Grid key={`unique-key${key}`} container spacing={2} justifyContent="space-between">
              <Grid item className={classes.confirmLabel}>
                {getBillingFieldName(key)}:
              </Grid>
              <Grid item>{value}</Grid>
            </Grid>
          ),
      );
    } else {
      return address;
    }
  };

  return (
    <DialogContent>
      <Typography className={classes.confirmLabel}>Please confirm that you want to send</Typography>
      <Typography className={classes.ammount}>
        {selectedCoin === 'WALLET_EUR' ? Number(amount) + 5 : amount}{' '}
        {getCurrencySymbolName(selectedCoin)}
      </Typography>
      {selectedCoin === 'WALLET_EUR' && (
        <Typography className={classes.feeInfo}>5 EUR fee is included</Typography>
      )}
      <Typography className={classes.confirmLabel}>to</Typography>
      <Typography variant="h6" className={classes.currencyAddress}>
        {getAddress()}
      </Typography>

      {withdrawError != null ? <div className={classes.error}>{withdrawError}</div> : ''}
      {handleButtonDisabled() ? (
        <Link
          className={classes.link}
          to={{
            pathname: '/settings',
            state: {
              wallet: wallet,
            },
            search: '?auth=true',
          }}
        >
          Click here to enable
        </Link>
      ) : null}

      {withdrawInProgress ? (
        <Grid container className={classes.circularProgress} alignContent="center">
          <CircularProgress color="secondary" size={40} />
        </Grid>
      ) : (
        <Grid container alignContent="center" spacing={2}>
          <Grid item xs={3}>
            <Button size="large" className={classes.backButton} fullWidth onClick={handleBack}>
              <KeyboardBackspace />
              Back
            </Button>
          </Grid>
          <Grid item xs={9}>
            <CustomButtonPrimary
              variant="contained"
              size="large"
              className={classes.button}
              fullWidth
              onClick={handleConfirmWithdrawCurrency}
              disabled={handleButtonDisabled()}
            >
              Confirm
            </CustomButtonPrimary>
          </Grid>
        </Grid>
      )}
    </DialogContent>
  );
};

export default WithdrawCoinsConfirmDialog;
