import { connect } from 'react-redux';
import { AppState } from '../../../../state/initialState';
import InvestCurrency from './InvestCurrency';
import { setSelectedTab, investCurrencySaveState } from './state/actions';
import {
  shouldDisableAllCurrencies,
  getInvestCurrencySelector,
  membershipStateSelector,
} from './state/selectors';

const mapDispatchToProps = {
  setSelectedTab: setSelectedTab,
  onInvestCurrencyStep: investCurrencySaveState,
};

const mapStateToProps = (state: AppState) => {
  const investCurrency = getInvestCurrencySelector(state);
  const membershipLevel = membershipStateSelector(state);
  const disabledAllCurrencies = shouldDisableAllCurrencies(state);
  return { membershipLevel, disabledAllCurrencies, ...investCurrency };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvestCurrency);
