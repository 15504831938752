/* eslint-disable */
import { createAsyncAction } from 'typesafe-actions';
import { FeeSettingsState, StoreItem, UpdateFeeRateRequest } from './initialState';

export const GET_STORE_ITEMS = 'store_items';
export const GET_STORE_ITEMS_SUCCESS = `${GET_STORE_ITEMS}/success`;
export const GET_STORE_ITEMS_FAIL = `${GET_STORE_ITEMS}/fail`;

export const UPDATE_FEE_RATE = 'update_fee_rate';
export const UPDATE_FEE_RATE_SUCCESS = `${UPDATE_FEE_RATE}/success`;
export const UPDATE_FEE_RATE_FAIL = `${UPDATE_FEE_RATE}/fail`;
export const UPDATE_FEE_RATE_CLEAR_STATE = `${UPDATE_FEE_RATE}/clear_state`;

export const asyncGetStoreItems = createAsyncAction(
  GET_STORE_ITEMS,
  GET_STORE_ITEMS_SUCCESS,
  GET_STORE_ITEMS_FAIL,
)<StoreItem[], StoreItem[], Error>();

export const asyncUpdateFeeRate = createAsyncAction(
  UPDATE_FEE_RATE,
  UPDATE_FEE_RATE_SUCCESS,
  UPDATE_FEE_RATE_CLEAR_STATE,
  UPDATE_FEE_RATE_FAIL,
)<UpdateFeeRateRequest, StoreItem[], FeeSettingsState, Error>();
