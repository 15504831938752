/* eslint-disable */

import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import React from 'react';
import SVG from 'react-inlinesvg';
import { connect, useDispatch, useSelector } from 'react-redux';
import { PayloadAC } from 'typesafe-actions';
import { SET_INFO_DIALOG_FIELDS } from '../../App/Home/HomeContent/InfoDialog/state/actions';
import { ProfileInfoState } from '../../App/Home/HomeContent/Settings/Membership/state/initialState';
import {
  isRestrictedCoinWithoutKYC,
  profileInfoStateSelector,
} from '../../App/Home/HomeContent/Settings/state/selectors';
import DepositModal from '../../App/Home/HomeContent/Wallet/DepositModal';
import {
  CLEAR_DEPOSIT_MODAL_STATE,
  createWalletForCurrency,
  SET_DEPOSIT_MODAL_OPEN,
} from '../../App/Home/HomeContent/Wallet/state/actions';
import { depositModalStateSelector } from '../../App/Home/HomeContent/Wallet/state/selectors';
import { AppState } from '../../App/state/initialState';
import deposit from '../../assets/depositIcon.svg';
import { CreateWalletForCurrencyRequest } from '../../data/repositories/wallet/typedefs';
import { Colors } from '../../ui/style/colors';
import CustomButtonPrimary from '../CustomButtons/CustomButtonPrimary';

const styles = (theme: Theme) =>
  createStyles({
    depositButton: {
      textTransform: 'none',
      fontSize: 14,
      marginTop: 30,
      height: 48,
      fontWeight: 600,
    },
    depositIcon: {
      marginRight: '17px',
    },
  });
const useStyles = makeStyles(styles);

type Props = {
  createWalletForCurrency: PayloadAC<
    'wallet/create_wallet_for_currency',
    CreateWalletForCurrencyRequest
  >;
  symbol?: string;
  profileInfo: ProfileInfoState;
};

const DepositButtonWithModal: React.FC<Props> = ({
  createWalletForCurrency,
  symbol,
  profileInfo: { affiliate_package_info },
}: Props): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const isRestricted =
    symbol && useSelector((state: AppState) => isRestrictedCoinWithoutKYC(symbol)(state));

  const handleClickOpen = () => {
    // For users without membership and KYC, notify them to complete KYC to deposit EUR
    if (symbol && !affiliate_package_info && isRestricted) {
      dispatch({
        type: SET_INFO_DIALOG_FIELDS,
        payload: {
          open: true,
          dialogType: 'KycRestrictionDialog',
        },
      });
    } else {
      dispatch({ type: SET_DEPOSIT_MODAL_OPEN, payload: true });
    }
  };
  const state = useSelector((state: AppState) => depositModalStateSelector(state));

  const handleClose = () => {
    dispatch({ type: CLEAR_DEPOSIT_MODAL_STATE });
  };

  return (
    <div>
      <CustomButtonPrimary
        variant="contained"
        size="large"
        className={classes.depositButton}
        onClick={() => handleClickOpen()}
      >
        <SVG
          src={deposit}
          preProcessor={code => code.replace(/fill=".*?"/g, `fill=${Colors.white}`)}
          className={classes.depositIcon}
        />
        Deposit {symbol && symbol === 'WALLET_EUR' ? 'EUR' : 'Currencies'}
      </CustomButtonPrimary>
      <DepositModal
        open={state.open}
        onClose={handleClose}
        createWallet={createWalletForCurrency}
      />
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  const profileInfo = profileInfoStateSelector(state);
  return { profileInfo };
};

const mapDispatchToProps = {
  createWalletForCurrency: createWalletForCurrency,
};
export default connect(mapStateToProps, mapDispatchToProps)(DepositButtonWithModal);
