/* eslint-disable */
import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import {
  CreateRevolutCounterpartyData,
  CreateWalletForCurrencyRequest,
  SaveAllCoinsRequest,
  SaveWalletBalanceRequest,
  Wallet,
  WithdrawCurrencyRequest,
  WithdrawCurrencyResponse,
  WithdrawEurRequest,
  WithdrawEurResponse,
} from '../../../../../data/repositories/wallet/typedefs';
import {
  BuyWalletModalState,
  CreateDepositOrderRequest,
  CreateDepositOrderResponse,
  DepositTermRequest,
  termPackageObject,
} from './initialState';

export const GET_WALLET_BALANCE = 'wallet/get_wallet_balance';
export const GET_WALLET_BALANCE_SUCCESS = 'wallet/get_wallet_balance_success';
export const GET_WALLET_BALANCE_FAIL = 'wallet/get_wallet_balance_fail';
export const CREATE_WALLET_FOR_CURRENCY = 'wallet/create_wallet_for_currency';
export const WITHDRAW_CURRENCY = 'wallet/withdraw_currency';
export const WITHDRAW_CURRENCY_SUCCESS = 'wallet/withdraw_currency_success';
export const WITHDRAW_CURRENCY_FAIL = 'wallet/withdraw_currency_fail';
export const SAVE_WITHDRAW_ADDRESS = 'wallet/save_withdraw_address';
export const SAVE_WITHDRAW_AMOUNT = 'wallet/save_withdraw_amount';
export const GET_TRANSFER = 'wallet/get_transfer';
export const CLEAR_WITHDRAW_STATE = 'wallet/clear_withdraw_state';
export const IS_WITHDRAW_DIALOG_OPEN = 'wallet/is_withdraw_dialog_open';
export const IS_INVEST_DIALOG_OPEN = 'wallet/is_invest_dialog_open';
export const GET_ENTIRE_WALLET_BALANCES = 'walllet/get_entire_wallet_ballances';
export const GET_ENTIRE_WALLET_BALANCES_SUCCESS = 'walllet/get_entire_wallet_ballances_success';
export const GET_ENTIRE_WALLET_BALANCES_FAIL = 'walllet/get_entire_wallet_ballances_fail';
export const GET_TERM_SAVING_TYPES = 'account/get_term_saving_types';
export const GET_TERM_SAVING_TYPES_SUCCESS = 'account/get_term_saving_types_success';
export const GET_TERM_SAVING_TYPES_FAIL = 'account/get_term_saving_types_fail';
export const DEPOSIT_TERM_SAVING = 'account/deposit_term_saving';
export const DEPOSIT_TERM_SAVING_SUCCESS = 'account/deposit_term_saving_success';
export const DEPOSIT_TERM_SAVING_FAIL = 'account/deposit_term_saving_fail';
export const SET_TERM_SAVING_PACKAGE = 'account/set_term_saving_package';
export const WIRE_DEPOSIT_EUR = 'wallet/wire_deposit_euro';
export const WIRE_DEPOSIT_EUR_SUCCESS = 'wallet/wire_deposit_euro_success';
export const WIRE_DEPOSIT_EUR_FAIL = 'wallet/wire_deposit_euro_fail';
export const SET_DEPOSIT_MODAL_DATA = 'wallet/set_deposit_modal_data';
export const SET_DEPOSIT_MODAL_STEP = 'wallet/set_deposit_modal_step';
export const CLEAR_DEPOSIT_MODAL_STATE = 'wallet/clear_deposit_modal_state';
export const SET_DEPOSIT_MODAL_OPEN = 'wallet/set_deposit_modal_open';

// export const GET_COIN_PRICES = 'wallet/get_coin_prices';
// export const GET_COIN_PRICES_SUCCESS = 'wallet/get_coin_prices_success';
// export const GET_COIN_PRICES_FAIL = 'wallet/get_coin_prices_fail';

export const SAVE_WALLET_BALANCE = 'wallet/save_wallet_balance';

export const GET_ALL_COINS = 'wallet/get_all_coins';
export const SAVE_ALL_COINS = 'wallet/save_all_coins';

export const OPEN_WITHDRAW_DIALOG = 'wallet/open_withdraw_dialog';

export const GET_REVOLUT_COUNTERPARTY = 'wallet/get_revolut_counterparty';
export const GET_REVOLUT_COUNTERPARTY_SUCCESS = 'wallet/get_revolut_counterparty_success';
export const GET_REVOLUT_COUNTERPARTY_FAIL = 'wallet/get_revolut_counterparty_fail';

export const CREATE_REVOLUT_COUNTERPARTY = 'wallet/create_revolut_counterparty';
export const CREATE_REVOLUT_COUNTERPARTY_SUCCESS = 'wallet/create_revolut_counterparty_success';
export const CREATE_REVOLUT_COUNTERPARTY_FAIL = 'wallet/create_revolut_counterparty_fail';

export const WITHDRAW_EUR = 'wallet/withdraw_eur';
export const WITHDRAW_EUR_SUCCESS = 'wallet/withdraw_eur_success';
export const WITHDRAW_EUR_FAIL = 'wallet/withdraw_eur_fail';

export const OPEN_BUY_SELL_MODAL = 'wallet/open_buy_sell_modal';
export const IS_BUY_SELL_MODAL_OPEN = 'wallet/is_buy_sell_modal_open';
export const CLEAR_BUY_SELL_MODAL_STATE = 'wallet/clear_buy_sell_modal_state';

export const BUY_WALLET = 'wallet/buy_wallet';
export const BUY_WALLET_SUCCESS = 'wallet/buy_wallet_success';
export const BUY_WALLET_FAIL = 'wallet/buy_wallet_fail';

export const OPEN_BUY_WALLET_MODAL = 'wallet/open_buy_wallet_modal';
export const CLOSE_BUY_WALLET_MODAL = 'wallet/close_buy_wallet_modal';

export const getWalletBalance = createStandardAction(GET_WALLET_BALANCE)<undefined>();
export const getWalletBalanceSuccess = createStandardAction(
  GET_WALLET_BALANCE_SUCCESS,
)<SaveWalletBalanceRequest>();
export const getWalletBalanceFail = createStandardAction(GET_WALLET_BALANCE_FAIL)<Error>();
export const saveWalletBalance =
  createStandardAction(SAVE_WALLET_BALANCE)<SaveWalletBalanceRequest>();

export const createWalletForCurrency = createStandardAction(
  CREATE_WALLET_FOR_CURRENCY,
)<CreateWalletForCurrencyRequest>();

export const SAVE_WALLET_FOR_CURRENCY = 'wallet/save_wallet_for_currency';

export const saveWalletForCurrency = createStandardAction(SAVE_WALLET_FOR_CURRENCY)<Wallet>();

export const getAllCoins = createStandardAction(GET_ALL_COINS)<undefined>();
export const saveAllCoins = createStandardAction(SAVE_ALL_COINS)<SaveAllCoinsRequest>();

export const withdrawCurrency = createStandardAction(WITHDRAW_CURRENCY)<WithdrawCurrencyRequest>();
export const withdrawCurrencySuccess =
  createStandardAction(WITHDRAW_CURRENCY_SUCCESS)<WithdrawCurrencyResponse>();
export const withdrawCurrencyFail = createStandardAction(WITHDRAW_CURRENCY_FAIL)<Error>();

export const saveWithdrawAddress = createStandardAction(SAVE_WITHDRAW_ADDRESS)<string>();
export const saveWithdrawAmount = createStandardAction(SAVE_WITHDRAW_AMOUNT)<number>();

export const openWithdrawDialog = createStandardAction(OPEN_WITHDRAW_DIALOG)<number>();
export const clearWithdrawState = createStandardAction(CLEAR_WITHDRAW_STATE)<undefined>();
export const isWithdrawDialogOpen = createStandardAction(IS_WITHDRAW_DIALOG_OPEN)<boolean>();

export const openBuySellModal = createStandardAction(OPEN_BUY_SELL_MODAL)<boolean>();
export const clearBuySellModalState = createStandardAction(CLEAR_BUY_SELL_MODAL_STATE)<undefined>();
export const isBuySellModalOpen = createStandardAction(IS_BUY_SELL_MODAL_OPEN)<boolean>();

export const isInvestDialogOpen = createStandardAction(IS_INVEST_DIALOG_OPEN)<boolean>();
export const isDepositModalOpen = createStandardAction(SET_DEPOSIT_MODAL_OPEN)<boolean>();

export const asyncGetEntiretWalletBalances = createAsyncAction(
  GET_ENTIRE_WALLET_BALANCES,
  GET_ENTIRE_WALLET_BALANCES_SUCCESS,
  GET_ENTIRE_WALLET_BALANCES_FAIL,
)<undefined, any, Error>();

export const asyncGetTermSavingTypes = createAsyncAction(
  GET_TERM_SAVING_TYPES,
  GET_TERM_SAVING_TYPES_SUCCESS,
  GET_TERM_SAVING_TYPES_FAIL,
)<undefined, any, Error>();

export const asyncDepositTermSaving = createAsyncAction(
  DEPOSIT_TERM_SAVING,
  DEPOSIT_TERM_SAVING_SUCCESS,
  DEPOSIT_TERM_SAVING_FAIL,
)<DepositTermRequest, undefined, string>();

export const setTermSavingPackage =
  createStandardAction(SET_TERM_SAVING_PACKAGE)<termPackageObject>();

export const SWAP_TO_SLC = 'wallet/swap_to_slc';
export const SWAP_TO_SLC_SUCCESS = 'wallet/swap_to_slc/success';
export const SWAP_TO_SLC_FAIL = 'wallet/swap_to_slc/fail';

export const asyncSwapToSLC = createAsyncAction(SWAP_TO_SLC, SWAP_TO_SLC_SUCCESS, SWAP_TO_SLC_FAIL)<
  undefined,
  undefined,
  Error
>();

export const asyncGetRevolutCounterparty = createAsyncAction(
  GET_REVOLUT_COUNTERPARTY,
  GET_REVOLUT_COUNTERPARTY_SUCCESS,
  GET_REVOLUT_COUNTERPARTY_FAIL,
)<undefined, any, Error>();

export const asyncCreateRevolutCounterparty = createAsyncAction(
  CREATE_REVOLUT_COUNTERPARTY,
  CREATE_REVOLUT_COUNTERPARTY_SUCCESS,
  CREATE_REVOLUT_COUNTERPARTY_FAIL,
)<CreateRevolutCounterpartyData, WithdrawEurRequest, Error>();

export const asyncWithdrawEur = createAsyncAction(
  WITHDRAW_EUR,
  WITHDRAW_EUR_SUCCESS,
  WITHDRAW_EUR_FAIL,
)<WithdrawEurRequest, WithdrawEurResponse, Error>();

export const asyncWireDepositEur = createAsyncAction(
  WIRE_DEPOSIT_EUR,
  WIRE_DEPOSIT_EUR_SUCCESS,
  WIRE_DEPOSIT_EUR_FAIL,
)<CreateDepositOrderRequest, CreateDepositOrderResponse, Error>();

export const asyncBuyWallet = createAsyncAction(BUY_WALLET, BUY_WALLET_SUCCESS, BUY_WALLET_FAIL)<
  BuyWalletModalState,
  undefined,
  Error
>();

export const openBuyWalletModal = createStandardAction(OPEN_BUY_WALLET_MODAL)<boolean>();

export const closeBuyWalletModal = createStandardAction(CLOSE_BUY_WALLET_MODAL)<boolean>();
