/* eslint-disable @typescript-eslint/indent */
import { CircularProgress } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { size } from 'lodash';
import React, { useEffect } from 'react';
import { EmptyAC, PayloadAC } from 'typesafe-actions';
import Satelite from '../../../../../../assets/satelite.svg';
import CustomButtonPrimary from '../../../../../../components/CustomButtons/CustomButtonPrimary';
import SacretEmpty from '../../../../../../components/SacretEmpty';
import SacretError from '../../../../../../components/SacretError';
import WalletTransfersTable from '../../../../../../components/WalletTransfersTable';
import {
  ExportUserTransactionHistoryRequest,
  Filter,
  GetPaginatedUserTransactionHistoryRequest,
  HeaderProps,
  SelectedUser,
  WalletTransfer,
  WalletTransfersState,
} from '../../../../../../data/repositories/admin/typedefs';
import {
  EXPORT_WALLET_TRANSFERS,
  GET_WALLET_TRANSFERS_PAGINATED,
  SET_WALLET_TRANSFERS_TABLE,
  USER_TRANSACTION_HISTORY_CLEAR_ALL_FILTERS,
} from '../state/actions';
import { selectedUserInitialState } from '../state/initialState';
import WalletTransfersFilterBar from './WalletTransfersFilterBar';

const styles = (theme: Theme) =>
  createStyles({
    root: { width: '100%', minHeight: 530, position: 'relative' },
    center: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      marginTop: 0,
    },
    exportWrapper: {
      position: 'absolute',
      right: 0,
      top: -52,
      [theme.breakpoints.down('sm')]: {
        position: 'initial',
        marginBottom: 20,
      },
    },
    exportButton: {
      marginLeft: '10px',
    },
    exportLoaderWrappers: {
      width: '230px',
      height: '36px',
      display: 'inline-block',
      verticalAlign: 'middle',
      textAlign: 'center',
    },
  });

const useStyles = makeStyles(styles);

const headers: HeaderProps[] = [
  { name: '', align: 'center' },
  { name: 'Status', align: 'left' },
  { name: 'Transfer type', align: 'left' },
  { name: 'Date & Time', align: 'right' },
  { name: 'Currency', align: 'left' },
  { name: 'Amount', align: 'right' },
];

type Props = WalletTransfersState & {
  getWalletTransfersPaginated: PayloadAC<
    typeof GET_WALLET_TRANSFERS_PAGINATED,
    GetPaginatedUserTransactionHistoryRequest
  >;
  clearAllFilters: EmptyAC<typeof USER_TRANSACTION_HISTORY_CLEAR_ALL_FILTERS>;
  setWalletTransfersTable: PayloadAC<typeof SET_WALLET_TRANSFERS_TABLE, number>;
  asyncExportWalletTransfers: PayloadAC<
    typeof EXPORT_WALLET_TRANSFERS,
    ExportUserTransactionHistoryRequest
  >;
  appliedFilters?: any;
  exportInProgress: boolean;
  selectedUser: SelectedUser;
};

const WalletTransfers: React.FC<Props> = ({
  walletTransfers,
  inProgress,
  totalRows,
  page,
  appliedFilters,
  getWalletTransfersPaginated,
  clearAllFilters,
  setWalletTransfersTable,
  asyncExportWalletTransfers,
  error,
  exportInProgress,
  selectedUser = selectedUserInitialState,
}): JSX.Element => {
  const classes = useStyles();
  const { id, email, firstName, lastName } = selectedUser;

  useEffect(() => {
    clearAllFilters();
    getWalletTransfersPaginated({
      logAccountId: id,
      page: 0,
      perPage: 10,
      appliedFilters,
    });
  }, [id]);

  const onPageChange = (newPage: number) => {
    const object: any = {};
    appliedFilters.forEach((value: any, key: React.ReactText) => {
      object[key] = value;
    });
    setWalletTransfersTable(newPage);
    getWalletTransfersPaginated({
      logAccountId: id,
      page: newPage,
      perPage: 10,
      appliedFilters: {
        ...object,
      },
    });
  };

  const isEmpty = size(walletTransfers) == 0;
  if (error) {
    return <SacretError errorMessage={error} showBackButton={false} />;
  }

  const exportData = () => {
    const getFilters = (appliedFilters: Filter) => {
      const object: any = {};
      appliedFilters.forEach((value, key) => {
        object[key] = value;
      });
      return object;
    };
    const data = {
      appliedFilters: getFilters(appliedFilters),
      user_email: email,
      user_name: `${firstName}_${lastName}`,
    };
    asyncExportWalletTransfers(data);
  };

  return (
    <div className={classes.root}>
      <div className={classes.exportWrapper}>
        Export:
        {exportInProgress ? (
          <div className={classes.exportLoaderWrappers}>
            <CircularProgress size={34} color="secondary" />
          </div>
        ) : (
          <CustomButtonPrimary
            variant="outlined"
            size="medium"
            className={classes.exportButton}
            onClick={exportData}
            disabled={isEmpty}
          >
            Transfers
          </CustomButtonPrimary>
        )}
      </div>
      <WalletTransfersFilterBar logAccountId={id} />
      {inProgress && !isEmpty ? (
        <div className={classes.center}>
          <CircularProgress color="secondary" size={60} />
        </div>
      ) : (
        <>
          {!isEmpty ? (
            <WalletTransfersTable
              filter={appliedFilters}
              headers={headers}
              inProgress={inProgress}
              totalRows={totalRows}
              page={page}
              walletTransfers={
                walletTransfers ? walletTransfers : new Map<string, WalletTransfer>()
              }
              getWalletTransfersPaginated={getWalletTransfersPaginated}
              onPageChange={onPageChange}
            />
          ) : (
            <SacretEmpty
              image={Satelite}
              title="Oops"
              text="We couldn’t find any transfers"
              hasButton={false}
            />
          )}
        </>
      )}
    </div>
  );
};

export default WalletTransfers;
