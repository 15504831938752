import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import React, { useState } from 'react';
import { EmptyAC, PayloadAC } from 'typesafe-actions';
import SacretSearchInput from '../../../../../components/SacretSearchInput';
import SacretSelect from '../../../../../components/SacretSelect';
import {
  GetProductsPaginatedRequest,
  ProductDetail,
  SaveProductFilter,
} from '../../../../../data/repositories/product/typedef';
import { User } from '../../../../../data/typedefs/user';
import { Colors } from '../../../../../ui/style/colors';
import { roles } from '../../../../../utils/constants/roles';
import AppliedFiltersChips from './AppliedFiltersChips';

const styles = (theme: Theme) =>
  createStyles({
    filter: {
      minWidth: 200,
      marginRight: 10,
    },
    filterFlex: { display: 'flex', minWidth: 220, marginRight: 10 },
    clearAll: {
      textTransform: 'none',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    clearAllIcon: {
      fontSize: 12,
      padding: 5,
    },
    label: {
      float: 'left',
      padding: '2px 0',
      fontSize: 12,
      color: theme.palette.primary.light,
    },
    input: {
      width: 42,
    },
    sliderText: {
      fontSize: 12,
      color: `${Colors.wildBlueYonder}`,
    },
    minWidth: {
      minWidth: 236,
    },
  });

const useStyles = makeStyles(styles);

type Props = {
  appliedFilters: any;
  appliedTab: any;
  clearAllFilters: EmptyAC<'product/clear_all_filters'>;
  asyncGetProductsPaginated: PayloadAC<
    'product/get_products_paginated',
    GetProductsPaginatedRequest
  >;
  toggleFilter: PayloadAC<'product/toggle_filter', SaveProductFilter>;
  backerMax: number;
  timeLeftMax: number;
  user: User;
};

const statuses: { name: string; value: ProductDetail['status'] }[] = [
  { name: 'Created', value: 'created' },
  { name: 'Published', value: 'published' },
];

const ProductFilterBar: React.FC<Props> = ({
  appliedFilters,
  appliedTab,
  clearAllFilters,
  asyncGetProductsPaginated,
  toggleFilter,
  user,
}: Props): JSX.Element => {
  const [productSearchValue, setProductSearchValue]: any = useState('');
  const classes = useStyles();

  const searchProduct = (value: string) => {
    asyncGetProductsPaginated({
      page: 0,
      perPage: 10,
      appliedFilters: { ...appliedFilters, product: appliedTab.product, name: value },
      appliedTab,
    });
    toggleFilter({ filterType: 'name', value });
    setProductSearchValue('');
  };

  return (
    <Grid container spacing={2}>
      <>
        <Grid item xs={12} sm="auto" className={`${classes.filter} ${classes.minWidth}`}>
          <SacretSearchInput
            placeholder={'Product name'}
            onChange={(value: string[]) => setProductSearchValue(value)}
            stateFilterValue={[productSearchValue]}
            searchHandler={() => searchProduct(productSearchValue)}
          />
        </Grid>

        {!appliedTab.product && roles.isOwner(user.roles) ? (
          <Grid xs={12} sm="auto" item className={classes.filterFlex}>
            <SacretSelect
              items={statuses}
              label="Select a status"
              multiple={false}
              onChange={(value: string[]) => {
                asyncGetProductsPaginated({
                  page: 0,
                  perPage: 10,
                  appliedFilters: { ...appliedFilters, status: value },
                });
                toggleFilter({ filterType: 'status', value });
              }}
              stateFilterValue={appliedFilters.status}
            />
          </Grid>
        ) : null}
      </>
      <AppliedFiltersChips
        clearAllFilters={clearAllFilters}
        stateAppliedFilters={appliedFilters}
        appliedTab={appliedTab}
        toggleFilter={toggleFilter}
      />
    </Grid>
  );
};

export default ProductFilterBar;
