import { connect } from 'react-redux';
import UserTransactionHistory from './UserTransactionHistory';
import { AppState } from '../../../../state/initialState';
import { userTransactionHistoryStateSelector } from './state/selectors';
import { setTransactionHistorySelectedUser } from './state/actions';
import { setInfoDialogFields } from '../../InfoDialog/state/actions';

const mapStateToProps = (state: AppState) => {
  return { ...userTransactionHistoryStateSelector(state) };
};

const mapDispatchToProps = {
  setInfoDialogFields: setInfoDialogFields.request,
  setTransactionHistorySelectedUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserTransactionHistory);
