import { connect } from 'react-redux';
import { setCurrentHomePage } from '../../state/common/actions';
import Product from './Product';
import { AppState } from '../../../state/initialState';
import { productTableStateSelector } from './ProductsTable/state/selectors';
import {
  clearAllFilters,
  asyncGetProductsPaginated,
  toggleTab,
} from './ProductsTable/state/actions';
import { userStateSelector } from '../../../state/selectors';
import { setProductForm } from './ProductForm/state/actions';
import { getWalletBalance } from '../Wallet/state/actions';

const mapDispatchToProps = {
  asyncGetProductsPaginated: asyncGetProductsPaginated.request,
  setCurrentHomePage: setCurrentHomePage,
  clearAllFilters: clearAllFilters,
  setProductForm: setProductForm,
  toggleTab,
  getWalletBalance: getWalletBalance,
};

const mapStateToProps = (state: AppState) => ({
  ...productTableStateSelector(state),
  user: userStateSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Product);
