import { Grid, ListItemIcon, ListItemText, MenuItem, Select } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import React, { FC, useEffect } from 'react';
import { PayloadAC } from 'typesafe-actions';
import { Coin, CreateWalletForCurrencyRequest } from '../../data/repositories/wallet/typedefs';
import { Colors } from '../../ui/style/colors';
import SacretBaseInput from '../SacretBaseInput';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      fill: theme.palette.secondary.light,
      width: 15,
      height: 15,
      marginTop: 4,
      marginRight: 7,
      paddingTop: 0,
      color: theme.palette.primary.light,
    },
    content: {},
    label: {
      float: 'left',
      padding: '2px 0',
      fontSize: 12,
      color: theme.palette.primary.light,
    },
    countChip: {
      fontSize: 12,
      float: 'right',
      marginRight: 3,
    },
    checkbox: {
      minWidth: 0,
      minHeight: 0,
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 3,
      paddingRight: 3,
    },
    item: {
      minHeight: 0,
      lineHeight: 1,
      fontSize: 12,
      color: theme.palette.primary.light,
      '&:hover': {
        backgroundColor: `${Colors.athensGray} !important`,
        fontWeight: 'bold',
      },
    },
    selectedItem: {
      backgroundColor: `${Colors.white} !important`,
      color: theme.palette.secondary.main,
    },
    input: {
      border: `1px solid ${theme.palette.secondary.light}`,
      boxShadow: '0 0px 0px 0 rgba(132,141,182,0.25)',
      '&:hover': {
        border: `1px solid ${Colors.blueCharcoal}`,
      },
      height: 50,
      paddingTop: 0,
      display: 'flex',
      alignItems: 'center',
    },
    imageDiv: {
      minWidth: 28,
      marginRight: 20,
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    image: {
      height: 26,
      width: 26,
    },
  }),
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const AnchorProps: {
  horizontal: number | 'left' | 'right' | 'center';
  vertical: number | 'bottom' | 'top' | 'center';
} = {
  vertical: 'bottom',
  horizontal: 'left',
};

const TransformProps: {
  horizontal: number | 'left' | 'right' | 'center';
  vertical: number | 'bottom' | 'top' | 'center';
} = {
  vertical: 'top',
  horizontal: 'left',
};

const borderColor = (theme: Theme) => theme.palette.secondary.light;
const MenuProps = {
  PaperProps: {
    style: {
      borderRadius: 4,
      border: `1px solid ${borderColor}`,
      borderTop: 0,
      //borderWidth: '0 0 0 5px',
      boxShadow: '0 10px 30px 0 rgba(132,141,182,0.25)',
      marginTop: -6,
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 150,
    },
  },
  anchorOrigin: AnchorProps,
  transformOrigin: TransformProps,
  getContentAnchorEl: null,
};

type Props = {
  label: string;
  allCoins: Map<string, Coin>;
  createWallet?: PayloadAC<'wallet/create_wallet_for_currency', CreateWalletForCurrencyRequest>;
  setSelectedCoin: any;
  selectedCoin: string;
  allowedCoins?: string[];
};

const SacretCoinSelect: FC<Props> = ({
  allCoins,
  createWallet,
  setSelectedCoin,
  selectedCoin,
  allowedCoins,
}: Props) => {
  const classes = useStyles();
  let allCoinsArray = Object.entries(allCoins);

  // If there is limitation, such as withdraw wallet, filter out all coins
  if (allowedCoins) {
    allCoinsArray = allCoinsArray.filter(el => allowedCoins && allowedCoins.includes(el[0]));
  }

  const x = new Map<string, Coin>(allCoinsArray);
  const initialySelectedCoin = x.get(selectedCoin);
  const initialySelectedSymbol = initialySelectedCoin
    ? initialySelectedCoin.symbol
    : (allowedCoins && allowedCoins[0]) || '';
  const [selectedItem, setSelectedItem] = React.useState<string>(
    selectedCoin || initialySelectedSymbol,
  );
  const [selectDropdownOpened, setSelectDropdownOpened] = React.useState<boolean>(false);

  useEffect(() => {
    allowedCoins && setSelectedCoin && setSelectedCoin(selectedItem);
  }, []);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedItem = event.target.value as string;

    if (setSelectedCoin) setSelectedCoin(selectedItem);
    setSelectedItem(event.target.value as string);
    if (createWallet && selectedItem != selectedCoin) createWallet({ symbol: selectedItem });
  };
  const onSelectOpen = () => {
    setSelectDropdownOpened(true);
  };
  const onSelectClose = () => {
    setSelectDropdownOpened(false);
  };

  return (
    <Select
      variant="outlined"
      value={selectedItem}
      onChange={handleChange}
      input={<SacretBaseInput value="Amount" />}
      MenuProps={MenuProps}
      inputProps={{
        classes: { icon: classes.icon },
        className: classes.input,
      }}
      displayEmpty={true}
      IconComponent={selectDropdownOpened ? KeyboardArrowUp : KeyboardArrowDown}
      onOpen={onSelectOpen}
      onClose={onSelectClose}
    >
      {allCoinsArray.map(([, value]) => (
        <MenuItem
          key={value.symbol}
          value={value.symbol}
          dense
          disableRipple
          classes={{ selected: classes.selectedItem, root: classes.item }}
        >
          <Grid container>
            <ListItemIcon style={{ minWidth: 28 }}>
              <div className={classes.imageDiv}>
                <img src={value.icon} alt="icon" className={classes.image} />
              </div>
            </ListItemIcon>
            <ListItemText id={`checkbox-list-label-${value.symbol}`} primary={value.name} />
          </Grid>
        </MenuItem>
      ))}
    </Select>
  );
};

export default SacretCoinSelect;
