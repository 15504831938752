import { connect } from 'react-redux';
import { getUserInfo } from '../../../../state/actions';
import KycExpiredDocDialog from './KycExpiredDocDialog';

const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {
  getUserInfo: getUserInfo.request,
};
export default connect(mapStateToProps, mapDispatchToProps)(KycExpiredDocDialog);
