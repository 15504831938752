/* eslint-disable */
import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import { GetUserInfoResponse } from '../../../../data/repositories/account/typedefs';
import { ExchangeAuthCodeRequest } from './initialState';

export const LOGIN = 'login';
export const LOGOUT = 'logout';

export const CLEAR_LOGIN_STATE = 'login/clear_state';

export const EXCHANGE_AUTH_CODE = '/login/exchange_auth_code';
export const EXCHANGE_AUTH_CODE_SUCCESS = '/login/exchange_auth_code/success';
export const EXCHANGE_AUTH_CODE_FAIL = '/login/exchange_auth_code/fail';

export const GET_USER_INFO = '/get_user_info';
export const GET_USER_INFO_SUCCESS = '/get_user_info/success';
export const GET_USER_INFO_FAIL = '/get_user_info/fail';

export const login = createStandardAction(LOGIN)<undefined>();

export const logout = createStandardAction(LOGOUT)<undefined>();
export const exchangeAuthCode = createAsyncAction(
  EXCHANGE_AUTH_CODE,
  EXCHANGE_AUTH_CODE_SUCCESS,
  EXCHANGE_AUTH_CODE_FAIL,
)<ExchangeAuthCodeRequest, undefined, Error>();

export const getUserInfo = createAsyncAction(
  GET_USER_INFO,
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO_FAIL,
)<undefined, GetUserInfoResponse, Error>();
