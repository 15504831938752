import { connect } from 'react-redux';
import { AppState } from '../../../../../state/initialState';
import { getAllCoinsSelector } from '../../../Wallet/state/selectors';
import {
  clearAllFilters,
  toggleFilter,
  asyncGetUserEarningsTablePaginated,
} from '../state/actions';
import {
  getAppliedFiltersSelector,
  getAppliedFiltersIdsSelector,
  getAreAnyFiltersApplied,
} from '../state/selectors';
import FilterBar from '../../FilterBar';

const mapStateToProps = (state: AppState) => {
  const allCoins = getAllCoinsSelector(state);
  const appliedFilters = getAppliedFiltersSelector(state);
  const appliedFiltersIds = getAppliedFiltersIdsSelector(state);
  const areFiltersApplied = getAreAnyFiltersApplied(state);
  const inputFields = ['status', 'transactionType', 'dateFilter', 'searchByEmailInput'];
  return { allCoins, appliedFilters, appliedFiltersIds, areFiltersApplied, inputFields };
};

const dispatchStateToProps = {
  asyncTableDataPaginated: asyncGetUserEarningsTablePaginated.request,
  toggleFilter,
  clearAllFilters: clearAllFilters,
};

export default connect(mapStateToProps, dispatchStateToProps)(FilterBar);
