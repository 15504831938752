import { connect } from 'react-redux';
import { AppState } from '../../../../state/initialState';
import {
  asyncGetFriendsInvitationsPaginated,
  asyncGetTotalFriendsInvitationsPaginated,
} from '../state/actions';
import InviteFriendsModal from './InviteFriendsModal';
import { asyncSendInvites } from './state/actions';
import { getInviteModalSelector } from './state/selectors';

const mapStateToProps = (state: AppState) => ({
  ...getInviteModalSelector(state),
});

const mapDispatchToProps = {
  asyncSendInvites: asyncSendInvites.request,
  asyncGetTotalFriendsInvitationsPaginated: asyncGetTotalFriendsInvitationsPaginated.request,
  asyncGetFriendsInvitationsPaginated: asyncGetFriendsInvitationsPaginated.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(InviteFriendsModal);
