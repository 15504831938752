import { connect } from 'react-redux';
import { AppState } from '../../../../../state/initialState';
import { clearCommonState } from '../../../../state/common/actions';
import { isWithdrawDialogOpen, openWithdrawDialog } from '../../state/actions';
import { getComponentStateSelector, stepSelector } from '../../state/selectors';
import WithdrawDialog from './WithdrawDialog';

const mapDispatchToProps = {
  openWithdrawDialog: openWithdrawDialog,
  isWithdrawDialogOpen: isWithdrawDialogOpen,
  clearCommonState: clearCommonState,
};
const mapStateToProps = (state: AppState) => {
  const step = stepSelector(state);
  const componentState = getComponentStateSelector(state);
  return { step, componentState };
};

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawDialog);
