import { MenuItem, Select } from '@material-ui/core';
import { createTheme, createStyles, makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import { ReactComponent as DownArrowSvg } from '../../assets/down-arrow.svg';
import { Colors } from '../../ui/style/colors';

const theme = createTheme({
  overrides: {
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'none',
        },
      },
      icon: {
        marginRight: 14,
        position: 'relative',
      },
    },
  },
});

export const useClasses = makeStyles(() =>
  createStyles({
    input: {
      fontSize: 16,
      color: `${Colors.blueCharcoal}`,
      marginTop: 10,
    },
    selectInput: {
      padding: '0 14px',
      height: 50,
      display: 'flex',
      alignItems: 'center',
    },
    placeholderStyle: {
      color: `${Colors.blueHazeTwo}`,
    },
    selectSelectedStyle: {
      color: `${Colors.masala}`,
    },
    placeholderOverride: {
      '&::placeholder': {
        color: `${Colors.blueHazeTwo} !important`,
        opacity: '1 !important',
      },
      '&::hover': {
        cursor: 'pointer !important',
      },
    },
    popupSelectMenu: {
      maxHeight: 550,
      maxWidth: 200,
    },
  }),
);
export type SelectProps = {
  field: {
    name: string;
    value: string;
    onChange(): void;
    onClose(): void;
  };
  form: {
    touched: { [key: string]: boolean };
    error: boolean;
    setFieldTouched: (name: string) => void;
    values: { [key: string]: string };
    errors: { [key: string]: string };
  };
  optionFieldValue?: string;
  tabIndex: string;
  options: any[];
  placeholder?: string;
  props: any;
  inputStyle?: string;
  onChange(): void;
  id?: string;
};

const truncate = (input: string) => (input.length > 28 ? input.substring(0, 28) + '...' : input);

const SelectComponent: React.FC<SelectProps> = ({
  field: { name, value },
  form: { setFieldTouched },
  options,
  placeholder = `Select your ${name}`,
  optionFieldValue = 'abbreviation',
  props,
  tabIndex,
  inputStyle,
  onChange,
  id,
}: SelectProps) => {
  const classes = useClasses();

  return (
    <MuiThemeProvider theme={theme}>
      <Select
        id={id}
        variant="outlined"
        name={name}
        value={value}
        fullWidth
        displayEmpty
        onChange={onChange}
        onClose={() => setFieldTouched(name)}
        className={
          value === '' || (value as any).name === ''
            ? clsx(classes.input, classes.placeholderStyle, inputStyle)
            : clsx(classes.input, classes.selectSelectedStyle, inputStyle)
        }
        IconComponent={DownArrowSvg}
        inputProps={{ className: classes.selectInput, tabIndex: tabIndex }}
        MenuProps={{
          className: classes.popupSelectMenu,
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
        }}
        {...props}
      >
        <MenuItem value="" disabled style={{ display: 'none' }}>
          {placeholder}
        </MenuItem>
        {options.map(option => (
          <MenuItem
            key={option.name ? option.name : option}
            value={option[optionFieldValue] ? option[optionFieldValue] : option}
            style={{ maxWidth: '100%', whiteSpace: 'normal' }}
          >
            {option.name ? (value !== '' ? truncate(option.name) : option.name) : option}
          </MenuItem>
        ))}
      </Select>
    </MuiThemeProvider>
  );
};

export default SelectComponent;
