import { connect } from 'react-redux';
import { AppState } from '../../../state/initialState';
import { isInfoDialogOpen } from './state/actions';
import { getInfoDialogStateSelector } from './state/selectors';
import InfoDialog from './InfoDialog';
import { InfoDialogState } from './state/initialState';

const mapDispatchToProps = {
  isInfoDialogOpen: isInfoDialogOpen,
};

const mapStateToProps = (state: AppState) => {
  const infoDialogState: InfoDialogState = getInfoDialogStateSelector(state);
  return { infoDialogState };
};

export default connect(mapStateToProps, mapDispatchToProps)(InfoDialog);
