import { createReducer } from 'typesafe-actions';
import {
  UPDATE_FEE_RATE,
  UPDATE_FEE_RATE_SUCCESS,
  UPDATE_FEE_RATE_FAIL,
  UPDATE_FEE_RATE_CLEAR_STATE,
  GET_STORE_ITEMS,
  GET_STORE_ITEMS_SUCCESS,
  GET_STORE_ITEMS_FAIL,
} from './actions';
import { FeeSettingsState, initialState } from './initialState';

export default createReducer(initialState, {
  [GET_STORE_ITEMS]: (state): FeeSettingsState => ({
    ...state,
    fail: false,
    inProgress: true,
  }),
  [GET_STORE_ITEMS_SUCCESS]: (state: FeeSettingsState, { payload }) => ({
    ...state,
    storeItems: payload,
    inProgress: false,
  }),
  [GET_STORE_ITEMS_FAIL]: (state: FeeSettingsState) => ({
    ...state,
    fail: true,
    inProgress: false,
  }),
  [UPDATE_FEE_RATE]: (state: FeeSettingsState) => ({
    ...state,
    error: null,
    inProgress: true,
  }),
  [UPDATE_FEE_RATE_SUCCESS]: (state: FeeSettingsState) => ({
    ...state,
    inProgress: false,
    fail: false,
    success: true,
  }),
  [UPDATE_FEE_RATE_CLEAR_STATE]: (state: FeeSettingsState) => ({
    ...state,
    ...initialState,
  }),
  [UPDATE_FEE_RATE_FAIL]: (state: FeeSettingsState, { payload }) => ({
    ...state,
    inProgress: false,
    fail: true,
    success: false,
    ...(payload && payload.response ? { error: payload.response.data.error } : {}),
  }),
});
