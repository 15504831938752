import { Product } from '../../../../../data/repositories/product/typedef';

export type ProductDetailsDialogState = {
  selectedProduct?: Product | null;
};
export const productDetailsDialogInitialState = {
  selectedProduct: null,
};
export type DialogProps = ProductDetailsDialogState & {
  fieldsToUpdate?: any;
  className?: string;
  maxWidth?: false | 'sm' | 'xs' | 'md' | 'lg' | 'xl' | undefined;
  dialogTittleWrapperClass?: string;
  activeTab?: string;
};

export type InfoDialogState = {
  open: boolean;
  status: string | null;
  dialogType: any;
  title: string;
  dialogProps: DialogProps;
  fail: boolean;
  errorMessage: string;
  success: boolean;
  successMessage: string;
  hideCloseButton: boolean;
  showDialogContent?: boolean; //If modal should contain title e.g. error or success message with appropriate icon
};

export type KycDialogFormState = {
  citizenship?: string;
  phoneNumber?: string;
  countryCode?: string;
  dateOfBirth?: Date | string;
};

export const initialState: InfoDialogState = {
  open: false,
  dialogType: '',
  title: '',
  dialogProps: {
    ...productDetailsDialogInitialState,
    fieldsToUpdate: [],
    maxWidth: 'sm',
    dialogTittleWrapperClass: '',
    activeTab: '',
  },
  fail: false,
  errorMessage: '',
  success: false,
  successMessage: '',
  hideCloseButton: false,
  showDialogContent: true,
  status: null,
};
