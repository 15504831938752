/* eslint-disable @typescript-eslint/indent */
import { CircularProgress } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { size } from 'lodash';
import React, { useEffect } from 'react';
import { PayloadAC } from 'typesafe-actions';
import Satelite from '../../../../../assets/satelite.svg';
import AffiliatesPremiumTable, {
  HeaderProps,
} from '../../../../../components/AffiliatesPremiumTable/';
import SacretEmpty from '../../../../../components/SacretEmpty';
import {
  GetReferralsPaginatedRequest,
  Referral,
} from '../../../../../data/repositories/affiliate/typedefs';
import { termSavingsDetailsRequest } from '../state/initialState';
import TransactionHashDialog from '../TransactionHashDialog';
import { AffiliatePremiumTableState } from './state/initialState';

const styles = (theme: Theme) =>
  createStyles({
    myAffiliatesList: {
      color: theme.palette.secondary.light,
      fontSize: 18,
      fontWeight: 800,
      paddingBottom: 30,
    },
    root: {},
    circularProgress: {
      position: 'absolute',
      left: '50%',
      top: '50%',
    },
  });

const useStyles = makeStyles(styles);

type Props = AffiliatePremiumTableState & {
  asyncGetPremiumReferralsPaginated: PayloadAC<
    'affiliate/get_premium_referrals_paginated',
    GetReferralsPaginatedRequest
  >;
  inProgress: boolean;
  appliedFilters?: any;
  clearAllFilters: any;
  asyncGetTermSavingsDetails: PayloadAC<'account/get_term_savings_details', string>;
  termSavingsDetails?: termSavingsDetailsRequest;
};

const headers: HeaderProps[] = [
  { name: 'Status', align: 'left' },
  { name: 'TX Proof', align: 'left' },
  { name: 'Transactions type', align: 'left' },
  { name: 'Date & Time', align: 'right' },
  { name: 'Amount', align: 'right' },
  { name: 'Value(EUR)', align: 'right' },
];

const AffiliatePremiumTable: React.FC<Props> = (props: Props): JSX.Element => {
  const classes = useStyles();
  const {
    referrals,
    asyncGetPremiumReferralsPaginated,
    inProgress,
    stats: { totalReferrals },
    page,
    appliedFilters,
    clearAllFilters,
    asyncGetTermSavingsDetails,
    termSavingsDetails,
  } = props;

  useEffect(() => {
    clearAllFilters();
    asyncGetPremiumReferralsPaginated({ page: page, perPage: 10, appliedFilters });
  }, []);

  const isEmpty = size(referrals) == 0;

  return (
    <div>
      {inProgress && !referrals ? (
        <CircularProgress color="secondary" className={classes.circularProgress} size={60} />
      ) : (
        <div>
          {!isEmpty ? (
            <div className={classes.root}>
              {/*<Typography className={classes.myAffiliatesList}>My affiliates list</Typography>*/}
              <AffiliatesPremiumTable
                referrals={referrals ? referrals : new Map<string, Referral>()}
                headers={headers}
                totalReferrals={totalReferrals || 0}
                page={page}
                filter={appliedFilters}
                inProgress={inProgress}
                appliedFilters={appliedFilters}
                asyncGetTermSavingsDetails={asyncGetTermSavingsDetails}
                termSavingsDetails={termSavingsDetails}
              />
              <TransactionHashDialog />
            </div>
          ) : (
            <SacretEmpty
              image={Satelite}
              title="Oops"
              text="We couldn’t find any transactions"
              hasButton={false}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default AffiliatePremiumTable;
