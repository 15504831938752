import { Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { EmptyAC, PayloadAC } from 'typesafe-actions';
import SacretDaterangePickerSelect from '../../../../../../components/SacrateDaterangePickerSelect';
import SacretSearchInput from '../../../../../../components/SacretSearchInput';
import SacretSelect from '../../../../../../components/SacretSelect';
import {
  GetPaginatedDataRequest,
  SaveFilter,
} from '../../../../../../data/repositories/admin/typedefs';
import { ALL_COINS } from '../../../../../../utils/constants/amounts';
import { getCurrencySymbolName } from '../../../../../../utils/helper/utils';
import {
  GET_TRANSACTION_GROUPING_PAGINATED,
  TRANSACTION_GROUPING_CLEAR_ALL_FILTERS,
  TRANSACTION_GROUPING_TOGGLE_FILTER,
} from '../state/actions';
import AppliedFiltersChips from './AppliedFiltersChips';
const styles = (theme: Theme) =>
  createStyles({
    filter: {
      minWidth: 200,
      marginRight: 10,
    },
    'm-r': {
      marginRight: 10,
    },
    clearAll: {
      textTransform: 'none',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    clearAllIcon: {
      fontSize: 12,
      padding: 5,
    },
    label: {
      float: 'left',
      padding: '2px 0',
      fontSize: 12,
      color: theme.palette.primary.light,
    },
    minWidth: {
      minWidth: 236,
    },
  });

const useStyles = makeStyles(styles);

type Props = {
  toggleFilter: PayloadAC<typeof TRANSACTION_GROUPING_TOGGLE_FILTER, SaveFilter>;
  appliedFilters: Map<string, string[]>;
  clearAllFilters: EmptyAC<typeof TRANSACTION_GROUPING_CLEAR_ALL_FILTERS>;
  asyncTableDataPaginated: PayloadAC<
    typeof GET_TRANSACTION_GROUPING_PAGINATED,
    GetPaginatedDataRequest
  >;
};

const statuses = [
  { name: 'Completed', value: 'completed' },
  { name: 'Pending', value: 'pending' },
  { name: 'Escrow', value: 'escrow' },
  { name: 'In HODL', value: 'invested' },
  { name: 'Pending Escrow', value: 'pending_escrow' },
  { name: 'Timed Escrow', value: 'timed_escrow' },
];

const walletCoins = ALL_COINS.map((coin: string) => {
  const coinSymbol = getCurrencySymbolName(coin);
  return {
    name: coinSymbol,
    value: coinSymbol,
  };
});

const transferTypes = [
  { name: 'Withdraw', value: 'withdraw' },
  { name: 'Deposit', value: 'deposit' },
];

const WalletTransfersFilterBar: React.FC<Props> = ({
  toggleFilter,
  appliedFilters,
  clearAllFilters,
  asyncTableDataPaginated,
}: Props): JSX.Element => {
  const [query, setQuery]: any = useState('');
  const classes = useStyles();

  const handleOnChangeEvent = (val: string[] | string | undefined, filterType: string) => {
    const object: any = {};
    const value = filterType === 'searchByEmail' && val ? [val[0].trim()] : val; //Delete extra blank spaces for search input

    appliedFilters.forEach((value, key) => {
      object[key] = value;
    });

    asyncTableDataPaginated({
      page: 0,
      perPage: 10,
      appliedFilters: { ...object, [filterType]: value },
    });

    toggleFilter({ filterType, value });
    if (filterType === 'searchByEmail') setQuery('');
  };

  return (
    <Grid container spacing={2}>
      <Grid xs={12} sm="auto" item className={classes.filter}>
        <SacretSelect
          items={statuses}
          label="Status"
          onChange={(value: string[]) => handleOnChangeEvent(value, 'status')}
          stateFilterValue={appliedFilters.get('status')}
        />
      </Grid>
      <Grid xs={12} sm="auto" item className={classes.filter}>
        <SacretSelect
          items={transferTypes}
          label="Transfer type"
          onChange={(value: string[]) => handleOnChangeEvent(value, 'transferType')}
          stateFilterValue={appliedFilters.get('transferType')}
        />
      </Grid>

      <Grid xs={12} sm="auto" item className={`${classes.filter} ${classes.minWidth}`}>
        <SacretSelect
          items={walletCoins}
          label="Currency"
          multiple={false}
          onChange={(value: string[]) => handleOnChangeEvent(value, 'coinSymbol')}
          stateFilterValue={appliedFilters.get('coinSymbol')}
        />
      </Grid>
      <Grid xs={12} sm="auto" item className={`${classes.filter} ${classes.minWidth}`}>
        <SacretDaterangePickerSelect
          label="Choose a date"
          onChange={(value: string[]) => handleOnChangeEvent(value, 'dateFilter')}
          stateFilterValue={appliedFilters.get('dateFilter')}
        />
      </Grid>
      <Grid xs={12} sm="auto" item className={`${classes.filter} ${classes.minWidth}`}>
        <SacretSearchInput
          onChange={(value: string[]) => setQuery(value)}
          placeholder="Search by email"
          stateFilterValue={[query]}
          searchHandler={() => handleOnChangeEvent(query, 'searchByEmail')}
        />{' '}
      </Grid>
      <AppliedFiltersChips
        clearAllFilters={clearAllFilters}
        stateAppliedFilters={appliedFilters}
        toggleFilter={toggleFilter}
      />
    </Grid>
  );
};

export default WalletTransfersFilterBar;
