import { connect } from 'react-redux';
import { AppState } from '../../../../../App/state/initialState';
import { getTransfersForCoin } from '../../../state/actions';
import { setSelectedCoin } from '../../../state/common/actions';
import { isTwoFactorAuthEnabled } from '../../Settings/state/actions';
import { twoFactorAuthEnableSelector } from '../../Settings/state/selectors';
import { getSingleCoinTransactionsIdsSelector } from '../../Transactions/state/selectors';
import {
  clearWithdrawState,
  createWalletForCurrency,
  getWalletBalance,
  isInvestDialogOpen,
  openBuySellModal,
  openWithdrawDialog,
} from '../state/actions';
import {
  getAllCoinsSelector,
  walletSLCBalanceSelector,
  walletTotalBalanceSelector,
  withdrawSuccessSelector,
} from '../state/selectors';
import WalletCoin from './WalletCoin';

const mapStateToProps = (state: AppState) => {
  const totalBalanceEUR = walletTotalBalanceSelector(state);
  const totalBalanceSLC = walletSLCBalanceSelector(state);
  const allCoins = getAllCoinsSelector(state);
  const transactionsIds = getSingleCoinTransactionsIdsSelector(state);
  const withdrawSuccess = withdrawSuccessSelector(state);
  const is2FaEnabled = twoFactorAuthEnableSelector(state);
  // const wallet = getWalletBySymbolSelectorSelector(state);

  return {
    totalBalanceEUR,
    totalBalanceSLC,
    allCoins,
    transactionsIds,
    withdrawSuccess,
    is2FaEnabled,
  };
};

const mapDispatchToProps = {
  createWalletForCurrency: createWalletForCurrency,
  setSelectedCoin: setSelectedCoin,
  openWithdrawDialog: openWithdrawDialog,
  openBuySellModal: openBuySellModal,
  isInvestDialogOpen: isInvestDialogOpen,
  getTransfersForCoin: getTransfersForCoin,
  clearWithdrawState: clearWithdrawState,
  getWalletBalance: getWalletBalance,
  isTwoFactorAuthEnabled: isTwoFactorAuthEnabled,
};

export default connect(mapStateToProps, mapDispatchToProps)(WalletCoin);
