import { CircularProgress, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { EmptyAC, PayloadAC } from 'typesafe-actions';
import CustomButtonPrimary from '../../../../../components/CustomButtons/CustomButtonPrimary';
import ProductsPurchaseHistoryTable from '../../../../../components/ProductsPurchaseHistoryTable';
import SacretError from '../../../../../components/SacretError';
import { Filter } from '../../../../../data/repositories/admin/typedefs';
import ProductsPurchaseHistoryFilterBar from './ProductsPurchaseHistoryFilterBar';
import {
  EXPORT_PRODUCTS_PURCHASE_HISTORY,
  GET_PRODUCTS_PURCHASE_HISTORY_PAGINATED,
  PRODUCTS_PURCHASE_HISTORY_CLEAR_ALL_FILTERS,
} from './state/actions';
import { ProductsPurchaseHistoryState } from './state/initialState';

type ProductsPurchaseHistoryProps = ProductsPurchaseHistoryState & {
  productsPurchaseHistoryClearAllFilters: EmptyAC<
    typeof PRODUCTS_PURCHASE_HISTORY_CLEAR_ALL_FILTERS
  >;
  asyncGetProductsPurchaseHistory: PayloadAC<typeof GET_PRODUCTS_PURCHASE_HISTORY_PAGINATED, any>;
  asyncExportProductsPurchaseHistory: PayloadAC<typeof EXPORT_PRODUCTS_PURCHASE_HISTORY, any>;
};

type HeaderProps = {
  name: string;
  align?: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined;
};

const headers: HeaderProps[] = [
  { name: 'Status', align: 'left' },
  { name: 'Name', align: 'left' },
  { name: 'Affiliate email', align: 'left' },
  { name: 'Date & Time', align: 'left' },
  { name: 'SLC purchased', align: 'right' },
  { name: 'SLC rate', align: 'right' },
  { name: 'SLC purchased (EUR)', align: 'right' },
  { name: 'Current value of purchased SLC(EUR)', align: 'right' },
];

const ProductsPurchaseHistory: React.FC<ProductsPurchaseHistoryProps> = ({
  productsPurchaseHistory,
  error,
  inProgress,
  appliedFilters,
  page,
  totalCount,
  exportInProgress,
  productsPurchaseHistoryClearAllFilters,
  asyncGetProductsPurchaseHistory,
  asyncExportProductsPurchaseHistory,
}): JSX.Element => {
  useEffect(() => {
    productsPurchaseHistoryClearAllFilters();
    asyncGetProductsPurchaseHistory({ page: 0, perPage: 10 });
  }, []);

  const exportData = () => {
    const getFilters = (appliedFilters: Filter) => {
      const object: any = {};
      appliedFilters.forEach((value, key) => {
        object[key] = value;
      });
      return object;
    };
    asyncExportProductsPurchaseHistory({
      page: 0,
      perPage: 10,
      appliedFilters: getFilters(appliedFilters),
    });
  };

  if (error) {
    return <SacretError errorMessage={error} />;
  }

  return (
    <div>
      <Grid container justifyContent="space-between">
        <Grid item sm={10}>
          <ProductsPurchaseHistoryFilterBar />
        </Grid>
        <Grid item>
          {exportInProgress ? (
            <CircularProgress size={34} color="secondary" />
          ) : (
            <CustomButtonPrimary variant="outlined" size="medium" onClick={exportData}>
              Export
            </CustomButtonPrimary>
          )}
        </Grid>
      </Grid>

      <ProductsPurchaseHistoryTable
        productsPurchaseHistory={productsPurchaseHistory}
        headers={headers}
        page={page}
        inProgress={inProgress}
        filter={appliedFilters}
        totalCount={totalCount}
      />
    </div>
  );
};

export default ProductsPurchaseHistory;
