import {
  CircularProgress,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { PayloadAC } from 'typesafe-actions';
import VerificationCodeInput from '../../../../../components/VerificationCodeInput';
import { TRADE_SLC } from '../AffiliateTable/state/actions';
import { dialogState } from '../AffiliateTable/state/initialState';
const styles = (theme: Theme) =>
  createStyles({
    dialog: {
      height: 'center',
      width: 'center',
      borderRadius: 6,
      backgroundColor: 'transparent',
      boxShadow: '0 10px 30px 0 rgba(132,141,182,0.25)',
      paddingLeft: 30,
      paddingRight: 30,
    },
    infoLabel: {
      color: theme.palette.primary.light,
      fontSize: 12,
      fontWeight: 600,
      marginLeft: 10,
    },
    circularProgress: {
      marginTop: 30,
      marginBottom: 30,
      justifyContent: 'center',
      display: 'flex',
    },
  });
const useStyles = makeStyles(styles);

type Props = {
  is2FaEnabled: boolean | undefined;
  dialogState: dialogState;
  asyncTradeSLC: PayloadAC<typeof TRADE_SLC, any>;
};

const AffiliateAddDeduct2fa = ({ is2FaEnabled, dialogState, asyncTradeSLC }: Props) => {
  const classes = useStyles();
  const { error, inProgress, referralUser, amount, isEscrow, comment } = dialogState;

  return (
    <>
      {inProgress ? (
        <Grid container className={classes.circularProgress} alignContent="center">
          <CircularProgress color="secondary" size={60} />
        </Grid>
      ) : is2FaEnabled ? (
        <div>
          <Typography className={classes.infoLabel} component="p" style={{ marginBottom: 24 }}>
            Please, enter your two-factor authentication code
          </Typography>
          <VerificationCodeInput
            onComplete={(code: any) =>
              asyncTradeSLC({
                referralUser,
                amount,
                isEscrow,
                comment,
                code,
              })
            }
            error={error}
          />
        </div>
      ) : (
        <Typography className={classes.infoLabel} component="p" style={{ marginBottom: 24 }}>
          First enable 2FA authentication{' '}
          <Link
            to={{
              pathname: '/settings',
              search: '?auth=true',
            }}
          >
            2FA
          </Link>
        </Typography>
      )}
    </>
  );
};
export default AffiliateAddDeduct2fa;
