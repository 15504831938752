import { push } from 'connected-react-router';
import { all, put, takeEvery } from 'redux-saga/effects';
import LocalStorage from '../../../../data/LocalStorage';
import api from '../../../../data/repositories/account';
import {
  ExchangeAuthCodeResponse,
  GetUserInfoResponse,
} from '../../../../data/repositories/account/typedefs';
import {
  ACCESS_TOKEN,
  KYC_STATUS,
  REFRESH_TOKEN,
  USER_EMAIL,
  USER_ID,
  AFFILIATE_PACKAGE,
} from '../../../../utils/constants/localStorageKeys';
import { APP_DASHBOARD_ROUTE } from '../../../../utils/constants/routes';
import {
  exchangeAuthCode,
  EXCHANGE_AUTH_CODE,
  getUserInfo,
  GET_USER_INFO,
  login,
  LOGIN,
} from './actions';

export function* login$({}: ReturnType<typeof login>) {
  yield api.login();
}

export function* checkUserInfo$({}: ReturnType<typeof getUserInfo.request>) {
  try {
    const user_id = LocalStorage.get(USER_ID);
    const userInfo: GetUserInfoResponse = yield api.getUserInfo(user_id);

    yield put(getUserInfo.success(userInfo));
    yield put(push(APP_DASHBOARD_ROUTE));
  } catch (error) {
    LocalStorage.clear();
    yield put(getUserInfo.failure(error as Error));
    yield put(exchangeAuthCode.failure(error as Error));
  }
}
export function* exchangeAuthCode$(action: ReturnType<typeof exchangeAuthCode.request>) {
  try {
    const response: ExchangeAuthCodeResponse = yield api.exchangeAuthCode(
      action.payload.code,
      action.payload.status,
    );

    LocalStorage.set(USER_ID, response.id);
    LocalStorage.set(USER_EMAIL, response.email);
    LocalStorage.set(KYC_STATUS, response.kyc_status);
    LocalStorage.set(ACCESS_TOKEN, response.access_token);
    LocalStorage.set(REFRESH_TOKEN, response.refresh_token);
    LocalStorage.set(AFFILIATE_PACKAGE, !!response.affiliate_package);

    yield put(push(APP_DASHBOARD_ROUTE));
    yield put(exchangeAuthCode.success());
  } catch (error) {
    yield put(exchangeAuthCode.failure(error as Error));
  }
}

export default function* () {
  yield all([
    takeEvery(LOGIN, login$),
    takeEvery(EXCHANGE_AUTH_CODE, exchangeAuthCode$),
    takeEvery(GET_USER_INFO, checkUserInfo$),
  ]);
}
