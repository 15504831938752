import { createSelector } from 'reselect';
import { AppState } from '../../../../../state/initialState';

const getUpdateCurrencyRateState = ({
  home: {
    settings: { currencyRate },
  },
}: AppState) => currencyRate;

export const updateCurrencyRateStateSelector = createSelector(
  getUpdateCurrencyRateState,
  currencyRate => currencyRate,
);
