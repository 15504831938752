import { Wallet, WithdrawEurRequest } from '../../../../../data/repositories/wallet/typedefs';

export type ComponentState = {
  coinsTableInProgress: boolean;
  transactionTableInProgress: boolean;
  fail: boolean;
  error: Error | null;
  inProgress: boolean;
  isDialogOpen: boolean;
};

export type termPackageObject = {
  create_account_id: string;
  created_at: string;
  description: string;
  id: string;
  interest_rate: number;
  name: string;
  payment_frequency_type: string;
  time_period: number;
  update_account_id: {
    String: string;
    Valid: boolean;
  };
  updated_at: { Time: string; Valid: boolean };
};

export type InvestDialogState = {
  investDialogOpened: boolean;
  termPackageTypes: termPackageObject[];
  selectedPackage: termPackageObject;
  errorMessage: string;
  inProgress: boolean;
};

export type DepositTermRequest = {
  amount: number;
  term_saving_type_name: string;
  balance_status: string;
};

export type CreateDepositOrderRequest = {
  order_type: string;
  payment_type: string;
  eur_price: number | undefined;
};

export type CreateDepositOrderResponse = {
  order_id: string;
  order_proof: string;
  order_status: string;
  payment_type: string;
  price: number;
  symbol: string;
};

type walletObject = {
  address: string;
  aliases: string[];
  symbol: string;
  balance: number;
  pending_balance: number;
  escrow_balance: number;
  invested_balance: number;
};

type currencyObject = {
  symbol: string;
  name: string;
  network: string;
  decimals: number;
};

export type entireWalletBalanceObject = {
  currency: currencyObject;
  wallet: walletObject;
};

export type SwapToSLCState = {
  completed: boolean;
  inProgress: boolean;
  success: boolean;
  fail: boolean;
  error: Error | null;
};

export type GetRevolutCounterparty = {
  inProgress: boolean;
  fail: boolean;
  success: boolean;
};
export type AsyncRequest = {
  inProgress: boolean;
  fail: boolean;
  error: Error | null;
  success: boolean;
  order: any;
};
export type DepositModalState = {
  depositOrder: AsyncRequest;
  depositCheckout: AsyncRequest;
  step: number;
  data: { amount_in_eurocents: number | undefined };
  open: boolean;
};
export type BuySellModalState = {
  isOpen: boolean;
  inProgress: boolean;
};

export type BuyWalletModalState = {
  inProgress: boolean;
  success: boolean;
  fail: boolean;
  error: Error | null;
  isOpen: boolean;
};

export type WalletState = {
  wallets: Map<string, Wallet>;
  componentState: ComponentState;
  investDialogState: InvestDialogState;
  depositModal: DepositModalState;
  buySellModal: BuySellModalState;
  step: number;
  withdrawInProgress: boolean;
  withdrawError: Error | null;
  withdrawSuccess: boolean | null;
  revolutCounterpartyData: WithdrawEurRequest;
  getRevolutCounterparty: GetRevolutCounterparty;
  entireWalletBalance: entireWalletBalanceObject;
  swapSLTtoSLC: SwapToSLCState;
  buyWalletModal: BuyWalletModalState;
};

export const initialState: WalletState = {
  wallets: new Map<string, Wallet>(),
  componentState: {
    coinsTableInProgress: false,
    transactionTableInProgress: false,
    fail: false,
    error: null,
    inProgress: true,
    isDialogOpen: false,
  },
  investDialogState: {
    investDialogOpened: false,
    termPackageTypes: [],
    selectedPackage: {
      create_account_id: 'string',
      created_at: '',
      description: '',
      id: '',
      interest_rate: 0,
      name: '',
      payment_frequency_type: '',
      time_period: 0,
      update_account_id: {
        String: '',
        Valid: false,
      },
      updated_at: { Time: '', Valid: false },
    },
    errorMessage: '',
    inProgress: false,
  },
  depositModal: {
    step: 0,
    open: false,
    depositOrder: {
      fail: false,
      error: null,
      inProgress: false,
      success: false,
      order: null,
    },
    depositCheckout: {
      fail: false,
      error: null,
      inProgress: false,
      success: false,
      order: null,
    },
    data: {
      amount_in_eurocents: undefined,
    },
  },
  buySellModal: {
    isOpen: false,
    inProgress: false,
  },
  step: 0,
  withdrawInProgress: false,
  withdrawError: null,
  withdrawSuccess: null,
  revolutCounterpartyData: {
    amount_in_eurocents: undefined,
    code: '',
  },
  getRevolutCounterparty: { inProgress: false, fail: false, success: false },
  entireWalletBalance: {
    currency: {
      decimals: 0,
      name: '',
      network: '',
      symbol: '',
    },
    wallet: {
      address: '',
      aliases: [],
      balance: 0,
      pending_balance: 0,
      symbol: '',
      escrow_balance: 0,
      invested_balance: 0,
    },
  },
  swapSLTtoSLC: {
    completed: false,
    inProgress: false,
    success: false,
    fail: false,
    error: null,
  },
  buyWalletModal: {
    inProgress: false,
    success: false,
    fail: false,
    error: null,
    isOpen: false,
  },
};
