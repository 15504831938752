import {
  AllTransactionTableState,
  Filter,
  SelectedUser,
  WalletTransfer,
  WalletTransfersState,
} from '../../../../../../data/repositories/admin/typedefs';

export const AllTransactionInitialState: AllTransactionTableState = {
  referrals: null,
  inProgress: false,
  fail: false,
  error: null,
  page: 0,
  perPage: 10,
  cycles: [],
  stats: {
    totalReferrals: null,
    directReferrals: null,
  },
};

export const walletTransfersInitialState: WalletTransfersState = {
  walletTransfers: new Map<string, WalletTransfer>(),
  inProgress: false,
  fail: false,
  error: null,
  page: 0,
  perPage: 10,
  totalRows: 0,
  totalSum: null,
};

export type UserTransactionHistoryState = {
  allTransactions: AllTransactionTableState;
  walletTransfers: WalletTransfersState;
  appliedFilters: Filter;
  exportInProgress: boolean;
  error: Error | null;
  activeTab: string;
  selectedUser: SelectedUser;
};

export const selectedUserInitialState = {
  id: '',
  email: '',
  firstName: '',
  lastName: '',
};

export const initialState: UserTransactionHistoryState = {
  allTransactions: AllTransactionInitialState,
  walletTransfers: walletTransfersInitialState,
  appliedFilters: new Map<string, string[]>(),
  exportInProgress: false,
  error: null,
  activeTab: 'all_transactions',
  selectedUser: selectedUserInitialState,
};
