import { connect } from 'react-redux';
import { setCurrentHomePage } from '../../state/common/actions';
import Affiliate from './Affiliate';
import { AppState } from '../../../state/initialState';
import { affiliateTableStateSelector } from './AffiliateTable/state/selectors';
import { clearAllFilters } from './AffiliateTable/state/actions';
import { getWalletBalance } from '../Wallet/state/actions';
const mapDispatchToProps = {
  setCurrentHomePage: setCurrentHomePage,
  clearAllFilters: clearAllFilters,
  getWalletBalance: getWalletBalance,
};
const mapStateToProps = (state: AppState) => ({ ...affiliateTableStateSelector(state) });
export default connect(mapStateToProps, mapDispatchToProps)(Affiliate);
