import { connect } from 'react-redux';
import { setCurrentHomePage } from '../../state/common/actions';

import Orders from './Orders';
import { clearAllFilters } from './AffiliateMerchantsTable/state/actions';
import { AppState } from '../../../state/initialState';
import { affiliateMerchantsTableStateSelector } from './state/selectors';
import { getWalletBalance } from '../Wallet/state/actions';
// import { clearAllFilters } from './state/actions';

const mapStateToProps = (state: AppState) => ({
  ordersState: affiliateMerchantsTableStateSelector(state),
});

const mapDispatchToProps = {
  setCurrentHomePage: setCurrentHomePage,
  clearAllFilters: clearAllFilters,
  getWalletBalance: getWalletBalance,
};

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
