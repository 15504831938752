import { Input } from '@material-ui/core';
import React from 'react';

type Props = {
  field: {
    name: string;
    value: string;
    onChange(): void;
  };
  form: {
    touched: boolean;
    error: boolean;
  };
  props: any;
};

const TextInput: React.FC<Props> = ({ field, form: { touched, error }, ...props }: Props) => (
  <Input fullWidth error={touched && error} {...field} {...props} />
);

export default TextInput;
