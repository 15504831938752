/* eslint-disable */
import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import {
  SaveTransactionFilter,
  SaveTransferRequest,
  SwapInfo,
} from '../../../../../data/repositories/transaction/typedef';

export const GET_TRANSFERS = 'transactions/get_transfers';
export const GET_TRANSFERS_SUCCESS = 'transactions/get_transfers_success';
export const GET_TRANSFERS_FAIL = 'transactions/get_transfers_fail';
export const GET_TRANSFERS_FOR_COIN = 'transactions/get_transfers_for_coin';
export const GET_TRANSFERS_FOR_COIN_SUCCESS = 'transactions/get_transfers_for_coin_success';
export const GET_TRANSFERS_FOR_COIN_FAIL = 'transactions/get_transfers_for_coin_fail';
export const SAVE_TRANSFERS = 'transactions/save_transfers';
export const GET_TRANSFERS_SET_LOADING = 'transactions/get_transfer_set_loading';
export const TOGGLE_FILTER = 'transactions/toggle_filter';
export const CLEAR_ALL_FILTERS = 'transactions/clear_all_filters';
export const EXPORT_USERS = 'csv-export/users';
export const EXPORT_USERS_SUCCESS = 'csv-export/users_success';
export const EXPORT_USERS_FAIL = 'csv-export/users_fail';
export const EXPORT_TRANSACTIONS = 'csv-export/transactions';
export const EXPORT_TRANSACTIONS_SUCCESS = 'csv-export/transactions_success';
export const EXPORT_TRANSACTIONS_FAIL = 'csv-export/transactions_fail';
export const IS_TRANSACTION_HASH_DIALOG_OPEN = 'transactions/is_transaction_hash_dialog_open';
export const SET_TRANSACTION_HASH_DIALOG_TX_ID = 'transactions/set_transaction_hash_dialog_txhash';
export const CLEAR_TRANSACTION_HASH_DIALOG_STATE =
  'transactions/clear_transaction_hash_dialog_state';
export const GET_SWAP_INFO = 'transactions/get_swap_info';
export const GET_SWAP_INFO_SUCCESS = 'transactions/get_swap_info_success';
export const GET_SWAP_INFO_FAIL = 'transactions/get_swap_info_fail';

export const isTransactionHashDialogOpen = createStandardAction(IS_TRANSACTION_HASH_DIALOG_OPEN)<
  boolean
>();
export const setTransactionHashDialogTxId = createStandardAction(SET_TRANSACTION_HASH_DIALOG_TX_ID)<
  string
>();

export const getTransfers = createStandardAction(GET_TRANSFERS)<undefined>();
export const getTransfersSuccess = createStandardAction(GET_TRANSFERS_SUCCESS)<undefined>();
export const getTransfersFail = createStandardAction(GET_TRANSFERS_FAIL)<Error>();
export const getTransfersForCoin = createStandardAction(GET_TRANSFERS_FOR_COIN)<string>();
export const getTransfersForCoinSuccess = createStandardAction(GET_TRANSFERS_FOR_COIN_SUCCESS)<
  SaveTransferRequest
>();
export const getTransfersForCoinFail = createStandardAction(GET_TRANSFERS_FOR_COIN_FAIL)<Error>();
export const saveTransfers = createStandardAction(SAVE_TRANSFERS)<SaveTransferRequest>();
export const getTransferSetLoading = createStandardAction(GET_TRANSFERS_SET_LOADING)<boolean>();

export const toggleFilter = createStandardAction(TOGGLE_FILTER)<SaveTransactionFilter>();
export const clearAllFilters = createStandardAction(CLEAR_ALL_FILTERS)<undefined>();

export const clearTransactionHashDialogState = createStandardAction(
  CLEAR_TRANSACTION_HASH_DIALOG_STATE,
)<undefined>();

export const asyncExportUsers = createAsyncAction(
  EXPORT_USERS,
  EXPORT_USERS_SUCCESS,
  EXPORT_USERS_FAIL,
)<undefined, undefined, Error>();

export const asyncExportTransactions = createAsyncAction(
  EXPORT_TRANSACTIONS,
  EXPORT_TRANSACTIONS_SUCCESS,
  EXPORT_TRANSACTIONS_FAIL,
)<undefined, undefined, Error>();

export const asyncGetSwapInfo = createAsyncAction(
  GET_SWAP_INFO,
  GET_SWAP_INFO_SUCCESS,
  GET_SWAP_INFO_FAIL,
)<undefined, SwapInfo, Error>();
export * from '../TransfersTable/state/actions';
