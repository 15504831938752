import { combineReducers } from 'redux';
import productsPurchaseHistory from '../ProductsPurchaseHistory/state/reducer';
import transactionGrouping from '../TransactionGrouping/state/reducer';
import userEarningsTable from '../UserEarningsTable/state/reducer';
import userTransactionHistory from '../UserTransactionHistory/state/reducer';
import changeUserPassword from '../ChangeUserPassword/state/reducer';

export default combineReducers({
  userEarningsTable,
  productsPurchaseHistory,
  transactionGrouping,
  userTransactionHistory,
  changeUserPassword,
});
