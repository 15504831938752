import {
  createStyles,
  FormLabel,
  Grid,
  LinearProgress,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { Colors } from '../../../ui/style/colors';
import balanceUtils from '../../../utils/balanceUtils';
import {
  WIRE_EUR_DECIMALS,
  WIRE_EUR_MAX_FRACTION,
  WIRE_EUR_MIN_FRACTION,
} from '../../../utils/constants/amounts';
import { getProductPhase, handleDuration, renderPrice } from '../../../utils/productUtils';

const useStyles = makeStyles(() =>
  createStyles({
    labels: {
      fontSize: 14,
      color: `${Colors.wildBlueYonder}`,
    },
    primaryLabel: {
      fontSize: 14,
    },
    fundedProgressBar: {
      height: 19,
      backgroundColor: `${Colors.white}`,
      border: `1px solid ${Colors.wildBlueYonder}`,
    },
    barColorPrimary: {
      backgroundColor: `${Colors.wildBlueYonder}`,
    },
    fundedWrapper: {
      position: 'relative',
    },
    funded: {
      position: 'absolute',
      top: 3,
      right: '50%',
      transform: 'translateX(50%)',
      color: `${Colors.blueHaze}`,
      fontSize: 12,
    },
  }),
);

const ProductMainInfo = (props: any) => {
  const classes = useStyles();
  const { product } = props;

  const fundedValue = Number(product.percent_funded.toFixed(2));
  return (
    <Grid container>
      {/*Phase cell*/}
      <Grid item xs={6} zeroMinWidth>
        <FormLabel className={classes.labels}>Phase</FormLabel>
      </Grid>
      <Grid item xs={6} zeroMinWidth>
        <Typography align="right" className={classes.primaryLabel} component="p">
          {getProductPhase(product.product_phase)}
        </Typography>
      </Grid>

      {/*Funded cell*/}
      <Grid item xs={6} zeroMinWidth>
        <FormLabel className={classes.labels}>Funded</FormLabel>
      </Grid>
      <Grid item xs={6} className={classes.fundedWrapper} zeroMinWidth>
        <LinearProgress
          classes={{
            root: classes.fundedProgressBar,
            barColorPrimary: classes.barColorPrimary,
          }}
          variant="determinate"
          value={fundedValue >= 100 ? 100 : fundedValue}
        />
        <span className={classes.funded}>{fundedValue}%</span>
      </Grid>

      {/*Product price cell is value in eur of 1SLC price*/}
      <Grid item xs={6} zeroMinWidth>
        <FormLabel className={classes.labels}>Product price of 1SLC(EUR)</FormLabel>
      </Grid>
      <Grid item xs={6} zeroMinWidth>
        <Typography align="right" className={classes.primaryLabel} component="p">
          {renderPrice(product.product, product.product_phase)} EUR
        </Typography>
      </Grid>

      {/*Goal cell is maximum goal*/}
      <Grid item xs={6} zeroMinWidth>
        <FormLabel className={classes.labels}>Goal</FormLabel>
      </Grid>
      <Grid item xs={6} zeroMinWidth>
        <Typography align="right" className={classes.primaryLabel} component="p">
          {balanceUtils.formatBalanceToString(
            product.product.hard_cap || 0,
            WIRE_EUR_DECIMALS,
            WIRE_EUR_MIN_FRACTION,
            WIRE_EUR_MAX_FRACTION,
          )}
          EUR
        </Typography>
      </Grid>

      {/*Backers cell*/}
      <Grid item xs={6} zeroMinWidth>
        <FormLabel className={classes.labels}>Backers</FormLabel>
      </Grid>
      <Grid item xs={6} zeroMinWidth>
        <Typography align="right" className={classes.primaryLabel} component="p">
          {product.backer_count}
        </Typography>
      </Grid>

      {/*Time left cell day? hour? minutes*/}
      <Grid item xs={6} zeroMinWidth>
        <FormLabel className={classes.labels}>Time left</FormLabel>
      </Grid>
      <Grid item xs={6} zeroMinWidth>
        <Typography align="right" className={classes.primaryLabel} component="p">
          {handleDuration(product.time_left)}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ProductMainInfo;
