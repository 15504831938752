import { connect } from 'react-redux';
import { AppState } from '../../../../../App/state/initialState';
import { asyncGetEntiretWalletBalances } from '../../../state/actions';
import { entireWalletBalanceSelector } from '../../Wallet/state/selectors';
import {
  asyncGetBalanceFromAffiliateUser,
  asyncTradeSLC,
  setDialogStep,
  setUserForDialog,
} from '../state/actions';
import { getDialogStateSelector } from '../state/selectors';
import AffiliateAddDeduct from './AffiliateAddDeduct';

const mapDispatchToProps = {
  asyncTradeSLC: asyncTradeSLC.request,
  asyncGetEntiretWalletBalances: asyncGetEntiretWalletBalances.request,
  asyncGetBalanceFromAffiliateUser: asyncGetBalanceFromAffiliateUser.request,
  setDialogStep: setDialogStep,
  setUserForDialog: setUserForDialog,
};
const mapStateToProps = (state: AppState) => {
  const dialogState = getDialogStateSelector(state);
  const entireWalletBalance = entireWalletBalanceSelector(state);
  return { dialogState, entireWalletBalance };
};

export default connect(mapStateToProps, mapDispatchToProps)(AffiliateAddDeduct);
