/* eslint-disable */
import { createStyles, Dialog, Divider, Grid, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import { EmptyAC, PayloadAC } from 'typesafe-actions';
import WithdrawIcon from '../../../../../assets/deposit.svg';
import OutlinedTextField from '../../../../../components/CopyTextField';
import CustomButtonPrimary from '../../../../../components/CustomButtons/CustomButtonPrimary';
import CustomButtonSecondary from '../../../../../components/CustomButtons/CustomButtonSecondary';
import DepositButtonWithModal from '../../../../../components/DepositButtonWithModal';
import ModalState from '../../../../../components/ModalState';
import {
  Coin,
  CreateWalletForCurrencyRequest,
} from '../../../../../data/repositories/wallet/typedefs';
import { Colors } from '../../../../../ui/style/colors';
import balanceUtils from '../../../../../utils/balanceUtils';
import {
  WIRE_EUR_MAX_FRACTION,
  WIRE_EUR_MIN_FRACTION,
} from '../../../../../utils/constants/amounts';
import { getCurrencyFraction } from '../../../../../utils/helper/numbers';
import { getCurrencySymbolName } from '../../../../../utils/helper/utils';
import numberUtils from '../../../../../utils/numberUtils';
import useExchangeCurrencyPrices from '../../Exchange/ExchangeCurrency/useExchangeCurrencyPrices';
import { IS_TWO_FACTOR_AUTH_ENABLED } from '../../Settings/state/actions';
import AffiliatePremiumTable from '../../Transactions/AffiliatePremiumTable';
import TransactionsFilterBar from '../../Transactions/TransactionsFilterBar';
import WalletTransfersFilterBar from '../../Transactions/TransfersFilterBar';
import TransfersTable from '../../Transactions/TransfersTable';
import { OPEN_BUY_SELL_MODAL } from '../state/actions';
import InvestDialog from './InvestDialog';

type StyleProps = {
  width: number;
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: 24,
      paddingTop: 30,
    },
    totalBalanceLabel: {
      color: theme.palette.primary.light,
      fontSize: 18,
      fontWeight: 'bold',
    },
    totalBalanceSLCLabel: {
      color: theme.palette.secondary.light,
      fontSize: 18,
      marginLeft: 24,
    },
    buttonsWrapper: {
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        '& button': {
          margin: '30px 0 0',
        },
        '& div>button': {
          width: '100%',
        },
      },
    },
    withdrawButton: {
      boxSizing: 'border-box',
      marginBottom: 35,
      marginLeft: 30,
      marginTop: 30,
      fontSize: 14,
      fontWeight: 600,
      letterSpacing: 0.88,
      textAlign: 'center',
      textTransform: 'none',
      height: 48,
    },
    investButton: {
      textTransform: 'none',
      fontSize: 14,
      marginTop: 30,
      height: 48,
      fontWeight: 600,
      marginLeft: 30,
    },
    depositIcon: {
      marginRight: 23,
    },
    withdrawIcon: {
      marginRight: 16,
      '&:hover': {
        fill: `${Colors.white}`,
      },
    },
    avatar: {
      backgroundColor: 'blue[100]',
      color: 'blue[600]',
    },
    divider: {
      width: '100%',
      marginBottom: 40,
    },
    name: {
      color: theme.palette.primary.light,
      fontSize: 32,
      marginLeft: 16,
    },
    transactionsLabel: {
      color: theme.palette.secondary.light,
      fontSize: 18,
      fontWeight: 800,
      marginBottom: 30,
    },

    investIcon: {
      position: 'relative',
      left: '-9px',
    },
    flex: {
      display: 'flex',
      alignItems: 'center',
    },
    greenColor: {
      color: `${Colors.havelockBlue}`,
    },
    addressWrapper: {
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    address: {
      width: (props: StyleProps) => props.width,
      [theme.breakpoints.down('sm')]: {
        width: '100%!important',
      },
    },
    icon: {
      width: 80,
      height: 80,
    },
    marginBottom: {
      marginBottom: 10,
    },
    balance: {
      fontSize: 36,
      fontWeight: 'bold',
      color: `${Colors.blueCharcoal}`,
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.3rem',
      },
    },
    eurValue: {
      fontSize: 26,
      color: theme.palette.primary.light,
      [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
      },
    },
    balanceLabel: {
      marginBottom: 10,
      fontSize: '1rem',
      color: theme.palette.secondary.light,
      textAlign: 'center',
    },
    pendingBalance: {
      color: theme.palette.secondary.light,
    },
    dialog: {
      height: 'center',
      width: 'center',
      borderRadius: 6,
      backgroundColor: 'transparent',
      paddingLeft: 30,
      paddingRight: 30,
    },
  });

const useStyles = makeStyles(styles);

type Props = {
  totalBalanceEUR: string;
  totalBalanceSLC: string;
  createWalletForCurrency: PayloadAC<
    'wallet/create_wallet_for_currency',
    CreateWalletForCurrencyRequest
  >;
  allCoins: Map<string, Coin>;
  setSelectedCoin: PayloadAC<'wallet/set_selected_coin', string>;
  transactionsIds: string[];
  openWithdrawDialog: PayloadAC<'wallet/open_withdraw_dialog', number>;
  openBuySellModal: PayloadAC<typeof OPEN_BUY_SELL_MODAL, boolean>;
  isInvestDialogOpen: PayloadAC<'wallet/is_invest_dialog_open', boolean>;
  getTransfersForCoin: PayloadAC<'transactions/get_transfers_for_coin', string>;
  wallet?: any;
  withdrawSuccess: boolean | null;
  clearWithdrawState: EmptyAC<'wallet/clear_withdraw_state'>;
  getWalletBalance: EmptyAC<'wallet/get_wallet_balance'>;
  isTwoFactorAuthEnabled: EmptyAC<typeof IS_TWO_FACTOR_AUTH_ENABLED>;
  is2FaEnabled: any;
};

const calculateAddressWidth = (address: string) => {
  // If address is being created it will return '0x' from BE,
  // so we hijack this method and return fixed width for explanation
  if (address === '0x') {
    return 505;
  }

  const addressLength = address.length;
  const uppercaseLettersInAddress = address.replace(/[^A-Z]/g, '').length;
  let characterLength =
    uppercaseLettersInAddress / addressLength > 0.6
      ? 11.3
      : uppercaseLettersInAddress / addressLength > 0.4
      ? 10.8
      : 10.5;
  return addressLength * characterLength;
};

const WalletCoin: React.FC<Props> = ({
  allCoins,
  totalBalanceEUR,
  totalBalanceSLC,
  createWalletForCurrency,
  setSelectedCoin,
  transactionsIds,
  openWithdrawDialog,
  openBuySellModal,
  isInvestDialogOpen,
  getTransfersForCoin,
  wallet,
  withdrawSuccess,
  clearWithdrawState,
  getWalletBalance,
  isTwoFactorAuthEnabled,
  is2FaEnabled,
}: Props): JSX.Element => {
  const { balance, decimals, price_eur, symbol, address, escrow_balance, icon, pending_balance } =
    wallet;
  const styleProps = { width: calculateAddressWidth(address) };
  const classes = useStyles(styleProps);

  useEffect(() => {
    setSelectedCoin(symbol);
    createWalletForCurrency({ symbol: symbol });
    isTwoFactorAuthEnabled();
    if (transactionsIds.length === 0) {
      getTransfersForCoin(symbol);
    }
  }, []);

  useEffect(() => {
    if (withdrawSuccess) {
      clearWithdrawState();
      getWalletBalance();
    }
  });
  const [isOpen, setIsOpen] = useState(false);

  const handleInvestOpen = () => {
    isInvestDialogOpen(true);
  };

  const convertedBalance = balanceUtils.formatBalanceToBigWithDecimals(
    balance === 'string' ? '0' : balance,
    decimals,
  );
  const { getSelectedCoinMarketVal } = useExchangeCurrencyPrices();
  const value = Number(price_eur ? price_eur : 0) * Number(convertedBalance);
  const cryptoValueInEur = numberUtils.formatNumberDigits(
    parseFloat(String(value) || '0'),
    WIRE_EUR_MIN_FRACTION,
    WIRE_EUR_MAX_FRACTION,
  );
  const symbolName = getCurrencySymbolName(symbol);

  const getBalance = (symbol: string, balance: string) =>
    balanceUtils.formatBalanceToString(
      balance === 'string' ? '0' : balance,
      decimals,
      getCurrencyFraction(symbol, 'min'),
      getCurrencyFraction(symbol, 'max'),
    );
  const handleWithdrawOpen = () => {
    if (!is2FaEnabled) {
      setIsOpen(true);
    } else {
      getBalance(symbolName, balance) == 0 ||
      (symbol === 'WALLET_EUR' && getBalance(symbolName, balance) <= 5)
        ? setIsOpen(true)
        : openWithdrawDialog(1);
    }
  };
  const handleBuySellOpen = () => {
    return openBuySellModal(true);
  };
  return (
    <Grid container direction="column">
      <Grid item>
        <Grid container direction="column" alignItems="center" justifyContent="center">
          <img src={icon} className={`${classes.icon} ${classes.marginBottom}`} />
          <Grid item>
            {symbol === 'WALLET_EUR' ? (
              <>
                <Typography className={classes.balanceLabel}>TOTAL / PENDING BALANCE</Typography>
                <Typography className={classes.balance}>
                  <span>{getBalance(symbolName, balance)}</span>
                  <span className={classes.pendingBalance}>
                    &nbsp;/ {getBalance(symbolName, pending_balance)} {symbolName}
                  </span>
                </Typography>
              </>
            ) : (
              <Typography className={classes.balance}>{`${getBalance(
                symbolName,
                balance,
              )} ${symbolName}`}</Typography>
            )}
          </Grid>
          {symbol !== 'WALLET_EUR' && (
            <Grid item>
              <Typography className={`${classes.eurValue} ${classes.marginBottom}`}>
                {cryptoValueInEur} EUR
              </Typography>
            </Grid>
          )}
          {symbol !== 'SLC' && (
            <Grid item>
              <Typography className={`${classes.greenColor} ${classes.marginBottom}`}>
                Secured by {symbol && symbol === 'WALLET_EUR' ? 'Revolut' : 'BitGo'}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      {symbol !== 'WALLET_EUR' && (
        <Grid item>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            className={classes.addressWrapper}
          >
            <Typography>Your {symbolName} address</Typography>
            <OutlinedTextField text={address} customClass={classes.address} />
          </Grid>
        </Grid>
      )}
      <Grid item>
        <Grid
          container
          item
          direction="row"
          justifyContent="center"
          className={classes.buttonsWrapper}
        >
          <DepositButtonWithModal symbol={symbol} />
          <Dialog open={isOpen} className={classes.dialog}>
            <ModalState
              onExit={() => setIsOpen(false)}
              message={
                !is2FaEnabled
                  ? 'First enable 2FA authentication'
                  : 'Not enough funds on your balance'
              }
              variant={!is2FaEnabled ? 'warning' : 'failed'}
              showCloseButton={false}
            >
              {is2FaEnabled ? null : (
                <Link
                  to={{
                    pathname: '/settings',
                    search: '?auth=true',
                  }}
                >
                  Enable here
                </Link>
              )}
            </ModalState>
          </Dialog>
          <CustomButtonSecondary
            variant="outlined"
            size="large"
            className={classes.withdrawButton}
            onClick={handleWithdrawOpen}
          >
            <SVG
              className={classes.withdrawIcon}
              preProcessor={code => code.replace(/fill=".*?"/g, 'fill="currentColor"')}
              src={WithdrawIcon}
            />
            Withdraw {symbol === 'WALLET_EUR' ? 'EUR' : 'Currencies'}
          </CustomButtonSecondary>
          {/* Removed this feature because it is not comlpleted yet */}
          {/* {symbol !== 'WALLET_EUR' && symbol !== 'SLC' ? (
            <CustomButtonPrimary
              variant="outlined"
              size="large"
              className={classes.withdrawButton}
              onClick={handleBuySellOpen}
            >
              Buy/Sell
            </CustomButtonPrimary>
          ) : null} */}
          {symbol === 'SLC' && wallet ? (
            <>
              <CustomButtonPrimary
                variant="contained"
                size="large"
                className={classes.investButton}
                onClick={handleInvestOpen}
              >
                HODL SLC
              </CustomButtonPrimary>
              <InvestDialog
                totalEscrow={escrow_balance}
                walletBalance={balance}
                walletDecimals={decimals}
              />
            </>
          ) : null}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider className={classes.divider}></Divider>
        <Typography className={classes.transactionsLabel} component="p">
          Recent transaction
        </Typography>

        {symbol === 'SLC' ? (
          <>
            <TransactionsFilterBar />
            <AffiliatePremiumTable />
          </>
        ) : (
          <React.Fragment>
            <WalletTransfersFilterBar coin={symbol} />
            <TransfersTable coin={symbol} />
          </React.Fragment>
        )}
      </Grid>
    </Grid>
  );
};

export default WalletCoin;
