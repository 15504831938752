import { connect } from 'react-redux';
import { AppState } from '../../../../state/initialState';
import {
  asyncGetFriendsInvitationsPaginated,
  clearAllFilters,
  toggleFilter,
} from '../state/actions';
import { getAppliedFiltersSelector } from '../state/selectors';
import InvitesFilterBar from './InvitesFilterBar';

const mapStateToProps = (state: AppState) => {
  const appliedFilters = getAppliedFiltersSelector(state);

  return { appliedFilters };
};

const dispatchStateToProps = {
  asyncGetFriendsInvitationsPaginated: asyncGetFriendsInvitationsPaginated.request,
  toggleFilter,
  clearAllFilters: clearAllFilters,
};

export default connect(mapStateToProps, dispatchStateToProps)(InvitesFilterBar);
