import moment from 'moment';
import {
  ChartPeriodFilterType,
  CryptoNonFormatedResponse,
  CryptoNonFormatedResponseFallback,
  GetCryptoChartRequest,
} from '../data/repositories/dashboard/typedefs';
import { CRYPTO_CHART_INFO_DECIMALS } from './constants/amounts';
import { convertToInternationalCurrencySystem } from './helper/utils';

const CHART_PERIOD_FILTERS: ChartPeriodFilterType[] = [
  {
    name: 'day',
    symbol: '1d',
    xAxisDateFormat: 'ddd HHa',
  },
  { name: 'week', symbol: '1w', xAxisDateFormat: 'MMM Do' },
  {
    name: 'month',
    symbol: '1m',
    xAxisDateFormat: 'MMM Do',
  },
  {
    name: 'quarter',
    symbol: '3m',
    xAxisDateFormat: "MMM 'YY",
  },
  {
    name: 'year',
    symbol: '1y',
    xAxisDateFormat: "MMM 'YY",
  },
];

const getCoinSymbolName = (item: string) => {
  switch (item) {
    case 'litecoin':
      return 'LTC';
    case 'bitcoin':
      return 'BTC';
    case 'bitcoin_cash':
      return 'BCH';
    case 'ethereum':
      return 'ETH';
    case 'tether':
      return 'USDT';
    case 'ltc':
      return 'LTC';
    case 'btc':
      return 'BTC';
    case 'bch':
      return 'BCH';
    case 'eth':
      return 'ETH';
    case 'usdt':
      return 'USDT';
  }
};
const getLastFromObject = (obj: any) => obj[Object.keys(obj)[Object.keys(obj).length - 1]];

//Display date format for the x axis depending on the selected period request.
const formatDateBySelectedPeriod = (selectedPeriod: GetCryptoChartRequest) =>
  CHART_PERIOD_FILTERS.filter(item => item.name === selectedPeriod)[0]['xAxisDateFormat'];

const getDailyChange = (prices: Map<string, number>) => {
  const last24HValues: number[] = [];

  Object.entries(prices).map(([key, value]) => {
    const currentTime = moment();
    const duration = moment.duration(currentTime.diff(moment.unix(Number(key)))).asHours();
    duration <= 24 && last24HValues.push(value);
  });
  const firstElement = last24HValues[0];
  const lastElement = last24HValues[last24HValues.length - 1];
  const percentageDifference = ((lastElement - firstElement) / firstElement) * 100;
  return Number(percentageDifference.toFixed(CRYPTO_CHART_INFO_DECIMALS));
};

//Format data for crypto chart.
//Display date format for the x axis depending on the selected period request. We get the format value from formatDateBySelectedPeriod
//date property is used for the chart Tooltip
const formatChartValues = (
  prices: Map<string, number>,
  selectedPeriod: GetCryptoChartRequest,
  unix = true,
) => {
  const getValue = (key: string) => (unix ? moment.unix(Number(key)) : moment(key));

  return Object.entries(prices).map(([key, value]) => ({
    xAxis: getValue(key).format(formatDateBySelectedPeriod(selectedPeriod) || 'MMMM Do'),
    yAxis: value,
    date: getValue(key).format('MMMM Do, YYYY HH:mm:ss'),
  }));
};

//For Volume & market cap value we use the last item from the given response, and value is displayed in billions
//Daily change is optional
const formatData = (
  { price, market_cap, total_volume }: CryptoNonFormatedResponse,
  selectedPeriod: GetCryptoChartRequest,
  calculateDailyChange = false,
) => ({
  prices: formatChartValues(price, selectedPeriod),
  volume: convertToInternationalCurrencySystem(getLastFromObject(total_volume)),
  marketCap: convertToInternationalCurrencySystem(getLastFromObject(market_cap)),
  dailyChange: calculateDailyChange ? getDailyChange(price) : undefined,
});

const formatFallbackData = ({
  price,
  market_cap,
  volume_24h,
  change_24h,
}: CryptoNonFormatedResponseFallback) => ({
  prices: price,
  volume: volume_24h,
  marketCap: market_cap,
  dailyChange: change_24h,
});
//Get past date from now regarding to selected period filter
//e.g. for "week" period filter, get exact date a week before today
const getPastDateFromNow = (selectedPeriod: GetCryptoChartRequest) => {
  const today = moment();

  switch (selectedPeriod) {
    case 'day':
      return today.subtract(1, 'days');
    case 'month':
      return today.subtract(1, 'months');
    case 'quarter':
      return today.subtract(3, 'months');
    case 'year':
      return today.subtract(1, 'years');
    default:
      return today.subtract(1, 'weeks');
  }
};

const calculatePercentageIncrease = (oldValue: number, value: number) =>
  ((value - oldValue) / oldValue) * 100;

const isInDateRange = (date: string | moment.Moment, startDate: string, endDate: string) =>
  moment(date).isBetween(startDate, endDate ? endDate : new Date());

export default {
  formatData,
  formatFallbackData,
  getCoinSymbolName,
  CHART_PERIOD_FILTERS,
  formatChartValues,
  getPastDateFromNow,
  calculatePercentageIncrease,
  isInDateRange,
};
