import { connect } from 'react-redux';
import { AppState } from '../../../../state/initialState';
import { userStateSelector } from '../../../../state/selectors';
import AffiliateTable from './AffiliateTable';
import {
  asyncChangeUserStatus,
  asyncGetReferralsPaginated,
  asyncResetUserKYCStatus,
  asyncToggleAdminRole,
  isAddDeductDialogOpen,
  isFullProfileDialogOpen,
  setUserForDialog,
} from './state/actions';
import {
  affiliateTableStateSelector,
  getAppliedFiltersSelector,
  getAreAnyFiltersApplied,
} from './state/selectors';

const mapDispatchToProps = {
  asyncGetReferralsPaginated: asyncGetReferralsPaginated.request,
  asyncChangeUserStatus: asyncChangeUserStatus.request,
  asyncToggleAdminRole: asyncToggleAdminRole.request,
  asyncResetUserKYCStatus: asyncResetUserKYCStatus.request,
  isAddDeductDialogOpen: isAddDeductDialogOpen,
  isFullProfileDialogOpen,
  setUserForDialog: setUserForDialog,
};

const mapStateToProps = (state: AppState) => ({
  ...affiliateTableStateSelector(state),
  anyFilters: getAreAnyFiltersApplied(state),
  appliedFilters: getAppliedFiltersSelector(state),
  user: userStateSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(AffiliateTable);
