import { AnyAction } from 'redux';
import { all, put, takeEvery } from 'redux-saga/effects';
import LocalStorage from '../../../../../data/LocalStorage';
import apiAccount from '../../../../../data/repositories/account';
import api from '../../../../../data/repositories/infoDialog';
import { REFRESH_TOKEN, USER_ID } from '../../../../../utils/constants/localStorageKeys';
import {
  getProfileInfoFail,
  getProfileInfoSuccess,
  GET_PROFILE_INFO,
} from '../../../state/actions';
import { asyncKycUpdateAplicant, KYC_UPDATE_APLICANT, setInfoDialogFields } from './actions';

export function* getProfileInfo$() {
  const userId = LocalStorage.get(USER_ID);
  if (userId) {
    try {
      const response = yield apiAccount.getProfileInfo(userId || '');
      const hasKyc = response.kyc_info;
      const hasAffiliatePackage = !!response.affiliate_package_info;
      yield put(getProfileInfoSuccess(response));

      if (response.should_swap) {
        /**Add lock class to the root div to disable pointer events */
        const root = document.getElementById('root');
        root && root.classList.add('lock');

        yield put(
          setInfoDialogFields.request({
            dialogType: 'SwapSLTtoSLCDialog',
            title: 'Converting SLT currency to SLC',
            hideCloseButton: true,
            dialogProps: {
              maxWidth: 'md',
            },
          }),
        );
      }

      if (hasAffiliatePackage && !hasKyc) {
        yield put({ type: REFRESH_TOKEN });
      }
    } catch (error) {
      if (error.response.status === 451) {
        yield put({ type: REFRESH_TOKEN });
      } else {
        yield put(getProfileInfoFail(error));
        yield put(setInfoDialogFields.failure(error.message));
        yield put(setInfoDialogFields.request({ hideCloseButton: true }));
      }
    }
  }
}

export function* kycUpdateAplicant$(action: AnyAction) {
  try {
    const userId = LocalStorage.get(USER_ID);
    const response = yield api.kycUpdateAplicant(userId || '', action.payload);

    yield put(asyncKycUpdateAplicant.success(response));
    yield put(setInfoDialogFields.success('Profile successfully updated'));
  } catch (error) {
    yield put(asyncKycUpdateAplicant.failure(error));
    yield put(
      setInfoDialogFields.failure(
        error && error.message ? error.message : 'Update applicant failed!',
      ),
    );
  }
}

export default function* () {
  yield all([
    takeEvery(GET_PROFILE_INFO, getProfileInfo$),
    takeEvery(KYC_UPDATE_APLICANT, kycUpdateAplicant$),
  ]);
}
