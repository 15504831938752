import { Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useState } from 'react';
import SacretDaterangePickerSelect from '../../../../../components/SacrateDaterangePickerSelect';
import SacretSearchInput from '../../../../../components/SacretSearchInput';
import SacretSelect from '../../../../../components/SacretSelect';
import { Colors } from '../../../../../ui/style/colors';
import { ALL_COINS } from '../../../../../utils/constants/amounts';
import AppliedFiltersChips from './AppliedFiltersChips';

const styles = (theme: Theme) =>
  createStyles({
    filter: {
      minWidth: 200,
      marginRight: 10,
    },
    valueSlider: {},
    clearAll: {
      textTransform: 'none',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    clearAllIcon: {
      fontSize: 12,
      padding: 5,
    },
    label: {
      float: 'left',
      padding: '2px 0',
      fontSize: 12,
      color: theme.palette.primary.light,
    },
    inputError: {
      color: `${Colors.red}`,
      marginTop: 10,
      fontSize: 12,
    },
  });

const useStyles = makeStyles(styles);

type Props = {
  toggleFilter: any;
  appliedFilters: Map<string, string[]>;
  clearAllFilters: any;
  asyncTableDataPaginated: any;
  appliedFiltersIds: string[];
  areFiltersApplied: boolean;
  inputFields: string[];
};

const names = [
  { name: 'Affiliate became Partner', value: 'merchant_parent_reward' },
  { name: 'Became Partner', value: 'new_merchant_reward' },
  { name: 'Affiliate package purchase', value: 'premium_user_reward' },
  { name: 'BV', value: 'affiliate_percentage_reward' },
  { name: 'New Affiliate', value: 'new_user_affiliate_reward' },
  { name: 'Affiliate purchased Affiliate package', value: 'premium_user_affiliate_reward' },
  { name: 'Registration reward', value: 'new_user_reward' },
];

const statuses = [
  { name: 'Completed', value: 'completed' },
  { name: 'Pending', value: 'pending' },
  { name: 'Pending Escrow', value: 'pending_escrow' },
  { name: 'Timed Escrow', value: 'timed_escrow' },
  { name: 'Escrow', value: 'escrow' },
];

const walletCoins = ALL_COINS.map((coin: string) => ({ name: coin, value: coin }));

const transferTypes = [
  { name: 'Withdraw', value: 'send' },
  { name: 'Deposit', value: 'receive' },
  { name: 'Buy', value: 'buy' },
  { name: 'Sell', value: 'sell' },
  { name: 'Transfer', value: 'transfer' },
];

const userToUserTransferTypes = [
  { name: 'Withdraw', value: 'withdraw' },
  { name: 'Deposit', value: 'deposit' },
  { name: 'Wallet transfer', value: 'transfer' },
];

type ErrorMsg = {
  [searchByEmailInput: string]: string;
};
const FilterBar: React.FC<Props> = ({
  toggleFilter,
  clearAllFilters,
  appliedFilters,
  asyncTableDataPaginated,
  appliedFiltersIds,
  areFiltersApplied,
  inputFields,
}: Props): JSX.Element => {
  const classes = useStyles();
  const errorsInitialState: ErrorMsg = {
    searchByEmailInput: '',
  };
  const [errors, setError] = useState(errorsInitialState);
  const [query, setQuery]: any = useState('');

  const onInputChange = (value: string[], name: string) => {
    const object: any = {};
    appliedFilters.forEach((value, key) => {
      object[key] = value;
    });
    asyncTableDataPaginated({
      page: 0,
      perPage: 10,
      appliedFilters: { ...object, [name]: value },
    });
    toggleFilter({ filterType: name, value });
    if (name === 'searchByEmailInput') setQuery('');
  };

  const checkEmailField = (val: string[]) => {
    const value = val ? [val[0].trim()] : val; //Delete any extra spaces that appear when a user pastes copied email address
    setQuery(value);
  };

  const clearAppliedFilters = () => {
    clearAllFilters();
    setError(errorsInitialState);
  };
  const clearError = (name: string) => setError({ ...errors, [name]: '' });

  const availableFilterInputs = [
    {
      label: 'Status',
      name: 'status',
      component: SacretSelect,
      items: statuses,
    },
    {
      label: 'Transaction type',
      name: 'transactionType',
      component: SacretSelect,
      items: names,
    },
    {
      label: 'Choose a date',
      name: 'dateFilter',
      component: SacretDaterangePickerSelect,
    },
    {
      label: 'Coin',
      name: 'coinSymbol',
      component: SacretSelect,
      items: walletCoins,
    },
    {
      label: 'TransferType',
      name: 'transferType',
      component: SacretSelect,
      items: transferTypes,
    },
    {
      label: 'TransferType',
      name: 'userToUserTransferTypes',
      component: SacretSelect,
      items: userToUserTransferTypes,
    },

    {
      label: 'Search by user email',
      placeholder: 'Search by user email',
      name: 'searchByEmailInput',
      component: SacretSearchInput,
      onChange: checkEmailField,
      searchHandler: onInputChange,
    },
    {
      label: 'Search by email',
      placeholder: 'Search by email',
      name: 'searchByEmail',
      component: SacretSearchInput,
    },
  ];

  const renderInputs = () =>
    availableFilterInputs.map((input, key) => {
      if (inputFields.includes(input.name)) {
        const componentProps: any = {
          label: input.label,
          onChange: (value: string[]) =>
            input.onChange ? input.onChange(value) : onInputChange(value, input.name),
          stateFilterValue: input.onChange ? [query] : appliedFilters.get(input.name),
          name: input.name,
          searchHandler: () => onInputChange([query], input.name),
        };
        if (input.items) {
          componentProps.items = input.items;
        }
        if (input.placeholder) {
          componentProps.placeholder = input.placeholder;
        }
        return (
          <Grid xs={12} sm="auto" key={key} item className={classes.filter}>
            <input.component {...componentProps} />
            <div className={classes.inputError}>{errors[input.name] && errors[input.name]} </div>
          </Grid>
        );
      }
    });

  return (
    <Grid container spacing={2}>
      {renderInputs()}

      <AppliedFiltersChips
        clearAllFilters={clearAppliedFilters}
        clearError={clearError}
        stateAppliedFilters={appliedFilters}
        toggleFilter={toggleFilter}
        appliedFiltersIds={appliedFiltersIds}
        areFiltersApplied={areFiltersApplied}
        asyncTableDataPaginated={asyncTableDataPaginated}
      />
    </Grid>
  );
};

export default FilterBar;
