import { connect } from 'react-redux';
import { AppState } from '../../../../state/initialState';
import CurrencySettings from './CurrencySettings';
import {
  updateCurrencyRate,
  updateCurrencyRateClearState,
  updateCurrencyRateFail,
  updateCurrencyRateSuccess,
} from './state/actions';
import { updateCurrencyRateStateSelector } from './state/selectors';

const mapDispatchToProps = {
  updateCurrencyRate,
  updateCurrencyRateSuccess,
  updateCurrencyRateFail,
  updateCurrencyRateClearState,
};

const mapStateToProps = (state: AppState) => ({ ...updateCurrencyRateStateSelector(state) });

export default connect(mapStateToProps, mapDispatchToProps)(CurrencySettings);
