import { connect } from 'react-redux';
import { AppState } from '../../../../state/initialState';
import SwapSLTtoSLCDialog from './SwapSLTtoSLCDialog';
import { swapToSLCSelector } from '../../Wallet/state/selectors';

const mapStateToProps = (state: AppState) => {
  const swapToSLCSate = swapToSLCSelector(state);
  return { swapToSLCSate };
};

export default connect(mapStateToProps)(SwapSLTtoSLCDialog);
