import { createStyles, IconButton, LinearProgress, makeStyles, Paper } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PayloadAC } from 'typesafe-actions';
import ExchangeCurrencyTab from '../../../../../components/ExchangeCurrencyTab';
import { Colors } from '../../../../../ui/style/colors';
import { isUserMember } from '../../../../../utils/helper/utils';
import { AppState } from '../../../../state/initialState';
import theme from '../../../../theme';
import { INVEST_CURRENCY_SAVE_STATE } from '../../../state/actions';
import { membershipStateObject } from '../../Settings/Membership/state/initialState';
import Invest2fa from './Invest2fa';
import InvestCurrencyBuyOptions from './InvestCurrencyBuyOptions';
import InvestCurrencyDetails from './InvestCurrencyDetails';
import InvestCurrencySuccess from './InvestCurrencySuccess';
import {
  initialState,
  ProductCurrencyRequestState,
  ProductCurrencyState,
} from './state/initialState';

const styles = createStyles({
  paper: {
    borderRadius: 3,
    boxShadow: '0 10px 30px 0 rgba(132,141,182,0.25)',
  },
  indicator: {
    display: 'none',
  },
  unselectedTab: {
    boxSizing: 'border-box',
    border: '1px solid rgba(151, 151, 151, 0.2)',
    backgroundColor: 'rgba(190, 195, 216, 0.2)',
    height: 51,
  },
  selectedTab: {
    position: 'relative',
    backgroundColor: `${Colors.white}`,
    height: 51,
    pointerEvents: 'none',
  },
  tabLabel: {
    color: `${Colors.wildBlueYonder}`,
    fontSize: 16,
    fontWeight: 500,
    textTransform: 'none',
    zIndex: 3,
  },
  progressBar: {
    height: 6,
    borderRadius: '0 0 3px 3px ',
  },
  unselectedTabLabel: {
    color: `${Colors.blueHaze}`,
  },
  linearProgressOverride: {
    borderRadius: 3,
  },
  tooltip: {
    position: 'relative',
    top: 5,
    left: 5,
    fontSize: 20,
    color: `${Colors.black}`,
  },
  ignoreDisabledHover: {
    pointerEvents: 'all',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(0.5),
    top: theme.spacing(0.5),
  },
});

const useStyles = makeStyles(styles);

type Props = ProductCurrencyState & {
  setSelectedTab: PayloadAC<'invest/set_selected_tab', number>;
  onInvestCurrencyStep: PayloadAC<'invest/save_state', ProductCurrencyRequestState>;
  membershipState?: membershipStateObject;
  membershipLevel?: any;
  disabledAllCurrencies: boolean;
};

const InvestCurrency = ({
  step,
  selectedTab,
  membershipLevel,
  disabledAllCurrencies,
  ...restof
}: Props): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { selectedOption } = useSelector((state: AppState) => state.home.product.productInvest);
  const isMember = isUserMember(membershipLevel);
  useEffect(() => {
    if (!selectedTab)
      dispatch({
        type: INVEST_CURRENCY_SAVE_STATE,
        payload: {
          ...initialState,
          membershipState: restof.membershipState,
          order_payment_info: restof.order_payment_info,
          fetchMembershipProgress: restof.fetchMembershipProgress,
          selectedTab: selectedTab,
          step: selectedTab === 1 ? 1 : 0,
          isDialogOpen: true,
          selectedOption: disabledAllCurrencies
            ? ''
            : !isMember
            ? 'WIRE_EUR'
            : selectedTab === 1
            ? selectedOption === 'WIRE_EUR'
              ? 'SLC'
              : selectedOption
            : 'WIRE_EUR',
          time: moment().unix(),
        },
      });
  }, [selectedTab]);

  useEffect(() => {
    dispatch({ type: 'wallet/get_wallet_balance' });
    dispatch({ type: 'profile_info' });
  }, []);

  const onDialogClose = () => {
    dispatch({
      type: 'invest/dialogOpen',
      payload: false,
    });
  };

  const renderTab = () => {
    if (selectedTab === 0) {
      switch (step) {
        case 0:
          return <InvestCurrencyBuyOptions isMember={isMember} />;
        case 1:
          return <InvestCurrencyDetails />;
        case 2:
          return <Invest2fa />;
        case 3:
          return <InvestCurrencySuccess />;
        default:
      }
    } else {
      switch (step) {
        case 0:
          return <InvestCurrencyBuyOptions isMember={isMember} />;
        case 1:
          return <InvestCurrencyDetails />;
        case 2:
          return <InvestCurrencySuccess />;
        default:
      }
    }
  };

  return (
    <>
      <IconButton className={classes.closeButton} size="small" onClick={onDialogClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
      <Paper className={classes.paper}>
        <ExchangeCurrencyTab value={selectedTab} index={selectedTab}>
          {renderTab()}
        </ExchangeCurrencyTab>
        {step > 0 && selectedTab === 0 && (
          <LinearProgress
            variant="determinate"
            value={50 * step}
            className={classes.progressBar}
            color="secondary"
            classes={{ barColorSecondary: classes.linearProgressOverride }}
          />
        )}

        {step > 1 && selectedTab === 1 && (
          <LinearProgress
            variant="determinate"
            value={25 * step}
            className={classes.progressBar}
            color="secondary"
            classes={{ barColorSecondary: classes.linearProgressOverride }}
          />
        )}
      </Paper>
    </>
  );
};

export default InvestCurrency;
