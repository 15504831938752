/* eslint-disable */
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Slide,
  Snackbar,
  TextField,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import CloseIcon from '@material-ui/icons/Close';
import SaveAlt from '@material-ui/icons/SaveAlt';
import QRCode from 'qrcode.react';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { PayloadAC } from 'typesafe-actions';
import Shape from '../../../../../assets/Shape.svg';
import CustomButtonPrimary from '../../../../../components/CustomButtons/CustomButtonPrimary';
import SacretCoinSelect from '../../../../../components/SacretCoinSelect';
import {
  Coin,
  CreateWalletForCurrencyRequest,
  WalletMetadata,
} from '../../../../../data/repositories/wallet/typedefs';
import { Colors } from '../../../../../ui/style/colors';
import { ALLOWED_CRYPTO_FOR_MEMBERS } from '../../../../../utils/constants/amounts';
import { getAllowedCurrenciesForNonMembers, isUserMember } from '../../../../../utils/helper/utils';
import { AppState } from '../../../../state/initialState';
import { CREATE_WALLET_FOR_CURRENCY } from '../state/actions';
import { filterAvailableCoinsSelector } from '../state/selectors';
import DepositEurForm from './DepositEurForm';
import DepositSuccess from './DepositSuccess';

const styles = (theme: Theme) =>
  createStyles({
    dialogTittleText: {
      color: `${Colors.blueCharcoal}`,
      fontSize: 18,
      fontWeight: 600,
      letterSpacing: 1.13,
      display: 'flex',
      alignItems: 'center',
    },
    dialogTitle: {
      marginTop: 25,
      marginLeft: 25,
      marginRight: 25,
      padding: 0,
    },
    depositIcon: {
      marginRight: 20,
    },
    dialogPaper: {
      height: 'center',
      width: 'center',
      borderRadius: 6,
      backgroundColor: 'transparent',
      boxShadow: '0 10px 30px 0 rgba(132,141,182,0.25)',
    },
    closeButton: {
      float: 'right',
      verticalAlign: 'text-top',
    },
    currencyLabel: {
      color: theme.palette.primary.light,
      fontSize: 12,
      fontWeight: 600,
      marginBottom: 10,
    },
    button: {
      fontSize: 14,
      marginTop: 30,
      marginBottom: 20,
      textTransform: 'none',
    },
    inputField: {
      borderRadius: 6,
      marginBottom: 30,
      height: 50,
    },
    qrCode: {
      border: `2px solid ${Colors.white}`,
      borderRadius: 6,
      backgroundColor: `${Colors.white}`,
      textAlign: 'center',
    },
    dropdownStyle: {
      border: `1px solid ${theme.palette.secondary.light}`,
      borderRadius: 6,
    },
    snackbar: {
      background: `${Colors.apple}`,
    },
    value: {
      color: `${Colors.blueCharcoal}`,
      textAlign: 'center',
      marginBottom: 10,
      fontSize: 14,
    },
    input: {
      '&::placeholder': {
        color: theme.palette.secondary.light,
      },
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'none',
      },
      '&[type=number]': {
        '-webkit-appearance': 'textfield',
        '-moz-appearance': 'textfield',
      },
    },
    modalHeader: {
      marginBottom: 20,
    },
  });
const useStyles = makeStyles(styles);

type Props = {
  open: boolean;
  onClose: () => void;
  createWallet: PayloadAC<typeof CREATE_WALLET_FOR_CURRENCY, CreateWalletForCurrencyRequest>;
  depositWalletAddress: string;
  allCoins: Map<string, Coin>;
  selectedCoin: string;
  setSelectedCoin: PayloadAC<'wallet/set_selected_coin', string>;
  createWalletForCurrency: PayloadAC<
    typeof CREATE_WALLET_FOR_CURRENCY,
    CreateWalletForCurrencyRequest
  >;
  membershipLevel: any;
  wallet: WalletMetadata;
  step: number;
  hasCompletedKYC: boolean;
};

const DepositModal = ({
  onClose,
  open,
  createWallet,
  depositWalletAddress,
  allCoins,
  selectedCoin,
  setSelectedCoin,
  createWalletForCurrency,
  membershipLevel,
  wallet,
  step,
  hasCompletedKYC,
}: Props): JSX.Element => {
  const classes = useStyles();
  const TransitionUp = (props: TransitionProps) => <Slide {...props} direction="up" />;
  const isMember = isUserMember(membershipLevel);

  useEffect(() => {
    if (open && (!depositWalletAddress || depositWalletAddress === '')) {
      createWalletForCurrency({ symbol: selectedCoin });
    }
  }, [open]);

  const [snackbarOpen, setsnackbarOpen] = React.useState(false);
  const [transition, setTransition] = React.useState<
    React.ComponentType<TransitionProps> | undefined
  >(undefined);

  const handleSnackbarOpen = () => {
    setTransition(() => TransitionUp);
    setsnackbarOpen(true);
  };

  const handleSnackbarClose = () => setsnackbarOpen(false);

  const copyClipboard = () => {
    const copyText = document.getElementById('depositAddressInput') as HTMLInputElement;
    copyText.select();
    document.execCommand('Copy');
    copyText.blur();
    handleSnackbarOpen();
  };
  const addressValue =
    depositWalletAddress === '0x'
      ? 'It takes some time to generate the address, please try again later'
      : depositWalletAddress;
  const ac = new Map<string, Coin>(Object.entries(allCoins));
  let selectedCoinsName = ac.get(selectedCoin);

  const filterAvailableCoins = (availableCoins: string[]) =>
    useSelector((state: AppState) => {
      return filterAvailableCoinsSelector(availableCoins)(state);
    });

  const modalHeader = (
    <div className={classes.modalHeader}>
      <Typography className={classes.currencyLabel}>Choose currency</Typography>
      <SacretCoinSelect
        label="labela"
        allCoins={allCoins}
        createWallet={createWallet}
        selectedCoin={selectedCoin ? selectedCoin : 'BTC'}
        setSelectedCoin={setSelectedCoin}
        allowedCoins={filterAvailableCoins(
          isMember
            ? ALLOWED_CRYPTO_FOR_MEMBERS
            : getAllowedCurrenciesForNonMembers(hasCompletedKYC),
        )}
      />
    </div>
  );
  return (
    <Dialog fullWidth onClose={onClose} open={open} className={classes.dialogPaper}>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Grid container item xs={12} justifyContent="space-between">
          <Typography className={classes.dialogTittleText}>
            <SaveAlt className={classes.depositIcon} />
            Deposit Currency
          </Typography>

          {onClose ? (
            <IconButton className={classes.closeButton} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </Grid>
      </DialogTitle>
      <DialogContent>
        {selectedCoin === 'WALLET_EUR' ? (
          <>{step === 1 ? <DepositSuccess /> : <DepositEurForm>{modalHeader}</DepositEurForm>}</>
        ) : (
          <>
            {modalHeader}

            <TextField
              id="depositAddressInput"
              variant="outlined"
              fullWidth
              value={addressValue}
              className={classes.inputField}
              InputProps={{
                style: {
                  height: 50,
                },
                endAdornment: (
                  <InputAdornment position="end" onClick={copyClipboard}>
                    <img src={Shape} />
                  </InputAdornment>
                ),
              }}
            />
            <div
              className={classes.qrCode}
              style={depositWalletAddress === '' ? { visibility: 'hidden' } : {}}
            >
              <QRCode value={depositWalletAddress} />
            </div>
            <CustomButtonPrimary
              className={classes.button}
              variant="contained"
              fullWidth
              onClick={onClose}
            >
              Done
            </CustomButtonPrimary>
            <Snackbar
              open={snackbarOpen}
              onClose={handleSnackbarClose}
              TransitionComponent={transition}
              ContentProps={{
                'aria-describedby': 'message-id',
                classes: {
                  root: classes.snackbar,
                },
              }}
              message={<span id="message-id">Copied</span>}
              autoHideDuration={1000}
            />
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default DepositModal;
