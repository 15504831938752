import { connect } from 'react-redux';
import { AppState } from '../../../../state/initialState';
import MyInvitesTable from './MyInvitesTable';
import { asyncGetFriendsInvitationsPaginated } from './state/actions';
import { getAppliedFiltersSelector, myInvitesTableStateSelector } from './state/selectors';

const mapDispatchToProps = {
  asyncGetFriendsInvitationsPaginated: asyncGetFriendsInvitationsPaginated.request,
};

const mapStateToProps = (state: AppState) => ({
  ...myInvitesTableStateSelector(state),
  appliedFilters: getAppliedFiltersSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyInvitesTable);
