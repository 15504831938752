import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { Colors } from '../../../../../ui/style/colors';
import { InfoDialogState } from '../state/initialState';
import ModalState from '../../../../../components/ModalState';

type AffiliatePackageStatusDialogProps = {
  infoDialogState: InfoDialogState;
};

export const styles = (theme: Theme) =>
  createStyles({
    wrapper: {
      padding: 0,
    },
    label: {
      fontWeight: 'bold',
      fontSize: '1.2rem',
      color: theme.palette.primary.light,
    },
    switched: {
      color: `${Colors.mediumTurquoise}`,
    },
    textLight: {
      color: theme.palette.primary.light,
      margin: '30px 0 20px 0',
      fontWeight: 'bold',
    },
    buttonSuccess: {
      backgroundColor: `${Colors.havelockBlue}`,
      color: `${Colors.white}`,
      '&:hover ': {
        opacity: 0.9,
        backgroundColor: `${Colors.havelockBlue}`,
      },
    },
    buttonsWrapper: {
      alignSelf: 'flex-end',
      padding: '10px',
    },
    contentWrapper: {
      margin: '0 30px',
      textAlign: 'center',
      '@media(max-width: 600px)': {
        margin: 0,
      },
    },
    content: {
      fontSize: '1rem',
      marginBottom: 10,
      '@media(max-width: 600px)': {
        fontSize: '0.75rem',
      },
    },
    logoutBtn: {
      color: `${Colors.mediumTurquoise}`,
      backgroundColor: `${Colors.white}`,
    },
  });
const useStyles = makeStyles(styles);

const AffiliatePackageStatusDialog = ({ infoDialogState }: AffiliatePackageStatusDialogProps) => {
  const classes = useStyles();

  if (infoDialogState.status === 'success') {
    return (
      <ModalState
        variant="success"
        showCloseButton={false}
        message="Congratulations! You have successfully bought affiliate package"
        className={classes.wrapper}
        showMainButton={false}
      />
    );
  } else {
    return (
      <ModalState
        variant="failed"
        showCloseButton={false}
        className={classes.wrapper}
        showMainButton={false}
      />
    );
  }
};

export default AffiliatePackageStatusDialog;
