import { Switch } from '@material-ui/core';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import { Colors } from '../../ui/style/colors';

const IOSSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 60,
      height: 32,
      padding: 0,
      borderRadius: 19,
      margin: theme.spacing(0),
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(75%)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: `${Colors.havelockBlue}`,
          opacity: 1,
          border: 'none',
        },
      },
    },
    thumb: {
      width: 22,
      height: 22,
      marginLeft: 5,
      marginTop: 4,
      marginBottom: 5,
      marginRight: 5,
    },
    track: {
      borderRadius: 26 / 2,
      border: `0px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.primary.light,
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
  }),
)(Switch);

export default IOSSwitch;
