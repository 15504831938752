import { Box, Collapse } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Remove from '@material-ui/icons/Add';
import Add from '@material-ui/icons/Remove';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { DrawerState } from '../../App/Home/HomeDrawer/state/initialState';
import { getDrawerMenuStateSelector } from '../../App/Home/HomeDrawer/state/selectors';
import { GET_COIN_PRICES } from '../../App/state/actions';
import { AppState } from '../../App/state/initialState';
import { CurrencyPair, GetCoinPricesResponse } from '../../data/repositories/wallet/typedefs';
import CoinPricesCollapseListItem from './CoinPricesCollapseListItem';

const styles = (theme: Theme) =>
  createStyles({
    currencyList: {
      padding: 0,
    },
    coinPrices: {
      color: theme.palette.secondary.light,
      fontSize: 16,
      height: 24,
      visibility: 'visible',
      opacity: 1,
      transition: 'visibility 0s, opacity 0.5s linear',
    },
    coinPricesNone: {
      visibility: 'hidden',
      opacity: 0,
      order: 2,
    },
    icon: {
      color: theme.palette.secondary.light,
    },
    addRemoveIconCenter: {
      justifyContent: 'space-between',
      padding: '24px 0',
      height: '72px',
    },
  });

const useStyles = makeStyles(styles);

const mapStateToProps = (state: AppState) => {
  const drawerState = getDrawerMenuStateSelector(state);
  return { drawerState };
};

type Props = {
  coins?: GetCoinPricesResponse;
  drawerState: DrawerState;
};

const CoinPricesCollapse: React.FC<Props> = ({ coins, drawerState }: Props): JSX.Element => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => setOpen(!open);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: GET_COIN_PRICES });
  }, [open]);

  const coinsOrderArray = [4, 1, 2, 6, 3, 0];
  const [coinsArray, setCoinsArray] = useState<[string, CurrencyPair][]>([]);

  useEffect(() => {
    if (coins) setCoinsArray(Object.entries(coins));
  }, [coins]);

  useEffect(() => {
    setOpen(!open);
  }, []);

  return (
    <List className={classes.currencyList} onClick={e => e.stopPropagation()}>
      <ListItem button onClick={handleClick} className={classes.addRemoveIconCenter}>
        <Box
          className={
            drawerState.isOpen || drawerState.isHovering
              ? classes.coinPrices
              : classes.coinPricesNone
          }
        >
          Currency prices
        </Box>
        {open ? <Add className={classes.icon} /> : <Remove className={classes.icon} />}
      </ListItem>
      <Collapse in={open} timeout={50} unmountOnExit>
        {coinsArray.length > 0 && (
          <List component="div" disablePadding>
            {coinsOrderArray.map(coinOrder => {
              const [key, value] = coinsArray[coinOrder];

              return (
                <CoinPricesCollapseListItem
                  key={key}
                  coin={{ name: key, symbol: key, value: `${value.EUR}` }}
                />
              );
            })}
          </List>
        )}
      </Collapse>
    </List>
  );
};

export default connect(mapStateToProps)(CoinPricesCollapse);
