import { connect } from 'react-redux';
import { AppState } from '../../../../../App/state/initialState';
import { isTwoFactorAuthEnabled } from '../../Settings/state/actions';
import { setUserForDialog, clearDialogState } from '../state/actions';
import { getDialogStateSelector } from '../state/selectors';
import AffiliateAddDeductDialog from './AffiliateAddDeductDialog';

const mapDispatchToProps = {
  isTwoFactorAuthEnabled: isTwoFactorAuthEnabled,
  setUserForDialog: setUserForDialog,
  clearDialogState: clearDialogState,
};
const mapStateToProps = (state: AppState) => {
  const dialogState = getDialogStateSelector(state);
  return { dialogState };
};

export default connect(mapStateToProps, mapDispatchToProps)(AffiliateAddDeductDialog);
