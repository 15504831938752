import { createReducer } from 'typesafe-actions';
import {
  RETURN_TO_DEFAULT_STATE,
  SEND_INVITES,
  SEND_INVITES_FAIL,
  SEND_INVITES_SUCCESS,
} from './actions';
import { initialState } from './initialState';

export default createReducer(initialState, {
  [SEND_INVITES]: state => ({
    ...state,
    inProgress: true,
    fail: false,
    error: null,
    success: false,
  }),
  [SEND_INVITES_SUCCESS]: (state, { payload }) => ({
    ...state,
    inProgress: false,
    success: true,
    inviteData: payload,
  }),
  [SEND_INVITES_FAIL]: (state, { payload }) => ({
    ...state,
    inProgress: false,
    error: payload,
    fail: true,
  }),
  [RETURN_TO_DEFAULT_STATE]: state => ({
    ...state,
    error: null,
    success: false,
    fail: false,
  }),
});
