import {
  UserEarningsTable,
  Filter,
  UserEarningsStats,
} from '../../../../../../data/repositories/admin/typedefs';

export type UserEarningsTableState = {
  users: UserEarningsTable;
  inProgress: boolean;
  fail: boolean;
  error: string | null;
  stats: UserEarningsStats;
  page: number;
  perPage: number;
  appliedFilters: Filter;
  exportInProgress: boolean;
};

export const initialState: UserEarningsTableState = {
  users: {
    usersEarningsInfo: null,
    totalAmount: undefined,
    totalEarned: undefined,
  },
  inProgress: false,
  fail: false,
  error: null,
  page: 0,
  perPage: 10,
  stats: {
    totalEarningInfoCount: null,
  },
  appliedFilters: new Map<string, string[]>(),
  exportInProgress: false,
};
