import { Grid, InputLabel, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { Colors } from '../../../../../ui/style/colors';
import balanceUtils from '../../../../../utils/balanceUtils';
import { getCurrencyFraction } from '../../../../../utils/helper/numbers';
import { dialogState } from '../AffiliateTable/state/initialState';

const styles = (theme: Theme) =>
  createStyles({
    dialogTittleText: {
      color: `${Colors.blueCharcoal}`,
      fontSize: 18,
      fontWeight: 600,
      letterSpacing: 1.13,
      display: 'flex',
      alignItems: 'center',
    },
    labels: {
      fontWeight: 'bold',
      color: theme.palette.primary.light,
      marginTop: 10,
    },
    values: {
      color: theme.palette.primary.main,
      marginTop: 10,
    },
  });

type Props = {
  walletName: string;
  walletPosition: number;
  decimals: number;
  dialogState: dialogState;
};
const AffiliateWallet: React.FC<Props> = (props: Props) => {
  const { walletName, walletPosition, decimals, dialogState } = props;
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const shouldPresentValue = walletPosition !== -1;
  const prepareValueWithDecimals = (value: number) => {
    const prepared = balanceUtils.formatBalanceToString(
      value,
      decimals,
      getCurrencyFraction(walletName, 'min'),
      getCurrencyFraction(walletName),
    );

    return prepared;
  };
  if (!shouldPresentValue) {
    return (
      <Grid item xs={12} sm={6}>
        <Typography className={classes.dialogTittleText}>
          User has no {walletName} wallet.
        </Typography>
      </Grid>
    );
  }

  if (!dialogState.fullUserProfile.wallets[walletPosition]) {
    return (
      <Grid item xs={12} sm={6}>
        <Typography className={classes.dialogTittleText}>
          Please reload the profile dialog!
        </Typography>
      </Grid>
    );
  }

  return (
    <Grid item xs={12} sm={6}>
      <Typography className={classes.dialogTittleText}>{walletName} wallet</Typography>

      <InputLabel className={classes.labels}>Balance</InputLabel>
      <InputLabel className={classes.values}>
        {prepareValueWithDecimals(dialogState.fullUserProfile.wallets[walletPosition].balance || 0)}
      </InputLabel>

      <InputLabel className={classes.labels}>Pending balance</InputLabel>
      <InputLabel className={classes.values}>
        {prepareValueWithDecimals(
          dialogState.fullUserProfile.wallets[walletPosition].pending_balance || 0,
        )}
      </InputLabel>

      {walletName === 'SLC' && (
        <>
          <InputLabel className={classes.labels}>Escrow balance</InputLabel>
          <InputLabel className={classes.values}>
            {prepareValueWithDecimals(
              dialogState.fullUserProfile.wallets[walletPosition].escrow_balance || 0,
            )}
          </InputLabel>

          <InputLabel className={classes.labels}>Reward escrow balance</InputLabel>
          <InputLabel className={classes.values}>
            {prepareValueWithDecimals(
              dialogState.fullUserProfile.wallets[walletPosition].reward_escrow_balance || 0,
            )}
          </InputLabel>

          <InputLabel className={classes.labels}>Pending escrow balance</InputLabel>
          <InputLabel className={classes.values}>
            {prepareValueWithDecimals(
              dialogState.fullUserProfile.wallets[walletPosition].pending_escrow_balance || 0,
            )}
          </InputLabel>

          <InputLabel className={classes.labels}>Timed escrow balance</InputLabel>
          <InputLabel className={classes.values}>
            {prepareValueWithDecimals(
              dialogState.fullUserProfile.wallets[walletPosition].timed_escrow_balance || 0,
            )}
          </InputLabel>

          <InputLabel className={classes.labels}>HODL</InputLabel>
          <InputLabel className={classes.values}>
            {prepareValueWithDecimals(
              dialogState.fullUserProfile.wallets[walletPosition].invested_balance || 0,
            )}
          </InputLabel>
        </>
      )}
    </Grid>
  );
};

export default AffiliateWallet;
