import { CircularProgress, Grid, Theme, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import CustomButtonPrimary from '../../../../../../components/CustomButtons/CustomButtonPrimary';
import BuyWalletModal from '../../../../../../components/BuyWalletModal/BuyWalletModal';
import { useDispatch, useSelector } from 'react-redux';
import { OPEN_BUY_WALLET_MODAL } from '../../state/actions';
import arrow from '../../../../../../assets/down-arrow.svg';
import { Colors } from '../../../../../../ui/style/colors';
import { AppState } from '../../../../../state/initialState';
import { getFeeSettingsStateSelector } from '../../../Settings/state/selectors';
import { GET_STORE_ITEMS } from '../../../Settings/FeeSettings/state/actions';

const useStyles = makeStyles((theme: Theme) => ({
  loaderWrapper: {
    margin: '20% auto',
  },
  coinName: {
    fontSize: 36,
    fontWeight: 'bold',
    color: `${Colors.blueCharcoal}`,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.3rem',
    },
  },
  icon: {
    width: 80,
    height: 80,
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 30,
  },
  notification: {
    marginBottom: 20,
    textAlign: 'center',
  },
  arrow: {
    width: 40,
    height: 40,
    marginBottom: 20,
  },
  errorMessage: {
    marginTop: 30,
    textAlign: 'center',
  },
}));

type Props = {
  icon: string;
  symbol: string;
  euroBalance: string;
};

const getFeeSetingsState = () => {
  const feeSettingsState = useSelector((state: AppState) => getFeeSettingsStateSelector(state));
  return feeSettingsState;
};

const UnavailableCoin = ({ icon, symbol, euroBalance }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { inProgress, fail, storeItems } = getFeeSetingsState();
  const [storeItem] = storeItems;

  const walletPrice = storeItem && storeItem.price_in_eurocents / 100;

  useEffect(() => {
    dispatch({ type: GET_STORE_ITEMS });
  }, []);

  if (inProgress) {
    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={classes.loaderWrapper}
      >
        <CircularProgress size={50} color="secondary" />
      </Grid>
    );
  }

  return (
    <Grid container direction="column" alignItems="center" justifyContent="center">
      <Grid item className={classes.title}>
        <img src={icon} className={classes.icon} />
        <Typography className={classes.coinName}>{symbol}</Typography>
      </Grid>

      {fail ? (
        <Typography>Something went wrong,please come back later and try again.</Typography>
      ) : (
        <>
          <Typography className={classes.notification}>
            You don&apos;t have an {symbol} address.
            <br />
            You&apos;ll need to purchase an ETH address, which will also come with a USDT address.{' '}
            <br />
            We can offer you to buy one for {walletPrice} euros.
            <br />
            Please click on button below if you want to buy.
          </Typography>

          <img src={arrow} className={classes.arrow} />

          <Grid item>
            <CustomButtonPrimary
              variant="contained"
              size="large"
              onClick={() => dispatch({ type: OPEN_BUY_WALLET_MODAL })}
            >
              Buy ETH wallet
            </CustomButtonPrimary>

            <BuyWalletModal symbol={symbol} euroBalance={euroBalance} walletPrice={walletPrice} />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default UnavailableCoin;
