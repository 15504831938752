import { CircularProgress, DialogContent, Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { PayloadAC } from 'typesafe-actions';
import VerificationCodeInput from '../../../../../../components/VerificationCodeInput';
import {
  CreateRevolutCounterpartyData,
  Wallet,
  WithdrawCurrencyRequest,
} from '../../../../../../data/repositories/wallet/typedefs';
import { EURWithdrawAddressType } from '../../../../state/common/initialState';
import { CREATE_REVOLUT_COUNTERPARTY } from '../../state/actions';

const styles = (theme: Theme) =>
  createStyles({
    dialog: {
      height: 'center',
      width: 'center',
      borderRadius: 6,
      backgroundColor: 'transparent',
      boxShadow: '0 10px 30px 0 rgba(132,141,182,0.25)',
      paddingLeft: 30,
      paddingRight: 30,
    },
    infoLabel: {
      color: theme.palette.primary.light,
      fontSize: 12,
      fontWeight: 600,
      marginLeft: 10,
    },
    circularProgress: {
      marginTop: 30,
      marginBottom: 30,
      justifyContent: 'center',
      display: 'flex',
    },
  });
const useStyles = makeStyles(styles);

type Props = {
  withdrawCurrency: PayloadAC<'wallet/withdraw_currency', WithdrawCurrencyRequest>;
  asyncCreateRevolutCounterparty: PayloadAC<
    typeof CREATE_REVOLUT_COUNTERPARTY,
    CreateRevolutCounterpartyData
  >;
  openWithdrawDialog: PayloadAC<'wallet/open_withdraw_dialog', number>;

  amount: string;
  address: string | EURWithdrawAddressType;
  withdrawError: Error | null;
  withdrawInProgress: boolean;
  wallet?: Wallet;
  selectedCoin: string;
};

const WithdrawCoins2fa: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const {
    withdrawCurrency,
    openWithdrawDialog,
    asyncCreateRevolutCounterparty,
    amount,
    address,
    withdrawError,
    withdrawInProgress,
    wallet,
    selectedCoin,
  } = props;

  const amountToSend = Number(amount) * Math.pow(10, wallet ? wallet.decimals : 0);
  let withdrawData: any = {};
  if (typeof address !== 'string') {
    withdrawData = {
      amount: amountToSend,
      ...(address as EURWithdrawAddressType),
    };
  } else {
    withdrawData = {
      amount: amountToSend,
      address: address,
    };
  }

  return (
    <DialogContent className={classes.dialog}>
      <Typography className={classes.infoLabel} component="p" style={{ marginBottom: 24 }}>
        Please, enter your two-factor authentication code once more
      </Typography>
      {withdrawInProgress ? (
        <Grid container className={classes.circularProgress} alignContent="center">
          <CircularProgress color="secondary" size={60} />
        </Grid>
      ) : (
        <div>
          <VerificationCodeInput
            onComplete={(code: string) => {
              const data = { ...withdrawData, code };
              const withdraw = () => {
                if (selectedCoin === 'WALLET_EUR') {
                  return asyncCreateRevolutCounterparty(data);
                } else {
                  return withdrawCurrency(data);
                }
              };
              withdraw();
              openWithdrawDialog(4);
            }}
            withdrawData={withdrawData}
            error={withdrawError}
          />
        </div>
      )}
    </DialogContent>
  );
};

export default WithdrawCoins2fa;
