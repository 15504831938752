export type UpdateCurrencyRateState = {
  inProgress: boolean;
  fail: boolean;
  success: boolean;
  error: Error | null;
};

export const initialState: UpdateCurrencyRateState = {
  inProgress: false,
  fail: false,
  success: false,
  error: null,
};

export type UpdateCurrencyRateRequest = {
  rate: string;
  selectedCurrency: string;
};
