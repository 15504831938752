import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import {
  ProductDetail,
  GetProductRequest,
} from '../../../../../../data/repositories/product/typedef';
import { ProductFormState } from './initialState';

export const SET_PRODUCT_FORM = 'product_form/set_initial_values';

export const GET_PRODUCT = 'product_form/get_product';
export const GET_PRODUCT_SUCCESS = `${GET_PRODUCT}/success`;
export const GET_PRODUCT_FAIL = `${GET_PRODUCT}/fail`;

export const PRODUCT_CREATE = 'product_form/create';
export const PRODUCT_CREATE_SUCCESS = `${PRODUCT_CREATE}/success`;
export const PRODUCT_CREATE_FAIL = `${PRODUCT_CREATE}/fail`;

export const PRODUCT_UPDATE = 'product_form/update';
export const PRODUCT_UPDATE_SUCCESS = `${PRODUCT_UPDATE}/success`;
export const PRODUCT_UPDATE_FAIL = `${PRODUCT_UPDATE}/fail`;

export const PRODUCT_PUBLISH = 'product_form/publish';
export const PRODUCT_PUBLISH_SUCCESS = `${PRODUCT_PUBLISH}/success`;
export const PRODUCT_PUBLISH_FAIL = `${PRODUCT_PUBLISH}/fail`;

export const asyncProductCreate = createAsyncAction(
  PRODUCT_CREATE,
  PRODUCT_CREATE_SUCCESS,
  PRODUCT_CREATE_FAIL,
)<ProductDetail, any, Error>();

export const asyncProductUpdate = createAsyncAction(
  PRODUCT_UPDATE,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT_UPDATE_FAIL,
)<ProductDetail, any, Error>();

export const asyncProductPublish = createAsyncAction(
  PRODUCT_PUBLISH,
  PRODUCT_PUBLISH_SUCCESS,
  PRODUCT_PUBLISH_FAIL,
)<string, any, Error>();

export const asyncGetProduct = createAsyncAction(
  GET_PRODUCT,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_FAIL,
)<GetProductRequest, any, Error>();
export const setProductForm = createStandardAction(SET_PRODUCT_FORM)<ProductFormState>();
