import {
  ACCOUNT_ENDPOINT,
  GET_AFFILIATE_BALANCES,
  USERS_ENDPOINT,
  GET_CHECK_IF_USER_HAS_AFFILIATE_ENDPOINT,
} from '../../../utils/constants/api';
import Http from '../../Http';
import {
  GetAffiliateCodeResponse,
  GetRefferalsResponse,
  GetRefferalTreeResponse,
} from './typedefs';

const identityURL = process.env.REACT_APP_IDENTITY_API_URL;

export const getAffiliateCode = async (endpoint: string): Promise<GetAffiliateCodeResponse> => {
  return (await Http.get(endpoint)).data;
};

export const getReferrals = async (endpoint: string): Promise<GetRefferalsResponse> => {
  return (await Http.get(endpoint)).data;
};

export const getReferralsPaginated = async (endpoint: string): Promise<GetRefferalsResponse> => {
  return (await Http.get(endpoint)).data;
};

export const getReferralsStats = async (endpoint: string): Promise<GetRefferalsResponse> => {
  return (await Http.get(endpoint)).data;
};

export const getReferralTree = async (endpoint: string): Promise<GetRefferalTreeResponse> => {
  return (await Http.get(endpoint)).data;
};

export const getUnclaimedSLC = async (endpoint: string): Promise<any> => {
  return (await Http.get(endpoint)).data;
};

export const claimSLC = async (endpoint: string): Promise<any> => (await Http.post(endpoint)).data;

const filtersAvailable =
  'new_user_reward,new_user_affiliate_reward,new_merchant_reward,merchant_parent_reward,affiliate_percentage_reward';

export const getAffiliateBalances = async (accountId: string): Promise<any> =>
  (await Http.get(GET_AFFILIATE_BALANCES(accountId), { origin: filtersAvailable })).data;

export const getCheckIfUserHasAffiliate = async (adminId: string, selectedUserId: string) =>
  await Http.get(GET_CHECK_IF_USER_HAS_AFFILIATE_ENDPOINT(adminId, selectedUserId));

export const tradeSLC = async (data: any) => {
  const { ownerAccountId, payload } = data;

  return (
    await Http.post(`${ACCOUNT_ENDPOINT}/${ownerAccountId}/add-deduct-slc`, {
      account_id: payload.referralUser.id || payload.referralUser.referral_id,
      amount: payload.amount,
      transaction_type: payload.referralUser.send_to_user ? 'add' : 'deduct',
      balance_status:
        payload.referralUser.send_to_user && payload.isEscrow ? 'escrow' : 'completed',
      comment: payload.comment,
      code: payload.code,
    })
  ).data;
};

/**Blacklist/Whitelist **/
export const changeUserStatus = async (data: any) => {
  const { ownerAccountId, payload } = data;

  // We receive account id in different format if we searched the affiliate table
  const account_id = payload.hasOwnProperty('id') ? payload.id : payload.referral_id;

  return (
    await Http.post(
      `${USERS_ENDPOINT}/${ownerAccountId}/lock-user`,
      {
        user_identifier: account_id,
        locked: payload.enabled,
      },
      {
        baseURL: identityURL,
        headers: { User: ownerAccountId },
      },
    )
  ).data;
};

export const resetUserKYCStatus = async (data: {
  userId: string;
  ownerAccountId: string | null;
}) => {
  const { ownerAccountId, userId } = data;

  return await Http.delete(
    `${USERS_ENDPOINT}/${userId}/reset-kyc-data`,
    {
      user_identifier: userId,
    },
    {
      baseURL: identityURL,
      headers: { User: ownerAccountId },
    },
  );
};

export const changeOrderStatus = async (data: any) => {
  const { ownerAccountId, payload } = data;

  return (
    await Http.post(
      `${ACCOUNT_ENDPOINT}/${ownerAccountId}/order/${payload.orderId}/${
        payload.orderStatus ? 'accept' : 'reject'
      }`,
      { comment: payload.comment, has_comment: !!payload.comment },
    )
  ).data;
};

export const toggleAdminRole = async (data: any) => {
  const { ownerAccountId, payload } = data;

  // We receive account id in different format if we searched the affiliate table
  const account_id = payload.hasOwnProperty('id') ? payload.id : payload.referral_id;

  return (
    await Http.post(`${ACCOUNT_ENDPOINT}/${ownerAccountId}/assign-admin`, {
      account_id,
      enabled: payload.adminRoleStatus,
    })
  ).data;
};

export const getBalanceFromAffiliateUser = async (data: any) => {
  const { ownerAccountId, payload } = data;

  return (
    await Http.get(
      `${ACCOUNT_ENDPOINT}/${ownerAccountId}/wallet/${payload.balanceSymbol}/balance`,
      {
        user_id: payload.user_id,
      },
    )
  ).data;
};

export const saveChangeSlcLimit = async (adminId: string, selectedUserId: string, data: any) =>
  (await Http.post(GET_CHECK_IF_USER_HAS_AFFILIATE_ENDPOINT(adminId, selectedUserId), data)).data;

export const getFullAffiliateProfile = async (data: any) => {
  const { ownerAccountId, affiliateId } = data;

  return (await Http.get(`${ACCOUNT_ENDPOINT}/${ownerAccountId}/full-profile-info/${affiliateId}`))
    .data;
};

export default {
  getAffiliateCode,
  getReferrals,
  getReferralsStats,
  getReferralsPaginated,
  getReferralTree,
  getUnclaimedSLC,
  claimSLC,
  getAffiliateBalances,
  tradeSLC,
  changeUserStatus,
  toggleAdminRole,
  changeOrderStatus,
  getBalanceFromAffiliateUser,
  getFullAffiliateProfile,
  resetUserKYCStatus,
  getCheckIfUserHasAffiliate,
  saveChangeSlcLimit,
};
