import { connect } from 'react-redux';
import { AppState } from '../../../../../state/initialState';
import { getTableChartStateSelector } from '../../state/selectors';
import DailyChange from './DailyChange';

const mapStateToProps = (state: AppState) => {
  const tableChartState = getTableChartStateSelector(state);
  return { tableChartState };
};

export default connect(mapStateToProps, {})(DailyChange);
