import Http from '../../Http';
import { BuyPackageRequest, ExchangeCurrencyRequest } from './typedefs';

const exchangeCurrency = async (data: ExchangeCurrencyRequest, endpoint: string): Promise<any> =>
  (await Http.post(endpoint, data)).data;

const allPackages = async (endpoint: string): Promise<any> => (await Http.get(endpoint)).data;
const buyPackage = async (data: BuyPackageRequest, endpoint: string): Promise<any> =>
  (await Http.post(endpoint, data)).data;

export default { exchangeCurrency, allPackages, buyPackage };
