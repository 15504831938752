import qs from 'qs';
import {
  ACCOUNT_ENDPOINT,
  AFFILIATE_CODE_CHECK_EXISTING_ENDPOINT,
  CHANGE_PASSWORD_ENDPOINT,
  CONFIRM_ACCOUNT,
  EMAIL_CHECK_EXISTING_ENDPOINT,
  FORGOT_PASSWORD_ENDPOINT,
  GET_KYC_APPLICANTS_ENDPOINT,
  GET_KYC_CHECK_ENDPOINT,
  GET_KYC_DOCUMENTS_ENDPOINT,
  GET_PROFILE_INFO,
  GET_TOKEN_ENDPOINT,
  KYC_ENDPOINT,
  LOGIN_ENDPOINT,
  NEW_PASSWORD_ENDPOINT,
  REGISTER_ENDPOINT,
  TWO_FACTOR_ENDPOINT,
  UPDATE_PAYMENT_INFO,
  USERS_ENDPOINT,
} from '../../../utils/constants/api';
import Http from '../../Http';
import {
  Account,
  ChangePasswordRequest,
  CheckDataResponse,
  ConfirmTwoFactorAuthRequest,
  EnableTwoFactorAuthResponse,
  ForgotPasswordRequest,
  GetCitizenshipsResponse,
  GetCountriesResponse,
  GetUserInfoResponse,
  KycCreateAplicantRequest,
  NewPasswordRequest,
  RegisterRequest,
  RegisterResponse,
  VerifyEmailRequest,
} from './typedefs';

const baseURL = process.env.REACT_APP_BASE_API_URL || 'https://app.slaff.io/api/v1/';
const identityURL = process.env.REACT_APP_IDENTITY_API_URL;

/**This endpoint will be replaced with the new one when BE create it */
const getUserInfo = async (user_id: string | null): Promise<GetUserInfoResponse> => {
  return (
    await Http.get(
      `${USERS_ENDPOINT}/${user_id}/user-info`,
      {},
      {
        baseURL: identityURL,
        headers: { User: user_id },
      },
    )
  ).data;
};

const refreshToken = async (refresh_token: string) => {
  return (
    await Http.post(
      GET_TOKEN_ENDPOINT,
      qs.stringify({
        grant_type: 'refresh_token',
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        refresh_token,
      }),
      {
        baseURL: identityURL,
      },
    )
  ).data;
};

const register = async (data: RegisterRequest): Promise<RegisterResponse> => {
  const { firstName, lastName, ...body } = data;
  /* eslint-disable */
  return (
    await Http.post(
      REGISTER_ENDPOINT,
      {
        ...body,
        first_name: firstName,
        last_name: lastName,
      },
      {
        baseURL: baseURL.replace('v1', 'v2'),
      },
    )
  ).data;
  /* eslint-enable */
};

const verifyEmail = async (data: VerifyEmailRequest): Promise<any> => {
  const { account_id, token_id } = data;

  return (
    await Http.post(CONFIRM_ACCOUNT, {
      account_id,
      token_id,
    })
  ).data;
};

const isDuplicateEmail = async (email: string): Promise<CheckDataResponse> => {
  return (
    await Http.post(EMAIL_CHECK_EXISTING_ENDPOINT, {
      email,
    })
  ).data;
};

const affiliateCodeExists = async (code: string): Promise<CheckDataResponse> => {
  return (
    await Http.post(AFFILIATE_CODE_CHECK_EXISTING_ENDPOINT, {
      code,
    })
  ).data;
};

const login = async (): Promise<any> =>
  (
    await Http.get(
      `${LOGIN_ENDPOINT}?response_type=code&client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_HOST}&scope=any`,
      { redirect: 'manual' },
      {
        baseURL: identityURL,
      },
    )
  ).data;

const exchangeAuthCode = async (code: string, status?: string) =>
  //redirect_uri MUST BE THE SAME  as on the autologin, otherwise you will get status 400!
  (
    await Http.post(
      GET_TOKEN_ENDPOINT,
      qs.stringify({
        grant_type: 'authorization_code',
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        code,
        redirect_uri: status
          ? `${window.location.origin}?status=${status}`
          : window.location.origin,
        scope: 'any',
      }),
      {
        baseURL: identityURL,
      },
    )
  ).data;

const getAccount = async (accountId: string): Promise<Account> =>
  Http.get(`${ACCOUNT_ENDPOINT}/${accountId}`);

const changePassword = async (data: ChangePasswordRequest, accountId: string) =>
  Http.put(`${USERS_ENDPOINT}/${accountId}${CHANGE_PASSWORD_ENDPOINT}`, data, {
    baseURL: identityURL,
    headers: { User: accountId },
  });

const enableTwoFactorAuth = async (accountId: string): Promise<EnableTwoFactorAuthResponse> =>
  (
    await Http.post(
      `${USERS_ENDPOINT}/${accountId}${TWO_FACTOR_ENDPOINT}`,
      {},
      {
        baseURL: identityURL,
        headers: { User: accountId },
      },
    )
  ).data;

const confirmTwoFactorAuth = async (
  data: ConfirmTwoFactorAuthRequest,
  accountId: string,
): Promise<any | null> =>
  await Http.put(
    `${USERS_ENDPOINT}/${accountId}${TWO_FACTOR_ENDPOINT}`,
    { code: data.code },
    {
      baseURL: identityURL,
      headers: { User: accountId },
    },
  );

const disableTwoFactorAuth = async (
  data: ConfirmTwoFactorAuthRequest,
  accountId: string,
): Promise<any | null> =>
  await Http.delete(
    `${USERS_ENDPOINT}/${accountId}${TWO_FACTOR_ENDPOINT}`,
    {},
    {
      baseURL: identityURL,
      headers: { User: accountId, Code: data.code },
    },
  );

const kycCreateAplicant = async (
  data: KycCreateAplicantRequest,
  accountId: string,
): Promise<any> => {
  return (await Http.post(GET_KYC_APPLICANTS_ENDPOINT(accountId), data)).data;
};

const kycIdVerification = async (data: any, accountId: string): Promise<any> => {
  return (await Http.post(GET_KYC_DOCUMENTS_ENDPOINT(accountId), data)).data;
};

const kycCreateCheck = async (data: any, accountId: string): Promise<any> => {
  return (await Http.post(GET_KYC_CHECK_ENDPOINT(accountId), data)).data;
};

const getProfileInfo = async (accountId: string): Promise<any> => {
  return (await Http.get(GET_PROFILE_INFO(accountId))).data;
};

const updatePaymentInfo = async (
  accountId: string,
  { beneficiary, beneficiary_address, bic, etherium_address, iban, other_info }: any,
) => {
  return await Http.post(UPDATE_PAYMENT_INFO(accountId), {
    ether_address: etherium_address,
    beneficiary,
    beneficiary_address,
    iban,
    bic,
    other_info,
  });
};

const getCountries: GetCountriesResponse = {
  countries: [
    {
      abbreviation: 'AFG',
      name: 'Afghanistan',
    },
    {
      abbreviation: 'ALB',
      name: 'Albania',
    },
    {
      abbreviation: 'DZA',
      name: 'Algeria',
    },
    {
      abbreviation: 'AND',
      name: 'Andorra',
    },
    {
      abbreviation: 'ATG',
      name: 'Antigua and Barbuda',
    },
    {
      abbreviation: 'ARG',
      name: 'Argentina',
    },
    {
      abbreviation: 'ARM',
      name: 'Armenia',
    },
    {
      abbreviation: 'ABW',
      name: 'Aruba',
    },
    {
      abbreviation: 'AUS',
      name: 'Australia',
    },
    {
      abbreviation: 'AUT',
      name: 'Austria',
    },
    {
      abbreviation: 'AZE',
      name: 'Azerbaijan',
    },
    {
      abbreviation: 'BHR',
      name: 'Bahrain',
    },
    {
      abbreviation: 'BRB',
      name: 'Barbados',
    },
    {
      abbreviation: 'BLR',
      name: 'Belarus',
    },
    {
      abbreviation: 'BEL',
      name: 'Belgium',
    },
    {
      abbreviation: 'BLZ',
      name: 'Belize',
    },
    {
      abbreviation: 'BEN',
      name: 'Benin',
    },
    {
      abbreviation: 'BMU',
      name: 'Bermuda',
    },
    {
      abbreviation: 'BTN',
      name: 'Bhutan',
    },
    {
      abbreviation: 'BIH',
      name: 'Bosnia and Herzegovina',
    },
    {
      abbreviation: 'BRA',
      name: 'Brazil',
    },
    {
      abbreviation: 'BRN',
      name: 'Brunei Darussalam',
    },
    {
      abbreviation: 'BGR',
      name: 'Bulgaria',
    },
    {
      abbreviation: 'BFA',
      name: 'Burkina Faso',
    },
    {
      abbreviation: 'BDI',
      name: 'Burundi',
    },
    {
      abbreviation: 'KHM',
      name: 'Cambodia',
    },
    {
      abbreviation: 'CMR',
      name: 'Cameroon',
    },
    {
      abbreviation: 'CAN',
      name: 'Canada',
    },
    {
      abbreviation: 'CYM',
      name: 'Cayman Islands',
    },
    {
      abbreviation: 'CAF',
      name: 'Central African Republic',
    },
    {
      abbreviation: 'CHL',
      name: 'Chile',
    },
    {
      abbreviation: 'CHN',
      name: 'China',
    },
    {
      abbreviation: 'COL',
      name: 'Colombia',
    },
    {
      abbreviation: 'COG',
      name: 'Congo',
    },
    {
      abbreviation: 'COK',
      name: 'Cook Islands',
    },
    {
      abbreviation: 'CRI',
      name: 'Costa Rica',
    },
    {
      abbreviation: 'HRV',
      name: 'Croatia',
    },
    {
      abbreviation: 'CUB',
      name: 'Cuba',
    },
    {
      abbreviation: 'CYP',
      name: 'Cyprus',
    },
    {
      abbreviation: 'CZE',
      name: 'Czechia',
    },
    {
      abbreviation: 'CIV',
      name: "Côte d'Ivoire",
    },
    {
      abbreviation: 'DNK',
      name: 'Denmark',
    },
    {
      abbreviation: 'DJI',
      name: 'Djibouti',
    },
    {
      abbreviation: 'DMA',
      name: 'Dominica',
    },
    {
      abbreviation: 'DOM',
      name: 'Dominican Republic',
    },
    {
      abbreviation: 'ECU',
      name: 'Ecuador',
    },
    {
      abbreviation: 'EGY',
      name: 'Egypt',
    },
    {
      abbreviation: 'SLV',
      name: 'El Salvador',
    },
    {
      abbreviation: 'GNQ',
      name: 'Equatorial Guinea',
    },
    {
      abbreviation: 'EST',
      name: 'Estonia',
    },
    {
      abbreviation: 'ETH',
      name: 'Ethiopia',
    },
    {
      abbreviation: 'FRO',
      name: 'Faroe Islands',
    },
    {
      abbreviation: 'FJI',
      name: 'Fiji',
    },
    {
      abbreviation: 'FIN',
      name: 'Finland',
    },
    {
      abbreviation: 'FRA',
      name: 'France',
    },
    {
      abbreviation: 'GAB',
      name: 'Gabon',
    },
    {
      abbreviation: 'GEO',
      name: 'Georgia',
    },
    {
      abbreviation: 'DEU',
      name: 'Germany',
    },
    {
      abbreviation: 'GIB',
      name: 'Gibraltar',
    },
    {
      abbreviation: 'GHA',
      name: 'Ghana',
    },
    {
      abbreviation: 'GRC',
      name: 'Greece',
    },
    {
      abbreviation: 'GRD',
      name: 'Grenada',
    },
    {
      abbreviation: 'GTM',
      name: 'Guatemala',
    },
    {
      abbreviation: 'GGY',
      name: 'Guernsey',
    },
    {
      abbreviation: 'GIN',
      name: 'Guinea',
    },
    {
      abbreviation: 'HND',
      name: 'Honduras',
    },
    {
      abbreviation: 'HKG',
      name: 'Hong Kong',
    },
    {
      abbreviation: 'HUN',
      name: 'Hungary',
    },
    {
      abbreviation: 'ISL',
      name: 'Iceland',
    },
    {
      abbreviation: 'IND',
      name: 'India',
    },
    {
      abbreviation: 'IDN',
      name: 'Indonesia',
    },
    {
      abbreviation: 'IRN',
      name: 'Iran (Islamic Republic of)',
    },
    {
      abbreviation: 'IRL',
      name: 'Ireland',
    },
    {
      abbreviation: 'IMN',
      name: 'Isle of Man',
    },
    {
      abbreviation: 'ISR',
      name: 'Israel',
    },
    {
      abbreviation: 'ITA',
      name: 'Italy',
    },
    {
      abbreviation: 'JAM',
      name: 'Jamaica',
    },
    {
      abbreviation: 'JPN',
      name: 'Japan',
    },
    {
      abbreviation: 'JEY',
      name: 'Jersey',
    },
    {
      abbreviation: 'KAZ',
      name: 'Kazakhstan',
    },
    {
      abbreviation: 'KEN',
      name: 'Kenya',
    },
    {
      abbreviation: 'KOR',
      name: 'Korea (Republic of)',
    },
    {
      abbreviation: 'KWT',
      name: 'Kuwait',
    },
    {
      abbreviation: 'KGZ',
      name: 'Kyrgyzstan',
    },
    {
      abbreviation: 'LVA',
      name: 'Latvia',
    },
    {
      abbreviation: 'LBN',
      name: 'Lebanon',
    },
    {
      abbreviation: 'LSO',
      name: 'Lesotho',
    },
    {
      abbreviation: 'LBY',
      name: 'Libya',
    },
    {
      abbreviation: 'LIE',
      name: 'Liechtenstein',
    },
    {
      abbreviation: 'LTU',
      name: 'Lithuania',
    },
    {
      abbreviation: 'LUX',
      name: 'Luxembourg',
    },
    {
      abbreviation: 'MWI',
      name: 'Malawi',
    },
    {
      abbreviation: 'MYS',
      name: 'Malaysia',
    },
    {
      abbreviation: 'MLI',
      name: 'Mali',
    },
    {
      abbreviation: 'MLT',
      name: 'Malta',
    },
    {
      abbreviation: 'MRT',
      name: 'Mauritania',
    },
    {
      abbreviation: 'MEX',
      name: 'Mexico',
    },
    {
      abbreviation: 'MDA',
      name: 'Moldova (Republic of)',
    },
    {
      abbreviation: 'MCO',
      name: 'Monaco',
    },
    {
      abbreviation: 'MNE',
      name: 'Montenegro',
    },
    {
      abbreviation: 'MSR',
      name: 'Montserrat',
    },
    {
      abbreviation: 'MAR',
      name: 'Morocco',
    },
    {
      abbreviation: 'NAM',
      name: 'Namibia',
    },
    {
      abbreviation: 'NPL',
      name: 'Nepal',
    },
    {
      abbreviation: 'NLD',
      name: 'Netherlands',
    },
    {
      abbreviation: 'NZL',
      name: 'New Zealand',
    },
    {
      abbreviation: 'NIC',
      name: 'Nicaragua',
    },
    {
      abbreviation: 'NER',
      name: 'Niger',
    },
    {
      abbreviation: 'NGA',
      name: 'Nigeria',
    },
    {
      abbreviation: 'NFK',
      name: 'Norfolk Island',
    },
    { abbreviation: 'MKD', name: 'North Macedonia, Republic of' },
    {
      abbreviation: 'NOR',
      name: 'Norway',
    },
    {
      abbreviation: 'OMN',
      name: 'Oman',
    },
    {
      abbreviation: 'PAK',
      name: 'Pakistan',
    },
    {
      abbreviation: 'PSE',
      name: 'Palestine, State of',
    },
    {
      abbreviation: 'PAN',
      name: 'Panama',
    },
    {
      abbreviation: 'PRY',
      name: 'Paraguay',
    },
    {
      abbreviation: 'PER',
      name: 'Peru',
    },
    {
      abbreviation: 'PHL',
      name: 'Philippines',
    },
    {
      abbreviation: 'POL',
      name: 'Poland',
    },
    {
      abbreviation: 'PRT',
      name: 'Portugal',
    },
    {
      abbreviation: 'PRI',
      name: 'Puerto Rico',
    },
    {
      abbreviation: 'QAT',
      name: 'Qatar',
    },
    {
      abbreviation: 'ROU',
      name: 'Romania',
    },
    {
      abbreviation: 'RUS',
      name: 'Russian Federation',
    },
    {
      abbreviation: 'KNA',
      name: 'Saint Kitts and Nevis',
    },
    {
      abbreviation: 'LCA',
      name: 'Saint Lucia',
    },
    {
      abbreviation: 'VCT',
      name: 'Saint Vincent and the Grenadines',
    },
    {
      abbreviation: 'WSM',
      name: 'Samoa',
    },
    {
      abbreviation: 'SAU',
      name: 'Saudi Arabia',
    },
    {
      abbreviation: 'SRB',
      name: 'Serbia',
    },
    {
      abbreviation: 'SGP',
      name: 'Singapore',
    },
    {
      abbreviation: 'SVK',
      name: 'Slovakia',
    },
    {
      abbreviation: 'SVN',
      name: 'Slovenia',
    },
    {
      abbreviation: 'SOM',
      name: 'Somalia',
    },
    {
      abbreviation: 'ZAF',
      name: 'South Africa',
    },
    {
      abbreviation: 'ESP',
      name: 'Spain',
    },
    {
      abbreviation: 'LKA',
      name: 'Sri Lanka',
    },
    {
      abbreviation: 'SDN',
      name: 'Sudan',
    },
    {
      abbreviation: 'SWE',
      name: 'Sweden',
    },
    {
      abbreviation: 'CHE',
      name: 'Switzerland',
    },
    {
      abbreviation: 'SYR',
      name: 'Syrian Arab Republic',
    },
    {
      abbreviation: 'TWN',
      name: 'Taiwan, Province of China',
    },
    {
      abbreviation: 'TJK',
      name: 'Tajikistan',
    },
    {
      abbreviation: 'TZA',
      name: 'Tanzania, United Republic of',
    },
    {
      abbreviation: 'THA',
      name: 'Thailand',
    },
    {
      abbreviation: 'TGO',
      name: 'Togo',
    },
    {
      abbreviation: 'TTO',
      name: 'Trinidad and Tobago',
    },
    {
      abbreviation: 'TUN',
      name: 'Tunisia',
    },
    {
      abbreviation: 'TUR',
      name: 'Turkey',
    },
    {
      abbreviation: 'TKM',
      name: 'Turkmenistan',
    },
    {
      abbreviation: 'TCA',
      name: 'Turks and Caicos Islands',
    },
    {
      abbreviation: 'TUV',
      name: 'Tuvalu',
    },
    {
      abbreviation: 'UGA',
      name: 'Uganda',
    },
    {
      abbreviation: 'UKR',
      name: 'Ukraine',
    },
    {
      abbreviation: 'ARE',
      name: 'United Arab Emirates',
    },
    {
      abbreviation: 'GBR',
      name: 'United Kingdom of Great Britain and Northern Ireland',
    },
    {
      name: 'United States of America',
      abbreviation: 'USA',
      states: [
        {
          name: 'Alabama',
          abbreviation: 'AL',
        },
        {
          name: 'Alaska',
          abbreviation: 'AK',
        },
        {
          name: 'American Samoa',
          abbreviation: 'AS',
        },
        {
          name: 'Arizona',
          abbreviation: 'AZ',
        },
        {
          name: 'Arkansas',
          abbreviation: 'AR',
        },
        {
          name: 'California',
          abbreviation: 'CA',
        },
        {
          name: 'Colorado',
          abbreviation: 'CO',
        },
        {
          name: 'Connecticut',
          abbreviation: 'CT',
        },
        {
          name: 'Delaware',
          abbreviation: 'DE',
        },
        {
          name: 'District Of Columbia',
          abbreviation: 'DC',
        },
        {
          name: 'Federated States Of Micronesia',
          abbreviation: 'FM',
        },
        {
          name: 'Florida',
          abbreviation: 'FL',
        },
        {
          name: 'Georgia',
          abbreviation: 'GA',
        },
        {
          name: 'Guam',
          abbreviation: 'GU',
        },
        {
          name: 'Hawaii',
          abbreviation: 'HI',
        },
        {
          name: 'Idaho',
          abbreviation: 'ID',
        },
        {
          name: 'Illinois',
          abbreviation: 'IL',
        },
        {
          name: 'Indiana',
          abbreviation: 'IN',
        },
        {
          name: 'Iowa',
          abbreviation: 'IA',
        },
        {
          name: 'Kansas',
          abbreviation: 'KS',
        },
        {
          name: 'Kentucky',
          abbreviation: 'KY',
        },
        {
          name: 'Louisiana',
          abbreviation: 'LA',
        },
        {
          name: 'Maine',
          abbreviation: 'ME',
        },
        {
          name: 'Marshall Islands',
          abbreviation: 'MH',
        },
        {
          name: 'Maryland',
          abbreviation: 'MD',
        },
        {
          name: 'Massachusetts',
          abbreviation: 'MA',
        },
        {
          name: 'Michigan',
          abbreviation: 'MI',
        },
        {
          name: 'Minnesota',
          abbreviation: 'MN',
        },
        {
          name: 'Mississippi',
          abbreviation: 'MS',
        },
        {
          name: 'Missouri',
          abbreviation: 'MO',
        },
        {
          name: 'Montana',
          abbreviation: 'MT',
        },
        {
          name: 'Nebraska',
          abbreviation: 'NE',
        },
        {
          name: 'Nevada',
          abbreviation: 'NV',
        },
        {
          name: 'New Hampshire',
          abbreviation: 'NH',
        },
        {
          name: 'New Jersey',
          abbreviation: 'NJ',
        },
        {
          name: 'New Mexico',
          abbreviation: 'NM',
        },
        {
          name: 'New York',
          abbreviation: 'NY',
        },
        {
          name: 'North Carolina',
          abbreviation: 'NC',
        },
        {
          name: 'North Dakota',
          abbreviation: 'ND',
        },
        {
          name: 'Northern Mariana Islands',
          abbreviation: 'MP',
        },
        {
          name: 'Ohio',
          abbreviation: 'OH',
        },
        {
          name: 'Oklahoma',
          abbreviation: 'OK',
        },
        {
          name: 'Oregon',
          abbreviation: 'OR',
        },
        {
          name: 'Palau',
          abbreviation: 'PW',
        },
        {
          name: 'Pennsylvania',
          abbreviation: 'PA',
        },
        {
          name: 'Puerto Rico',
          abbreviation: 'PR',
        },
        {
          name: 'Rhode Island',
          abbreviation: 'RI',
        },
        {
          name: 'South Carolina',
          abbreviation: 'SC',
        },
        {
          name: 'South Dakota',
          abbreviation: 'SD',
        },
        {
          name: 'Tennessee',
          abbreviation: 'TN',
        },
        {
          name: 'Texas',
          abbreviation: 'TX',
        },
        {
          name: 'Utah',
          abbreviation: 'UT',
        },
        {
          name: 'Vermont',
          abbreviation: 'VT',
        },
        {
          name: 'Virgin Islands',
          abbreviation: 'VI',
        },
        {
          name: 'Virginia',
          abbreviation: 'VA',
        },
        {
          name: 'Washington',
          abbreviation: 'WA',
        },
        {
          name: 'West Virginia',
          abbreviation: 'WV',
        },
        {
          name: 'Wisconsin',
          abbreviation: 'WI',
        },
        {
          name: 'Wyoming',
          abbreviation: 'WY',
        },
      ],
    },
    {
      abbreviation: 'UZB',
      name: 'Uzbekistan',
    },
    {
      abbreviation: 'VUT',
      name: 'Vanuatu',
    },
    {
      abbreviation: 'VEN',
      name: 'Venezuela (Bolivarian Republic of)',
    },
    {
      abbreviation: 'VNM',
      name: 'Vietnam',
    },
    {
      abbreviation: 'VGB',
      name: 'Virgin Islands (British)',
    },
    {
      abbreviation: 'ZMB',
      name: 'Zambia',
    },
    {
      abbreviation: 'ZWE',
      name: 'Zimbabwe',
    },
    {
      abbreviation: 'ALA',
      name: 'Åland Islands',
    },
  ],
};

const getCitizenships: GetCitizenshipsResponse = {
  citizenships: [
    {
      abbreviation: 'AFG',
      name: 'Afghanistan',
    },
    {
      abbreviation: 'ALB',
      name: 'Albania',
    },
    {
      abbreviation: 'DZA',
      name: 'Algeria',
    },
    {
      abbreviation: 'AND',
      name: 'Andorra',
    },
    {
      abbreviation: 'ATG',
      name: 'Antigua and Barbuda',
    },
    {
      abbreviation: 'ARG',
      name: 'Argentina',
    },
    {
      abbreviation: 'ARM',
      name: 'Armenia',
    },
    {
      abbreviation: 'ABW',
      name: 'Aruba',
    },
    {
      abbreviation: 'AUS',
      name: 'Australia',
    },
    {
      abbreviation: 'AUT',
      name: 'Austria',
    },
    {
      abbreviation: 'AZE',
      name: 'Azerbaijan',
    },
    {
      abbreviation: 'BHR',
      name: 'Bahrain',
    },
    {
      abbreviation: 'BRB',
      name: 'Barbados',
    },
    {
      abbreviation: 'BLR',
      name: 'Belarus',
    },
    {
      abbreviation: 'BEL',
      name: 'Belgium',
    },
    {
      abbreviation: 'BLZ',
      name: 'Belize',
    },
    {
      abbreviation: 'BEN',
      name: 'Benin',
    },
    {
      abbreviation: 'BMU',
      name: 'Bermuda',
    },
    {
      abbreviation: 'BTN',
      name: 'Bhutan',
    },
    {
      abbreviation: 'BIH',
      name: 'Bosnia and Herzegovina',
    },
    {
      abbreviation: 'BRA',
      name: 'Brazil',
    },
    {
      abbreviation: 'BRN',
      name: 'Brunei Darussalam',
    },
    {
      abbreviation: 'BGR',
      name: 'Bulgaria',
    },
    {
      abbreviation: 'BFA',
      name: 'Burkina Faso',
    },
    {
      abbreviation: 'BDI',
      name: 'Burundi',
    },
    {
      abbreviation: 'KHM',
      name: 'Cambodia',
    },
    {
      abbreviation: 'CMR',
      name: 'Cameroon',
    },
    {
      abbreviation: 'CAN',
      name: 'Canada',
    },
    {
      abbreviation: 'CYM',
      name: 'Cayman Islands',
    },
    {
      abbreviation: 'CAF',
      name: 'Central African Republic',
    },
    {
      abbreviation: 'CHL',
      name: 'Chile',
    },
    {
      abbreviation: 'CHN',
      name: 'China',
    },
    {
      abbreviation: 'COL',
      name: 'Colombia',
    },
    {
      abbreviation: 'COG',
      name: 'Congo',
    },
    {
      abbreviation: 'COK',
      name: 'Cook Islands',
    },
    {
      abbreviation: 'CRI',
      name: 'Costa Rica',
    },
    {
      abbreviation: 'HRV',
      name: 'Croatia',
    },
    {
      abbreviation: 'CUB',
      name: 'Cuba',
    },
    {
      abbreviation: 'CYP',
      name: 'Cyprus',
    },
    {
      abbreviation: 'CZE',
      name: 'Czechia',
    },
    {
      abbreviation: 'CIV',
      name: "Côte d'Ivoire",
    },
    {
      abbreviation: 'DNK',
      name: 'Denmark',
    },
    {
      abbreviation: 'DJI',
      name: 'Djibouti',
    },
    {
      abbreviation: 'DMA',
      name: 'Dominica',
    },
    {
      abbreviation: 'DOM',
      name: 'Dominican Republic',
    },
    {
      abbreviation: 'ECU',
      name: 'Ecuador',
    },
    {
      abbreviation: 'EGY',
      name: 'Egypt',
    },
    {
      abbreviation: 'SLV',
      name: 'El Salvador',
    },
    {
      abbreviation: 'GNQ',
      name: 'Equatorial Guinea',
    },
    {
      abbreviation: 'EST',
      name: 'Estonia',
    },
    {
      abbreviation: 'ETH',
      name: 'Ethiopia',
    },
    {
      abbreviation: 'FRO',
      name: 'Faroe Islands',
    },
    {
      abbreviation: 'FJI',
      name: 'Fiji',
    },
    {
      abbreviation: 'FIN',
      name: 'Finland',
    },
    {
      abbreviation: 'FRA',
      name: 'France',
    },
    {
      abbreviation: 'GAB',
      name: 'Gabon',
    },
    {
      abbreviation: 'GEO',
      name: 'Georgia',
    },
    {
      abbreviation: 'DEU',
      name: 'Germany',
    },
    {
      abbreviation: 'GIB',
      name: 'Gibraltar',
    },
    {
      abbreviation: 'GHA',
      name: 'Ghana',
    },
    {
      abbreviation: 'GRC',
      name: 'Greece',
    },
    {
      abbreviation: 'GRD',
      name: 'Grenada',
    },
    {
      abbreviation: 'GTM',
      name: 'Guatemala',
    },
    {
      abbreviation: 'GGY',
      name: 'Guernsey',
    },
    {
      abbreviation: 'GIN',
      name: 'Guinea',
    },
    {
      abbreviation: 'HND',
      name: 'Honduras',
    },
    {
      abbreviation: 'HKG',
      name: 'Hong Kong',
    },
    {
      abbreviation: 'HUN',
      name: 'Hungary',
    },
    {
      abbreviation: 'ISL',
      name: 'Iceland',
    },
    {
      abbreviation: 'IND',
      name: 'India',
    },
    {
      abbreviation: 'IDN',
      name: 'Indonesia',
    },
    {
      abbreviation: 'IRN',
      name: 'Iran (Islamic Republic of)',
    },
    {
      abbreviation: 'IRL',
      name: 'Ireland',
    },
    {
      abbreviation: 'IMN',
      name: 'Isle of Man',
    },
    {
      abbreviation: 'ISR',
      name: 'Israel',
    },
    {
      abbreviation: 'ITA',
      name: 'Italy',
    },
    {
      abbreviation: 'JAM',
      name: 'Jamaica',
    },
    {
      abbreviation: 'JPN',
      name: 'Japan',
    },
    {
      abbreviation: 'JEY',
      name: 'Jersey',
    },
    {
      abbreviation: 'KAZ',
      name: 'Kazakhstan',
    },
    {
      abbreviation: 'KEN',
      name: 'Kenya',
    },
    {
      abbreviation: 'KOR',
      name: 'Korea (Republic of)',
    },
    {
      abbreviation: 'KWT',
      name: 'Kuwait',
    },
    {
      abbreviation: 'KGZ',
      name: 'Kyrgyzstan',
    },
    {
      abbreviation: 'LVA',
      name: 'Latvia',
    },
    {
      abbreviation: 'LBN',
      name: 'Lebanon',
    },
    {
      abbreviation: 'LSO',
      name: 'Lesotho',
    },
    {
      abbreviation: 'LBY',
      name: 'Libya',
    },
    {
      abbreviation: 'LIE',
      name: 'Liechtenstein',
    },
    {
      abbreviation: 'LTU',
      name: 'Lithuania',
    },
    {
      abbreviation: 'LUX',
      name: 'Luxembourg',
    },
    {
      abbreviation: 'MWI',
      name: 'Malawi',
    },
    {
      abbreviation: 'MYS',
      name: 'Malaysia',
    },
    {
      abbreviation: 'MLI',
      name: 'Mali',
    },
    {
      abbreviation: 'MLT',
      name: 'Malta',
    },
    {
      abbreviation: 'MRT',
      name: 'Mauritania',
    },
    {
      abbreviation: 'MEX',
      name: 'Mexico',
    },
    {
      abbreviation: 'MDA',
      name: 'Moldova (Republic of)',
    },
    {
      abbreviation: 'MCO',
      name: 'Monaco',
    },
    {
      abbreviation: 'MNE',
      name: 'Montenegro',
    },
    {
      abbreviation: 'MSR',
      name: 'Montserrat',
    },
    {
      abbreviation: 'MAR',
      name: 'Morocco',
    },
    {
      abbreviation: 'NAM',
      name: 'Namibia',
    },
    {
      abbreviation: 'NPL',
      name: 'Nepal',
    },
    {
      abbreviation: 'NLD',
      name: 'Netherlands',
    },
    {
      abbreviation: 'NZL',
      name: 'New Zealand',
    },
    {
      abbreviation: 'NIC',
      name: 'Nicaragua',
    },
    {
      abbreviation: 'NER',
      name: 'Niger',
    },
    {
      abbreviation: 'NGA',
      name: 'Nigeria',
    },
    {
      abbreviation: 'NFK',
      name: 'Norfolk Island',
    },
    { abbreviation: 'NMK', name: 'North Macedonia, Republic of' },
    {
      abbreviation: 'NOR',
      name: 'Norway',
    },
    {
      abbreviation: 'OMN',
      name: 'Oman',
    },
    {
      abbreviation: 'PAK',
      name: 'Pakistan',
    },
    {
      abbreviation: 'PSE',
      name: 'Palestine, State of',
    },
    {
      abbreviation: 'PAN',
      name: 'Panama',
    },
    {
      abbreviation: 'PRY',
      name: 'Paraguay',
    },
    {
      abbreviation: 'PER',
      name: 'Peru',
    },
    {
      abbreviation: 'PHL',
      name: 'Philippines',
    },
    {
      abbreviation: 'POL',
      name: 'Poland',
    },
    {
      abbreviation: 'PRT',
      name: 'Portugal',
    },
    {
      abbreviation: 'PRI',
      name: 'Puerto Rico',
    },
    {
      abbreviation: 'QAT',
      name: 'Qatar',
    },
    {
      abbreviation: 'ROU',
      name: 'Romania',
    },
    {
      abbreviation: 'RUS',
      name: 'Russian Federation',
    },
    {
      abbreviation: 'KNA',
      name: 'Saint Kitts and Nevis',
    },
    {
      abbreviation: 'LCA',
      name: 'Saint Lucia',
    },
    {
      abbreviation: 'VCT',
      name: 'Saint Vincent and the Grenadines',
    },
    {
      abbreviation: 'WSM',
      name: 'Samoa',
    },
    {
      abbreviation: 'SAU',
      name: 'Saudi Arabia',
    },
    {
      abbreviation: 'SRB',
      name: 'Serbia',
    },
    {
      abbreviation: 'SGP',
      name: 'Singapore',
    },
    {
      abbreviation: 'SVK',
      name: 'Slovakia',
    },
    {
      abbreviation: 'SVN',
      name: 'Slovenia',
    },
    {
      abbreviation: 'SOM',
      name: 'Somalia',
    },
    {
      abbreviation: 'ZAF',
      name: 'South Africa',
    },
    {
      abbreviation: 'ESP',
      name: 'Spain',
    },
    {
      abbreviation: 'LKA',
      name: 'Sri Lanka',
    },
    {
      abbreviation: 'SDN',
      name: 'Sudan',
    },
    {
      abbreviation: 'SWE',
      name: 'Sweden',
    },
    {
      abbreviation: 'CHE',
      name: 'Switzerland',
    },
    {
      abbreviation: 'SYR',
      name: 'Syrian Arab Republic',
    },
    {
      abbreviation: 'TWN',
      name: 'Taiwan, Province of China',
    },
    {
      abbreviation: 'TJK',
      name: 'Tajikistan',
    },
    {
      abbreviation: 'TZA',
      name: 'Tanzania, United Republic of',
    },
    {
      abbreviation: 'THA',
      name: 'Thailand',
    },
    {
      abbreviation: 'TGO',
      name: 'Togo',
    },
    {
      abbreviation: 'TTO',
      name: 'Trinidad and Tobago',
    },
    {
      abbreviation: 'TUN',
      name: 'Tunisia',
    },
    {
      abbreviation: 'TUR',
      name: 'Turkey',
    },
    {
      abbreviation: 'TKM',
      name: 'Turkmenistan',
    },
    {
      abbreviation: 'TCA',
      name: 'Turks and Caicos Islands',
    },
    {
      abbreviation: 'TUV',
      name: 'Tuvalu',
    },
    {
      abbreviation: 'UGA',
      name: 'Uganda',
    },
    {
      abbreviation: 'UKR',
      name: 'Ukraine',
    },
    {
      abbreviation: 'ARE',
      name: 'United Arab Emirates',
    },
    {
      abbreviation: 'GBR',
      name: 'United Kingdom of Great Britain and Northern Ireland',
    },
    {
      abbreviation: 'USA',
      name: 'United States of America',
    },
    {
      abbreviation: 'UZB',
      name: 'Uzbekistan',
    },
    {
      abbreviation: 'VUT',
      name: 'Vanuatu',
    },
    {
      abbreviation: 'VEN',
      name: 'Venezuela (Bolivarian Republic of)',
    },
    {
      abbreviation: 'VNM',
      name: 'Vietnam',
    },
    {
      abbreviation: 'VGB',
      name: 'Virgin Islands (British)',
    },
    {
      abbreviation: 'ZMB',
      name: 'Zambia',
    },
    {
      abbreviation: 'ZWE',
      name: 'Zimbabwe',
    },
    {
      abbreviation: 'ALA',
      name: 'Åland Islands',
    },
  ],
};

const getStatus = async (accountId: string): Promise<string> =>
  (await Http.get(`${ACCOUNT_ENDPOINT}/${accountId}${KYC_ENDPOINT}/status`)).data;
// async (accountId: string): Promise<Account> =>
//   Http.get(`${ACCOUNT_ENDPOINT}/${accountId}`);

const forgotPassword = async (data: ForgotPasswordRequest): Promise<any> =>
  (await Http.post(FORGOT_PASSWORD_ENDPOINT, data)).data;

const newPassword = async (data: NewPasswordRequest): Promise<any> =>
  (await Http.post(NEW_PASSWORD_ENDPOINT, data)).data;

export default {
  register,
  login,
  getAccount,
  changePassword,
  enableTwoFactorAuth,
  confirmTwoFactorAuth,
  disableTwoFactorAuth,
  getCountries,
  getCitizenships,
  getStatus,
  kycCreateAplicant,
  kycIdVerification,
  kycCreateCheck,
  forgotPassword,
  newPassword,
  isDuplicateEmail,
  affiliateCodeExists,
  verifyEmail,
  getProfileInfo,
  updatePaymentInfo,
  exchangeAuthCode,
  getUserInfo,
  refreshToken,
};
