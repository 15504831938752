import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { ProductCurrencyState } from '../../App/Home/HomeContent/Product/ProductInvest/state/initialState';
import useInvestCurrencyPrices from '../../App/Home/HomeContent/Product/ProductInvest/useInvestCurrencyPrices';
import useInvestCurrencyState from '../../App/Home/HomeContent/Product/ProductInvest/useInvestCurrencyState';
import { Colors } from '../../ui/style/colors';
import {
  CRYPTO_MAX_FRACTION,
  CRYPTO_MIN_FRACTION,
  WIRE_EUR_MIN_FRACTION,
  WIRE_EUR_TRANSACTION_FEE,
} from '../../utils/constants/amounts';
import { getCurrencySymbolName } from '../../utils/helper/utils';

const useStyles = makeStyles({
  inputSymbol: {
    width: 71,
    height: 44,
    borderRadius: '0 4px 4px 0',
    backgroundColor: `${Colors.blueHaze}`,
    color: `${Colors.white}`,
    fontSize: 12,
    fontWeight: 600,
  },
  inputSymbolValue: {
    position: 'relative',
    float: 'left',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  value: {
    color: `${Colors.blueCharcoal}`,
    textAlign: 'center',
    // marginBottom: 10,
    fontSize: 14,
  },
  input: {
    '&::placeholder': {
      color: `${Colors.blueHaze}`,
    },
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
    },
    '&[type=number]': {
      '-webkit-appearance': 'textfield',
      '-moz-appearance': 'textfield',
    },
  },
  valueOfSLC: {
    color: `${Colors.blueHaze}`,
    fontSize: 12,
    position: 'absolute',
    top: '50%',
    transform: ' translate(-100%, -50%)',
    width: 'max-content',
    paddingRight: 12,
  },
  valueOfFee: {
    color: `${Colors.blueHaze}`,
    fontSize: 12,
    position: 'absolute',
    width: 'max-content',
    paddingRight: 0,
    top: 53,
    right: 0,
  },
});

type InputProps = ProductCurrencyState & {
  field: {
    name: string;
    value: string;
  };
  form: {
    setFieldError: (name: string, message: string) => void;
    setFieldTouched: (name: string) => void;
    handleBlur: void;
    values: any;
  };
  fee: number;
  onChange(): void;
};

const InvestInput = ({
  form: { setFieldError, setFieldTouched },
  field,
  onChange,
  fee,
}: InputProps) => {
  const classes = useStyles();
  const { name, value } = field;

  const { investCurrency } = useInvestCurrencyState();
  const { selectedOption } = investCurrency;

  const symbolName = getCurrencySymbolName(selectedOption);

  const { getSelectedCoinMarketVal } = useInvestCurrencyPrices();
  const selectedMarketVal = getSelectedCoinMarketVal(selectedOption);
  const slcEurVal = getSelectedCoinMarketVal('SLC');

  const getInputText = () => {
    if (selectedOption === 'WIRE_EUR') {
      return name === 'amount' ? 'EUR' : 'SLC';
    } else if (selectedOption === 'SLC') {
      return name === 'amount' ? symbolName : 'EUR';
    } else if (selectedOption === 'ETH') {
      return name === 'amount' ? 'SLC' : symbolName;
    } else if (selectedOption !== 'WIRE_EUR' && selectedOption !== '') {
      return name === 'amount' ? 'SLC' : symbolName;
    } else {
      return name === 'amount' ? 'SLC' : 'EUR';
    }
  };

  const renderEndAdornment = () => {
    switch (selectedOption) {
      case 'WIRE_EUR':
        return (
          <div className={classes.valueOfSLC}>
            1 EUR =&nbsp;
            {Number(1 / (investCurrency.membershipState.eur_price / 100)).toFixed(
              CRYPTO_MAX_FRACTION,
            )}
            &nbsp; SLC
          </div>
        );
      case 'SLC':
        return <div className={classes.valueOfSLC}>1 SLC = {slcEurVal} EUR</div>;

      case 'WALLET_EUR':
        return (
          <div className={classes.valueOfSLC}>
            1 SLC =&nbsp;
            {investCurrency.membershipState.eur_price.toFixed(WIRE_EUR_MIN_FRACTION) / 100}
            &nbsp; {symbolName}
          </div>
        );
      default:
        return (
          <div className={classes.valueOfSLC}>
            1 SLC =&nbsp;
            {(investCurrency.membershipState.eur_price / 100 / selectedMarketVal).toFixed(
              CRYPTO_MIN_FRACTION,
            )}
            &nbsp; {symbolName}
          </div>
        );
    }
  };

  const renderFeeAdornment = () => {
    return (
      <div className={classes.valueOfFee} style={{}}>
        {selectedOption !== 'WIRE_EUR' && `+ ${fee}  ${symbolName} fee is included`}
        {selectedOption === 'WIRE_EUR' && ` + ${WIRE_EUR_TRANSACTION_FEE} EUR fee is included`}
      </div>
    );
  };

  return (
    <TextField
      variant="outlined"
      type="number"
      name={name}
      className={classes.value}
      onInput={() => setFieldTouched(name)}
      onChange={onChange}
      onBlur={() => {
        setFieldTouched(name);
        if (value === '0')
          setFieldError(name, `${name[0].toUpperCase() + name.substr(1)} must be more than 0`);
      }}
      disabled={name === 'get'}
      autoComplete="off"
      placeholder="0"
      fullWidth
      value={value}
      inputProps={{ min: '0', step: 0.00000000000000001 }}
      InputProps={{
        classes: {
          input: classes.input,
        },
        style: {
          height: 44,
          paddingRight: 0,
        },
        endAdornment: (
          <div style={{ display: 'flex' }}>
            {name === 'amount' && renderEndAdornment()}
            {name === 'get' && value && renderFeeAdornment()}

            <div className={classes.inputSymbol}>
              <div className={classes.inputSymbolValue}>{getInputText()}</div>
            </div>
          </div>
        ),
      }}
      // {...field}
      // {...props}
    />
  );
};

export default InvestInput;
