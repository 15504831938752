import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { PayloadAC } from 'typesafe-actions';
import { ReactComponent as SuccessIconSvg } from '../../../../../assets/success@1.5x.svg';
import CustomButtonPrimary from '../../../../../components/CustomButtons/CustomButtonPrimary';
import CustomButtonSecondary from '../../../../../components/CustomButtons/CustomButtonSecondary';
import Chips from '../../../../../components/InviteFirendsModal/Chips';
import SacretError from '../../../../../components/SacretError';
import LocalStorage from '../../../../../data/LocalStorage';
import {
  GetFriendsInvitationsPaginatedRequest,
  TotalInvitesRequest,
} from '../../../../../data/repositories/invites/typedef';
import { Colors } from '../../../../../ui/style/colors';
import { USER_ID } from '../../../../../utils/constants/localStorageKeys';
import { emailValidator } from '../../../../../utils/validators';
import { RETURN_TO_DEFAULT_STATE, SEND_INVITES } from '../state/actions';
import { InviteData, InviteModalState } from './state/initialState';

const styles = (theme: Theme) =>
  createStyles({
    dialogPaper: {
      height: 'center',
      width: 'center',
      borderRadius: 6,
      backgroundColor: 'transparent',
      boxShadow: '0 10px 30px 0 rgba(132,141,182,0.25)',
    },
    dialogTittleText: {
      color: `${Colors.blueCharcoal}`,
      fontSize: 18,
      fontWeight: 600,
      letterSpacing: 1.13,
      display: 'flex',
      alignItems: 'center',
    },
    dialogTitle: {
      marginTop: 25,
      marginLeft: 25,
      marginRight: 25,
      padding: 0,
    },
    inviteFriendIcon: {
      marginRight: 20,
    },
    closeButton: {
      float: 'right',
      verticalAlign: 'text-top',
    },
    inputField: {
      borderRadius: 6,
      marginBottom: 30,
      height: 50,
    },
    marginBottom: {
      marginBottom: 5,
    },
    textArea: {
      marginTop: 14,
    },
    label: {
      fontSize: 14,
      color: `${Colors.nepal}`,
      marginBottom: 10,
      marginLeft: 4,
    },
    buttonAdd: {
      cursor: 'pointer',
      background: 'transparent',
      border: 'none',
    },
    buttons: {
      textTransform: 'none',
      fontSize: 14,
      marginTop: 30,
      height: 48,
      fontWeight: 600,
      width: '100%',
    },
    buttonWhite: {
      marginTop: 20,
      marginBottom: 15,
    },
    scroll: {
      height: 200,
      overflowY: 'scroll',
    },
    inputError: {
      color: `${Colors.red}`,
      margin: '10px 0',
      height: 15,
      fontFamily: 'Manrope3',
    },
    wrapper: {
      height: 200,
    },
    wrapperError: {
      height: 400,
    },
    successMessage: {
      marginLeft: 6,
      fontSize: 24,
      color: theme.palette.primary.light,
      fontWeight: 'bold',
    },
  });

type Props = InviteModalState & {
  asyncGetTotalFriendsInvitationsPaginated: PayloadAC<
    'invites/get_total_friends_invitations',
    TotalInvitesRequest
  >;
  asyncGetFriendsInvitationsPaginated: PayloadAC<
    'invites/get_friends_invitations',
    GetFriendsInvitationsPaginatedRequest
  >;
  isOpen: boolean;
  closeModal: () => void;
  asyncSendInvites: PayloadAC<typeof SEND_INVITES, InviteData>;
};
const useStyles = makeStyles(styles);

const InviteFriendsModal: React.FC<Props> = ({
  asyncGetTotalFriendsInvitationsPaginated,
  asyncGetFriendsInvitationsPaginated,
  isOpen,
  asyncSendInvites,
  closeModal,
  ...props
}: Props): JSX.Element => {
  const classes = useStyles();
  const [emailInputValue, setEmailInputValue] = useState('');
  const [emails, setEmails] = useState<string[]>([]);
  const [message, setMessage] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isTocuhed, setIsTouched] = useState(false);
  const { inProgress, success, error } = props;
  const dispatch = useDispatch();
  const accountId = LocalStorage.get(USER_ID);

  const addEmail = () => {
    if (
      emailValidator(emailInputValue) &&
      !emails.includes(emailInputValue) &&
      emails.length < 100
    ) {
      setEmails(prevState => [...prevState, emailInputValue]);
      setEmailInputValue('');
      setIsEmailValid(true);
      setIsTouched(false);
    } else {
      setIsEmailValid(false);
      setIsTouched(true);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key == 'Enter' || event.key == ' ') {
      addEmail();
    }
  };

  const deleteEmail = (selectedEmail: string) => {
    if (emails.length !== -1) {
      const filteredEmails = emails.filter(email => email !== selectedEmail);
      setEmails(filteredEmails);
    }
  };

  const sendInviteHandler = () => {
    const emailsInfo = emails.map(email => ({ email }));
    asyncSendInvites({ invite_emails: emailsInfo, text: message });
    setTimeout(() => {
      accountId &&
        asyncGetTotalFriendsInvitationsPaginated({
          accountId: accountId,
        }) &&
        asyncGetFriendsInvitationsPaginated({
          accountId,
          page: 0,
          perPage: 10,
        });
    }, 1000);
  };

  const handleCloseModal = () => {
    closeModal();
    dispatch({
      type: RETURN_TO_DEFAULT_STATE,
    });
    setEmailInputValue('');
    setEmails([]);
    setMessage('');
    setIsEmailValid(false);
    setIsTouched(false);
  };
  return (
    <Dialog fullWidth open={isOpen} className={classes.dialogPaper}>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Grid container item xs={12} justifyContent="space-between">
          <Typography className={classes.dialogTittleText}>
            <PersonAddOutlinedIcon className={classes.inviteFriendIcon} />
            Invite Friends
          </Typography>
          <IconButton className={classes.closeButton} onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {inProgress ? (
          <Grid container justifyContent="center" alignItems="center" className={classes.wrapper}>
            <CircularProgress color="secondary" size={60} />
          </Grid>
        ) : success ? (
          <Grid container justifyContent="center" alignItems="center" className={classes.wrapper}>
            <SuccessIconSvg style={{ marginTop: 29, marginBottom: 24 }} />
            <Typography className={classes.successMessage}>
              {emails.length > 1
                ? 'Invitations sent successfully!'
                : 'Invitation sent successfully!'}
            </Typography>
          </Grid>
        ) : error ? (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            className={classes.wrapperError}
          >
            <SacretError errorMessage="Invite failed" showBackButton={false} />
          </Grid>
        ) : (
          <>
            <InputLabel className={classes.label}>Add email</InputLabel>
            <TextField
              variant="outlined"
              type="email"
              fullWidth
              value={emailInputValue}
              placeholder="Maximum 100 email address"
              className={
                isTocuhed ? `${classes.inputField} ${classes.marginBottom}` : classes.inputField
              }
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setEmailInputValue(e.target.value)
              }
              onKeyPress={handleKeyPress}
              onBlur={() => setIsTouched(false)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <button className={classes.buttonAdd} onClick={addEmail}>
                      Add
                    </button>
                  </InputAdornment>
                ),
              }}
            />
            {isTocuhed && !isEmailValid && (
              <Box className={classes.inputError}>Email is not valid</Box>
            )}
            <Chips chipsArray={emails} onDelete={deleteEmail} scrollChips />
            <TextField
              label="Write your email"
              multiline
              minRows={8}
              variant="outlined"
              fullWidth
              className={classes.textArea}
              value={message}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMessage(e.target.value)}
            />
            <CustomButtonPrimary
              disabled={message.trim() !== '' && emails.length >= 1 ? false : true}
              variant="contained"
              size="large"
              className={classes.buttons}
              onClick={sendInviteHandler}
            >
              Send
            </CustomButtonPrimary>
            <CustomButtonSecondary
              variant="contained"
              size="large"
              className={`${classes.buttons} ${classes.buttonWhite}`}
              onClick={handleCloseModal}
            >
              Cancel
            </CustomButtonSecondary>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default InviteFriendsModal;
