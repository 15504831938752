import { connect } from 'react-redux';
import TransactionGrouping from './TransactionGrouping';
import { AppState } from '../../../../state/initialState';
import {
  asyncGetTransactionGroupingPaginated,
  asyncExportTransactionGrouping,
  transactionGroupingClearAllFilters,
} from './state/actions';
import {
  transactionGroupingStateSelector,
  getTransactionGroupingAppliedFiltersSelector,
} from './state/selectors';

const mapStateToProps = (state: AppState) => {
  const appliedFilters = getTransactionGroupingAppliedFiltersSelector(state);
  return { ...transactionGroupingStateSelector(state), appliedFilters };
};

const mapDispatchToProps = {
  asyncGetTransactionGroupingPaginated: asyncGetTransactionGroupingPaginated.request,
  asyncExportTransactionGrouping: asyncExportTransactionGrouping.request,
  transactionGroupingClearAllFilters: transactionGroupingClearAllFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionGrouping);
