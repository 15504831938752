import { createReducer } from 'typesafe-actions';
import {
  GET_AFFILIATE_CODE,
  GET_AFFILIATE_CODE_FAIL,
  GET_AFFILIATE_CODE_SUCCESS,
  GET_REFERRALS,
  GET_REFERRALS_FAIL,
  GET_REFERRALS_SUCCESS,
  GET_INCOME_BALANCES_SUCCESS,
} from './actions';
import { initialState } from './initialState';

export default createReducer(initialState, {
  [GET_AFFILIATE_CODE]: state => ({
    ...state,
    getCodeInProgress: true,
  }),
  [GET_AFFILIATE_CODE_SUCCESS]: (state, { payload }) => ({
    ...state,
    code: payload.code,
    getCodeInProgress: false,
    getCodeFail: false,
  }),
  [GET_AFFILIATE_CODE_FAIL]: (state, { payload }) => ({
    ...state,
    getCodeInProgress: false,
    getCodeFail: true,
    ...(payload && payload.response ? { getCodeError: payload.response.data } : {}),
  }),
  [GET_REFERRALS]: state => ({
    ...state,
    getReferralsInProgress: true,
  }),
  [GET_REFERRALS_SUCCESS]: (state, { payload }) => ({
    ...state,
    directReferrals: payload,
    getReferralsInProgress: false,
    getReferralsFail: false,
  }),
  [GET_REFERRALS_FAIL]: (state, { payload }) => ({
    ...state,
    getReferralsInProgress: false,
    getReferralsFail: true,
    ...(payload && payload.response ? { getReferralsError: payload.response.data } : {}),
  }),
  [GET_INCOME_BALANCES_SUCCESS]: (state, { payload }) => ({
    ...state,
    incomeBalances: payload,
  }),
});
