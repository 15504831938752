import { connect } from 'react-redux';
import { AppState } from '../../../../../state/initialState';
import AllTransactions from './AllTransactions';
import {
  allTransactionsTableStateSelector,
  getUserTransactionHistoryAppliedFiltersSelector,
  userTransactionHistoryStateSelector,
  getSelectedUserStateSelector,
} from '../state/selectors';
import {
  asyncGetAllTransactionsPaginated,
  userTransactionHistoryClearAllFilters,
  setAllTransactionsTablePage,
  asyncExportAllTransactions,
} from '../state/actions';

const mapDispatchToProps = {
  asyncGetAllTransactionsPaginated: asyncGetAllTransactionsPaginated.request,
  setAllTransactionsTablePage: setAllTransactionsTablePage,
  clearAllFilters: userTransactionHistoryClearAllFilters,
  asyncExportAllTransactions: asyncExportAllTransactions.request,
};

const mapStateToProps = (state: AppState) => {
  const exportInProgress = userTransactionHistoryStateSelector(state).exportInProgress;
  const selectedUser = getSelectedUserStateSelector(state);
  return {
    appliedFilters: getUserTransactionHistoryAppliedFiltersSelector(state),
    ...allTransactionsTableStateSelector(state),
    exportInProgress,
    selectedUser,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AllTransactions);
