import { createStyles, makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { UserDetails } from '../../../data/repositories/admin/typedefs';
import { Colors } from '../../../ui/style/colors';
import balanceUtils from '../../../utils/balanceUtils';
import {
  CRYPTO_MAX_FRACTION,
  CRYPTO_MIN_FRACTION,
  WIRE_EUR_DECIMALS,
  WIRE_EUR_MAX_FRACTION,
  WIRE_EUR_MIN_FRACTION,
} from '../../../utils/constants/amounts';

const useStyles = makeStyles(() =>
  createStyles({
    statusSuccess: {
      color: `${Colors.havelockBlue}`,
      fontSize: 14,
      textTransform: 'capitalize',
    },
    statusPending: {
      color: `${Colors.blueViolet}`,
      fontSize: 14,
      textTransform: 'capitalize',
    },
    link: {
      color: `${Colors.blueBell}`,
      cursor: 'pointer',
      textDecoration: 'none',
    },
  }),
);

type Props = {
  user: UserDetails;
};

const renderType = (type: any) => {
  switch (type) {
    case 'new_user_reward':
      return 'Registration reward';
    case 'new_merchant_reward':
      return 'Became Partner';
    case 'merchant_parent_reward':
      return 'Affiliate became Partner';
    case 'new_user_affiliate_reward':
      return 'New Affiliate';
    case 'premium_user_reward':
      return 'Affiliate package purchase';
    case 'premium_user_affiliate_reward':
      return 'Affiliate purchased Affiliate package';
    default:
      return 'BV';
  }
};

const statusRenderType = (type: string) => {
  switch (type) {
    case 'pending_escrow':
      return 'Pending Escrow';
    case 'timed_escrow':
      return 'Timed Escrow';
    default:
      return type;
  }
};

const UserEarningsTableRow: FC<Props> = ({ user }: Props) => {
  const classes = useStyles();

  const balance = balanceUtils.formatBalanceToString(
    user.slc_amount,
    7,
    CRYPTO_MIN_FRACTION,
    CRYPTO_MAX_FRACTION,
  );
  return (
    <TableRow>
      <TableCell
        className={user.tx_status === 'completed' ? classes.statusSuccess : classes.statusPending}
      >
        {statusRenderType(user.tx_status)}
      </TableCell>
      <TableCell>{renderType(user.tx_origin)}</TableCell>
      <TableCell>
        <Link
          to={{
            pathname: '/affiliate',
            state: { email: user.user_email },
          }}
          className={classes.link}
        >
          {user.user_email}
        </Link>
      </TableCell>
      <TableCell>
        <div>{user.parent_email}</div>
      </TableCell>
      <TableCell>
        <div> {moment(user.tx_created_at).format('DD/MM/YYYY HH:mm')}</div>
      </TableCell>
      <TableCell align="right">{balance} SLC</TableCell>
      <TableCell align="right">
        {`${balanceUtils.formatBalanceToString(
          user.eur_amount || 0,
          WIRE_EUR_DECIMALS,
          WIRE_EUR_MIN_FRACTION,
          WIRE_EUR_MAX_FRACTION,
        )} EUR`}{' '}
      </TableCell>
    </TableRow>
  );
};

export default UserEarningsTableRow;
