import { Chip, Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';
import { PayloadAC } from 'typesafe-actions';
import UserIcon from '../../../../../assets/user-icon-modified.svg';
import CustomButtonPrimary from '../../../../../components/CustomButtons/CustomButtonPrimary';
import SacretEmpty from '../../../../../components/SacretEmpty';
import { SelectedUser } from '../../../../../data/repositories/admin/typedefs';
import { Colors } from '../../../../../ui/style/colors';
import { SET_INFO_DIALOG_FIELDS } from '../../InfoDialog/state/actions';
import AllTransactions from './AllTransactions';
import { SET_TRANSACTION_HISTORY_SELETCED_USER } from './state/actions';
import { selectedUserInitialState, UserTransactionHistoryState } from './state/initialState';
import WalletTransfers from './WalletTransfers';

const styles = (theme: Theme) =>
  createStyles({
    wrapper: {
      paddingTop: 30,
      flexDirection: 'column',
      position: 'relative',
    },
    label: {
      color: theme.palette.secondary.light,
      fontSize: 18,
      fontWeight: 800,
      float: 'left',
      marginBottom: 30,
      cursor: 'pointer',
    },
    selectedUser: {
      color: `${Colors.blueCharcoal}`,
      fontSize: 20,
      fontWeight: 800,
      textTransform: 'uppercase',
    },
    'active-label': {
      color: `${Colors.mediumTurquoise}`,
    },
    'disabled-button': {
      cursor: 'default;',
    },
    margin: {
      marginLeft: 5,
      marginRight: 5,
    },
    clearChipIcon: {
      fontSize: 14,
      padding: 5,
      color: `${Colors.white}`,
    },
    chip: {
      backgroundColor: `${Colors.spunPearl}`,
      '&:hover': {
        backgroundColor: `${Colors.ghost}`,
      },
      color: `${Colors.white}`,
      padding: 5,
    },
    sacretEmpty: {
      '& img': {
        width: 60,
      },
    },
    mt30: {
      marginTop: 30,
    },
    dialog: {
      display: 'block',
      paddingLeft: 20,
      '& p': {
        margin: 0,
      },
    },
  });

const useStyles = makeStyles(styles);
type Props = UserTransactionHistoryState & {
  setInfoDialogFields: PayloadAC<typeof SET_INFO_DIALOG_FIELDS, any>;
  setTransactionHistorySelectedUser: PayloadAC<
    typeof SET_TRANSACTION_HISTORY_SELETCED_USER,
    SelectedUser
  >;
};

const UserTransactionHistory: React.FC<Props> = (props: Props): JSX.Element => {
  const { setInfoDialogFields, setTransactionHistorySelectedUser, selectedUser, activeTab } = props;
  const [selectedTab, setSelectedTab] = useState('all_transactions');
  const isUserSelected = selectedUser.id !== '';
  const classes = useStyles();
  useEffect(() => {
    setTransactionHistorySelectedUser(selectedUserInitialState);
  }, []);
  useEffect(() => setSelectedTab(activeTab), [activeTab]);

  const openModal = () => {
    setInfoDialogFields({
      dialogProps: { maxWidth: 'md', dialogTittleWrapperClass: classes.dialog },
      dialogType: 'SeacrhUserDialog',
      title: 'User search',
    });
  };

  return (
    <div>
      <CustomButtonPrimary variant="outlined" size="medium" onClick={openModal}>
        Select User
      </CustomButtonPrimary>
      {isUserSelected ? (
        <>
          <Grid container justifyContent="space-between" className={classes.mt30}>
            <Grid item>
              <Chip
                size="small"
                clickable={true}
                deleteIcon={<CloseIcon className={classes.clearChipIcon} />}
                label={selectedUser.email}
                onDelete={() => setTransactionHistorySelectedUser(selectedUserInitialState)}
                className={classes.chip}
              />
            </Grid>
            <Grid item>
              <Typography className={classes.selectedUser}>
                {selectedUser.firstName} {selectedUser.lastName}
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="column" className={classes.wrapper}>
            <Grid item>
              <Typography
                onClick={() => setSelectedTab('all_transactions')}
                className={`${classes.label} ${
                  selectedTab === 'all_transactions' ? classes['active-label'] : null
                }`}
              >
                SLC transactions
              </Typography>
              <Typography className={`${classes.label} ${classes.margin}`}>/</Typography>
              <Typography
                onClick={() => setSelectedTab('wallet_transfers')}
                className={`${classes.label} ${
                  selectedTab === 'wallet_transfers' ? classes['active-label'] : null
                }`}
              >
                Wallet Transfers
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {selectedTab === 'all_transactions' && <AllTransactions />}
              {selectedTab === 'wallet_transfers' && <WalletTransfers />}
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <SacretEmpty
            image={UserIcon}
            title="No user selected!"
            text="Please select user to see his transaction history"
            className={classes.sacretEmpty}
          />
        </>
      )}
    </div>
  );
};

export default UserTransactionHistory;
