import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { EmptyAC, PayloadAC } from 'typesafe-actions';
import IOSSwitch from '../../../../../components/IOSSwitch';
import { Colors } from '../../../../../ui/style/colors';
import Settings2faDialog from './Settings2faDialog';
import { TwoFactorAuthState } from './state/initialState';

const styles = (theme: Theme) =>
  createStyles({
    card: {
      boxShadow: '0 10px 30px 0 rgba(132,141,182,0.25)',
      verticalAlign: 'sub',
    },
    cardContent: {
      margin: 30,
    },
    label: {
      color: theme.palette.secondary.light,
      fontSize: 14,
      fontWeight: 800,
      letterSpacing: 1,
      textTransform: 'uppercase',
      marginBottom: 30,
    },
    twoFactorAuth: {
      color: theme.palette.primary.light,
      fontSize: 14,
      letterSpacing: 0.88,
      marginBottom: 24,
    },
    switchLabel: {
      color: theme.palette.primary.light,
      fontSize: 14,
      marginLeft: 16,
    },
    link: {
      cursor: 'pointer',
      textDecoration: 'none',
      color: `${Colors.mediumTurquoise}`,
    },
  });

const useStyles = makeStyles(styles);
type Props = TwoFactorAuthState & {
  openTwoFactorAuth: PayloadAC<'settings/open_dialog', number>;
  enableTwoFactorAuth: EmptyAC<'settings/enable_two_factor_auth'>;
  isTwoFactorAuthEnabled: EmptyAC<'settings/is_two_factor_auth_enabled'>;
};

const SettingsTwoFactorAuth: React.FC<Props> = (props: Props): JSX.Element => {
  const classes = useStyles();
  const { openTwoFactorAuth, isTwoFactorAuthEnabled, enabled } = props;

  useEffect(() => {
    if (enabled == undefined) {
      isTwoFactorAuthEnabled();
    }
  }, []);

  const authMessage =
    'Two-factor authentication adds an extra layer of security to your account. When you set it up, you sign in to your account in two steps, using your password and the generated code from an authenticator app, such as ';

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Typography className={classes.label} component="p">
          Two-factor authentication(2FA)
        </Typography>
        <Typography className={classes.twoFactorAuth} component="p">
          {authMessage}
          <a target="_blank" rel="noreferrer" href="https://authy.com" className={classes.link}>
            Authy
          </a>
          <span>&#44; </span>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://support.google.com/accounts/answer/1066447?co=GENIE.Platform%3DAndroid&hl=en&oco=0"
            className={classes.link}
          >
            Google Authenticator
          </a>
          <span>&#44; </span>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.microsoft.com/en-us/account/authenticator"
            className={classes.link}
          >
            Microsoft Authenticator
          </a>
          <span>&#44; etc.</span>
        </Typography>
        <Grid item xs={12}>
          <IOSSwitch
            onClick={() => {
              if (enabled == undefined ? false : enabled) {
                openTwoFactorAuth(3);
              } else {
                openTwoFactorAuth(1);
              }
            }}
            checked={enabled == undefined ? false : enabled}
          />
          <Settings2faDialog />
          <Typography className={classes.switchLabel} display="inline" component="p">
            Switch to {enabled ? 'deactivate' : 'activate'}
          </Typography>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SettingsTwoFactorAuth;
