import { createReducer } from 'typesafe-actions';
import {
  GET_MERCHANTS_REFERRALS_PAGINATED,
  GET_MERCHANTS_REFERRALS_PAGINATED_FAILED,
  GET_MERCHANTS_REFERRALS_PAGINATED_SUCCESS,
  GET_MERCHANTS_REFERRALS_STATS,
  GET_MERCHANTS_REFERRALS_STATS_FAIL,
  GET_MERCHANTS_REFERRALS_STATS_SUCCESS,
  GET_MERCHANTS_UNCLAIMED_SLC_FAIL,
  GET_MERCHANTS_UNCLAIMED_SLC_SUCCESS,
  SET_MERCHANTS_TABLE_PAGE,
} from './actions';
import { initialState } from './initialState';

export default createReducer(initialState, {
  [GET_MERCHANTS_REFERRALS_STATS]: state => ({
    ...state,
    inProgress: true,
  }),
  [GET_MERCHANTS_REFERRALS_STATS_SUCCESS]: (state, { payload }) => ({
    ...state,
    stats: payload,
    inProgress: false,
    fail: false,
  }),
  [GET_MERCHANTS_REFERRALS_STATS_FAIL]: (state, { payload }) => ({
    ...state,
    inProgress: false,
    fail: true,
    ...(payload && payload.response ? { error: payload.response.data } : {}),
  }),
  [GET_MERCHANTS_REFERRALS_PAGINATED]: state => ({
    ...state,
    inProgress: true,
    fail: false,
  }),
  [GET_MERCHANTS_REFERRALS_PAGINATED_SUCCESS]: (state, { payload }) => ({
    ...state,
    referrals: payload,
    inProgress: false,
    fail: false,
  }),
  [GET_MERCHANTS_REFERRALS_PAGINATED_FAILED]: (state, { payload }) => ({
    ...state,
    inProgress: false,
    fail: true,
    ...(payload && payload.response ? { error: payload.response.data } : {}),
  }),
  [SET_MERCHANTS_TABLE_PAGE]: (state, { payload }) => ({
    ...state,
    page: payload,
  }),
  [GET_MERCHANTS_UNCLAIMED_SLC_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      cycles: payload,
    };
  },
  [GET_MERCHANTS_UNCLAIMED_SLC_FAIL]: (state, { payload }) => ({
    ...state,
    ...(payload && payload.response ? { error: payload.response.data } : {}),
  }),
});
