import { Dialog, DialogTitle, IconButton, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect } from 'react';
import { EmptyAC, PayloadAC } from 'typesafe-actions';
import { Colors } from '../../../../../../ui/style/colors';
import Settings2faCodeDisableDialog from '../Settings2faDisableDialogs/Settings2faCodeDisableDialog/';
import Settings2faConfirmDisableDialog from '../Settings2faDisableDialogs/Settings2faConfirmDisableDialog/';
import SettingsTwoFactorAuthConfirmDialog from '../SettingsTwoFactorAuthConfirmDialog/';
import SettingsTwoFactorAuthDialog from '../SettingsTwoFactorAuthDialog/';
import { TwoFactorAuthState } from '../state/initialState';

const styles = (theme: Theme) =>
  createStyles({
    dialog: {
      height: 'center',
      width: 'center',
      borderRadius: 6,
      backgroundColor: 'transparent',
      paddingLeft: 30,
      paddingRight: 30,
    },
    label: {
      color: `${Colors.blueCharcoal}`,
      fontSize: 18,
      fontWeight: 600,
      letterSpacing: 1.13,
    },
    closeButton: {
      position: 'absolute',
      right: 5,
      top: 5,
      color: theme.palette.primary.light,
    },
  });
const useStyles = makeStyles(styles);

type Props = TwoFactorAuthState & {
  openTwoFactorAuth: PayloadAC<'settings/open_dialog', number>;
  clearTwoFactorAuthState: EmptyAC<'settings/clear_two_factor_auth_state'>;
  isTwoFactorAuthDialogOpen: PayloadAC<'settings/is_two_factor_auth_dialog_open', boolean>;
};

const Settings2faDialog: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const {
    openTwoFactorAuth,
    step,
    clearTwoFactorAuthState,
    isTwoFactorAuthDialogOpen,
    isDialogOpen,
  } = props;

  useEffect(() => {
    if (step > 0) {
      isTwoFactorAuthDialogOpen(true);
    }
    clearTwoFactorAuthState();
  }, [step]);

  const onClose = () => {
    isTwoFactorAuthDialogOpen(false);
  };

  const onExited = () => {
    openTwoFactorAuth(0);
  };

  return (
    <Dialog TransitionProps={{ onExited: onExited }} open={isDialogOpen} className={classes.dialog}>
      <DialogTitle>
        {step < 2 ? (
          <Typography className={classes.label} component="p">
            Two-factor authentication(2FA)
          </Typography>
        ) : (
          <Typography className={classes.label} component="p">
            {step === 2 ? 'Activate' : ' Deactivate'} 2FA
          </Typography>
        )}

        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {step === 1 && <SettingsTwoFactorAuthDialog />}
      {step === 2 && <SettingsTwoFactorAuthConfirmDialog />}
      {step === 3 && <Settings2faConfirmDisableDialog />}
      {step === 4 && <Settings2faCodeDisableDialog />}
    </Dialog>
  );
};

export default Settings2faDialog;
