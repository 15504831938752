import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { PayloadAC } from 'typesafe-actions';
import { REDIRECT_TO_KYC } from '../../../../../utils/constants/routes';
import { styles } from '../KycRedirectionDialog/KycRedirectionDialog';
import { IS_INFO_DIALOG_OPEN } from '../state/actions';
import { ACCESS_TOKEN } from '../../../../../utils/constants/localStorageKeys';
import LocalStorage from '../../../../../data/LocalStorage';
const useStyles = makeStyles(styles);

type Props = {
  isInfoDialogOpen: PayloadAC<typeof IS_INFO_DIALOG_OPEN, boolean>;
};

const KycRestrictionDialog: React.FC<Props> = ({ isInfoDialogOpen }: Props) => {
  const classes = useStyles();
  const token = LocalStorage.get(ACCESS_TOKEN);

  return (
    <Grid id="KycRedirectionDialog" container alignItems="center" className={classes.wrapper}>
      <Grid container className={classes.contentWrapper}>
        <Grid item xs={12} className={classes.content}>
          Please complete KYC to deposit funds on your Revolut account
        </Grid>
      </Grid>

      <Grid container justifyContent="space-evenly" className={classes.buttonsWrapper}>
        <Grid item xs={12} sm={5}>
          <Button
            onClick={() => isInfoDialogOpen(false)}
            variant="contained"
            size="large"
            fullWidth
            className={classes.logoutBtn}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Button
   onClick={() => {
    LocalStorage.clear();
    window.location.href =
`${process.env.REACT_APP_IAM_ROUTE}/logout?from_url=${window.location.origin}`;
  }}            className={classes.buttonSuccess}
            variant="contained"
            size="large"
            fullWidth
          >
            Proceed
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default KycRestrictionDialog;
