import { createSelector } from 'reselect';
import { ONLY_KYC_REQUIRED_COINS } from '../../../../../../utils/constants/amounts';
import { AppState } from '../../../../../state/initialState';

const getProfileInfoState = ({
  home: {
    settings: { profileInfo },
  },
}: AppState) => profileInfo;

export const profileInfoStateSelector = createSelector(
  getProfileInfoState,
  profileInfo => profileInfo,
);

export const hasCompletedKYCSelector = createSelector(
  getProfileInfoState,
  profileInfo => profileInfo.account_info.kyc_status === 'completed',
);

export const isRestrictedCoinWithoutKYC = (symbol: string) => {
  return createSelector(
    getProfileInfoState,
    profileInfo =>
      ONLY_KYC_REQUIRED_COINS.includes(symbol) &&
      profileInfo.account_info.kyc_status !== 'completed',
  );
};
