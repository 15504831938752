/* eslint-disable */
import { createAsyncAction } from 'typesafe-actions';
import { Wallet } from '../../../../../../data/repositories/dashboard/typedefs';
import { DepositFiatRequest, FiatDepositState } from './initialState';

export const DEPOSIT_FIAT = 'deposit_fiat';
export const DEPOSIT_FIAT_SUCCESS = `${DEPOSIT_FIAT}/success`;
export const DEPOSIT_FIAT_FAIL = `${DEPOSIT_FIAT}/fail`;
export const DEPOSIT_FIAT_CLEAR_STATE = `${DEPOSIT_FIAT}/clear_state`;

export const asyncDepositFiat = createAsyncAction(
  DEPOSIT_FIAT,
  DEPOSIT_FIAT_SUCCESS,
  DEPOSIT_FIAT_FAIL,
  DEPOSIT_FIAT_CLEAR_STATE,
)<DepositFiatRequest, Wallet, Error>();
