import { connect } from 'react-redux';
import { AppState } from '../../../../../state/initialState';
import { asyncWireDepositEur } from '../../state/actions';
import {
  getSelectedCoinSelector,
  expensiveGetWalletForCurrency,
  depositModalStepSelector,
  depositModalStateSelector,
} from '../../state/selectors';

import DepositEurForm from './DepositEurForm';

const mapStateToProps = (state: AppState) => {
  const selectedCoin = getSelectedCoinSelector(state);
  const wallet = expensiveGetWalletForCurrency(selectedCoin)(state);
  const step = depositModalStepSelector(state);
  const depositModalState = depositModalStateSelector(state);
  return { wallet, step, depositModalState };
};

const mapDispatchToProps = {
  asyncWireDepositEur: asyncWireDepositEur.request,
};
export default connect(mapStateToProps, mapDispatchToProps)(DepositEurForm);
