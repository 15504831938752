import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  InputLabel,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import CustomButtonPrimary from '../../../../../components/CustomButtons/CustomButtonPrimary';
import SacretSelect from '../../../../../components/SacretSelect';
import { Colors } from '../../../../../ui/style/colors';
import { FeeSettingsState, StoreItem } from './state/initialState';
import { useDispatch, connect } from 'react-redux';
import { toNumber } from 'lodash';
import utils from '../../../../../utils/balanceUtils';
import {
  GET_STORE_ITEMS,
  UPDATE_FEE_RATE,
  UPDATE_FEE_RATE_CLEAR_STATE,
  asyncGetStoreItems,
  asyncUpdateFeeRate,
} from './state/actions';
import { AppState } from '../../../../state/initialState';
import { getFeeSettingsStateSelector } from './state/selectors';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    boxShadow: '0 10px 30px 0 rgba(132,141,182,0.25)',
    verticalAlign: 'sub',
    padding: 20,
  },
  inputFields: {
    marginTop: 5,
    height: 44,
    boxSizing: 'border-box',
    border: `1px solid ${theme.palette.secondary.light}`,
    borderRadius: 4,
    backgroundColor: `${Colors.white}`,
    paddingLeft: 12,
    width: '100%',
  },
  labels: {
    fontWeight: 'bold',
    color: theme.palette.primary.light,
    marginTop: 30,
    marginBottom: 8,
  },
  updateCurrencyLabel: {
    color: theme.palette.secondary.light,
    fontSize: 14,
    fontWeight: 800,
    letterSpacing: 1,
    textTransform: 'uppercase',
    marginBottom: 30,
  },
  button: {
    marginTop: 40,
    textTransform: 'none',
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 1,
    textAlign: 'center',
  },
  buttonSuccess: {
    backgroundColor: `${Colors.havelockBlue}`,
    marginTop: 40,
    '&:hover': {
      backgroundColor: `${Colors.havelockBlue}`,
    },
  },
  inputError: {
    color: `${Colors.red}`,
    marginTop: 10,
  },
}));

const FeeSettings = ({ inProgress, success, error, fail, storeItems }: FeeSettingsState) => {
  const classes = useStyles();
  const [selectedFee, setSelectedFee] = useState({
    name: 'ETH WALLET ADDRESS',
    value: 'ETHWALLET',
  });
  const dispatch = useDispatch();

  const selectedStoreItem: StoreItem = storeItems.find(item => item.code === selectedFee.value);

  const submit = (rate: number) => {
    const updatedStoreItem = {
      name: selectedStoreItem.name,
      description: selectedStoreItem.description,
      code: selectedStoreItem.code,
      price_in_eurocents: Number(utils.formatBalanceToBigWithDecimals(rate, -2)),
    };

    dispatch({
      type: UPDATE_FEE_RATE,
      payload: { storeItem: updatedStoreItem, id: selectedStoreItem.id },
    });
  };
  const initValues = { rate: '' };

  const selectedItem = storeItems.find(item => item.code === selectedFee.value);

  const eurValue =
    selectedItem &&
    Number(utils.formatBalanceToBigWithDecimals(selectedItem.price_in_eurocents, 2));

  const codeNames = (itemCode: string) => {
    switch (itemCode) {
      case 'ETHWALLET':
        return 'ETH WALLET ADDRESS';
      default:
        return itemCode;
    }
  };

  const dropdownItems = storeItems.map(item => ({ name: codeNames(item.code), value: item.code }));

  useEffect(() => {
    dispatch({ type: UPDATE_FEE_RATE_CLEAR_STATE });
    dispatch({ type: GET_STORE_ITEMS });
  }, []);

  return (
    <Card className={classes.card}>
      <CardContent>
        <Formik
          initialValues={initValues}
          onSubmit={(value, actions) => {
            actions.setSubmitting(false);
            submit(toNumber(value.rate));
          }}
          validationSchema={yup.object({
            rate: yup
              .number()
              .positive(`${selectedFee.name} - EUR must be a positive number!`)
              .required(`${selectedFee.name} - EUR rate is required`),
          })}
        >
          {({ isSubmitting, errors, touched }) => {
            return (
              <Form>
                <Typography className={classes.updateCurrencyLabel} component="p">
                  Change fee rate for:
                </Typography>

                <Grid xs={12} sm="auto" item>
                  <SacretSelect
                    multiple={false}
                    items={dropdownItems}
                    label={selectedFee.name}
                    onChange={(currency: string) =>
                      setSelectedFee({ name: codeNames(currency), value: `${currency}` })
                    }
                    stateFilterValue={selectedFee.value}
                  />
                </Grid>

                <InputLabel className={classes.labels}>
                  Current rate: 1 {selectedFee.name} = {eurValue} EUR
                </InputLabel>

                <InputLabel className={classes.labels}>New rate in EUR</InputLabel>
                <Field
                  id="slt_eur_rate"
                  type="number"
                  name="rate"
                  className={classes.inputFields}
                />

                {errors.rate && touched.rate ? (
                  <Box className={classes.inputError}>{errors.rate}</Box>
                ) : null}

                <Grid container alignItems="center" justifyContent="center">
                  {inProgress ? (
                    <CircularProgress />
                  ) : (
                    <CustomButtonPrimary
                      type="submit"
                      variant="contained"
                      size="large"
                      fullWidth
                      className={success ? classes.buttonSuccess : classes.button}
                      disabled={isSubmitting}
                    >
                      {success ? 'Succesfully changed!' : 'Save'}
                    </CustomButtonPrimary>
                  )}
                </Grid>
                {fail && (
                  <Box className={classes.inputError}>
                    {error ? error : 'Change currency rate failed'}
                  </Box>
                )}
              </Form>
            );
          }}
        </Formik>
      </CardContent>
    </Card>
  );
};

const mapDispatchToProps = {
  asyncGetStoreItems,
  asyncUpdateFeeRate,
};

const mapStateToProps = (state: AppState) => ({ ...getFeeSettingsStateSelector(state) });

export default connect(mapStateToProps, mapDispatchToProps)(FeeSettings);
