import {
  EXCHANGE_RATE_HISTORY,
  GET_CRYPTO_CHART_DATA,
  GET_CRYPTO_CHART_DATA_FALLBACK,
} from '../../../utils/constants/api';
import Http from '../../Http';

const getCryptoChartData = async (accountId: string, period: string) =>
  (await Http.get(GET_CRYPTO_CHART_DATA(accountId, period))).data;
const getCryptoChartDataFallback = async (accountId: string) =>
  (await Http.get(GET_CRYPTO_CHART_DATA_FALLBACK(accountId))).data;
const exchangeRateHistory = async (accountId: string, coinSymbol = 'SLC') =>
  (await Http.get(EXCHANGE_RATE_HISTORY(accountId, coinSymbol))).data;

export default {
  getCryptoChartData,
  getCryptoChartDataFallback,
  exchangeRateHistory,
};
