import { connect } from 'react-redux';
import { asyncGetReferralsStats } from '../../../../state/actions';
import { AppState } from '../../../../state/initialState';
import { affiliateTableStateSelector } from '../AffiliateTable/state/selectors';
import AffiliateInfo from './AffiliateInfo';
import { asyncGetAffiliateCode } from './state/actions';
import { affiliateInfoStateSelector } from './state/selectors';

const mapDispatchToProps = {
  asyncGetAffiliateCode: asyncGetAffiliateCode.request,
  asyncGetReferralsStats: asyncGetReferralsStats.request,
};

const mapStateToProps = (state: AppState) => {
  const affiliateTableState = affiliateTableStateSelector(state);
  const affiliateInfoState = affiliateInfoStateSelector(state);

  return { affiliateTableState, affiliateInfoState };
};

export default connect(mapStateToProps, mapDispatchToProps)(AffiliateInfo);
