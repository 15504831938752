import {
  CircularProgress,
  createStyles,
  InputAdornment,
  makeStyles,
  Theme,
} from '@material-ui/core';
import React from 'react';
import { ReactComponent as SLCIconSvg } from '../../assets/coins/sacretLifeCoin.svg';
import { Colors } from '../../ui/style/colors';
import LightTooltip from '../LightTooltip';
import SacretInput from '../SacretInput';

const styles = (theme: Theme) =>
  createStyles({
    input: {
      height: 1,
      fontSize: 12,
      '-webkit-inner-spin-button': '-webkit-appearance: none;',
      '-webkit-outer-spin-button': '-webkit-appearance: none',
    },
    root: {
      root: { lineHeight: '1.1875em' },
    },
    icon: {
      height: 20,
      fill: theme.palette.primary.light,
      width: 20,
      marginRight: 10,
    },
    iconPosition: {
      background: `${Colors.white}`,
      marginRight: 5,
      boxShadow: '0 10px 30px 0 rgba(132,141,182,0.25)',
    },
    inputWrapper: {
      boxShadow: '0 10px 30px 0 rgba(132,141,182,0.25)',
      width: 120,
    },
    tooltip: {
      left: 0,
      top: 0,
    },
  });
const useStyles = makeStyles(styles);

type Props = {
  onChange: (value: string, limit: string) => void;
  loading?: boolean;
  limit: string;
  stateValue: string[] | string | undefined;
  currency: string;
  showIcon?: boolean;
  disabled?: boolean;
  disabledTextExplanation?: string;
};

const SacretInputNumber: React.FC<Props> = ({
  onChange,
  loading = false,
  limit,
  stateValue = '',
  currency,
  showIcon = true,
  disabled = false,
  disabledTextExplanation,
}: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.inputWrapper}>
      <SacretInput
        placeholder={`${limit} ${currency}`}
        inputProps={{
          classes: { icon: classes.icon, input: classes.input },
        }}
        value={stateValue}
        type="number"
        disabled={disabled}
        disableUnderline={true}
        onChange={e => onChange(e.target.value, limit)}
        endAdornment={
          <InputAdornment position="end" className={classes.iconPosition}>
            {loading ? (
              <CircularProgress color="secondary" size={12} />
            ) : (
              <React.Fragment>
                {!disabled && showIcon ? <SLCIconSvg className={classes.icon} /> : null}

                {disabled && (
                  <LightTooltip
                    className={classes.tooltip}
                    title={disabledTextExplanation || 'Action not allowed.'}
                  />
                )}
              </React.Fragment>
            )}
          </InputAdornment>
        }
      />
    </div>
  );
};

export default SacretInputNumber;
