import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { createTheme } from '@material-ui/core/styles';
import { Colors } from '../ui/style/colors';
const breakpoints = createBreakpoints({});

export default createTheme({
  palette: {
    primary: {
      main: `${Colors.havelockBlue}`,
      light: `${Colors.wildBlueYonder}`,
    },
    secondary: {
      main: `${Colors.mediumTurquoise}`,
      light: `${Colors.blueHaze}`,
    },
  },
  typography: {
    fontFamily: ['"Manrope3"', 'Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    fontSize: 14,
  },
  overrides: {
    MuiTablePagination: {
      caption: {
        [breakpoints.down('sm')]: {
          margin: '10px auto',
        },
      },
    },
    DateRangePicker__Month: {
      width: '100%',
      margin: 0,
    },
    MuiDialog: {
      paper: {
        [breakpoints.down('sm')]: {
          margin: 0,
        },
      },
    },
  },
});
