import { createStyles, Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { Colors } from '../../../../../ui/style/colors';
import WalletInfoTotalBalance from './WalletInfoTotalBalance';

const styles = () =>
  createStyles({
    walletPaper: {
      borderRadius: '3px',
      backgroundColor: `${Colors.white}`,
      boxShadow: '0 10px 30px 0 rgba(132,141,182,0.25)',
      padding: 20,
      margin: '12px 6px 12px 12px',
      '@media(max-width:960px)': {
        margin: 0,
      },
    },
  });

const useStyles = makeStyles(styles);

type Props = { className?: string };

const WalletInfo: React.FC<Props> = ({ className }): JSX.Element => {
  const classes = useStyles();

  return (
    <Paper className={`${classes.walletPaper} ${className}`}>
      <Grid container>
        <WalletInfoTotalBalance />
      </Grid>
    </Paper>
  );
};

export default WalletInfo;
