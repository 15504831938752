import { Grid } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { ReactComponent as SuccessIconSvg } from '../../../../../../assets/success@1.5x.svg';
import { Colors } from '../../../../../../ui/style/colors';
import balanceUtils from '../../../../../../utils/balanceUtils';
import {
  WIRE_EUR_DECIMALS,
  WIRE_EUR_MAX_FRACTION,
  WIRE_EUR_MIN_FRACTION,
  WIRE_EUR_TRANSACTION_FEE,
} from '../../../../../../utils/constants/amounts';
import { DepositModalState } from '../../state/initialState';

const styles = () =>
  createStyles({
    text: {
      fontSize: 14,
      color: `${Colors.wildBlueYonder}`,
      fontFamily: 'Manrope3',
      fontWeight: 600,
      textAlign: 'left',
      margin: '10px 0',
      '& span': {
        color: `${Colors.blueCharcoal}`,
        fontWeight: 600,
      },
    },
    successIcon: {
      margin: '30px auto',
    },
    content: {
      paddingButton: 25,
    },
    heading: {
      textAlign: 'center',
      fontSize: '16px',
      marginBottom: 30,
    },
  });
const useStyles = makeStyles(styles);

type Props = {
  depositModalState: DepositModalState;
};

const DepositSuccess = ({ depositModalState: { depositOrder } }: Props): JSX.Element => {
  const classes = useStyles();
  const { order } = depositOrder;
  const amount = Number(
    balanceUtils
      .formatBalanceToString(
        (order && order.price) || 0,
        WIRE_EUR_DECIMALS,
        WIRE_EUR_MIN_FRACTION,
        WIRE_EUR_MAX_FRACTION,
      )
      .replace(/,/g, '')
      .trim(),
  );

  return (
    <Grid container justifyContent="center" direction="column">
      <SuccessIconSvg className={classes.successIcon} />

      <Grid item className={classes.content}>
        <p className={`${classes.text} ${classes.heading}`}>
          Your order to deposit {Number(amount) - WIRE_EUR_TRANSACTION_FEE} EUR has been registered.
        </p>
        <p className={classes.text}>
          By paying {Number(amount)} EUR ({WIRE_EUR_TRANSACTION_FEE} EUR fee included) using the
          following instructions:
        </p>
        <p className={classes.text}>Beneficiary: SL ENTERPRISES EOOD</p>
        <p className={classes.text}>IBAN: LT41 3250 0642 1959 1672</p>
        <p className={classes.text}> BIC: REVOLT21</p>
        <p className={classes.text}>
          Beneficiary address: Opalchenska 46-48, ET 7, Sofia, 1303, Bulgaria
        </p>
        <p className={classes.text}>
          Bank/Payment institution: Revolut with reference to the number{' '}
          <span>{order && order.order_proof}</span> you will be entitled to use the goods or
          services purchased Payment is required to be registered within <span>120 hours</span> of
          the purchase, otherwise the registration will be canceled.
        </p>
        <p className={classes.text}>
          <span>Note: these instructions have also been sent to your e-mail address.</span>
        </p>
      </Grid>
    </Grid>
  );
};

export default DepositSuccess;
