import { connect } from 'react-redux';
import { AppState } from '../../../../../state/initialState';
import {
  clearCommonState,
  saveWithdrawAddress,
  saveWithdrawAmount,
  setSelectedCoin,
} from '../../../../state/common/actions';
import { getWithdrawAddress, getWithdrawAmount } from '../../../../state/common/selectors';
import { openWithdrawDialog } from '../../state/actions';
import {
  getAllCoinsSelector,
  getSelectedCoinSelector,
  getWalletBySymbolSelectorSelector,
} from '../../state/selectors';
import WithdrawCoinsDialog from './WithdrawCoinsDialog';
import { membershipStateSelector } from '../../../Exchange/state/selectors';
import { hasCompletedKYCSelector } from '../../../Settings/state/selectors';

const mapDispatchToProps = {
  setSelectedCoin: setSelectedCoin,
  saveWithdrawAddress: saveWithdrawAddress,
  saveWithdrawAmount: saveWithdrawAmount,
  openWithdrawDialog: openWithdrawDialog,
  clearCommonState: clearCommonState,
};

const mapStateToProps = (state: AppState) => {
  const selectedCoin = getSelectedCoinSelector(state);
  const wallet = getWalletBySymbolSelectorSelector(state);
  const allCoins = getAllCoinsSelector(state);
  const withdrawAddress = getWithdrawAddress(state);
  const withdrawAmount = getWithdrawAmount(state);
  const membershipLevel = membershipStateSelector(state);
  const hasCompletedKYC = hasCompletedKYCSelector(state);

  return {
    allCoins,
    selectedCoin,
    wallet,
    withdrawAddress,
    withdrawAmount,
    membershipLevel,
    hasCompletedKYC,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawCoinsDialog);
