import { expirationMonth, number } from 'card-validator';

export const composeValidators =
  (...args: Array<(value: any) => string | undefined>) =>
  (value: any) => {
    for (const validator of args) {
      const error = validator(value);
      if (error) {
        return error;
      }
    }

    return undefined;
  };

export const requiredValidator = (value?: string | number) => {
  return value || value === 0 ? undefined : 'Required field';
};

export const cardValidatorAll = (value: any) => {
  return number(value);
};

export const monthCardValidator = (value = '', year = new Date().getFullYear().toString()) => {
  const currYear = new Date().getFullYear().toString();

  if (year === currYear) {
    return expirationMonth(value).isValidForThisYear ? undefined : 'Card expired!';
  }

  if (year < currYear) {
    return 'Card expired!';
  }

  return expirationMonth(value).isValid ? undefined : 'Card expired!';
};

export const cardValidator = (value?: string) => {
  return number(value).isValid ? undefined : 'Card expired!';
};

export const numberValidator = (value: any) => {
  if (value === undefined || value === null) {
    return undefined;
  }

  return value && isNaN(value) ? 'Must be a number' : undefined;
};

export const codeLenValidator = (value: string, numDig = 11) => {
  return value && value.length !== numDig ? `Must have ${numDig} characters` : undefined;
};

export const minLenValidator = (value: string, numDig = 6) => {
  return value && value.length < numDig ? `Minimum ${numDig} characters` : undefined;
};

export const maxLenValidator = (value: string, numDig = 6) => {
  return value && value.length > numDig ? `Maximum ${numDig} characters` : undefined;
};

export const lessThanValidator = (value: any, maxVal: number) => {
  return parseFloat(value) > maxVal ? `Must be less then ${maxVal}` : undefined;
};

export const greaterThanZero = (value: number, greaterValue = 0) => {
  return +value <= greaterValue ? `Must be greater then ${greaterValue}` : undefined;
};

export const greaterOrEqual = (value: number, greaterValue = 0) => {
  return +value < greaterValue ? `Must be greater then ${greaterValue}` : undefined;
};

export const lessOrEqual = (value: number, maxVal: number) => {
  return +value > maxVal ? `Must be less then ${maxVal}` : undefined;
};

export const lettersOnlyValidator = (value: any) => {
  return value && !/^[a-zA-ZšŠđĐžŽčČćĆ\s]*$/.test(value) ? 'Only letters allowed' : undefined;
};

export const allowDecimalNumbersValidator = (value: string) => {
  return value && !/^\d*(\.\d{0,2})?$/.test(value)
    ? `Only numbers with 2 decimals allowed`
    : undefined;
};

export const integerOnlyValidator = (value: string) => {
  return value && !/^[0-9]*$/.test(value) ? `Only numbers allowed` : undefined;
};

export const emailValidator = (value: string) => {
  return value && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)
    ? 'Email is not valid'
    : undefined;
};
