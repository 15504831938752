import { createSelector } from 'reselect';
import { AppState } from '../../../../../state/initialState';

const getChangeUserPasswordState = ({
  home: {
    admin: { changeUserPassword },
  },
}: AppState) => changeUserPassword;

export const changeUserPasswordStateSelector = createSelector(
  getChangeUserPasswordState,
  changeUserPassword => changeUserPassword,
);

export const getSelectedUserStateSelector = createSelector(
  getChangeUserPasswordState,
  changeUserPassword => changeUserPassword.selectedUser,
);
