import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    colorGreen: {
      color: theme.palette.success.light,
    },
    colorRed: {
      color: theme.palette.error.light,
    },
    amount: {
      // fontWeight: 600,
    },
    currency: {
      marginLeft: 5,
    },
  }),
);
type Props = {
  amount: string | number;
  currency?: string;
  condition: boolean;
  showOperator?: boolean;
};
const Amount = ({ amount, currency, condition, showOperator = false }: Props) => {
  const classes = useStyles();
  const getClassName = condition ? classes.colorGreen : classes.colorRed;

  return (
    <>
      {showOperator && <span className={getClassName}>{condition ? '+' : '-'} </span>}
      <span className={`${getClassName} ${classes.amount}`}>{amount}</span>
      {currency && <span className={classes.currency}>{currency}</span>}
    </>
  );
};

export default Amount;
