import { CircularProgress, Paper, TablePagination } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { size } from 'lodash';
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { PayloadAC } from 'typesafe-actions';
import AffiliateAddDeductDialog from '../../App/Home/HomeContent/Affiliate/AffiliateAddDeductDialog';
import AffiliateChangeSlcLimitModal from '../../App/Home/HomeContent/Affiliate/AffiliateChangeSlcLimitDialog';
import AffiliateFullProfileDialog from '../../App/Home/HomeContent/Affiliate/AffiliateFullProfileDialog';
import {
  ChangeUserStatusRequest,
  referralUser,
} from '../../App/Home/HomeContent/Affiliate/AffiliateTable/state/initialState';
import { RESET_USER_KYC_STATUS } from '../../App/state/actions';
import { AppState } from '../../App/state/initialState';
import { userStateSelector } from '../../App/state/selectors';
import { Referral } from '../../data/repositories/affiliate/typedefs';
import { User } from '../../data/typedefs/user';
import { Colors } from '../../ui/style/colors';
import { roles } from '../../utils/constants/roles';
import TablePaginationActions from '../TablePaginationActions';
import AffiliatesTableRow from './AffiliatesTableRow';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      overflowY: 'auto',
      boxShadow: '0 10px 30px 0 rgba(132,141,182,0.25)',
      backgroundColor: `${Colors.white}`,
      borderRadius: '3px',
    },
    table: {
      minWidth: 650,
      borderCollapse: 'collapse',
      borderRadius: 6,
    },
    head: {
      backgroundColor: 'rgba(242, 243, 247, 0.7)',
    },
    headerText: {
      color: theme.palette.primary.light,
      fontSize: 12,
      fontWeight: 600,
      letterSpacing: 0.86,
    },
    headerButton: {
      textAlign: 'right',
      padding: '0 !important',
      width: '10%',
    },
    claimReward: {
      borderRadius: ' 0 8px 0 8px',
      backgroundColor: `${Colors.mediumTurquoise}`,
    },
    button: {
      borderRadius: ' 0 8px 0 8px',
      fontSize: 14,
      fontWeight: 600,
      textTransform: 'none',
      height: 47,
    },
    pagination: { flexWrap: 'wrap', justifyContent: 'space-between', borderRadius: 5 },
    spacer: { flex: '0 0 100%' },
    widgetPagination: { marginRight: 0 },
  }),
);

export type HeaderProps = {
  name: string;
  align?: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined;
};

type Props = {
  referrals: Map<string, Referral>;
  headers: HeaderProps[];
  totalReferrals: number;
  page: number;
  inProgress: boolean;
  referralTree: any;
  hasPagination: boolean;
  isAddDeductDialogOpen: PayloadAC<'affiliate/is_add_deduct_dialog_open', boolean>;
  isFullProfileDialogOpen: PayloadAC<'affiliate/is_full_profile_dialog_open', boolean>;
  asyncChangeUserStatus: PayloadAC<'affiliate/change_user_status', ChangeUserStatusRequest>;
  asyncToggleAdminRole: PayloadAC<'affiliate/toggle_admin_role', boolean>;
  setUserForDialog: PayloadAC<'affiliate/set_user_for_dialog', referralUser>;
  asyncResetUserKYCStatus: PayloadAC<typeof RESET_USER_KYC_STATUS, string>;
  user: User;
  referralDetail: any;
  appliedFilters: any;
  referralDetailInProgress: boolean;
  isAdminSearchWidget?: boolean;
};

const AffiliatesTable = ({
  referrals,
  headers,
  page,
  totalReferrals,
  inProgress,
  isAddDeductDialogOpen,
  isFullProfileDialogOpen,
  setUserForDialog,
  asyncChangeUserStatus,
  asyncToggleAdminRole,
  asyncResetUserKYCStatus,
  user,
  appliedFilters,
  referralDetail,
  referralDetailInProgress,
  isAdminSearchWidget,
}: Props) => {
  const classes = useStyles();
  const dispath = useDispatch();

  const handleChangePage = (event: unknown, newPage: number) => {
    const object: any = {};
    appliedFilters.forEach((value: any, key: React.ReactText) => {
      object[key] = value;
    });
    dispath({ type: 'affiliate/set_table_page', payload: newPage });
    dispath({
      type: 'affiliate/get_referrals_paginated',
      payload: {
        page: newPage,
        perPage: 10,
        appliedFilters: {
          ...object,
        },
      },
    });
  };

  const [expanded, setExpanded] = React.useState(-1);

  useEffect(() => {
    if (size(referrals) === 1) {
      const referral = Object.values(referrals)[0];
      setExpanded(0);
      dispath({
        type: 'affiliate/get_detail',
        payload: { id: referral.id || referral.referral_id, email: referral.email },
      });
    } else setExpanded(-1);
  }, [referrals]);

  const rowsPerPage = 10;
  const emptyRows = rowsPerPage - Object.keys(referrals).length;

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead className={classes.head}>
          <TableRow>
            {headers.map(header => {
              if (header.name !== 'Options' || roles.isOwner(user.roles))
                return (
                  <TableCell
                    className={classes.headerText}
                    key={header.name}
                    align={header.align}
                    component={'th'}
                  >
                    {header.name}
                  </TableCell>
                );
            })}
          </TableRow>
        </TableHead>
        {inProgress ? (
          <TableBody style={{ minHeight: 530 }}>
            <TableRow>
              <TableCell colSpan={6} style={{ textAlign: 'center' }}>
                <CircularProgress color="secondary" size={60} />
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <TableBody style={{ minHeight: 530 }}>
            {Object.entries(referrals).map(([key, value], index) => (
              <AffiliatesTableRow
                isAdminSearchWidget={isAdminSearchWidget}
                key={key}
                referral={value}
                expanded={expanded === index}
                setExpanded={(id: string, email: string) => {
                  setExpanded(prev => (prev === index ? -1 : index));
                  dispath({ type: 'affiliate/get_detail', payload: { id, email } });
                }}
                referralDetailInProgress={referralDetailInProgress}
                referralDetail={referralDetail}
                isAddDeductDialogOpen={isAddDeductDialogOpen}
                isFullProfileDialogOpen={isFullProfileDialogOpen}
                setUserForDialog={setUserForDialog}
                asyncChangeUserStatus={asyncChangeUserStatus}
                asyncResetUserKYCStatus={asyncResetUserKYCStatus}
                asyncToggleAdminRole={asyncToggleAdminRole}
              />
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        )}
      </Table>
      <AffiliateAddDeductDialog />
      <AffiliateFullProfileDialog />
      <AffiliateChangeSlcLimitModal />

      <TablePagination
        component="div"
        count={totalReferrals}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[]}
        page={page}
        labelDisplayedRows={({ from, to, count }: { from: number; to: number; count: number }) => {
          return `Showing ${from} to ${to} of ${count}`;
        }}
        backIconButtonProps={{
          'aria-label': 'previous page',
        }}
        nextIconButtonProps={{
          'aria-label': 'next page',
        }}
        onPageChange={handleChangePage}
        ActionsComponent={subProps => (
          <TablePaginationActions {...subProps} className={classes.widgetPagination} />
        )}
        classes={{
          toolbar: classes.pagination,
          spacer: classes.spacer,
        }}
      />
    </Paper>
  );
};

const mapStateToProps = (state: AppState) => {
  const user = userStateSelector(state);
  return { user };
};

export default connect(mapStateToProps, null)(AffiliatesTable);
