import { all, put, select, takeEvery } from 'redux-saga/effects';
import LocalStorage from '../../../../../../data/LocalStorage';
import api from '../../../../../../data/repositories/product';
import { WALLET_COINS } from '../../../../../../utils/constants/amounts';
import { INVEST_PRODUCT } from '../../../../../../utils/constants/api';
import { USER_ID } from '../../../../../../utils/constants/localStorageKeys';
import { getCurrencySymbolName } from '../../../../../../utils/helper/utils';
import { isTwoFactorAuthEnabled } from '../../../Settings/SettingsTwoFactorAuth/state/actions';
import { getCoinDecimalsSelector } from '../../../Wallet/state/selectors';
import { asyncGetProductsPaginated } from '../../state/actions';
import {
  investCurrencySaveState,
  INVEST_CURRENCY_SAVE_STATE,
  productInvest,
  PRODUCT_INVEST,
} from './actions';
import { ProductCurrencyState } from './initialState';
import { getInvestCurrencySelector } from './selectors';

export function* productInvest$(action: ReturnType<typeof productInvest.request>) {
  try {
    const userID = LocalStorage.get(USER_ID);
    const response = yield api.investProduct(action.payload, INVEST_PRODUCT(userID ? userID : ''));
    yield put(productInvest.success(response));
    yield put(
      asyncGetProductsPaginated.request({
        page: 0,
        perPage: 10,
      }),
    );
  } catch ({ response }) {
    yield put(
      productInvest.failure(
        response.data && response.data.error
          ? response.data.error
          : 'Something went wrong! Please try again later.',
      ),
    );
  }
}

//TODO
export function* exchangeSaveState$(action: ReturnType<typeof investCurrencySaveState>) {
  const {
    selectedOption,
    time,
    get,
    amount,
    two_factor,
    membershipState: { eur_price, product_id },
  }: ProductCurrencyState = yield select(getInvestCurrencySelector);

  const getProductInvestData = (decimals: any) => ({
    order_type: 'crowdfunding',
    '2fa': two_factor,
    product_id,
    eur_price: Math.round(+(amount || 1) * eur_price),
    price_in_currency: Math.round(+(get || 0) * Math.pow(10, decimals)),
    dialog_popup_time: time,
    slc_amount: Math.round(+(amount || 0) * 10000000),
    payment_type: 'other',
    symbol: getCurrencySymbolName(selectedOption).toLowerCase(),
  });
  if (action.payload.step)
    switch (action.payload.step) {
      case 1:
        break;
      case 2:
        if (selectedOption !== 'WIRE_EUR') yield put(isTwoFactorAuthEnabled());
        else yield put(investCurrencySaveState({ step: 3 }));
        break;
      case 3: {
        let data;
        if (WALLET_COINS.includes(selectedOption)) {
          const decimals: ReturnType<typeof getCoinDecimalsSelector> = yield select(
            getCoinDecimalsSelector(selectedOption),
          );
          data = getProductInvestData(decimals);
        } else {
          data = {
            order_type: 'crowdfunding',
            product_id,
            eur_price: Math.round(+(amount || 0) * 100),
            payment_type: 'wire_transfer',
            slc_amount: Math.round(+(get || 0) * 10000000),
          };
        }

        yield put(productInvest.request(data));
        break;
      }
      case 3:
        break;
      default:
        break;
    }
}

export default function* () {
  yield all([
    takeEvery(INVEST_CURRENCY_SAVE_STATE, exchangeSaveState$),
    takeEvery(PRODUCT_INVEST, productInvest$),
  ]);
}
