import { createSelector } from 'reselect';
import { AppState } from '../../../../../state/initialState';
import { isEmpty } from 'lodash';

const getAffiliateMerchantsTableState = ({
  home: {
    orders: { affiliateMerchantsTable },
  },
}: AppState) => affiliateMerchantsTable;

export const affiliateMerchantsTableStateSelector = createSelector(
  getAffiliateMerchantsTableState,
  affiliateMerchantsTable => affiliateMerchantsTable,
);

export const getAffiliateMerchantsTableReferralsSelector = createSelector(
  affiliateMerchantsTableStateSelector,
  affiliateMerchantsTable => affiliateMerchantsTable.referrals,
);

export const getAffiliateMerchantsTableReferralsStatsSelector = createSelector(
  affiliateMerchantsTableStateSelector,
  affiliateMerchantsTable => affiliateMerchantsTable.stats,
);

export const getMerchantsCyclesSelector = createSelector(
  affiliateMerchantsTableStateSelector,
  affiliateMerchantsTable => affiliateMerchantsTable.cycles,
);
export const getAppliedFiltersState = createSelector(
  affiliateMerchantsTableStateSelector,
  affiliateMerchantsTable => affiliateMerchantsTable.appliedFilters,
);
// const getAppliedFiltersState = ({ getAffiliateMerchantsTableState }: AppState) =>
//   value.appliedFilters;
const getAllAppliedFiltersSelector = createSelector(
  getAppliedFiltersState,
  appliedFilters => appliedFilters,
);

const getAppliedFiltersIds = createSelector(getAllAppliedFiltersSelector, appliedFilters =>
  Object.keys(appliedFilters),
);

export const getAppliedFiltersSelector = createSelector(
  getAllAppliedFiltersSelector,
  getAppliedFiltersIds,
  (appliedFilters, appliedFiltersIds) => {
    const af = new Map<string, string[]>(Object.entries(appliedFilters));
    const appliedFiltersNonEmpty = new Map<string, string[]>();
    for (const index in appliedFiltersIds) {
      const filter = af.get(appliedFiltersIds[index]);
      if (!isEmpty(filter) && filter) {
        appliedFiltersNonEmpty.set(appliedFiltersIds[index], filter);
      }
    }

    return appliedFiltersNonEmpty;
  },
);

export const getAreAnyFiltersApplied = createSelector(
  getAppliedFiltersState,
  getAppliedFiltersIds,
  (appliedFilters, appliedFiltersIds) => {
    let applied = false;
    for (const index in appliedFiltersIds) {
      const af = new Map<string, string[]>(Object.entries(appliedFilters));
      applied = applied || !isEmpty(af.get(appliedFiltersIds[index]));
    }
    return applied;
  },
);

export const getAppliedFiltersIdsSelector = createSelector(
  getAppliedFiltersIds,
  appliedFiltersIds => appliedFiltersIds,
);
