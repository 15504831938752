import { CircularProgress, Divider, Grid, TextField, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Big from 'big.js';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { EmptyAC, PayloadAC } from 'typesafe-actions';
import * as yup from 'yup';
import CustomButtonPrimary from '../../../../../../components/CustomButtons/CustomButtonPrimary';
import SelectComponent from '../../../../../../components/SelectComponent';
import { Wallet } from '../../../../../../data/repositories/wallet/typedefs';
import { Colors } from '../../../../../../ui/style/colors';
import balanceUtils from '../../../../../../utils/balanceUtils';
import {
  allCountries,
  countries,
  optionalIbanCountries,
} from '../../../../../../utils/constants/countries';
import { sortArrayAlphabetically } from '../../../../../../utils/helper/utils';
import {
  EURWithdrawAddressType,
  EURWithdrawInitialState,
} from '../../../../state/common/initialState';
import { ProfileInfoState } from '../../../Settings/Membership/state/initialState';
import { GET_REVOLUT_COUNTERPARTY } from '../../state/actions';
import { GetRevolutCounterparty } from '../../state/initialState';

const styles = (theme: Theme) =>
  createStyles({
    formWrapper: {
      marginTop: 15,
    },
    input: {
      margin: '10px 0',
      width: '100%',
      '&::placeholder': {
        color: `${Colors.blueHaze}`,
      },
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'none',
      },
      '&[type=number]': {
        '-webkit-appearance': 'textfield',
        '-moz-appearance': 'textfield',
      },
    },
    inputRoot: {
      height: 50,
    },
    currencyLabel: {
      color: theme.palette.primary.light,
      fontSize: 12,
      fontWeight: 600,
      margin: '10px 0',
    },
    button: {
      fontSize: 14,
      margin: '10px 0 20px 0',
      textTransform: 'none',
    },
    inputSymbol: {
      width: 51,
      height: 50,
      borderRadius: '0 4px 4px 0',
      backgroundColor: `${theme.palette.secondary.light}`,
      color: `${Colors.white}`,
      fontSize: 12,
      fontWeight: 600,
    },
    inputSymbolValue: {
      position: 'relative',
      float: 'left',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    value: {
      color: `${Colors.blueCharcoal}`,
      textAlign: 'center',
      marginBottom: 10,
      fontSize: 14,
    },
    currencyBalance: {
      fontSize: 12,
      fontWeight: 600,
      marginLeft: 3,
    },
    noMargin: {
      margin: 0,
    },
    inputError: {
      color: `${Colors.red}`,
      marginTop: 10,
    },
    loaderWrapper: {
      width: 400,
      height: 200,
      '@media(max-width:500px)': {
        width: '100%',
      },
    },
    divider: {
      margin: '15px 0',
    },
    helperText: {
      margin: 5,
      fontSize: 12,
      color: 'gray',
    },
  });
const useStyles = makeStyles(styles);

type Country = {
  code: string;
  name: string;
  iban?: boolean;
  bic?: boolean;
  sort_code?: boolean;
  routing_number?: boolean;
  clabe?: boolean;
  ifsc?: boolean;
  bsb_code?: boolean;
};

type Props = {
  wallet?: Wallet;
  selectedCoin: string;
  saveWithdrawAddress: PayloadAC<'wallet/save_withdraw_address', string | EURWithdrawAddressType>;
  saveWithdrawAmount: PayloadAC<'wallet/save_withdraw_amount', string>;
  openWithdrawDialog: PayloadAC<'wallet/open_withdraw_dialog', number>;
  clearCommonState: EmptyAC<'wallet/clear_common_state'>;
  withdrawAmount: string;
  asyncGetRevolutCounterparty: EmptyAC<typeof GET_REVOLUT_COUNTERPARTY>;
  withdrawAddress: string | EURWithdrawAddressType;
  getRevolutCounterpartyState: GetRevolutCounterparty;
  profileInfo?: ProfileInfoState;
};

export const WithdrawEURDialogContent = ({
  wallet,
  saveWithdrawAmount,
  openWithdrawDialog,
  withdrawAmount,
  saveWithdrawAddress,
  asyncGetRevolutCounterparty,
  withdrawAddress,
  getRevolutCounterpartyState,
  profileInfo,
}: Props) => {
  const classes = useStyles();

  const [state, setState] = useState<any>(EURWithdrawInitialState);
  const [selectedBankCountry, setSelectedBankCountry] = useState<Country | null>(null);

  useEffect(() => {
    asyncGetRevolutCounterparty();
  }, []);

  useEffect(() => {
    let initialData = {};

    if (getRevolutCounterpartyState.success) {
      if (typeof withdrawAddress !== 'string' && withdrawAddress.account_no !== state.account_no) {
        initialData = {
          ...EURWithdrawInitialState,
          ...withdrawAddress,
        };

        const bankCountry = countries.find(
          (item: Country) => item.code === withdrawAddress.bank_country,
        );
        setSelectedBankCountry(bankCountry ? bankCountry : null);
      }
    }

    if (profileInfo && profileInfo.kyc_info.phone_number[0] === '+') {
      initialData = { ...initialData, phone: profileInfo.kyc_info.phone_number.replace(/\s/g, '') };
    }

    setState({ ...state, ...initialData });
  }, [getRevolutCounterpartyState.success, profileInfo]);

  const handleInputChange = (inputValue: string) => {
    saveWithdrawAmount(inputValue);
  };

  const walletBalanceBig = Big(
    wallet
      ? wallet.balance
        ? balanceUtils.formatBalanceToBigWithDecimals(wallet.balance, wallet.decimals)
        : '0'
      : '0',
  );
  const walletBalanceString = balanceUtils.formatBigToStringWithCommasAndRound(
    walletBalanceBig,
    2,
    4,
  );
  const amountToWithdrawBig = Big(withdrawAmount !== '' ? withdrawAmount : '0');
  const isWithdrawAllowed =
    amountToWithdrawBig.gt(0) && Big(Number(walletBalanceString) - 5).gte(amountToWithdrawBig);
  const inputProps = {
    classes: {
      root: classes.inputRoot,
      input: classes.input,
    },
  };

  //Remove countries without billing data
  const availableCountries = sortArrayAlphabetically(countries).filter(
    (i: Country) =>
      i.iban || i.bic || i.clabe || i.routing_number || i.sort_code || i.ifsc || i.bsb_code,
  );

  return getRevolutCounterpartyState.inProgress ? (
    <Grid container justifyContent="center" alignItems="center" className={classes.loaderWrapper}>
      <CircularProgress color="secondary" size={60} />
    </Grid>
  ) : (
    <Formik
      enableReinitialize
      initialValues={state}
      onSubmit={(values, actions) => {
        actions.setSubmitting(false);
      }}
      validationSchema={yup.object({
        bank_country: yup.string().required('Bank country is required'),
        phone: yup
          .string()
          .matches(/^[+]/, 'Phone must start with valid country code')
          .matches(/^\S*$/, 'Whitespace is not allowed')
          .matches(
            /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
            'Invalid phone number',
          )
          .trim()
          .required('Phone is required'),
        email: yup
          .string()
          .required('Email is required')
          .matches(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            'Email is not valid',
          )
          .trim(),
        account_no: yup
          .string()
          .required('Account number is required')
          .matches(/^\S*$/, 'Whitespace is not allowed')
          .matches(/^[a-zA-Z0-9-]*$/, 'The entered symbol is not allowed')
          .matches(
            /^[a-zA-Z0-9](.*[a-zA-Z0-9])?$/,
            'Account number must start and finish with valid code',
          )
          .matches(
            /^(?!.*[!@#$%^&*()\-_+={}[\]|\\;:'",<.>\/?]{2}).+$/,
            'Account number is not valid',
          )
          .min(4, 'Account number can have minimum 4 characters')
          .max(28, 'Account number can have maximum 28 characters')
          .trim(),
        street_line1: yup.string().required('Street, house number are required').trim(),
        street_line2: yup.string().required('Apartment, building are required').trim(),
        region: yup.string().required('Region is required').trim(),
        postcode: yup
          .string()
          .required('Postcode is required')
          .matches(/^[a-zA-Z0-9][a-zA-Z0-9\- ]{0,10}[a-zA-Z0-9]$/, 'Invalid postcode')
          .matches(/^[a-zA-Z0-9]+(?:[-\s][a-zA-Z0-9]+)?$/, 'Single space or dash is allowed'),
        city: yup.string().required('City is required').trim(),
        country: yup.string().required('Country is required'),
        iban: yup.string().when('bank_country', {
          is: () => {
            if (selectedBankCountry) {
              return selectedBankCountry.iban;
            }
            return false;
          },
          then: yup.string().required('IBAN is required'),
        }),
        bic: yup
          .string()
          .when('bank_country', {
            is: () => selectedBankCountry && selectedBankCountry.bic,
            then: yup.string().required('BIC is required'),
          })
          .matches(
            /^([A-Z]{6}[A-Z2-9][A-NP-Z1-9])(X{3}|[A-WY-Z0-9][A-Z0-9]{2})?$/,
            'Invalid bic/swift code',
          ),
        sort_code: yup.string().when('bank_country', {
          is: () => selectedBankCountry && selectedBankCountry.sort_code,
          then: yup.string().required('Sort code is required'),
        }),
        routing_number: yup.string().when('bank_country', {
          is: () => selectedBankCountry && selectedBankCountry.routing_number,
          then: yup.string().required('Routing number is required'),
        }),
        clabe: yup.string().when('bank_country', {
          is: () => selectedBankCountry && selectedBankCountry.clabe,
          then: yup.string().required('Clabe is required'),
        }),
        ifsc: yup.string().when('bank_country', {
          is: () => selectedBankCountry && selectedBankCountry.ifsc,
          then: yup.string().required('IFSC is required'),
        }),
        bsb_code: yup.string().when('bank_country', {
          is: () => selectedBankCountry && selectedBankCountry.bsb_code,
          then: yup.string().required('BSB code is required'),
        }),
      })}
    >
      {({ errors, touched, setFieldValue, values, handleBlur, isValid }) => {
        return (
          <>
            <Form id="WithdrawEUR">
              <Grid container direction="column" className={classes.formWrapper}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="name"
                      name="first_name"
                      variant="outlined"
                      fullWidth
                      className={classes.input}
                      placeholder="First Name"
                      InputProps={inputProps}
                      disabled
                      value={profileInfo && profileInfo.kyc_info.first_name}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="name"
                      name="last_name"
                      variant="outlined"
                      fullWidth
                      className={classes.input}
                      placeholder="Last Name"
                      InputProps={inputProps}
                      disabled
                      value={profileInfo && profileInfo.kyc_info.last_name}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="email"
                      name="email"
                      variant="outlined"
                      fullWidth
                      className={classes.input}
                      placeholder="Email"
                      InputProps={inputProps}
                      onBlur={handleBlur}
                      value={values.email}
                      onChange={ev => setFieldValue('email', ev.target.value)}
                    />
                    {errors.email && touched.email && (
                      <div className={classes.inputError}>{errors.email}</div>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="phone"
                      name="phone"
                      variant="outlined"
                      fullWidth
                      className={classes.input}
                      placeholder="Phone number"
                      InputProps={inputProps}
                      onBlur={handleBlur}
                      onChange={ev => setFieldValue('phone', ev.target.value)}
                      value={values.phone}
                    />
                    {errors.phone && touched.phone && (
                      <div className={classes.inputError}>{errors.phone}</div>
                    )}
                  </Grid>
                </Grid>
                <TextField
                  id="account_no"
                  name="account_no"
                  variant="outlined"
                  fullWidth
                  className={classes.input}
                  placeholder="Account number"
                  InputProps={inputProps}
                  onBlur={handleBlur}
                  onChange={ev => setFieldValue('account_no', ev.target.value)}
                  value={values.account_no}
                />
                {errors.account_no && touched.account_no && (
                  <div className={classes.inputError}>{errors.account_no}</div>
                )}

                {/**Address fields */}
                <Divider className={classes.divider} />
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="street_line1"
                      name="street_line1"
                      variant="outlined"
                      fullWidth
                      className={classes.input}
                      placeholder="Street, house number"
                      InputProps={inputProps}
                      onBlur={handleBlur}
                      value={values.street_line1}
                      onChange={ev => setFieldValue('street_line1', ev.target.value)}
                    />
                    {errors.street_line1 && touched.street_line1 && (
                      <div className={classes.inputError}>{errors.street_line1}</div>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="street_line2"
                      name="street_line2"
                      variant="outlined"
                      fullWidth
                      className={classes.input}
                      placeholder="Apartment, building"
                      InputProps={inputProps}
                      value={values.street_line2}
                      onBlur={handleBlur}
                      onChange={ev => setFieldValue('street_line2', ev.target.value)}
                    />
                    {errors.street_line2 && touched.street_line2 && (
                      <div className={classes.inputError}>{errors.street_line2}</div>
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="region"
                      name="region"
                      variant="outlined"
                      fullWidth
                      className={classes.input}
                      placeholder="Region"
                      InputProps={inputProps}
                      onBlur={handleBlur}
                      value={values.region}
                      onChange={ev => setFieldValue('region', ev.target.value)}
                    />
                    {errors.region && touched.region && (
                      <div className={classes.inputError}>{errors.region}</div>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="postcode"
                      name="postcode"
                      variant="outlined"
                      fullWidth
                      className={classes.input}
                      placeholder="Postcode"
                      InputProps={inputProps}
                      onBlur={handleBlur}
                      value={values.postcode}
                      onChange={ev => setFieldValue('postcode', ev.target.value)}
                    />
                    {errors.postcode && touched.postcode && (
                      <div className={classes.inputError}>{errors.postcode}</div>
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="city"
                      name="city"
                      variant="outlined"
                      fullWidth
                      className={classes.input}
                      placeholder="City"
                      InputProps={inputProps}
                      onBlur={handleBlur}
                      value={values.city}
                      onChange={ev => setFieldValue('city', ev.target.value)}
                    />
                    {errors.city && touched.city && (
                      <div className={classes.inputError}>{errors.city}</div>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      id="country"
                      label="Country"
                      name="country"
                      component={SelectComponent}
                      className={`${classes.input} ${classes.inputRoot}`}
                      options={sortArrayAlphabetically(allCountries)}
                      InputProps={inputProps}
                      onBlur={handleBlur}
                      value={values.country}
                      placeholder="Country"
                      optionFieldValue="code"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue('country', e.target.value)
                      }
                    />
                    {errors.country && touched.country && (
                      <div className={classes.inputError}>{errors.country}</div>
                    )}
                  </Grid>
                </Grid>
                <Divider className={classes.divider} />
                <Field
                  id="bank_country"
                  label="Bank country"
                  name="bank_country"
                  component={SelectComponent}
                  className={`${classes.input} ${classes.inputRoot}`}
                  options={availableCountries}
                  InputProps={inputProps}
                  onBlur={handleBlur}
                  value={values.bank_country}
                  placeholder="Bank country"
                  optionFieldValue="code"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const value = e.target.value;
                    const countryObject = availableCountries.find(
                      (item: Country) => item.code === value,
                    );
                    setSelectedBankCountry(countryObject ? countryObject : null);
                    return setFieldValue('bank_country', value);
                  }}
                />
                <span className={classes.helperText}>
                  {' '}
                  If your country is not on a list, please contact our support
                </span>
                {errors.bank_country && touched.bank_country && (
                  <div className={classes.inputError}>{errors.bank_country}</div>
                )}
                {selectedBankCountry && (
                  <>
                    {(selectedBankCountry.iban ||
                      optionalIbanCountries.includes(selectedBankCountry.name)) && (
                      <>
                        <TextField
                          id="iban"
                          name="iban"
                          variant="outlined"
                          fullWidth
                          className={classes.input}
                          placeholder="IBAN"
                          InputProps={inputProps}
                          onBlur={handleBlur}
                          value={values.iban}
                          onChange={ev => {
                            setFieldValue('iban', ev.target.value);
                          }}
                        />
                        {errors.iban && touched.iban && (
                          <div className={classes.inputError}>{errors.iban}</div>
                        )}
                      </>
                    )}
                    {selectedBankCountry.bic && (
                      <>
                        <TextField
                          id="bic"
                          name="bic"
                          variant="outlined"
                          fullWidth
                          className={classes.input}
                          placeholder="BIC/SWIFT"
                          InputProps={inputProps}
                          onBlur={handleBlur}
                          value={values.bic}
                          onChange={ev => setFieldValue('bic', ev.target.value)}
                        />
                        {errors.bic && touched.bic && (
                          <div className={classes.inputError}>{errors.bic}</div>
                        )}
                      </>
                    )}
                    {selectedBankCountry.sort_code && (
                      <>
                        {/*Sort code*/}
                        <TextField
                          id="sort_code"
                          name="sort_code"
                          variant="outlined"
                          fullWidth
                          className={classes.input}
                          placeholder="Sort code"
                          InputProps={inputProps}
                          onBlur={handleBlur}
                          value={values.sort_code}
                          onChange={ev => setFieldValue('sort_code', ev.target.value)}
                        />
                        {errors.sort_code && touched.sort_code && (
                          <div className={classes.inputError}>{errors.sort_code}</div>
                        )}
                      </>
                    )}
                    {selectedBankCountry.routing_number && (
                      <>
                        {/**Routing number */}
                        <TextField
                          id="routing_number"
                          name="routing_number"
                          variant="outlined"
                          fullWidth
                          className={classes.input}
                          placeholder="Routing number"
                          InputProps={inputProps}
                          onBlur={handleBlur}
                          value={values.routing_number}
                          onChange={ev => setFieldValue('routing_number', ev.target.value)}
                        />
                        {errors.routing_number && touched.routing_number && (
                          <div className={classes.inputError}>{errors.routing_number}</div>
                        )}
                      </>
                    )}
                    {selectedBankCountry.clabe && (
                      <>
                        {/**Clabe */}
                        <TextField
                          id="clabe"
                          name="clabe"
                          variant="outlined"
                          fullWidth
                          className={classes.input}
                          placeholder="Clabe"
                          InputProps={inputProps}
                          onBlur={handleBlur}
                          value={values.clabe}
                          onChange={ev => setFieldValue('clabe', ev.target.value)}
                        />
                        {errors.clabe && touched.clabe && (
                          <div className={classes.inputError}>{errors.clabe}</div>
                        )}
                      </>
                    )}
                    {selectedBankCountry.ifsc && (
                      <>
                        {/**IFSC */}
                        <TextField
                          id="ifsc"
                          name="ifsc"
                          variant="outlined"
                          fullWidth
                          className={classes.input}
                          placeholder="IFSC"
                          InputProps={inputProps}
                          value={values.ifsc}
                          onBlur={handleBlur}
                          onChange={ev => setFieldValue('ifsc', ev.target.value)}
                        />
                        {errors.ifsc && touched.ifsc && (
                          <div className={classes.inputError}>{errors.ifsc}</div>
                        )}
                      </>
                    )}
                    {selectedBankCountry.bsb_code && (
                      <>
                        {/**BSB code */}
                        <TextField
                          id="bsb_code"
                          name="bsb_code"
                          variant="outlined"
                          fullWidth
                          className={classes.input}
                          placeholder="BSB code"
                          InputProps={inputProps}
                          onBlur={handleBlur}
                          value={values.bsb_code}
                          onChange={ev => setFieldValue('bsb_code', ev.target.value)}
                        />
                        {errors.bsb_code && touched.bsb_code && (
                          <div className={classes.inputError}>{errors.bsb_code}</div>
                        )}
                      </>
                    )}
                  </>
                )}
                <input
                  id="currency"
                  name="currency"
                  value={values.currency ? values.currency : 'EUR'}
                  type="hidden"
                />
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography className={classes.currencyLabel}>Amount to send</Typography>

                    <TextField
                      id="amount"
                      name="amount"
                      variant="outlined"
                      type="number"
                      className={classes.value}
                      onBlur={handleBlur}
                      onChange={event => handleInputChange(event.target.value)}
                      placeholder="0"
                      fullWidth
                      InputProps={{
                        ...inputProps,
                        style: {
                          paddingRight: 0,
                        },
                        endAdornment: (
                          <div className={classes.inputSymbol}>
                            <div className={classes.inputSymbolValue}>EUR</div>
                          </div>
                        ),
                      }}
                    />

                    <Grid container item direction="row">
                      <Typography className={`${classes.currencyLabel} ${classes.noMargin}`}>
                        EUR Balance:
                      </Typography>
                      <Typography color="secondary" className={classes.currencyBalance}>
                        {walletBalanceString}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography className={classes.currencyLabel}>Total</Typography>
                    <TextField
                      id="total"
                      name="total"
                      variant="outlined"
                      type="number"
                      className={classes.value}
                      disabled
                      value={Number(withdrawAmount) > 0 && Number(withdrawAmount) + 5}
                      fullWidth
                      InputProps={{
                        ...inputProps,
                        style: {
                          paddingRight: 0,
                        },
                        endAdornment: (
                          <div className={classes.inputSymbol}>
                            <div className={classes.inputSymbolValue}>EUR</div>
                          </div>
                        ),
                      }}
                    />
                    <Grid container item direction="row">
                      <Typography className={`${classes.currencyLabel} ${classes.noMargin}`}>
                        Transaction fee =
                      </Typography>
                      <Typography color="secondary" className={classes.currencyBalance}>
                        5 EUR
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
            <CustomButtonPrimary
              form="WithdrawEUR"
              type="submit"
              className={classes.button}
              variant="contained"
              fullWidth
              disabled={!isWithdrawAllowed || !isValid}
              onClick={() => {
                const { street_line1, street_line2, region, postcode, city, country, ...rest } =
                  values;
                saveWithdrawAddress({
                  ...rest,
                  address: {
                    street_line1,
                    street_line2,
                    region,
                    postcode,
                    city,
                    country,
                  },
                });
                openWithdrawDialog(2);
              }}
            >
              Send EUR
            </CustomButtonPrimary>
          </>
        );
      }}
    </Formik>
  );
};
