import { connect } from 'react-redux';
import { AppState } from '../../../state/initialState';
import { userStateSelector } from '../../../state/selectors';
import { setCurrentHomePage } from '../../state/common/actions';
import Settings from './Settings';

const mapDispatchToProps = {
  setCurrentHomePage: setCurrentHomePage,
};

const mapStateToProps = (state: AppState) => {
  const user = userStateSelector(state);

  return {
    user,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
