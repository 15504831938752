import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Colors } from '../../ui/style/colors';

const styles = () =>
  createStyles({
    tabContent: {
      backgroundColor: `${Colors.white}`,
      paddingRight: 30,
      paddingLeft: 30,
    },
  });

const useStyles = makeStyles(styles);
type Props = {
  children?: React.ReactNode;
  index: any;
  value: any;
};

const ExchangeCurrencyTab: React.FC<Props> = (props: Props): JSX.Element => {
  const classes = useStyles();
  const { children, value, index } = props;

  return (
    <div className={classes.tabContent} hidden={value !== index}>
      {children}
    </div>
  );
};

export default ExchangeCurrencyTab;
