import { connect } from 'react-redux';
import { AppState } from '../../../../state/initialState';
import SettingsTwoFactorAuth from './SettingsTwoFactorAuth';
import { enableTwoFactorAuth, isTwoFactorAuthEnabled, openTwoFactorAuth } from './state/actions';
import { twoFactorAuthStateSelector } from './state/selectors';

const mapDispatchToProps = {
  openTwoFactorAuth: openTwoFactorAuth,
  enableTwoFactorAuth: enableTwoFactorAuth,
  isTwoFactorAuthEnabled: isTwoFactorAuthEnabled,
};

const mapStateToProps = (state: AppState) => ({
  ...twoFactorAuthStateSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsTwoFactorAuth);
