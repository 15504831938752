import { connect } from 'react-redux';
import ProductDetailsDialog from './ProductDetailsDialog';
import { setDialogOpen, setInitialInvestValues } from '../state/actions';
import { isInfoDialogOpen } from '../../InfoDialog/state/actions';

const mapDispatchToProps = {
  setInfoDialogOpen: isInfoDialogOpen,
  setInvestDialogOpen: setDialogOpen,
  setInitialInvestValues: setInitialInvestValues,
};

export default connect(null, mapDispatchToProps)(ProductDetailsDialog);
