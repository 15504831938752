import { createSelector } from 'reselect';
import { AppState } from '../../../../../state/initialState';

const getFiatDepositState = ({
  home: {
    settings: { fiatDepositState },
  },
}: AppState) => fiatDepositState;

const getDashboardWalletsState = ({
  home: {
    dashboard: { wallets },
  },
}: AppState) => wallets;

const getDashboardComponentState = ({
  home: {
    dashboard: { componentState },
  },
}: AppState) => componentState;

export const getFiatDepositStateSelector = createSelector(
  getFiatDepositState,
  fiatDepositState => fiatDepositState,
);
export const getDashboardWalletsStateSelector = createSelector(
  getDashboardWalletsState,
  wallets => wallets,
);
export const getDashboardChartStateSelector = createSelector(
  getDashboardComponentState,
  dashboardState => dashboardState,
);
