import { Dialog, DialogTitle, Grid, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { EmptyAC, PayloadAC } from 'typesafe-actions';
import { BuySellModalState } from '../state/initialState';
import {
  CLEAR_BUY_SELL_MODAL_STATE,
  IS_BUY_SELL_MODAL_OPEN,
  OPEN_BUY_SELL_MODAL,
} from '../state/actions';
import { openBuySellModal, isBuySellModalOpen, clearBuySellModalState } from '../state/actions';
import { getBuyModalStateSelector } from '../state/selectors';
import { AppState } from '../../../../state/initialState';
import theme from '../../../../theme';
import { NavTab, NavTabs, NavTabContent } from '../../../../../components/NavTabs';
import { Colors } from '../../../../../ui/style/colors';
import BuySellForm from './BuySellForm/BuySellForm';

const useStyles = makeStyles({
  dialog: {
    height: 'center',
    maxWidth: 'center',
    borderRadius: 6,
    backgroundColor: 'transparent',
    padding: '35px 50px',
  },

  closeButton: {
    position: 'absolute',
    right: 5,
    top: 5,
    color: theme.palette.primary.light,
  },
  card: {
    paddingLeft: 25,
    paddingRight: 25,
  },
  inputFields: {
    marginTop: 5,
    height: 33,
    backgroundColor: `${Colors.white}`,
    width: '70%',
    border: 'none',
    outline: 'none',
    fontSize: 'medium',
  },
  selectItem: {
    marginTop: 4,
    height: 33,
    width: '50%',
    textAlign: 'center',
    boxSizing: 'border-box',
    borderRadius: 4,
    backgroundColor: `${Colors.white}`,
  },
  button: {
    marginTop: 40,
    textTransform: 'none',
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 1,
    textAlign: 'center',
  },
  labels: {
    fontWeight: 'bold',
    color: theme.palette.primary.light,
    marginTop: 30,
    marginBottom: 8,
  },
  inputError: {
    color: `${Colors.red}`,
    marginTop: 10,
  },

  coinIcon: {
    height: 25,
    marginRight: 6,
    marginBottom: 2,
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  progressNoAnimation: {
    animation: 'none',
  },
});

export type Coin = {
  symbol: string;
  icon: string;
};

type BuySellModalProps = {
  openBuySellModal: PayloadAC<typeof OPEN_BUY_SELL_MODAL, boolean>;
  isBuySellModalOpen: PayloadAC<typeof IS_BUY_SELL_MODAL_OPEN, boolean>;
  clearBuySellModalState: EmptyAC<typeof CLEAR_BUY_SELL_MODAL_STATE>;
  buyModalState: BuySellModalState;
  allCoins: Coin[];
  selectedWalletCoin: Coin;
};

const BuySellModal = ({
  openBuySellModal,
  buyModalState,
  allCoins,
  selectedWalletCoin,
  clearBuySellModalState,
}: BuySellModalProps) => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(0);

  const onClose = () => {
    clearBuySellModalState();
    openBuySellModal(false);
  };

  const onExit = () => {
    openBuySellModal(false);
  };

  const handleTabChange = (event: React.ChangeEvent<Record<string, unknown>>, newValue: number) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    if (buyModalState.isOpen) {
      openBuySellModal(true);
    }
  }, [buyModalState.isOpen]);
  return (
    <Dialog
      open={buyModalState.isOpen}
      className={classes.dialog}
      TransitionProps={{ onExited: onExit }}
    >
      <DialogTitle disableTypography>
        <Grid item>
          <NavTabs
            variant="scrollable"
            value={activeTab}
            onChange={handleTabChange}
            aria-label="Settings"
          >
            <NavTab label="Buy" />
            <NavTab label="Sell" />
          </NavTabs>
        </Grid>
      </DialogTitle>

      {onClose ? (
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
      <NavTabContent value={activeTab} index={0}>
        <BuySellForm
          activeTab={activeTab}
          inProgress={buyModalState.inProgress}
          allCoins={allCoins}
          selectedWalletCoin={selectedWalletCoin}
        />
      </NavTabContent>

      <NavTabContent value={activeTab} index={1}>
        <BuySellForm
          activeTab={activeTab}
          inProgress={buyModalState.inProgress}
          allCoins={allCoins}
          selectedWalletCoin={selectedWalletCoin}
        />
      </NavTabContent>
    </Dialog>
  );
};

const mapDispatchToProps = {
  openBuySellModal: openBuySellModal,
  isBuySellModalOpen: isBuySellModalOpen,
  clearBuySellModalState: clearBuySellModalState,
};
const mapStateToProps = (state: AppState) => {
  const buyModalState = getBuyModalStateSelector(state);
  return { buyModalState };
};

export default connect(mapStateToProps, mapDispatchToProps)(BuySellModal);
