/* eslint-disable */
import { createSelector } from 'reselect';
import { AppState } from '../../../../state/initialState';

const getInfoDialogState = ({ home: { infoDialog } }: AppState) => infoDialog;

export const getInfoDialogStateSelector = createSelector(
  getInfoDialogState,
  infoDialog => infoDialog,
);
