import { createSelector } from 'reselect';
import { AppState } from '../../../../../state/initialState';

const getTwoFactorAuthState = ({
  home: {
    settings: { twoFactorAuth },
  },
}: AppState) => twoFactorAuth;

export const isTwoFactorInProgress = createSelector(
  getTwoFactorAuthState,
  ({ isEnabledInProgress }) => isEnabledInProgress,
);

export const twoFactorAuthDialogSelector = createSelector(getTwoFactorAuthState, twoFactorAuth => ({
  step: twoFactorAuth.step,
}));

export const twoFactorAuthStateSelector = createSelector(
  getTwoFactorAuthState,
  twoFactorAuth => twoFactorAuth,
);

export const twoFactorAuthEnableSelector = createSelector(
  getTwoFactorAuthState,
  twoFactorAuth => twoFactorAuth.enabled,
);
