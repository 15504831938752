/* eslint-disable */
import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import { ExchangeCurrencyRequest } from '../../../../../../data/repositories/exchange/typedefs';
import { ExchangeCurrencyRequestState } from './initialState';

export const SET_SELECTED_OPTION = 'exchange/set_selected_option';
export const EXCHANGE_CURRENCY_SAVE_STATE = 'exchange/save_state';
export const SELL_WEEKLY_LIMIT = 'exchange/sell_weekly_limit';
export const EXCHANGE_CURRENCY_SELL_SAVE_STATE = 'exchange/save_state_sell';
export const SET_SELECTED_TAB = 'exchange/set_selected_tab';
export const EXCHANGE_CURRENCY = 'exchange/exchange';
export const EXCHANGE_CURRENCY_SUCCESS = 'exchange/exchange_currency/success';
export const EXCHANGE_CURRENCY_FAIL = 'exchange/exchange_currency/fail';
export const SLC_PACKAGES = 'exchange/slc_packages';
export const SLC_PACKAGES_SUCCESS = 'exchange/slc_packages/success';
export const SLC_PACKAGES_FAIL = 'exchange/slc_packages/fail';
export const BUY_SLC_PACKAGE = 'exchange/buy_slc_package';
export const BUY_SLC_PACKAGE_SUCCESS = 'exchange/buy_slc_package/success';
export const BUY_SLC_PACKAGE_FAIL = 'exchange/buy_slc_package/fail';

export const setSelectedOption = createStandardAction(SET_SELECTED_OPTION)<string>();
export const exchangeCurrencySaveState = createStandardAction(EXCHANGE_CURRENCY_SAVE_STATE)<
  ExchangeCurrencyRequestState
>();
export const exchangeCurrencySellSaveState = createStandardAction(
  EXCHANGE_CURRENCY_SELL_SAVE_STATE,
)<ExchangeCurrencyRequestState>();
export const setSelectedTab = createStandardAction(SET_SELECTED_TAB)<number>();

export const exchangeCurrency = createAsyncAction(
  EXCHANGE_CURRENCY,
  EXCHANGE_CURRENCY_SUCCESS,
  EXCHANGE_CURRENCY_FAIL,
)<ExchangeCurrencyRequest, undefined, Error>();

export const allPackages = createAsyncAction(SLC_PACKAGES, SLC_PACKAGES_SUCCESS, SLC_PACKAGES_FAIL)<
  any,
  any,
  Error
>();
export const getAllPackages = createStandardAction(SLC_PACKAGES)<undefined>();
export const buyPackage = createAsyncAction(
  BUY_SLC_PACKAGE,
  BUY_SLC_PACKAGE_SUCCESS,
  BUY_SLC_PACKAGE_FAIL,
)<any, any, Error | string>();
