export default {
  formatNumberDigits: (value, minFraction, maxFraction) =>
    value.toLocaleString('en', {
      minimumFractionDigits: minFraction,
      maximumFractionDigits: maxFraction,
    }),

  formatBigDigits: (value, decimals) =>
    parseFloat(value.toFixed(decimals).toString()).toLocaleString('en'),

  formatRoundedBigToStringWithCommas: (value, minFraction, maxFraction) =>
    value.toLocaleString('en', {
      minimumFractionDigits: minFraction,
      maximumFractionDigits: maxFraction,
    }),

  allowOnlyFloatNumbers: event => {
    // event for onKeyPress
    const floatRegex = /^[0-9]|[.]$/;
    if (!floatRegex.test(event.key)) event.preventDefault();
  },
};
