import { connect } from 'react-redux';
import { AppState } from '../../../../../../state/initialState';
import { hasCompletedKYCSelector } from '../../../../Settings/state/selectors';
import { setSelectedOption } from '../../state/actions';
import { getInvestCurrencySelector } from '../../state/selectors';
import InvestCurrencyBuyOptionsItem from './InvestCurrencyBuyOptionsItem';

const mapDispatchToProps = {
  setSelectedOption: setSelectedOption,
};

const mapStateToProps = (state: AppState) => {
  const hasCompletedKYC = hasCompletedKYCSelector(state);
  const investCurrencyState = getInvestCurrencySelector(state);
  return { hasCompletedKYC, ...investCurrencyState };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvestCurrencyBuyOptionsItem);
