import { all, put, select, takeEvery } from 'redux-saga/effects';
import LocalStorage from '../../../../../../data/LocalStorage';
import api from '../../../../../../data/repositories/exchange';
import { WALLET_COINS } from '../../../../../../utils/constants/amounts';
import {
  BUY_SLC_PACKAGE_ENDPOINT,
  EXCHANGE_CURRENCY_ENDPOINT,
  SELL_SLC_PACKAGE_ENDPOINT,
  SLC_PACKAGES_ENDPOINT,
  WEEKLY_SELL_LIMIT_ENDPOINT,
} from '../../../../../../utils/constants/api';
import { USER_ID } from '../../../../../../utils/constants/localStorageKeys';
import { getCurrencySymbolName } from '../../../../../../utils/helper/utils';
import { isTwoFactorAuthEnabled } from '../../../Settings/SettingsTwoFactorAuth/state/actions';
import { getCoinDecimalsSelector } from '../../../Wallet/state/selectors';
import {
  allPackages,
  buyPackage,
  BUY_SLC_PACKAGE,
  exchangeCurrency,
  exchangeCurrencySaveState,
  EXCHANGE_CURRENCY,
  EXCHANGE_CURRENCY_SAVE_STATE,
  SELL_WEEKLY_LIMIT,
  SLC_PACKAGES,
} from './actions';
import { ExchangeCurrencyState } from './initialState';
import { getExchangeCurrencySelector } from './selectors';

export function* exchangeCurrency$(action: ReturnType<typeof exchangeCurrency.request>) {
  try {
    const userID = LocalStorage.get(USER_ID);
    yield api.exchangeCurrency(action.payload, EXCHANGE_CURRENCY_ENDPOINT(userID ? userID : ''));
    yield put(exchangeCurrency.success());
  } catch (error) {
    yield put(exchangeCurrency.failure(error));
  }
}

export function* fetchWeeklyLimit$() {
  try {
    const userID = LocalStorage.get(USER_ID);
    const {
      allowed_amount_to_sell_in_eurocents,
      allowed_amount_to_sell_in_slc,
      sold_in_eurocents,
      sold_in_slc,
      total_amount_to_sell_in_eurocents,
      total_amount_to_sell_in_slc,
    } = yield api.allPackages(WEEKLY_SELL_LIMIT_ENDPOINT(userID ? userID : ''));

    yield put(
      buyPackage.success({
        allowed_amount_to_sell_in_eurocents,
        allowed_amount_to_sell_in_slc: allowed_amount_to_sell_in_slc / 10000000,
        sold_in_eurocents,
        sold_in_slc: sold_in_slc / 10000000,
        total_amount_to_sell_in_eurocents: total_amount_to_sell_in_eurocents / 10000000,
        total_amount_to_sell_in_slc: total_amount_to_sell_in_slc / 10000000,
      }),
    );
  } catch (error) {
    if (error.response.status !== 406) {
      yield put(exchangeCurrency.failure(error));
    }
  }
}

export function* fetchPackages$() {
  try {
    const userID = LocalStorage.get(USER_ID);
    const packages = yield api.allPackages(SLC_PACKAGES_ENDPOINT(userID ? userID : ''));

    const preparedPackages = packages.map((singlePackage: any) => {
      return {
        ...singlePackage.buy_package,
        final_slc_price: singlePackage.final_slc_price,
      };
    });

    yield put(
      allPackages.success({
        packages: preparedPackages.reduce((p: any, c: any) => {
          return {
            ...p,
            [c.payment_type]: [...(p[c.payment_type] || []), c].sort(
              (a, b) => a.eur_price - b.eur_price,
            ),
          };
        }, {}),
      }),
    );
  } catch (error) {
    yield put(allPackages.failure(error));
  }
}

export function* buyPackage$(action: ReturnType<typeof buyPackage.request>) {
  try {
    const userID = LocalStorage.get(USER_ID);
    if (action.payload.buy_package_name) {
      const response = yield api.buyPackage(
        action.payload,
        BUY_SLC_PACKAGE_ENDPOINT(userID ? userID : ''),
      );
      yield put(buyPackage.success(response));
    } else {
      const response = yield api.buyPackage(
        action.payload,
        SELL_SLC_PACKAGE_ENDPOINT(userID ? userID : ''),
      );

      if (response.order_status === 'rejected') {
        throw new Error(`Exchange is currently overloaded. Please try again later.`);
      }

      yield put(buyPackage.success(response));
    }
  } catch (error) {
    let error_message = 'Something went wrong! Please try again later.';

    if (error.message) {
      error_message = error.message;
    }
    if (error.response) {
      error_message =
        error.response.status == 425
          ? 'Cannot sell right now, please try again in a few minutes.'
          : error.response.data.error;
    }
    yield put(buyPackage.failure(error_message));
  }
}

//TODO
export function* exchangeSaveState$(action: ReturnType<typeof exchangeCurrencySaveState>) {
  const {
    packageName: buy_package_name,
    selectedOption,
    time,
    get,
    selectedTab,
    amount,
    beneficiary,
    beneficiary_address,
    iban,
    bic,
    two_factor,
  }: ExchangeCurrencyState = yield select(getExchangeCurrencySelector);
  const slcDecimals: number = yield select(getCoinDecimalsSelector('SLC'));

  const getBuyPackageData = (decimals: any) => ({
    '2fa': two_factor,
    order_type: 'buy',
    payment_type: 'other',
    dialog_popup_time: time,
    buy_package_name,
    symbol: getCurrencySymbolName(selectedOption).toLowerCase(),
    slc_amount: get,
    price_in_currency: Math.round(+(amount || 0) * Math.pow(10, decimals)),
  });

  const getSellPackageData = (decimals: any) => ({
    '2fa': two_factor,
    order_type: 'sell',
    payment_type: 'other',
    dialog_popup_time: time,
    symbol: getCurrencySymbolName(selectedOption).toLowerCase(),
    slc_amount: Math.round(+(amount || 0) * Math.pow(10, slcDecimals)),
    price_in_currency: Math.round(+(get || 0) * Math.pow(10, decimals)),
  });

  if (action.payload.step)
    switch (action.payload.step) {
      case 1:
        break;
      case 2:
        if (selectedOption === 'WIRE_EUR' || selectedOption === 'SLC')
          yield put(exchangeCurrencySaveState({ step: 3 }));
        else yield put(isTwoFactorAuthEnabled());
        break;
      case 3:
        const decimals: ReturnType<typeof getCoinDecimalsSelector> = yield select(
          getCoinDecimalsSelector(selectedOption),
        );
        if (!selectedTab) {
          let data;
          if (selectedOption === 'WIRE_EUR') {
            data = { buy_package_name, payment_type: 'wire_transfer', order_type: 'buy' };
          }
          if (WALLET_COINS.includes(selectedOption)) {
            data = getBuyPackageData(decimals);
          }
          yield put(buyPackage.request(data));
        } else {
          let data;
          if (selectedOption === 'SLC') {
            data = {
              order_type: 'sell',
              payment_type: 'wire_transfer',
              eur_price: Math.round(+(get || 0) * 100),
              slc_amount: Math.round(+(amount || 0) * Math.pow(10, slcDecimals)),
              beneficiary,
              beneficiary_address,
              iban,
              bic,
              other_info: action.payload.other_info || '',
            };
          }
          if (WALLET_COINS.includes(selectedOption)) {
            data = getSellPackageData(decimals);
          }

          yield put(buyPackage.request(data));
        }
        break;
      default:
        break;
    }
}

export default function* () {
  yield all([
    takeEvery(EXCHANGE_CURRENCY_SAVE_STATE, exchangeSaveState$),
    takeEvery(EXCHANGE_CURRENCY, exchangeCurrency$),
    takeEvery(SLC_PACKAGES, fetchPackages$),
    takeEvery(BUY_SLC_PACKAGE, buyPackage$),
    takeEvery(SELL_WEEKLY_LIMIT, fetchWeeklyLimit$),
  ]);
}
