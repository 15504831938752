import {
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  FormLabel,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  SvgIcon,
  Tooltip,
  Typography,
} from '@material-ui/core';
import orange from '@material-ui/core/colors/orange';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { EmptyAC, PayloadAC } from 'typesafe-actions';
import { setChangeUserPasswordSelectedUser } from '../../../App/Home/HomeContent/Admin/ChangeUserPassword/state/actions';
import {
  setTransactionHistoryActiveTab,
  setTransactionHistorySelectedUser,
} from '../../../App/Home/HomeContent/Admin/UserTransactionHistory/state/actions';
import {
  clearAllFilters,
  RESET_USER_KYC_STATUS,
  toggleFilter,
} from '../../../App/Home/HomeContent/Affiliate/AffiliateTable/state/actions';
import { ChangeUserStatusRequest } from '../../../App/Home/HomeContent/Affiliate/AffiliateTable/state/initialState';
import useExchangeCurrencyPrices from '../../../App/Home/HomeContent/Exchange/ExchangeCurrency/useExchangeCurrencyPrices';
import { IS_INFO_DIALOG_OPEN } from '../../../App/Home/HomeContent/InfoDialog/state/actions';
import { InfoDialogState } from '../../../App/Home/HomeContent/InfoDialog/state/initialState';
import { getInfoDialogStateSelector } from '../../../App/Home/HomeContent/InfoDialog/state/selectors';
import { twoFactorAuthEnableSelector } from '../../../App/Home/HomeContent/Settings/state/selectors';
import { getCoinDecimalsSelector } from '../../../App/Home/HomeContent/Wallet/state/selectors';
import {
  isTwoFactorAuthEnabled,
  IS_CHANGE_SELL_LIMIT_DIALOG_OPEN,
  IS_TWO_FACTOR_AUTH_ENABLED,
  USER_HAS_AFFILIATE_PACKAGE_CHECK,
} from '../../../App/state/actions';
import { AppState } from '../../../App/state/initialState';
import { userStateSelector } from '../../../App/state/selectors';
import { SelectedUser } from '../../../data/repositories/admin/typedefs';
import { Colors } from '../../../ui/style/colors';
import utils from '../../../utils/balanceUtils';
import { CRYPTO_MAX_FRACTION, CRYPTO_MIN_FRACTION } from '../../../utils/constants/amounts';
import { roles } from '../../../utils/constants/roles';
import ModalState from '../../ModalState';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    claimReward: {
      color: `${Colors.mediumTurquoise}`,
      fontSize: 14,
      fontWeight: 600,
      textAlign: 'right',
      cursor: 'pointer',
    },
    listName: {
      '& span': {
        overflowX: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      maxHeight: 316,
      overflow: 'auto',
      marginLeft: '-1rem',
    },
    status: {
      color: `${Colors.havelockBlue}`,
      fontSize: 14,
    },
    statusPending: {
      color: `${Colors.blueViolet}`,
      fontSize: 14,
    },
    expandButton: {
      textTransform: 'none',
      marginLeft: 'auto',
      color: theme.palette.primary.light,
    },
    cell: {
      borderBottom: 'none',
    },
    expandableCell: { borderTop: 'none' },
    collapse: {
      padding: '20px 10px',
    },
    labels: {
      fontSize: 14,
      color: `${Colors.wildBlueYonder}`,
      textTransform: 'uppercase',
    },
    transactionDetailsValues: {
      marginTop: 0,
      fontSize: 14,
      color: theme.palette.primary.light,
    },
    transactionStatusValue: {
      marginTop: 10,
      fontSize: 16,
      color: `${Colors.ceruleanBLue}`,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    capitalFirst: {
      textTransform: 'capitalize',
    },
    viewDetailsButton: {},
    flex: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    moreOptionsWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '24px',
      float: 'right',
      color: `${Colors.wildBlueYonder}`,
      '&:hover, &:focus': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
        cursor: 'pointer',
        borderRadius: '4px',
        border: 0,
      },
    },
    error: {
      color: orange[600],
    },
    parent: {
      marginLeft: '1rem',
      fontSize: '14px',
    },
    mainLabel: {
      marginTop: 3,
    },
    mainLabel2: {
      marginTop: 8,
      fontSize: '14px',
    },
    tooltip: {
      position: 'relative',
      top: 5,
      left: 5,
      fontSize: 20,
    },
    additionalInfoWrapper: {
      display: 'flex',
      borderBottom: `1px solid ${Colors.wildBlueYonder}`,
      marginBottom: 10,
      paddingBottom: 10,
      minHeight: 59,
    },
    adminWidgetRow: {
      cursor: 'pointer',
      '&:hover ': {
        '& $moreOptionsWrapper': {
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
          cursor: 'pointer',
          borderRadius: '4px',
          border: 0,
        },
      },
    },
    textOverflow: {
      width: 100,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    dialog: {
      height: 'center',
      width: 'center',
      borderRadius: 6,
      backgroundColor: 'transparent',
      paddingLeft: 30,
      paddingRight: 30,
    },
  }),
);

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 16,
  },
}))(Tooltip);

type Props = {
  referral: any;
  isAddDeductDialogOpen: PayloadAC<'affiliate/is_add_deduct_dialog_open', boolean>;
  isFullProfileDialogOpen: PayloadAC<'affiliate/is_full_profile_dialog_open', boolean>;
  asyncChangeUserStatus: PayloadAC<'affiliate/change_user_status', ChangeUserStatusRequest>;
  asyncToggleAdminRole: PayloadAC<'affiliate/toggle_admin_role', boolean>;
  setUserForDialog: PayloadAC<'affiliate/set_user_for_dialog', any>;
  setTransactionHistoryActiveTab: PayloadAC<
    'admin/user_transaction_history/set_active_tab',
    string
  >;
  setTransactionHistorySelectedUser: PayloadAC<
    'admin/user_transaction_history/set_selected_user',
    SelectedUser
  >;
  setChangeUserPasswordSelectedUser: PayloadAC<
    'admin/change_user_password/set_selected_user',
    SelectedUser
  >;
  asyncResetUserKYCStatus: PayloadAC<typeof RESET_USER_KYC_STATUS, string>;
  isTwoFactorAuthEnabled: EmptyAC<typeof IS_TWO_FACTOR_AUTH_ENABLED>;
  infoDialogState: InfoDialogState;
  user: any;
  slcDecimals: any;
  expanded: boolean;
  setExpanded: (id: string, email: string) => void;
  referralDetailInProgress: boolean;
  referralDetail: any;
  clearAllFilters: any;
  isAdminSearchWidget?: boolean;
  is2FaEnabled?: any;
};

const AffiliateIcon = () => (
  <SvgIcon>
    <path d="M16 17V19H2V17S2 13 9 13 16 17 16 17M12.5 7.5A3.5 3.5 0 1 0 9 11A3.5 3.5 0 0 0 12.5 7.5M15.94 13A5.32 5.32 0 0 1 18 17V19H22V17S22 13.37 15.94 13M15 4A3.39 3.39 0 0 0 13.07 4.59A5 5 0 0 1 13.07 10.41A3.39 3.39 0 0 0 15 11A3.5 3.5 0 0 0 15 4Z" />
  </SvgIcon>
);

const AffiliatesTableRow: FC<Props> = ({
  referral,
  isAddDeductDialogOpen,
  isFullProfileDialogOpen,
  setUserForDialog,
  asyncChangeUserStatus,
  setTransactionHistoryActiveTab,
  setTransactionHistorySelectedUser,
  setChangeUserPasswordSelectedUser,
  user,
  slcDecimals,
  expanded,
  setExpanded,
  referralDetailInProgress,
  referralDetail,
  clearAllFilters,
  isAdminSearchWidget,
  infoDialogState,
  isTwoFactorAuthEnabled,
  is2FaEnabled,
}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { dialogProps } = infoDialogState;
  const onExit = () => {
    setAnchorEl(null);
  };
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    isTwoFactorAuthEnabled();
  }, []);
  const openDialog = (sendToUser: boolean, fullInfoDialog = false) => {
    if (!is2FaEnabled && fullInfoDialog === false) {
      setIsOpen(true);
    } else {
      referral.send_to_user = sendToUser;

      onExit();
      setUserForDialog({ referralUser: referral });

      if (fullInfoDialog) {
        isFullProfileDialogOpen(true);
      } else {
        isAddDeductDialogOpen(true);
      }
    }
  };

  const changeUserStatus = (status: boolean) => {
    const data = {
      id: referral.id,
      enabled: status,
    };
    onExit();
    asyncChangeUserStatus(data);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const { getSelectedCoinMarketVal } = useExchangeCurrencyPrices();
  const slcEurVal = getSelectedCoinMarketVal('SLC');

  const getStatusType = () => {
    /*
      enabled: false => affiliate status: Blacklisted
      enabled: true, confirmed: true => affiliate status: Confirmed
      enabled: true, confirmed: false => affiliate status: Not Confirmed 
      NOTE: For Not Confirmed we used value: whitelisted because of select component duplication
    */
    const { enabled, confirmed } = referral;

    switch (true) {
      case !enabled:
        return 'Blacklisted';
      case enabled && confirmed:
        return 'Confirmed';
      case enabled && !confirmed:
        return 'Not Confirmed';
      default:
        return 'Signed in';
    }
  };
  const setWidgetState = (activeTab = 'all_transactions') => {
    const { id, email, first_name, last_name } = referral;
    const selectedUser = { id, email, firstName: first_name, lastName: last_name };
    dispatch({
      type: IS_INFO_DIALOG_OPEN,
      payload: false,
    });
    if (dialogProps.activeTab === 'change_user_password') {
      setChangeUserPasswordSelectedUser(selectedUser);
    } else {
      setTransactionHistoryActiveTab(activeTab);
      setTransactionHistorySelectedUser(selectedUser);
    }
  };
  const getKycStatus = () => {
    switch (referral.kyc_status) {
      case 'applicant_created':
        return 'Applicant created';
      case 'completed':
        return 'Completed';
      case 'document_uploaded':
        return 'Document uploaded';
      case 'not_started':
        return 'Not started';
      case 'pending':
        return 'Pending';
      case 'rejected':
        return 'Rejected';
      case 'suspended':
        return 'Suspended';
    }
  };

  const openChangeSlcLimitModal = () => {
    setUserForDialog({ referralUser: referral });
    dispatch({ type: IS_CHANGE_SELL_LIMIT_DIALOG_OPEN, payload: true });
    dispatch({
      type: USER_HAS_AFFILIATE_PACKAGE_CHECK,
      payload: referral.id,
    });
    onExit();
  };

  const getTxHashLink = () => {
    if (referral.blockchain_tx_hash) {
      return (
        <div className={classes.textOverflow}>
          <a
            target="_blank"
            rel="noreferrer"
            href={`${process.env.REACT_APP_HYPERLEDGER_EXPLORER_URL}?tab=transactions&transId=${referral.blockchain_tx_hash}`}
          >
            {referral.blockchain_tx_hash}
          </a>
        </div>
      );
    } else {
      return <span>Coming soon</span>;
    }
  };
  return isAdminSearchWidget ? (
    <TableRow className={classes.adminWidgetRow}>
      <TableCell>
        <div>{referral.email}</div>
      </TableCell>
      <TableCell>
        {referral.first_name} {referral.last_name}
      </TableCell>
      <TableCell align="right">
        <div className={classes.moreOptionsWrapper} onClick={handleClick}>
          <MoreVertIcon />
        </div>

        <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={onExit}>
          {dialogProps.activeTab === 'change_user_password' ? (
            <MenuItem onClick={() => setWidgetState()}>Change Password</MenuItem>
          ) : (
            <div>
              <MenuItem onClick={() => setWidgetState()}>Transactions</MenuItem>
              <MenuItem onClick={() => setWidgetState('wallet_transfers')}>Transfers</MenuItem>
            </div>
          )}
        </Menu>
      </TableCell>
    </TableRow>
  ) : (
    <>
      <TableRow>
        <TableCell>
          <div className={classes.flex}>
            <div>{referral.email}</div>
            <Button
              className={classes.expandButton}
              style={expanded ? { fontWeight: 'bold', color: `${Colors.blueCharcoal}` } : {}}
              onClick={() => setExpanded(referral.id || referral.referral_id, referral.email)}
            >
              {expanded ? 'Hide' : 'View'} Details
            </Button>
          </div>
        </TableCell>

        <TableCell>
          {referral.first_name} {referral.last_name}
        </TableCell>
        <TableCell>{getTxHashLink()}</TableCell>
        <TableCell
          className={[
            classes.status,
            referral.hasOwnProperty('enabled') && !referral.enabled && classes.error,
            referral.enabled && !referral.confirmed && classes.statusPending,
          ].join(' ')}
        >
          {getStatusType()}
        </TableCell>

        {roles.isOwner(user.roles) && (
          <>
            <TableCell align="left">{referral.has_bitgo ? 'Yes' : 'No'}</TableCell>
            <TableCell align="left">{getKycStatus()}</TableCell>
          </>
        )}

        <TableCell align="right">{moment(referral.created_at).format('DD/MM/YYYY')}</TableCell>
        <TableCell align="right">{referral.level}</TableCell>
        {roles.isOwner(user.roles) && (
          <TableCell align="right">
            <div className={classes.moreOptionsWrapper} onClick={handleClick}>
              <MoreVertIcon />
            </div>
            <Dialog open={isOpen} className={classes.dialog}>
              <ModalState
                onExit={() => setIsOpen(false)}
                message={'First enable 2FA authentication'}
                variant="warning"
                showCloseButton={false}
              >
                <Link
                  to={{
                    pathname: '/settings',
                    search: '?auth=true',
                  }}
                >
                  Enable here
                </Link>
              </ModalState>
            </Dialog>
            <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={onExit}>
              <MenuItem onClick={() => openDialog(false, true)}>View profile</MenuItem>
              <MenuItem onClick={() => openDialog(true)}>Add SLC</MenuItem>
              <MenuItem onClick={() => openDialog(false)}>Deduct SLC</MenuItem>
              <MenuItem onClick={openChangeSlcLimitModal}>Change SLC sell limit</MenuItem>
              {referral.hasOwnProperty('enabled') ? (
                <MenuItem onClick={() => changeUserStatus(referral.enabled)}>
                  {referral.enabled ? 'Blacklist user' : 'Whitelist user'}
                </MenuItem>
              ) : (
                <div>
                  <MenuItem onClick={() => changeUserStatus(true)}>Whitelist user</MenuItem>
                  <MenuItem onClick={() => changeUserStatus(false)}>Blacklist user</MenuItem>
                </div>
              )}

              {/* <MenuItem onClick={() => toggleAdminRole(true)}>Add admin role</MenuItem>
              <MenuItem onClick={() => toggleAdminRole(false)}>Remove admin role</MenuItem> */}

              {/* <MenuItem key="deduct" onClick={() => openDialog(false)}>
                Deduct SLC
              </MenuItem> */}
            </Menu>
          </TableCell>
        )}
        {/* <TableCell>12312</TableCell>
      <TableCell>1231</TableCell> */}
        {/*<TableCell>*/}
        {/*<div className={classes.flex}>*/}
        {/*{referral.has_children && (*/}
        {/*<Button*/}
        {/*className={classes.expandButton}*/}
        {/*onClick={() => {*/}
        {/*let path;*/}
        {/*if (referral.mail_path) {*/}
        {/*const mailPath = referral.mail_path.split(' -> ');*/}
        {/*const refPath = referral.reference_path.split(' -> ');*/}
        {/*if (mailPath.length > 1)*/}
        {/*path = mailPath.reduce(*/}
        {/*(p: { [x: string]: { email: any; id: any } }, c: any, index: number) => {*/}
        {/*p[index + 1] = { email: c, id: refPath[index] };*/}
        {/*return p;*/}
        {/*},*/}
        {/*{},*/}
        {/*);*/}
        {/*}*/}
        {/*dispatch({*/}
        {/*type: 'affiliate/get_referral_tree',*/}
        {/*payload: {*/}
        {/*referralId: referral.id || referral.referral_id,*/}
        {/*level: referral.level,*/}
        {/*email: referral.email,*/}
        {/*path,*/}
        {/*},*/}
        {/*});*/}
        {/*}}*/}
        {/*>*/}
        {/*View tree*/}
        {/*</Button>*/}
        {/*)}*/}
        {/*</div>*/}
        {/*</TableCell>*/}
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          className={classes.expandableCell}
          colSpan={6}
        >
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Grid container spacing={3} className={classes.collapse}>
              {referralDetailInProgress ? (
                <CircularProgress color="secondary" size={30} />
              ) : (
                referralDetail.full_referral_info && (
                  <>
                    <Grid item xs={6} zeroMinWidth>
                      <Grid item xs={12} zeroMinWidth className={classes.additionalInfoWrapper}>
                        <Grid item xs={6} zeroMinWidth>
                          <FormLabel className={classes.labels}>
                            TOTAL / PENDING INCOME
                            <LightTooltip
                              className={classes.tooltip}
                              title={
                                <div>
                                  Total BV & Rewards that you earned from {referral.first_name}{' '}
                                  {referral.last_name} only.
                                </div>
                              }
                            >
                              <HelpOutlineIcon />
                            </LightTooltip>
                          </FormLabel>
                        </Grid>
                        <Grid item xs={6} zeroMinWidth>
                          <Typography className={classes.mainLabel} component="p">
                            {referralDetail.full_referral_info.completed_income
                              ? utils.calculateValue(
                                  Number(referralDetail.full_referral_info.completed_income),
                                  7,
                                  slcEurVal,
                                )
                              : '0.00'}
                            <span>
                              &nbsp;/&nbsp;
                              {referralDetail.full_referral_info.pending_income
                                ? utils.calculateValue(
                                    Number(referralDetail.full_referral_info.pending_income),
                                    7,
                                    slcEurVal,
                                  )
                                : '0.00'}{' '}
                              EUR
                            </span>
                          </Typography>
                          <Typography
                            noWrap
                            component="p"
                            className={classes.transactionDetailsValues}
                          >
                            {referralDetail.full_referral_info.completed_income
                              ? utils.formatBalanceToString(
                                  Number(referralDetail.full_referral_info.completed_income),
                                  slcDecimals,
                                  CRYPTO_MIN_FRACTION,
                                  CRYPTO_MAX_FRACTION,
                                )
                              : '0.00'}

                            <span>
                              &nbsp;/&nbsp;
                              {referralDetail.full_referral_info.pending_income
                                ? utils.formatBalanceToString(
                                    Number(referralDetail.full_referral_info.pending_income),
                                    slcDecimals,
                                    CRYPTO_MIN_FRACTION,
                                    CRYPTO_MAX_FRACTION,
                                  )
                                : '0.00'}
                              &nbsp;SLC
                            </span>
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} zeroMinWidth className={classes.additionalInfoWrapper}>
                        <Grid item xs={6} zeroMinWidth>
                          <FormLabel className={classes.labels}>
                            TOTAL AFFILIATE INCOME{' '}
                            <LightTooltip
                              className={classes.tooltip}
                              title={
                                <div>
                                  Total BV that {referral.first_name} {referral.last_name} earned
                                  from all of his affiliates.
                                </div>
                              }
                            >
                              <HelpOutlineIcon />
                            </LightTooltip>
                          </FormLabel>
                        </Grid>{' '}
                        <Grid item xs={6} zeroMinWidth>
                          <Typography className={classes.mainLabel} component="p">
                            {referralDetail.referral_income_total.completed_income
                              ? utils.calculateValue(
                                  Number(referralDetail.referral_income_total.completed_income),
                                  7,
                                  slcEurVal,
                                )
                              : '0.00'}
                            <span>
                              &nbsp;/&nbsp;
                              {referralDetail.referral_income_total.pending_income
                                ? utils.calculateValue(
                                    Number(referralDetail.referral_income_total.pending_income),
                                    7,
                                    slcEurVal,
                                  )
                                : '0.00'}{' '}
                              EUR
                            </span>
                          </Typography>
                          <Typography
                            noWrap
                            component="p"
                            className={classes.transactionDetailsValues}
                          >
                            {referralDetail.referral_income_total.completed_income
                              ? utils.formatBalanceToString(
                                  Number(referralDetail.referral_income_total.completed_income),
                                  slcDecimals,
                                  CRYPTO_MIN_FRACTION,
                                  CRYPTO_MAX_FRACTION,
                                )
                              : '0.00'}

                            <span>
                              &nbsp;/&nbsp;
                              {referralDetail.referral_income_total.pending_income
                                ? utils.formatBalanceToString(
                                    Number(referralDetail.referral_income_total.pending_income),
                                    slcDecimals,
                                    CRYPTO_MIN_FRACTION,
                                    CRYPTO_MAX_FRACTION,
                                  )
                                : '0.00'}
                              &nbsp;SLC
                            </span>
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} zeroMinWidth className={classes.additionalInfoWrapper}>
                        <Grid item xs={6} zeroMinWidth>
                          <FormLabel className={classes.labels}>
                            MY INCOME FOR SELECTED BRANCH
                            <LightTooltip
                              className={classes.tooltip}
                              title={
                                <div>
                                  Total BV you have earned from {referral.first_name}{' '}
                                  {referral.last_name} and all of his/her affiliates together.
                                </div>
                              }
                            >
                              <HelpOutlineIcon />
                            </LightTooltip>
                          </FormLabel>
                        </Grid>
                        <Grid item xs={6} zeroMinWidth>
                          <Typography className={classes.mainLabel} component="p">
                            {referralDetail.full_referral_info.completed_income
                              ? utils.calculateValue(
                                  Number(
                                    referralDetail.my_income_from_referral_total.completed_income +
                                      referralDetail.full_referral_info.completed_income,
                                  ),
                                  7,
                                  slcEurVal,
                                )
                              : '0.00'}
                            <span>
                              &nbsp;/&nbsp;
                              {referralDetail.full_referral_info.pending_income
                                ? utils.calculateValue(
                                    Number(
                                      referralDetail.my_income_from_referral_total.pending_income +
                                        referralDetail.full_referral_info.pending_income,
                                    ),
                                    7,
                                    slcEurVal,
                                  )
                                : '0.00'}{' '}
                              EUR
                            </span>
                          </Typography>
                          <Typography
                            noWrap
                            component="p"
                            className={classes.transactionDetailsValues}
                          >
                            {referralDetail.full_referral_info.completed_income
                              ? utils.formatBalanceToString(
                                  Number(
                                    referralDetail.my_income_from_referral_total.completed_income +
                                      referralDetail.full_referral_info.completed_income,
                                  ),
                                  slcDecimals,
                                  CRYPTO_MIN_FRACTION,
                                  CRYPTO_MAX_FRACTION,
                                )
                              : '0.00'}

                            <span>
                              &nbsp;/&nbsp;
                              {referralDetail.full_referral_info.pending_income
                                ? utils.formatBalanceToString(
                                    Number(
                                      referralDetail.my_income_from_referral_total.pending_income +
                                        referralDetail.full_referral_info.pending_income,
                                    ),
                                    slcDecimals,
                                    CRYPTO_MIN_FRACTION,
                                    CRYPTO_MAX_FRACTION,
                                  )
                                : '0.00'}
                              &nbsp;SLC
                            </span>
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} zeroMinWidth className={classes.additionalInfoWrapper}>
                        <Grid item xs={6} zeroMinWidth>
                          <FormLabel className={classes.labels}>
                            DIRECT AFFILIATE INCOME
                            <LightTooltip
                              className={classes.tooltip}
                              title={
                                <div>
                                  Total BV that {referral.first_name} {referral.last_name} earned
                                  from his direct affiliates.
                                </div>
                              }
                            >
                              <HelpOutlineIcon />
                            </LightTooltip>
                          </FormLabel>
                        </Grid>
                        <Grid item xs={6} zeroMinWidth>
                          <Typography className={classes.mainLabel} component="p">
                            {referralDetail.referral_income_direct.completed_income
                              ? utils.calculateValue(
                                  Number(referralDetail.referral_income_direct.completed_income),
                                  7,
                                  slcEurVal,
                                )
                              : '0.00'}
                            <span>
                              &nbsp;/&nbsp;
                              {referralDetail.referral_income_direct.pending_income
                                ? utils.calculateValue(
                                    Number(referralDetail.referral_income_direct.pending_income),
                                    7,
                                    slcEurVal,
                                  )
                                : '0.00'}{' '}
                              EUR
                            </span>
                          </Typography>
                          <Typography
                            noWrap
                            component="p"
                            className={classes.transactionDetailsValues}
                          >
                            {referralDetail.referral_income_direct.completed_income
                              ? utils.formatBalanceToString(
                                  Number(referralDetail.referral_income_direct.completed_income),
                                  slcDecimals,
                                  CRYPTO_MIN_FRACTION,
                                  CRYPTO_MAX_FRACTION,
                                )
                              : '0.00'}

                            <span>
                              &nbsp;/&nbsp;
                              {referralDetail.referral_income_direct.pending_income
                                ? utils.formatBalanceToString(
                                    Number(referralDetail.referral_income_direct.pending_income),
                                    slcDecimals,
                                    CRYPTO_MIN_FRACTION,
                                    CRYPTO_MAX_FRACTION,
                                  )
                                : '0.00'}
                              &nbsp;SLC
                            </span>
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} zeroMinWidth className={classes.additionalInfoWrapper}>
                        <Grid item xs={6} zeroMinWidth>
                          <FormLabel className={classes.labels}>
                            MY DIRECT AFFILIATE INCOME
                            <LightTooltip
                              className={classes.tooltip}
                              title={
                                <div>
                                  Total BV that you earned from {referral.first_name}{' '}
                                  {referral.last_name} direct affiliates.
                                </div>
                              }
                            >
                              <HelpOutlineIcon />
                            </LightTooltip>
                          </FormLabel>
                        </Grid>{' '}
                        <Grid item xs={6} zeroMinWidth>
                          <Typography className={classes.mainLabel} component="p">
                            {referralDetail.my_income_from_referral_direct.completed_income
                              ? utils.calculateValue(
                                  Number(
                                    referralDetail.my_income_from_referral_direct.completed_income,
                                  ),
                                  7,
                                  slcEurVal,
                                )
                              : '0.00'}
                            <span>
                              &nbsp;/&nbsp;
                              {referralDetail.my_income_from_referral_direct.pending_income
                                ? utils.calculateValue(
                                    Number(
                                      referralDetail.my_income_from_referral_direct.pending_income,
                                    ),
                                    7,
                                    slcEurVal,
                                  )
                                : '0.00'}{' '}
                              EUR
                            </span>
                          </Typography>
                          <Typography
                            noWrap
                            component="p"
                            className={classes.transactionDetailsValues}
                          >
                            {referralDetail.my_income_from_referral_direct.completed_income
                              ? utils.formatBalanceToString(
                                  Number(
                                    referralDetail.my_income_from_referral_direct.completed_income,
                                  ),
                                  slcDecimals,
                                  CRYPTO_MIN_FRACTION,
                                  CRYPTO_MAX_FRACTION,
                                )
                              : '0.00'}

                            <span>
                              &nbsp;/&nbsp;
                              {referralDetail.my_income_from_referral_direct.pending_income
                                ? utils.formatBalanceToString(
                                    Number(
                                      referralDetail.my_income_from_referral_direct.pending_income,
                                    ),
                                    slcDecimals,
                                    CRYPTO_MIN_FRACTION,
                                    CRYPTO_MAX_FRACTION,
                                  )
                                : '0.00'}
                              &nbsp;SLC
                            </span>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6} zeroMinWidth>
                      <Grid item xs={12} zeroMinWidth style={{ display: 'flex' }}>
                        <Grid item xs={6} zeroMinWidth>
                          <FormLabel className={classes.labels}>Upline affiliates</FormLabel>
                          {(!isEmpty(referralDetail.path) && (
                            <List
                              dense
                              disablePadding
                              component="nav"
                              className={classes.root}
                              aria-label="contacts"
                            >
                              {Object.values(referralDetail.path).map(
                                ({ email, id }: any, index) => (
                                  <ListItem
                                    key={id}
                                    onClick={() => {
                                      clearAllFilters();
                                      dispatch({
                                        type: 'affiliate/toggle_filter',
                                        payload: { filterType: 'searchInput', value: [email] },
                                      });
                                      dispatch({
                                        type: 'affiliate/get_referral_tree',
                                        payload: { email },
                                      });
                                    }}
                                    button
                                    disabled={email === user.account.email}
                                  >
                                    <ListItemText
                                      className={classes.listName}
                                      primary={`${email} ${
                                        index === Object.keys(referralDetail.path).length - 1
                                          ? '(Direct)'
                                          : ''
                                      }`}
                                    />
                                  </ListItem>
                                ),
                              )}
                            </List>
                          )) || (
                            <Typography className={classes.mainLabel2} component="p">
                              No affiliates
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={6} zeroMinWidth>
                          <FormLabel className={classes.labels}>
                            Direct downline affiliates (
                            {referralDetail.children && referralDetail.children.length})
                          </FormLabel>
                          {(referralDetail.children && referralDetail.children.length !== 0 && (
                            <List
                              dense
                              disablePadding
                              component="nav"
                              className={classes.root}
                              style={{ maxHeight: 280 }}
                              aria-label="contacts"
                            >
                              {referralDetail.children.map(
                                ({ email, referral_id, shallow_children_count }: any) => (
                                  <ListItem
                                    key={referral_id}
                                    onClick={() => {
                                      clearAllFilters();
                                      dispatch({
                                        type: 'affiliate/toggle_filter',
                                        payload: { filterType: 'searchInput', value: [email] },
                                      });
                                      dispatch({
                                        type: 'affiliate/get_referral_tree',
                                        payload: { email },
                                      });
                                    }}
                                    button
                                  >
                                    <ListItemText className={classes.listName} primary={email} />
                                    {shallow_children_count > 0 && (
                                      <ListItemIcon>
                                        <AffiliateIcon />
                                      </ListItemIcon>
                                    )}
                                  </ListItem>
                                ),
                              )}
                            </List>
                          )) || (
                            <Typography className={classes.mainLabel2} component="p">
                              No affiliates
                            </Typography>
                          )}

                          <Grid
                            item
                            xs={12}
                            zeroMinWidth
                            style={{ display: 'block', marginTop: 10 }}
                          >
                            <FormLabel className={classes.labels}>
                              TOTAL DOWNLINE AFFILIATES ({referralDetail.num_of_downline_affiliates}
                              )
                            </FormLabel>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )
              )}
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
      {/*<TableRow>*/}
      {/*<TableCell*/}
      {/*style={{ paddingBottom: 0, paddingTop: 0 }}*/}
      {/*className={classes.expandableCell}*/}
      {/*colSpan={6}*/}
      {/*>*/}
      {/*<Collapse in={expanded} timeout="auto" unmountOnExit>*/}
      {/*/!*<Grid container spacing={3} className={classes.collapse}>*!/*/}
      {/*/!*<Grid item xs={8} zeroMinWidth>*!/*/}
      {/*/!*<FormLabel className={classes.labels}>Transaction ID</FormLabel>*!/*/}
      {/*/!*<Typography noWrap component="p" className={classes.transactionDetailsValues}>*!/*/}
      {/*/!*cao*!/*/}
      {/*/!*</Typography>*!/*/}
      {/*/!*</Grid>*!/*/}
      {/*/!*<Grid item xs={4}>*!/*/}
      {/*/!*<FormLabel className={classes.labels}>Status</FormLabel>*!/*/}
      {/*/!*<Typography component="p">*!/*/}
      {/*/!*<a*!/*/}
      {/*/!*target="_blank"*!/*/}
      {/*/!*href={`https://ropsten.etherscan.io/tx/`}*!/*/}
      {/*/!*className={classes.transactionStatusValue}*!/*/}
      {/*/!*>*!/*/}
      {/*/!*confirmed <img src={ExternalLink} alt="externalLink" />*!/*/}
      {/*/!*</a>*!/*/}
      {/*/!*</Typography>*!/*/}
      {/*/!*</Grid>*!/*/}
      {/*/!*</Grid>*!/*/}
      {/*<AffiliateTree referralTree={referralTree} referralId={referral.id} />*/}
      {/*</Collapse>*/}
      {/*</TableCell>*/}
      {/*</TableRow>*/}
    </>
  );
};

// export default AffiliatesTableRow;
const mapStateToProps = (state: AppState) => {
  const user = userStateSelector(state);
  const slcDecimals = getCoinDecimalsSelector('SLC')(state);
  const infoDialogState: InfoDialogState = getInfoDialogStateSelector(state);
  const is2FaEnabled = twoFactorAuthEnableSelector(state);

  return { user, slcDecimals, infoDialogState, is2FaEnabled };
};

const dispatchStateToProps = {
  toggleFilter,
  clearAllFilters: clearAllFilters,
  setTransactionHistorySelectedUser,
  setTransactionHistoryActiveTab,
  setChangeUserPasswordSelectedUser,
  isTwoFactorAuthEnabled: isTwoFactorAuthEnabled,
};

export default connect(mapStateToProps, dispatchStateToProps)(AffiliatesTableRow);
