import { all, put, takeEvery } from 'redux-saga/effects';
import LocalStorage from '../../../../../../data/LocalStorage';
import api from '../../../../../../data/repositories/admin';
import { USER_ID } from '../../../../../../utils/constants/localStorageKeys';
import { getCoinPrices } from '../../../../state/actions';
import {
  updateCurrencyRate,
  updateCurrencyRateFail,
  updateCurrencyRateSuccess,
  UPDATE_CURRENCY_RATE,
} from './actions';

export function* updateCurrencyRate$(action: ReturnType<typeof updateCurrencyRate>) {
  try {
    const userId = LocalStorage.get(USER_ID);
    const { rate, selectedCurrency } = action.payload;
    yield api.updateCurrencyRate(userId || '', rate, selectedCurrency);

    yield put(updateCurrencyRateSuccess());
    yield put(getCoinPrices());
  } catch (error) {
    yield put(updateCurrencyRateFail(error));
  }
}

export default function* () {
  yield all([takeEvery(UPDATE_CURRENCY_RATE, updateCurrencyRate$)]);
}
