import { Referral } from '../../../../../../data/repositories/affiliate/typedefs';

export type affiliateIncomeObject = {
  account_id: string;
  transaction_status: string;
  aggregated_value: number;
};

export type AffiliateInfoState = {
  code: string;
  directReferrals: Referral[];
  getCodeInProgress: boolean;
  getCodeFail: boolean;
  getCodeError: Error | null;
  getReferralsInProgress: boolean;
  getReferralsFail: boolean;
  getReferralsError: Error | null;
  incomeBalances: affiliateIncomeObject[];
};

export const initialState: AffiliateInfoState = {
  code: '',
  directReferrals: [],
  getCodeInProgress: false,
  getCodeFail: false,
  getCodeError: null,
  getReferralsInProgress: false,
  getReferralsFail: false,
  getReferralsError: null,
  incomeBalances: [],
};
