// import { Grid } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { PayloadAC } from 'typesafe-actions';
// import ExchangeCurrency from './ExchangeCurrency';
// import ExchangePackagesOptions from './ExchangePackages/ExchangePackagesOptions';

const styles = () =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      maxWidth: 1000,
      margin: 'auto',
      paddingTop: 24,
      paddingLeft: 24,
      paddingRight: 24,
      boxSizing: 'border-box',
    },
    nzm: {
      paddingLeft: '24px !important',
      paddingTop: '24px !important',
      paddingRight: '24px !important',
      marginRight: -12,
      marginLeft: -12,
      marginTop: -12,
      overflow: 'auto',
      height: 'calc(100vh - 64px)',
    },
    exchangeMaintenance: {
      marginTop: 'auto',
      fontSize: '32px',
      height: 'calc(100vh - 64px)',
      width: '100%',
      textAlign: 'center',
      alignItems: 'center',
      display: 'grid',
    },
  });

const useStyles = makeStyles(styles);

type Props = {
  setCurrentHomePage: PayloadAC<'home/set_current_home_page', string>;
};

const Exchange: React.FC<Props> = ({ setCurrentHomePage }: Props): JSX.Element => {
  useEffect(() => {
    setCurrentHomePage('exchange');
  }, []);

  const classes = useStyles();

  return (
    // <div className={classes.root}>
    <div className={classes.exchangeMaintenance}>
      The exchange page is under maintenance and will be unavailable until April 30, 2024.
    </div>

    /* <Grid container spacing={3}>
        <Grid item xs={12} md={6}>

          <ExchangeCurrency />
        </Grid>
        <Grid className={classes.nzm} xs={12} md={6} item>
          <ExchangePackagesOptions />
        </Grid>
      </Grid> */
    // </div>
  );
};

export default Exchange;
