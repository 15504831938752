import { connect } from 'react-redux';
import { AppState } from '../../../../state/initialState';
import AffiliatePremiumTable from './AffiliatePremiumTable';
import { asyncGetPremiumReferralsPaginated, asyncGetTermSavingsDetails } from './state/actions';
import {
  affiliatePremiumTableStateSelector,
  getTermSavingsDetailsSelector,
} from './state/selectors';
import { getAppliedFiltersSelector } from '../state/selectors';
import { clearAllFilters } from '../state/actions';

const mapDispatchToProps = {
  asyncGetPremiumReferralsPaginated: asyncGetPremiumReferralsPaginated.request,
  asyncGetTermSavingsDetails: asyncGetTermSavingsDetails.request,
  clearAllFilters: clearAllFilters,
};

// const mapStateToProps = (state: AppState) => {
//   const allCoins = getAllCoinsSelector(state);
//   const appliedFilters = getAppliedFiltersSelector(state);
//   return { allCoins, appliedFilters };
// };

const mapStateToProps = (state: AppState) => ({
  appliedFilters: getAppliedFiltersSelector(state),
  termSavingsDetails: getTermSavingsDetailsSelector(state),
  ...affiliatePremiumTableStateSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(AffiliatePremiumTable);
