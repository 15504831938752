import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import React, { useEffect } from 'react';
import { EmptyAC, PayloadAC } from 'typesafe-actions';
import Satellite from '../../../../../assets/satelite.svg';
import { Colors } from '../../../../../ui/style/colors';
import {
  GET_SWAP_INFO,
  IS_TRANSACTION_HASH_DIALOG_OPEN,
  SET_TRANSACTION_HASH_DIALOG_TX_ID,
} from '../state/actions';
import { TransactionHashDialogState } from '../state/initialState';

const styles = (theme: Theme) =>
  createStyles({
    dialog: {
      borderRadius: 6,
      backgroundColor: 'transparent',
      paddingLeft: 30,
      paddingRight: 30,
    },
    wrapper: {
      '@media (max-width: 600px)': {
        marginTop: 10,
      },
    },
    button: {
      marginTop: 25,
    },
    closeButton: {
      position: 'absolute',
      right: 5,
      top: 5,
      color: theme.palette.primary.light,
    },
    dialogTittleText: {
      color: `${Colors.blueCharcoal}`,
      fontSize: 18,
      fontWeight: 600,
      letterSpacing: 1.13,
    },
    dialogTitle: {
      margin: 25,
      padding: 0,
    },
    subtitle: {
      fontWeight: 600,
      marginBottom: 10,
    },
    fetchingDialog: {
      paddingBottom: 50,
      minWidth: 400,
    },
    labels: {
      fontWeight: 'bold',
      color: theme.palette.primary.light,
    },
    values: {
      color: theme.palette.primary.main,
      marginTop: 10,
    },
    img: {
      boxSizing: 'border-box',
      textAlign: 'center',
      width: '100%',
    },
    textOverflow: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      width: '100%',
      fontSize: '1rem',
    },
    swapDetails: {
      marginTop: 10,
    },
    marginTop: {
      marginTop: 10,
    },
  });
const useStyles = makeStyles(styles);

type Props = {
  transactionHashDialogState: TransactionHashDialogState;
  asyncGetSwapInfo: EmptyAC<typeof GET_SWAP_INFO>;
  isTransactionHashDialogOpen: PayloadAC<typeof IS_TRANSACTION_HASH_DIALOG_OPEN, boolean>;
  setTransactionHashDialogTxId: PayloadAC<typeof SET_TRANSACTION_HASH_DIALOG_TX_ID, string>;
};

const TransactionHashDialog: React.FC<Props> = ({
  transactionHashDialogState,
  asyncGetSwapInfo,
  isTransactionHashDialogOpen,
  setTransactionHashDialogTxId,
}: Props) => {
  const classes = useStyles();

  const { open, selectedTXHashId, swapTXHashId, swapRate, swapDate, inProgress, fail } =
    transactionHashDialogState;

  const hyperledgerURL = process.env.REACT_APP_HYPERLEDGER_EXPLORER_URL;

  useEffect(() => {
    asyncGetSwapInfo();
  }, []);

  const onExit = () => {
    isTransactionHashDialogOpen(false);
    setTransactionHashDialogTxId('');
  };

  return (
    <Dialog open={open} className={classes.dialog} TransitionProps={{ onExited: onExit }}>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Typography className={classes.dialogTittleText}>Transaction info</Typography>

        <IconButton className={classes.closeButton} onClick={onExit}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.fetchingDialog}>
        {inProgress ? (
          <Grid container item xs={12} spacing={4} justifyContent="center" alignItems="center">
            <CircularProgress color="secondary" size={50} />
          </Grid>
        ) : fail ? (
          <Box pb={3}>
            <Grid container justifyContent="center" alignItems="center" direction="column" item>
              <Grid item>
                <img src={Satellite} alt="ilustration" className={classes.img} />
              </Grid>
              <Grid item className={classes.labels}>
                Something went wrong. Failed to fetch transaction data.
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Grid container>
            <Grid item xs={12} className={classes.wrapper}>
              <Grid container justifyContent="space-between">
                <Grid item xs={12} sm={7}>
                  <Typography className={classes.labels}>Transaction Hash</Typography>
                </Grid>
                <Grid item xs={12} sm={5} className={classes.textOverflow}>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`${hyperledgerURL}?tab=transactions&transId=${selectedTXHashId}`}
                  >
                    {selectedTXHashId}
                  </a>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent="space-between"
              className={`${classes.wrapper} ${classes.marginTop}`}
            >
              <Grid item xs={12} sm={7}>
                <Typography className={classes.labels}>Swap Transaction Hash</Typography>
              </Grid>
              <Grid item xs={12} sm={5} className={classes.textOverflow}>
                {swapTXHashId ? (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`${hyperledgerURL}?tab=transactions&transId=${swapTXHashId}`}
                  >
                    {swapTXHashId}
                  </a>
                ) : (
                  'Not available at this time'
                )}
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent="space-between"
              className={`${classes.wrapper} ${classes.marginTop}`}
            >
              <Grid container item xs={12} sm={7} justifyContent="center" direction="column">
                <Grid container>
                  <Grid item xs={12}>
                    <Typography className={classes.labels}>Swap Date</Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.textOverflow}>
                    <Typography> {moment(swapDate).format('DD/MM/YYYY HH:mm')}</Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container item xs={12} sm={5} justifyContent="center" direction="column">
                <Grid container>
                  <Grid item xs={12}>
                    <Typography className={classes.labels}>Swap Rate</Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.textOverflow}>
                    <Typography>{swapRate}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container justifyContent="center">
              <Grid item xs={3}>
                <Button fullWidth onClick={onExit} className={classes.button}>
                  Ok
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default TransactionHashDialog;
