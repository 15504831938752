import { Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { EmptyAC, PayloadAC } from 'typesafe-actions';
import SacretDaterangePickerSelect from '../../../../../../../components/SacrateDaterangePickerSelect';
import SacretInputNumber from '../../../../../../../components/SacretInputNumber';
import SacretSelect from '../../../../../../../components/SacretSelect';
import {
  GetPaginatedUserTransactionHistoryRequest,
  SaveFilter,
} from '../../../../../../../data/repositories/admin/typedefs';
import { WALLET_COINS } from '../../../../../../../utils/constants/amounts';
import { getCurrencySymbolName } from '../../../../../../../utils/helper/utils';
import {
  GET_WALLET_TRANSFERS_PAGINATED,
  USER_TRANSACTION_HISTORY_CLEAR_ALL_FILTERS,
  USER_TRANSACTION_HISTORY_TOGGLE_FILTER,
} from '../../state/actions';
import AppliedFiltersChips from './AppliedFiltersChips';

const styles = (theme: Theme) =>
  createStyles({
    filter: {
      minWidth: 200,
      marginRight: 10,
    },
    'm-r': {
      marginRight: 10,
    },
    valueSlider: {},
    clearAll: {
      textTransform: 'none',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    clearAllIcon: {
      fontSize: 12,
      padding: 5,
    },
    label: {
      float: 'left',
      padding: '2px 0',
      fontSize: 12,
      color: theme.palette.primary.light,
    },
    minWidth: {
      minWidth: 236,
    },
  });

const useStyles = makeStyles(styles);

type Props = {
  toggleFilter: PayloadAC<typeof USER_TRANSACTION_HISTORY_TOGGLE_FILTER, SaveFilter>;
  appliedFilters: Map<string, string[]>;
  getWalletTransfersPaginated: PayloadAC<
    typeof GET_WALLET_TRANSFERS_PAGINATED,
    GetPaginatedUserTransactionHistoryRequest
  >;
  clearAllFilters: EmptyAC<typeof USER_TRANSACTION_HISTORY_CLEAR_ALL_FILTERS>;
  logAccountId: string;
};

const statuses = [
  { name: 'Completed', value: 'completed' },
  { name: 'Pending', value: 'pending' },
  { name: 'Escrow', value: 'escrow' },
  { name: 'Pending Escrow', value: 'pending_escrow' },
  { name: 'Timed Escrow', value: 'timed_escrow' },
];

const walletCoins = WALLET_COINS.map((coin: string) => {
  const coinSymbol = getCurrencySymbolName(coin);
  return {
    name: coinSymbol,
    value: coinSymbol,
  };
});

const transferTypes = [
  { name: 'Send', value: 'send' },
  {
    name: 'BV',
    value:
      'affiliate_percentage_reward,term_saving,term_saving_finished&exclude_tx_status=invested',
  },
  { name: 'Buy', value: 'buy' },
  { name: 'Buy product', value: 'buy_product' },
  { name: 'Buy item', value: 'store_item_buy' },
  { name: 'Sell item', value: 'store_item_sell' },
  { name: 'Deposit', value: 'deposit' },
  { name: 'Deposit receive', value: 'receive' },
  { name: 'Sale', value: 'merchant_income' },
  { name: 'Sell', value: 'sell' },
  { name: 'Transfer', value: 'transfer' },
  { name: 'Withdraw', value: 'withdraw' },
];

const WalletTransfersFilterBar: React.FC<Props> = ({
  toggleFilter,
  appliedFilters,
  clearAllFilters,
  getWalletTransfersPaginated,
  logAccountId,
}: Props): JSX.Element => {
  const classes = useStyles();

  const handleOnChangeEvent = (
    value: string[] | string | undefined,
    filterType: string,
    limit?: string,
  ) => {
    const object: any = {};
    appliedFilters.forEach((value, key) => {
      object[key] = value;
    });

    let valueRange;

    switch (limit) {
      case 'Min':
        valueRange = [value, object.valueRange ? object.valueRange[1] : '10000000000'];
        const maxValue = valueRange[1] === '10000000000' ? '' : valueRange[1];

        getWalletTransfersPaginated({
          logAccountId,
          page: 0,
          perPage: 10,
          appliedFilters: { ...object, [filterType]: valueRange },
        });
        value = value === '' ? '' : value;
        toggleFilter({
          filterType: 'valueRange',
          minValue: value,
          maxValue: maxValue,
          value: valueRange,
        });
        break;
      case 'Max':
        valueRange = [object.valueRange ? object.valueRange[0] : '-10000000000', value];
        const minValue = valueRange[0] === '-10000000000' ? '' : valueRange[0];

        getWalletTransfersPaginated({
          logAccountId,
          page: 0,
          perPage: 10,
          appliedFilters: { ...object, [filterType]: valueRange },
        });
        value = value === '' ? '' : value;

        toggleFilter({
          filterType: 'valueRange',
          maxValue: value,
          minValue: minValue,
          value: valueRange,
        });
        break;
      default:
        getWalletTransfersPaginated({
          logAccountId,
          page: 0,
          perPage: 10,
          appliedFilters: { ...object, [filterType]: value },
        });

        toggleFilter({ filterType, value });
        break;
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid xs={12} sm="auto" item className={classes.filter}>
        <SacretSelect
          items={statuses}
          label="Status"
          onChange={(value: string[]) => handleOnChangeEvent(value, 'status')}
          stateFilterValue={appliedFilters.get('status')}
        />
      </Grid>
      <Grid xs={12} sm="auto" item className={classes.filter}>
        <SacretSelect
          items={transferTypes}
          label="Transfer type"
          onChange={(value: string[]) => handleOnChangeEvent(value, 'transferType')}
          stateFilterValue={appliedFilters.get('transferType')}
        />
      </Grid>

      <Grid xs={12} sm="auto" item className={`${classes.filter} ${classes.minWidth}`}>
        <SacretDaterangePickerSelect
          label="Choose a date"
          onChange={(value: string[]) => handleOnChangeEvent(value, 'dateFilter')}
          stateFilterValue={appliedFilters.get('dateFilter')}
        />
      </Grid>
      <Grid xs={12} sm="auto" item className={`${classes.filter} ${classes.minWidth}`}>
        <SacretSelect
          items={walletCoins}
          label="Currency"
          multiple={false}
          onChange={(value: string[]) => handleOnChangeEvent(value, 'coinSymbol')}
          stateFilterValue={appliedFilters.get('coinSymbol')}
        />
      </Grid>
      <Grid item className={classes['m-r']}>
        <SacretInputNumber
          currency=""
          limit="Min"
          key="minK"
          disabled={!appliedFilters.get('coinSymbol')}
          disabledTextExplanation="Please, select a currency for filtering."
          showIcon={false}
          onChange={(value: string) => handleOnChangeEvent(value, 'valueRange', 'Min')}
          stateValue={appliedFilters.get('minValue')}
        />
      </Grid>
      <Grid item className={classes['m-r']}>
        <SacretInputNumber
          currency=""
          limit="Max"
          key="maxK"
          showIcon={false}
          disabled={!appliedFilters.get('coinSymbol')}
          disabledTextExplanation="Please, select a currency for filtering."
          onChange={(value: string) => {
            handleOnChangeEvent(value, 'valueRange', 'Max');
          }}
          stateValue={appliedFilters.get('maxValue')}
        />
      </Grid>

      <AppliedFiltersChips
        logAccountId={logAccountId}
        clearAllFilters={clearAllFilters}
        stateAppliedFilters={appliedFilters}
        toggleFilter={toggleFilter}
      />
    </Grid>
  );
};

export default WalletTransfersFilterBar;
