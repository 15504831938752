import { createStyles, FormLabel, Grid, Link, makeStyles, Typography } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import crowdfunding from '../../../assets/crowdfunding.png';
import { Colors } from '../../../ui/style/colors';
import balanceUtils from '../../../utils/balanceUtils';
import {
  WIRE_EUR_DECIMALS,
  WIRE_EUR_MAX_FRACTION,
  WIRE_EUR_MIN_FRACTION,
} from '../../../utils/constants/amounts';

const useStyles = makeStyles(() =>
  createStyles({
    labels: {
      fontSize: 14,
      color: `${Colors.wildBlueYonder}`,
    },
    primaryLabel: {
      fontSize: 14,
    },
    coverImg: {
      maxWidth: '100%',
      maxHeight: 150,
    },
    description: {
      width: '100%',
      paddingTop: '1rem',
    },
    productDescriptionColumn: {
      paddingLeft: '1rem',
    },
  }),
);

const ProductDetails = ({ product, hideDescription = false }: any) => {
  const classes = useStyles();
  const handleImageError = (e: React.ChangeEvent<HTMLImageElement>) => {
    e.target.onerror = null;
    e.target.src = crowdfunding;
  };
  return (
    <Grid container>
      <Grid item xs={12} sm={6}>
        <Grid container>
          <Grid item xs={6} zeroMinWidth>
            <FormLabel className={classes.labels}>Created</FormLabel>
          </Grid>
          <Grid item xs={6} zeroMinWidth>
            <Typography className={classes.primaryLabel} component="p">
              {moment(product.product.created_at).format('L')}
            </Typography>
          </Grid>

          {/*pre sale time row*/}
          <Grid item xs={6} zeroMinWidth>
            <FormLabel className={classes.labels}>Pre sale time</FormLabel>
          </Grid>
          <Grid item xs={6} zeroMinWidth>
            <Typography className={classes.primaryLabel} component="p">
              {moment(product.product.pre_ico_period_start).format('L')} -{' '}
              {moment(product.product.pre_ico_period_end).format('L')}
            </Typography>
          </Grid>

          {/*sale time row*/}
          <Grid item xs={6} zeroMinWidth>
            <FormLabel className={classes.labels}>Sale time</FormLabel>
          </Grid>
          <Grid item xs={6} zeroMinWidth>
            <Typography className={classes.primaryLabel} component="p">
              {moment(product.product.ico_period_start).format('L')} -{' '}
              {moment(product.product.ico_period_end).format('L')}
            </Typography>
          </Grid>

          {/*min goal row*/}
          <Grid item xs={6} zeroMinWidth>
            <FormLabel className={classes.labels}>Minimum goal</FormLabel>
          </Grid>
          <Grid item xs={6} zeroMinWidth>
            <Typography className={classes.primaryLabel} component="p">
              {balanceUtils.formatBalanceToString(
                product.product.soft_cap || 0,
                WIRE_EUR_DECIMALS,
                WIRE_EUR_MIN_FRACTION,
                WIRE_EUR_MAX_FRACTION,
              )}{' '}
              EUR
            </Typography>
          </Grid>

          {/*max goal row*/}
          <Grid item xs={6} zeroMinWidth>
            <FormLabel className={classes.labels}>Maximum goal</FormLabel>
          </Grid>
          <Grid item xs={6} zeroMinWidth>
            <Typography className={classes.primaryLabel} component="p">
              {balanceUtils.formatBalanceToString(
                product.product.hard_cap || 0,
                WIRE_EUR_DECIMALS,
                WIRE_EUR_MIN_FRACTION,
                WIRE_EUR_MAX_FRACTION,
              )}{' '}
              EUR
            </Typography>
          </Grid>

          {/*min buy row*/}
          <Grid item xs={6} zeroMinWidth>
            <FormLabel className={classes.labels}>Minimum buy</FormLabel>
          </Grid>
          <Grid item xs={6} zeroMinWidth>
            <Typography className={classes.primaryLabel} component="p">
              {balanceUtils.formatBalanceToString(
                product.product.buy_limit_low,
                WIRE_EUR_DECIMALS,
                WIRE_EUR_MIN_FRACTION,
                WIRE_EUR_MAX_FRACTION,
              )}{' '}
              EUR
            </Typography>
          </Grid>

          {/*max buy row*/}
          <Grid item xs={6} zeroMinWidth>
            <FormLabel className={classes.labels}>Maximum buy</FormLabel>
          </Grid>
          <Grid item xs={6} zeroMinWidth>
            <Typography className={classes.primaryLabel} component="p">
              {balanceUtils.formatBalanceToString(
                product.product.buy_limit_high,
                WIRE_EUR_DECIMALS,
                WIRE_EUR_MIN_FRACTION,
                WIRE_EUR_MAX_FRACTION,
              )}{' '}
              EUR
            </Typography>
          </Grid>

          {/*documentation row*/}
          <Grid item xs={12} style={{ marginTop: '1rem' }} zeroMinWidth>
            {product.product.white_paper.String && (
              <Link href={product.product.white_paper.String} target="_blank">
                Documentation
              </Link>
            )}
          </Grid>

          {/*website row*/}
          <Grid item style={{ marginTop: '1rem' }} xs={12} zeroMinWidth>
            {product.product.website.String ? (
              <Link href={product.product.website.String} target="_blank">
                Website
              </Link>
            ) : null}
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={6} zeroMinWidth className={classes.productDescriptionColumn}>
        <img
          className={classes.coverImg}
          alt={'Product cover'}
          src={product.product.photo.String}
          onError={handleImageError}
        />
      </Grid>
      {!hideDescription && (
        <Grid container>
          <div className={classes.description}>{product.product.description}</div>
        </Grid>
      )}
    </Grid>
  );
};

export default ProductDetails;
