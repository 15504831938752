import { connect } from 'react-redux';
import { AppState } from '../../../../state/initialState';
import OrdersTable from './OrdersTable';
import { asyncGetMerchantsReferralsPaginated } from './state/actions';
import { affiliateMerchantsTableStateSelector, getAppliedFiltersSelector } from './state/selectors';
import { userStateSelector } from '../../../../state/selectors';

const mapDispatchToProps = {
  asyncGetMerchantsReferralsPaginated: asyncGetMerchantsReferralsPaginated.request,
};

const mapStateToProps = (state: AppState) => ({
  user: userStateSelector(state),
  ...affiliateMerchantsTableStateSelector(state),
  appliedFilters: getAppliedFiltersSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrdersTable);
