import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';
import { EmptyAC } from 'typesafe-actions';
import WithdrawIcon from '../../../../../assets/deposit.svg';
import ModalState from '../../../../../components/ModalState';
import { Colors } from '../../../../../ui/style/colors';
import { IS_TWO_FACTOR_AUTH_ENABLED } from '../../Settings/SettingsTwoFactorAuth/state/actions';
import AffilaiteAddDeduct from '../AffiliateAddDeduct';
import AffiliateAddDeduct2fa from '../AffiliateAddDeduct2fa';
import { dialogState } from '../AffiliateTable/state/initialState';
import { CLEAR_DIALOG_STATE } from '../state/actions';

const styles = (theme: Theme) =>
  createStyles({
    dialog: {
      height: 'center',
      width: 'center',
      borderRadius: 6,
      backgroundColor: 'transparent',
      paddingLeft: 30,
      paddingRight: 30,
      minWidth: 400,
    },
    button: {
      marginTop: 30,
      marginBottom: 30,
    },
    closeButton: {
      position: 'absolute',
      right: 5,
      top: 5,
      color: theme.palette.primary.light,
    },
    dialogTittleText: {
      color: `${Colors.blueCharcoal}`,
      fontSize: 18,
      fontWeight: 600,
      letterSpacing: 1.13,
      display: 'flex',
      alignItems: 'center',
    },
    dialogTitle: {
      marginTop: 25,
      marginLeft: 25,
      marginRight: 25,
      padding: 0,
    },
    depositIcon: {
      marginRight: 20,
    },
    withdraw: {
      textAlign: 'center',
      marginBottom: 30,
    },
    successDialog: {
      paddingBottom: 50,
      minWidth: 400,
    },
    progressBar: {
      height: 6,
      borderRadius: '0 0 3px 3px ',
    },
    linearProgressOverride: {
      borderRadius: 3,
    },
  });
const useStyles = makeStyles(styles);

type Props = {
  isTwoFactorAuthEnabled: EmptyAC<typeof IS_TWO_FACTOR_AUTH_ENABLED>;
  clearDialogState: EmptyAC<typeof CLEAR_DIALOG_STATE>;
  dialogState: dialogState;
};

const AffiliateAddDeductDialog: React.FC<Props> = (props: Props) => {
  const classes = useStyles();

  const { dialogState, isTwoFactorAuthEnabled, clearDialogState } = props;
  const { isDialogOpen, referralUser, step, success } = dialogState;
  const [isEscrow, setIsEscrow] = useState(false);

  useEffect(() => {
    clearDialogState();
    isTwoFactorAuthEnabled();
  }, []);

  const onExit = () => {
    clearDialogState();
    setIsEscrow(false);
  };

  const isSuccess = step === 2 && success;

  return (
    <Dialog open={isDialogOpen} className={classes.dialog} TransitionProps={{ onExited: onExit }}>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <div className={classes.withdraw}>
          {!isSuccess && (
            <Typography className={classes.dialogTittleText}>
              <img src={WithdrawIcon} className={classes.depositIcon} />
              {referralUser.send_to_user
                ? isEscrow
                  ? 'Add SLC to escrow'
                  : 'Add SLC to balance'
                : 'Deduct SLC'}
            </Typography>
          )}

          {onExit ? (
            <IconButton className={classes.closeButton} onClick={onExit}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </div>
      </DialogTitle>

      <DialogContent className={classes.successDialog}>
        {step === 0 && <AffilaiteAddDeduct isEscrow={isEscrow} onChange={setIsEscrow} />}
        {step === 1 && <AffiliateAddDeduct2fa />}

        {isSuccess && (
          <ModalState
            onExit={() => onExit()}
            message="Transaction completed!"
            variant="success"
            showCloseButton={false}
          />
        )}
      </DialogContent>
      {
        <LinearProgress
          variant="determinate"
          value={((step + 1) / 3) * 100}
          className={classes.progressBar}
          color="secondary"
          classes={{ barColorSecondary: classes.linearProgressOverride }}
        />
      }
    </Dialog>
  );
};

export default AffiliateAddDeductDialog;
