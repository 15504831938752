import { createReducer } from 'typesafe-actions';
import { handleMinMaxRange } from '../../../../../../utils/helper/utils';
import {
  EXPORT_PRODUCTS_PURCHASE_HISTORY,
  EXPORT_PRODUCTS_PURCHASE_HISTORY_FAIL,
  EXPORT_PRODUCTS_PURCHASE_HISTORY_SUCCESS,
  GET_PRODUCTS_PURCHASE_HISTORY_PAGINATED,
  GET_PRODUCTS_PURCHASE_HISTORY_PAGINATED_FAIL,
  GET_PRODUCTS_PURCHASE_HISTORY_PAGINATED_SUCCESS,
  PRODUCTS_PURCHASE_HISTORY_CLEAR_ALL_FILTERS,
  PRODUCTS_PURCHASE_HISTORY_TOGGLE_FILTER,
  SET_PRODUCTS_PURCHASE_HISTORY_TABLE_PAGE,
} from './actions';
import { initialState } from './initialState';

export default createReducer(initialState, {
  [GET_PRODUCTS_PURCHASE_HISTORY_PAGINATED]: state => ({
    ...state,
    inProgress: true,
    fail: false,
  }),
  [GET_PRODUCTS_PURCHASE_HISTORY_PAGINATED_SUCCESS]: (state, { payload }) => ({
    ...state,
    productsPurchaseHistory: payload.crowdfunding_purchases,
    totalCount: payload.purchase_count,
    inProgress: false,
    fail: false,
  }),
  [GET_PRODUCTS_PURCHASE_HISTORY_PAGINATED_FAIL]: (state, { payload }) => ({
    ...state,
    inProgress: false,
    fail: true,
    error: payload,
  }),
  [SET_PRODUCTS_PURCHASE_HISTORY_TABLE_PAGE]: (state, { payload }) => ({
    ...state,
    page: payload,
  }),
  [PRODUCTS_PURCHASE_HISTORY_TOGGLE_FILTER]: (state, { payload }) => {
    let objectRange = {
      minValue: 0,
      maxValue: 0,
    };
    if (payload.filterType === 'valueRange') {
      objectRange = handleMinMaxRange(state, payload);
      if (!objectRange['maxValue'] && !objectRange['minValue']) {
        payload.value = undefined;
      }
    }

    return {
      ...state,
      appliedFilters: {
        ...state.appliedFilters,
        [payload.filterType]: payload.value,
        ...objectRange,
      },
    };
  },
  [PRODUCTS_PURCHASE_HISTORY_CLEAR_ALL_FILTERS]: state => ({
    ...state,
    appliedFilters: new Map(),
  }),
  [EXPORT_PRODUCTS_PURCHASE_HISTORY]: state => ({
    ...state,
    exportInProgress: true,
  }),
  [EXPORT_PRODUCTS_PURCHASE_HISTORY_SUCCESS]: state => ({
    ...state,
    exportInProgress: false,
  }),
  [EXPORT_PRODUCTS_PURCHASE_HISTORY_FAIL]: (state, { payload }) => ({
    ...state,
    exportInProgress: false,
    error: payload,
  }),
});
