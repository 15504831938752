import { AdminState, initialState as admin } from '../HomeContent/Admin/state/initialState';
import {
  AffiliateState,
  initialState as affiliate,
} from '../HomeContent/Affiliate/state/initialState';
import {
  DashboardState,
  initialState as dashboard,
} from '../HomeContent/Dashboard/state/initialState';
import {
  EarningsState,
  initialState as earnings,
} from '../HomeContent/Earnings/state/initialState';
import {
  ExchangeState,
  initialState as exchange,
} from '../HomeContent/Exchange/state/inititalState';
import {
  InfoDialogState,
  initialState as infoDialog,
} from '../HomeContent/InfoDialog/state/initialState';
import { initialState as invites, InvitesState } from '../HomeContent/Invites/state/initialState';
import { initialState as orders, OrdersState } from '../HomeContent/Orders/state/initialState';
import { initialState as product, ProductState } from '../HomeContent/Product/state/initialState';
import {
  initialState as settings,
  SettingsState,
} from '../HomeContent/Settings/state/initialState';
import {
  initialState as transactions,
  TransactionsState,
} from '../HomeContent/Transactions/state/initialState';
import { initialState as wallets, WalletState } from '../HomeContent/Wallet/state/initialState';
import { DrawerState, initialState as drawer } from '../HomeDrawer/state/initialState';
import { CommonState, initialState as common } from './common/initialState';

export type HomeState = {
  common: CommonState;
  infoDialog: InfoDialogState;
  dashboard: DashboardState;
  wallets: WalletState;
  settings: SettingsState;
  affiliate: AffiliateState;
  transactions: TransactionsState;
  exchange: ExchangeState;
  earnings: EarningsState;
  orders: OrdersState;
  product: ProductState;
  invites: InvitesState;
  admin: AdminState;
  drawer: DrawerState;
};

export const initialState: HomeState = {
  common,
  infoDialog,
  dashboard,
  wallets,
  settings,
  affiliate,
  transactions,
  exchange,
  earnings,
  orders,
  product,
  invites,
  admin,
  drawer,
};
