import { CircularProgress, Dialog, DialogContent, IconButton, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import CustomButtonPrimary from '../../components/CustomButtons/CustomButtonPrimary';
import ModalState from '../../components/ModalState';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { Colors } from '../../ui/style/colors';
import { BUY_WALLET, CLOSE_BUY_WALLET_MODAL, GET_WALLET_BALANCE } from '../../App/state/actions';
import { AppState } from '../../App/state/initialState';
import { buyWalletModalStateSelector } from '../../App/Home/HomeContent/Wallet/state/selectors';
import { capitalizeFirstLetter } from '../../utils/helper/strings';

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    height: 'center',
    width: 'center',
    borderRadius: 6,
  },
  dialogContent: {
    width: 400,
    maxWidth: '100%',
    marginTop: 15,
    padding: 15,
  },
  closeButton: {
    position: 'absolute',
    right: 5,
    top: 5,
    color: theme.palette.primary.light,
  },
  title: {
    textAlign: 'center',
    color: `${Colors.blueCharcoal}`,
    fontSize: 18,
    fontWeight: 600,
    margin: '20px 0',
  },
  centeredContent: {
    textAlign: 'center',
  },
  input: {
    margin: '15px 0',
    width: '100%',
    height: 50,
  },
  inputSymbol: {
    width: 51,
    height: '100%',
    borderRadius: '0 4px 4px 0',
    backgroundColor: `${theme.palette.secondary.light}`,
    color: `${Colors.white}`,
    fontSize: 12,
    fontWeight: 600,
  },
  inputSymbolValue: {
    position: 'relative',
    float: 'left',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  button: {
    display: 'block',
    fontSize: 14,
    margin: '20px auto',
    width: 150,
  },
}));

type Props = {
  symbol: string;
  euroBalance: string;
  walletPrice: number | undefined;
};

const getBuyWalletModalState = () => {
  const buyWalletState = useSelector((state: AppState) => buyWalletModalStateSelector(state));
  return buyWalletState;
};

const BuyWalletModal = ({ symbol, euroBalance, walletPrice }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { inProgress, fail, error, success, isOpen } = getBuyWalletModalState();

  const submit = () => {
    dispatch({ type: BUY_WALLET, payload: `ETHWALLET` });
  };

  const closeModal = () => dispatch({ type: CLOSE_BUY_WALLET_MODAL });

  const Loader = (
    <DialogContent className={`${classes.dialogContent} ${classes.centeredContent}`}>
      <CircularProgress size={50} color="secondary" />
    </DialogContent>
  );

  if (walletPrice && walletPrice > Number(euroBalance) / 100) {
    return (
      <Dialog open={isOpen}>
        <ModalState variant="failed" onExit={closeModal} message="You don't have enough funds" />
      </Dialog>
    );
  }

  if (fail) {
    return (
      <Dialog open={isOpen}>
        <ModalState
          variant="failed"
          onExit={closeModal}
          message={capitalizeFirstLetter(String(error))}
        />
      </Dialog>
    );
  }

  if (success) {
    return (
      <Dialog open={isOpen}>
        <ModalState
          variant="success"
          onExit={() => dispatch({ type: GET_WALLET_BALANCE })}
          message="You have successfully completed your purchase"
        />
      </Dialog>
    );
  }

  return (
    <Dialog open={isOpen} className={classes.dialog}>
      <IconButton className={classes.closeButton} onClick={closeModal}>
        <CloseIcon />
      </IconButton>
      {inProgress ? (
        Loader
      ) : (
        <DialogContent className={classes.dialogContent}>
          <Typography className={classes.title}>Buy {symbol} wallet</Typography>

          <Typography className={classes.centeredContent}>
            You will be charged {walletPrice} euros.
          </Typography>

          <CustomButtonPrimary className={classes.button} variant="contained" onClick={submit}>
            Buy
          </CustomButtonPrimary>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default BuyWalletModal;
