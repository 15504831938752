import { connect } from 'react-redux';
import { AppState } from '../../../../state/initialState';
import { getAllCoinsSelector } from '../../Wallet/state/selectors';
import { clearAllFilters, toggleFilter } from '../state/actions';
import { getAppliedFiltersSelector } from '../state/selectors';
import TransactionsFilterBar from './TransactionsFilterBar';
import { asyncGetMerchantsReferralsPaginated } from '../../Affiliate/AffiliateMerchantsTable/state/actions';

const mapStateToProps = (state: AppState) => {
  const allCoins = getAllCoinsSelector(state);
  const appliedFilters = getAppliedFiltersSelector(state);
  return { allCoins, appliedFilters };
};

const dispatchStateToProps = {
  asyncGetMerchantsReferralsPaginated: asyncGetMerchantsReferralsPaginated.request,
  toggleFilter,
  clearAllFilters: clearAllFilters,
};

export default connect(mapStateToProps, dispatchStateToProps)(TransactionsFilterBar);
