/* eslint-disable @typescript-eslint/indent */
import { CircularProgress } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { isEmpty, size } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { PayloadAC } from 'typesafe-actions';
import Satelite from '../../../../../assets/satelite.svg';
import AffiliatesTable, { HeaderProps } from '../../../../../components/AffiliatesTable/';
import { AffiliateTree } from '../../../../../components/AffiliateTree';
import SacretEmpty from '../../../../../components/SacretEmpty';
import {
  GetReferralsPaginatedRequest,
  Referral,
} from '../../../../../data/repositories/affiliate/typedefs';
import { roles } from '../../../../../utils/constants/roles';
import { RESET_USER_KYC_STATUS } from './state/actions';
import { AffiliateTableState, ChangeUserStatusRequest, referralUser } from './state/initialState';

const styles = (theme: Theme) =>
  createStyles({
    myAffiliatesList: {
      color: theme.palette.secondary.light,
      fontSize: 18,
      fontWeight: 800,
      paddingBottom: 30,
    },
    root: {},
    circularProgress: {
      position: 'absolute',
      left: '50%',
      top: '50%',
    },
    adminSearchWidget: {
      marginTop: 50,
    },
  });

const useStyles = makeStyles(styles);

type Props = AffiliateTableState & {
  asyncGetReferralsPaginated: PayloadAC<
    'affiliate/get_referrals_paginated',
    GetReferralsPaginatedRequest
  >;
  anyFilters: boolean;
  inProgress: boolean;
  inProgressRefferal: boolean;
  appliedFilters: any;
  referralDetail: any;
  referralDetailInProgress: boolean;
  isAddDeductDialogOpen: PayloadAC<'affiliate/is_add_deduct_dialog_open', boolean>;
  isFullProfileDialogOpen: PayloadAC<'affiliate/is_full_profile_dialog_open', boolean>;
  asyncChangeUserStatus: PayloadAC<'affiliate/change_user_status', ChangeUserStatusRequest>;
  asyncToggleAdminRole: PayloadAC<'affiliate/toggle_admin_role', boolean>;
  setUserForDialog: PayloadAC<'affiliate/set_user_for_dialog', referralUser>;
  asyncResetUserKYCStatus: PayloadAC<typeof RESET_USER_KYC_STATUS, string>;
  user: any;
  isAdminSearchWidget?: boolean;
};
const adminSearchWidgetHeaders: HeaderProps[] = [
  { name: 'Email Address', align: 'left' },
  { name: 'Name', align: 'left' },
  { name: 'Options', align: 'right' },
];

const headersFirstPart: HeaderProps[] = [
  { name: 'Email Address', align: 'left' },
  { name: 'Name', align: 'left' },
  { name: 'Tx Proof', align: 'left' },
  { name: 'Status', align: 'left' },
];

const adminHeadersPart: HeaderProps[] = [
  { name: 'BitGo wallet', align: 'left' },
  { name: 'KYC status', align: 'left' },
];

const headersSecondPart: HeaderProps[] = [
  { name: 'Registration date', align: 'right' },
  { name: 'Level', align: 'right' },
  { name: 'Options', align: 'right' },
];

const AffiliateTable: React.FC<Props> = (props: Props): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    referrals,
    referralTree,
    asyncGetReferralsPaginated,
    inProgressRefferal,
    level,
    breadcrumbs,
    anyFilters,
    referralsTreeInProgress,
    stats: { totalReferrals, paginatedReferrals },
    page,
    appliedFilters,
    isAddDeductDialogOpen,
    isFullProfileDialogOpen,
    setUserForDialog,
    asyncChangeUserStatus,
    asyncToggleAdminRole,
    asyncResetUserKYCStatus,
    referralDetail,
    referralDetailInProgress,
    user,
    isAdminSearchWidget,
  } = props;
  const history = useHistory();
  const [emailFromLocationHistory, setEmailFromLocationHistory] = useState('');

  useEffect(() => {
    if (history.location.state && (history.location.state as any).email) {
      setEmailFromLocationHistory((history.location.state as any).email);
    }

    if (!totalReferrals) {
      asyncGetReferralsPaginated({ page: page, perPage: 10 });
    }
  }, []);

  useEffect(() => {
    if (size(referrals) > 1 && emailFromLocationHistory) {
      dispatch({
        type: 'affiliate/toggle_filter',
        payload: { filterType: 'searchInput', value: [emailFromLocationHistory] },
      });
      dispatch({
        type: 'affiliate/get_referral_tree',
        payload: { email: emailFromLocationHistory },
      });

      // clear email from state for refresh page and clearAllFilters action
      setEmailFromLocationHistory('');
      history.replace('/affiliate', {});
    }
  }, [referrals]);

  const isEmptyR = size(referrals) == 0;

  return (
    <div>
      {(inProgressRefferal && !referrals) || (isEmpty(referralTree) && referralsTreeInProgress) ? (
        <CircularProgress color="secondary" className={classes.circularProgress} size={60} />
      ) : (
        <div>
          {!isEmptyR && isEmpty(referralTree) ? (
            <div className={classes.root}>
              <AffiliatesTable
                referrals={referrals ? referrals : new Map<string, Referral>()}
                headers={
                  isAdminSearchWidget
                    ? adminSearchWidgetHeaders
                    : [
                        ...headersFirstPart,
                        ...(roles.isOwner(user.roles) ? adminHeadersPart : []),
                        ...headersSecondPart,
                      ]
                }
                appliedFilters={appliedFilters}
                hasPagination={!anyFilters}
                referralTree={referralTree}
                totalReferrals={paginatedReferrals ? paginatedReferrals : 0}
                page={page}
                inProgress={inProgressRefferal}
                isAddDeductDialogOpen={isAddDeductDialogOpen}
                isFullProfileDialogOpen={isFullProfileDialogOpen}
                setUserForDialog={setUserForDialog}
                referralDetail={referralDetail}
                referralDetailInProgress={referralDetailInProgress}
                asyncChangeUserStatus={asyncChangeUserStatus}
                asyncToggleAdminRole={asyncToggleAdminRole}
                isAdminSearchWidget={isAdminSearchWidget}
                asyncResetUserKYCStatus={asyncResetUserKYCStatus}
              />
            </div>
          ) : !isEmpty(referralTree) ? (
            <AffiliateTree referralTree={referralTree} level={level} breadcrumbs={breadcrumbs} />
          ) : isAdminSearchWidget ? (
            <SacretEmpty
              image={Satelite}
              title="Yikes, no users found"
              className={classes.adminSearchWidget}
            />
          ) : (
            <SacretEmpty
              image={Satelite}
              title="Yikes, you don't have any affiliates"
              text="Feel free to share your affiliate code with friends and earn reward for each purchase they make through the system. That's not all, you will also earn reward when they recommend SLAFF to their friends. Lifetime commission, for all their future payments, no strings attached."
              hasButton={false}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default AffiliateTable;
