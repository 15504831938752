import { CircularProgress, Grid } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { PayloadAC } from 'typesafe-actions';
import { NavTab, NavTabContent, NavTabs } from '../../../../components/NavTabs';
import { User } from '../../../../data/typedefs/user';
import { Colors } from '../../../../ui/style/colors';
import { roles } from '../../../../utils/constants/roles';
import { APP_DASHBOARD_ROUTE } from '../../../../utils/constants/routes';
import { SET_CURRENT_HOME_PAGE } from '../../state/actions';
import { GET_WALLET_BALANCE } from '../Wallet/state/actions';
import ChangeUserPassword from './ChangeUserPassword';
import ProductsPurchaseHistory from './ProductsPurchaseHistory';
import TransactionGrouping from './TransactionGrouping';
import UserEarningsTable from './UserEarningsTable';
import UserTransactionHistory from './UserTransactionHistory';

const styles = () =>
  createStyles({
    main: {
      padding: 24,
      paddingTop: 30,
      flexDirection: 'column',
      position: 'relative',
    },
    wrapper: {
      backgroundColor: `${Colors.whiteLilac}`,
    },
    tabPanel: {
      marginBottom: 30,
      width: '100%',
    },
  });

const useStyles = makeStyles(styles);
type Props = {
  setCurrentHomePage: PayloadAC<typeof SET_CURRENT_HOME_PAGE, string>;
  user: User;
};
const Admin: React.FC<Props> = ({ setCurrentHomePage, user }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    dispatch({ type: GET_WALLET_BALANCE });
    setCurrentHomePage('admin');
  }, []);
  useEffect(() => {
    if (user.roles && !roles.isOwner(user.roles)) {
      history.push(APP_DASHBOARD_ROUTE);
    }
  }, [user.roles]);
  if (!user.roles) {
    return <CircularProgress />;
  }

  const handleTabChange = (event: React.ChangeEvent<Record<string, unknown>>, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <div className={classes.wrapper}>
      <Grid container direction="column" className={classes.main}>
        <Grid item className={classes.tabPanel}>
          <NavTabs
            variant="scrollable"
            value={activeTab}
            onChange={handleTabChange}
            aria-label="Admin"
          >
            <NavTab label=" User Earnings" />
            <NavTab label=" Transaction Grouping" />
            <NavTab label=" Products Purchase History" />
            <NavTab label="  User Transaction History" />
            <NavTab label="Change Password" />
          </NavTabs>
        </Grid>

        <Grid item xs={12}>
          <NavTabContent value={activeTab} index={0}>
            <UserEarningsTable />{' '}
          </NavTabContent>
          <NavTabContent value={activeTab} index={1}>
            <TransactionGrouping />{' '}
          </NavTabContent>
          <NavTabContent value={activeTab} index={2}>
            <ProductsPurchaseHistory />{' '}
          </NavTabContent>
          <NavTabContent value={activeTab} index={3}>
            <UserTransactionHistory />{' '}
          </NavTabContent>
          <NavTabContent value={activeTab} index={4}>
            {' '}
            <ChangeUserPassword />
          </NavTabContent>
        </Grid>
      </Grid>
    </div>
  );
};

export default Admin;
