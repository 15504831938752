import { getCurrencySymbolName } from '../helper/utils';
export const REGISTER_ENDPOINT = '/register';
export const ACCOUNT_ENDPOINT = '/account';
export const USERS_ENDPOINT = '/users';
export const CHANGE_PASSWORD_ENDPOINT = '/password';
export const CHANGE_USER_PASSWORD_ENDPOINT = '/password-forced';
export const TWO_FACTOR_ENDPOINT = '/2fa';
export const AFFILIATE__ENDPOINT = 'affiliate';
export const AFFILIATE_REFERRALS_ENDPOINT = 'affiliate/referrals';
export const AFFILIATE_MERCHANTS_REFERRALS_ENDPOINT = 'affiliate/merchants_referrals';
export const AFFILIATE_PREMIUM_REFERRALS_ENDPOINT = 'affiliate/premium_referrals';
export const AFFILIATE_REFERRALS_TREE_ENDPOINT = 'affiliate/referral-tree';
export const AFFILIATE_MERCHANTS_REFERRALS_TREE_ENDPOINT = 'affiliate/merchants_referral-tree';
export const AFFILIATE_PREMIUM_REFERRALS_TREE_ENDPOINT = 'affiliate/premium_referral-tree';
export const AFFILIATE_SELLL_IMIT_ENDPOINT = 'sell-limit';
export const KYC_ENDPOINT = '/kyc';
export const EXCHANGE_ENDPOINT = '/exchange';
export const ALL_SLC_PACKAGES = '/slc-packages';
export const BUY_SLC_PACKAGE = '/create-buy-order';
export const WEEKLY_SELL_LIMIT = '/sell-limit';
export const SELL_SLC_PACKAGE = '/create-sell-order';
export const FORGOT_PASSWORD_ENDPOINT = '/password/forgot-password';
export const NEW_PASSWORD_ENDPOINT = '/password/reset-password';
export const EMAIL_CHECK_EXISTING_ENDPOINT = '/check-existing/email';
export const AFFILIATE_CODE_CHECK_EXISTING_ENDPOINT = '/check-existing/affiliate-code';
export const CONFIRM_ACCOUNT = '/confirm-account';
export const SWAP_SLT_TO_SLC_ENDPOINT = '/swap-slt-to-slc';
export const REVOLUT_ENDPOINT = '/revolut';
export const INVITES_ENDPOINT = '/mail_invite';
//products
export const CROWDFUNDING = '/crowdfunding';
export const CROWDFUNDING_CREATE = `${CROWDFUNDING}/insert`;
export const CROWDFUNDING_PRODUCTS = `${CROWDFUNDING}/get-all`;

//AUTH IDENTITY ENPOINTS
export const LOGIN_ENDPOINT = `/oauth/authorize`;
export const LOGOUT_ENDPOINT = '/logout'; //TODO . Currently this endpoint doesn't redirect user to SLAFF app
export const GET_TOKEN_ENDPOINT = '/oauth/token';

export const GET_WALLET_ENDPOINT = (userId: string) => `${ACCOUNT_ENDPOINT}/${userId}/wallet`;

export const WALLET_FOR_CURRENCY_ENDPOINT = (userId: string, currencySymbol: string) =>
  `${GET_WALLET_ENDPOINT(userId)}/${getCurrencySymbolName(currencySymbol)}`;

export const GET_AFFILIATE_CODE_ENDPOINT = (userId: string) =>
  `${ACCOUNT_ENDPOINT}/${userId}/${AFFILIATE__ENDPOINT}`;

//todo for merchants
export const GET_REFERRALS_ENDPOINT = (userId: string) =>
  `${ACCOUNT_ENDPOINT}/${userId}/${AFFILIATE_REFERRALS_ENDPOINT}/${userId}`;

export const GET_REFERRALS_STATS_ENDPOINT = (userId: string) =>
  `${ACCOUNT_ENDPOINT}/${userId}/${AFFILIATE_REFERRALS_TREE_ENDPOINT}`;

export const GET_REFERRALS_TREE_ENDPOINT = (
  userId: string,
  referralId: string,
  direction = 'down',
) =>
  `${ACCOUNT_ENDPOINT}/${userId}/${AFFILIATE_REFERRALS_ENDPOINT}/${referralId}/traverse?direction=${direction}`;

export const GET_REFERRALS_TREE_SEARCH_ENDPOINT = (userId: string, email: string) =>
  `${ACCOUNT_ENDPOINT}/${userId}/affiliate/info?referral_email=${encodeURIComponent(email)}`;
export const GET_MERCHANTS_REFERRALS_STATS_ENDPOINT = (userId: string) =>
  `${ACCOUNT_ENDPOINT}/${userId}/${AFFILIATE_MERCHANTS_REFERRALS_TREE_ENDPOINT}`;

export const GET_PREMIUM_REFERRALS_STATS_ENDPOINT = (userId: string) =>
  `${ACCOUNT_ENDPOINT}/${userId}/${AFFILIATE_PREMIUM_REFERRALS_TREE_ENDPOINT}`;

export const GET_CHECK_IF_USER_HAS_AFFILIATE_ENDPOINT = (adminId: string, selectedUserId: string) =>
  `${ACCOUNT_ENDPOINT}/${adminId}/${AFFILIATE_SELLL_IMIT_ENDPOINT}/${selectedUserId}`;

export const GET_REFERRALS_PAGINATED_ENDPOINT = (
  userId: string,
  page: number,
  perPage: number,
  filter = '',
) =>
  `${ACCOUNT_ENDPOINT}/${userId}/${AFFILIATE_REFERRALS_ENDPOINT}/${userId}?page=${page}&per_page=${perPage}${filter}`;

export const GET_MERCHANTS_PAGINATED_ENDPOINT = (
  userId: string,
  page: number,
  perPage: number,
  filter = '',
) => `${ACCOUNT_ENDPOINT}/${userId}/txlog?page=${page}&per_page=${perPage}${filter}`;

export const GET_ORDERS_PAGINATED_ENDPOINT = (
  userId: string,
  page: number,
  perPage: number,
  filter = '',
) => `${ACCOUNT_ENDPOINT}/${userId}/orders?page=${page}&per_page=${perPage}${filter}`;

export const GET_PREMIUM_PAGINATED_ENDPOINT = (userId: string, page: number, perPage: number) =>
  `${ACCOUNT_ENDPOINT}/${userId}/${AFFILIATE_PREMIUM_REFERRALS_ENDPOINT}/${userId}?page=${page}&per_page=${perPage}`;

export const GET_UNCLAIMED_SLC_ENDPOINT = (userId: string) =>
  `${ACCOUNT_ENDPOINT}/${userId}/slc/unclaimed`;

export const CLAIM_SLC_ENDPOINT = (userId: string, cycle: number) =>
  `${ACCOUNT_ENDPOINT}/${userId}/slc/claim/${cycle}`;

export const GET_MERCHANTS_UNCLAIMED_SLC_ENDPOINT = (userId: string) =>
  `${ACCOUNT_ENDPOINT}/${userId}/slc/merchants_unclaimed`;

export const GET_PREMIUM_UNCLAIMED_SLC_ENDPOINT = (userId: string) =>
  `${ACCOUNT_ENDPOINT}/${userId}/slc/premium_unclaimed`;

export const CLAIM_MERCHANTS_SLC_ENDPOINT = (userId: string, cycle: number) =>
  `${ACCOUNT_ENDPOINT}/${userId}/slc/claim_merchants/${cycle}`;

export const CLAIM_PREMIUM_SLC_ENDPOINT = (userId: string, cycle: number) =>
  `${ACCOUNT_ENDPOINT}/${userId}/slc/claim_premium/${cycle}`;

export const GET_ALL_COINS_ENDPOINT = () => `toBeMade`;

export const GET_TRANSFERS_FOR_SYMBOL_ENDPOINT = (
  userId: string,
  symbol: string,
  page: number,
  perPage: number,
) =>
  `${ACCOUNT_ENDPOINT}/${userId}/wallet/${getCurrencySymbolName(
    symbol,
  )}/transfers?page=${page}&per_page=${perPage}`;

export const GET_TRANSFERS_ENDPOINT = (
  userId: string,
  symbol: string,
  page: number,
  perPage: number,
) => `${ACCOUNT_ENDPOINT}/${userId}/transfers?page=${page}&per_page=${perPage}`;

export const WITHDRAW_CURRENCY_ENDPOINT = (userId: string, symbol: string) => {
  const generatePath = () => {
    if (symbol === 'SLC') {
      return 'withdraw';
    } else if (symbol === 'WALLET_EUR') {
      return 'eur-withdraw';
    } else {
      return 'bitgo-withdraw';
    }
  };

  return `${ACCOUNT_ENDPOINT}/${userId}/wallet/${symbol.toLowerCase()}/${generatePath()}`;
};

export const GET_KYC_APPLICANTS_ENDPOINT = (accountId: string) =>
  `${ACCOUNT_ENDPOINT}/${accountId}/kyc/aplicants`;

export const UPDATE_KYC_APPLICANTS_ENDPOINT = (accountId: string) =>
  `${ACCOUNT_ENDPOINT}/${accountId}/kyc/update_applicant`;

export const GET_KYC_DOCUMENTS_ENDPOINT = (accountId: string) =>
  `${ACCOUNT_ENDPOINT}/${accountId}/kyc/documents`;

export const GET_KYC_CHECK_ENDPOINT = (accountId: string) =>
  `${ACCOUNT_ENDPOINT}/${accountId}/kyc/checks`;

export const EXCHANGE_CURRENCY_ENDPOINT = (accountId: string) =>
  `${ACCOUNT_ENDPOINT}/${accountId}${EXCHANGE_ENDPOINT}`;

export const WEEKLY_SELL_LIMIT_ENDPOINT = (accountId: string) =>
  `${ACCOUNT_ENDPOINT}/${accountId}${WEEKLY_SELL_LIMIT}`;

export const SLC_PACKAGES_ENDPOINT = (accountId: string) =>
  `${ACCOUNT_ENDPOINT}/${accountId}${ALL_SLC_PACKAGES}`;

export const BUY_SLC_PACKAGE_ENDPOINT = (accountId: string) =>
  `${ACCOUNT_ENDPOINT}/${accountId}${BUY_SLC_PACKAGE}`;

export const SELL_SLC_PACKAGE_ENDPOINT = (accountId: string) =>
  `${ACCOUNT_ENDPOINT}/${accountId}${SELL_SLC_PACKAGE}`;

export const USER_ENDPOINT = (accountId: string) => `${ACCOUNT_ENDPOINT}/${accountId}`;

export const GET_AFFILIATE_BALANCES = (accountId: string) =>
  `${ACCOUNT_ENDPOINT}/${accountId}/income`;

export const GET_ENTIRE_WALLET_BALANCES = (accountId: string) =>
  `${ACCOUNT_ENDPOINT}/${accountId}/wallet/SLC`;

export const EXPORT_USERS = (accountId: string) =>
  `${ACCOUNT_ENDPOINT}/${accountId}/csv-export/users`;

export const EXPORT_TRANSACTIONS = (accountId: string) =>
  `${ACCOUNT_ENDPOINT}/${accountId}/csv-export/transaction-logs`;

export const GET_TERM_SAVING_TYPES = (accountId: string) =>
  `${ACCOUNT_ENDPOINT}/${accountId}/term-saving-types`;

export const DEPOSIT_TERM_SAVING = (accountId: string) =>
  `${ACCOUNT_ENDPOINT}/${accountId}/deposit-term-saving`;

export const GET_TERM_SAVINGS_DETAILS = (accountId: string) =>
  `${ACCOUNT_ENDPOINT}/${accountId}/term-savings-details`;

export const GET_PROFILE_INFO = (accountId: string) =>
  `${ACCOUNT_ENDPOINT}/${accountId}/profile-info`;

export const UPDATE_PAYMENT_INFO = (accountId: string) =>
  `${ACCOUNT_ENDPOINT}/${accountId}/insert-update-order-payment-info`;

// products
export const PUBLISH_PRODUCT = (accountId: string, productId: string) =>
  `${ACCOUNT_ENDPOINT}/${accountId}${CROWDFUNDING}s/${productId}/publish`;

export const INVEST_PRODUCT = (accountId: string) =>
  `${ACCOUNT_ENDPOINT}/${accountId}${CROWDFUNDING}/invest`;

export const INSERT_PRODUCT = (accountId: string) =>
  `${ACCOUNT_ENDPOINT}/${accountId}${CROWDFUNDING_CREATE}`;

export const EDIT_PRODUCT = (accountId: string, productId: string) =>
  `${ACCOUNT_ENDPOINT}/${accountId}${CROWDFUNDING}s/${productId}/edit`;

export const INVESTED_PRODUCTS = (accountId: string, page: number, perPage: number, filter = '') =>
  `${ACCOUNT_ENDPOINT}/${accountId}${CROWDFUNDING}/get-invested?page=${page}&per_page=${perPage}${filter}`;

export const GET_PRODUCTS = (userId: string, page: number, perPage: number, filter = '') =>
  `${ACCOUNT_ENDPOINT}/${userId}${CROWDFUNDING_PRODUCTS}?page=${page}&per_page=${perPage}${filter}`;

export const GET_SINGLE_PRODUCT = (userId: string, productId: string) =>
  `${ACCOUNT_ENDPOINT}/${userId}${CROWDFUNDING}/get-single/${productId}`;

export const EXPORT_PRODUCTS_ENDPOINT = (accountId: string) =>
  `${ACCOUNT_ENDPOINT}/${accountId}/csv-export/products`;

export const GET_CRYPTO_CHART_DATA = (accountId: string, period: string) =>
  `${EXCHANGE_ENDPOINT}/${accountId}/gecko-crypto-history?flag=${period}`;

export const GET_CRYPTO_CHART_DATA_FALLBACK = (accountId: string) =>
  `${EXCHANGE_ENDPOINT}/${accountId}/cmc-crypto-history`;

export const GET_WALLET_TRANSFERS_PAGINATED = (
  userId: string,
  page: number,
  perPage: number,
  filter = '',
) => `${ACCOUNT_ENDPOINT}/${userId}/bitgo-logs?page=${page}&per_page=${perPage}${filter}`;

export const UPDATE_CURRENCY_RATE_ENDPOINT = (accountId: string, selectedCurrency: string) =>
  `${ACCOUNT_ENDPOINT}/${accountId}/update-price/${selectedCurrency}`;

//this is a temporary account id
export const UPDATE_STORE_ITEM_ENDPOINT = (storeItemId: string) =>
  `${ACCOUNT_ENDPOINT}/52f15893-039c-41cf-b066-44034efc7501/store_item/${storeItemId}`;

//this is a temporary account id
export const GET_STORE_ITEMS_ENDPOINT = (userId: string) =>
  `${ACCOUNT_ENDPOINT}/${userId}/store_item`;

export const DEPOSIT_FIAT_ENDPOINT = (userId: string, symbol: string) =>
  `${ACCOUNT_ENDPOINT}/${userId}/fiat-deposit/${symbol}`;

export const GET_USER_EARNINGS_PAGINATED_ENDPOINT = (
  userId: string,
  page: number,
  perPage: number,
  filter = '',
) => `${ACCOUNT_ENDPOINT}/${userId}/earnings?page=${page}&per_page=${perPage}${filter}`;

export const GET_PRODUCTS_PURCHASE_HISTORY_ENDPOINT = (
  userId: string,
  page: number,
  perPage: number,
  filter = '',
) =>
  `${ACCOUNT_ENDPOINT}/${userId}/product-purchase-history?page=${page}&per_page=${perPage}${filter}`;

export const EXPORT_USERS_EARNINGS_ENDPOINT = (
  accountId: string,
  page: number,
  perPage: number,
  filter = '',
) =>
  `${ACCOUNT_ENDPOINT}/${accountId}/csv-export/user-earnings?page=${page}&per_page=${perPage}${filter}`;

export const EXPORT_PRODUCTS_PURCHASE_HISTORY_ENDPOINT = (
  accountId: string,
  page: number,
  perPage: number,
  filter = '',
) =>
  `${ACCOUNT_ENDPOINT}/${accountId}/product-purchase-history/export?page=${page}&per_page=${perPage}${filter}`;

export const GET_TRANSACTION_GROUPING_PAGINATED_ENDPOINT = (
  userId: string,
  page: number,
  perPage: number,
  filter = '',
) => `${ACCOUNT_ENDPOINT}/${userId}/user-to-user-txs?page=${page}&per_page=${perPage}${filter}`;

export const EXPORT_TRANSACTION_GROUPING_ENDPOINT = (
  accountId: string,
  page: number,
  perPage: number,
  filter = '',
) =>
  `${ACCOUNT_ENDPOINT}/${accountId}/user-to-user-txs/export?page=${page}&per_page=${perPage}${filter}`;

export const GET_USER_TRANSACTION_HISTORY_PAGINATED_ENDPOINT = (
  userId: string,
  logaccountid: string,
  page: number,
  perPage: number,
  filter = '',
) =>
  `${ACCOUNT_ENDPOINT}/${userId}/txlog/${logaccountid}?page=${page}&per_page=${perPage}${filter}`;

export const EXPORT_USER_TRANSACTION_HISTORY = (
  accountId: string,
  user_email: string,
  filter = '',
) =>
  `${ACCOUNT_ENDPOINT}/${accountId}/csv-export/transaction-logs?user_email=${encodeURIComponent(
    user_email,
  )}${filter}`;

export const GET_USER_TRASNACTION_HISTORY_TRANSFERS_PAGINATED = (
  userId: string,
  logaccountid: string,
  page: number,
  perPage: number,
  filter = '',
) =>
  `${ACCOUNT_ENDPOINT}/${userId}/bitgo-logs/${logaccountid}?page=${page}&per_page=${perPage}${filter}`;

export const EXPORT_USER_TRANSFERS_HISTORY = (accountId: string, user_email: string, filter = '') =>
  `${ACCOUNT_ENDPOINT}/${accountId}/csv-export/bitgo-transaction-logs?user_email=${encodeURIComponent(
    user_email,
  )}${filter}`;

export const SWAP_SLT_TO_SLC = (accountId: string) =>
  `${ACCOUNT_ENDPOINT}/${accountId}${SWAP_SLT_TO_SLC_ENDPOINT}`;

export const GET_SWAP_INFO = (accountId: string) => `${ACCOUNT_ENDPOINT}/${accountId}/swap-info`;

export const REVOLUT_COUNTERPARTY = (accountId: string) =>
  `${ACCOUNT_ENDPOINT}/${accountId}${REVOLUT_ENDPOINT}/counterparty`;

export const REVOLUT_WITHDRAW = (accountId: string) =>
  `${ACCOUNT_ENDPOINT}/${accountId}${REVOLUT_ENDPOINT}/withdraw`;

export const BUY_WALLET = (accountId: string, storeItemCode: string) =>
  `${ACCOUNT_ENDPOINT}/${accountId}/store_item/${storeItemCode}/buy`;

export const DEPOSIT_EUR = (accountId: string) =>
  `${ACCOUNT_ENDPOINT}/${accountId}${REVOLUT_ENDPOINT}/order`;

export const CREATE_DEPOSIT_ORDER = (accountId: string) =>
  `${ACCOUNT_ENDPOINT}/${accountId}/create-deposit-order`;

export const EXCHANGE_RATE_HISTORY = (accountId: string, coinSymbol = 'SLC') =>
  `${ACCOUNT_ENDPOINT}/${accountId}/exchange-rate-history/${coinSymbol}`;

export const REVOLUT_DEPOSIT = (userId: string, filter = '') =>
  `${ACCOUNT_ENDPOINT}/${userId}/revolut/deposits?${filter}`;

export const REVOLUT_WIDTHDRAWAL = (userId: string, page: number, perPage: number, filter = '') =>
  `${ACCOUNT_ENDPOINT}/${userId}/revolut/withdrawals?page=${page}&per_page=${perPage}${filter}`;

export const GET_ALL_FRIENDS_INVITATIONS = (accountId: string, status?: string) =>
  `${ACCOUNT_ENDPOINT}/${accountId}${INVITES_ENDPOINT}${status ? `?status=${status}` : ''}`;

export const GET_ALL_FRIENDS_INVITATIONS_PAGINATED = (
  accountId: string,
  page: number,
  perPage: number,
  filter = '',
) =>
  `${ACCOUNT_ENDPOINT}/${accountId}${INVITES_ENDPOINT}?page=${page}&per_page=${perPage}${filter}`;
export const SEND_INVITES = (accountId: string) =>
  `${ACCOUNT_ENDPOINT}/${accountId}${INVITES_ENDPOINT}`;
