import { Grid } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { EmptyAC, PayloadAC } from 'typesafe-actions';
import SacretDaterangePickerSelect from '../../../../../../../components/SacrateDaterangePickerSelect';
import SacretInputNumber from '../../../../../../../components/SacretInputNumber';
import SacretSelect from '../../../../../../../components/SacretSelect';
import {
  GetPaginatedUserTransactionHistoryRequest,
  SaveFilter,
} from '../../../../../../../data/repositories/admin/typedefs';
import {
  GET_ALL_TRANSACTIONS_PAGINATED,
  USER_TRANSACTION_HISTORY_CLEAR_ALL_FILTERS,
  USER_TRANSACTION_HISTORY_TOGGLE_FILTER,
} from '../../state/actions';
import AppliedFiltersChips from './AppliedFiltersChips';
const styles = () =>
  createStyles({
    filter: {
      minWidth: 200,
      marginRight: 10,
    },
    'm-r': {
      marginRight: 10,
    },
  });

const useStyles = makeStyles(styles);

type Props = {
  toggleFilter: PayloadAC<typeof USER_TRANSACTION_HISTORY_TOGGLE_FILTER, SaveFilter>;
  appliedFilters: Map<string, string[]>;
  clearAllFilters: EmptyAC<typeof USER_TRANSACTION_HISTORY_CLEAR_ALL_FILTERS>;
  asyncGetAllTransactionsPaginated: PayloadAC<
    typeof GET_ALL_TRANSACTIONS_PAGINATED,
    GetPaginatedUserTransactionHistoryRequest
  >;
  logAccountId: string;
};

const names = [
  { name: 'Affiliate became Partner', value: 'merchant_parent_reward' },
  { name: 'Affiliate package purchase', value: 'premium_user_reward' },
  { name: 'Affiliate purchased Affiliate package', value: 'premium_user_affiliate_reward' },
  {
    name: 'BV',
    value:
      'affiliate_percentage_reward,term_saving,term_saving_finished&exclude_tx_status=invested',
  },
  { name: 'Became Partner', value: 'new_merchant_reward' },
  { name: 'Bonus', value: 'bonus' },
  { name: 'Bonus Reward', value: 'bonus_reward' },
  { name: 'Buy', value: 'buy' },
  { name: 'Buy product', value: 'buy_product' },
  { name: 'Deposit', value: 'deposit' },
  { name: 'Event reward', value: 'event_reward' },
  { name: 'HODL', value: 'term_saving' },
  { name: 'HODL Interested payment', value: 'interest_payment' },
  { name: 'New Affiliate', value: 'new_user_affiliate_reward' },
  { name: 'Purchase', value: 'payment_sent' },
  { name: 'Registration reward', value: 'new_user_reward' },
  { name: 'Reward transfer', value: 'reward_correction' },
  { name: 'Sale', value: 'merchant_income' },
  { name: 'Term saving payout', value: 'term_saving_finished' },
  { name: 'Transfer', value: 'transfer' },
  { name: 'Withdraw', value: 'withdraw' },
];

const statuses = [
  { name: 'Completed', value: 'completed' },
  { name: 'Pending', value: 'pending' },
  { name: 'Escrow', value: 'escrow' },
  { name: 'In HODL', value: 'invested' },
  { name: 'Pending Escrow', value: 'pending_escrow' },
  { name: 'Timed Escrow', value: 'timed_escrow' },
];

const AllTransactionsFilterBar: React.FC<Props> = ({
  toggleFilter,
  appliedFilters,
  clearAllFilters,
  asyncGetAllTransactionsPaginated,
  logAccountId,
}: Props): JSX.Element => {
  const classes = useStyles();

  const handleOnChangeEvent = (
    value: string[] | string | undefined,
    filterType: string,
    limit?: string,
  ) => {
    const object: any = {};
    appliedFilters.forEach((value, key) => {
      object[key] = value;
    });

    let valueRange;

    switch (limit) {
      case 'Min':
        valueRange = [value, object.valueRange ? object.valueRange[1] : '100000'];
        const maxValue = valueRange[1] === '100000' ? '' : valueRange[1];

        asyncGetAllTransactionsPaginated({
          logAccountId,
          page: 0,
          perPage: 10,
          appliedFilters: { ...object, [filterType]: valueRange },
        });
        value = value === '' ? '' : value;
        toggleFilter({
          filterType: 'valueRange',
          minValue: value,
          maxValue: maxValue,
          value: valueRange,
        });
        break;
      case 'Max':
        valueRange = [object.valueRange ? object.valueRange[0] : '-100000', value];
        const minValue = valueRange[0] === '-100000' ? '' : valueRange[0];

        asyncGetAllTransactionsPaginated({
          logAccountId,
          page: 0,
          perPage: 10,
          appliedFilters: { ...object, [filterType]: valueRange },
        });
        value = value === '' ? '' : value;

        toggleFilter({
          filterType: 'valueRange',
          maxValue: value,
          minValue: minValue,
          value: valueRange,
        });
        break;
      default:
        asyncGetAllTransactionsPaginated({
          logAccountId,
          page: 0,
          perPage: 10,
          appliedFilters: { ...object, [filterType]: value },
        });

        toggleFilter({ filterType, value });
        break;
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item className={classes.filter}>
        <SacretSelect
          items={statuses}
          label="Status"
          onChange={(value: string[]) => handleOnChangeEvent(value, 'status')}
          stateFilterValue={appliedFilters.get('status')}
        />
      </Grid>
      <Grid item className={classes.filter} style={{ minWidth: 240 }}>
        <SacretSelect
          items={names}
          label="Transaction type"
          onChange={(value: string[]) => handleOnChangeEvent(value, 'transactionType')}
          stateFilterValue={appliedFilters.get('transactionType')}
        />
      </Grid>
      <Grid item className={classes.filter} style={{ minWidth: 236 }}>
        <SacretDaterangePickerSelect
          label="Choose a date"
          onChange={(value: string[]) => handleOnChangeEvent(value, 'dateFilter')}
          stateFilterValue={appliedFilters.get('dateFilter')}
        />
      </Grid>
      <Grid item className={classes['m-r']}>
        <SacretInputNumber
          currency="SLC"
          limit="Min"
          onChange={(value: string) => handleOnChangeEvent(value, 'valueRange', 'Min')}
          stateValue={appliedFilters.get('minValue')}
        />
      </Grid>
      <Grid item className={classes['m-r']}>
        <SacretInputNumber
          currency="SLC"
          limit="Max"
          onChange={(value: string) => {
            handleOnChangeEvent(value, 'valueRange', 'Max');
          }}
          stateValue={appliedFilters.get('maxValue')}
        />
      </Grid>
      <AppliedFiltersChips
        clearAllFilters={clearAllFilters}
        stateAppliedFilters={appliedFilters}
        toggleFilter={toggleFilter}
        logAccountId={logAccountId}
      />
    </Grid>
  );
};

export default AllTransactionsFilterBar;
