import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import initialState from '../App/state/initialState';
import createRootReducer from '../App/state/reducers';
import sagas from '../App/state/sagas';

declare global {
  // eslint-disable-next-line
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const history = createBrowserHistory();

const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  const router = routerMiddleware(history);
  // const loggerPod = process.env.NODE_ENV === 'production' ? [logger] : [];
  // const middleWares = [...loggerPod, sagaMiddleware, router];
  const middleWares = [sagaMiddleware, router];

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const enhancer = composeEnhancers(applyMiddleware(...middleWares));

  const appReducer = createRootReducer(history);

  const rootReducer = (state: any, action: any) => {
    if (action.type === 'logout') {
      state = undefined;
    }
    return appReducer(state, action);
  };

  const store = createStore(rootReducer, initialState, enhancer);

  sagaMiddleware.run(sagas);

  if (module.hot)
    module.hot.accept('../App/state/reducers', () =>
      store.replaceReducer(createRootReducer(history)),
    );

  return store;
};

export default { store: configureStore(), history };
