import { Button, Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { EmptyAC, PayloadAC } from 'typesafe-actions';
import sacretLifeLogo from '../../../assets/dashboard/logo.svg';
import OffIcon from '../../../assets/off.svg';
import ForcePassUpdateDialog from '../../../components/ForcePassUpdateDialog';
import VerifyAccountButton from '../../../components/VerifyAccountButton';
import LocalStorage from '../../../data/LocalStorage';
import { CreateWalletForCurrencyRequest } from '../../../data/repositories/wallet/typedefs';
import { LOGOUT_ENDPOINT } from '../../../utils/constants/api';
import { APP_DASHBOARD_ROUTE } from '../../../utils/constants/routes';
import InfoDialog from '../HomeContent/InfoDialog';
import { GET_PROFILE_INFO } from '../HomeContent/Settings/Membership/state/actions';
import { ProfileInfoState } from '../HomeContent/Settings/Membership/state/initialState';
import UserEmailAndMembership from './UserEmailAndMembership/index';
const styles = (theme: Theme) =>
  createStyles({
    link: {
      float: 'right',
      display: 'flex',
    },
    rightWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    role: {
      textTransform: 'capitalize',
    },
    blockchainWrapper: {
      marginLeft: '12px',
    },
    blockchainIcon: {
      fill: 'white',
    },
    membershipIcon: {
      height: 25,
      width: 25,
      paddingLeft: 8,
    },
    logo: {
      height: 50,
      '& > img': {
        width: '100%',
        height: '100%',
      },
      [theme.breakpoints.down('sm')]: {
        height: 'auto',
        paddingTop: 7,
        width: 120,
        margin: 'auto',
      },
    },
  });

const useStyles = makeStyles(styles);

type Props = {
  logout: EmptyAC<'logout'>;
  createWallet: PayloadAC<'wallet/create_wallet_for_currency', CreateWalletForCurrencyRequest>;
  user: any;
  depositWalletAddress: string;
  loginSaveUserDetails: any;
  showEmailAndMembership?: boolean;
  hasCompletedKYC: boolean;
  profileInfo: ProfileInfoState;
};

const HomeHeader: React.FC<Props> = ({
  createWallet,
  user,
  showEmailAndMembership,
  hasCompletedKYC,
  profileInfo,
}: Props): JSX.Element => {
  const classes = useStyles();

  const handleLogout = () => {
    LocalStorage.clear();
    window.location.href = `${process.env.REACT_APP_SL_GROUP_HOST}${LOGOUT_ENDPOINT}?from_url=${window.location.origin}`;
  };
  const [weakPassDialog, setWeakPassDialog] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    createWallet({ symbol: 'ETH' });
    dispatch({ type: GET_PROFILE_INFO });
  }, []);

  useEffect(() => {
    if (user && user.shouldUpdatePass) setWeakPassDialog(true);
  }, [user]);

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap">
        <Link to={APP_DASHBOARD_ROUTE} className={classes.logo}>
          <img src={sacretLifeLogo} />
        </Link>
        <span className={classes.rightWrapper}>
          {!!profileInfo.account_info.kyc_status && showEmailAndMembership && !hasCompletedKYC && (
            <VerifyAccountButton />
          )}
          {showEmailAndMembership && <UserEmailAndMembership />}
          <ForcePassUpdateDialog onClose={() => setWeakPassDialog(false)} open={weakPassDialog} />
          <Button onClick={handleLogout} color="inherit" className={classes.link}>
            <img src={OffIcon} />
          </Button>
        </span>
      </Grid>

      <InfoDialog />
    </>
  );
};

export default HomeHeader;
