import { connect } from 'react-redux';
import { AppState } from '../../../../../state/initialState';
import { depositModalStateSelector } from '../../state/selectors';
import DepositSuccess from './DepositSuccess';

const mapStateToProps = (state: AppState) => {
  const depositModalState = depositModalStateSelector(state);
  return { depositModalState };
};

export default connect(mapStateToProps, null)(DepositSuccess);
