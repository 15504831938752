import { InputBase } from '@material-ui/core';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';

const SacretBaseInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      fontSize: 12,
      padding: '8px 26px 0 12px',
      height: '32px',
      transition: theme.transitions.create(['box-shadow']),
      borderRadius: 4,
      boxShadow: '0 10px 30px 0 rgba(132,141,182,0.25)',
      '&:focus': {
        backgroundColor: theme.palette.background.paper,
        borderRadius: 4,
        boxShadow: '0 10px 30px 0 rgba(132,141,182,0.25)',
      },
      '&::placeholder': {
        color: theme.palette.secondary.light,
      },
    },
  }),
)(InputBase);

export default SacretBaseInput;
