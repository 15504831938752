import { Dialog, Grid } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Business from '@material-ui/icons/Business';
import CardGiftcard from '@material-ui/icons/CardGiftcard';
import DashboardIcon from '@material-ui/icons/Dashboard';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { EmptyAC, PayloadAC } from 'typesafe-actions';
import adminIcon from '../../../assets/admin.svg';
import exchangeIcon from '../../../assets/affiliate-transactions.svg';
import affiliateIcon from '../../../assets/affiliateIcon.svg';
import invites from '../../../assets/invites.svg';
import realEstateIcon from '../../../assets/real_estate.svg';
import affiliatePackage from '../../../assets/affiliatePackage.svg';
import settingsIcon from '../../../assets/settingsIcon.svg';
import earningsIcon from '../../../assets/Shape@1.5x.svg';
import transactionsIcon from '../../../assets/transactions.svg';
import walletIcon from '../../../assets/walletIcon.svg';
import CoinPricesCollapse from '../../../components/CoinPricesCollapse';
import ModalState from '../../../components/ModalState';
import VerifyAccountButton from '../../../components/VerifyAccountButton';
import { GetCoinPricesResponse } from '../../../data/repositories/wallet/typedefs';
import { Colors } from '../../../ui/style/colors';
import { roles } from '../../../utils/constants/roles';
import {
  ADMIN_PANEL_ROUTE,
  AFFILIATE_ROUTE,
  APP_DASHBOARD_ROUTE,
  BECOME_AN_AFFILIATE_URL,
  EARNINGS_ROUTE,
  EXCHANGE_ROUTE,
  MY_INVITES,
  ORDERS_ROUTE,
  PRODUCTS_ROUTE,
  REDIRECT_TO_REAL_ESTATE,
  SETTINGS_ROUTE,
  TRANSACTIONS_ROUTE,
  WALLET_ROUTE,
} from '../../../utils/constants/routes';
import { AppState } from '../../state/initialState';
import { userStateSelector } from '../../state/selectors';
import { initialState as exchangeInitialState } from '../HomeContent/Exchange/ExchangeCurrency/state/initialState';
import { getExchangeCurrencySelector } from '../HomeContent/Exchange/ExchangeCurrency/state/selectors';
import HomeHeader from '../HomeHeader';
import UserEmailAndMembership from '../HomeHeader/UserEmailAndMembership/index';
import {
  CALCULATE_IF_IS_MOBILE_DEVICE,
  SET_DRAWER_HOVER,
  SET_DRAWER_MENU_STATE,
} from './state/actions';
import { DrawerState } from './state/initialState';

const drawerWidth = 245;

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      top: 0,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      backgroundImage: `linear-gradient(to right,${Colors.white}, ${Colors.mediumTurquoise});`,
    },

    menuButton: {
      marginRight: 36,
      color: `${Colors.havelockBlue}`,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      '@media (max-width:1280px)': {
        width: '100vw',
      },
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(10) + 1,
      },
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      '&:hover': {
        width: drawerWidth,
      },
      '@media (max-width:1280px)': {
        width: 0,
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          width: 0,
        },
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      backgroundColor: `${Colors.blueCharcoal}`,
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },

    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },

    drawerPaper: {
      width: drawerWidth,
    },
    ListItemText: {
      fontSize: 18,
      marginLeft: 25,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    coinPrices: {
      color: theme.palette.secondary.light,
      fontSize: 16,
      fontWeight: 'bold',
    },
    link: {
      textDecoration: 'none',
      color: theme.palette.primary.light,
      backgroundColor: `${Colors.white}`,
    },
    buttonLink: {
      border: `1px solid ${theme.palette.primary.light}`,
    },
    menuButtonLinkSelected: {
      border: `1px solid ${Colors.mediumTurquoise}`,
    },
    menuItemSelected: {
      color: `${Colors.mediumTurquoise}`,
      backgroundColor: 'transparent !important',
    },
    icon: {
      minWidth: 22,
      display: 'inline-flex',
      flexShrink: 0,
      justifyContent: 'center',
    },
    list: {
      marginTop: 50,
      padding: 24,
      paddingTop: 40,
      overflowY: 'scroll',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    menuListItem: {
      marginBottom: 20,
      padding: 0,
    },
    emailAndRole: {
      display: 'flex',
      color: `${Colors.wildBlueYonder}`,
      justifyContent: 'left',
      fontSize: 18,
      marginBottom: 24,
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      paddingBottom: '15px',
    },
    verifyAccountButton: {
      margin: '10px 0',
      border: 'none',
      width: '100%',
    },
    dialog: {
      height: 'center',
      width: 'center',
      borderRadius: 6,
      backgroundColor: 'transparent',
      paddingLeft: 30,
      paddingRight: 30,
    },
  });

const useStyles = makeStyles(styles);

type Props = {
  curentHomePage: string;
  coinPrices?: GetCoinPricesResponse;
  user: any;
  exchangeCurrency: any;
  drawerState: DrawerState;
  calcIfIsMobileDevice: PayloadAC<typeof CALCULATE_IF_IS_MOBILE_DEVICE, number>;
  setHomeDrawerMenu: PayloadAC<typeof SET_DRAWER_MENU_STATE, boolean>;
  setDrawerHover: PayloadAC<typeof SET_DRAWER_HOVER, boolean>;
  isTwoFactorAuthEnabled: EmptyAC<'settings/is_two_factor_auth_enabled'>;
  hasCompletedKYC: boolean;
  is2FaEnabled: boolean | undefined;
};

const HomeDrawer: React.FC<Props> = ({
  curentHomePage,
  coinPrices,
  user,
  exchangeCurrency,
  drawerState,
  setHomeDrawerMenu,
  calcIfIsMobileDevice,
  setDrawerHover,
  isTwoFactorAuthEnabled,
  hasCompletedKYC,
  is2FaEnabled,
}) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [isRealEstateTabClicked, setIsRealEstateTabClicked] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (window.innerWidth < 1025) {
      setHomeDrawerMenu(false);
    }
    calcIfIsMobileDevice(window.innerWidth);
    const handleResize = () => {
      const width = window.innerWidth;
      calcIfIsMobileDevice(width);
      closeDrawerIfMobileDevice();
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    isTwoFactorAuthEnabled();
  }, []);

  const isMobileDevice = drawerState.isMobileDevice;

  const closeDrawerIfMobileDevice = () => {
    if (isMobileDevice) {
      return setHomeDrawerMenu(false);
    }
  };

  const hangleOpenCloseDrawer = () => {
    setHomeDrawerMenu(!drawerState.isOpen);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />

      <AppBar position="fixed" className={clsx(classes.appBar)}>
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            onClick={hangleOpenCloseDrawer}
            edge="start"
            className={clsx(classes.menuButton)}
          >
            <MenuIcon />
          </IconButton>
          <HomeHeader showEmailAndMembership={!isMobileDevice} />
        </Toolbar>
      </AppBar>
      <Drawer
        onMouseEnter={() => setDrawerHover(true)}
        onMouseLeave={() => setDrawerHover(false)}
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: drawerState.isOpen,
          [classes.drawerClose]: !drawerState.isOpen,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: drawerState.isOpen,
            [classes.drawerClose]: !drawerState.isOpen,
          }),
        }}
      >
        <Divider />
        <List className={classes.list} onClick={closeDrawerIfMobileDevice}>
          {isMobileDevice && (
            <Grid container direction="column" className={classes.emailAndRole}>
              <UserEmailAndMembership />
              {!hasCompletedKYC && (
                <Grid item xs={12} sm={4}>
                  <VerifyAccountButton className={classes.verifyAccountButton} />
                </Grid>
              )}
            </Grid>
          )}
          <Link to={APP_DASHBOARD_ROUTE} className={classes.link}>
            <MenuItem
              button
              key="dashboard"
              selected={curentHomePage === 'dashboard'}
              classes={{ selected: classes.menuItemSelected }}
              className={classes.menuListItem}
            >
              <div className={classes.icon}>
                <DashboardIcon />
              </div>
              <ListItemText primary="Dashboard" classes={{ primary: classes.ListItemText }} />
            </MenuItem>
          </Link>
          <Link to={WALLET_ROUTE} className={classes.link}>
            <MenuItem
              button
              key="wallet"
              selected={curentHomePage === 'wallet'}
              classes={{ selected: classes.menuItemSelected }}
              className={classes.menuListItem}
            >
              <div className={classes.icon}>
                <SVG
                  preProcessor={code => code.replace(/fill=".*?"/g, 'fill="currentColor"')}
                  src={walletIcon}
                />
              </div>
              <ListItemText primary="Wallet" classes={{ primary: classes.ListItemText }} />
            </MenuItem>
          </Link>

          <Link to={TRANSACTIONS_ROUTE} className={classes.link}>
            <MenuItem
              button
              key="transactions"
              selected={curentHomePage === 'transactions'}
              classes={{ selected: classes.menuItemSelected }}
              className={classes.menuListItem}
            >
              <div className={classes.icon}>
                <SVG
                  preProcessor={code => code.replace(/fill=".*?"/g, 'fill="currentColor"')}
                  src={transactionsIcon}
                />
              </div>
              <ListItemText primary="Transactions" classes={{ primary: classes.ListItemText }} />
            </MenuItem>
          </Link>

          <Link to={EXCHANGE_ROUTE} className={classes.link}>
            <MenuItem
              button
              key="exchange"
              selected={curentHomePage === 'exchange'}
              classes={{ selected: classes.menuItemSelected }}
              className={classes.menuListItem}
              onClick={() =>
                dispatch({
                  type: 'exchange/save_state',
                  payload: {
                    ...exchangeInitialState,
                    step: 0,
                    packages: exchangeCurrency.packages,
                    fetchMembershipProgress: exchangeCurrency.fetchMembershipProgress,
                  },
                })
              }
            >
              <div className={classes.icon}>
                <SVG
                  preProcessor={code => code.replace(/fill=".*?"/g, 'fill="currentColor"')}
                  src={exchangeIcon}
                />
              </div>
              <ListItemText primary="Exchange" classes={{ primary: classes.ListItemText }} />
            </MenuItem>
          </Link>

          <Link to={AFFILIATE_ROUTE} className={classes.link}>
            <MenuItem
              button
              key="affiliate"
              selected={curentHomePage === 'affiliate'}
              classes={{ selected: classes.menuItemSelected }}
              className={classes.menuListItem}
            >
              <div className={classes.icon}>
                <SVG
                  preProcessor={code => code.replace(/fill=".*?"/g, 'fill="currentColor"')}
                  src={affiliateIcon}
                />
              </div>
              <ListItemText primary="Affiliates" classes={{ primary: classes.ListItemText }} />
            </MenuItem>
          </Link>

          <Link to={EARNINGS_ROUTE} className={classes.link}>
            <MenuItem
              button
              key="earnings"
              selected={curentHomePage === 'earnings'}
              classes={{ selected: classes.menuItemSelected }}
              className={classes.menuListItem}
            >
              <div className={classes.icon}>
                <SVG
                  preProcessor={code => code.replace(/fill=".*?"/g, 'fill="currentColor"')}
                  src={earningsIcon}
                />
              </div>
              <ListItemText primary="Earnings" classes={{ primary: classes.ListItemText }} />
            </MenuItem>
          </Link>

          {roles.isOwner(user.roles) && (
            <Link to={ORDERS_ROUTE} className={classes.link}>
              <MenuItem
                button
                key="orders"
                selected={curentHomePage === 'orders'}
                classes={{ selected: classes.menuItemSelected }}
                className={classes.menuListItem}
              >
                <div className={classes.icon}>
                  <CardGiftcard />
                </div>
                <ListItemText primary="Orders" classes={{ primary: classes.ListItemText }} />
              </MenuItem>
            </Link>
          )}

          <Link to={PRODUCTS_ROUTE} className={classes.link}>
            <MenuItem
              button
              key="product"
              selected={curentHomePage === 'product'}
              classes={{ selected: classes.menuItemSelected }}
              className={classes.menuListItem}
            >
              <div className={classes.icon}>
                <Business />
              </div>
              <ListItemText primary="Products" classes={{ primary: classes.ListItemText }} />
            </MenuItem>
          </Link>
          {!is2FaEnabled && isOpen && (
            <Dialog open={isOpen} className={classes.dialog}>
              <ModalState
                onExit={() => {
                  setIsOpen(false);
                  setIsRealEstateTabClicked(false);
                }}
                message={'Please enable 2FA then try again'}
                variant="warning"
                buttonText="Cancel"
              >
                <Link
                  to={{
                    pathname: '/settings',
                    search: '?auth=true',
                  }}
                  onClick={() => {
                    setIsOpen(false);
                    setIsRealEstateTabClicked(false);
                  }}
                >
                  Enable 2FA
                </Link>
              </ModalState>
            </Dialog>
          )}

          <MenuItem
            button
            onClick={() => {
              !is2FaEnabled ? setIsOpen(true) : window.open(REDIRECT_TO_REAL_ESTATE, '_blank');
              !is2FaEnabled ? setIsRealEstateTabClicked(true) : setIsRealEstateTabClicked(false);
            }}
            key="real estate"
            classes={{ selected: classes.menuItemSelected }}
            className={`${classes.menuListItem} ${classes.link} ${
              isRealEstateTabClicked && classes.menuItemSelected
            }`}
          >
            <div className={classes.icon}>
              <SVG
                preProcessor={code => code.replace(/fill=".*?"/g, 'fill="currentColor"')}
                src={realEstateIcon}
              />
            </div>
            <ListItemText primary="Real Estate" classes={{ primary: classes.ListItemText }} />
          </MenuItem>

          <MenuItem
            button
            onClick={() => {
              window.open(BECOME_AN_AFFILIATE_URL, '_self');
            }}
            key="affiliate package"
            classes={{ selected: classes.menuItemSelected }}
            className={`${classes.menuListItem} ${classes.link}`}
          >
            <div className={classes.icon}>
              <SVG
                preProcessor={code => code.replace(/fill=".*?"/g, 'fill="currentColor"')}
                src={affiliatePackage}
              />
            </div>
            <ListItemText
              primary="Affiliate packages"
              classes={{ primary: classes.ListItemText }}
            />
          </MenuItem>

          <Link to={MY_INVITES} className={classes.link}>
            <MenuItem
              button
              key="invites"
              selected={curentHomePage === 'invites'}
              classes={{ selected: classes.menuItemSelected }}
              className={classes.menuListItem}
            >
              <div className={classes.icon}>
                <SVG
                  preProcessor={code => code.replace(/fill=".*?"/g, 'fill="currentColor"')}
                  src={invites}
                />
              </div>
              <ListItemText primary="Invites" classes={{ primary: classes.ListItemText }} />
            </MenuItem>
          </Link>

          <Link to={SETTINGS_ROUTE} className={classes.link}>
            <MenuItem
              button
              key="settings"
              selected={curentHomePage === 'settings'}
              classes={{ selected: classes.menuItemSelected }}
              className={classes.menuListItem}
            >
              <div className={classes.icon}>
                <SVG
                  preProcessor={code => code.replace(/fill=".*?"/g, 'fill="currentColor"')}
                  src={settingsIcon}
                />
              </div>
              <ListItemText primary="Settings" classes={{ primary: classes.ListItemText }} />
            </MenuItem>
          </Link>
          {roles.isOwner(user.roles) && (
            <Link to={ADMIN_PANEL_ROUTE} className={`${classes.link} `}>
              <MenuItem
                button
                key="admin"
                selected={curentHomePage === 'admin'}
                classes={{
                  selected: `${classes.menuItemSelected} ${classes.menuButtonLinkSelected}`,
                }}
                className={`${classes.menuListItem} ${classes.buttonLink} `}
              >
                <div className={classes.icon}>
                  <SVG
                    preProcessor={code => code.replace(/fill=".*?"/g, 'fill="currentColor"')}
                    src={adminIcon}
                  />
                </div>
                <ListItemText primary="Admin Panel" classes={{ primary: classes.ListItemText }} />
              </MenuItem>
            </Link>
          )}
          <CoinPricesCollapse coins={coinPrices} />
        </List>
      </Drawer>
    </div>
  );
};
const mapStateToProps = (state: AppState) => {
  const user = userStateSelector(state);
  const exchangeCurrency = getExchangeCurrencySelector(state);
  return { user, exchangeCurrency };
};
export default connect(mapStateToProps, null)(React.memo(HomeDrawer));
