import { Grid } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { EmptyAC, PayloadAC } from 'typesafe-actions';
import { NavTab, NavTabs } from '../../../../components/NavTabs';
import OrdersTable from './AffiliateMerchantsTable';
import { AffiliateMerchantsTableState } from './AffiliateMerchantsTable/state/initialState';
import RevolutOrdersTable from './RevolutOrders';
import RevolutOrdersFilterBar from './RevolutOrders/RevolutOrdersFilterBar';
import { TOGGLE_ORDER_TYPE } from './state/actions';
import TransactionsFilterBar from './TransactionsFilterBar';

const styles = () =>
  createStyles({
    orders: {
      margin: 24,
      marginTop: 30,
    },
    navTabs: {
      marginBottom: 30,
    },
  });

const useStyles = makeStyles(styles);

type Props = {
  ordersState?: AffiliateMerchantsTableState;
  setCurrentHomePage: PayloadAC<'home/set_current_home_page', string>;
  clearAllFilters: EmptyAC<'orders/clear_all_filters'>;
  getWalletBalance: EmptyAC<'wallet/get_wallet_balance'>;
};

const Orders: React.FC<Props> = ({
  setCurrentHomePage,
  clearAllFilters,
  getWalletBalance,
}: Props): JSX.Element => {
  const [activeTab, setActiveTab] = useState(0);

  const getTabValue = (value: number) => {
    switch (value) {
      case 0:
        return 'buy';
      case 1:
        return 'sell';
      case 2:
        return 'crowdfunding';
      case 3:
        return 'deposit_fiat';
      case 4:
        return 'revolutWithdrawal';
      case 5:
        return 'project_invest';
      default:
        return 'buy';
    }
  };
  const handleTabChange = (event: React.ChangeEvent<Record<string, unknown>>, newValue: number) => {
    dispatch({ type: TOGGLE_ORDER_TYPE, payload: getTabValue(newValue) });
    clearAllFilters();
    setActiveTab(newValue);
  };

  useEffect(() => {
    clearAllFilters();
    getWalletBalance();
    setCurrentHomePage('orders');
    dispatch({ type: TOGGLE_ORDER_TYPE, payload: 'buy' });
  }, []);
  const dispatch = useDispatch();

  const classes = useStyles();
  return (
    <Grid item className={classes.orders}>
      <Grid item className={classes.navTabs}>
        <NavTabs
          variant="scrollable"
          value={activeTab}
          onChange={handleTabChange}
          aria-label="Orders"
        >
          <NavTab label="Buy orders" />
          <NavTab label="Sell orders" />
          <NavTab label="Product orders" />
          <NavTab label="Revolut deposit" />
          <NavTab label="Revolut withdraw" />
          <NavTab label="Real estate projects orders" />
        </NavTabs>
      </Grid>
      {activeTab < 3 || activeTab === 5 ? (
        <>
          {' '}
          <TransactionsFilterBar />
          <OrdersTable />{' '}
        </>
      ) : (
        <>
          <RevolutOrdersFilterBar />
          <RevolutOrdersTable />
        </>
      )}
    </Grid>
  );
};

export default Orders;
