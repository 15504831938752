import { connect } from 'react-redux';
import ChangeUserPassword from './ChangeUserPassword';
import { AppState } from '../../../../state/initialState';
import { changeUserPasswordStateSelector } from './state/selectors';
import {
  setChangeUserPasswordSelectedUser,
  changeUserPasswordClearState,
  asyncChangeUserPassword,
} from './state/actions';
import { setInfoDialogFields } from '../../InfoDialog/state/actions';

const mapStateToProps = (state: AppState) => {
  return changeUserPasswordStateSelector(state);
};

const mapDispatchToProps = {
  changePassword: asyncChangeUserPassword.request,
  setInfoDialogFields: setInfoDialogFields.request,
  setChangeUserPasswordSelectedUser,
  changeUserPasswordClearState,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeUserPassword);
