import { Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { EmptyAC, PayloadAC } from 'typesafe-actions';
import SacretDaterangePickerSelect from '../../../../../components/SacrateDaterangePickerSelect';
import SacretSearchInput from '../../../../../components/SacretSearchInput';
import SacretSelect from '../../../../../components/SacretSelect';
import LocalStorage from '../../../../../data/LocalStorage';
import { SaveTransactionFilter } from '../../../../../data/repositories/transaction/typedef';
import { USER_ID } from '../../../../../utils/constants/localStorageKeys';
import { GET_WALLET_TRANSFERS } from '../../Transactions/state/actions';
import AppliedFiltersChips from './AppliedFiltersChips';

const styles = (theme: Theme) =>
  createStyles({
    root: { marginTop: 10 },
    filter: {
      minWidth: 200,
      marginRight: 10,
    },
    clearAll: {
      textTransform: 'none',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    clearAllIcon: {
      fontSize: 12,
      padding: 5,
    },
    label: {
      float: 'left',
      padding: '2px 0',
      fontSize: 12,
      color: theme.palette.primary.light,
    },
    minWidth: {
      minWidth: 236,
    },
  });

const useStyles = makeStyles(styles);

type Props = {
  toggleFilter: PayloadAC<'earnings/toggle_filter', SaveTransactionFilter>;
  appliedFilters: Map<string, string[]>;
  clearAllEurFilters: EmptyAC<'earnings/clear_all_eur_filters'>;
  getWalletTransfersPaginated: PayloadAC<typeof GET_WALLET_TRANSFERS, any>;
};
export const earningsTransactionTypeBV = [
  'affiliate_percentage_reward,term_saving,term_saving_finished&exclude_tx_status=invested',
];

const user_id = LocalStorage.get(USER_ID);

const statuses = [
  { name: 'Completed', value: 'completed' },
  { name: 'Pending', value: 'pending' },
  { name: 'Pending Escrow', value: 'pending_escrow' },
  { name: 'Timed Escrow', value: 'timed_escrow' },
  { name: 'Escrow', value: 'escrow' },
];

const EurTransactionsFilterBar: React.FC<Props> = ({
  toggleFilter,
  appliedFilters,
  clearAllEurFilters,
  getWalletTransfersPaginated,
}: Props): JSX.Element => {
  const classes = useStyles();
  const [query, setQuery]: any = useState('');
  const appliedEmailFilter = (value: string) => {
    const object: any = {};
    appliedFilters.forEach((value, key) => {
      object[key] = value;
    });
    getWalletTransfersPaginated({
      page: 0,
      perPage: 10,
      appliedFilters: {
        ...object,
        searchInput: value,
        coinSymbol: 'EUR',
        transferType: earningsTransactionTypeBV,
      },
    });

    toggleFilter({ filterType: 'searchInput', value });
    setQuery('');
  };
  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid xs={12} sm="auto" item className={classes.filter}>
        <SacretSelect
          items={statuses}
          label="Status"
          onChange={(value: string[]) => {
            const object: any = {};
            appliedFilters.forEach((value, key) => {
              object[key] = value;
            });
            getWalletTransfersPaginated({
              user_id,
              page: 0,
              perPage: 10,
              appliedFilters: {
                ...object,
                status: value,
                coinSymbol: 'EUR',
                transferType: earningsTransactionTypeBV,
              },
            });
            toggleFilter({ filterType: 'status', value });
          }}
          stateFilterValue={appliedFilters.get('status')}
        />
      </Grid>
      <Grid xs={12} sm="auto" item className={`${classes.filter} ${classes.minWidth}`}>
        <SacretDaterangePickerSelect
          label="Choose a date"
          onChange={(value: string[]) => {
            const object: any = {};
            appliedFilters.forEach((value, key) => {
              object[key] = value;
            });
            getWalletTransfersPaginated({
              user_id,
              page: 0,
              perPage: 10,
              appliedFilters: {
                ...object,
                dateFilter: value,
                coinSymbol: 'EUR',
                transferType: earningsTransactionTypeBV,
              },
            });
            toggleFilter({ filterType: 'dateFilter', value });
          }}
          stateFilterValue={appliedFilters.get('dateFilter')}
        />
      </Grid>
      <Grid xs={12} sm="auto" item className={`${classes.filter} ${classes.minWidth}`}>
        <SacretSearchInput
          onChange={(value: string[]) => setQuery(value)}
          stateFilterValue={[query]}
          searchHandler={() => appliedEmailFilter(query)}
        />
      </Grid>
      <AppliedFiltersChips
        clearAllEurFilters={clearAllEurFilters}
        stateAppliedFilters={appliedFilters}
        toggleFilter={toggleFilter}
      />
    </Grid>
  );
};

export default EurTransactionsFilterBar;
