import { createReducer } from 'typesafe-actions';
import {
  GET_TRANSACTION_GROUPING_PAGINATED,
  GET_TRANSACTION_GROUPING_PAGINATED_FAIL,
  GET_TRANSACTION_GROUPING_PAGINATED_SUCCESS,
  GET_TRANSACTION_GROUPING_STATS,
  GET_TRANSACTION_GROUPING_STATS_FAIL,
  GET_TRANSACTION_GROUPING_STATS_SUCCESS,
  SET_TRANSACTION_GROUPING_PAGE,
  EXPORT_TRANSACTION_GROUPING,
  EXPORT_TRANSACTION_GROUPING_SUCCESS,
  EXPORT_TRANSACTION_GROUPING_FAIL,
  TRANSACTION_GROUPING_TOGGLE_FILTER,
  TRANSACTION_GROUPING_CLEAR_ALL_FILTERS,
} from './actions';
import { initialState } from './initialState';

export default createReducer(initialState, {
  [GET_TRANSACTION_GROUPING_STATS]: state => ({
    ...state,
    inProgress: true,
  }),
  [GET_TRANSACTION_GROUPING_STATS_SUCCESS]: (state, { payload }) => ({
    ...state,
    stats: payload,
    inProgress: false,
    fail: false,
  }),
  [GET_TRANSACTION_GROUPING_STATS_FAIL]: (state, { payload }) => ({
    ...state,
    inProgress: false,
    fail: true,
    error: payload,
  }),
  [GET_TRANSACTION_GROUPING_PAGINATED]: state => ({
    ...state,
    inProgress: true,
    fail: false,
  }),
  [GET_TRANSACTION_GROUPING_PAGINATED_SUCCESS]: (state, { payload }) => ({
    ...state,
    transactions: payload,
    inProgress: false,
    fail: false,
  }),
  [GET_TRANSACTION_GROUPING_PAGINATED_FAIL]: (state, { payload }) => ({
    ...state,
    inProgress: false,
    fail: true,
    error: payload,
  }),
  [SET_TRANSACTION_GROUPING_PAGE]: (state, { payload }) => ({
    ...state,
    page: payload,
  }),
  [TRANSACTION_GROUPING_TOGGLE_FILTER]: (state, { payload }) => {
    return {
      ...state,
      appliedFilters: { ...state.appliedFilters, [payload.filterType]: payload.value },
    };
  },
  [TRANSACTION_GROUPING_CLEAR_ALL_FILTERS]: state => ({
    ...state,
    appliedFilters: new Map(),
  }),
  [EXPORT_TRANSACTION_GROUPING]: state => ({
    ...state,
    exportInProgress: true,
  }),
  [EXPORT_TRANSACTION_GROUPING_SUCCESS]: state => ({
    ...state,
    exportInProgress: false,
  }),
  [EXPORT_TRANSACTION_GROUPING_FAIL]: (state, { payload }) => ({
    ...state,
    exportInProgress: false,
    error: payload,
  }),
});
