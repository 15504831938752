import { createReducer } from 'typesafe-actions';
import {
  CLEAR_TWO_FACTOR_AUTH_STATE,
  CONFIRM_TWO_FACTOR_AUTH,
  CONFIRM_TWO_FACTOR_AUTH_FAIL,
  CONFIRM_TWO_FACTOR_AUTH_SUCCESS,
  DISABLE_TWO_FACTOR_AUTH,
  DISABLE_TWO_FACTOR_AUTH_FAIL,
  DISABLE_TWO_FACTOR_AUTH_SUCCESS,
  ENABLE_TWO_FACTOR_AUTH,
  ENABLE_TWO_FACTOR_FAIL,
  ENABLE_TWO_FACTOR_SUCCESS,
  IS_TWO_FACTOR_AUTH_DIALOG_OPEN,
  IS_TWO_FACTOR_AUTH_ENABLED,
  IS_TWO_FACTOR_AUTH_ENABLED_FAIL,
  IS_TWO_FACTOR_AUTH_ENABLED_SUCCESS,
  OPEN_TWO_FACTOR_AUTH_DIALOG,
  SET_DIALOG_TITLE,
} from './actions';
import { initialState } from './initialState';

export default createReducer(initialState, {
  [SET_DIALOG_TITLE]: (state, { payload }) => ({
    ...state,
    dialogTitle: payload,
  }),
  [OPEN_TWO_FACTOR_AUTH_DIALOG]: (state, { payload }) => ({
    ...state,
    step: payload,
  }),
  [ENABLE_TWO_FACTOR_AUTH]: state => ({
    ...state,
    inProgress: true,
    fail: false,
    error: null,
  }),
  [ENABLE_TWO_FACTOR_SUCCESS]: (state, { payload }) => ({
    ...state,
    secret: payload.secret,
    qrCode: payload.data_uri,
    inProgress: false,
    fail: false,
  }),
  [ENABLE_TWO_FACTOR_FAIL]: (state, { payload }) => ({
    ...state,
    inProgress: false,
    fail: true,
    ...(payload && payload.response ? { error: payload.response.data.error } : {}),
  }),
  [CONFIRM_TWO_FACTOR_AUTH]: state => ({
    ...state,
    confirmInProgress: true,
    confirmFail: false,
    confirmError: null,
  }),
  [CONFIRM_TWO_FACTOR_AUTH_SUCCESS]: state => ({
    ...state,
    enabled: true,
    confirmInProgress: false,
    confirmFail: false,
    isDialogOpen: false,
  }),
  [CONFIRM_TWO_FACTOR_AUTH_FAIL]: (state, { payload }) => ({
    ...state,
    confirmInProgress: false,
    confirmFail: true,
    ...(payload && payload.response ? { confirmError: payload.response.data.message } : {}),
  }),
  [IS_TWO_FACTOR_AUTH_ENABLED]: state => ({ ...state, isEnabledInProgress: true }),
  [IS_TWO_FACTOR_AUTH_ENABLED_SUCCESS]: (state, { payload }) => ({
    ...state,
    enabled: payload.has_2fa,
    isEnabledInProgress: false,
  }),
  [IS_TWO_FACTOR_AUTH_ENABLED_FAIL]: state => ({
    ...state,
    isEnabledInProgress: false,
  }),
  [DISABLE_TWO_FACTOR_AUTH]: state => ({
    ...state,
    disableInProgress: true,
    disableFail: false,
  }),
  [DISABLE_TWO_FACTOR_AUTH_SUCCESS]: state => ({
    ...state,
    enabled: false,
    disableInProgress: false,
    disableFail: false,
    qrCode: '',
    secret: '',
    isDialogOpen: false,
  }),
  [DISABLE_TWO_FACTOR_AUTH_FAIL]: (state, { payload }) => ({
    ...state,
    disableInProgress: false,
    disableFail: true,
    ...(payload && payload.response ? { disableError: payload.response.data.message } : {}),
  }),
  [CLEAR_TWO_FACTOR_AUTH_STATE]: state => ({
    ...state,
    error: null,
    confirmError: null,
    disableError: null,
  }),
  [IS_TWO_FACTOR_AUTH_DIALOG_OPEN]: (state, { payload }) => ({
    ...state,
    isDialogOpen: payload,
  }),
});
