import {
  Button,
  Collapse,
  createStyles,
  Grid,
  makeStyles,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  Theme,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React, { FC } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { PayloadAC } from 'typesafe-actions';
import useExchangeCurrencyPrices from '../../../App/Home/HomeContent/Exchange/ExchangeCurrency/useExchangeCurrencyPrices';
import {
  setDialogOpen,
  setInitialInvestValues,
} from '../../../App/Home/HomeContent/Product/ProductInvest/state/actions';
import {
  getAllAppliedFiltersSelector,
  getAllAppliedTabSelector,
} from '../../../App/Home/HomeContent/Product/ProductsTable/state/selectors';
import { AppState } from '../../../App/state/initialState';
import { userStateSelector } from '../../../App/state/selectors';
import { Product } from '../../../data/repositories/product/typedef';
import { User } from '../../../data/typedefs/user';
import { Colors } from '../../../ui/style/colors';
import balanceUtils from '../../../utils/balanceUtils';
import {
  WIRE_EUR_DECIMALS,
  WIRE_EUR_MAX_FRACTION,
  WIRE_EUR_MIN_FRACTION,
} from '../../../utils/constants/amounts';
import { roles } from '../../../utils/constants/roles';
import { PRODUCTS_ROUTE } from '../../../utils/constants/routes';
import ProductDetails from '../ProductDetails';
import ProductMainInfo from '../ProductMainInfo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    expandButton: {
      textTransform: 'none',
      color: theme.palette.primary.light,
    },
    expandButtonActive: {
      fontWeight: 'bold',
      color: `${Colors.blueCharcoal}`,
    },
    expandableCell: { border: 0, padding: 0 },
    rowExpanded: {
      borderBottomWidth: 1,
      paddingBottom: 0,
      paddingTop: 0,
    },
    collapse: {
      padding: 15,
    },
    mainLabel: {
      marginTop: 3,
    },
    labels: {
      fontSize: 14,
      color: `${Colors.wildBlueYonder}`,
    },
    primaryLabel: {
      fontSize: 14,
    },
    columnWithButton: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    coverImg: {
      maxWidth: '100%',
      maxHeight: 150,
    },
    description: {
      width: '100%',
      paddingTop: '1rem',
    },
    productDescriptionColumn: {
      paddingLeft: '1rem',
    },
    moreOptionsWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '24px',
      float: 'right',
      color: `${Colors.wildBlueYonder}`,
      '&:hover, &:focus': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
        cursor: 'pointer',
        borderRadius: '4px',
        border: 0,
      },
    },
    productIcon: {
      height: 20,
    },
    fundedProgressBar: {
      height: 19,
      backgroundColor: `${Colors.white}`,
      border: `1px solid ${Colors.wildBlueYonder}`,
    },
    barColorPrimary: {
      backgroundColor: `${Colors.wildBlueYonder}`,
    },
    marginTop: {
      marginTop: '1rem',
    },
    fundedWrapper: {
      position: 'relative',
    },
    funded: {
      position: 'absolute',
      top: 3,
      right: '50%',
      transform: 'translateX(50%)',
      color: `${Colors.blueHaze}`,
      fontSize: 12,
    },
    collapseGridItem: {
      margin: 10,
    },
  }),
);

type Props = {
  product: Product | any;
  setDialogOpen: PayloadAC<'invest/dialogOpen', boolean>;
  expanded: boolean;
  setExpanded: () => void;
  user: User;
  slcDecimals: any;
  setInitialInvestValues: PayloadAC<'invest/setInitialValues', any>;
  appliedFilters: any;
  appliedTab: any;
};

const ProductsTableRow: FC<Props> = ({
  product,
  expanded,
  setExpanded,
  user,
  slcDecimals,
}: Props) => {
  const { getSelectedCoinMarketVal } = useExchangeCurrencyPrices();
  const slcEurVal = getSelectedCoinMarketVal('SLC');
  const classes = useStyles();
  const history = useHistory();
  const { backer_count, percent_funded, product_phase, time_left, investment } = product;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const onExit = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <TableRow>
        {/*Name cell*/}
        <TableCell>{investment.product_name}</TableCell>
        <TableCell align="right">
          {balanceUtils.formatBalanceToBigWithDecimals(investment.slc_sum, slcDecimals).toString()}
        </TableCell>
        <TableCell align="right">
          {balanceUtils.formatBalanceToString(
            investment.eur_sum,
            WIRE_EUR_DECIMALS,
            WIRE_EUR_MIN_FRACTION,
            WIRE_EUR_MAX_FRACTION,
          )}
        </TableCell>
        <TableCell align="right">
          {balanceUtils.calculateValue(investment.slc_sum, 7, slcEurVal)}
        </TableCell>
        <TableCell align="right">
          {roles.isOwner(user.roles) ? (
            <>
              <div className={classes.moreOptionsWrapper} onClick={handleClick}>
                <MoreVertIcon />
              </div>

              <Menu anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={onExit}>
                <MenuItem onClick={setExpanded}>{expanded ? 'Hide' : 'View'} Details</MenuItem>
                <MenuItem
                  onClick={() => {
                    history.push(`${PRODUCTS_ROUTE}/${investment.product.product.id}`);
                  }}
                >
                  Edit product
                </MenuItem>
              </Menu>
            </>
          ) : (
            <Button
              className={`${classes.expandButton} ${expanded && classes.expandButtonActive}`}
              onClick={setExpanded}
            >
              {expanded ? 'Hide' : 'View'} Details
            </Button>
          )}
        </TableCell>
      </TableRow>

      {/* Collapsed row */}
      <TableRow>
        <TableCell className={expanded ? classes.rowExpanded : classes.expandableCell} colSpan={8}>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Grid container justifyContent="space-between" className={classes.collapse}>
              <Grid item sm={12} md={4} className={classes.collapseGridItem}>
                <ProductMainInfo
                  product={{
                    ...investment,
                    backer_count,
                    percent_funded,
                    product_phase,
                    time_left,
                  }}
                />
              </Grid>
              <Grid item sm={12} md={7} className={classes.collapseGridItem}>
                <ProductDetails product={investment} hideDescription />{' '}
              </Grid>
              <Grid item xs={12}>
                <div className={classes.description}>{investment.product.description}</div>
              </Grid>
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const mapDispatchToProps = {
  setDialogOpen: setDialogOpen,
  setInitialInvestValues: setInitialInvestValues,
};

const mapStateToProps = (state: AppState) => {
  const user = userStateSelector(state);
  const appliedFilters = getAllAppliedFiltersSelector(state);
  const appliedTab = getAllAppliedTabSelector(state);

  return { user, appliedFilters, appliedTab };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsTableRow);
