export type UserState = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  twoFactor: boolean;
};

export const initialState: UserState = {
  id: '',
  email: '',
  firstName: '',
  lastName: '',
  twoFactor: false,
};
