import { Button, Chip, Grid, Theme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { createStyles, makeStyles } from '@material-ui/styles';
import React from 'react';
import { EmptyAC, PayloadAC } from 'typesafe-actions';
import { GetProductsPaginatedRequest } from '../../../../../../data/repositories/product/typedef';
import { SaveTransactionFilter } from '../../../../../../data/repositories/transaction/typedef';
import { Colors } from '../../../../../../ui/style/colors';

const styles = (theme: Theme) =>
  createStyles({
    filter: {
      minWidth: 200,
      '&:not(:first-child)': { paddingLeft: 15 },
      '&:not(:last-child)': { paddingRight: 15 },
    },
    filterDate: {
      minWidth: 270,
      '&:not(:first-child)': { paddingLeft: 15 },
      '&:not(:last-child)': { paddingRight: 15 },
    },
    valueSlider: {},
    clearAll: {
      textTransform: 'none',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      color: theme.palette.primary.light,
    },
    clearAllIcon: {
      fontSize: 14,
      padding: 5,
      color: theme.palette.primary.light,
    },
    chip: {
      backgroundColor: `${Colors.spunPearl}`,
      '&:hover': {
        backgroundColor: `${Colors.ghost}`,
      },
      textTransform: 'capitalize',
      color: `${Colors.white}`,
      margin: 10,
      padding: 5,
    },
    clearChipIcon: {
      fontSize: 14,
      padding: 5,
      color: `${Colors.white}`,
    },
  });

const useStyles = makeStyles(styles);

type Props = {
  clearAllFilters: EmptyAC<'product/clear_all_filters'>;
  appliedFiltersIds: string[];
  stateAppliedFilters: any;
  toggleFilter: PayloadAC<'product/toggle_filter', SaveTransactionFilter>;
  areFiltersApplied: boolean;
  asyncGetProductsPaginated: PayloadAC<
    'product/get_products_paginated',
    GetProductsPaginatedRequest
  >;
  appliedTab: any;
};

type Chip = {
  value: string;
  type: string;
};

const AppliedFiltersChips = ({
  clearAllFilters,
  stateAppliedFilters,
  toggleFilter,
  areFiltersApplied,
  asyncGetProductsPaginated,
  appliedTab,
}: Props) => {
  const classes = useStyles();
  let chips: any = [];
  chips = [];
  Object.entries(stateAppliedFilters).forEach(([key, value]: [any, any]) => {
    switch (key) {
      case 'status':
        if (value) chips.push({ value });
        break;
      case 'name':
        if (value) chips.push({ value: 'Name: ' + value, type: key });
        break;
    }
  });

  const onDelete = (chip: Chip) => {
    let newFilterValue;
    if (chip.type != 'valueRange' && chip.type != 'dateFilter') {
      const filter = stateAppliedFilters[chip.type];
      if (filter) {
        if (!Array.isArray(filter) || filter.length === 1) {
          newFilterValue = undefined;
        } else {
          newFilterValue = filter.filter((f: any) => f != chip.value);
          newFilterValue = newFilterValue.length > 0 ? newFilterValue : undefined;
        }
      }
    }
    if (!newFilterValue && Object.keys(stateAppliedFilters).length === 1) {
      asyncGetProductsPaginated({
        page: 0,
        perPage: 10,
        appliedFilters: appliedTab.product ? { product: appliedTab.product } : null,
        appliedTab,
      });
      clearAllFilters();
    } else {
      asyncGetProductsPaginated({
        page: 0,
        perPage: 10,
        appliedFilters: { ...stateAppliedFilters, [chip.type]: newFilterValue },
        appliedTab,
      });
      toggleFilter({ filterType: chip.type, value: newFilterValue });
    }
  };

  const renderType = (type: any) => {
    switch (type) {
      case 'finished':
        return 'Completed';
      case 'frozen':
        return 'Frozen';
      case 'pre-ico':
        return 'Pre sale';
      case 'ico':
        return 'Sale';
      default:
        return type;
    }
  };

  return (
    <Grid item xs={12}>
      {chips.map((chip: any) => (
        <Chip
          key={chip.value}
          size="small"
          clickable={true}
          deleteIcon={<CloseIcon className={classes.clearChipIcon} />}
          label={renderType(chip.value)}
          onDelete={() => onDelete(chip)}
          className={classes.chip}
        />
      ))}
      {areFiltersApplied && (
        <Button
          size="small"
          className={classes.clearAll}
          onClick={() => {
            asyncGetProductsPaginated({
              page: 0,
              perPage: 10,
              appliedFilters: appliedTab.product ? { product: appliedTab.product } : null,
              appliedTab,
            });
            clearAllFilters();
          }}
        >
          <CloseIcon className={classes.clearAllIcon} />
          Clear all
        </Button>
      )}
    </Grid>
  );
};

export default AppliedFiltersChips;
