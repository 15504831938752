import { connect } from 'react-redux';
import { AppState } from '../../../state/initialState';
import { setCurrentHomePage } from '../../state/actions';
import Invites from './Invites';
import { clearAllFilters } from './MyInvitesTable/state/actions';
import { myInvitesTableStateSelector } from './state/selectors';

const mapStateToProps = (state: AppState) => ({
  invitesState: myInvitesTableStateSelector(state),
});
const mapDispatchToProps = {
  setCurrentHomePage: setCurrentHomePage,
  clearAllFilters: clearAllFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(Invites);
