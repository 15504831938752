import { Big } from 'big.js';
import { Wallet } from '../data/repositories/wallet/typedefs';
import balanceUtils from './balanceUtils';

type Props = {
  exchangeCurrency: any;
  walletState: any;
};

export const walletBalance = ({ walletState }: Props) => {
  const wallets: Wallet[] = Object.values(walletState);
  const wallet: Wallet | undefined = wallets.find((wallet: Wallet) => {
    return wallet.symbol == 'SLC';
  });

  const walletBalance = Big(
    wallet
      ? wallet.balance
        ? balanceUtils.formatBalanceToBigWithDecimals(wallet.balance, wallet.decimals)
        : '0'
      : '0',
  );
  return {
    walletBalanceNum: Number(balanceUtils.formatBigToStringWithCommasAndRound(walletBalance, 2, 2)),
    wallet: wallet,
  };
};

export const getEuroBalance = wallets => {
  const euroWallet = wallets.find(wallet => wallet.symbol === 'WALLET_EUR');
  return euroWallet.balance;
};
