import { all } from 'redux-saga/effects';
import admin from '../HomeContent/Admin/state/saga';
import affiliate from '../HomeContent/Affiliate/state/sagas';
import dashboard from '../HomeContent/Dashboard/state/sagas';
import exchange from '../HomeContent/Exchange/state/sagas';
import infoDialog from '../HomeContent/InfoDialog/state/sagas';
import invites from '../HomeContent/Invites/state/sagas';
import orders from '../HomeContent/Orders/state/sagas';
import product from '../HomeContent/Product/state/sagas';
import settings from '../HomeContent/Settings/state/sagas';
import transactions from '../HomeContent/Transactions/state/sagas';
import wallet from '../HomeContent/Wallet/state/sagas';
import common from './common/sagas';

export default function* () {
  yield all([
    wallet(),
    common(),
    settings(),
    affiliate(),
    transactions(),
    exchange(),
    orders(),
    product(),
    infoDialog(),
    admin(),
    dashboard(),
    invites(),
  ]);
}
