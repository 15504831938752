import { Dialog, DialogTitle, Grid, IconButton, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect } from 'react';
import { EmptyAC, PayloadAC } from 'typesafe-actions';
import WithdrawIcon from '../../../../../../assets/deposit.svg';
import Withdraw from '../../../../../../assets/withdraw.svg';
import { Colors } from '../../../../../../ui/style/colors';
import { ComponentState } from '../../state/initialState';
import WalletStatusMessage from '../WalletMessage';
import WithdrawCoins2fa from '../WithdrawCoins2fa';
import WithdrawCoinsConfirmDialog from '../WithdrawCoinsConfirmDialog';
import WithdrawCoinsDialog from '../WithdrawCoinsDialog';

const styles = (theme: Theme) =>
  createStyles({
    dialog: {
      height: 'center',
      width: 'center',
      borderRadius: 6,
      backgroundColor: 'transparent',
      paddingLeft: 30,
      paddingRight: 30,
    },
    label: {
      color: `${Colors.blueCharcoal}`,
      fontSize: 18,
      fontWeight: 600,
      letterSpacing: 1.13,
    },
    infoLabel: {
      color: theme.palette.primary.light,
      fontSize: 12,
      fontWeight: 600,
    },
    button: {
      marginTop: 30,
      marginBottom: 30,
    },
    backButton: {
      background: 'none',
      border: 'none',
      marginTop: 30,
      marginBottom: 30,
      fontSize: 14,
    },
    copyToClipbord: {
      boxSizing: 'border-box',
      height: 38,
      border: `${theme.palette.secondary.light}`,
      borderRadius: 6,
      backgroundColor: `${Colors.white}`,
      boxShadow: '0 2px 10px 0 rgba(132,141,182,0.25)',
      display: 'flex',
    },
    closeButton: {
      position: 'absolute',
      right: 5,
      top: 5,
      color: theme.palette.primary.light,
    },
    dialogTittleText: {
      color: `${Colors.blueCharcoal}`,
      fontSize: 18,
      fontWeight: 600,
      letterSpacing: 1.13,
      display: 'flex',
      alignItems: 'center',
    },
    dialogTitle: {
      marginTop: 25,
      marginLeft: 25,
      marginRight: 25,
      padding: 0,
      minWidth: '320px',
    },
    depositIcon: {
      marginRight: 20,
    },
    withdraw: {
      textAlign: 'center',
      marginBottom: 30,
    },
  });
const useStyles = makeStyles(styles);

type Props = {
  openWithdrawDialog: PayloadAC<'wallet/open_withdraw_dialog', number>;
  isWithdrawDialogOpen: PayloadAC<'wallet/is_withdraw_dialog_open', boolean>;
  clearCommonState: EmptyAC<'wallet/clear_common_state'>;
  step: number;
  componentState: ComponentState;
};

const WithdrawDialog: React.FC<Props> = (props: Props) => {
  const classes = useStyles();

  const { step, openWithdrawDialog, componentState, isWithdrawDialogOpen, clearCommonState } =
    props;

  const onClose = () => {
    clearCommonState();
    isWithdrawDialogOpen(false);
    openWithdrawDialog(0);
  };
  useEffect(() => {
    if (step > 0) {
      isWithdrawDialogOpen(true);
    }
  }, [step]);

  const onExit = () => {
    openWithdrawDialog(0);
  };

  return (
    <Dialog
      open={componentState.isDialogOpen ? componentState.isDialogOpen : step === 4 ? true : false}
      className={classes.dialog}
      TransitionProps={{ onExited: onExit }}
    >
      <DialogTitle disableTypography className={classes.dialogTitle}>
        {step != 2 ? (
          <Grid container item xs={12} justifyContent="space-between">
            <Typography className={classes.dialogTittleText}>
              <img src={WithdrawIcon} className={classes.depositIcon} />
              Withdraw Currency
            </Typography>

            {onClose ? (
              <IconButton className={classes.closeButton} onClick={onClose}>
                <CloseIcon />
              </IconButton>
            ) : null}
          </Grid>
        ) : (
          <div className={classes.withdraw}>
            <img src={Withdraw} />
            {onClose ? (
              <IconButton className={classes.closeButton} onClick={onClose}>
                <CloseIcon />
              </IconButton>
            ) : null}
          </div>
        )}
      </DialogTitle>
      {step === 1 && <WithdrawCoinsDialog />}
      {step === 2 && <WithdrawCoinsConfirmDialog />}
      {step === 3 && <WithdrawCoins2fa />}
      {step === 4 && <WalletStatusMessage />}
    </Dialog>
  );
};

export default WithdrawDialog;
