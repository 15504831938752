import { connect } from 'react-redux';
import { AppState } from '../../../../../../state/initialState';
import {
  userTransactionHistoryClearAllFilters,
  userTransactionHistoryToggleFilter,
  getWalletTransfersPaginated,
} from '../../state/actions';
import { getUserTransactionHistoryAppliedFiltersSelector } from '../../state/selectors';
import WalletTransfersFilterBar from './WalletTransfersFilterBar';

const mapStateToProps = (state: AppState) => {
  const appliedFilters = getUserTransactionHistoryAppliedFiltersSelector(state);
  return { appliedFilters };
};

const dispatchStateToProps = {
  getWalletTransfersPaginated: getWalletTransfersPaginated.request,
  toggleFilter: userTransactionHistoryToggleFilter,
  clearAllFilters: userTransactionHistoryClearAllFilters,
};

export default connect(mapStateToProps, dispatchStateToProps)(WalletTransfersFilterBar);
