import { CircularProgress, DialogContent, Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { PayloadAC } from 'typesafe-actions';
import VerificationCodeInput from '../../../../../../../components/VerificationCodeInput';
import { ConfirmTwoFactorAuthRequest } from '../../../../../../../data/repositories/account/typedefs';
import { Colors } from '../../../../../../../ui/style/colors';

const styles = (theme: Theme) =>
  createStyles({
    dialog: {
      height: 'center',
      width: 'center',
      borderRadius: 6,
      backgroundColor: 'transparent',
      boxShadow: '0 10px 30px 0 rgba(132,141,182,0.25)',
      paddingLeft: 30,
      paddingRight: 30,
    },
    label: {
      color: `${Colors.blueCharcoal}`,
      fontSize: 18,
      fontWeight: 600,
      letterSpacing: 1.13,
    },
    infoLabel: {
      color: theme.palette.primary.light,
      fontSize: 12,
      fontWeight: 600,
      marginLeft: 10,
    },
    keepItButton: {
      borderRadius: 6,
      boxShadow: '0 2px 10px 0 rgba(132,141,182,0.25)',
      fontSize: 14,
    },

    closeButton: {
      position: 'absolute',
      right: 5,
      top: 5,
      color: theme.palette.primary.light,
    },
    error: {
      color: `${Colors.red}`,
      fontSize: 12,
      marginTop: 3,
    },
    circularProgress: {
      marginTop: 30,
      marginBottom: 30,
      justifyContent: 'center',
      display: 'flex',
    },
  });
const useStyles = makeStyles(styles);

type Props = {
  disableTwoFactorAuth: PayloadAC<'settings/disable_two_factor_auth', ConfirmTwoFactorAuthRequest>;
  step: number;
  disableInProgress: boolean;
  disableError: Error | null;
};

const Settings2faCodeDisableDialog: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { disableTwoFactorAuth, disableInProgress, disableError } = props;

  return (
    <DialogContent className={classes.dialog}>
      <Typography className={classes.infoLabel} component="p" style={{ marginBottom: 24 }}>
        Please, enter your two-factor authentication code once more
      </Typography>
      {disableInProgress ? (
        <Grid container className={classes.circularProgress} alignContent="center">
          <CircularProgress color="secondary" size={60} />
        </Grid>
      ) : (
        <div>
          <VerificationCodeInput disable2fa={disableTwoFactorAuth} error={disableError} />
        </div>
      )}
    </DialogContent>
  );
};

export default Settings2faCodeDisableDialog;
