import { Breadcrumbs, List, ListItem, ListItemIcon, ListItemText, Paper } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { isEmpty } from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import { ReferralTree } from '../../data/repositories/affiliate/typedefs';
import { Colors } from '../../ui/style/colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 200,
      backgroundColor: theme.palette.background.paper,
      borderRight: `1px solid ${Colors.alto}`,
    },
    paper: {
      width: '100%',
      display: 'flex',
      overflowY: 'auto',
      boxShadow: '0 10px 30px 0 rgba(132,141,182,0.25)',
      backgroundColor: `${Colors.white}`,
      borderRadius: '3px',
    },
    activeList: {
      width: '100%',
      maxWidth: 200,
      backgroundColor: `${Colors.alto}`,
    },
    listName: {
      '& span': {
        overflowX: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    arrowIcon: {
      minWidth: '24px',
    },
    breadcrumbsRoot: {
      padding: '10px',
      marginTop: '10px',
      marginBottom: '10px',
    },
  }),
);

type Props = {
  referralTree: any;
  level: number;
  breadcrumbs: any;
};

export const AffiliateTree = ({ referralTree, level: currLevel, breadcrumbs }: Props) => {
  const classes = useStyles();
  const dispath = useDispatch();

  const handleAffiliateTree = (referral_id: string, level: number, email: string) => {
    dispath({
      type: 'affiliate/get_referral_tree',
      payload: { referralId: referral_id, level, email },
    });
  };

  function renderColumn(affiliates: any, level: number) {
    return (
      <List
        dense
        disablePadding
        component="nav"
        className={level === currLevel - 1 ? classes.activeList : classes.root}
        aria-label="contacts"
      >
        {affiliates.map((affiliate: ReferralTree) => (
          <ListItem
            key={affiliate.referral_id}
            selected={breadcrumbs[level + 1] && breadcrumbs[level + 1].email === affiliate.email}
            onClick={() => handleAffiliateTree(affiliate.referral_id, level + 1, affiliate.email)}
            button
          >
            <ListItemText className={classes.listName} primary={affiliate.email} />
            {affiliate.has_children && (
              <ListItemIcon className={classes.arrowIcon}>
                <ArrowRightIcon />
              </ListItemIcon>
            )}
          </ListItem>
        ))}
      </List>
    );
  }
  const handleBreadcrumb = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    { level, email, id }: any,
  ) => {
    event.preventDefault();
    dispath({
      type: 'affiliate/get_referral_tree',
      payload: { referralId: id, level, email },
    });
  };
  const clearTree = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    dispath({ type: 'affiliate/clear_all_filters', payload: {} });
    dispath({ type: 'affiliate/get_referrals_paginated', payload: { page: 0, perPage: 10 } });
  };
  return (
    <>
      <Paper className={classes.breadcrumbsRoot}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Link color="inherit" href="/" onClick={clearTree}>
            All affiliates
          </Link>
          {Object.values(breadcrumbs).map((breadcrumb: any, index) => {
            if (currLevel - 1 === index)
              return <Typography color="textPrimary">{breadcrumb.email}</Typography>;
            return (
              <Link
                key={index}
                color="inherit"
                href="/"
                onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
                  handleBreadcrumb(e, { ...breadcrumb, level: index + 1 })
                }
              >
                {breadcrumb.email}
              </Link>
            );
          })}
        </Breadcrumbs>
      </Paper>
      <Paper className={classes.paper}>
        {!isEmpty(referralTree) &&
          Object.values(referralTree).map(
            (tree: any, index: number) => tree.length > 0 && renderColumn(tree, index),
          )}
      </Paper>
    </>
  );
};
