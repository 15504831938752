import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import { ProductCurrencyRequestState } from './initialState';

export const SET_SELECTED_OPTION = 'invest/set_selected_option';

export const INVEST_CURRENCY_SAVE_STATE = 'invest/save_state';

export const SET_SELECTED_TAB = 'invest/set_selected_tab';

//trigger invest form
export const PRODUCT_INVEST = 'invest/product';
export const PRODUCT_INVEST_SUCCESS = `${PRODUCT_INVEST}/success`;
export const PRODUCT_INVEST_FAIL = `${PRODUCT_INVEST}/fail`;

export const SET_INVEST_OPEN = 'invest/dialogOpen';
export const SET_INVEST_INITIAL = 'invest/setInitialValues';

export const setSelectedOption = createStandardAction(SET_SELECTED_OPTION)<string>();

export const investCurrencySaveState = createStandardAction(
  INVEST_CURRENCY_SAVE_STATE,
)<ProductCurrencyRequestState>();

export const setSelectedTab = createStandardAction(SET_SELECTED_TAB)<number>();

export const productInvest = createAsyncAction(
  PRODUCT_INVEST,
  PRODUCT_INVEST_SUCCESS,
  PRODUCT_INVEST_FAIL,
)<any, any, Error>();

export const setDialogOpen = createStandardAction(SET_INVEST_OPEN)<boolean>();
export const setInitialInvestValues = createStandardAction(SET_INVEST_INITIAL)<boolean>();
