import { Grid } from '@material-ui/core';
import React from 'react';
import { Product } from '../../../../../data/repositories/product/typedef';
import ProductCard from './ProductCard';

type Props = {
  products: { [id: string]: Product } | null;
};

const ProductsCard: React.FC<Props> = (props: Props): JSX.Element => {
  const { products } = props;

  return (
    <Grid container spacing={3}>
      {products &&
        Object.entries(products).map(([key, product]) => {
          return product.product && <ProductCard key={key} product={product} />;
        })}
    </Grid>
  );
};

export default ProductsCard;
