import { Box, CircularProgress } from '@material-ui/core';
import React, { useEffect } from 'react';
import { LOGIN_ENDPOINT } from '../../../utils/constants/api';

const Autologin = () => {
  const params = new URLSearchParams(window.location.search);
  const status = params.get('status');
  const baseURL = process.env.REACT_APP_IDENTITY_API_URL;

  const redirectUrl = () => {
    if (status) {
      return `${process.env.REACT_APP_HOST}?status=${status}`;
    }
    return process.env.REACT_APP_HOST;
  };

  useEffect(() => {
    window.location.href = `${baseURL}${LOGIN_ENDPOINT}?response_type=code&client_id=${
      process.env.REACT_APP_CLIENT_ID
    }&redirect_uri=${redirectUrl()}&scope=any`;
  }, []);

  return (
    <Box style={{ display: 'flex', justifyContent: 'center' }}>
      <CircularProgress size={50} />
    </Box>
  );
};
export default Autologin;
