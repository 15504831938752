import { createSelector } from 'reselect';
import { AppState } from '../../../../../state/initialState';

const getProductFormState = ({
  home: {
    product: { productForm },
  },
}: AppState) => productForm;

export const getProductFormStateSelector = createSelector(
  getProductFormState,
  productForm => productForm,
);
