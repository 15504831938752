import {
  CRYPTO_MAX_FRACTION,
  CRYPTO_MIN_FRACTION,
  WIRE_EUR_MAX_FRACTION,
  WIRE_EUR_MIN_FRACTION,
} from '../constants/amounts';

type Param = (symbol: string, type?: 'max' | 'min') => number;

export const getCurrencyFraction: Param = (symbol, type = 'max') => {
  if (type === 'min') {
    return symbol === 'EUR' || symbol === 'WALLET_EUR'
      ? WIRE_EUR_MIN_FRACTION
      : CRYPTO_MIN_FRACTION;
  } else {
    return symbol === 'EUR' || symbol === 'WALLET_EUR'
      ? WIRE_EUR_MAX_FRACTION
      : CRYPTO_MAX_FRACTION;
  }
};
