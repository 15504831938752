/* eslint-disable */
import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import {
  GetPaginatedDataRequest,
  SaveFilter,
  ExportUserEarningsRequest,
  UserEarningsStats,
  UserEarningsTable,
} from '../../../../../../data/repositories/admin/typedefs';

export const GET_USER_EARNINGS_STATS = 'admin/get_user_earnings_stats';
export const GET_USER_EARNINGS_STATS_SUCCESS = 'admin/get_user_earnings_stats_success';
export const GET_USER_EARNINGS_STATS_FAIL = 'admin/get_user_earnings_stats_fail';

export const GET_USER_EARNINGS_TABLE_PAGINATED = 'admin/get_user_earnings_table_paginated';
export const GET_USER_EARNINGS_TABLE_PAGINATED_SUCCESS =
  'admin/get_user_earnings_table_paginated/success';
export const GET_USER_EARNINGS_TABLE_PAGINATED_FAILED =
  'admin/get_user_earnings_table_paginated/fail';

export const EXPORT_USERS_EARNINGS = 'csv-export/users_earnings';
export const EXPORT_USERS_EARNINGS_SUCCESS = 'csv-export/users_earnings_success';
export const EXPORT_USERS_EARNINGS_FAIL = 'csv-export/users_earnings_fail';

export const TOGGLE_FILTER = 'admin/toggle_filter';
export const CLEAR_ALL_FILTERS = 'admin/clear_all_filters';

export const SET_USER_EARNINGS_TABLE_PAGE = 'admin/set_user_earnings_table_page';

export const asyncGetUserEarningsStats = createAsyncAction(
  GET_USER_EARNINGS_STATS,
  GET_USER_EARNINGS_STATS_SUCCESS,
  GET_USER_EARNINGS_STATS_FAIL,
)<undefined, UserEarningsStats, Error>();

export const asyncGetUserEarningsTablePaginated = createAsyncAction(
  GET_USER_EARNINGS_TABLE_PAGINATED,
  GET_USER_EARNINGS_TABLE_PAGINATED_SUCCESS,
  GET_USER_EARNINGS_TABLE_PAGINATED_FAILED,
)<GetPaginatedDataRequest, UserEarningsTable, Error>();

export const asyncExportUsersEarning = createAsyncAction(
  EXPORT_USERS_EARNINGS,
  EXPORT_USERS_EARNINGS_SUCCESS,
  EXPORT_USERS_EARNINGS_FAIL,
)<ExportUserEarningsRequest, undefined, Error>();

export const toggleFilter = createStandardAction(TOGGLE_FILTER)<SaveFilter>();
export const clearAllFilters = createStandardAction(CLEAR_ALL_FILTERS)<undefined>();
export const setUserEarningsTablePage = createStandardAction(SET_USER_EARNINGS_TABLE_PAGE)<
  number
>();
