export type ProductFormState = {
  id?: string;
  name: string;
  description: string;
  pre_ico_price?: number | '';
  ico_price?: number | '';
  soft_cap?: number | '';
  hard_cap?: number | '';
  buy_limit_high?: number | '';
  buy_limit_low?: number | '';
  status?: 'created' | 'published';
  pre_ico_period_start: Date;
  pre_ico_period_end: Date;
  ico_period_start: Date;
  ico_period_end: Date;
  icon: string;
  photo: string;
  website: string;
  white_paper: string;
  inProgress: boolean;
  error: any;
  fail: boolean;
};

const ONE_MONTH = 2629800000;
const ONE_DAY = 86400000;

export const initialState: ProductFormState = {
  name: '',
  description: '',
  pre_ico_price: '',
  ico_price: '',
  soft_cap: '',
  hard_cap: '',
  buy_limit_high: '',
  buy_limit_low: '',
  pre_ico_period_start: new Date(new Date().getTime() + ONE_DAY),
  pre_ico_period_end: new Date(new Date().getTime() + ONE_MONTH),
  ico_period_start: new Date(new Date().getTime() + ONE_MONTH + ONE_DAY),
  ico_period_end: new Date(new Date().getTime() + 2 * ONE_MONTH),
  icon: '',
  photo: '',
  website: '',
  white_paper: '',
  inProgress: false,
  error: '',
  fail: false,
};
