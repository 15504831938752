import { Button, Chip, Grid, Theme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { createStyles, makeStyles } from '@material-ui/styles';
import React from 'react';
import { EmptyAC, PayloadAC } from 'typesafe-actions';
import LocalStorage from '../../../../../../data/LocalStorage';
import { SaveTransactionFilter } from '../../../../../../data/repositories/transaction/typedef';
import { Colors } from '../../../../../../ui/style/colors';
import { USER_ID } from '../../../../../../utils/constants/localStorageKeys';
import { GET_WALLET_TRANSFERS } from '../../../Transactions/state/actions';
import { earningsTransactionTypeBV } from '../EurTransactionsFilterBar';

const styles = (theme: Theme) =>
  createStyles({
    filter: {
      minWidth: 200,
      '&:not(:first-child)': { paddingLeft: 15 },
      '&:not(:last-child)': { paddingRight: 15 },
    },
    filterDate: {
      minWidth: 270,
      '&:not(:first-child)': { paddingLeft: 15 },
      '&:not(:last-child)': { paddingRight: 15 },
    },
    valueSlider: {},
    clearAll: {
      textTransform: 'none',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      color: theme.palette.primary.light,
    },
    clearAllIcon: {
      fontSize: 14,
      padding: 5,
      color: theme.palette.primary.light,
    },
    chip: {
      backgroundColor: `${Colors.spunPearl}`,
      '&:hover': {
        backgroundColor: `${Colors.ghost}`,
      },
      color: `${Colors.white}`,
      margin: 10,
      padding: 5,
    },
    clearChipIcon: {
      fontSize: 14,
      padding: 5,
      color: `${Colors.white}`,
    },
  });

const useStyles = makeStyles(styles);

type Props = {
  clearAllEurFilters: EmptyAC<'earnings/clear_all_eur_filters'>;
  appliedFiltersIds: string[];
  stateAppliedFilters: Map<string, string[]>;
  toggleFilter: PayloadAC<'earnings/toggle_filter', SaveTransactionFilter>;
  areFiltersApplied: boolean;
  getWalletTransfersPaginated: PayloadAC<typeof GET_WALLET_TRANSFERS, any>;
};

type Chip = {
  value: string;
  type: string;
};

const AppliedFiltersChips = ({
  clearAllEurFilters,
  appliedFiltersIds,
  stateAppliedFilters,
  toggleFilter,
  areFiltersApplied,
  getWalletTransfersPaginated,
}: Props) => {
  const classes = useStyles();
  const user_id = LocalStorage.get(USER_ID);

  const chips = [];
  appliedFiltersIds.push('coinSymbol', 'transferType');
  for (const index in appliedFiltersIds) {
    const filter = stateAppliedFilters.get(appliedFiltersIds[index]);
    switch (appliedFiltersIds[index]) {
      case 'status':
        if (filter) {
          for (const i in filter) {
            chips.push({ value: filter[i], type: appliedFiltersIds[index] });
          }
        }
        break;
      case 'valueRange':
      case 'dateFilter':
        if (filter) {
          chips.push({
            value: `${filter[0]} - ${filter[1]} ${
              appliedFiltersIds[index] == 'valueRange' ? 'EUR' : ''
            }`,
            type: appliedFiltersIds[index],
          });
        }
        break;
      case 'searchInput':
        if (filter) {
          chips.push({ value: filter[0], type: appliedFiltersIds[index] });
        }
    }
  }
  const onDelete = (chip: Chip) => {
    let newFilterValue;
    if (chip.type != 'valueRange' && chip.type != 'dateFilter') {
      const filter = stateAppliedFilters.get(chip.type);
      if (filter) {
        newFilterValue = filter.filter(f => f != chip.value);
        newFilterValue = newFilterValue.length > 0 ? newFilterValue : undefined;
      }
    }
    const object: any = {};
    stateAppliedFilters.forEach((value, key) => {
      object[key] = value;
    });

    if (!newFilterValue && stateAppliedFilters.size == 1) {
      getWalletTransfersPaginated({
        user_id,
        page: 0,
        perPage: 10,
        appliedFilters: { coinSymbol: 'EUR', transferType: earningsTransactionTypeBV },
      });
      clearAllEurFilters();
    } else {
      getWalletTransfersPaginated({
        user_id,
        page: 0,
        perPage: 10,
        appliedFilters: { ...object, [chip.type]: newFilterValue },
      });
      toggleFilter({ filterType: chip.type, value: newFilterValue });
    }
  };
  const renderType = (type: any) => {
    switch (type) {
      case 'airdrop_reward':
        return 'Airdrop';
      case 'new_user_reward':
        return 'Registration reward';
      case 'new_merchant_reward':
        return 'Became Partner';
      case 'buy':
        return 'Buy';
      case 'deposit':
        return 'Deposit';
      case 'withdraw':
        return 'Withdraw';
      case 'exchange':
        return 'Exchange';
      case 'merchant_parent_reward':
        return 'Affiliate became Partner';
      case 'new_user_affiliate_reward':
        return 'New Affiliate';
      case 'payment_sent':
        return 'Purchase';
      case 'merchant_income':
        return 'Sale';
      case 'pool_reward':
        return 'Pool reward';
      case 'platform_pool_reward':
        return 'Platform pool reward';
      case 'premium_user_reward':
        return 'Affiliate package purchase';
      case 'premium_user_affiliate_reward':
        return 'Affiliate purchased Affiliate package';
      case 'affiliate_percentage_reward,term_saving,term_saving_finished&exclude_tx_status=invested':
        return 'BV';
      case 'sell':
        return 'Sell';
      case 'VAT':
        return 'VAT';
      case 'timed_escrow':
        return 'Timed Escrow';
      case 'waiting_for_approval':
        return 'Waiting for approval';
      case 'pending_escrow':
        return 'Pending Escrow';
      case 'escrow':
        return 'Escrow';
      case 'term_saving':
        return 'HODL';
      case 'invested':
        return 'In HODL';
      case 'completed':
        return 'Completed';
      case 'pending':
        return 'Pending';
      default:
        return type;
    }
  };
  return (
    <Grid item xs={12}>
      {chips.map(chip => (
        <Chip
          key={chip.value}
          size="small"
          clickable={true}
          deleteIcon={<CloseIcon className={classes.clearChipIcon} />}
          label={renderType(chip.value)}
          onDelete={() => onDelete(chip)}
          className={classes.chip}
        />
      ))}
      {areFiltersApplied && appliedFiltersIds.length > 2 && (
        <Button
          size="small"
          className={classes.clearAll}
          onClick={() => {
            getWalletTransfersPaginated({
              user_id,
              page: 0,
              perPage: 10,
              appliedFilters: { coinSymbol: 'EUR', transferType: earningsTransactionTypeBV },
            });
            clearAllEurFilters();
          }}
        >
          <CloseIcon className={classes.clearAllIcon} />
          Clear all
        </Button>
      )}
    </Grid>
  );
};

export default AppliedFiltersChips;
