import { all, put, takeEvery } from 'redux-saga/effects';
import LocalStorage from '../../../../../../data/LocalStorage';
import api from '../../../../../../data/repositories/admin';
import {
  GET_USER_EARNINGS_PAGINATED_ENDPOINT,
  EXPORT_USERS_EARNINGS_ENDPOINT,
} from '../../../../../../utils/constants/api';
import { USER_ID } from '../../../../../../utils/constants/localStorageKeys';
import {
  asyncGetUserEarningsTablePaginated,
  asyncGetUserEarningsStats,
  GET_USER_EARNINGS_TABLE_PAGINATED,
  asyncExportUsersEarning,
  EXPORT_USERS_EARNINGS,
  setUserEarningsTablePage,
} from './actions';
import {
  GetUserEarningsResponse,
  UserEarningsStats,
} from '../../../../../../data/repositories/admin/typedefs';
import moment from 'moment';
import { saveAs } from 'file-saver';

const mapURL = (params: any) => {
  let newParams = {};
  Object.keys(params).forEach(key => {
    if (params[key] !== null) newParams = { ...newParams, [key]: params[key].toString() };
  });
  return new URLSearchParams(newParams).toString();
};

const getfilter = (action: any) => {
  const filtersAvailable = [
    'new_user_reward',
    'new_user_affiliate_reward',
    'new_merchant_reward',
    'merchant_parent_reward',
    'affiliate_percentage_reward',
    'premium_user_reward',
    'premium_user_affiliate_reward',
  ];
  return {
    origin:
      action.payload.appliedFilters &&
      action.payload.appliedFilters.transactionType &&
      action.payload.appliedFilters.transactionType.length > 0
        ? action.payload.appliedFilters.transactionType.filter((type: any) =>
            filtersAvailable.includes(type),
          )
        : filtersAvailable,
    dateFrom:
      action.payload.appliedFilters &&
      action.payload.appliedFilters.dateFilter &&
      action.payload.appliedFilters.dateFilter.length
        ? moment(action.payload.appliedFilters.dateFilter[0], 'DD/MM/YYYY').format(
            'YYYY-MM-DD HH:mm:ss',
          )
        : null,
    dateTo:
      action.payload.appliedFilters &&
      action.payload.appliedFilters.dateFilter &&
      action.payload.appliedFilters.dateFilter.length
        ? moment(action.payload.appliedFilters.dateFilter[1], 'DD/MM/YYYY').endOf('day').toDate() >
          moment().toDate()
          ? moment().format('YYYY-MM-DD HH:mm:ss')
          : moment(action.payload.appliedFilters.dateFilter[1], 'DD/MM/YYYY')
              .endOf('day')
              .format('YYYY-MM-DD HH:mm:ss')
        : null,
    tx_status:
      action.payload.appliedFilters && action.payload.appliedFilters.status
        ? action.payload.appliedFilters.status
        : null,
    user_email:
      action.payload.appliedFilters && action.payload.appliedFilters.searchByEmailInput
        ? action.payload.appliedFilters.searchByEmailInput
        : null,
  };
};

export function* getUserEarningsPaginated$(
  action: ReturnType<typeof asyncGetUserEarningsTablePaginated.request>,
) {
  try {
    const userId = LocalStorage.get(USER_ID);
    const filter = getfilter(action);
    const users: GetUserEarningsResponse = yield api.getUserEarningsPaginated(
      GET_USER_EARNINGS_PAGINATED_ENDPOINT(
        userId ? userId : '',
        action.payload.page,
        action.payload.perPage,
        mapURL(filter) && '&' + mapURL(filter),
      ),
    );

    const stats: UserEarningsStats = {
      totalEarningInfoCount: users.earnings_info_count,
    };

    yield put(asyncGetUserEarningsStats.success(stats));

    yield put(setUserEarningsTablePage(action.payload.page));
    yield put(
      asyncGetUserEarningsTablePaginated.success({
        totalEarned: users.total_earned,
        totalAmount: users.total_amount,
        usersEarningsInfo: users.user_earnings_info,
      }),
    );
  } catch (error) {
    yield put(
      asyncGetUserEarningsTablePaginated.failure(
        error && error.message ? error.message : 'Cannot fetch user earnings data.',
      ),
    );
  }
}

export function* exportUsersEarnings$(action: any) {
  try {
    const userId = LocalStorage.get(USER_ID);
    const filter = getfilter(action);

    const response = yield api.exportUsersEarnings(
      EXPORT_USERS_EARNINGS_ENDPOINT(
        userId ? userId : '',
        action.payload.page,
        action.payload.perPage,
        mapURL(filter) && '&' + mapURL(filter),
      ),
    );

    const csvData = new Blob([response], { type: 'text/csv;charset=utf-8;' });

    saveAs(csvData, 'UsersEarnings.csv');

    yield put(asyncExportUsersEarning.success());
  } catch (error) {
    yield put(asyncExportUsersEarning.failure(error));
  }
}

export default function* () {
  yield all([
    takeEvery(GET_USER_EARNINGS_TABLE_PAGINATED, getUserEarningsPaginated$),
    takeEvery(EXPORT_USERS_EARNINGS, exportUsersEarnings$),
  ]);
}
