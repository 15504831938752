import React from 'react';
import ProfileInfoShort from '../../../../../components/ProfileInfoShort';
import { ProfileInfoState } from '../Membership/state/initialState';

type Props = {
  profileInfo: ProfileInfoState;
};

const Profile: React.FC<Props> = ({
  profileInfo: { affiliate_package_info, slc_address, kyc_info, account_info },
}: Props): JSX.Element => {
  const { first_name, last_name, middle_name, gender } = kyc_info || account_info;
  return (
    <ProfileInfoShort
      firstName={first_name}
      lastName={last_name}
      middleName={middle_name}
      gender={gender}
      membershipLevel={
        affiliate_package_info ? affiliate_package_info.affiliate_package_identifier : 'None'
      }
      slcAddress={slc_address}
    />
  );
};

export default Profile;
