import { createReducer } from 'typesafe-actions';
import {
  UPDATE_CURRENCY_RATE,
  UPDATE_CURRENCY_RATE_SUCCESS,
  UPDATE_CURRENCY_RATE_FAIL,
  UPDATE_CURRENCY_RATE_CLEAR_STATE,
} from './actions';
import { initialState } from './initialState';

export default createReducer(initialState, {
  [UPDATE_CURRENCY_RATE]: state => ({
    ...state,
    error: null,
    inProgress: true,
  }),
  [UPDATE_CURRENCY_RATE_SUCCESS]: state => ({
    ...state,
    inProgress: false,
    fail: false,
    success: true,
  }),
  [UPDATE_CURRENCY_RATE_CLEAR_STATE]: state => ({
    ...state,
    ...initialState,
  }),
  [UPDATE_CURRENCY_RATE_FAIL]: (state, { payload }) => ({
    ...state,
    inProgress: false,
    fail: true,
    success: false,
    ...(payload && payload.response ? { error: payload.response.data.error } : {}),
  }),
});
