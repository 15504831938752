import moment from 'moment';
import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import LocalStorage from '../../../../../../data/LocalStorage';
import api from '../../../../../../data/repositories/affiliate';
import { GetUnclaimedSLCResponse } from '../../../../../../data/repositories/affiliate/typedefs';
import {
  CLAIM_PREMIUM_SLC_ENDPOINT,
  GET_MERCHANTS_PAGINATED_ENDPOINT,
  GET_PREMIUM_REFERRALS_STATS_ENDPOINT,
  GET_PREMIUM_UNCLAIMED_SLC_ENDPOINT,
} from '../../../../../../utils/constants/api';
import { USER_ID } from '../../../../../../utils/constants/localStorageKeys';
import { MerchantsReferralStats } from '../../AffiliateMerchantsTable/state/initialState';
import {
  asyncClaimPremiumSLC,
  asyncGetPremiumReferralsPaginated,
  asyncGetPremiumReferralsStats,
  asyncGetPremiumUnclaimedSLC,
  CLAIM_PREMIUM_SLC,
  GET_PREMIUM_REFERRALS_PAGINATED,
  GET_PREMIUM_REFERRALS_STATS,
  GET_PREMIUM_UNCLAIMED_SLC,
  setPremiumTablePage,
} from './actions';
import { PremiumReferralStats } from './initialState';
export function* getPremiumReferralsStats$() {
  try {
    const userId = LocalStorage.get(USER_ID);
    const response = yield api.getReferralsStats(
      GET_PREMIUM_REFERRALS_STATS_ENDPOINT(userId ? userId : ''),
    );

    const stats: PremiumReferralStats = {
      totalReferrals: response.referral_count,
      directReferrals: response.direct_referral_count,
    };

    yield put(asyncGetPremiumReferralsStats.success(stats));
  } catch (error) {
    yield put(asyncGetPremiumReferralsStats.failure(error));
  }
}

export function* getPremiumReferralsPaginated$(
  action: ReturnType<typeof asyncGetPremiumReferralsPaginated.request>,
) {
  try {
    const userId = LocalStorage.get(USER_ID);

    const filter = {
      origin:
        action.payload.appliedFilters && action.payload.appliedFilters.transactionType
          ? action.payload.appliedFilters.transactionType
          : null,
      amountFrom:
        action.payload.appliedFilters && action.payload.appliedFilters.valueRange
          ? action.payload.appliedFilters.valueRange[0] !== ''
            ? action.payload.appliedFilters.valueRange[0] * 10000000
            : -100000 * 10000000
          : null,
      amountTo:
        action.payload.appliedFilters && action.payload.appliedFilters.valueRange
          ? action.payload.appliedFilters.valueRange[1] !== ''
            ? action.payload.appliedFilters.valueRange[1] * 10000000
            : 100000 * 10000000
          : null,
      dateFrom:
        action.payload.appliedFilters &&
        action.payload.appliedFilters.dateFilter &&
        action.payload.appliedFilters.dateFilter.length
          ? moment(action.payload.appliedFilters.dateFilter[0], 'DD/MM/YYYY').format(
              'YYYY-MM-DD HH:mm:ss',
            )
          : null,
      dateTo:
        action.payload.appliedFilters &&
        action.payload.appliedFilters.dateFilter &&
        action.payload.appliedFilters.dateFilter.length
          ? moment(action.payload.appliedFilters.dateFilter[1], 'DD/MM/YYYY')
              .endOf('day')
              .toDate() > moment().toDate()
            ? moment().format('YYYY-MM-DD HH:mm:ss')
            : moment(action.payload.appliedFilters.dateFilter[1], 'DD/MM/YYYY')
                .endOf('day')
                .format('YYYY-MM-DD HH:mm:ss')
          : null,
      tx_status:
        action.payload.appliedFilters && action.payload.appliedFilters.status
          ? action.payload.appliedFilters.status
          : null,
    };
    const mapURL = params => {
      let newParams = {};
      Object.keys(params).forEach(key => {
        if (params[key] !== null) newParams = { ...newParams, [key]: params[key].toString() };
      });
      return new URLSearchParams(newParams).toString();
    };
    const referrals: any = yield api.getReferralsPaginated(
      GET_MERCHANTS_PAGINATED_ENDPOINT(
        userId ? userId : '',
        action.payload.page,
        action.payload.perPage,
        mapURL(filter) && '&' + mapURL(filter),
      ),
    );

    const stats: MerchantsReferralStats = {
      totalReferrals: referrals.transaction_count,
      directReferrals: null,
    };

    yield put(asyncGetPremiumReferralsStats.success(stats));

    const result: any = {};
    referrals.transaction_logs.map((referral: { id: string | number }) => {
      result[referral.id] = referral;
    });

    yield put(setPremiumTablePage(action.payload.page));
    yield put(asyncGetPremiumReferralsPaginated.success(result));
  } catch (error) {
    yield put(asyncGetPremiumReferralsPaginated.failure(error));
  }
}

export function* getPremiumUnclaimedSLC$() {
  try {
    const userId = LocalStorage.get(USER_ID);
    const response: GetUnclaimedSLCResponse = yield api.getUnclaimedSLC(
      GET_PREMIUM_UNCLAIMED_SLC_ENDPOINT(userId ? userId : ''),
    );

    yield put(asyncGetPremiumUnclaimedSLC.success(response));
  } catch (error) {
    yield put(asyncGetPremiumUnclaimedSLC.failure(error));
  }
}

export function* claimAllPremiumSLC$(action: ReturnType<typeof asyncClaimPremiumSLC.request>) {
  try {
    const userId = LocalStorage.get(USER_ID);

    yield Promise.all(
      action.payload.map(cycle =>
        api.claimSLC(CLAIM_PREMIUM_SLC_ENDPOINT(userId ? userId : '', cycle.cycle)),
      ),
    );

    yield put(asyncGetPremiumReferralsPaginated.request({ page: 1, perPage: 10 }));
  } catch (error) {
    yield put(asyncClaimPremiumSLC.failure(error));
  }
}

export default function* () {
  yield all([
    takeEvery(GET_PREMIUM_REFERRALS_STATS, getPremiumReferralsStats$),
    takeEvery(GET_PREMIUM_REFERRALS_PAGINATED, getPremiumReferralsPaginated$),
    takeEvery(GET_PREMIUM_UNCLAIMED_SLC, getPremiumUnclaimedSLC$),
    takeLatest(CLAIM_PREMIUM_SLC, claimAllPremiumSLC$),
  ]);
}
