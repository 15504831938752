import { createReducer } from 'typesafe-actions';
import {
  EXCHANGE_AUTH_CODE,
  EXCHANGE_AUTH_CODE_FAIL,
  EXCHANGE_AUTH_CODE_SUCCESS,
  GET_USER_INFO,
  GET_USER_INFO_FAIL,
  GET_USER_INFO_SUCCESS,
} from './actions';
import { initialState } from './initialState';

export default createReducer(initialState, {
  [EXCHANGE_AUTH_CODE]: state => ({
    ...state,
    inProgress: true,
  }),
  [EXCHANGE_AUTH_CODE_SUCCESS]: state => ({
    ...state,
    inProgress: false,
    fail: false,
  }),
  [EXCHANGE_AUTH_CODE_FAIL]: (state, { payload }) => ({
    ...state,
    inProgress: false,
    fail: true,
    ...(payload && payload.response ? { error: payload.response.data.error } : {}),
  }),
  [GET_USER_INFO]: state => ({
    ...state,
    inProgress: true,
  }),
  [GET_USER_INFO_SUCCESS]: (state, { payload }) => ({
    ...state,
    inProgress: false,
    fail: false,
    userInfo: {
      ...payload,
    },
  }),
  [GET_USER_INFO_FAIL]: (state, { payload }) => ({
    ...state,
    inProgress: false,
    fail: true,
    ...(payload && payload.response ? { error: payload.response.data.error } : {}),
  }),
});
