import {
  CircularProgress,
  createStyles,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  GET_PRODUCTS_PAGINATED,
  SET_TABLE_PAGE,
} from '../../App/Home/HomeContent/Product/ProductsTable/state/actions';
import {
  getAllAppliedFiltersSelector,
  getAllAppliedTabSelector,
} from '../../App/Home/HomeContent/Product/ProductsTable/state/selectors';
import { getCoinDecimalsSelector } from '../../App/Home/HomeContent/Wallet/state/selectors';
import { AppState } from '../../App/state/initialState';
import { userStateSelector } from '../../App/state/selectors';
import { Product } from '../../data/repositories/product/typedef';
import { Colors } from '../../ui/style/colors';
import TablePaginationActions from '../TablePaginationActions';
import ProductsTableRow from './ProductsTableRow';

type StyleProps = {
  emptyRows: number;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      overflowY: 'auto',
      boxShadow: '0 10px 30px 0 rgba(132,141,182,0.25)',
      backgroundColor: `${Colors.white}`,
      borderRadius: '3px',
    },
    table: {
      minWidth: 650,
      borderCollapse: 'collapse',
      borderRadius: 6,
    },
    tableBody: {
      minHeight: 530,
    },
    head: {
      backgroundColor: 'rgba(242, 243, 247, 0.7)',
    },
    headerText: {
      color: theme.palette.primary.light,
      fontSize: 12,
      fontWeight: 600,
      letterSpacing: 0.86,
    },
    headerButton: {
      textAlign: 'right',
      padding: '0 !important',
      width: '10%',
    },
    claimReward: {
      borderRadius: ' 0 8px 0 8px',
      backgroundColor: `${Colors.mediumTurquoise}`,
    },
    pagination: { flexWrap: 'wrap', justifyContent: 'space-between', borderRadius: 5 },
    spacer: { flex: '0 0 100%' },
    'm-t': {
      marginTop: 55,
    },
    progressCell: {
      textAlign: 'center',
    },
    emptyRows: {
      height: ({ emptyRows }: StyleProps) => 53 * emptyRows,
    },
  }),
);

export type HeaderProps = {
  name: string;
  align?: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined;
};

type Props = {
  products: { [id: string]: Product };
  headers: HeaderProps[];
  totalProducts: number;
  page: number;
  inProgress: boolean;
  appliedFilters: any;
  appliedTab: any;
  slcDecimals: any;
};

const ProductsTable = ({
  products,
  headers,
  page,
  totalProducts,
  inProgress,
  appliedFilters,
  appliedTab,
  slcDecimals,
}: Props) => {
  const rowsPerPage = 10;
  const emptyRows = rowsPerPage - Object.keys(products).length;
  const classes = useStyles({ emptyRows });
  const dispatch = useDispatch();

  const [expanded, setExpanded] = React.useState(-1);

  useEffect(() => {
    setExpanded(-1);
  }, [products]);

  const handleChangePage = (event: unknown, newPage: number) => {
    dispatch({ type: SET_TABLE_PAGE, payload: newPage });
    dispatch({
      type: GET_PRODUCTS_PAGINATED,
      payload: {
        page: newPage,
        perPage: 10,
        appliedFilters,
        appliedTab,
      },
    });
  };

  return (
    <Paper className={`${classes.root} ${appliedTab.product ? classes['m-t'] : null}`}>
      <Table className={classes.table}>
        <TableHead className={classes.head}>
          <TableRow>
            {headers.map(header => (
              <TableCell
                className={classes.headerText}
                key={header.name}
                align={header.align}
                component={'th'}
              >
                {header.name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {inProgress || !slcDecimals ? (
          <TableBody className={classes.tableBody}>
            <TableRow>
              <TableCell colSpan={8} className={classes.progressCell}>
                <CircularProgress color="secondary" size={60} />
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <TableBody className={classes.tableBody}>
            {Object.entries(products).map(([key, value], index) => (
              <ProductsTableRow
                expanded={expanded === index}
                setExpanded={() => {
                  setExpanded(prev => (prev === index ? -1 : index));
                }}
                key={key}
                product={value}
                slcDecimals={slcDecimals}
              />
            ))}
            {emptyRows > 0 && (
              <TableRow className={classes.emptyRows}>
                <TableCell colSpan={9} />
              </TableRow>
            )}
          </TableBody>
        )}
      </Table>

      {
        <TablePagination
          component="div"
          count={totalProducts}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[]}
          page={page}
          labelDisplayedRows={({
            from,
            to,
            count,
          }: {
            from: number;
            to: number;
            count: number;
          }) => {
            return `Showing ${from} to ${to} of ${count}`;
          }}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onPageChange={handleChangePage}
          ActionsComponent={TablePaginationActions}
          classes={{
            toolbar: classes.pagination,
            spacer: classes.spacer,
          }}
        />
      }
    </Paper>
  );
};

const mapStateToProps = (state: AppState) => {
  const user = userStateSelector(state);
  const appliedFilters = getAllAppliedFiltersSelector(state);
  const appliedTab = getAllAppliedTabSelector(state);
  const slcDecimals = getCoinDecimalsSelector('SLC')(state);

  return { user, appliedFilters, appliedTab, slcDecimals };
};

export default connect(mapStateToProps, null)(ProductsTable);
