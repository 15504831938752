import { createStyles, makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import React, { FC } from 'react';
import { connect } from 'react-redux';
import { asyncChangeStatus } from '../../../App/state/actions';
import { Colors } from '../../../ui/style/colors';

const useStyles = makeStyles(() =>
  createStyles({
    statusSuccess: {
      color: `${Colors.havelockBlue}`,
      fontSize: 14,
      textTransform: 'capitalize',
    },
    statusPending: {
      color: `${Colors.blueViolet}`,
      fontSize: 14,
      textTransform: 'capitalize',
    },
    statusRejected: { color: `${Colors.blueHaze}`, fontSize: 14, textTransform: 'capitalize' },
    moreOptionsWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '24px',
      float: 'right',
      color: `${Colors.wildBlueYonder}`,
      '&:hover, &:focus': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
        cursor: 'pointer',
        borderRadius: '4px',
        border: 0,
      },
    },
  }),
);

type Props = {
  invite: any;
  expanded: boolean;
  setExpanded: (transactionId: string) => void;
};

const InvitesTableRow: FC<Props> = ({ invite }: Props) => {
  const classes = useStyles();

  return (
    <>
      <TableRow>
        <TableCell>{invite.invite_email}</TableCell>
        <TableCell>{moment(invite.last_emailed_at).format('DD/MM/YYYY HH:mm')}</TableCell>
        <TableCell>
          {(invite.status === 'completed' &&
            moment(invite.updated_at.Time).format('DD/MM/YYYY HH:mm')) ||
            'Invitation pending'}
        </TableCell>
        <TableCell
          className={
            invite.status === 'completed'
              ? classes.statusSuccess
              : invite.status === 'pending'
              ? classes.statusPending
              : classes.statusRejected
          }
        >
          {invite.status}
        </TableCell>
      </TableRow>
    </>
  );
};

const mapDispatchToProps = {
  asyncChangeStatus: asyncChangeStatus.request,
};

export default connect(null, mapDispatchToProps)(InvitesTableRow);
