export type FiatDepositState = {
  inProgress: boolean;
  fail: boolean;
  success: boolean;
  error: Error | null;
};

export const initialState: FiatDepositState = {
  inProgress: false,
  fail: false,
  success: false,
  error: null,
};

export type DepositFiatRequest = {
  symbol: string;
  amount: number;
};
