/* eslint-disable */
import { createStandardAction } from 'typesafe-actions';
import {
  SaveTransactionFilter,
  SaveTransferRequest,
} from '../../../../../data/repositories/transaction/typedef';

export const GET_TRANSFERS = 'earnings/get_transfers';
export const GET_TRANSFERS_SUCCESS = 'earnings/get_transfers_success';
export const GET_TRANSFERS_FAIL = 'earnings/get_transfers_fail';
export const GET_TRANSFERS_FOR_COIN = 'earnings/get_transfers_for_coin';
export const GET_TRANSFERS_FOR_COIN_SUCCESS = 'earnings/get_transfers_for_coin_success';
export const GET_TRANSFERS_FOR_COIN_FAIL = 'earnings/get_transfers_for_coin_fail';
export const SAVE_TRANSFERS = 'earnings/save_transfers';
export const GET_TRANSFERS_SET_LOADING = 'earnings/get_transfer_set_loading';
export const TOGGLE_FILTER = 'earnings/toggle_filter';
export const CLEAR_ALL_FILTERS = 'earnings/clear_all_filters';
export const CLEAR_ALL_EUR_FILTERS = 'earnings/clear_all_eur_filters';

export const getTransfers = createStandardAction(GET_TRANSFERS)<undefined>();
export const getTransfersSuccess = createStandardAction(GET_TRANSFERS_SUCCESS)<undefined>();
export const getTransfersFail = createStandardAction(GET_TRANSFERS_FAIL)<Error>();
export const getTransfersForCoin = createStandardAction(GET_TRANSFERS_FOR_COIN)<string>();
export const getTransfersForCoinSuccess = createStandardAction(GET_TRANSFERS_FOR_COIN_SUCCESS)<
  SaveTransferRequest
>();
export const getTransfersForCoinFail = createStandardAction(GET_TRANSFERS_FOR_COIN_FAIL)<Error>();
export const saveTransfers = createStandardAction(SAVE_TRANSFERS)<SaveTransferRequest>();
export const getTransferSetLoading = createStandardAction(GET_TRANSFERS_SET_LOADING)<boolean>();

export const toggleFilter = createStandardAction(TOGGLE_FILTER)<SaveTransactionFilter>();

export const clearAllFilters = createStandardAction(CLEAR_ALL_FILTERS)<undefined>();
export const clearAllEurFilters = createStandardAction(CLEAR_ALL_EUR_FILTERS)<undefined>();
