import { connect } from 'react-redux';
import { AppState } from '../../../../../state/initialState';
import {
  transactionGroupingClearAllFilters,
  transactionGroupingToggleFilter,
  asyncGetTransactionGroupingPaginated,
} from '../state/actions';
import { getTransactionGroupingAppliedFiltersSelector } from '../state/selectors';
import TransactionGroupingFilterBar from './TransactionGroupingFilterBar';

const mapStateToProps = (state: AppState) => {
  const appliedFilters = getTransactionGroupingAppliedFiltersSelector(state);
  return { appliedFilters };
};

const dispatchStateToProps = {
  asyncTableDataPaginated: asyncGetTransactionGroupingPaginated.request,
  toggleFilter: transactionGroupingToggleFilter,
  clearAllFilters: transactionGroupingClearAllFilters,
};

export default connect(mapStateToProps, dispatchStateToProps)(TransactionGroupingFilterBar);
