import { createSelector } from 'reselect';
import { AppState } from '../../../../../state/initialState';
import { isEmpty } from 'lodash';

const getTransactionGroupingState = ({
  home: {
    admin: { transactionGrouping },
  },
}: AppState) => transactionGrouping;

export const transactionGroupingStateSelector = createSelector(
  getTransactionGroupingState,
  transactionGrouping => transactionGrouping,
);

export const getTransactionGroupingTransactionsSelector = createSelector(
  transactionGroupingStateSelector,
  transactionGrouping => transactionGrouping.transactions,
);

export const geTransactionGroupingTransactionsStatsSelector = createSelector(
  transactionGroupingStateSelector,
  transactionGrouping => transactionGrouping.stats,
);

export const getAppliedFiltersState = createSelector(
  transactionGroupingStateSelector,
  transactionGrouping => transactionGrouping.appliedFilters,
);

const getAllAppliedFiltersSelector = createSelector(
  getAppliedFiltersState,
  appliedFilters => appliedFilters,
);

const getAppliedFiltersIds = createSelector(getAllAppliedFiltersSelector, appliedFilters =>
  Object.keys(appliedFilters),
);

export const getTransactionGroupingAppliedFiltersSelector = createSelector(
  getAllAppliedFiltersSelector,
  getAppliedFiltersIds,
  (appliedFilters, appliedFiltersIds) => {
    const af = new Map<string, string[]>(Object.entries(appliedFilters));
    const appliedFiltersNonEmpty = new Map<string, string[]>();
    for (const index in appliedFiltersIds) {
      const filter = af.get(appliedFiltersIds[index]);
      if (!isEmpty(filter) && filter) {
        appliedFiltersNonEmpty.set(appliedFiltersIds[index], filter);
      }
    }

    return appliedFiltersNonEmpty;
  },
);

export const getAreAnyFiltersApplied = createSelector(
  getAppliedFiltersState,
  getAppliedFiltersIds,
  (appliedFilters, appliedFiltersIds) => {
    let applied = false;
    for (const index in appliedFiltersIds) {
      const af = new Map<string, string[]>(Object.entries(appliedFilters));
      applied = applied || !isEmpty(af.get(appliedFiltersIds[index]));
    }
    return applied;
  },
);

export const getAppliedFiltersIdsSelector = createSelector(
  getAppliedFiltersIds,
  appliedFiltersIds => appliedFiltersIds,
);
