import { Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { PayloadAC } from 'typesafe-actions';
import SettingsChangePassword from './SettingsChangePassword';
import SettingsTwoFactorAuth from './SettingsTwoFactorAuth';
import Membership from './Membership';
import Profile from './Profile';
import CurrencySettings from './CurrencySettings';
import { roles } from '../../../../utils/constants/roles';
import { NavTabs, NavTab, NavTabContent } from '../../../../components/NavTabs';
import FeeSettings from './FeeSettings';
import FiatDeposit from './FiatDeposit';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      overflowY: 'auto',
      margin: '30px 24px',
    },
    contentWrapper: {
      minWidth: 550,
      marginBottom: 30,
      borderCollapse: 'collapse',
      [theme.breakpoints.down('sm')]: {
        minWidth: '100%',
      },
    },
    contentPadding: {
      paddingRight: 24,
      [theme.breakpoints.down('sm')]: {
        padding: '20px 0',
      },
    },
  });

const useStyles = makeStyles(styles);

type Props = {
  setCurrentHomePage: PayloadAC<'home/set_current_home_page', string>;
  match: any;
  user: any;
};

const Settings: React.FC<Props> = ({ setCurrentHomePage, user }: Props): JSX.Element => {
  const [activeTab, setActiveTab] = useState(0);
  const adminLabels = ['Currency settings', 'Fee settings', 'Deposit fiat'];

  useEffect(() => {
    if (document.location.search === '?auth=true') setActiveTab(1);
    setCurrentHomePage('settings');
  }, []);

  const handleTabChange = (event: React.ChangeEvent<Record<string, unknown>>, newValue: number) => {
    setActiveTab(newValue);
  };

  const classes = useStyles();
  const adminNavTabs = adminLabels.map(label => <NavTab key={`${label}key`} label={label} />);
  return (
    <div className={classes.root}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className={classes.contentWrapper}
      >
        <Grid item>
          <NavTabs
            variant="scrollable"
            value={activeTab}
            onChange={handleTabChange}
            aria-label="Settings"
          >
            <NavTab label="Profile" />
            <NavTab label="Security" />

            {roles.isOwner(user.roles) && adminNavTabs}
          </NavTabs>
        </Grid>
      </Grid>

      <NavTabContent value={activeTab} index={0}>
        <Grid container className={classes.contentWrapper}>
          <Grid item xs={12} md={6} className={classes.contentPadding}>
            <Profile />
          </Grid>
          <Grid item xs={12} md={6}>
            <Membership />
          </Grid>
        </Grid>
      </NavTabContent>

      <NavTabContent value={activeTab} index={1}>
        {' '}
        <Grid container className={classes.contentWrapper}>
          <Grid item xs={12} md={6} className={classes.contentPadding}>
            <SettingsChangePassword />
          </Grid>
          <Grid item xs={12} md={6}>
            <SettingsTwoFactorAuth />
          </Grid>
        </Grid>
      </NavTabContent>

      <NavTabContent value={activeTab} index={2}>
        {' '}
        <Grid container className={classes.contentWrapper}>
          <Grid item xs={12} md={6} className={classes.contentPadding}>
            <CurrencySettings />
          </Grid>
        </Grid>
      </NavTabContent>

      <NavTabContent value={activeTab} index={3}>
        {' '}
        <Grid container className={classes.contentWrapper}>
          <Grid item xs={12} md={6} className={classes.contentPadding}>
            <FeeSettings />
          </Grid>
        </Grid>
      </NavTabContent>

      <NavTabContent value={activeTab} index={4}>
        {' '}
        <Grid container className={classes.contentWrapper}>
          <Grid item xs={12} md={6} className={classes.contentPadding}>
            <FiatDeposit />
          </Grid>
        </Grid>
      </NavTabContent>
    </div>
  );
};

export default Settings;
