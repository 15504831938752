import { Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { orderBy } from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { EmptyAC, PayloadAC } from 'typesafe-actions';
import SacretDaterangePickerSelect from '../../../../../components/SacrateDaterangePickerSelect';
import SacretInputNumber from '../../../../../components/SacretInputNumber';
import SacretSelect from '../../../../../components/SacretSelect';
import { GetReferralsPaginatedRequest } from '../../../../../data/repositories/affiliate/typedefs';
import { SaveTransactionFilter } from '../../../../../data/repositories/transaction/typedef';
import { User } from '../../../../../data/typedefs/user';
import { roles } from '../../../../../utils/constants/roles';
import { AppState } from '../../../../state/initialState';
import { userStateSelector } from '../../../../state/selectors';
import AppliedFiltersChips from './AppliedFiltersChips';

const styles = (theme: Theme) =>
  createStyles({
    filter: {
      minWidth: 200,
      marginRight: 10,
    },
    'm-r': {
      marginRight: 10,
    },
    valueSlider: {},
    clearAll: {
      textTransform: 'none',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    clearAllIcon: {
      fontSize: 12,
      padding: 5,
    },
    label: {
      float: 'left',
      padding: '2px 0',
      fontSize: 12,
      color: theme.palette.primary.light,
    },
    minWidth: {
      minWidth: 236,
    },
  });

const useStyles = makeStyles(styles);

type Props = {
  toggleFilter: PayloadAC<'transactions/toggle_filter', SaveTransactionFilter>;
  appliedFilters: Map<string, string[]>;
  clearAllFilters: EmptyAC<'transactions/clear_all_filters'>;
  asyncGetPremiumReferralsPaginated: PayloadAC<
    'affiliate/get_premium_referrals_paginated',
    GetReferralsPaginatedRequest
  >;
  user: User;
};

let names = [
  { name: 'Affiliate became Partner', value: 'merchant_parent_reward' },
  { name: 'Affiliate package purchase', value: 'premium_user_reward' },
  { name: 'Affiliate purchased Affiliate package', value: 'premium_user_affiliate_reward' },
  {
    name: 'BV',
    value:
      'affiliate_percentage_reward,term_saving,term_saving_finished&exclude_tx_status=invested',
  },
  { name: 'Became Partner', value: 'new_merchant_reward' },
  { name: 'Bonus', value: 'bonus' },
  { name: 'Bonus Reward', value: 'bonus_reward' },
  { name: 'Buy', value: 'buy' },
  { name: 'Buy product', value: 'buy_product' },
  { name: 'Deposit', value: 'deposit' },
  { name: 'Event reward', value: 'event_reward' },
  { name: 'HODL', value: 'term_saving' },
  { name: 'HODL Interested payment', value: 'interest_payment' },
  { name: 'New Affiliate', value: 'new_user_affiliate_reward' },
  { name: 'Purchase', value: 'payment_sent' },
  { name: 'Registration reward', value: 'new_user_reward' },
  { name: 'Reward transfer', value: 'reward_correction' },
  { name: 'Sale', value: 'merchant_income' },
  { name: 'Sell', value: 'sell' },
  { name: 'Term saving payout', value: 'term_saving_finished' },
  { name: 'Transfer', value: 'transfer' },
  { name: 'Withdraw', value: 'withdraw' },
];

const additionalNames = [
  { name: 'Platform pool reward', value: 'platform_pool_reward' },
  { name: 'VAT', value: 'VAT' },
];

const statuses = [
  { name: 'Completed', value: 'completed' },
  { name: 'Pending', value: 'pending' },
  { name: 'Escrow', value: 'escrow' },
  { name: 'In HODL', value: 'invested' },
  { name: 'Pending Escrow', value: 'pending_escrow' },
  { name: 'Timed Escrow', value: 'timed_escrow' },
];

const TransactionsFilterBar: React.FC<Props> = ({
  toggleFilter,
  appliedFilters,
  clearAllFilters,
  asyncGetPremiumReferralsPaginated,
  user,
}: Props): JSX.Element => {
  const classes = useStyles();

  useEffect(() => {
    if (roles.isOwner(user.roles)) {
      additionalNames.forEach(additionalFilter => {
        if (!names.find(filt => filt.name === additionalFilter.name)) {
          names.push(additionalFilter);
        }
      });
      names = orderBy(names, ['name'], ['asc']);
    }
  }, []);

  const handleOnChangeEvent = (
    value: string[] | string | undefined,
    filterType: string,
    limit?: string,
  ) => {
    const object: any = {};
    appliedFilters.forEach((value, key) => {
      object[key] = value;
    });

    let valueRange;

    switch (limit) {
      case 'Min':
        valueRange = [value, object.valueRange ? object.valueRange[1] : '100000'];
        const maxValue = valueRange[1] === '100000' ? '' : valueRange[1];

        asyncGetPremiumReferralsPaginated({
          page: 0,
          perPage: 10,
          appliedFilters: { ...object, [filterType]: valueRange },
        });
        value = value === '' ? '' : value;
        toggleFilter({
          filterType: 'valueRange',
          minValue: value,
          maxValue: maxValue,
          value: valueRange,
        });
        break;
      case 'Max':
        valueRange = [object.valueRange ? object.valueRange[0] : '-100000', value];
        const minValue = valueRange[0] === '-100000' ? '' : valueRange[0];

        asyncGetPremiumReferralsPaginated({
          page: 0,
          perPage: 10,
          appliedFilters: { ...object, [filterType]: valueRange },
        });
        value = value === '' ? '' : value;

        toggleFilter({
          filterType: 'valueRange',
          maxValue: value,
          minValue: minValue,
          value: valueRange,
        });
        break;
      default:
        asyncGetPremiumReferralsPaginated({
          page: 0,
          perPage: 10,
          appliedFilters: { ...object, [filterType]: value },
        });

        toggleFilter({ filterType, value });
        break;
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid xs={12} sm="auto" item className={classes.filter}>
        <SacretSelect
          items={statuses}
          label="Status"
          onChange={(value: string[]) => handleOnChangeEvent(value, 'status')}
          stateFilterValue={appliedFilters.get('status')}
        />
      </Grid>
      <Grid xs={12} sm="auto" item className={`${classes.filter} ${classes.minWidth}`}>
        <SacretSelect
          items={names}
          label="Transaction type"
          onChange={(value: string[]) => handleOnChangeEvent(value, 'transactionType')}
          stateFilterValue={appliedFilters.get('transactionType')}
          size="large"
        />
      </Grid>
      <Grid xs={12} sm="auto" item className={`${classes.filter} ${classes.minWidth}`}>
        <SacretDaterangePickerSelect
          label="Choose a date"
          onChange={(value: string[]) => handleOnChangeEvent(value, 'dateFilter')}
          stateFilterValue={appliedFilters.get('dateFilter')}
        />
      </Grid>
      <Grid item className={classes['m-r']}>
        <SacretInputNumber
          currency="SLC"
          limit="Min"
          onChange={(value: string) => handleOnChangeEvent(value, 'valueRange', 'Min')}
          stateValue={appliedFilters.get('minValue')}
        />
      </Grid>
      <Grid item className={classes['m-r']}>
        <SacretInputNumber
          currency="SLC"
          limit="Max"
          onChange={(value: string) => {
            handleOnChangeEvent(value, 'valueRange', 'Max');
          }}
          stateValue={appliedFilters.get('maxValue')}
        />
      </Grid>
      <AppliedFiltersChips
        clearAllFilters={clearAllFilters}
        stateAppliedFilters={appliedFilters}
        toggleFilter={toggleFilter}
      />
    </Grid>
  );
};

const mapStateToProps = (state: AppState) => {
  const user = userStateSelector(state);
  return { user };
};

export default connect(mapStateToProps, null)(TransactionsFilterBar);
