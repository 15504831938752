/* eslint-disable */
import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import {
  Cycle,
  getBalanceFromAffiliateUserRequest,
  GetReferralsPaginatedRequest,
  GetReferralsTreeRequest,
  GetUnclaimedSLCResponse,
  Referral,
} from '../../../../../../data/repositories/affiliate/typedefs';
import { SaveTransactionFilter } from '../../../../../../data/repositories/transaction/typedef';
import {
  ChangeUserStatusRequest,
  ReferralStats,
  SellSlcInfoResponse,
  SellSlcInfoRequest,
} from './initialState';

export const IS_ADD_DEDUCT_DIALOG_OPEN = 'affiliate/is_add_deduct_dialog_open';
export const SET_USER_FOR_DIALOG = 'affiliate/set_user_for_dialog';

export const IS_FULL_PROFILE_DIALOG_OPEN = 'affiliate/is_full_profile_dialog_open';
export const PREPARE_FULL_PROFILE_FOR_AFFILIATE = 'affiliate/prepare_full_profile_for_affiliate';
export const PREPARE_FULL_PROFILE_FOR_AFFILIATE_SUCCESS =
  'affiliate/prepare_full_profile_for_affiliate/success';
export const PREPARE_FULL_PROFILE_FOR_AFFILIATE_FAIL =
  'affiliate/prepare_full_profile_for_affiliate/fail';

export const GET_REFERRALS_STATS = 'affiliate/get_referrals_stats';
export const GET_REFERRALS_STATS_SUCCESS = 'affiliate/get_referrals_stats_success';
export const GET_REFERRALS_STATS_FAIL = 'affiliate/get_referrals_stats_fail';

export const GET_REFERRALS_PAGINATED = 'affiliate/get_referrals_paginated';
export const GET_REFERRALS_PAGINATED_SUCCESS = 'affiliate/get_referrals_paginated/success';
export const GET_REFERRALS_PAGINATED_FAILED = 'affiliate/get_referrals_paginated/fail';

export const GET_REFERRALS_TREE = 'affiliate/get_referral_tree';
export const GET_REFERRALS_TREE_SUCCESS = 'affiliate/get_referral_tree/success';
export const GET_REFERRALS_TREE_FAILED = 'affiliate/get_referral_tree/fail';
export const GET_REFERRALS_TREE_CLEAR = 'affiliate/clear_referral_tree';
export const SET_AFFILIATE_TABLE_PAGE = 'affiliate/set_merchants_table_page';

export const GET_UNCLAIMED_SLC = 'affiliate/get_unclaimed_slc';
export const GET_UNCLAIMED_SLC_SUCCESS = 'affiliate/get_unclaimed_slc/success';
export const GET_UNCLAIMED_SLC_FAIL = 'affiliate/get_unclaimed_slc/fail';

export const TRADE_SLC = 'affiliate/trade_slc';
export const TRADE_SLC_SUCCESS = 'affiliate/trade_slc/success';
export const TRADE_SLC_FAIL = 'affiliate/trade_slc/fail';

export const REFERRAL_DETAIL = 'affiliate/get_detail';
export const REFERRAL_DETAIL_SUCCESS = 'affiliate/get_detail/success';
export const REFERRAL_DETAIL_FAIL = 'affiliate/get_detail/fail';

export const CHANGE_USER_STATUS = 'affiliate/change_user_status';
export const CHANGE_USER_STATUS_SUCCESS = 'affiliate/change_user_status/success';
export const CHANGE_USER_STATUS_FAIL = 'affiliate/change_user_status/fail';

export const GET_BALANCE_FROM_AFFILIATE_USER = 'affiliate/get_balance_from_affiliate_user';
export const GET_BALANCE_FROM_AFFILIATE_USER_SUCCESS =
  'affiliate/get_balance_from_affiliate_user/success';
export const GET_BALANCE_FROM_AFFILIATE_USER_FAIL =
  'affiliate/get_balance_from_affiliate_user/fail';

export const TOGGLE_ADMIN_ROLE = 'affiliate/toggle_admin_role';
export const TOGGLE_ADMIN_ROLE_SUCCESS = 'affiliate/toggle_admin_role/success';
export const TOGGLE_ADMIN_ROLE_FAIL = 'affiliate/toggle_admin_role/fail';

export const CLAIM_SLC = 'affiliate/claim_slc';
export const CLAIM_SLC_SUCCESS = 'affiliate/claim_slc/success';
export const CLAIM_SLC_FAIL = 'affiliate/claim_slc/fail';

export const SET_TABLE_PAGE = 'affiliate/set_table_page';

export const TOGGLE_FILTER = 'affiliate/toggle_filter';
export const CLEAR_ALL_FILTERS = 'affiliate/clear_all_filters';
export const SET_DIALOG_STEP = 'affiliate/set_dialog_step';
export const CLEAR_DIALOG_STATE = 'affiliate/clear_dialog_state';

export const SAVE_SLC_CHANGE_LIMIT = 'affiliate/save_change_slc_limit';
export const SAVE_SLC_CHANGE_LIMIT_SUCCESS = 'affiliate/save_change_slc_limit/success';
export const SAVE_SLC_CHANGE_LIMIT_FAIL = 'affiliate/save_change_slc_limit/faill';

export const RESET_USER_KYC_STATUS = 'affiliate/reset_user_kyc_status';
export const RESET_USER_KYC_STATUS_SUCCESS = 'affiliate/reset_user_kyc_status/success';
export const RESET_USER_KYC_STATUS_FAIL = 'affiliate/reset_user_kyc_status/fail';

export const IS_CHANGE_SELL_LIMIT_DIALOG_OPEN = 'affiliate/is_change_sell_limit_dialog_open';

export const USER_HAS_AFFILIATE_PACKAGE_CHECK = 'affiliate/user_has_affiliate_package_check';
export const USER_HAS_AFFILIATE_PACKAGE_CHECK_SUCCESS =
  'affiliate/user_has_affiliate_package_check/success';
export const USER_HAS_AFFILIATE_PACKAGE_CHECK_FAIL =
  'affiliate/user_has_affiliate_package_check/fail';

export const asyncGetReferralsStats = createAsyncAction(
  GET_REFERRALS_STATS,
  GET_REFERRALS_STATS_SUCCESS,
  GET_REFERRALS_STATS_FAIL,
)<undefined, ReferralStats, Error>();

export const asyncGetReferralsPaginated = createAsyncAction(
  GET_REFERRALS_PAGINATED,
  GET_REFERRALS_PAGINATED_SUCCESS,
  GET_REFERRALS_PAGINATED_FAILED,
)<GetReferralsPaginatedRequest, { [id: string]: Referral }, Error>();

export const asyncGetReferralTree = createAsyncAction(
  GET_REFERRALS_TREE,
  GET_REFERRALS_TREE_SUCCESS,
  GET_REFERRALS_TREE_FAILED,
)<GetReferralsTreeRequest, any, Error>();

export const asyncGetReferralDetail = createAsyncAction(
  REFERRAL_DETAIL,
  REFERRAL_DETAIL_SUCCESS,
  REFERRAL_DETAIL_FAIL,
)<any, any, Error>();

export const asyncGetUnclaimedSLC = createAsyncAction(
  GET_UNCLAIMED_SLC,
  GET_UNCLAIMED_SLC_SUCCESS,
  GET_UNCLAIMED_SLC_FAIL,
)<undefined, GetUnclaimedSLCResponse, Error>();

export const asyncClaimSLC = createAsyncAction(CLAIM_SLC, CLAIM_SLC_SUCCESS, CLAIM_SLC_FAIL)<
  Cycle[],
  undefined,
  Error
>();

export const asyncUserHasAffiliatePackageCheck = createAsyncAction(
  USER_HAS_AFFILIATE_PACKAGE_CHECK,
  USER_HAS_AFFILIATE_PACKAGE_CHECK_SUCCESS,
  USER_HAS_AFFILIATE_PACKAGE_CHECK_FAIL,
)<string, SellSlcInfoResponse, Error | string>();

export const asyncSaveSlcChangeLimit = createAsyncAction(
  SAVE_SLC_CHANGE_LIMIT,
  SAVE_SLC_CHANGE_LIMIT_SUCCESS,
  SAVE_SLC_CHANGE_LIMIT_FAIL,
)<SellSlcInfoRequest, undefined, Error>();

export const setTablePage = createStandardAction(SET_TABLE_PAGE)<number>();

export const toggleFilter = createStandardAction(TOGGLE_FILTER)<SaveTransactionFilter>();

export const clearAllFilters = createStandardAction(CLEAR_ALL_FILTERS)<undefined>();
export const setAffiliateTablePage = createStandardAction(SET_AFFILIATE_TABLE_PAGE)<number>();

export const isAddDeductDialogOpen = createStandardAction(IS_ADD_DEDUCT_DIALOG_OPEN)<boolean>();
export const setUserForDialog = createStandardAction(SET_USER_FOR_DIALOG)<any>();

export const isFullProfileDialogOpen = createStandardAction(IS_FULL_PROFILE_DIALOG_OPEN)<boolean>();

export const setDialogStep = createStandardAction(SET_DIALOG_STEP)<number>();
export const clearDialogState = createStandardAction(CLEAR_DIALOG_STATE)<undefined>();

export const asyncPrepareFullProfileForAffiliate = createAsyncAction(
  PREPARE_FULL_PROFILE_FOR_AFFILIATE,
  PREPARE_FULL_PROFILE_FOR_AFFILIATE_SUCCESS,
  PREPARE_FULL_PROFILE_FOR_AFFILIATE_FAIL,
)<string, any, Error>();

export const asyncTradeSLC = createAsyncAction(TRADE_SLC, TRADE_SLC_SUCCESS, TRADE_SLC_FAIL)<
  any,
  undefined,
  Error
>();

export const asyncChangeUserStatus = createAsyncAction(
  CHANGE_USER_STATUS,
  CHANGE_USER_STATUS_SUCCESS,
  CHANGE_USER_STATUS_FAIL,
)<ChangeUserStatusRequest, undefined, Error>();

export const asyncResetUserKYCStatus = createAsyncAction(
  RESET_USER_KYC_STATUS,
  RESET_USER_KYC_STATUS_SUCCESS,
  RESET_USER_KYC_STATUS_FAIL,
)<string, undefined, Error>();

export const asyncToggleAdminRole = createAsyncAction(
  TOGGLE_ADMIN_ROLE,
  TOGGLE_ADMIN_ROLE_SUCCESS,
  TOGGLE_ADMIN_ROLE_FAIL,
)<any, undefined, Error>();

export const asyncGetBalanceFromAffiliateUser = createAsyncAction(
  GET_BALANCE_FROM_AFFILIATE_USER,
  GET_BALANCE_FROM_AFFILIATE_USER_SUCCESS,
  GET_BALANCE_FROM_AFFILIATE_USER_FAIL,
)<getBalanceFromAffiliateUserRequest, string, Error>();

export const isChangeSellLimitDialogOpen = createStandardAction(IS_CHANGE_SELL_LIMIT_DIALOG_OPEN)<
  boolean
>();
