import { Tooltip, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import React from 'react';
import { Colors } from '../../ui/style/colors';
import { SUPPORT_EMAIL } from '../../utils/constants/info';
import { BECOME_AN_AFFILIATE_URL, REDIRECT_TO_KYC } from '../../utils/constants/routes';
import LocalStorage from '../../data/LocalStorage';
import { ACCESS_TOKEN } from '../../utils/constants/localStorageKeys';

type StyleProps = {
  fontSize: number | string;
  color: string;
};

const useStyles = makeStyles<Theme, StyleProps>(() =>
  createStyles({
    tooltip: ({ color, fontSize }) => ({
      position: 'relative',
      top: -1,
      left: 5,
      fontSize,
      color,
      verticalAlign: 'middle',
    }),
    icon: {
      cursor: 'pointer',
    },
    tooltipLink: {
      color: `${Colors.gray}`,
      '&:hover': {
        color: `${Colors.black}`,
      },
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  }),
);

const StyledTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: `${Colors.gray}`,
    boxShadow: theme.shadows[1],
    fontSize: 16,
  },
}))(Tooltip);

export type LightTooltipProps = {
  title?: React.ReactNode | string;
  symbolName?: string;
  interactive?: boolean;
  fontSize?: number | string;
  color?: string;
  className?: any;
  walletType?: 'BitGo' | 'Revolut' | 'Wire transfer';
  variant?: 'membership' | 'kyc' | 'unavailable' | 'buyAddress';
};


const token = LocalStorage.get(ACCESS_TOKEN);

const LightTooltip = ({
  title,
  symbolName,
  variant,
  interactive = false,
  fontSize = 20,
  color = `${Colors.gray}`,
  className,
  walletType = 'BitGo',
}: LightTooltipProps) => {
  const styles = { fontSize, color };
  const classes = useStyles(styles);

  const membershipTitle = (
    <Typography>
      Become an{' '}
      <span
        className={classes.tooltipLink}
        onClick={() => {
          window.open(BECOME_AN_AFFILIATE_URL, '_self');
        }}
      >
        affiliate
      </span>{' '}
      to use a {walletType} wallet{' '}
    </Typography>
  );

  const kycTitle = (
    <Typography>
      Please complete{' '}
      <a className={classes.tooltipLink} href={`${REDIRECT_TO_KYC}?token=${token}&from_url=${window.location.origin}`}>
        KYC
      </a>{' '}
      to use a {walletType} wallet
    </Typography>
  );

  const unavailableTitle = (
    <Typography>
      Unavailable at the moment.
      <br /> Please contact our support at{' '}
      <a
        className={classes.tooltipLink}
        target="_blank"
        rel="noreferrer"
        href={`mailto: ${SUPPORT_EMAIL}`}
      >
        {SUPPORT_EMAIL}
      </a>
    </Typography>
  );

  const buyAddressTitle = (
    <Typography>
      Unavailable at the moment because you don&apos;t have an {symbolName} address.
    </Typography>
  );

  const getTitle = () => {
    switch (variant) {
      case 'membership':
        return membershipTitle;
      case 'kyc':
        return kycTitle;
      case 'unavailable':
        return unavailableTitle;
      case 'buyAddress':
        return buyAddressTitle;
      default:
        return title;
    }
  };

  return (
    <StyledTooltip
      interactive={interactive}
      className={classes.tooltip}
      title={<div>{getTitle()}</div>}
    >
      <HelpOutlineIcon className={`${classes.icon} ${className}`} />
    </StyledTooltip>
  );
};

export default LightTooltip;
