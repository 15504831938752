import { Product } from '../../../../../../data/repositories/product/typedef';

export type ProductTableState = {
  products: { [id: string]: Product } | null;
  inProgress: boolean;
  appliedFilters: Map<string, string[]>;
  appliedTab: Map<string, string[]>;
  fail: boolean;
  totalProducts: number;
  page: number;
  perPage: number;
  backerMax: number;
  timeLeftMax: number;
  exportInProgress: boolean;
};

export const initialState: ProductTableState = {
  products: null,
  timeLeftMax: 30,
  backerMax: 100,
  inProgress: false,
  appliedFilters: new Map<string, string[]>(),
  appliedTab: new Map<string, string[]>(),
  fail: false,
  totalProducts: 0,
  page: 0,
  perPage: 10,
  exportInProgress: false,
};
