import { all, put, takeEvery } from 'redux-saga/effects';
import LocalStorage from '../../../../../../data/LocalStorage';
import api from '../../../../../../data/repositories/admin';

import { USER_ID } from '../../../../../../utils/constants/localStorageKeys';
import { asyncChangeUserPassword, CHANGE_USER_PASSWORD } from './actions';

export function* changeUserPassword$(action: ReturnType<typeof asyncChangeUserPassword.request>) {
  try {
    const userId = LocalStorage.get(USER_ID);
    yield api.changeUserPassword(action.payload, userId ? userId : '');
    yield put(asyncChangeUserPassword.success());
  } catch (error) {
    yield put(asyncChangeUserPassword.failure(error));
  }
}

export default function* () {
  yield all([takeEvery(CHANGE_USER_PASSWORD, changeUserPassword$)]);
}
