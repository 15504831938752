//you can use this website to name colors https://chir.ag/projects/name-that-color/#FFFFFF
export const Colors = {
  black: '#000000',
  blueCharcoal: '#000622',
  white: '#FFFFFF',
  pigeonPost: '#ADB2D7',
  nepal: '#8F97BF',
  stratos: '#00022C',
  sunglow: '#FFC037',
  bittersweet: '#FF5F5E',
  blueBell: '#99A1C8',
  midnightBlue: '#002A68',
  blueHaze: '#BEC3D8',
  blueHazeTwo: '#C5CADE',
  wildBlueYonder: '#848DB6',
  turquoise: '#2BD1BF',
  mediumTurquoise: '#3DD6C4',
  blackPearl: '#040925',
  spunPearl: '#ADAFBA',
  ghost: '#c7c9d1',
  denim: '#1B75BC',
  havelockBlue: '#5693E3',
  whiteLilac: '#F8F9FD',
  jungleGreen: '#2EB88C',
  lightningYellow: '#fcc12a',
  goldenTainoi: '#ffc658',
  whiteLilac2: '#F7F8FC',
  gray: '#8c8c8c',
  dustyGray: '#979797',
  emerald: '#52D869',
  logan: '#A3AACB',
  apple: '#43A047',
  linkWater: '#E6E8FA',
  brandyPunch: '#cd7f32',
  gold: '#FFD700',
  westar: '#E5E4E2',
  frenchPass: '#b9f2ff',
  blueViolet: '#5563b8',
  ceruleanBLue: '#3366BB',
  sanMarino: '#426BAA',
  alto: '#d8d8d8',
  barleyWhite: '#ffefce',
  cadetBlue: '#b1b4c4',
  dodgerBlue: '#2196f3',
  springGreen: '#00e676',
  torchRed: '#ff1744',
  gallery: '#ebebeb',
  athensGray: '#F5F6F9',
  masala: '#3f3838',
  treePoppy: '#F7931A',
  azure: '#345D9D',
  carrotOrange: '#F08B16',
  seashell: '#f1f1f1',
  bombay: '#B2B4BC',
  mineShaft: '#222222',
  red: '#FF0000',
  yellow: '#FFFF00',
  iron: '#e5e6e8',
  muledVine: '#4E4B66',
  stormGray: '#6E7191',
  mirage: '#14142B',
  outerSpace: '#343A40',
};
