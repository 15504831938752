import { Coin, GetCoinPricesResponse } from '../../../../data/repositories/wallet/typedefs';

export const EURWithdrawInitialState = {
  bank_country: '',
  account_no: '',
  street_line1: '',
  street_line2: '',
  region: '',
  postcode: '',
  city: '',
  country: '',
  phone: '',
  currency: 'EUR',
  company_name: '',
  email: '',
  name: '',
  iban: '',
  sort_code: '',
  routing_number: '',
  bic: '',
  clabe: '',
  ifsc: '',
  bsb_code: '',
  amount: undefined,
  individual_name: {
    first_name: '',
    last_name: '',
  },
};
export type EURWithdrawAddressType = {
  bank_country: string;
  account_no: string;
  phone: string;
  address: {
    street_line1: string;
    street_line2: string;
    region: string;
    postcode: string;
    city: string;
    country: string;
  };
  currency: string;
  company_name?: string;
  email?: string;
  name?: string;
  iban?: string;
  sort_code?: string;
  routing_number?: string;
  bic?: string;
  clabe?: string;
  ifsc?: string;
  bsb_code?: string;
  amount?: number;
  individual_name: {
    first_name: string;
    last_name: string;
  };
};

export type RefreshTokenState = {
  inProgress: boolean;
  fail: boolean;
  success: boolean;
};

export type CommonState = {
  depositWalletAddress: string;
  depositWalletSymbol: string;
  allCoins: Map<string, Coin>;
  currentHomePage: string;
  selectedCoin: string;
  withdrawAddress: string | EURWithdrawAddressType;
  withdrawAmount: string;
  coinPrices?: GetCoinPricesResponse;
  coinPricesInProgress: boolean;
  refreshToken: RefreshTokenState;
};

export const initialState: CommonState = {
  depositWalletAddress: '',
  depositWalletSymbol: 'ETH',
  allCoins: new Map<string, Coin>(),
  currentHomePage: 'Wallet',
  selectedCoin: 'ETH',
  withdrawAddress: '',
  withdrawAmount: '0',
  coinPricesInProgress: false,
  refreshToken: {
    inProgress: false,
    fail: false,
    success: false,
  },
};
