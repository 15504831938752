import moment from 'moment';
import { Product, ProductDetail } from '../data/repositories/product/typedef';
import { WIRE_EUR_MAX_FRACTION, WIRE_EUR_MIN_FRACTION } from './constants/amounts';
import numberUtil from './numberUtils';

type PeriodType = 'years' | 'months' | 'days' | 'hours' | 'minutes';

export const handleDurationByType = (type: PeriodType, time: number) => {
  const duration = moment.duration(time, 'minutes');
  switch (type) {
    case 'years':
      return duration.years() > 0 ? duration.years() + 'y' : '';
    case 'months':
      return duration.months() > 0 ? duration.months() + 'm' : '';
    case 'days':
      return duration.days() > 0 ? duration.days() + 'd' : '';
    case 'hours':
      return duration.hours() > 0 ? duration.hours() + 'h' : '';
    case 'minutes':
      return duration.minutes() + 'min';
    default:
      return duration.minutes() + 'min';
  }
};

export const handleDuration = (
  time: number,
  periodTypes: PeriodType[] = ['years', 'months', 'days', 'hours', 'minutes'],
) => periodTypes.map((type: PeriodType) => `${handleDurationByType(type, time)}`).join(' ');

export const getProductPhase = (phase: Product['product_phase']): string => {
  switch (phase) {
    case 'pre-ico':
      return 'PreSale';
    case 'ico':
      return 'Sale';
    case 'finished':
      return 'Completed';
    default:
      return '';
  }
};

export const renderPrice = (product: ProductDetail, phase: Product['product_phase']) => {
  const { pre_ico_price, ico_price } = product;

  switch (phase) {
    case 'pre-ico':
      return numberUtil.formatNumberDigits(
        pre_ico_price / 100,
        WIRE_EUR_MIN_FRACTION,
        WIRE_EUR_MAX_FRACTION,
      );
    default:
      return numberUtil.formatNumberDigits(
        ico_price / 100,
        WIRE_EUR_MIN_FRACTION,
        WIRE_EUR_MAX_FRACTION,
      );
  }
};
