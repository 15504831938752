import Input from '@material-ui/core/Input';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';

const SacretInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      'label + &': {
        marginTop: theme.spacing(3),
      },
      backgroundColor: theme.palette.background.paper,

      borderRadius: 4,
    },
    input: {
      height: 22,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      fontSize: 12,
      padding: '8px 12px 8px 12px',
      borderRadius: 4,
      '&:focus': {
        backgroundColor: theme.palette.background.paper,
        borderRadius: 4,
      },
      '&::placeholder': {
        color: theme.palette.secondary.light,
      },
    },
  }),
)(Input);

export default SacretInput;
