import { connect } from 'react-redux';
import { AppState } from '../../../../state/initialState';
import { getAllCoinsSelector } from '../../Wallet/state/selectors';
import { asyncGetReferralsPaginated, clearAllFilters, toggleFilter } from '../state/actions';
import { getAppliedFiltersSelector } from '../state/selectors';
import TransactionsFilterBar from './TransactionsFilterBar';
import { userStateSelector } from '../../../../state/selectors';

const mapStateToProps = (state: AppState) => {
  const allCoins = getAllCoinsSelector(state);
  const appliedFilters = getAppliedFiltersSelector(state);
  const user = userStateSelector(state);

  return { allCoins, appliedFilters, user };
};

const dispatchStateToProps = {
  toggleFilter,
  clearAllFilters: clearAllFilters,
  asyncGetReferralsPaginated: asyncGetReferralsPaginated.request,
};

export default connect(mapStateToProps, dispatchStateToProps)(TransactionsFilterBar);
