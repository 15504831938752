/* eslint-disable */
import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import { GetCoinPricesResponse } from '../../../../data/repositories/wallet/typedefs';
import { EURWithdrawAddressType } from './initialState';

export const SAVE_DEPOSIT_ADDRESS = 'wallet/create_deposit_address';
export const GET_COIN_PRICES = 'wallet/get_coin_prices';
export const GET_COIN_PRICES_SUCCESS = 'wallet/get_coin_prices_success';
export const GET_COIN_PRICES_FAIL = 'wallet/get_coin_prices_fail';

export const GET_USER = 'home/get_user';
export const GET_USER_SUCCESS = 'home/get_user_success';
export const GET_USER_FAIL = 'home/get_user_fail';

export const SET_COIN_PRICES_INPROGRESS = 'wallet/set_coin_prices_in_progress';

export const setCoinPricesInProgress = createStandardAction(SET_COIN_PRICES_INPROGRESS)<boolean>();

export const saveDepositWalletAddress = createStandardAction(SAVE_DEPOSIT_ADDRESS)<string>();

export const SET_CURRENT_HOME_PAGE = 'home/set_current_home_page';

export const setCurrentHomePage = createStandardAction(SET_CURRENT_HOME_PAGE)<string>();

export const SET_SELECTED_COIN = 'wallet/set_selected_coin';
export const setSelectedCoin = createStandardAction(SET_SELECTED_COIN)<string>();

export const SAVE_WITHDRAW_ADDRESS = 'wallet/save_withdraw_address';
export const SAVE_WITHDRAW_AMOUNT = 'wallet/save_withdraw_amount';
export const saveWithdrawAddress = createStandardAction(SAVE_WITHDRAW_ADDRESS)<
  string | EURWithdrawAddressType
>();
export const saveWithdrawAmount = createStandardAction(SAVE_WITHDRAW_AMOUNT)<string>();

export const GET_SELECTED_COIN = 'wallet/get_selected_coin';
export const getSelectedCoin = createStandardAction(GET_SELECTED_COIN)<undefined>();

export const CLEAR_COMMON_STATE = 'wallet/clear_common_state';
export const clearCommonState = createStandardAction(CLEAR_COMMON_STATE)<undefined>();

export const getCoinPrices = createStandardAction(GET_COIN_PRICES)<undefined>();
export const getCoinPricesSuccess = createStandardAction(GET_COIN_PRICES_SUCCESS)<
  GetCoinPricesResponse
>();
export const getUserSuccess = createStandardAction(GET_USER_SUCCESS)<GetCoinPricesResponse>();
export const getCoinPricesFail = createStandardAction(GET_COIN_PRICES_FAIL)<Error>();

export const GET_REFRESH_TOKEN = 'home/refresh_token';
export const GET_REFRESH_TOKEN_SUCCESS = 'home/refresh_token/success';
export const GET_REFRESH_TOKEN_FAIL = 'home/refresh_token/fail';

export const getRefreshToken = createAsyncAction(
  GET_REFRESH_TOKEN,
  GET_REFRESH_TOKEN_SUCCESS,
  GET_REFRESH_TOKEN_FAIL,
)<undefined, any, Error | string>();
