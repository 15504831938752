import { createReducer } from 'typesafe-actions';
import {
  GET_REFERRALS_PAGINATED,
  GET_REFERRALS_PAGINATED_FAILED,
  GET_REFERRALS_PAGINATED_SUCCESS,
  GET_REFERRALS_STATS,
  GET_REFERRALS_STATS_FAIL,
  GET_REFERRALS_STATS_SUCCESS,
  GET_REFERRALS_TREE,
  GET_REFERRALS_TREE_FAILED,
  GET_REFERRALS_TREE_SUCCESS,
  GET_UNCLAIMED_SLC_FAIL,
  GET_UNCLAIMED_SLC_SUCCESS,
  SET_TABLE_PAGE,
  CLEAR_ALL_FILTERS,
  TOGGLE_FILTER,
  SET_AFFILIATE_TABLE_PAGE,
  IS_ADD_DEDUCT_DIALOG_OPEN,
  IS_FULL_PROFILE_DIALOG_OPEN,
  SET_USER_FOR_DIALOG,
  TRADE_SLC,
  TRADE_SLC_SUCCESS,
  TRADE_SLC_FAIL,
  REFERRAL_DETAIL,
  REFERRAL_DETAIL_SUCCESS,
  REFERRAL_DETAIL_FAIL,
  GET_BALANCE_FROM_AFFILIATE_USER,
  GET_BALANCE_FROM_AFFILIATE_USER_SUCCESS,
  GET_BALANCE_FROM_AFFILIATE_USER_FAIL,
  PREPARE_FULL_PROFILE_FOR_AFFILIATE,
  PREPARE_FULL_PROFILE_FOR_AFFILIATE_SUCCESS,
  PREPARE_FULL_PROFILE_FOR_AFFILIATE_FAIL,
  SET_DIALOG_STEP,
  CLEAR_DIALOG_STATE,
  IS_CHANGE_SELL_LIMIT_DIALOG_OPEN,
  USER_HAS_AFFILIATE_PACKAGE_CHECK,
  USER_HAS_AFFILIATE_PACKAGE_CHECK_SUCCESS,
  USER_HAS_AFFILIATE_PACKAGE_CHECK_FAIL,
  SAVE_SLC_CHANGE_LIMIT,
  SAVE_SLC_CHANGE_LIMIT_SUCCESS,
  SAVE_SLC_CHANGE_LIMIT_FAIL,
} from './actions';
import { initialState, tradeInitialState } from './initialState';

export default createReducer(initialState, {
  [GET_REFERRALS_TREE]: state => ({
    ...state,
    referralsTreeInProgress: true,
  }),
  [GET_REFERRALS_TREE_SUCCESS]: (state, { payload }) => ({
    ...state,
    ...payload,
    referralsTreeInProgress: false,
  }),
  [GET_REFERRALS_TREE_FAILED]: state => ({
    ...state,
    referralsTreeInProgress: false,
  }),
  [REFERRAL_DETAIL]: state => ({
    ...state,
    referralDetailInProgress: true,
  }),
  [REFERRAL_DETAIL_SUCCESS]: (state, { payload }) => ({
    ...state,
    ...payload,
    referralDetailInProgress: false,
  }),
  [REFERRAL_DETAIL_FAIL]: state => ({
    ...state,
    referralDetailInProgress: false,
  }),
  [GET_REFERRALS_STATS]: state => ({
    ...state,
    inProgress: true,
  }),
  [GET_REFERRALS_STATS_SUCCESS]: (state, { payload }) => ({
    ...state,
    stats: { ...state.stats, ...payload },
    inProgress: false,
    fail: false,
  }),
  [GET_REFERRALS_STATS_FAIL]: (state, { payload }) => ({
    ...state,
    inProgress: false,
    fail: true,
    ...(payload && payload.response ? { error: payload.response.data } : {}),
  }),
  [GET_REFERRALS_PAGINATED]: state => ({
    ...state,
    inProgressRefferal: true,
    fail: false,
  }),
  [GET_REFERRALS_PAGINATED_SUCCESS]: (state, { payload }) => ({
    ...state,
    referrals: payload,
    referralsTreeInProgress: false,
    inProgressRefferal: false,
    fail: false,
  }),
  [GET_REFERRALS_PAGINATED_FAILED]: (state, { payload }) => ({
    ...state,
    inProgressRefferal: false,
    referralsTreeInProgress: false,
    fail: true,
    ...(payload && payload.response ? { error: payload.response.data } : {}),
  }),
  [USER_HAS_AFFILIATE_PACKAGE_CHECK]: state => ({
    ...state,
    dialogState: {
      ...state.dialogState,
      inProgress: true,
      fail: false,
      error: null,
      errorMessage: '',
      success: false,
      userSellSlcInfo: {
        total_amount_to_sell_in_eurocents: 0,
        total_amount_to_sell_in_slc: 0,
        allowed_amount_to_sell_in_eurocents: 0,
        allowed_amount_to_sell_in_slc: 0,
        sold_in_eurocents: 0,
        sold_in_slc: 0,
      },
    },
  }),
  [USER_HAS_AFFILIATE_PACKAGE_CHECK_SUCCESS]: (state, { payload }) => ({
    ...state,
    dialogState: {
      ...state.dialogState,
      inProgress: false,
      success: true,
      userSellSlcInfo: payload,
    },
  }),
  [USER_HAS_AFFILIATE_PACKAGE_CHECK_FAIL]: (state, { payload }) => ({
    ...state,
    dialogState: {
      ...state.dialogState,
      inProgress: false,
      fail: true,
      errorMessage: payload,
    },
  }),
  [SAVE_SLC_CHANGE_LIMIT]: state => ({
    ...state,
    dialogState: {
      ...state.dialogState,
      inProgress: true,
    },
  }),
  [SAVE_SLC_CHANGE_LIMIT_SUCCESS]: state => ({
    ...state,
    dialogState: {
      ...state.dialogState,
      inProgress: false,
      success: true,
    },
  }),
  [SAVE_SLC_CHANGE_LIMIT_FAIL]: (state, { payload }) => ({
    ...state,
    dialogState: {
      ...state.dialogState,
      inProgress: false,
      error: payload,
      success: false,
    },
  }),
  [SET_TABLE_PAGE]: (state, { payload }) => ({
    ...state,
    page: payload,
  }),
  [GET_UNCLAIMED_SLC_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      cycles: payload,
    };
  },
  [GET_UNCLAIMED_SLC_FAIL]: (state, { payload }) => ({
    ...state,
    ...(payload && payload.response ? { error: payload.response.data } : {}),
  }),
  [TOGGLE_FILTER]: (state, { payload }) => {
    return {
      ...state,
      appliedFilters: { ...state.appliedFilters, [payload.filterType]: payload.value },
    };
  },
  [CLEAR_ALL_FILTERS]: state => ({
    ...state,
    breadcrumbs: {},
    referralTree: {},
    level: 0,
    appliedFilters: new Map(),
  }),
  [SET_AFFILIATE_TABLE_PAGE]: (state, { payload }) => ({
    ...state,
    page: payload,
  }),
  [IS_ADD_DEDUCT_DIALOG_OPEN]: (state, { payload }) => {
    return {
      ...state,
      dialogState: { ...state.dialogState, isDialogOpen: payload, isFullProfileDialogOpen: false },
    };
  },
  [IS_CHANGE_SELL_LIMIT_DIALOG_OPEN]: (state, { payload }) => ({
    ...state,
    dialogState: { ...state.dialogState, isChangeSellLimitDialogOpen: payload },
  }),
  [SET_DIALOG_STEP]: (state, { payload }) => {
    return {
      ...state,
      dialogState: { ...state.dialogState, step: payload },
    };
  },
  [CLEAR_DIALOG_STATE]: state => {
    return {
      ...state,
      dialogState: {
        ...state.dialogState,
        ...tradeInitialState,
      },
    };
  },
  [IS_FULL_PROFILE_DIALOG_OPEN]: (state, { payload }) => {
    return {
      ...state,
      dialogState: { ...state.dialogState, isFullProfileDialogOpen: payload, isDialogOpen: false },
    };
  },
  [PREPARE_FULL_PROFILE_FOR_AFFILIATE]: state => {
    return {
      ...state,
      dialogState: {
        ...state.dialogState,
        inProgress: true,
        fail: false,
        error: null,
        fullUserProfile: { ...state.dialogState.fullUserProfile },
      },
    };
  },
  [PREPARE_FULL_PROFILE_FOR_AFFILIATE_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      dialogState: { ...state.dialogState, inProgress: false, fullUserProfile: payload },
    };
  },
  [PREPARE_FULL_PROFILE_FOR_AFFILIATE_FAIL]: (state, { payload }) => {
    return {
      ...state,
      dialogState: { ...state.dialogState, inProgress: false, fail: true, error: payload },
    };
  },
  [SET_USER_FOR_DIALOG]: (state, { payload }) => {
    return {
      ...state,
      dialogState: { ...state.dialogState, ...payload },
    };
  },
  [TRADE_SLC]: state => {
    const { error, fail, referralUser, isDialogOpen } = state.dialogState;

    return {
      ...state,
      dialogState: {
        ...state.dialogState,
        inProgress: true,
        error,
        fail,
        referralUser,
        isDialogOpen,
      },
    };
  },
  [TRADE_SLC_SUCCESS]: state => {
    const { error, fail, referralUser, isDialogOpen } = state.dialogState;

    return {
      ...state,
      dialogState: {
        ...state.dialogState,
        inProgress: false,
        error,
        fail,
        success: true,
        referralUser,
        isDialogOpen,
      },
    };
  },
  [TRADE_SLC_FAIL]: (state, { payload }) => {
    const { error, referralUser, isDialogOpen } = state.dialogState;

    return {
      ...state,
      dialogState: {
        ...state.dialogState,
        inProgress: false,
        error,
        fail: true,
        success: false,
        referralUser,
        isDialogOpen,
        ...(payload && payload.response
          ? {
              error: payload.response.data.error
                ? payload.response.data.error
                : payload.response.data,
            }
          : {}),
      },
    };
  },

  // clear slcBalance on open dialog since
  // it's our custom value not sent by SENT_USER_FOR_DIALOG
  [GET_BALANCE_FROM_AFFILIATE_USER]: state => {
    return {
      ...state,
      dialogState: {
        ...state.dialogState,
        referralUser: {
          ...state.dialogState.referralUser,
          slcBalance: '',
        },
      },
    };
  },

  [GET_BALANCE_FROM_AFFILIATE_USER_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      dialogState: {
        ...state.dialogState,
        referralUser: {
          ...state.dialogState.referralUser,
          slcBalance: payload,
        },
      },
    };
  },

  [GET_BALANCE_FROM_AFFILIATE_USER_FAIL]: (state, { payload }) => {
    return {
      ...state,
      dialogState: { inProgress: false, error: payload, fail: true },
    };
  },
});
