import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import React, { FC } from 'react';
import { Colors } from '../../../ui/style/colors';
import balanceUtils from '../../../utils/balanceUtils';
import {
  CRYPTO_MAX_FRACTION,
  CRYPTO_MIN_FRACTION,
  WIRE_EUR_DECIMALS,
  WIRE_EUR_MAX_FRACTION,
  WIRE_EUR_MIN_FRACTION,
} from '../../../utils/constants/amounts';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    claimReward: {
      color: `${Colors.mediumTurquoise}`,
      fontSize: 14,
      fontWeight: 600,
      textAlign: 'right',
      cursor: 'pointer',
    },
    statusSuccess: {
      color: theme.palette.success.light,
      fontSize: 14,
      textTransform: 'capitalize',
    },
    statusPending: {
      color: `${Colors.blueViolet}`,
      fontSize: 14,
      textTransform: 'capitalize',
    },
  }),
);

type Props = {
  referral: any;
};

const AffiliatesMerchantsTableRow: FC<Props> = ({ referral }: Props) => {
  const classes = useStyles();
  const renderType = (type: any) => {
    switch (type) {
      case 'new_user_reward':
        return 'Registration reward';
      case 'new_merchant_reward':
        return 'Became Partner';
      case 'merchant_parent_reward':
        return 'Affiliate became Partner';
      case 'new_user_affiliate_reward':
        return 'New Affiliate';
      case 'premium_user_reward':
        return 'Affiliate package purchase';
      case 'premium_user_affiliate_reward':
        return 'Affiliate purchased Affiliate package';
      default:
        return 'BV';
    }
  };

  const statusRenderType = (type: string) => {
    switch (type) {
      case 'pending_escrow':
        return 'Pending Escrow';
      case 'timed_escrow':
        return 'Timed Escrow';
      default:
        return type;
    }
  };

  const balance = balanceUtils.formatBalanceToString(
    referral.amount,
    7,
    CRYPTO_MIN_FRACTION,
    CRYPTO_MAX_FRACTION,
  );
  return (
    <TableRow>
      <TableCell
        className={
          referral.transaction_status === 'completed'
            ? classes.statusSuccess
            : classes.statusPending
        }
      >
        {statusRenderType(referral.transaction_status)}
      </TableCell>
      <TableCell>{renderType(referral.origin)}</TableCell>
      <TableCell>
        <div>{referral.affiliate_email.String}</div>
      </TableCell>
      <TableCell>
        <div> {moment(referral.created_at).format('DD/MM/YYYY HH:mm')}</div>
      </TableCell>
      <TableCell align="right">{`${balance} ${referral.symbol}`}</TableCell>
      <TableCell align="right">
        {`${balanceUtils.formatBalanceToString(
          referral.eur_amount || 0,
          WIRE_EUR_DECIMALS,
          WIRE_EUR_MIN_FRACTION,
          WIRE_EUR_MAX_FRACTION,
        )} EUR`}
      </TableCell>
    </TableRow>
  );
};

export default AffiliatesMerchantsTableRow;
