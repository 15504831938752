import { connect } from 'react-redux';
import { ALL_COINS, WALLET_COINS } from '../../../../utils/constants/amounts';
import { AppState } from '../../../state/initialState';
import { setCurrentHomePage } from '../../state/common/actions';
import { hasCompletedKYCSelector } from '../Settings/state/selectors';
import { getAllCoins, getWalletBalance } from './state/actions';
import {
  expensiveGetWalletWithMetadataBySymbol,
  getComponentStateSelector,
  getCurrenciesSymbolsSelector,
} from './state/selectors';
import Wallet from './Wallet';

type OwnProps = {
  location?: any;
};

const mapDispatchToProps = {
  getWalletBalance: getWalletBalance,
  getAllCoins: getAllCoins,
  setCurrentHomePage: setCurrentHomePage,
};
const mapStateToProps = (state: AppState, { location }: OwnProps) => {
  let walletsSymbols = getCurrenciesSymbolsSelector(state);
  // If there is any missing wallet, place dummy data in order to notify him
  // to buy membership or contact support
  if (walletsSymbols.length !== ALL_COINS.length) {
    walletsSymbols = [...new Set([...walletsSymbols, ...WALLET_COINS])];
  }
  walletsSymbols.concat();
  const hasCompletedKYC = hasCompletedKYCSelector(state);
  const componentState = getComponentStateSelector(state);
  const wallets = walletsSymbols.map(walletSymbol => {
    return expensiveGetWalletWithMetadataBySymbol(walletSymbol)(state);
  });

  return {
    hasCompletedKYC,
    walletsSymbols,
    componentState,
    wallets,
    location,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Wallet);
