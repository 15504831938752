import { connect } from 'react-redux';
import { AppState } from '../../../../../state/initialState';
import {
  clearCommonState,
  saveWithdrawAddress,
  saveWithdrawAmount,
} from '../../../../state/common/actions';
import { getWithdrawAddress, getWithdrawAmount } from '../../../../state/common/selectors';
import { profileInfoStateSelector } from '../../../Settings/state/selectors';
import { asyncGetRevolutCounterparty, openWithdrawDialog } from '../../state/actions';
import {
  getRevolutCounterpartySelector,
  getSelectedCoinSelector,
  getWalletBySymbolSelectorSelector,
} from '../../state/selectors';
import { WithdrawEURDialogContent } from './WithdrawEURDialogContent';

const mapDispatchToProps = {
  saveWithdrawAddress: saveWithdrawAddress,
  saveWithdrawAmount: saveWithdrawAmount,
  openWithdrawDialog: openWithdrawDialog,
  clearCommonState: clearCommonState,
  asyncGetRevolutCounterparty: asyncGetRevolutCounterparty.request,
};

const mapStateToProps = (state: AppState) => {
  const selectedCoin = getSelectedCoinSelector(state);
  const wallet = getWalletBySymbolSelectorSelector(state);
  const withdrawAmount = getWithdrawAmount(state);
  const withdrawAddress = getWithdrawAddress(state);
  const getRevolutCounterpartyState = getRevolutCounterpartySelector(state);
  const profileInfo = profileInfoStateSelector(state);

  return {
    selectedCoin,
    wallet,
    withdrawAmount,
    withdrawAddress,
    getRevolutCounterpartyState,
    profileInfo,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawEURDialogContent);
