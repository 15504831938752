import { connect } from 'react-redux';
import { AppState } from '../../../../../state/initialState';
import {
  clearTwoFactorAuthState,
  isTwoFactorAuthDialogOpen,
  openTwoFactorAuth,
} from '../state/actions';
import { twoFactorAuthStateSelector } from '../state/selectors';
import Settings2faDialog from './Settings2faDialog';

const mapDispatchToProps = {
  openTwoFactorAuth: openTwoFactorAuth,
  clearTwoFactorAuthState: clearTwoFactorAuthState,
  isTwoFactorAuthDialogOpen: isTwoFactorAuthDialogOpen,
};
const mapStateToProps = (state: AppState) => ({ ...twoFactorAuthStateSelector(state) });

export default connect(mapStateToProps, mapDispatchToProps)(Settings2faDialog);
