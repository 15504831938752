import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import {
  GetFriendsInvitationsPaginatedRequest,
  GetFriendsInvitationsSuccessPayload,
  GetTotalFriendsInvitationsSuccessPayload,
  TotalInvitesRequest,
} from '../../../../../../data/repositories/invites/typedef';

export const GET_FRIENDS_INVITATIONS = 'invites/get_friends_invitations';
export const GET_FRIENDS_INVITATIONS_SUCCESS = 'invites/get_friends_invitations/success';
export const GET_FRIENDS_INVITATIONS_FAIL = 'invites/get_friends_invitations/fail';

export const GET_TOTAL_FRIENDS_INVITATIONS = 'invites/get_total_friends_invitations';
export const GET_TOTAL_FRIENDS_INVITATIONS_SUCCESS =
  'invites/get_total_friends_invitations/success';
export const GET_TOTAL_FRIENDS_INVITATION_FAIL = 'invites/get_total_friends_invitations/fail';

export const TOGGLE_FILTER = 'invites/toggle_filters';
export const CLEAR_ALL_FILTERS = 'invites/clear_all_filters';

export const SET_INVITES_TABLE_PAGE = 'invites/set_invites_table_page';

export const toggleFilter = createStandardAction(TOGGLE_FILTER)<string>();
export const clearAllFilters = createStandardAction(CLEAR_ALL_FILTERS)<undefined>();

export const asyncGetFriendsInvitationsPaginated = createAsyncAction(
  GET_FRIENDS_INVITATIONS,
  GET_FRIENDS_INVITATIONS_SUCCESS,
  GET_FRIENDS_INVITATIONS_FAIL,
)<GetFriendsInvitationsPaginatedRequest, GetFriendsInvitationsSuccessPayload, Error>();

export const asyncGetTotalFriendsInvitationsPaginated = createAsyncAction(
  GET_TOTAL_FRIENDS_INVITATIONS,
  GET_TOTAL_FRIENDS_INVITATIONS_SUCCESS,
  GET_TOTAL_FRIENDS_INVITATION_FAIL,
)<TotalInvitesRequest, GetTotalFriendsInvitationsSuccessPayload, Error>();

export const setInvitesTablePage = createStandardAction(SET_INVITES_TABLE_PAGE)<number>();
