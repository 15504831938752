import { Cycle, Referral } from '../../../../../../data/repositories/affiliate/typedefs';
import { TransactionsFilter } from '../../../../../../data/repositories/transaction/typedef';

export type ReferralStats = {
  totalReferrals?: number | null;
  directReferrals?: number | null;
  paginatedReferrals?: number;
};

export type referralUser = {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  tx_hash: null;
  level: number;
  has_children: boolean;
  created_at: string;
  send_to_user: boolean;
  slcBalance?: string;
  referral_id?: string;
};

export type ChangeUserStatusRequest = {
  id: string;
  referral_id?: string;
  enabled: boolean;
};

export type walletType = {
  address: string;
  aliases: string[];
  symbol: string;
  balance: number;
  pending_balance: number;
  waiting_balance: number;
  escrow_balance: number;
  reward_escrow_balance: number;
  pending_escrow_balance: number;
  timed_escrow_balance: number;
  invested_balance: number;
};

type AddressType = {
  id: string;
  account_id: string;
  address: string;
  postal_code: string;
  country: string;
  city: string;
  flat_number?: string;
};

type fullUserProfileType = {
  kyc_info: {
    account_id: string;
    applicant_id: string;
    first_name: string;
    last_name: string;
    middle_name: string;
    date_of_birth: string;
    gender: string;
    phone_number: string | null;
    citizenship: string;
    addresses: AddressType | null;
    documents: string | null;
    created_at: string;
    updated_at: string;
  };
  affiliate_package_info: {
    user_identifier: string;
    affiliate_package_identifier: string;
    affiliate_package_level: number;
    expires_at: number;
    expires_at_string: string;
    cadence: string;
    is_expired: boolean;

    sell_high_limit: number;
    sell_low_limit: number;
    created_at: string;
  };
  wallets: walletType[];
};

export type SellSlcInfoResponse = {
  total_amount_to_sell_in_eurocents: number;
  total_amount_to_sell_in_slc: number;
  allowed_amount_to_sell_in_eurocents: number;
  allowed_amount_to_sell_in_slc: number;
  sold_in_eurocents: number;
  sold_in_slc: number;
};

export type SellSlcInfoRequest = {
  adminId: string;
  id: string;
  sell_limit_in_eurocents: number;
};

export type TradeInitialState = {
  fail: boolean;
  error: null;
  errorMessage: string;
  isDialogOpen: boolean;
  isFullProfileDialogOpen: boolean;
  success: boolean;
  isEscrow: boolean;
  step: number;
  amount: string;
  comment: string;
  isChangeSellLimitDialogOpen: boolean;
  userSellSlcInfo: SellSlcInfoResponse;
};
export const tradeInitialState = {
  fail: false,
  error: null,
  errorMessage: '',
  success: false,
  isDialogOpen: false,
  isFullProfileDialogOpen: false,
  isEscrow: false,
  amount: '',
  comment: 'null',
  step: 0,
  isChangeSellLimitDialogOpen: false,
  userSellSlcInfo: {
    total_amount_to_sell_in_eurocents: 0,
    total_amount_to_sell_in_slc: 0,
    allowed_amount_to_sell_in_eurocents: 0,
    allowed_amount_to_sell_in_slc: 0,
    sold_in_eurocents: 0,
    sold_in_slc: 0,
  },
};

export type dialogState = TradeInitialState & {
  inProgress: boolean;
  referralUser: referralUser;
  fullUserProfile: fullUserProfileType;
};

export type AffiliateTableState = {
  referrals: Map<string, Referral> | null;
  inProgress: boolean;
  inProgressRefferal: boolean;
  referralTree: Record<string, unknown>;
  referralsTreeInProgress: any;
  referralDetail: Record<string, unknown>;
  referralDetailInProgress: boolean;
  level: number;
  appliedFilters: TransactionsFilter;
  breadcrumbs: Record<string, unknown>;
  fail: boolean;
  error: Error | null;
  stats: ReferralStats;
  page: number;
  perPage: number;
  cycles: Cycle[];
  dialogState: dialogState;
};

export const initialState: AffiliateTableState = {
  referrals: null,
  referralTree: {},
  level: 0,
  appliedFilters: new Map<string, string[]>(),
  breadcrumbs: {},
  referralsTreeInProgress: false,
  referralDetail: {},
  referralDetailInProgress: false,
  inProgress: false,
  inProgressRefferal: false,
  fail: false,
  error: null,
  page: 0,
  perPage: 10,
  cycles: [],
  stats: {
    totalReferrals: null,
    directReferrals: null,
    paginatedReferrals: 0,
  },
  dialogState: {
    ...tradeInitialState,
    inProgress: false,
    referralUser: {
      id: '',
      email: '',
      first_name: '',
      last_name: '',
      tx_hash: null,
      level: 0,
      has_children: false,
      created_at: '',
      send_to_user: false,
      slcBalance: '',
    },
    fullUserProfile: {
      kyc_info: {
        account_id: '',
        applicant_id: '',
        first_name: '',
        last_name: '',
        middle_name: '',
        date_of_birth: '',
        gender: '',
        phone_number: '' || null,
        citizenship: '',
        addresses: '' || null,
        documents: '' || null,
        created_at: '',
        updated_at: '',
      },
      affiliate_package_info: {
        affiliate_package_identifier: '',
        affiliate_package_level: 0,
        cadence: '',
        expires_at: 0,
        expires_at_string: '',
        is_expired: false,
        user_identifier: '',
        sell_high_limit: 0,
        sell_low_limit: 0,
        created_at: '',
      },
      wallets: [],
    },
  },
};
