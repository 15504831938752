import { createReducer } from 'typesafe-actions';
import { SAVE_ALL_COINS } from '../../HomeContent/Wallet/state/actions';
import { SAVE_ALL_COINS as SAVE_ALL_COINS_DASHBOARD } from '../../HomeContent/Dashboard/state/actions';
import {
  CLEAR_COMMON_STATE,
  SAVE_DEPOSIT_ADDRESS,
  SAVE_WITHDRAW_ADDRESS,
  SAVE_WITHDRAW_AMOUNT,
  SET_CURRENT_HOME_PAGE,
  SET_SELECTED_COIN,
  GET_COIN_PRICES_SUCCESS,
  SET_COIN_PRICES_INPROGRESS,
  GET_USER_SUCCESS,
  GET_REFRESH_TOKEN,
  GET_REFRESH_TOKEN_SUCCESS,
  GET_REFRESH_TOKEN_FAIL,
} from './actions';
import { initialState } from './initialState';

export default createReducer(initialState, {
  [SAVE_DEPOSIT_ADDRESS]: (state, { payload }) => ({
    ...state,
    depositWalletAddress: payload,
  }),
  [SAVE_ALL_COINS]: (state, { payload }) => ({
    ...state,
    allCoins: payload,
  }),
  [SAVE_ALL_COINS_DASHBOARD]: (state, { payload }) => ({
    ...state,
    allCoins: payload,
  }),
  [SAVE_WITHDRAW_ADDRESS]: (state, { payload }) => {
    if (payload && payload.currency && payload.currency === 'EUR') {
      return {
        ...state,
        withdrawAddress: {
          ...payload,
        },
      };
    }

    return {
      ...state,
      withdrawAddress: payload,
    };
  },
  [SAVE_WITHDRAW_AMOUNT]: (state, { payload }) => ({
    ...state,
    withdrawAmount: payload,
  }),
  [SET_CURRENT_HOME_PAGE]: (state, { payload }) => ({
    ...state,
    currentHomePage: payload,
  }),
  [SET_SELECTED_COIN]: (state, { payload }) => ({
    ...state,
    selectedCoin: payload,
  }),
  [CLEAR_COMMON_STATE]: state => ({
    ...state,
    withdrawAddress: '',
    withdrawAmount: '0',
  }),
  [GET_COIN_PRICES_SUCCESS]: (state, { payload }) => ({
    ...state,
    coinPrices: payload,
  }),
  [GET_USER_SUCCESS]: (state, { payload }) => ({
    ...state,
    user: payload,
  }),
  [SET_COIN_PRICES_INPROGRESS]: (state, { payload }) => ({
    ...state,
    coinPricesInProgress: payload,
  }),
  [GET_REFRESH_TOKEN]: state => ({
    ...state,
    refreshToken: { inProgress: true },
  }),
  [GET_REFRESH_TOKEN_SUCCESS]: state => ({
    ...state,
    refreshToken: { inProgress: false, success: true, fail: false },
  }),
  [GET_REFRESH_TOKEN_FAIL]: (state, { payload }) => ({
    ...state,
    refreshToken: {
      inProgress: false,
      success: false,
      fail: true,
      ...(payload ? { error: payload.message } : {}),
    },
  }),
});
