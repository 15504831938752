import { connect } from 'react-redux';
import { AppState } from '../../../../../state/initialState';
import WalletTransfers from './WalletTransfers';
import {
  getUserTransactionHistoryAppliedFiltersSelector,
  getWalletTransfersStateSelector,
  getSelectedUserStateSelector,
  userTransactionHistoryStateSelector,
} from '../state/selectors';
import {
  getWalletTransfersPaginated,
  userTransactionHistoryClearAllFilters,
  setWalletTransfersTable,
  asyncExportWalletTransfers,
} from '../state/actions';

const mapStateToProps = (state: AppState) => {
  const exportInProgress = userTransactionHistoryStateSelector(state).exportInProgress;
  const selectedUser = getSelectedUserStateSelector(state);
  return {
    ...getWalletTransfersStateSelector(state),
    appliedFilters: getUserTransactionHistoryAppliedFiltersSelector(state),
    exportInProgress,
    selectedUser,
  };
};

const mapDispatchToProps = {
  getWalletTransfersPaginated: getWalletTransfersPaginated.request,
  clearAllFilters: userTransactionHistoryClearAllFilters,
  setWalletTransfersTable: setWalletTransfersTable,
  asyncExportWalletTransfers: asyncExportWalletTransfers.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(WalletTransfers);
