import { Grid, Hidden, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import LightTooltip from '../../../../../../components/LightTooltip';
import { Colors } from '../../../../../../ui/style/colors';
import balanceUtils from '../../../../../../utils/balanceUtils';
import { COMMING_SOON } from '../../../../../../utils/constants/amounts';
import { getCurrencyFraction } from '../../../../../../utils/helper/numbers';
import { getCurrencySymbolName } from '../../../../../../utils/helper/utils';

const styles = (theme: Theme) =>
  createStyles({
    commingSoon: {
      color: `${Colors.mediumTurquoise}`,
    },
    tooltip: {
      color: `${Colors.gray}`,
    },
    walletNameMobile: {
      marginTop: 5,
      overflowWrap: 'anywhere',
      [theme.breakpoints.down('xs')]: {
        fontSize: '0.6rem',
      },
    },
    unavailable: {
      textTransform: 'capitalize',
      color: `${Colors.mediumTurquoise}`,
    },
    unavailableLink: {
      color: `${Colors.gray}`,
      '&:hover': {
        color: `${Colors.black}`,
      },
    },
  });

const useStyles = makeStyles(styles);
type Props = { wallet?: any };

const WalletDrawerItem = ({ wallet }: Props) => {
  const classes = useStyles();
  const symbolName = getCurrencySymbolName(wallet.symbol);
  const isCommingSoon = COMMING_SOON.includes(wallet.symbol);

  const getBalance = () =>
    wallet.balance !== 'string' ? (
      `${balanceUtils.formatBalanceToString(
        wallet.balance || '0',
        wallet.decimals,
        getCurrencyFraction(wallet.symbol, 'min'),
        getCurrencyFraction(wallet.symbol, 'max'),
      )}${' '}
        ${symbolName}`
    ) : (
      <Typography className={classes.unavailable} align="left">
        Unavailable
        <LightTooltip
          variant="buyAddress"
          symbolName={symbolName}
          interactive={true}
          className={classes.tooltip}
        />
      </Typography>
    );
  return !isCommingSoon ? (
    <Grid item>
      <Hidden smDown>
        <Grid container wrap="nowrap" alignItems="center">
          <Grid item>
            <img src={wallet.icon} style={{ width: 40, height: 40, marginRight: 10 }} />
          </Grid>
          <Grid item>
            <Typography align="left">
              {wallet.name} &nbsp;({symbolName})
            </Typography>
            <Typography align="left">{getBalance()}</Typography>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid container direction="column" alignItems="center">
          <img src={wallet.icon} style={{ width: 20, height: 20 }} />

          <Typography component="span" className={classes.walletNameMobile}>
            {wallet.name} &nbsp;({symbolName})
          </Typography>
          <Typography component="span" className={classes.walletNameMobile}>
            {getBalance()}
          </Typography>
        </Grid>
      </Hidden>
    </Grid>
  ) : (
    <Grid container wrap="nowrap" alignItems="center">
      <Grid item>
        <img src={wallet.icon} style={{ width: 40, height: 40, marginRight: 10 }} />
      </Grid>
      <Grid item>
        <Typography align="left">
          {wallet.name} &nbsp;({symbolName})
        </Typography>
        <Typography align="left" className={classes.commingSoon}>
          COMMING SOON
        </Typography>
      </Grid>
    </Grid>
  );
};

export default WalletDrawerItem;
