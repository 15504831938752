import { createStyles, Grid, makeStyles } from '@material-ui/core';
import { GridJustification, GridSize, GridSpacing } from '@material-ui/core/Grid';
import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { EmptyAC } from 'typesafe-actions';
import { getAllCoins } from '../../App/Home/HomeContent/Wallet/state/actions';
import {
  expensiveGetWalletWithMetadataBySymbol,
  getAllCoinsSelector,
} from '../../App/Home/HomeContent/Wallet/state/selectors';
import { AppState } from '../../App/state/initialState';
import { Coin, WalletMetadata } from '../../data/repositories/wallet/typedefs';

const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      height: 25,
      width: 25,
    },
    symbolName: {
      maxWidth: 25,
      marginLeft: '5px',
      visibility: 'visible',
      opacity: 1,
      transition: 'visibility 0s, opacity 0.5s linear',
    },
    symbolNameNone: {
      visibility: 'hidden',
      opacity: 0,
    },
  }),
);

type Props = {
  getAllCoins: EmptyAC<'wallet/get_all_coins'>;
  allCoins: Map<string, Coin>;
  symbol: string;
  hideSymbol?: boolean;
  value?: any;
  className?: string;
  containerJustfy?: GridJustification;
  containerGridSpacing?: GridSpacing;
  imgGridSize?: GridSize;
  imgClass?: string;
};

const CoinIconCell = ({
  allCoins,
  getAllCoins,
  symbol,
  hideSymbol = false,
  value,
  className,
  containerJustfy = 'flex-start',
  containerGridSpacing = 0,
  imgClass,
}: Props) => {
  const classes = useStyles();
  const wallet: WalletMetadata = useSelector((state: AppState) =>
    expensiveGetWalletWithMetadataBySymbol(
      symbol === 'SLT' ? 'SLC' : symbol === 'EUR' ? 'WALLET_EUR' : symbol,
    )(state),
  );

  useEffect(() => {
    if (allCoins.size === 0) {
      getAllCoins();
    }
  }, []);

  const getCoinWithSymbol = () => (
    <Grid
      container
      alignItems="center"
      justifyContent={containerJustfy}
      spacing={containerGridSpacing}
      wrap="nowrap"
    >
      <img src={wallet.icon} alt="icon" className={imgClass ? imgClass : classes.icon} />
      &nbsp;
      <Grid item className={!hideSymbol ? classes.symbolName : classes.symbolNameNone}>
        {symbol}
      </Grid>
    </Grid>
  );
  return value ? (
    <Grid
      container
      wrap="nowrap"
      spacing={2}
      justifyContent="flex-end"
      alignItems="center"
      className={className ? className : ''}
    >
      {value}
      <Grid item xs={6}>
        {getCoinWithSymbol()}
      </Grid>
    </Grid>
  ) : (
    getCoinWithSymbol()
  );
};
const mapDispatchToProps = {
  getAllCoins: getAllCoins,
};

const mapStateToProps = (state: AppState) => {
  const allCoins = getAllCoinsSelector(state);
  return { allCoins };
};

export default connect(mapStateToProps, mapDispatchToProps)(CoinIconCell);
