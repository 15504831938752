import { Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { EmptyAC, PayloadAC } from 'typesafe-actions';
import SacretDaterangePickerSelect from '../../../../../../components/SacrateDaterangePickerSelect';
import SacretInputNumber from '../../../../../../components/SacretInputNumber';
import SacretSearchInput from '../../../../../../components/SacretSearchInput';
import SacretSelect from '../../../../../../components/SacretSelect';
import {
  GET_PRODUCTS_PURCHASE_HISTORY_PAGINATED,
  PRODUCTS_PURCHASE_HISTORY_CLEAR_ALL_FILTERS,
  PRODUCTS_PURCHASE_HISTORY_TOGGLE_FILTER,
} from '../state/actions';
import AppliedFiltersChips from './AppliedFiltersChips';

const styles = (theme: Theme) =>
  createStyles({
    filter: {
      minWidth: 200,
      marginRight: 10,
    },
    'm-r': {
      marginRight: 10,
    },
    valueSlider: {},
    clearAll: {
      textTransform: 'none',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    clearAllIcon: {
      fontSize: 12,
      padding: 5,
    },
    label: {
      float: 'left',
      padding: '2px 0',
      fontSize: 12,
      color: theme.palette.primary.light,
    },
    minWidth: {
      minWidth: 236,
    },
  });

const useStyles = makeStyles(styles);

type Props = {
  productsPurchaseHistoryToggleFilter: PayloadAC<
    typeof PRODUCTS_PURCHASE_HISTORY_TOGGLE_FILTER,
    any
  >;
  appliedFilters: Map<string, string[]>;
  productsPurchaseHistoryClearAllFilters: EmptyAC<
    typeof PRODUCTS_PURCHASE_HISTORY_CLEAR_ALL_FILTERS
  >;
  asyncGetProductsPurchaseHistory: PayloadAC<typeof GET_PRODUCTS_PURCHASE_HISTORY_PAGINATED, any>;
};

const statuses = [
  { name: 'Accepted', value: 'accepted' },
  { name: 'Rejected', value: 'rejected' },
  { name: 'Pending', value: 'created' },
];

const ProductsPurchaseHistoryFilterBar: React.FC<Props> = ({
  appliedFilters,
  asyncGetProductsPurchaseHistory,
  productsPurchaseHistoryToggleFilter,
  productsPurchaseHistoryClearAllFilters,
}): JSX.Element => {
  const [productInputValue, setProductInputValue]: any = useState('');
  const [emailInputValue, setEmailInputValue]: any = useState('');
  const classes = useStyles();

  const handleOnChangeEvent = (
    value: string[] | string | undefined,
    filterType: string,
    limit?: string,
  ) => {
    const object: any = {};
    appliedFilters.forEach((value, key) => {
      object[key] = value;
    });

    let valueRange;

    switch (limit) {
      case 'Min':
        valueRange = [value, object.valueRange ? object.valueRange[1] : '100000'];
        const maxValue = valueRange[1] === '100000' ? '' : valueRange[1];

        asyncGetProductsPurchaseHistory({
          page: 0,
          perPage: 10,
          appliedFilters: { ...object, [filterType]: valueRange },
        });
        value = value === '' ? '' : value;
        productsPurchaseHistoryToggleFilter({
          filterType: 'valueRange',
          minValue: value,
          maxValue: maxValue,
          value: valueRange,
        });
        break;
      case 'Max':
        valueRange = [object.valueRange ? object.valueRange[0] : '-100000', value];
        const minValue = valueRange[0] === '-100000' ? '' : valueRange[0];

        asyncGetProductsPurchaseHistory({
          page: 0,
          perPage: 10,
          appliedFilters: { ...object, [filterType]: valueRange },
        });
        value = value === '' ? '' : value;

        productsPurchaseHistoryToggleFilter({
          filterType: 'valueRange',
          maxValue: value,
          minValue: minValue,
          value: valueRange,
        });
        break;

      default:
        asyncGetProductsPurchaseHistory({
          page: 0,
          perPage: 10,
          appliedFilters: { ...object, [filterType]: value },
        });

        productsPurchaseHistoryToggleFilter({ filterType, value });
        break;
    }
    if (filterType === 'productName') setProductInputValue('');
    if (filterType === 'searchInput') setEmailInputValue('');
  };

  return (
    <Grid container spacing={2}>
      <Grid xs={12} sm="auto" item className={classes.filter}>
        <SacretSelect
          items={statuses}
          label="Status"
          onChange={(value: string[]) => handleOnChangeEvent(value, 'status')}
          stateFilterValue={appliedFilters.get('status')}
        />
      </Grid>

      <Grid xs={12} sm="auto" item className={`${classes.filter} ${classes.minWidth}`}>
        <SacretSearchInput
          onChange={(value: string[]) => setProductInputValue(value)}
          stateFilterValue={[productInputValue]}
          placeholder="Search by product name"
          searchHandler={() => handleOnChangeEvent([productInputValue], 'productName')}
        />
      </Grid>
      <Grid xs={12} sm="auto" item className={`${classes.filter} ${classes.minWidth}`}>
        <SacretSearchInput
          onChange={(value: string[]) => {
            const val = value ? [value[0].trim()] : value;
            setEmailInputValue(val);
          }}
          stateFilterValue={[emailInputValue]}
          searchHandler={() => handleOnChangeEvent([emailInputValue], 'searchInput')}
        />
      </Grid>
      <Grid xs={12} sm="auto" item className={`${classes.filter} ${classes.minWidth}`}>
        <SacretDaterangePickerSelect
          label="Choose a date"
          onChange={(value: string[]) => handleOnChangeEvent(value, 'dateFilter')}
          stateFilterValue={appliedFilters.get('dateFilter')}
        />
      </Grid>

      <Grid item className={classes['m-r']}>
        <SacretInputNumber
          currency="SLC"
          limit="Min"
          onChange={(value: string) => handleOnChangeEvent(value, 'valueRange', 'Min')}
          stateValue={appliedFilters.get('minValue')}
        />
      </Grid>
      <Grid item className={classes['m-r']}>
        <SacretInputNumber
          currency="SLC"
          limit="Max"
          onChange={(value: string) => {
            handleOnChangeEvent(value, 'valueRange', 'Max');
          }}
          stateValue={appliedFilters.get('maxValue')}
        />
      </Grid>

      <AppliedFiltersChips
        productsPurchaseHistoryClearAllFilters={productsPurchaseHistoryClearAllFilters}
        stateAppliedFilters={appliedFilters}
        productsPurchaseHistoryToggleFilter={productsPurchaseHistoryToggleFilter}
      />
    </Grid>
  );
};

export default ProductsPurchaseHistoryFilterBar;
