import { connect } from 'react-redux';
import { AppState } from '../../../../state/initialState';
import Profile from './Profile';
import { profileInfoStateSelector } from '../state/selectors';

const mapStateToProps = (state: AppState) => ({
  profileInfo: profileInfoStateSelector(state),
});

export default connect(mapStateToProps, null)(Profile);
