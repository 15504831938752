import { connect } from 'react-redux';
import { AppState } from '../../../../../../state/initialState';
import { getAllCoinsSelector } from '../../../../Wallet/state/selectors';
import AllTransactionsFilterBar from './AllTransactionsFilterBar';
import {
  asyncGetAllTransactionsPaginated,
  userTransactionHistoryToggleFilter,
  userTransactionHistoryClearAllFilters,
} from '../../state/actions';
import { getUserTransactionHistoryAppliedFiltersSelector } from '../../state/selectors';

const mapStateToProps = (state: AppState) => {
  const allCoins = getAllCoinsSelector(state);
  const appliedFilters = getUserTransactionHistoryAppliedFiltersSelector(state);
  return { allCoins, appliedFilters };
};

const dispatchStateToProps = {
  asyncGetAllTransactionsPaginated: asyncGetAllTransactionsPaginated.request,
  toggleFilter: userTransactionHistoryToggleFilter,
  clearAllFilters: userTransactionHistoryClearAllFilters,
};

export default connect(mapStateToProps, dispatchStateToProps)(AllTransactionsFilterBar);
