import { Button, createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router';
import Satelite from '../../assets/satelite.svg';
import { SUPPORT_EMAIL } from '../../utils/constants/info';
const styles = (theme: Theme) =>
  createStyles({
    img: {
      boxSizing: 'border-box',
      textAlign: 'center',
      width: '100%',
    },
    title: {
      fontSize: 24,
      color: theme.palette.primary.light,
      fontWeight: 'bold',
    },
    answer: {
      fontSize: 14,
      color: theme.palette.primary.light,
      marginTop: 15,
    },
  });
const useStyles = makeStyles(styles);
type Props = {
  title?: string;
  errorMessage?: string;
  showBackButton?: boolean;
  className?: string;
};

const SacretError = ({
  title = 'Something went wrong!',
  errorMessage = '',
  showBackButton = true,
  className,
}: Props) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
      style={{ marginTop: 100 }}
      className={className}
    >
      <Grid item xs={2}>
        <img src={Satelite} alt="ilustration" className={classes.img} />
      </Grid>

      <Grid item xs={6} style={{ marginTop: 50 }}>
        <Typography variant="h5" align="center" className={classes.title}>
          {title}
        </Typography>
        <Typography gutterBottom align="center" className={classes.answer}>
          {errorMessage}
        </Typography>
        <Typography gutterBottom align="center" className={classes.answer}>
          Please try again later or contact our support{' '}
          <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
        </Typography>
      </Grid>
      {showBackButton && (
        <Grid item xs={3}>
          <Button onClick={() => history.goBack()}> Go Back</Button>
        </Grid>
      )}
    </Grid>
  );
};

export default SacretError;
