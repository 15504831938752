import { connect } from 'react-redux';
import { AppState } from '../../../../state/initialState';
import ProductCards from './ProductCards';
import { productTableStateSelector } from '../ProductsTable/state/selectors';
import { userStateSelector } from '../../../../state/selectors';

const mapStateToProps = (state: AppState) => ({
  ...productTableStateSelector(state),
  user: userStateSelector(state),
});

export default connect(mapStateToProps, {})(ProductCards);
