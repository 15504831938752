import React from 'react';
import Invest2fa from './Invest2fa';
import { connect } from 'react-redux';
import { AppState } from '../../../../../state/initialState';
import {
  isTwoFactorInProgress,
  twoFactorAuthEnableSelector,
} from '../../../Settings/SettingsTwoFactorAuth/state/selectors';

const Invest2faStep = (props: any) => (
  <Invest2fa initValues={{ step: 2 }} submitText={''} {...props} />
);

const mapStateToProps = (state: AppState) => {
  return {
    is2FaEnabled: twoFactorAuthEnableSelector(state),
    is2FaInProgress: isTwoFactorInProgress(state),
  };
};
export default connect(mapStateToProps, {})(Invest2faStep);
