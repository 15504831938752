import {
  AffiliateMerchantsTableState,
  initialState as affiliateMerchantsTable,
} from '../AffiliateMerchantsTable/state/initialState';

export type OrdersState = {
  affiliateMerchantsTable: AffiliateMerchantsTableState;
};

export const initialState = {
  affiliateMerchantsTable,
};
