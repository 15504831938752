import { useSelector } from 'react-redux';
import { AppState } from '../../../../state/initialState';
import { walletStateSelector } from '../../Wallet/state/selectors';
import { getInvestCurrencySelector } from './state/selectors';

const useInvestCurrencyState = () => {
  const investCurrency = useSelector((state: AppState) => getInvestCurrencySelector(state));
  const walletState = useSelector((state: AppState) => walletStateSelector(state));

  return { investCurrency, walletState };
};

export default useInvestCurrencyState;
