import { CircularProgress, Paper, TablePagination } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Invite } from '../../data/repositories/invites/typedef';
import { Colors } from '../../ui/style/colors';
import TablePaginationActions from '../TablePaginationActions';
import InvitesTableRow from './InvitesTableRow';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      overflowY: 'auto',
      boxShadow: '0 10px 30px 0 rgba(132,141,182,0.25)',
      backgroundColor: `${Colors.white}`,
      borderRadius: '3px',
    },
    table: {
      minWidth: 650,
      borderCollapse: 'collapse',
      borderRadius: 6,
    },
    head: {
      backgroundColor: 'rgba(242, 243, 247, 0.7)',
    },
    headerText: {
      color: theme.palette.primary.light,
      fontSize: 12,
      fontWeight: 600,
      letterSpacing: 0.86,
    },
    pagination: { flexWrap: 'wrap', justifyContent: 'space-between', borderRadius: 5 },
    spacer: { flex: '0 0 100%' },
  }),
);

export type HeaderProps = {
  name: string;
  align?: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined;
};

type Props = {
  invites: any;
  totalInvites: number;
  headers: HeaderProps[];
  page: number;
  filter: any;
  inProgress: boolean;
  accountId: string | null;
};

const InvitesTable = ({
  invites,
  headers,
  page,
  inProgress,
  filter,
  totalInvites,
  accountId,
}: Props) => {
  const classes = useStyles();
  const dispath = useDispatch();

  const [expanded, setExpanded] = React.useState(-1);

  useEffect(() => {
    setExpanded(-1);
  }, [invites]);

  const handleChangePage = (event: unknown, newPage: number) => {
    const object: any = {};
    filter &&
      filter.forEach((value: any, key: React.ReactText) => {
        object[key] = value;
      });
    dispath({ type: 'invites/set_invites_table_page', payload: newPage });
    accountId &&
      dispath({
        type: 'invites/get_friends_invitations',
        payload: {
          accountId: accountId,
          page: newPage,
          perPage: 10,
          appliedFilters: {
            ...object,
          },
        },
      });
  };

  const rowsPerPage = 10;
  const emptyRows = rowsPerPage - Object.keys(invites).length;

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead className={classes.head}>
          <TableRow>
            {headers.map(header => (
              <TableCell
                className={classes.headerText}
                key={header.name}
                align={header.align}
                component={'th'}
              >
                {header.name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {inProgress ? (
          <TableBody style={{ minHeight: 530 }}>
            <TableRow>
              <TableCell colSpan={8} style={{ textAlign: 'center' }}>
                <CircularProgress color="secondary" size={60} />
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <TableBody style={{ minHeight: 530 }}>
            {invites.map((invite: Invite, index: number) => (
              <InvitesTableRow
                expanded={expanded === index}
                setExpanded={custom => {
                  if (custom === 'close') {
                    setExpanded(-1);
                  } else setExpanded(prev => (prev === index ? -1 : index));
                }}
                key={`invite-${index}`}
                invite={invite}
              />
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        )}
      </Table>

      <TablePagination
        component="div"
        count={totalInvites}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[]}
        page={page}
        labelDisplayedRows={({ from, to, count }: { from: number; to: number; count: number }) => {
          return `Showing ${from} to ${to} of ${count}`;
        }}
        backIconButtonProps={{
          'aria-label': 'previous page',
        }}
        nextIconButtonProps={{
          'aria-label': 'next page',
        }}
        onPageChange={handleChangePage}
        ActionsComponent={TablePaginationActions}
        classes={{
          toolbar: classes.pagination,
          spacer: classes.spacer,
        }}
      />
    </Paper>
  );
};

export default InvitesTable;
