import { all, put, takeEvery } from 'redux-saga/effects';
import LocalStorage from '../../../../data/LocalStorage';
import { LOGOUT_ENDPOINT } from '../../../../utils/constants/api';
import {
  ACCESS_TOKEN,
  AFFILIATE_PACKAGE,
  KYC_STATUS,
  REFRESH_TOKEN,
  USER_EMAIL,
  USER_ID,
} from '../../../../utils/constants/localStorageKeys';
import { setInfoDialogFields } from '../../HomeContent/InfoDialog/state/actions';
import { getRefreshToken, GET_REFRESH_TOKEN } from './actions';
import apiAccount from '../../../../data/repositories/account';

export function* refreshToken$() {
  const refresh_token = LocalStorage.get(REFRESH_TOKEN);

  try {
    const response = yield apiAccount.refreshToken(refresh_token ? refresh_token : '');
    LocalStorage.set(USER_ID, response.id);
    LocalStorage.set(USER_EMAIL, response.email);
    LocalStorage.set(KYC_STATUS, response.kyc_status);
    LocalStorage.set(ACCESS_TOKEN, response.access_token);
    LocalStorage.set(REFRESH_TOKEN, response.refresh_token);
    LocalStorage.set(AFFILIATE_PACKAGE, !!response.affiliate_package);

    if (response.kyc_status.toLowerCase() === 'expired') {
      yield put(
        setInfoDialogFields.request({
          dialogType: 'KycExpiredDocDialog',
          hideCloseButton: true,
        }),
      );
    } else if (response.affiliate_package && response.kyc_status.toLowerCase() !== 'completed') {
      yield put(
        setInfoDialogFields.request({
          dialogType: 'KycRedirectionDialog',
          hideCloseButton: true,
        }),
      );
    }
    yield put(getRefreshToken.success(response));
  } catch (error) {
    yield put(getRefreshToken.failure(error));
    LocalStorage.clear();
    return (window.location.href = `${process.env.REACT_APP_SL_GROUP_HOST}${LOGOUT_ENDPOINT}?from_url=${window.location.origin}`);
  }
}

export default function* () {
  yield all([takeEvery(GET_REFRESH_TOKEN, refreshToken$)]);
}
