/* eslint-disable @typescript-eslint/indent */
import { Box, CircularProgress } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { size } from 'lodash';
import React, { useEffect } from 'react';
import { PayloadAC } from 'typesafe-actions';
import Satelite from '../../../../../../assets/satelite.svg';
import { HeaderProps } from '../../../../../../components/AffiliatesMerchantsTable/';
import EarningsTableEur from '../../../../../../components/EarningsTableEur';
import SacretEmpty from '../../../../../../components/SacretEmpty';
import LocalStorage from '../../../../../../data/LocalStorage';
import { WalletTransfer } from '../../../../../../data/repositories/admin/typedefs';
import { USER_ID } from '../../../../../../utils/constants/localStorageKeys';
import { GET_WALLET_TRANSFERS } from '../../../Transactions/state/actions';
import { earningsTransactionTypeBV } from '../EurTransactionsFilterBar';

const styles = (theme: Theme) =>
  createStyles({
    myAffiliatesList: {
      color: theme.palette.secondary.light,
      fontSize: 18,
      fontWeight: 800,
      paddingBottom: 30,
    },
    root: {},
    circularProgress: {
      position: 'absolute',
      left: '50%',
      top: '50%',
    },
  });

const useStyles = makeStyles(styles);

type Props = any & {
  getWalletTransfersPaginated: PayloadAC<typeof GET_WALLET_TRANSFERS, any>;
  inProgress: boolean;
  appliedFilters?: any;
  walletTransfers: Map<string, WalletTransfer>;
};

const headers: HeaderProps[] = [
  { name: 'Status', align: 'left' },
  { name: 'Type', align: 'left' },
  { name: 'Affiliate email', align: 'left' },
  { name: 'Date & Time', align: 'left' },
  { name: 'Amount', align: 'right' },
  { name: 'Value(EUR)', align: 'right' },
];

const EurEarningsTable: React.FC<Props> = (props: Props): JSX.Element => {
  const classes = useStyles();
  const {
    walletTransfers,
    getWalletTransfersPaginated,
    inProgress,
    page,
    appliedFilters,
    totalRows,
    totalSum,
  } = props;
  const user_id = LocalStorage.get(USER_ID);

  const isEmpty = size(walletTransfers) == 0;

  useEffect(() => {
    //we pass coinSymbol here, else BE returns (bitgo_tx_sums: null) when no sybmol is selected
    getWalletTransfersPaginated({
      user_id,
      page: page,
      perPage: 10,
      appliedFilters: { coinSymbol: 'EUR', transferType: earningsTransactionTypeBV },
    });
  }, [page]);

  return (
    <Box>
      {inProgress && isEmpty ? (
        <CircularProgress color="secondary" className={classes.circularProgress} size={60} />
      ) : (
        <Box>
          {!isEmpty ? (
            <Box className={classes.root}>
              <EarningsTableEur
                walletTransfers={
                  walletTransfers ? walletTransfers : new Map<string, WalletTransfer>()
                }
                headers={headers}
                totalReferrals={totalRows ? totalRows : 0}
                totalSum={totalSum}
                page={page}
                inProgress={inProgress}
                filter={appliedFilters}
                getWalletTransfersPaginated={getWalletTransfersPaginated}
              />
            </Box>
          ) : (
            <SacretEmpty
              image={Satelite}
              title="Yikes, You don't have any earnings"
              text="Feel free to share your affiliate code with friends and earn reward for each purchase they make through the system. That's not all, you will also earn reward when they recommend SLAFF to their friends. Lifetime commission, for all their future payments, no strings attached."
              hasButton={false}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default EurEarningsTable;
