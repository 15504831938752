import { createSelector } from 'reselect';
import { AppState } from '../../../../../state/initialState';

const getChangePasswordState = ({
  home: {
    settings: { changePassword },
  },
}: AppState) => changePassword;

export const changePasswordStateSelector = createSelector(
  getChangePasswordState,
  changePassword => changePassword,
);
