import { connect } from 'react-redux';
import { AppState } from '../../../../state/initialState';
import ProductForm from './ProductForm';
import { setCurrentHomePage } from '../../../state/common/actions';
import {
  asyncProductCreate,
  asyncProductPublish,
  asyncProductUpdate,
  asyncGetProduct,
} from './state/actions';
import { getProductFormStateSelector } from './state/selectors';
import { userStateSelector } from '../../../../state/selectors';

const mapStateToProps = (state: AppState) => {
  const productForm = getProductFormStateSelector(state);
  const user = userStateSelector(state);

  return { productForm, user };
};
const mapDispatchToProps = {
  asyncProductCreate: asyncProductCreate.request,
  asyncProductUpdate: asyncProductUpdate.request,
  asyncProductPublish: asyncProductPublish.request,
  asyncGetProduct: asyncGetProduct.request,
  setCurrentHomePage: setCurrentHomePage,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductForm);
