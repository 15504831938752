import { connect } from 'react-redux';
import { exchangeAuthCodeStateSelector } from '../Auth/state/selectors';
import { getUserInfo } from '../state/actions';
import { AppState } from '../state/initialState';
import Home from './Home';
import { getRefreshTokenState } from './state/common/selectors';
import { getRefreshToken } from './state/actions';

const mapStateToProps = (state: AppState) => {
  const authState = exchangeAuthCodeStateSelector(state);
  const refreshTokenState = getRefreshTokenState(state);
  return { authState, refreshTokenState };
};

const mapDispatchToProps = {
  getUserInfo: getUserInfo.request,
  getRefreshToken: getRefreshToken.request,
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);
