import { Box, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import DailyChange from '../../../../Dashboard/CryptoChart/DailyChange';
import { Colors } from '../../../../../../../ui/style/colors';
import api from '../../../../../../../../src/data/repositories/wallet/index';

const useStyles = makeStyles({
  wrapper: {
    width: 230,
    padding: 10,
    textAlign: 'center',
    backgroundColor: `${Colors.whiteLilac2}`,
    margin: 10,
    cursor: 'default',
  },
  coinIcon: {
    width: 40,
    height: 40,
    margin: 10,
  },
  coinName: {
    fontSize: 20,
    fontWeight: 'bold',
    color: `${Colors.blueCharcoal}`,
  },
  coinPrice: {
    padding: 10,
    color: `${Colors.wildBlueYonder}`,
  },
});
type CoinCardProps = {
  coin: string;
  coinPrice: any;
};
const CoinCard = ({ coin, coinPrice }: CoinCardProps) => {
  const classes = useStyles();

  const getCoinIcon = () => {
    return api.allCoins.map((element, index) => {
      if (element.symbol === coin) {
        return <img className={classes.coinIcon} key={index} src={element.icon} />;
      }
    });
  };

  return (
    <Grid className={classes.wrapper} item>
      <Box>{getCoinIcon()}</Box>
      <Box className={classes.coinName}>{coin}</Box>
      <Box className={classes.coinPrice}>€ {coinPrice}</Box>
      <DailyChange symbol={coin} />
    </Grid>
  );
};

export default CoinCard;
