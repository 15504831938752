import { createSelector } from 'reselect';
import { AppState } from '../../../../../state/initialState';
import { isEmpty } from 'lodash';

const getAffiliateTableState = ({
  home: {
    product: { productTable },
  },
}: AppState) => productTable;

export const productTableStateSelector = createSelector(
  getAffiliateTableState,
  productTable => productTable,
);

export const getAppliedFiltersIds = createSelector(productTableStateSelector, productTable =>
  Object.keys(productTable.appliedFilters),
);

export const getBackerMax = createSelector(
  productTableStateSelector,
  productTable => productTable.backerMax,
);

export const getTimeLeftMax = createSelector(
  productTableStateSelector,
  productTable => productTable.timeLeftMax,
);

export const getAppliedFiltersIdsSelector = createSelector(
  getAppliedFiltersIds,
  appliedFiltersIds => appliedFiltersIds,
);

export const getAllAppliedFiltersSelector = createSelector(
  productTableStateSelector,
  productTable => productTable.appliedFilters,
);

export const getAllAppliedTabSelector = createSelector(
  productTableStateSelector,
  productTable => productTable.appliedTab,
);

export const getAppliedFiltersSelector = createSelector(
  getAllAppliedFiltersSelector,
  getAppliedFiltersIds,
  (appliedFilters, appliedFiltersIds) => {
    const af = new Map<string, string[]>(Object.entries(appliedFilters));
    const appliedFiltersNonEmpty = new Map<string, string[]>();
    for (const index in appliedFiltersIds) {
      const filter = af.get(appliedFiltersIds[index]);
      if (!isEmpty(filter) && filter) {
        appliedFiltersNonEmpty.set(appliedFiltersIds[index], filter);
      }
    }
    return appliedFiltersNonEmpty;
  },
);

export const getAreAnyFiltersApplied = createSelector(
  getAllAppliedFiltersSelector,
  getAppliedFiltersIds,
  (appliedFilters, appliedFiltersIds) => {
    let applied = false;
    const { ...filters }: any = appliedFilters;
    for (const index in appliedFiltersIds) {
      const af = new Map<string, string[]>(Object.entries(filters));
      applied = applied || !isEmpty(af.get(appliedFiltersIds[index]));
    }
    return applied;
  },
);
