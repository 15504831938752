import { Button, styled } from '@material-ui/core';
import { Colors } from '../../ui/style/colors';

const CustomButtonSecondary = styled(Button)(() => ({
  color: `${Colors.blueCharcoal}`,
  borderRadius: '18px',
  border: `1px solid ${Colors.spunPearl}`,
  backgroundColor: `${Colors.white}`,
  transition: 'none',
  '&:hover': {
    backgroundImage: `linear-gradient(to right,${Colors.havelockBlue}, ${Colors.mediumTurquoise});`,
    color: `${Colors.white}`,
    border: `1px solid ${Colors.havelockBlue}`,
    // transition: 'all .3s ease-in-out,background .3s',
  },
}));

export default CustomButtonSecondary;
