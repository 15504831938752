import { Formik } from 'formik';
import moment from 'moment';
import React from 'react';
import { PayloadAC } from 'typesafe-actions';
import * as yup from 'yup';
import { KycUpdateAplicantRequest } from '../../../../../data/repositories/infoDialog/typedefs';
import { ProfileInfoState } from '../../Settings/Membership/state/initialState';
import { InfoDialogState } from '../state/initialState';

type Props = {
  infoDialogState: InfoDialogState;
  asyncKycUpdateAplicant: PayloadAC<'infoDialog/update_aplicant', KycUpdateAplicantRequest>;
  profileInfo: ProfileInfoState;
};

const Kyc: React.FC<Props> = ({ infoDialogState, asyncKycUpdateAplicant, profileInfo }: Props) => {
  const {
    dialogProps: { fieldsToUpdate },
  } = infoDialogState;

  //Get current values from profile info
  const { phone_number, date_of_birth, citizenship } = profileInfo.kyc_info;
  const initValues = {
    phoneNumber: phone_number ? phone_number : '',
    countryCode: '',
    dateOfBirth: date_of_birth === '0001-01-01T00:00:00Z' ? '' : date_of_birth,
    citizenship: citizenship,
  };

  const shouldUpdate = (field: string) => fieldsToUpdate.find((item: string) => item === field);

  const dateOfBirthShouldUpdate = shouldUpdate('date_of_birth');
  const phoneNumberShouldUpdate = shouldUpdate('phone_number');
  const citizenshipShouldUpdate = shouldUpdate('citizenship');

  const submit = (data: any) => {
    const requestPayload: any = {
      dob: data.dateOfBirth,
      phone_number: data.phoneNumber,
      citizenship: data.citizenship,
    };
    asyncKycUpdateAplicant(requestPayload);
  };

  return (
    <Formik
      initialValues={initValues}
      onSubmit={(value, actions) => {
        actions.setSubmitting(false);
        submit(value);
      }}
      validationSchema={yup.object({
        dateOfBirth:
          dateOfBirthShouldUpdate &&
          yup
            .date()
            .required('Date of birth is required')
            .max(moment().subtract(15, 'years').toDate(), 'You must be at least 15 years old'),
        citizenship: citizenshipShouldUpdate && yup.string().required('Citizenship is required'),
        phoneNumber:
          phoneNumberShouldUpdate &&
          yup
            .string()
            .required('Phone number is required')
            .min(9, 'Phone number can have minimum 8 digits') //included + character
            .max(17, 'Phone number can have maximum 16 digits') //included + character
            .matches(
              /^\+{1}[0-9]+$/,
              'Phone number must contain only digits and start with selected country code',
            )
            .when('countryCode', (countryCode: string, schema: any) => {
              return schema.test({
                test: (phoneNumber: string) => phoneNumber && phoneNumber.includes(countryCode),
                message: 'Country code does not match selected country.',
              });
            }),
        countryCode: phoneNumberShouldUpdate && yup.string().required('Country code is required'),
      })}
    ></Formik>
  );
};

export default Kyc;
