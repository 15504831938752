import React from 'react';
import footerMob from '../../../assets/dashboard/footer-vector-mob.svg';
import footerVector from '../../../assets/dashboard/footer-vector.svg';
import footer from '../../../assets/dashboard/icons/footer.svg';
import linkedFooter from '../../../assets/dashboard/icons/linked-footer.svg';
import twiter from '../../../assets/dashboard/icons/twiter.svg';
import logo from '../../../assets/dashboard/logo.svg';
import CookieComponent from '../../../components/CookieConsent';
import { LOGIN_ENDPOINT } from '../../../utils/constants/api';
import {
  DASHBOARD_ROUTE,
  IAM_REGISTER_ROUTE,
  PRIVACY_POLICY_ROUTE_SL_GROUP,
  TERMS_OF_USE_ROUTE_SL_GROUP,
} from '../../../utils/constants/routes';

const DashboardFooter = () => {
  const APP_DASHBOARD = process.env.REACT_APP_HOST;
  const REACT_APP_SL_GROUP_HOST = process.env.REACT_APP_SL_GROUP_HOST;

  const getReferralCode = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get('referral_code') || urlParams.get('ref'); 
  };

  const referralCode = getReferralCode();
  
  return (
    <footer className="o-footer position-relative">
      <img className="o-footer__bg d-none d-md-block" src={footerVector} alt="" />
      <img className="o-footer__bg d-md-none" src={footerMob} alt="" />
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <div className="o-footer__logo">
              <a href={APP_DASHBOARD}>
                <img src={logo} alt="logo" />
              </a>
            </div>
          </div>

          <div className="col-12 col-sm-4 col-md-3 col-lg-2">
            <div className="o-footer__subtitle">Menu</div>
            <ul className="o-footer__list">
              <li className="o-footer__list-item">
                <span>Project:</span>{' '}
                <a
                  href={process.env.REACT_APP_SACRET_LIFE_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Sleo.io
                </a>
              </li>
              <li className="o-footer__list-item">
              <a
      href={`${process.env.REACT_APP_IAM_ROUTE}${process.env.LOGIN_ENDPOINT}?response_type=code&client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${encodeURIComponent(window.location.origin)}&scope=any${referralCode ? `&referral_code=${referralCode}` : ''}`}
    >         
                  Login
                </a>
              </li>
              <li className="o-footer__list-item">
                <a
                      href={`${IAM_REGISTER_ROUTE}/register?returnUrl=${encodeURIComponent(window.location.origin)}${referralCode ? `&referral_code=${referralCode}` : ''}`}
                      >
                  Register
                </a>
              </li>
            </ul>
          </div>
          <div className="col-12 col-sm-4 col-md-3 col-lg-2">
            <div className="o-footer__subtitle">Support</div>
            <ul className="o-footer__list">
              <li className="o-footer__list-item">
                <a
                  href={`${REACT_APP_SL_GROUP_HOST}${TERMS_OF_USE_ROUTE_SL_GROUP}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms &#38; Conditions
                </a>
              </li>
              <li className="o-footer__list-item">
                <a
                  href={`${REACT_APP_SL_GROUP_HOST}${PRIVACY_POLICY_ROUTE_SL_GROUP}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy policy
                </a>
              </li>
              <li className="o-footer__list-item">
                <a href={`${APP_DASHBOARD}${DASHBOARD_ROUTE}#faq`}>FAQ</a>
              </li>
            </ul>
          </div>
          <div className="col-12 col-sm-4 col-md-3 col-lg-2">
            <div className="o-footer__subtitle">About us</div>
            <ul className="o-footer__list">
              <li className="o-footer__list-item">
                <a href={`${APP_DASHBOARD}${DASHBOARD_ROUTE}#aboutus`}>About</a>
              </li>
              <li className="o-footer__list-item">
                <a href={`${APP_DASHBOARD}${DASHBOARD_ROUTE}#team`}>Our Team</a>
              </li>
              <li className="o-footer__list-item">
                <a href={`${APP_DASHBOARD}${DASHBOARD_ROUTE}#partnership`}>Partnerships</a>
              </li>
            </ul>
          </div>
          <div className="col-md-3 col-lg-2">
            <div className="o-footer__subtitle d-none d-md-block">Contact us</div>
            <ul className="o-footer__social d-flex align-items-center justify-content-center justify-content-md-start">
              <li className="o-footer__social-item">
                <a
                  href="https://www.linkedin.com/showcase/slaff-io/about/"
                  className="o-footer__social-link"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img src={linkedFooter} alt="Slaff LinkedIn" />
                </a>
              </li>
              <li className="o-footer__social-item">
                <a
                  href="https://twitter.com/Slaffio"
                  className="o-footer__social-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={twiter} alt="Slaff Twitter" />
                </a>
              </li>
              <li className="o-footer__social-item">
                <a
                  href="https://www.facebook.com/slaff.io/"
                  className="o-footer__social-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={footer} alt="" />
                </a>
              </li>
            </ul>
            <a
              className="o-footer__link u-text-grad text-center text-md-start"
              href="mailto:info@slaff.io"
            >
              info@slaff.io
            </a>
          </div>
        </div>
      </div>
      <CookieComponent />
    </footer>
  );
};
export default DashboardFooter;
