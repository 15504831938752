import { connect } from 'react-redux';
import { AppState } from '../../../../state/initialState';
import { getAppliedFiltersSelector, getWalletTransfersStateSelector } from '../state/selectors';
import { getWalletTransfersPaginated } from './state/actions';
import TransfersTable from './TransfersTable';
import { membershipStateSelector } from '../../Exchange/state/selectors';

const mapStateToProps = (state: AppState) => ({
  ...getWalletTransfersStateSelector(state),
  appliedFilters: getAppliedFiltersSelector(state),
  membershipLevel: membershipStateSelector(state),
});

const mapDispatchToProps = {
  getWalletTransfersPaginated: getWalletTransfersPaginated.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(TransfersTable);
