import { connect } from 'react-redux';
import { AppState } from '../../../state/initialState';
import { setCurrentHomePage } from '../../state/common/actions';
import { asyncGetIncomeBalances } from '../Affiliate/state/actions';
import { affiliateInfoStateSelector } from '../Affiliate/state/selectors';
import { getWalletBalance } from '../Wallet/state/actions';

import Earnings from './Earnings';
import { clearAllFilters } from './state/actions';

const mapStateToProps = (state: AppState) => {
  const affiliateInfoState = affiliateInfoStateSelector(state);

  return { affiliateInfoState };
};

const mapDispatchToProps = {
  setCurrentHomePage: setCurrentHomePage,
  clearAllFilters: clearAllFilters,
  asyncGetIncomeBalances: asyncGetIncomeBalances.request,
  getWalletBalance: getWalletBalance,
};

export default connect(mapStateToProps, mapDispatchToProps)(Earnings);
