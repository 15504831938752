import { connect } from 'react-redux';
import { AppState } from '../../../../state/initialState';
import { asyncGetTotalFriendsInvitationsPaginated } from '../MyInvitesTable/state/actions';
import { myInvitesTableStateSelector } from '../MyInvitesTable/state/selectors';
import InvitesInfo from './InvitesInfo';

const dispatchStateToProps = {
  asyncGetTotalFriendsInvitationsPaginated: asyncGetTotalFriendsInvitationsPaginated.request,
};

const mapStateToProps = (state: AppState) => ({
  ...myInvitesTableStateSelector(state),
});

export default connect(mapStateToProps, dispatchStateToProps)(InvitesInfo);
