import AOS from 'aos';
import $ from 'jquery';

$(document).ready(function () {
  AOS.init();
  document.addEventListener('DOMContentLoaded', function () {
    const typingText = document.querySelector('.JS-typing');
    let dataText = ['Reenvisioned', 'Blockchain', 'Trustworthy'];
    function typeWriter(text, i, fnCallback) {
      if (i < text.length) {
        typingText.innerHTML = text.substring(0, i + 1);
        setTimeout(function () {
          typeWriter(text, i + 1, fnCallback);
        }, 150);
      } else if (typeof fnCallback == 'function') {
        setTimeout(fnCallback, 1000);
      }
    }
    function StartTextAnimation(i) {
      if (typeof dataText[i] == 'undefined') {
        setTimeout(function () {
          StartTextAnimation(0);
        }, 2000);
      }
      if (i < dataText.length) {
        typeWriter(dataText[i], 0, function () {
          StartTextAnimation(i + 1);
        });
      }
    }
    StartTextAnimation(0);
  });
});
