import { createReducer } from 'typesafe-actions';
import {
  PRODUCT_CREATE,
  PRODUCT_CREATE_FAIL,
  PRODUCT_CREATE_SUCCESS,
  PRODUCT_PUBLISH,
  PRODUCT_PUBLISH_FAIL,
  PRODUCT_PUBLISH_SUCCESS,
  PRODUCT_UPDATE,
  PRODUCT_UPDATE_FAIL,
  PRODUCT_UPDATE_SUCCESS,
  GET_PRODUCT,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_FAIL,
  SET_PRODUCT_FORM,
} from './actions';
import { initialState, ProductFormState } from './initialState';
import { ProductDetail } from '../../../../../../data/repositories/product/typedef';

export default createReducer(initialState, {
  [SET_PRODUCT_FORM]: (state: ProductFormState, { payload }) => ({
    ...initialState,
    ...payload,
  }),
  [PRODUCT_CREATE]: state => ({
    ...state,
    inProgress: true,
  }),
  [PRODUCT_CREATE_SUCCESS]: (state: ProductFormState) => {
    return {
      ...state,
      ...initialState,
    };
  },
  [PRODUCT_CREATE_FAIL]: (state: ProductFormState, { payload }: any) => {
    return {
      ...state,
      fail: true,
      inProgress: false,
      error: payload,
    };
  },
  [PRODUCT_UPDATE]: state => ({
    ...state,
    inProgress: true,
  }),
  [PRODUCT_UPDATE_SUCCESS]: (state: ProductFormState) => {
    return {
      ...state,
      ...initialState,
    };
  },
  [PRODUCT_UPDATE_FAIL]: (state: ProductFormState, { payload }: any) => {
    return {
      ...state,
      fail: true,
      inProgress: false,
      error: payload,
    };
  },
  [PRODUCT_PUBLISH]: state => ({
    ...state,
    inProgress: true,
  }),
  [PRODUCT_PUBLISH_SUCCESS]: (
    state: ProductFormState,
    { payload }: { payload: { status: ProductDetail['status'] } },
  ) => {
    return {
      ...state,
      fail: false,
      inProgress: false,
      ...payload,
    };
  },
  [PRODUCT_PUBLISH_FAIL]: (state: ProductFormState, { payload }: any) => {
    return {
      ...state,
      fail: true,
      inProgress: false,
      error: payload,
    };
  },
  [GET_PRODUCT]: state => ({
    ...state,
    inProgress: true,
  }),
  [GET_PRODUCT_SUCCESS]: (state: ProductFormState, { payload }: any) => ({
    ...state,
    ...payload,
    inProgress: false,
  }),
  [GET_PRODUCT_FAIL]: (state: ProductFormState, { payload }: any) => {
    return {
      ...state,
      fail: true,
      inProgress: false,
      error: payload,
    };
  },
});
