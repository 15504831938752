import { createReducer } from 'typesafe-actions';
import {
  CHANGE_STATUS,
  CHANGE_STATUS_FAIL,
  CHANGE_STATUS_SUCCESS,
  CLEAR_ALL_FILTERS,
  GET_MERCHANTS_REFERRALS_PAGINATED,
  GET_MERCHANTS_REFERRALS_PAGINATED_FAILED,
  GET_MERCHANTS_REFERRALS_PAGINATED_SUCCESS,
  GET_MERCHANTS_REFERRALS_STATS,
  GET_MERCHANTS_REFERRALS_STATS_FAIL,
  GET_MERCHANTS_REFERRALS_STATS_SUCCESS,
  GET_MERCHANTS_UNCLAIMED_SLC_FAIL,
  GET_MERCHANTS_UNCLAIMED_SLC_SUCCESS,
  SET_MERCHANTS_TABLE_PAGE,
  TOGGLE_FILTER,
  TOGGLE_ORDER_TYPE,
  CLOSE_ERROR_MODAL,
} from './actions';
import { initialState } from './initialState';

export default createReducer(initialState, {
  [GET_MERCHANTS_REFERRALS_STATS]: state => ({
    ...state,
    inProgress: true,
  }),
  [GET_MERCHANTS_REFERRALS_STATS_SUCCESS]: (state, { payload }) => ({
    ...state,
    stats: payload,
    inProgress: false,
    fail: false,
  }),
  [GET_MERCHANTS_REFERRALS_STATS_FAIL]: (state, { payload }) => ({
    ...state,
    inProgress: false,
    fail: true,
    ...(payload && payload.response ? { error: payload.response.data } : {}),
  }),
  [CHANGE_STATUS]: state => ({
    ...state,
    inProgress: true,
  }),
  [CHANGE_STATUS_SUCCESS]: state => ({
    ...state,
    inProgress: false,
  }),
  [CHANGE_STATUS_FAIL]: (state, { payload }) => ({
    ...state,
    inProgress: false,
    fail: true,
    errorMessage: payload.response.data.error,
    isErrorModalOpen: true,
    ...(payload && payload.response ? { error: payload.response.data } : {}),
  }),
  [CLOSE_ERROR_MODAL]: state => ({
    ...state,
    errorMessage: '',
    isErrorModalOpen: false,
    fail: false,
    error: null,
  }),
  [GET_MERCHANTS_REFERRALS_PAGINATED]: state => ({
    ...state,
    inProgress: true,
    fail: false,
  }),
  [GET_MERCHANTS_REFERRALS_PAGINATED_SUCCESS]: (state, { payload }) => ({
    ...state,
    referrals: payload,
    inProgress: false,
    fail: false,
  }),
  [GET_MERCHANTS_REFERRALS_PAGINATED_FAILED]: (state, { payload }) => ({
    ...state,
    inProgress: false,
    fail: true,
    ...(payload && payload.response ? { error: payload.response.data } : {}),
  }),
  [SET_MERCHANTS_TABLE_PAGE]: (state, { payload }) => ({
    ...state,
    page: payload,
  }),
  [GET_MERCHANTS_UNCLAIMED_SLC_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      cycles: payload,
    };
  },
  [GET_MERCHANTS_UNCLAIMED_SLC_FAIL]: (state, { payload }) => ({
    ...state,
    ...(payload && payload.response ? { error: payload.response.data } : {}),
  }),
  [TOGGLE_FILTER]: (state, { payload }) => {
    return {
      ...state,
      appliedFilters: { ...state.appliedFilters, [payload.filterType]: payload.value },
    };
  },
  [CLEAR_ALL_FILTERS]: state => ({
    ...state,
    appliedFilters: new Map(),
  }),
  [TOGGLE_ORDER_TYPE]: (state, { payload }) => {
    return { ...state, page: 0, appliedFilters: new Map<string, string[]>(), order_type: payload };
  },
});
