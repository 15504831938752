import { CircularProgress, createStyles, Grid, makeStyles } from '@material-ui/core';
import { FormikProps } from 'formik';
import React from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as SuccessIconSvg } from '../../../../../../assets/success@1.5x.svg';
import { Colors } from '../../../../../../ui/style/colors';
import { getCurrencySymbolName } from '../../../../../../utils/helper/utils';
import { AppState } from '../../../../../state/initialState';
import { ProductCurrencyRequestState } from '../state/initialState';
import withInvestCurrencyStep from '../withInvestCurrencyStep';

const style = createStyles({
  text: {
    fontSize: 14,
    color: `${Colors.wildBlueYonder}`,
    fontFamily: 'Manrope3',
    fontWeight: 600,
    textAlign: 'left',
    margin: '10px 0',
    '& span': {
      color: `${Colors.blueCharcoal}`,
      fontWeight: 600,
    },
  },
});

const useClasses = makeStyles(style);

type Props = FormikProps<ProductCurrencyRequestState>;

const InvestCurrencySuccess: React.FC<Props> = ({}: Props) => {
  const classes = useClasses();
  const { selectedTab, fail, order_proof, inProgress, get, amount, selectedOption } = useSelector(
    (state: AppState) => state.home.product.productInvest,
  );

  return (
    <div style={{ marginBottom: 40, textAlign: 'center' }}>
      {inProgress ? (
        <Grid container justifyContent="center" style={{ marginTop: 150 }}>
          <CircularProgress color="secondary" size={50} />
        </Grid>
      ) : selectedOption === 'WIRE_EUR' ? (
        <>
          <SuccessIconSvg style={{ marginTop: 29, marginBottom: 24 }} />
          <Grid container justifyContent="center">
            <Grid item xs={selectedTab === 0 ? 8 : 10}>
              {fail ? (
                <p className={classes.text}>{fail}</p>
              ) : (
                <>
                  <p className={classes.text} style={{ textAlign: 'center', fontSize: '16px' }}>
                    Your order to invest has been registered.
                  </p>
                  <p className={classes.text}>
                    By paying {Number(amount)} EUR using the following instructions:
                  </p>
                  <p className={classes.text}>Beneficiary: SL ENTERPRISES EOOD</p>
                  <p className={classes.text}>IBAN: LT41 3250 0642 1959 1672</p>
                  <p className={classes.text}> BIC: REVOLT21</p>
                  <p className={classes.text}>
                    {' '}
                    Beneficiary address: Opalchenska 46-48, ET 7, Sofia, 1303, Bulgaria
                  </p>
                  <p className={classes.text}>
                    Bank/Payment institution: Revolut with reference to the number{' '}
                    <span>{order_proof.split('-')[0]}</span> you will be entitled to use the goods
                    or services purchased Payment is required to be registered within{' '}
                    <span>120 hours</span> of the purchase, otherwise the registration will be
                    canceled.
                  </p>
                  <p className={classes.text}>
                    <span>
                      Note: these instructions have also been sent to your e-mail address.
                    </span>
                  </p>
                </>
              )}
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid container justifyContent="center">
          <Grid item xs={selectedTab === 0 ? 8 : 10}>
            {fail ? (
              <p className={classes.text}>{fail}</p>
            ) : (
              <>
                <p className={classes.text}>
                  Your order to invest{' '}
                  <span>
                    {Number(get)} {getCurrencySymbolName(selectedOption)}
                  </span>{' '}
                  has been registered.{' '}
                </p>
                <p className={classes.text}>
                  <span>Note: these instructions have also been sent to your e-mail address.</span>
                </p>
              </>
            )}
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default withInvestCurrencyStep(InvestCurrencySuccess);
