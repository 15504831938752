import { CircularProgress, Paper, TablePagination } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import { useDispatch } from 'react-redux';
import { PayloadAC } from 'typesafe-actions';
import { GET_WALLET_TRANSFERS } from '../../App/state/actions';
import { WalletTransfer } from '../../data/repositories/admin/typedefs';
import { Colors } from '../../ui/style/colors';
import balanceUtils from '../../utils/balanceUtils';
import {
  WIRE_EUR_DECIMALS,
  WIRE_EUR_MAX_FRACTION,
  WIRE_EUR_MIN_FRACTION,
} from '../../utils/constants/amounts';
import TablePaginationActions from '../TablePaginationActions';
import EarningsTableRowEur from './EarningsTableRowEur';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      overflowY: 'auto',
      boxShadow: '0 10px 30px 0 rgba(132,141,182,0.25)',
      backgroundColor: `${Colors.white}`,
      borderRadius: '3px',
    },
    table: {
      minWidth: 650,
      borderCollapse: 'collapse',
      borderRadius: 6,
    },
    head: {
      backgroundColor: 'rgba(242, 243, 247, 0.7)',
    },
    headerText: {
      color: theme.palette.primary.light,
      fontSize: 12,
      fontWeight: 600,
      letterSpacing: 0.86,
    },
    headerButton: {
      textAlign: 'right',
      padding: '0 !important',
      width: '10%',
    },
    button: {
      borderRadius: ' 0 8px 0 8px',
      fontSize: 14,
      fontWeight: 600,
      textTransform: 'none',
      height: 47,
    },
    pagination: { flexWrap: 'wrap', justifyContent: 'space-between', borderRadius: 5 },
    spacer: { flex: '0 0 100%' },
  }),
);

export type HeaderProps = {
  name: string;
  align?: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined;
};

type Props = {
  walletTransfers: Map<string, WalletTransfer>;
  headers: HeaderProps[];
  totalReferrals: number;
  page: number;
  inProgress: boolean;
  filter: any;
  getWalletTransfersPaginated: PayloadAC<typeof GET_WALLET_TRANSFERS, any>;
  totalSum: {
    sum_amount: number;
  } | null;
};

const EarningsTableEur = ({
  walletTransfers,
  headers,
  page,
  totalReferrals,
  inProgress,
  filter,
  getWalletTransfersPaginated,
  totalSum,
}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleChangePage = (event: unknown, newPage: number) => {
    const object: any = {};
    filter.forEach((value: any, key: React.ReactText) => {
      object[key] = value;
    });
    dispatch({ type: 'transfers/set_table', payload: newPage });
    getWalletTransfersPaginated({
      page: newPage,
      perPage: 10,
      appliedFilters: {
        ...object,
      },
    });
  };

  const rowsPerPage = 10;
  const emptyRows = rowsPerPage - Object.keys(walletTransfers).length;

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead className={classes.head}>
          <TableRow>
            {headers.map(header => (
              <TableCell
                className={classes.headerText}
                key={header.name}
                align={header.align}
                component={'th'}
              >
                {header.name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {inProgress ? (
          <TableBody style={{ minHeight: 530 }}>
            <TableRow>
              <TableCell colSpan={6} style={{ textAlign: 'center' }}>
                <CircularProgress color="secondary" size={60} />
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <TableBody style={{ minHeight: 530 }}>
            {Object.entries(walletTransfers).map(([key, value]) => (
              <EarningsTableRowEur key={key} walletTransfer={value} />
            ))}
            {totalSum.sum_amount !== null && (
              <TableRow style={{ borderTop: '2px solid black' }}>
                <TableCell colSpan={5} />
                <TableCell colSpan={1} align="right">
                  Total Earned <br />
                  {balanceUtils.formatBalanceToString(
                    totalSum.sum_amount,
                    WIRE_EUR_DECIMALS,
                    WIRE_EUR_MIN_FRACTION,
                    WIRE_EUR_MAX_FRACTION,
                  )}{' '}
                  EUR
                </TableCell>
              </TableRow>
            )}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        )}
      </Table>

      <TablePagination
        component="div"
        count={totalReferrals}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[]}
        page={page}
        labelDisplayedRows={({ from, to, count }: { from: number; to: number; count: number }) => {
          return `Showing ${from} to ${to} of ${count}`;
        }}
        backIconButtonProps={{
          'aria-label': 'previous page',
        }}
        nextIconButtonProps={{
          'aria-label': 'next page',
        }}
        onPageChange={handleChangePage}
        ActionsComponent={TablePaginationActions}
        classes={{
          toolbar: classes.pagination,
          spacer: classes.spacer,
        }}
      />
    </Paper>
  );
};

export default EarningsTableEur;
