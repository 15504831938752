import {
  ExchangeAuthCodeState,
  initialState as exchangeAuthCode,
} from '../ExchangeAuthCode/state/initialState';

export type AuthState = {
  exchangeAuthCode: ExchangeAuthCodeState;
};

export const initialState = {
  exchangeAuthCode,
};
