import { Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { PayloadAC } from 'typesafe-actions';
import CustomButtonPrimary from '../../../../../components/CustomButtons/CustomButtonPrimary';
import ProductDetails from '../../../../../components/ProductsTable/ProductDetails';
import { IS_INFO_DIALOG_OPEN } from '../../InfoDialog/state/actions';
import { InfoDialogState } from '../../InfoDialog/state/initialState';
import { SET_INVEST_INITIAL, SET_INVEST_OPEN } from '../state/actions';

const styles = () =>
  createStyles({
    wrapper: {
      maxHeight: 400,
      overflowY: 'auto',
      padding: 20,
    },
    button: {
      marginTop: 30,
    },
  });

const useStyles = makeStyles(styles);

type Props = {
  infoDialogState: InfoDialogState;
  setInfoDialogOpen: PayloadAC<typeof IS_INFO_DIALOG_OPEN, boolean>;
  setInvestDialogOpen: PayloadAC<typeof SET_INVEST_OPEN, boolean>;
  setInitialInvestValues: PayloadAC<typeof SET_INVEST_INITIAL, any>;
};

const ProductDetailsDialog: React.FC<Props> = (props: Props): JSX.Element => {
  const classes = useStyles();
  const {
    setInfoDialogOpen,
    setInitialInvestValues,
    setInvestDialogOpen,
    infoDialogState: {
      dialogProps: { selectedProduct },
    },
  } = props;

  if (!selectedProduct) {
    return <>No product selected</>;
  }
  const { name, status, id, buy_limit_low, buy_limit_high, pre_ico_price, ico_price } =
    selectedProduct.product;

  const handleBuyButtonClick = () => {
    setInfoDialogOpen(false);
    setInvestDialogOpen(true);
    setInitialInvestValues({
      product_id: id,
      buy_limit_low,
      buy_limit_high,
      eur_price: selectedProduct.product_phase === 'pre-ico' ? pre_ico_price : ico_price,
    });
  };

  return (
    <div className={classes.wrapper}>
      <Typography>{name}</Typography>
      <ProductDetails product={selectedProduct} />
      <Grid container justifyContent="center" className={classes.button}>
        {status === 'published' &&
          (selectedProduct.product_phase === 'pre-ico' ||
            selectedProduct.product_phase === 'ico') && (
            <CustomButtonPrimary size={'small'} onClick={handleBuyButtonClick} variant="outlined">
              Buy
            </CustomButtonPrimary>
          )}
      </Grid>
    </div>
  );
};

export default ProductDetailsDialog;
