import { createSelector } from 'reselect';
import { AppState } from '../../../../../state/initialState';
import { isEmpty } from 'lodash';

const getAffiliateTableState = ({
  home: {
    affiliate: { affiliateTable },
  },
}: AppState) => affiliateTable;

export const affiliateTableStateSelector = createSelector(
  getAffiliateTableState,
  affiliateTable => affiliateTable,
);

export const getAffiliateTableReferralsSelector = createSelector(
  affiliateTableStateSelector,
  affiliateTable => affiliateTable.referrals,
);

export const getAffiliateTableReferralTreeSelector = createSelector(
  affiliateTableStateSelector,
  affiliateTable => affiliateTable.referralTree,
);

export const getAffiliateTableReferralsStatsSelector = createSelector(
  affiliateTableStateSelector,
  affiliateTable => affiliateTable.stats,
);

export const getCyclesSelector = createSelector(
  affiliateTableStateSelector,
  affiliateTable => affiliateTable.cycles,
);
//
// const getAppliedFiltersIds = ({
//   home: {
//     earnings: { appliedFilters },
//   },
// }: AppState) => Object.keys(appliedFilters);

export const getAppliedFiltersIds = createSelector(affiliateTableStateSelector, affiliateTable =>
  Object.keys(affiliateTable.appliedFilters),
);

export const getAppliedFiltersIdsSelector = createSelector(
  getAppliedFiltersIds,
  appliedFiltersIds => appliedFiltersIds,
);

const getAllAppliedFiltersSelector = createSelector(
  affiliateTableStateSelector,
  affiliateTable => affiliateTable.appliedFilters,
);

export const getAppliedFiltersSelector = createSelector(
  getAllAppliedFiltersSelector,
  getAppliedFiltersIds,
  (appliedFilters, appliedFiltersIds) => {
    const af = new Map<string, string[]>(Object.entries(appliedFilters));
    const appliedFiltersNonEmpty = new Map<string, string[]>();
    for (const index in appliedFiltersIds) {
      const filter = af.get(appliedFiltersIds[index]);
      if (!isEmpty(filter) && filter) {
        appliedFiltersNonEmpty.set(appliedFiltersIds[index], filter);
      }
    }
    return appliedFiltersNonEmpty;
  },
);

export const getAreAnyFiltersApplied = createSelector(
  getAllAppliedFiltersSelector,
  getAppliedFiltersIds,
  (appliedFilters, appliedFiltersIds) => {
    let applied = false;
    for (const index in appliedFiltersIds) {
      const af = new Map<string, string[]>(Object.entries(appliedFilters));
      applied = applied || !isEmpty(af.get(appliedFiltersIds[index]));
    }
    return applied;
  },
);

const getDialogState = ({
  home: {
    affiliate: {
      affiliateTable: { dialogState },
    },
  },
}: AppState) => dialogState;

export const getDialogStateSelector = createSelector(getDialogState, dialogState => dialogState);
