import { Paper } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { SelectedCurrency } from '../../../../../../data/repositories/dashboard/typedefs';
import { Colors } from '../../../../../../ui/style/colors';

const styles = () =>
  createStyles({
    paper: {
      borderRadius: '3px',
      backgroundColor: `${Colors.white}`,
      boxShadow: '0 10px 30px 0 rgba(132,141,182,0.25)',
      padding: 15,
      overflow: 'hidden',
      textAlign: 'left',
    },
    price: {
      fontWeight: 900,
      marginTop: 5,
    },
    label: {
      color: `${Colors.blueHaze}`,
      fontWeight: 300,
      textTransform: 'uppercase',
      fontSize: 14,
      margin: 0,
    },
    date: {
      fontSize: 14,
    },
  });

const useStyles = makeStyles(styles);

type TooltipProps = {
  active?: boolean;
  payload?: any;
  selectedCurrency: SelectedCurrency;
};

const CryptoChartTooltip = ({ active, payload, selectedCurrency }: TooltipProps): JSX.Element => {
  const classes = useStyles();
  const renderContent = () =>
    active &&
    payload !== null && (
      <Paper className={classes.paper}>
        <p className={classes.label}>Price</p>
        <p className={classes.price}>
          {selectedCurrency.symbol}
          {payload[0].payload['yAxis']}
        </p>
        <p className={classes.date}>{payload[0].payload['date']}</p>
      </Paper>
    );
  return <>{renderContent()}</>;
};

export default CryptoChartTooltip;
