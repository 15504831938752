import moment from 'moment';
import { all, put, select, takeLatest } from 'redux-saga/effects';
import LocalStorage from '../../../../../../data/LocalStorage';
import api from '../../../../../../data/repositories/transaction';
import { GET_WALLET_TRANSFERS_PAGINATED } from '../../../../../../utils/constants/api';
import { USER_ID } from '../../../../../../utils/constants/localStorageKeys';
import { getCurrencySymbolName } from '../../../../../../utils/helper/utils';
import { getCoinDecimalsSelector } from '../../../Wallet/state/selectors';
import { getWalletTransfersPaginated, GET_WALLET_TRANSFERS } from './actions';

const mapURL = params => {
  let newParams = {};
  Object.keys(params).forEach(key => {
    if (params[key] !== null)
      newParams = {
        ...newParams,
        [key]:
          key !== 'symbol' ? params[key].toString() : getCurrencySymbolName(params[key].toString()),
      };
  });
  return new URLSearchParams(newParams).toString();
};
export function* getWalletTransfers$(action: any) {
  try {
    const userId = LocalStorage.get(USER_ID);
    const { page, perPage, appliedFilters } = action.payload;
    let multiplier = 1;
    if (!!appliedFilters && appliedFilters.coinSymbol !== null) {
      const symbol = appliedFilters.coinSymbol;
      const decimals = yield select(
        getCoinDecimalsSelector(Array.isArray(symbol) ? symbol[0] : symbol),
      );

      multiplier = Math.pow(10, decimals);
    }

    const filter = {
      origin: appliedFilters && appliedFilters.transferType ? appliedFilters.transferType : null,
      symbol: appliedFilters && appliedFilters.coinSymbol ? appliedFilters.coinSymbol : null,
      amountFrom:
        appliedFilters && appliedFilters.valueRange
          ? appliedFilters.valueRange[0] !== ''
            ? +appliedFilters.valueRange[0] * multiplier
            : -10000000000
          : null,
      amountTo:
        appliedFilters && appliedFilters.valueRange
          ? appliedFilters.valueRange[1] !== ''
            ? +appliedFilters.valueRange[1] * multiplier
            : 10000000000
          : null,
      dateFrom:
        appliedFilters && appliedFilters.dateFilter && appliedFilters.dateFilter.length
          ? moment(appliedFilters.dateFilter[0], 'DD/MM/YYYY').format('YYYY-MM-DD HH:mm:ss')
          : null,
      dateTo:
        appliedFilters && appliedFilters.dateFilter && appliedFilters.dateFilter.length
          ? moment(appliedFilters.dateFilter[1], 'DD/MM/YYYY').endOf('day').toDate() >
            moment().toDate()
            ? moment().format('YYYY-MM-DD HH:mm:ss')
            : moment(appliedFilters.dateFilter[1], 'DD/MM/YYYY')
                .endOf('day')
                .format('YYYY-MM-DD HH:mm:ss')
          : null,
      affiliate_email:
        action.payload.appliedFilters && action.payload.appliedFilters.searchInput
          ? action.payload.appliedFilters.searchInput
          : null,
      tx_status: appliedFilters && appliedFilters.status ? appliedFilters.status : null,
    };

    const customWalletTransferFilter =
      '&origin=affiliate_percentage_reward%2Cbuy%2Cbuy_product%2Cdeposit%2Cterm_saving%2Creceive%2Cmerchant_income%2Csell%2Ctransfer%2Csend%2Cwithdraw%2Cstore_item_buy%2Cstore_item_sell';

    const removeOriginIfTypeUnchecked = () => {
      const splitArray = mapURL(filter).split('origin=');

      if (splitArray[1] === '') {
        return customWalletTransferFilter.concat('&', mapURL(filter));
      } else if (splitArray[1].charAt(0) === '&') {
        splitArray.splice(0, 1, customWalletTransferFilter);
        return splitArray.join('');
      } else {
        return mapURL(filter);
      }
    };

    const handleCustomFilering = () => {
      if (mapURL(filter) && mapURL(filter).includes('origin')) {
        return mapURL(filter) && '&' + removeOriginIfTypeUnchecked();
      } else {
        return customWalletTransferFilter.concat('&', mapURL(filter));
      }
    };
    const walletTransfers = yield api.getWalletTransfersPaginated(
      GET_WALLET_TRANSFERS_PAGINATED(userId || '', page, perPage, handleCustomFilering()),
    );
    yield put(getWalletTransfersPaginated.success(walletTransfers));
  } catch (error) {
    yield put(getWalletTransfersPaginated.failure(error));
  }
}

export default function* () {
  yield all([takeLatest(GET_WALLET_TRANSFERS, getWalletTransfers$)]);
}
