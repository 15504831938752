import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { GetCoinPricesResponse } from '../../../../../../data/repositories/wallet/typedefs';
import { ALL_COINS } from '../../../../../../utils/constants/amounts';
import { AppState } from '../../../../../state/initialState';
import { getCoinPrices } from '../../../../state/common/selectors';
import CoinCard from './CoinCard/CoinCard';

const useStyles = makeStyles({
  wrapper: {
    marginTop: 50,
  },
});

type CoinSliderProps = {
  coinPrices?: GetCoinPricesResponse;
};

const CoinSlider = ({ coinPrices }: CoinSliderProps) => {
  const classes = useStyles();
  const walletCoins = ALL_COINS.filter(coin => coin !== 'WALLET_EUR');

  return (
    <Grid container className={classes.wrapper} justifyContent="center">
      {walletCoins.map(coin => {
        return (
          <CoinCard
            key={coin}
            coin={coin}
            coinPrice={coinPrices && coinPrices[coin] ? coinPrices[coin]['EUR'] : ''}
          />
        );
      })}
    </Grid>
  );
};

const mapStateToProps = (state: AppState) => {
  const coinPrices = getCoinPrices(state);
  return { coinPrices };
};
export default connect(mapStateToProps)(CoinSlider);
