import { combineReducers } from 'redux';
import affiliateInfo from '../AffiliateInfo/state/reducer';
import affiliateTable from '../AffiliateTable/state/reducer';
import affiliateMerchantsTable from '../AffiliateMerchantsTable/state/reducer';
import affiliatePremiumTable from '../AffiliatePremiumTable/state/reducer';

export default combineReducers({
  affiliateInfo,
  affiliateTable,
  affiliateMerchantsTable,
  affiliatePremiumTable,
});
