export type ChangePasswordState = {
  inProgress: boolean;
  fail: boolean;
  success: boolean;
  error: Error | null;
};

export const initialState: ChangePasswordState = {
  inProgress: false,
  fail: false,
  success: false,
  error: null,
};
