import { Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { EmptyAC, PayloadAC } from 'typesafe-actions';
import SacretDaterangePickerSelect from '../../../../../components/SacrateDaterangePickerSelect';
import SacretSearchInput from '../../../../../components/SacretSearchInput';
import SacretSelect from '../../../../../components/SacretSelect';
import { GetReferralsPaginatedRequest } from '../../../../../data/repositories/affiliate/typedefs';
import { SaveTransactionFilter } from '../../../../../data/repositories/transaction/typedef';
import AppliedFiltersChips from './AppliedFiltersChips';

const styles = (theme: Theme) =>
  createStyles({
    root: { marginTop: 10 },
    filter: {
      minWidth: 200,
      marginRight: 10,
    },
    clearAll: {
      textTransform: 'none',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    clearAllIcon: {
      fontSize: 12,
      padding: 5,
    },
    label: {
      float: 'left',
      padding: '2px 0',
      fontSize: 12,
      color: theme.palette.primary.light,
    },
    minWidth: {
      minWidth: 236,
    },
  });

const useStyles = makeStyles(styles);

type Props = {
  toggleFilter: PayloadAC<'earnings/toggle_filter', SaveTransactionFilter>;
  appliedFilters: Map<string, string[]>;
  clearAllFilters: EmptyAC<'earnings/clear_all_filters'>;
  asyncGetMerchantsReferralsPaginated: PayloadAC<
    'affiliate/get_merchants_referrals_paginated',
    GetReferralsPaginatedRequest
  >;
};

const names = [
  { name: 'Affiliate became Partner', value: 'merchant_parent_reward' },
  { name: 'Became Partner', value: 'new_merchant_reward' },
  { name: 'Affiliate package purchase', value: 'premium_user_reward' },
  { name: 'BV', value: 'affiliate_percentage_reward' },
  { name: 'New Affiliate', value: 'new_user_affiliate_reward' },
  { name: 'Affiliate purchased Affiliate package', value: 'premium_user_affiliate_reward' },
  { name: 'Registration reward', value: 'new_user_reward' },
];

const statuses = [
  { name: 'Completed', value: 'completed' },
  { name: 'Pending', value: 'pending' },
  { name: 'Pending Escrow', value: 'pending_escrow' },
  { name: 'Timed Escrow', value: 'timed_escrow' },
  { name: 'Escrow', value: 'escrow' },
];

const TransactionsFilterBar: React.FC<Props> = ({
  toggleFilter,
  appliedFilters,
  clearAllFilters,
  asyncGetMerchantsReferralsPaginated,
}: Props): JSX.Element => {
  const [query, setQuery]: any = useState('');
  const classes = useStyles();

  const appliedEmailFilter = (value: string) => {
    const object: any = {};
    appliedFilters.forEach((value, key) => {
      object[key] = value;
    });
    asyncGetMerchantsReferralsPaginated({
      page: 0,
      perPage: 10,
      appliedFilters: { ...object, searchInput: value },
    });

    toggleFilter({ filterType: 'searchInput', value });
    setQuery('');
  };
  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid xs={12} sm="auto" item className={classes.filter}>
        <SacretSelect
          items={statuses}
          label="Status"
          onChange={(value: string[]) => {
            const object: any = {};
            appliedFilters.forEach((value, key) => {
              object[key] = value;
            });
            asyncGetMerchantsReferralsPaginated({
              page: 0,
              perPage: 10,
              appliedFilters: { ...object, status: value },
            });
            toggleFilter({ filterType: 'status', value });
          }}
          stateFilterValue={appliedFilters.get('status')}
        />
      </Grid>
      <Grid xs={12} sm="auto" item className={`${classes.filter} ${classes.minWidth}`}>
        <SacretSelect
          items={names}
          label="Transaction type"
          onChange={(value: string[]) => {
            const object: any = {};
            appliedFilters.forEach((value, key) => {
              object[key] = value;
            });
            asyncGetMerchantsReferralsPaginated({
              page: 0,
              perPage: 10,
              appliedFilters: { ...object, transactionType: value },
            });

            toggleFilter({ filterType: 'transactionType', value });
          }}
          stateFilterValue={appliedFilters.get('transactionType')}
          size="large"
        />
      </Grid>
      <Grid xs={12} sm="auto" item className={`${classes.filter} ${classes.minWidth}`}>
        <SacretDaterangePickerSelect
          label="Choose a date"
          onChange={(value: string[]) => {
            const object: any = {};
            appliedFilters.forEach((value, key) => {
              object[key] = value;
            });
            asyncGetMerchantsReferralsPaginated({
              page: 0,
              perPage: 10,
              appliedFilters: { ...object, dateFilter: value },
            });
            toggleFilter({ filterType: 'dateFilter', value });
          }}
          stateFilterValue={appliedFilters.get('dateFilter')}
        />
      </Grid>
      <Grid xs={12} sm="auto" item className={`${classes.filter} ${classes.minWidth}`}>
        <SacretSearchInput
          onChange={(value: string[]) => setQuery(value)}
          stateFilterValue={[query]}
          searchHandler={() => appliedEmailFilter(query)}
        />
      </Grid>
      <AppliedFiltersChips
        clearAllFilters={clearAllFilters}
        stateAppliedFilters={appliedFilters}
        toggleFilter={toggleFilter}
      />
    </Grid>
  );
};

export default TransactionsFilterBar;
