import { ThemeProvider } from '@material-ui/styles';
import { ConnectedRouter } from 'connected-react-router';
import { History } from 'history';
import React, { ReactElement } from 'react';
import { Route, Switch } from 'react-router';
import { ParallaxProvider } from 'react-scroll-parallax';
import {
  AUTOLOGIN,
  PRIVACY_POLICY_ROUTE,
  ROOT_ROUTE,
  TERMS_OF_USE_ROUTE,
} from '../utils/constants/routes';
import Autologin from './Auth/Autologin/Autologin';
import ExchangeAuthCode from './Auth/ExchangeAuthCode';
import PrivacyPolicy from './Dashboard/PrivacyPolicy';
import TermsOfUse from './Dashboard/TermsOfUse';
import Home from './Home';
import theme from './theme';
type Props = {
  history: History;
};

const App: React.FC<Props> = ({ history }: Props): ReactElement => {
  return (
    <ConnectedRouter history={history}>
      <ThemeProvider theme={theme}>
        <ParallaxProvider>
          <Switch>
            <Route exact path={ROOT_ROUTE} component={ExchangeAuthCode} />
            <Route path={TERMS_OF_USE_ROUTE} component={TermsOfUse} />
            <Route path={PRIVACY_POLICY_ROUTE} component={PrivacyPolicy} />
            <Route path={AUTOLOGIN} component={Autologin} />
            <Route path={ROOT_ROUTE} component={Home} />
          </Switch>
        </ParallaxProvider>
      </ThemeProvider>
    </ConnectedRouter>
  );
};

export default App;
