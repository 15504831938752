import { Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { EmptyAC, PayloadAC } from 'typesafe-actions';
import SacretDaterangePickerSelect from '../../../../../components/SacrateDaterangePickerSelect';
import SacretSearchInput from '../../../../../components/SacretSearchInput';
import SacretSelect from '../../../../../components/SacretSelect';
import { GetReferralsPaginatedRequest } from '../../../../../data/repositories/affiliate/typedefs';
import { SaveTransactionFilter } from '../../../../../data/repositories/transaction/typedef';
import { roles } from '../../../../../utils/constants/roles';
import AppliedFiltersChips from './AppliedFiltersChips';

const styles = (theme: Theme) =>
  createStyles({
    filter: {
      minWidth: 200,
      marginRight: 10,
    },
    valueSlider: {},
    clearAll: {
      textTransform: 'none',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    clearAllIcon: {
      fontSize: 12,
      padding: 5,
    },
    label: {
      float: 'left',
      padding: '2px 0',
      fontSize: 12,
      color: theme.palette.primary.light,
    },
    root: {
      marginTop: 10,
    },
    minWidth: {
      minWidth: 236,
    },
  });

const useStyles = makeStyles(styles);

type Props = {
  toggleFilter: PayloadAC<'affiliate/toggle_filter', SaveTransactionFilter>;
  appliedFilters: Map<string, string[]>;
  clearAllFilters: EmptyAC<'affiliate/clear_all_filters'>;
  asyncGetReferralsPaginated: PayloadAC<
    'affiliate/get_referrals_paginated',
    GetReferralsPaginatedRequest
  >;
  user: any;
  isAdminSearchWidget?: boolean;
};

const statuses = [
  { name: 'Confirmed', value: 'confirmed' },
  { name: 'Blacklisted', value: 'blacklisted' },
  { name: 'Not confirmed', value: 'whitelisted' },
];

const bitGoStatus = [
  { name: 'Has BitGo wallet', value: 'yes' },
  { name: 'No BitGo wallet', value: 'no' },
];

const kycStatus = [
  { name: 'Applicant created', value: 'applicant_created' },
  { name: 'Completed', value: 'completed' },
  { name: 'Document uploaded', value: 'document_uploaded' },
  { name: 'Not started', value: 'not_started' },
  { name: 'Pending', value: 'pending' },
  { name: 'Rejected', value: 'rejected' },
  { name: 'Suspended', value: 'suspended' },
];

const TransactionsFilterBar: React.FC<Props> = ({
  toggleFilter,
  appliedFilters,
  clearAllFilters,
  asyncGetReferralsPaginated,
  user,
  isAdminSearchWidget,
}: Props): JSX.Element => {
  const classes = useStyles();
  const [query, setQuery]: any = useState('');

  const appliedEmailFilter = (inputValue: string) => {
    const object: any = {};
    appliedFilters.forEach((value, key) => {
      object[key] = value;
    });

    asyncGetReferralsPaginated({
      page: 0,
      perPage: 10,
      appliedFilters: { ...object, searchInput: inputValue },
    });
    toggleFilter({ filterType: 'searchInput', value: inputValue });
    setQuery('');
  };

  return isAdminSearchWidget ? (
    <Grid container className={classes.root} spacing={2}>
      <Grid xs={12} sm="auto" item className={`${classes.filter} ${classes.minWidth}`}>
        <SacretSearchInput
          onChange={(value: string[]) => setQuery(value)}
          stateFilterValue={[query]}
          searchHandler={() => appliedEmailFilter(query)}
        />
      </Grid>
      <AppliedFiltersChips
        clearAllFilters={clearAllFilters}
        stateAppliedFilters={appliedFilters}
        toggleFilter={toggleFilter}
      />
    </Grid>
  ) : (
    <Grid container className={classes.root} spacing={2}>
      <Grid xs={12} sm="auto" item className={`${classes.filter} ${classes.minWidth}`}>
        <SacretSearchInput
          onChange={(value: string[]) => setQuery(value)}
          stateFilterValue={[query]}
          searchHandler={() => appliedEmailFilter(query)}
        />
      </Grid>

      <Grid xs={12} sm="auto" item className={classes.filter}>
        <SacretSelect
          items={statuses}
          multiple={false}
          label="Status"
          onChange={(value: string[]) => {
            const object: any = {};
            appliedFilters.forEach((value, key) => {
              object[key] = value;
            });
            asyncGetReferralsPaginated({
              page: 0,
              perPage: 10,
              appliedFilters: { ...object, status: value },
            });
            toggleFilter({ filterType: 'status', value });
          }}
          stateFilterValue={appliedFilters.get('status')}
        />
      </Grid>

      {roles.isOwner(user.roles) && (
        <>
          <Grid xs={12} sm="auto" item className={classes.filter}>
            <SacretSelect
              items={bitGoStatus}
              multiple={false}
              label="BitGo status"
              onChange={(value: string[]) => {
                const object: any = {};
                appliedFilters.forEach((value, key) => {
                  object[key] = value;
                });
                asyncGetReferralsPaginated({
                  page: 0,
                  perPage: 10,
                  appliedFilters: { ...object, has_bitgo: value },
                });
                toggleFilter({ filterType: 'has_bitgo', value });
              }}
              stateFilterValue={appliedFilters.get('has_bitgo')}
            />
          </Grid>

          <Grid xs={12} sm="auto" item className={classes.filter}>
            <SacretSelect
              items={kycStatus}
              multiple={false}
              label="KYC status"
              onChange={(value: string[]) => {
                const object: any = {};
                appliedFilters.forEach((value, key) => {
                  object[key] = value;
                });
                asyncGetReferralsPaginated({
                  page: 0,
                  perPage: 10,
                  appliedFilters: { ...object, kyc_status: value },
                });
                toggleFilter({ filterType: 'kyc_status', value });
              }}
              stateFilterValue={appliedFilters.get('kyc_status')}
            />
          </Grid>
        </>
      )}

      <Grid xs={12} sm="auto" item className={`${classes.filter} ${classes.minWidth}`}>
        <SacretDaterangePickerSelect
          label="Choose a date"
          onChange={(value: string[]) => {
            const object: any = {};
            appliedFilters.forEach((value, key) => {
              object[key] = value;
            });
            asyncGetReferralsPaginated({
              page: 0,
              perPage: 10,
              appliedFilters: { ...object, dateFilter: value },
            });
            toggleFilter({ filterType: 'dateFilter', value });
          }}
          stateFilterValue={appliedFilters.get('dateFilter')}
        />
      </Grid>
      <AppliedFiltersChips
        clearAllFilters={clearAllFilters}
        stateAppliedFilters={appliedFilters}
        toggleFilter={toggleFilter}
      />
    </Grid>
  );
};

export default TransactionsFilterBar;
