import { CircularProgress, Grid, TextField, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Field, Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { PayloadAC } from 'typesafe-actions';
import CustomButtonPrimary from '../../../../../../components/CustomButtons/CustomButtonPrimary';
import { WalletMetadata } from '../../../../../../data/repositories/wallet/typedefs';
import { Colors } from '../../../../../../ui/style/colors';
import { WIRE_EUR_TRANSACTION_FEE } from '../../../../../../utils/constants/amounts';
import {
  allowDecimalNumbersValidator,
  composeValidators,
  greaterOrEqual,
  lessOrEqual,
  requiredValidator,
} from '../../../../../../utils/validators';
import {
  CLEAR_DEPOSIT_MODAL_STATE,
  SET_DEPOSIT_MODAL_DATA,
  WIRE_DEPOSIT_EUR,
} from '../../state/actions';
import { CreateDepositOrderRequest, DepositModalState } from '../../state/initialState';

const styles = (theme: Theme) =>
  createStyles({
    currencyLabel: {
      color: theme.palette.primary.light,
      fontSize: 12,
      fontWeight: 600,
      marginBottom: 10,
    },
    button: {
      fontSize: 14,
      margin: '10px 0',
      textTransform: 'none',
    },
    inputField: {
      borderRadius: 6,
      height: 50,
    },
    formField: {
      '&.rc-card-field--invalid, &:invalid': {
        borderVolor: 'red',
      },

      '&:required:valid,&.rc-card-field--completed': {
        borderVolor: 'lime',
      },
      '&:-webkit-autofill, &.rc-card-field--autofilled': {
        borderColor: 'yellow',
        '-webkit-text-fill-color': 'white',
        '-webkit-box-shadow': ' 0 0 0px 1000px #222 inset',
        transition: 'background-color 5000s ease-in-out 0s',
      },
    },
    inputWrapper: {
      marginBottom: 12,
    },

    value: {
      color: `${Colors.blueCharcoal}`,
      textAlign: 'center',
      fontSize: 14,
    },
    input: {
      '&::placeholder': {
        color: theme.palette.secondary.light,
      },
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'none',
      },
      '&[type=number]': {
        '-webkit-appearance': 'textfield',
        '-moz-appearance': 'textfield',
      },
    },
    inputRoot: {
      height: 50,
    },
    inputSymbol: {
      width: 51,
      height: 50,
      borderRadius: '0 4px 4px 0',
      backgroundColor: `${theme.palette.secondary.light}`,
      color: `${Colors.white}`,
      fontSize: 12,
      fontWeight: 600,
    },
    noMargin: {
      margin: 0,
    },
    inputSymbolValue: {
      position: 'relative',
      float: 'left',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    currencyBalance: {
      fontSize: 12,
      fontWeight: 600,
      marginLeft: 3,
    },
    inputError: {
      color: `${Colors.red}`,
      margin: '10px 0',
      height: 15,
      fontFamily: 'Manrope3',
    },
    inProgress: {
      minHeight: 300,
    },
  });
const useStyles = makeStyles(styles);

type Props = {
  wallet: WalletMetadata;
  step: number;
  children?: JSX.Element[] | JSX.Element;
  depositModalState: DepositModalState;
  asyncWireDepositEur: PayloadAC<typeof WIRE_DEPOSIT_EUR, CreateDepositOrderRequest>;
};

const DepositEurForm = ({
  children,
  depositModalState: { depositOrder },
  asyncWireDepositEur,
}: Props): JSX.Element => {
  const classes = useStyles();
  const { order, fail } = depositOrder;
  const initialState: { amount_in_eurocents: number | undefined } = {
    amount_in_eurocents: undefined,
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (!order) return;

    dispatch({ type: CLEAR_DEPOSIT_MODAL_STATE });
  }, [order]);

  if (depositOrder.inProgress) {
    return (
      <Grid container justifyContent="center" alignItems="center" className={classes.inProgress}>
        <CircularProgress color="secondary" size={60} />
      </Grid>
    );
  }
  const inputProps = {
    classes: {
      root: classes.inputRoot,
      input: classes.input,
    },
  };

  return (
    <>
      {children}

      <Formik
        initialValues={initialState}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          dispatch({
            type: SET_DEPOSIT_MODAL_DATA,
            payload: values,
          });
          const wireDepositEur: CreateDepositOrderRequest = {
            order_type: 'deposit_fiat',
            payment_type: 'wire_transfer',
            eur_price: values.amount_in_eurocents,
          };
          asyncWireDepositEur(wireDepositEur);
        }}
      >
        {({ handleChange, handleBlur, isValid, errors, values, touched }) => {
          const { amount_in_eurocents } = values;
          return (
            <Form>
              <Grid container direction="column" className={classes.inputWrapper}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography className={classes.currencyLabel}>Amount to deposit</Typography>

                    <Field
                      variant="outlined"
                      name="amount_in_eurocents"
                      id="amount_in_eurocents"
                      component={TextField}
                      className={classes.value}
                      placeholder="0"
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      defaultValue={amount_in_eurocents}
                      InputProps={{
                        classes: {
                          input: classes.input,
                        },
                        style: {
                          height: 50,
                          paddingRight: 0,
                        },
                        endAdornment: (
                          <div className={classes.inputSymbol}>
                            <div className={classes.inputSymbolValue}>EUR</div>
                          </div>
                        ),
                      }}
                      validate={composeValidators(
                        requiredValidator,
                        value => allowDecimalNumbersValidator(value),
                        value => greaterOrEqual(value, 10),
                        value => lessOrEqual(value, 9990),
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography className={classes.currencyLabel}>Get</Typography>
                    <TextField
                      variant="outlined"
                      type="number"
                      className={classes.value}
                      disabled
                      value={
                        Number(amount_in_eurocents) > 0 &&
                        Number(amount_in_eurocents) - WIRE_EUR_TRANSACTION_FEE > 0 &&
                        Number(amount_in_eurocents) - WIRE_EUR_TRANSACTION_FEE
                      }
                      fullWidth
                      InputProps={{
                        ...inputProps,
                        style: {
                          paddingRight: 0,
                        },
                        endAdornment: (
                          <div className={classes.inputSymbol}>
                            <div className={classes.inputSymbolValue}>EUR</div>
                          </div>
                        ),
                      }}
                    />
                    <Grid container item direction="row">
                      <Typography className={`${classes.currencyLabel} ${classes.noMargin}`}>
                        Transaction fee =
                      </Typography>
                      <Typography color="secondary" className={classes.currencyBalance}>
                        {WIRE_EUR_TRANSACTION_FEE} EUR
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  {errors.amount_in_eurocents && touched.amount_in_eurocents && (
                    <div className={classes.inputError}>{errors.amount_in_eurocents}</div>
                  )}
                  {fail && (
                    <div className={classes.inputError}>
                      Something went wrong. Please try again or contact our support.
                    </div>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <CustomButtonPrimary
                    className={classes.button}
                    variant="contained"
                    fullWidth
                    type="submit"
                    disabled={!isValid}
                  >
                    Create order
                  </CustomButtonPrimary>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default DepositEurForm;
