import { createStyles, Dialog, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { PayloadAC } from 'typesafe-actions';
import { getInvestDialog } from '../../App/Home/HomeContent/Product/ProductInvest/state/selectors';
import { setDialogOpen } from '../../App/state/actions';
import { AppState } from '../../App/state/initialState';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 'center',
      width: 'center',
      borderRadius: 6,
      backgroundColor: 'transparent',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(2.25),
      top: theme.spacing(2.25),
      color: theme.palette.grey[500],
    },
    img: {
      boxSizing: 'border-box',
      textAlign: 'center',
      width: '100%',
    },
    title: {
      fontSize: 24,
      marginTop: 20,
      marginBottom: 20,
    },
    answer: {
      fontSize: 14,
      color: theme.palette.primary.light,
    },
    button: {
      marginTop: 30,
      textTransform: 'none',
    },
    dialogPaper: {
      width: 500,
      paddingTop: 30,
      paddingBottom: 30,
    },
    dialogTitle: {
      paddingLeft: 30,
      paddingRight: 30,
      paddingBottom: 0,
      paddingTop: 0,
    },
  }),
);

export type Props = {
  setIsDialogOpened?: PayloadAC<'invest/dialogOpen', boolean>;
  isDialogOpened?: boolean;
  children: any;
};

const InvestDialog: React.FC<Props> = (props: Props): JSX.Element => {
  const { setIsDialogOpened, isDialogOpened } = props;
  const classes = useStyles();

  const onClose = () => {
    if (setIsDialogOpened) setIsDialogOpened(false);
  };

  return (
    <div>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={isDialogOpened ? isDialogOpened : false}
        className={classes.root}
        PaperProps={{ classes: { root: classes.dialogPaper } }}
      >
        {props.children}
      </Dialog>
    </div>
  );
};

const mapDispatchToProps = {
  setIsDialogOpened: setDialogOpen,
};
const mapStateToProps = (state: AppState) => {
  const isDialogOpened = getInvestDialog(state);

  return { isDialogOpened };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvestDialog);
