import { Grid, Paper, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { useEffect, useState } from 'react';
import { EmptyAC, PayloadAC } from 'typesafe-actions';
import euroIcon from '../../../../assets/coins/euroIcon.png';
import slcCoin from '../../../../assets/coins/sacretLifeCoin.svg';
import { NavTab, NavTabContent, NavTabs } from '../../../../components/NavTabs';
import { Colors } from '../../../../ui/style/colors';
import utils from '../../../../utils/balanceUtils';
import { AffiliateInfoState } from '../Affiliate/AffiliateInfo/state/initialState';
import AffiliateMerchantsTable from '../Affiliate/AffiliateMerchantsTable';
import useExchangeCurrencyPrices from '../Exchange/ExchangeCurrency/useExchangeCurrencyPrices';
import { GET_WALLET_BALANCE } from '../Wallet/state/actions';
import EurEarningsTable from './EurEarnings/EurEarningsTable/index';
import EurTransactionsFilterBar from './EurEarnings/index';
import TransactionsFilterBar from './TransactionsFilterBar';

const styles = (theme: Theme) =>
  createStyles({
    transactions: {
      margin: 24,
      marginTop: 30,
    },
    root: {
      borderRadius: '3px',
      backgroundColor: `${Colors.white}`,
      boxShadow: '0 10px 30px 0 rgba(132,141,182,0.25)',
      marginBottom: 10,
      padding: 20,
    },
    item: {
      flex: '0 0 auto',
      maxWidth: '100%',
      minWidth: 160,
      [theme.breakpoints.down('sm')]: {
        marginTop: 15,
        marginRight: 10,
      },
    },
    balanceLabel: {
      marginBottom: 10,
      fontSize: 14,
      fontWeight: 800,
      letterSpacing: 1,
      color: theme.palette.secondary.light,
    },
    balance: {
      fontSize: 26,
      fontWeight: 'bold',
      color: `${Colors.blueCharcoal}`,
      [theme.breakpoints.down('sm')]: {
        fontSize: 16,
      },
    },
    pendingBalance: {
      color: theme.palette.secondary.light,
    },
    icon: {
      height: 25,
      marginTop: 6,
      marginRight: 6,
      [theme.breakpoints.down('sm')]: {
        marginTop: 2,
        height: 16,
      },
    },
    tabPanel: {
      marginBottom: 30,
    },
  });

const useStyles = makeStyles(styles);

type Props = {
  setCurrentHomePage: PayloadAC<'home/set_current_home_page', string>;
  asyncGetIncomeBalances: EmptyAC<'affiliate/get_income_balances'>;
  getWalletBalance: EmptyAC<typeof GET_WALLET_BALANCE>;
  clearAllFilters: any;
  affiliateInfoState: AffiliateInfoState;
};

const Earnings: React.FC<Props> = ({
  setCurrentHomePage,
  asyncGetIncomeBalances,
  clearAllFilters,
  affiliateInfoState: { incomeBalances },
  getWalletBalance,
}: Props): JSX.Element => {
  useEffect(() => {
    asyncGetIncomeBalances();
    clearAllFilters();
    setCurrentHomePage('earnings');
    getWalletBalance();
  }, []);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const { getSelectedCoinMarketVal } = useExchangeCurrencyPrices();
  const slcEurVal = getSelectedCoinMarketVal('SLC');
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event: React.ChangeEvent<Record<string, unknown>>, newValue: number) => {
    setActiveTab(newValue);
    clearAllFilters();
  };
  let completed, pending;
  if (incomeBalances.length) {
    completed = incomeBalances.find(income => income.transaction_status === 'completed');
    pending = incomeBalances.find(income => income.transaction_status === 'pending');
  }

  return (
    <Grid item className={classes.transactions}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className={classes.tabPanel}
      >
        <Grid item>
          <NavTabs
            variant="scrollable"
            value={activeTab}
            onChange={handleTabChange}
            aria-label="Earnings"
          >
            <NavTab label="SLC earnings" />
            <NavTab label="EUR earnings" />
          </NavTabs>
        </Grid>
      </Grid>
      <Paper className={classes.root}>
        <Typography component="p" className={classes.balanceLabel}>
          COMPLETED <span className={classes.pendingBalance}>/ PENDING</span> INCOME
        </Typography>
        <Grid container spacing={isSmall ? 0 : 5}>
          <Grid item className={classes.item}>
            <Grid container justifyContent="center">
              <img src={euroIcon} alt="icon" className={classes.icon} />
              <Typography component="p" className={classes.balance}>
                {completed
                  ? utils.calculateValue(Number(completed.aggregated_value), 7, slcEurVal)
                  : '0.00'}
                <span className={classes.pendingBalance}>
                  &nbsp;/&nbsp;
                  {pending
                    ? utils.calculateValue(Number(pending.aggregated_value), 7, slcEurVal)
                    : '0.00'}{' '}
                  EUR
                </span>
              </Typography>
            </Grid>
          </Grid>

          <Grid item className={classes.item}>
            <Grid container justifyContent="center">
              <img src={slcCoin} alt="icon" className={classes.icon} />
              <Typography component="p" className={classes.balance}>
                {completed
                  ? utils.formatBalanceToString(Number(completed.aggregated_value), 7, 2, 2)
                  : '0.00'}
                <span className={classes.pendingBalance}>
                  &nbsp;/&nbsp;
                  {pending
                    ? utils.formatBalanceToString(Number(pending.aggregated_value), 7, 2, 2)
                    : '0.00'}{' '}
                  SLC
                </span>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <NavTabContent value={activeTab} index={0}>
        <TransactionsFilterBar />
        <AffiliateMerchantsTable />
      </NavTabContent>
      <NavTabContent value={activeTab} index={1}>
        <EurTransactionsFilterBar />
        <EurEarningsTable />
      </NavTabContent>
    </Grid>
  );
};

export default Earnings;
