import { CircularProgress, createStyles, Grid, makeStyles } from '@material-ui/core';
import { History } from 'history';
import React, { useEffect } from 'react';
import { EmptyAC } from 'typesafe-actions';
import LocalStorage from '../../data/LocalStorage';
import '../../index.css';
import { Colors } from '../../ui/style/colors';
import { ACCESS_TOKEN } from '../../utils/constants/localStorageKeys';
import { DASHBOARD_ROUTE } from '../../utils/constants/routes';
import { ExchangeAuthCodeState } from '../Auth/ExchangeAuthCode/state/initialState';
import { GET_USER_INFO } from '../state/actions';
import HomeContent from './HomeContent';
import HomeDrawer from './HomeDrawer';
import HomeHeader from './HomeHeader';
import { GET_REFRESH_TOKEN } from './state/common/actions';
import { RefreshTokenState } from './state/common/initialState';

const styles = () =>
  createStyles({
    container: {
      backgroundColor: `${Colors.whiteLilac}`,
      height: '100vh',
    },
    wrapper: {
      height: '100vh',
    },
  });

const useStyles = makeStyles(styles);

export type Props = {
  history: History;
  authState: ExchangeAuthCodeState;
  getUserInfo: EmptyAC<typeof GET_USER_INFO>;
  getRefreshToken: EmptyAC<typeof GET_REFRESH_TOKEN>;
  refreshTokenState: RefreshTokenState;
};

const Home = ({
  history,
  authState,
  getUserInfo,
  getRefreshToken,
  refreshTokenState,
}: Props): JSX.Element => {
  useEffect(() => {
    if (!LocalStorage.get(ACCESS_TOKEN)) {
      history.push(DASHBOARD_ROUTE);
    }
    getRefreshToken();
    getUserInfo();
  }, []);

  const classes = useStyles();

  return authState.inProgress || refreshTokenState.inProgress ? (
    <Grid container justifyContent="center" alignItems="center" className={classes.wrapper}>
      <CircularProgress color="secondary" size={60} />
    </Grid>
  ) : (
    <div className={classes.container}>
      <HomeHeader />
      <HomeDrawer />
      <HomeContent />
    </div>
  );
};

export default Home;
