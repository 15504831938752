import { createSelector } from 'reselect';
import { AppState } from '../../../../../state/initialState';

const getAffiliatePremiumTableState = ({
  home: {
    affiliate: { affiliatePremiumTable },
  },
}: AppState) => affiliatePremiumTable;

export const affiliatePremiumTableStateSelector = createSelector(
  getAffiliatePremiumTableState,
  affiliatePremiumTable => affiliatePremiumTable,
);

export const getAffiliatePremiumTableReferralsSelector = createSelector(
  affiliatePremiumTableStateSelector,
  affiliatePremiumTable => affiliatePremiumTable.referrals,
);

export const getAffiliatePremiumTableReferralsStatsSelector = createSelector(
  affiliatePremiumTableStateSelector,
  affiliatePremiumTable => affiliatePremiumTable.stats,
);

export const getPremiumCyclesSelector = createSelector(
  affiliatePremiumTableStateSelector,
  affiliatePremiumTable => affiliatePremiumTable.cycles,
);
