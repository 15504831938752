import { AuthState, initialState as auth } from '../Auth/state/initialState';
import { HomeState, initialState as home } from '../Home/state/initialState';
import { initialState as user, UserState } from '../state/user/initialState';

//tip account state

export type AppState = {
  auth: AuthState;
  user: UserState;
  home: HomeState;
};

const initialState = {
  auth,
  user,
  home,
};

export default initialState;
