import { CircularProgress, Grid, Hidden, Paper, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { EmptyAC, PayloadAC } from 'typesafe-actions';
import {
  ChartState,
  GetCryptoChartRequest,
} from '../../../../data/repositories/dashboard/typedefs';
import { Colors } from '../../../../ui/style/colors';
import { isUserMember } from '../../../../utils/helper/utils';
import { AppState } from '../../../state/initialState';
import { SET_CURRENT_HOME_PAGE } from '../../state/common/actions';
import CryptoChart from './CryptoChart/CryptoChart';
import EarningsAndAffiliates from './EarningsAndAffiliates/index';
import {
  GET_ALL_COINS,
  GET_CRYPTO_CHART_DATA,
  GET_WALLET_BALANCE,
  SET_SELECTED_COIN,
} from './state/actions';
import { ComponentState } from './state/initialState';
import { getCoinColorSelector } from './state/selectors';
import WalletCoinsTable from './WalletCoinsTable';
import WalletInfo from './WalletInfo';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      overflowY: 'auto',
      width: '100%',
    },
    progressRoot: {
      display: 'flex',
      height: '100vh',
      alignItems: 'center',
      justifyContent: 'center',
    },
    wallet: {
      minWidth: 1024,
      overflow: 'hidden',
    },
    walletPaper: {
      borderRadius: '3px',
      backgroundColor: `${Colors.white}`,
      boxShadow: '0 10px 30px 0 rgba(132,141,182,0.25)',
      padding: 20,
      overflow: 'hidden',
    },
    walletPaperMobile: {
      padding: 0,
      width: '100%',
    },
    label: {
      fontSize: 14,
      fontWeight: 800,
      letterSpacing: 1,
      color: theme.palette.secondary.light,
      textTransform: 'uppercase',
    },
    circularProgress: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%,-50%)',
    },
    tableCircularProgress: {
      margin: 'auto',
    },
    chartWrapper: {
      width: '100%',
      minHeight: 390,
      marginTop: 30,
      [theme.breakpoints.down('sm')]: {
        marginTop: 0,
      },
    },
    allMyCoins: { margin: '12px 6px auto 6px' },
    padding10: {
      padding: 10,
    },
    mobileRoot: {
      overflow: 'hidden',
      padding: 10,
    },
    walletInfo: {
      margin: '12px 0',
    },
  });

const useStyles = makeStyles(styles);

type Props = {
  getWalletBalance: EmptyAC<typeof GET_WALLET_BALANCE>;
  getAllCoins: EmptyAC<typeof GET_ALL_COINS>;
  setCurrentHomePage: PayloadAC<typeof SET_CURRENT_HOME_PAGE, string>;

  walletsSymbols: string[];
  componentState: ComponentState;
  membershipLevel?: any;
  asyncGetCryptoChartData: PayloadAC<typeof GET_CRYPTO_CHART_DATA, GetCryptoChartRequest>;
  setSelectedCoin: PayloadAC<typeof SET_SELECTED_COIN, string>;
  cryptoChartData: ChartState;
};

const Dashboard = ({
  getWalletBalance,
  walletsSymbols,
  getAllCoins,
  setCurrentHomePage,
  componentState,
  membershipLevel,
  asyncGetCryptoChartData,
  cryptoChartData,
}: Props): JSX.Element => {
  const isMember = isUserMember(membershipLevel);
  const classes = useStyles();
  const isEmpty: boolean = walletsSymbols.length == 0;

  const { coinsTableInProgress } = componentState;
  const coinColor: any = useSelector((state: AppState) =>
    getCoinColorSelector(cryptoChartData.selectedCoin)(state),
  );

  useEffect(() => {
    asyncGetCryptoChartData(cryptoChartData.selectedPeriodFilter);
    getAllCoins();

    if (walletsSymbols.length === 0) {
      getWalletBalance();
    }
    setCurrentHomePage('dashboard');
  }, []);

  if (coinsTableInProgress) {
    return (
      <div className={classes.circularProgress}>
        <CircularProgress color="secondary" size={60} />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Hidden smDown>
        <Grid container className={classes.wallet}>
          <Grid item xs={4}>
            <WalletInfo />
            <EarningsAndAffiliates />
          </Grid>

          <Grid item xs={8}>
            <Paper className={`${classes.walletPaper} ${classes.allMyCoins}`}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography className={classes.label} component="p">
                    All my cryptocurrencies
                  </Typography>
                </Grid>
                {isEmpty && coinsTableInProgress ? (
                  <CircularProgress
                    color="secondary"
                    size={60}
                    className={classes.tableCircularProgress}
                  />
                ) : (
                  <WalletCoinsTable walletsSymbols={walletsSymbols} isMember={isMember} />
                )}

                {!cryptoChartData.fail && (
                  <Grid item xs={12} className={classes.chartWrapper}>
                    <CryptoChart
                      cryptoChartData={cryptoChartData}
                      handleChange={asyncGetCryptoChartData}
                      stroke={coinColor}
                      showChartInfo
                    />
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid container direction="column" className={classes.mobileRoot}>
          <WalletInfo className={classes.walletInfo} />
          <Paper className={`${classes.walletPaper} ${classes.walletPaperMobile}`}>
            <Grid container className={classes.padding10}>
              <Grid item xs={12}>
                <Typography className={classes.label} component="p">
                  All my currencies
                </Typography>
              </Grid>
              {isEmpty && coinsTableInProgress ? (
                <CircularProgress
                  color="secondary"
                  size={60}
                  className={classes.tableCircularProgress}
                />
              ) : (
                <WalletCoinsTable walletsSymbols={walletsSymbols} isMember={isMember} />
              )}
            </Grid>
          </Paper>
          <Paper className={`${classes.walletPaper} ${classes.walletPaperMobile} `}>
            <Grid item xs={12} className={`${classes.chartWrapper} ${classes.padding10}`}>
              <CryptoChart
                cryptoChartData={cryptoChartData}
                handleChange={asyncGetCryptoChartData}
                stroke={coinColor}
                showChartInfo
              />
            </Grid>
          </Paper>
        </Grid>
      </Hidden>
    </div>
  );
};

export default Dashboard;
