import moment from 'moment';
import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import LocalStorage from '../../../../../../data/LocalStorage';
import api from '../../../../../../data/repositories/affiliate';
import { GetUnclaimedSLCResponse } from '../../../../../../data/repositories/affiliate/typedefs';
import {
  CLAIM_MERCHANTS_SLC_ENDPOINT,
  GET_MERCHANTS_PAGINATED_ENDPOINT,
  GET_MERCHANTS_REFERRALS_STATS_ENDPOINT,
  GET_MERCHANTS_UNCLAIMED_SLC_ENDPOINT,
} from '../../../../../../utils/constants/api';
import { USER_ID } from '../../../../../../utils/constants/localStorageKeys';
import {
  asyncClaimMerchantsSLC,
  asyncGetMerchantsReferralsPaginated,
  asyncGetMerchantsReferralsStats,
  asyncGetMerchantsUnclaimedSLC,
  CLAIM_MERCHANTS_SLC,
  GET_MERCHANTS_REFERRALS_PAGINATED,
  GET_MERCHANTS_REFERRALS_STATS,
  GET_MERCHANTS_UNCLAIMED_SLC,
  setMerchantsTablePage,
} from './actions';
import { MerchantsReferralStats } from './initialState';

export function* getMerchantsReferralsStats$() {
  try {
    const userId = LocalStorage.get(USER_ID);
    const response = yield api.getReferralsStats(
      GET_MERCHANTS_REFERRALS_STATS_ENDPOINT(userId ? userId : ''),
    );

    const stats: MerchantsReferralStats = {
      totalAmount: response.transaction_sums.slc_amount,
      totalEarned: response.transaction_sums.eur_amount,
      totalReferrals: response.referral_count,
      directReferrals: response.direct_referral_count,
    };

    yield put(asyncGetMerchantsReferralsStats.success(stats));
  } catch (error) {
    yield put(asyncGetMerchantsReferralsStats.failure(error));
  }
}

// const mapURL = (params: { [x: string]: { toString: () => void } }) => {
//   let newParams = {};
//   Object.keys(params).forEach(key => {
//     if (params[key] !== null) newParams = { ...newParams, [key]: params[key].toString() };
//   });
//   return new URLSearchParams(newParams).toString();
// };

export function* getMerchantsReferralsPaginated$(
  action: ReturnType<typeof asyncGetMerchantsReferralsPaginated.request>,
) {
  try {
    const userId = LocalStorage.get(USER_ID);
    const filtersAvailable = [
      'new_user_reward',
      'new_user_affiliate_reward',
      'new_merchant_reward',
      'merchant_parent_reward',
      'affiliate_percentage_reward',
      'premium_user_reward',
      'premium_user_affiliate_reward',
    ];

    const filter = {
      origin:
        action.payload.appliedFilters &&
        action.payload.appliedFilters.transactionType &&
        action.payload.appliedFilters.transactionType.length > 0
          ? action.payload.appliedFilters.transactionType.filter((type: any) =>
              filtersAvailable.includes(type),
            )
          : filtersAvailable,
      amountFrom:
        action.payload.appliedFilters && action.payload.appliedFilters.valueRange
          ? action.payload.appliedFilters.valueRange[0] > 0
            ? action.payload.appliedFilters.valueRange[0] * 100000000
            : 0
          : null,
      amountTo:
        action.payload.appliedFilters && action.payload.appliedFilters.valueRange
          ? action.payload.appliedFilters.valueRange[1] > 0
            ? action.payload.appliedFilters.valueRange[1] * 100000000
            : 0
          : null,
      dateFrom:
        action.payload.appliedFilters &&
        action.payload.appliedFilters.dateFilter &&
        action.payload.appliedFilters.dateFilter.length
          ? moment(action.payload.appliedFilters.dateFilter[0], 'DD/MM/YYYY').format(
              'YYYY-MM-DD HH:mm:ss',
            )
          : null,
      dateTo:
        action.payload.appliedFilters &&
        action.payload.appliedFilters.dateFilter &&
        action.payload.appliedFilters.dateFilter.length
          ? moment(action.payload.appliedFilters.dateFilter[1], 'DD/MM/YYYY')
              .endOf('day')
              .toDate() > moment().toDate()
            ? moment().format('YYYY-MM-DD HH:mm:ss')
            : moment(action.payload.appliedFilters.dateFilter[1], 'DD/MM/YYYY')
                .endOf('day')
                .format('YYYY-MM-DD HH:mm:ss')
          : null,
      tx_status:
        action.payload.appliedFilters && action.payload.appliedFilters.status
          ? action.payload.appliedFilters.status
          : null,
      affiliate_email:
        action.payload.appliedFilters && action.payload.appliedFilters.searchInput
          ? action.payload.appliedFilters.searchInput
          : null,
    };
    const mapURL = params => {
      let newParams = {};
      Object.keys(params).forEach(key => {
        if (params[key] !== null) newParams = { ...newParams, [key]: params[key].toString() };
      });
      return new URLSearchParams(newParams).toString();
    };
    const referrals: any = yield api.getReferralsPaginated(
      GET_MERCHANTS_PAGINATED_ENDPOINT(
        userId ? userId : '',
        action.payload.page,
        action.payload.perPage,
        mapURL(filter) && '&' + mapURL(filter),
      ),
    );

    // const referrals: any = yield api.getReferralsPaginated(
    //   GET_MERCHANTS_PAGINATED_ENDPOINT(
    //     userId ? userId : '',
    //     action.payload.page,
    //     action.payload.perPage,
    //     '&' + mapURL(filter),
    //   ),
    // );
    const stats: MerchantsReferralStats = {
      totalReferrals: referrals.transaction_count,
      directReferrals: null,
      totalEarned: referrals.transaction_sums.eur_amount,
      totalAmount: referrals.transaction_sums.slc_amount,
    };

    yield put(asyncGetMerchantsReferralsStats.success(stats));

    const result: any = {};
    referrals.transaction_logs.map((referral: { id: string | number }) => {
      result[referral.id] = referral;
    });

    // yield put(asyncGetMerchantsUnclaimedSLC.request());
    yield put(setMerchantsTablePage(action.payload.page));
    yield put(asyncGetMerchantsReferralsPaginated.success(result));
  } catch (error) {
    yield put(asyncGetMerchantsReferralsPaginated.failure(error));
  }
}

export function* getMerchantsUnclaimedSLC$() {
  try {
    const userId = LocalStorage.get(USER_ID);
    const response: GetUnclaimedSLCResponse = yield api.getUnclaimedSLC(
      GET_MERCHANTS_UNCLAIMED_SLC_ENDPOINT(userId ? userId : ''),
    );

    yield put(asyncGetMerchantsUnclaimedSLC.success(response));
  } catch (error) {
    yield put(asyncGetMerchantsUnclaimedSLC.failure(error));
  }
}

export function* claimAllMerchantsSLC$(action: ReturnType<typeof asyncClaimMerchantsSLC.request>) {
  try {
    const userId = LocalStorage.get(USER_ID);

    yield Promise.all(
      action.payload.map(cycle =>
        api.claimSLC(CLAIM_MERCHANTS_SLC_ENDPOINT(userId ? userId : '', cycle.cycle)),
      ),
    );

    yield put(asyncGetMerchantsReferralsPaginated.request({ page: 1, perPage: 10 }));
  } catch (error) {
    yield put(asyncClaimMerchantsSLC.failure(error));
  }
}

export default function* () {
  yield all([
    takeEvery(GET_MERCHANTS_REFERRALS_STATS, getMerchantsReferralsStats$),
    takeEvery(GET_MERCHANTS_REFERRALS_PAGINATED, getMerchantsReferralsPaginated$),
    takeEvery(GET_MERCHANTS_UNCLAIMED_SLC, getMerchantsUnclaimedSLC$),
    takeLatest(CLAIM_MERCHANTS_SLC, claimAllMerchantsSLC$),
  ]);
}
