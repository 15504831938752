/* eslint-disable */
import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import { SaveFilter } from '../../../../../../data/repositories/admin/typedefs';
import { ProductsPurchaseHistoryTableType } from './initialState';

export const GET_PRODUCTS_PURCHASE_HISTORY_PAGINATED = 'admin/get_products_purchase_history';
export const GET_PRODUCTS_PURCHASE_HISTORY_PAGINATED_SUCCESS =
  'admin/get_products_purchase_history_success';
export const GET_PRODUCTS_PURCHASE_HISTORY_PAGINATED_FAIL =
  'admin/get_products_purchase_history_fail';

export const EXPORT_PRODUCTS_PURCHASE_HISTORY = 'csv-export/products_purchase_history';
export const EXPORT_PRODUCTS_PURCHASE_HISTORY_SUCCESS =
  'csv-export/products_purchase_history_success';
export const EXPORT_PRODUCTS_PURCHASE_HISTORY_FAIL = 'csv-export/products_purchase_history_fail';

export const PRODUCTS_PURCHASE_HISTORY_TOGGLE_FILTER =
  'admin/products_purchase_history/toggle_filter';
export const PRODUCTS_PURCHASE_HISTORY_CLEAR_ALL_FILTERS =
  'admin/products_purchase_history/clear_all_filters';

export const SET_PRODUCTS_PURCHASE_HISTORY_TABLE_PAGE =
  'admin/products_purchase_history/set_table_page';

export const asyncGetProductsPurchaseHistory = createAsyncAction(
  GET_PRODUCTS_PURCHASE_HISTORY_PAGINATED,
  GET_PRODUCTS_PURCHASE_HISTORY_PAGINATED_SUCCESS,
  GET_PRODUCTS_PURCHASE_HISTORY_PAGINATED_FAIL,
)<any, ProductsPurchaseHistoryTableType[], Error>();

export const asyncExportProductsPurchaseHistory = createAsyncAction(
  EXPORT_PRODUCTS_PURCHASE_HISTORY,
  EXPORT_PRODUCTS_PURCHASE_HISTORY_SUCCESS,
  EXPORT_PRODUCTS_PURCHASE_HISTORY_FAIL,
)<any, undefined, Error>();

export const productsPurchaseHistoryToggleFilter = createStandardAction(
  PRODUCTS_PURCHASE_HISTORY_TOGGLE_FILTER,
)<SaveFilter>();
export const productsPurchaseHistoryClearAllFilters = createStandardAction(
  PRODUCTS_PURCHASE_HISTORY_CLEAR_ALL_FILTERS,
)<undefined>();
export const setProductsPurchaseHistoryTablePage = createStandardAction(
  SET_PRODUCTS_PURCHASE_HISTORY_TABLE_PAGE,
)<number>();
