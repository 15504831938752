import { combineReducers } from 'redux';
import changePassword from '../SettingsChangePassword/state/reducer';
import twoFactorAuth from '../SettingsTwoFactorAuth/state/reducer';
import profileInfo from '../Membership/state/reducer';
import currencyRate from '../CurrencySettings/state/reducer';
import feeState from '../FeeSettings/state/reducer';
import fiatDepositState from '../FiatDeposit/state/reducer';

export default combineReducers({
  changePassword,
  twoFactorAuth,
  profileInfo,
  currencyRate,
  feeState,
  fiatDepositState,
});
