import React from 'react';
import { EmptyAC, PayloadAC } from 'typesafe-actions';
import ChangePasswordForm from '../../../../../components/ChangePasswordForm';
import { ChangePasswordRequest } from '../../../../../data/repositories/account/typedefs';
import { CHANGE_PASSWORD, CHANGE_PASSWORD_CLEAR_STATE } from './state/actions';
import { ChangePasswordState } from './state/initialState';

type Props = ChangePasswordState & {
  changePassword: PayloadAC<typeof CHANGE_PASSWORD, ChangePasswordRequest>;
  changePasswordClearState: EmptyAC<typeof CHANGE_PASSWORD_CLEAR_STATE>;
};

const SettingsChangePassword: React.FC<Props> = ({
  inProgress,
  success,
  error,
  changePassword,
  changePasswordClearState,
}: Props): JSX.Element => {
  const submit = (data: any) => {
    const payload = {
      old_password: data.old_password,
      new_password: data.new_password,
    };
    changePassword(payload);
  };

  return (
    <ChangePasswordForm
      onSubmit={submit}
      changePasswordClearState={changePasswordClearState}
      changePasswordState={{ inProgress, success, error }}
    />
  );
};

export default SettingsChangePassword;
