/* eslint-disable @typescript-eslint/indent */
import { CircularProgress, Dialog } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { size } from 'lodash';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { PayloadAC } from 'typesafe-actions';
import Satelite from '../../../../../assets/satelite.svg';
import ModalState from '../../../../../components/ModalState';
import OrderTable, { HeaderProps } from '../../../../../components/OrdersTable/';
import SacretEmpty from '../../../../../components/SacretEmpty';
import {
  GetReferralsPaginatedRequest,
  Referral,
} from '../../../../../data/repositories/affiliate/typedefs';
import { roles } from '../../../../../utils/constants/roles';
import { AffiliateMerchantsTableState } from './state/initialState';
import { CLOSE_ERROR_MODAL } from './state/actions';
import { useDispatch } from 'react-redux';
import { capitalizeFirstLetter } from '../../../../../utils/helper/strings';

const styles = (theme: Theme) =>
  createStyles({
    myAffiliatesList: {
      color: theme.palette.secondary.light,
      fontSize: 18,
      fontWeight: 800,
      paddingBottom: 30,
    },
    root: {},
    circularProgress: {
      position: 'absolute',
      left: '50%',
      top: '50%',
    },
  });

const useStyles = makeStyles(styles);

type Props = AffiliateMerchantsTableState & {
  asyncGetMerchantsReferralsPaginated: PayloadAC<
    'orders/get_merchants_referrals_paginated',
    GetReferralsPaginatedRequest
  >;
  inProgress: boolean;
  user: any;
};

const headers: HeaderProps[] = [
  { name: 'Status', align: 'left' },
  { name: 'Payment type', align: 'left' },
  { name: 'Ref/proof', align: 'left' },
  { name: 'Affiliate email', align: 'left' },
  { name: 'Date & time', align: 'right' },
  { name: 'Amount', align: 'right' },
  { name: 'Value(EUR/ETH)', align: 'right' },
  { name: '', align: 'right' },
];

const headersRealEstate: HeaderProps[] = [
  { name: 'Name', align: 'left' },
  { name: 'Status', align: 'left' },
  { name: 'Payment type', align: 'left' },
  { name: 'Ref/proof', align: 'left' },
  { name: 'Affiliate email', align: 'left' },
  { name: 'Date & time', align: 'right' },
  { name: 'Value(EUR/ETH)', align: 'right' },
  { name: '', align: 'right' },
];
const OrdersTable: React.FC<Props> = (props: Props): JSX.Element => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    referrals,
    asyncGetMerchantsReferralsPaginated,
    inProgress,
    appliedFilters,
    stats: { totalReferrals },
    page,
    user,
    order_type,
    isErrorModalOpen,
    errorMessage,
  } = props;

  useEffect(() => {
    if (!totalReferrals) {
      asyncGetMerchantsReferralsPaginated({ page: page, perPage: 10 });
    }
  }, []);

  useEffect(() => {
    asyncGetMerchantsReferralsPaginated({ page, perPage: 10, order_type });
  }, [order_type]);

  useEffect(() => {
    if (user.roles && !roles.isOwner(props.user.roles)) history.push('/');
  }, [user]);

  const isEmpty = size(referrals) == 0;
  return (
    <div>
      {inProgress && !referrals ? (
        <CircularProgress color="secondary" className={classes.circularProgress} size={60} />
      ) : (
        <div>
          {!isEmpty ? (
            <div className={classes.root}>
              {/*<Typography className={classes.myAffiliatesList}>My affiliates list</Typography>*/}
              <OrderTable
                referrals={referrals ? referrals : new Map<string, Referral>()}
                headers={order_type === 'project_invest' ? headersRealEstate : headers}
                filter={appliedFilters}
                totalReferrals={totalReferrals ? totalReferrals : 0}
                page={page}
                inProgress={inProgress}
                order_type={order_type}
              />
              <Dialog open={isErrorModalOpen}>
                <ModalState
                  variant="failed"
                  onExit={() => dispatch({ type: CLOSE_ERROR_MODAL })}
                  message={capitalizeFirstLetter(errorMessage)}
                />
              </Dialog>
            </div>
          ) : (
            <SacretEmpty
              image={Satelite}
              title="Yikes, You don't have any orders"
              text=""
              hasButton={false}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default OrdersTable;
