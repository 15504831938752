import { connect } from 'react-redux';
import { AppState } from '../../../state/initialState';
import { userStateSelector } from '../../../state/selectors';
import { profileInfoStateSelector } from '../../HomeContent/Settings/Membership/state/selectors';
import UserEmailAndMembership from './UserEmailAndMembership';

const mapStateToProps = (state: AppState) => {
  const user = userStateSelector(state);

  const profileInfo = profileInfoStateSelector(state);
  return { profileInfo, user };
};

export default connect(mapStateToProps)(UserEmailAndMembership);
