import { connect } from 'react-redux';
import UserEarningsTable from './UserEarningsTable';
import { AppState } from '../../../../state/initialState';
import {
  asyncGetUserEarningsTablePaginated,
  clearAllFilters,
  asyncExportUsersEarning,
} from './state/actions';
import { userEarningsTableStateSelector, getAppliedFiltersSelector } from './state/selectors';

const mapStateToProps = (state: AppState) => {
  const appliedFilters = getAppliedFiltersSelector(state);
  return { ...userEarningsTableStateSelector(state), appliedFilters };
};

const mapDispatchToProps = {
  clearAllFilters,
  asyncGetUserEarningsTablePaginated: asyncGetUserEarningsTablePaginated.request,
  asyncExportUsersEarning: asyncExportUsersEarning.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserEarningsTable);
