export type progressStateObject = {
  inProgress: boolean;
  fail: boolean;
  success: boolean;
  error: null;
};

export type membershipStateObject = {
  weekly_sell_limit: number;
  user_identifier: string;
  affiliate_package_identifier: string;
  affiliate_package_level: number;
  expires_at: number;
  expires_at_string: string;
  cadence: string;
  is_expired: boolean;

  sell_high_limit: number;
  sell_low_limit: number;
  created_at: string;

  allowed_amount_to_sell_in_eurocents?: number;
  allowed_amount_to_sell_in_slc?: number;
  sold_in_eurocents?: number;
  sold_in_slc?: number;
};

export type kycInfoObject = {
  account_id: string;
  applicant_id: string;
  first_name: string;
  last_name: string;
  middle_name: string;
  date_of_birth: string;
  phone_number: string;
  gender: string;
  citizenship: string;
  addresses: string | null;
  documents: string | null;
  created_at: string;
  updated_at: string;
};

export type accountInfoObject = {
  confirmed: boolean;
  creaed_at: string;
  email: string;
  enabled: boolean;
  first_name: string;
  id: string;
  kyc_status: string;
  last_name: string;
  swapped: boolean;
};

export type orderPaymentInfoObject = {
  id: string;
  account_id: string;
  ether_address: {
    String: string;
    Valid: boolean;
  };
  beneficiary: {
    String: string;
    Valid: boolean;
  };
  beneficiary_address: {
    String: string;
    Valid: boolean;
  };
  iban: {
    String: string;
    Valid: boolean;
  };
  bic: {
    String: string;
    Valid: boolean;
  };
  created_at: string;
  updated_at: {
    Time: string;
    Valid: boolean;
  };
  other_info: {
    String: string;
    Valid: boolean;
  };
};

export type ProfileInfoState = {
  progressState: progressStateObject;
  kyc_info: kycInfoObject;
  affiliate_package_info: membershipStateObject;
  slc_address: string;
  order_payment_info: orderPaymentInfoObject;
  account_info: accountInfoObject;
};

export const initialState: ProfileInfoState = {
  progressState: {
    error: null,
    fail: false,
    inProgress: false,
    success: false,
  },
  kyc_info: {
    account_id: '',
    applicant_id: '',
    first_name: '',
    last_name: '',
    middle_name: '',
    date_of_birth: '',
    phone_number: '',
    gender: '',
    citizenship: '',
    addresses: null,
    documents: null,
    created_at: '',
    updated_at: '',
  },
  affiliate_package_info: {
    created_at: '',
    affiliate_package_identifier: '',
    affiliate_package_level: 0,
    cadence: '',
    expires_at: 0,
    expires_at_string: '',
    is_expired: false,
    user_identifier: '',
    allowed_amount_to_sell_in_eurocents: 0,
    allowed_amount_to_sell_in_slc: 0,
    sold_in_eurocents: 0,
    sold_in_slc: 0,
    weekly_sell_limit: 0,
    sell_high_limit: 0,
    sell_low_limit: 0,
  },
  slc_address: '',
  order_payment_info: {
    id: '',
    account_id: '',
    ether_address: {
      String: '',
      Valid: false,
    },
    beneficiary: {
      String: '',
      Valid: false,
    },
    beneficiary_address: {
      String: '',
      Valid: false,
    },
    iban: {
      String: '',
      Valid: false,
    },
    bic: {
      String: '',
      Valid: false,
    },
    created_at: '',
    updated_at: {
      Time: '',
      Valid: false,
    },
    other_info: {
      String: '',
      Valid: false,
    },
  },
  account_info: {
    confirmed: false,
    creaed_at: '',
    email: '',
    enabled: false,
    first_name: '',
    id: '',
    kyc_status: '',
    last_name: '',
    swapped: false,
  },
};
