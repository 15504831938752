import { createStyles, Dialog, Grid, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { EmptyAC, PayloadAC } from 'typesafe-actions';
import CustomButtonPrimary from '../../../../../../components/CustomButtons/CustomButtonPrimary';
import ModalState from '../../../../../../components/ModalState';
import { Coin } from '../../../../../../data/repositories/wallet/typedefs';
import { Colors } from '../../../../../../ui/style/colors';
import { WALLET_COINS } from '../../../../../../utils/constants/amounts';
import { IS_TWO_FACTOR_AUTH_ENABLED } from '../../../Settings/state/actions';
import { ProductCurrencyState } from '../state/initialState';
import InvestCurrencyBuyOptionsItem from './InvestCurrencyBuyOptionsItem';

const styles = createStyles({
  indicator: {
    display: 'none',
  },
  unselectedTab: {
    boxSizing: 'border-box',
    border: `1px solid ${Colors.dustyGray}`,
    opacity: 0.2,
    backgroundColor: `${Colors.blueHaze}`,
  },
  tabLabel: {
    color: `${Colors.wildBlueYonder}`,
    fontSize: 16,
    fontWeight: 500,
    textTransform: 'none',
  },
  title: {
    color: `${Colors.nepal}`,
    fontSize: 16,
    fontWeight: 'bold',
    marginTop: 24,
    marginBottom: 24,
  },
  desc: {
    color: `${Colors.nepal}`,
    marginBottom: 10,
  },
  button: {
    boxSizing: 'border-box',
    fontSize: 16,
    letterSpacing: 1,
    height: 48,
    padding: 0,
    position: 'relative',
    textTransform: 'none',
    marginBottom: 28,
  },
  activeButton: {
    fontWeight: 600,
  },
  dialog: {
    height: 'center',
    width: 'center',
    borderRadius: 6,
    backgroundColor: 'transparent',
    paddingLeft: 30,
    paddingRight: 30,
  },
});

const useStyles = makeStyles(styles);

type Props = Pick<ProductCurrencyState, 'selectedOption'> & {
  getAllCoins: EmptyAC<'wallet/get_all_coins'>;
  onInvestStep: PayloadAC<'invest/save_state', any>;
  allCoins: Map<string, Coin>;
  isMember: boolean;
  disabledAllCurrencies: boolean;
  isTwoFactorAuthEnabled: EmptyAC<typeof IS_TWO_FACTOR_AUTH_ENABLED>;
  is2FaEnabled: any;
};

const InvestCurrencyBuyOptions = ({
  allCoins,
  getAllCoins,
  onInvestStep,
  isMember,
  disabledAllCurrencies,
  selectedOption,
  isTwoFactorAuthEnabled,
  is2FaEnabled,
}: Props): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    dispatch({ type: 'exchange/sell_weekly_limit' });
    if (allCoins.size === 0) {
      getAllCoins();
    }
  }, []);

  useEffect(() => {
    isTwoFactorAuthEnabled();
  }, []);

  const handleContinue = () => {
    if (!is2FaEnabled && selectedOption !== 'WIRE_EUR') {
      setIsOpen(true);
    } else {
      onInvestStep({ step: 1, time: moment().unix() });
    }
  };

  return (
    <Grid container justifyContent="center">
      <Dialog open={isOpen} className={classes.dialog}>
        <ModalState
          onExit={() => {
            setIsOpen(false);
          }}
          message="First enable 2FA authentication"
          variant="warning"
          showCloseButton={false}
        >
          <Link
            to={{
              pathname: '/settings',
              search: '?auth=true',
            }}
          >
            Enable here
          </Link>
        </ModalState>
      </Dialog>
      <Grid item xs={12}>
        <Typography className={classes.title}>Choose payment type</Typography>
      </Grid>
      <Grid container item xs={12} spacing={3} style={{ marginBottom: 57 }} justifyContent="center">
        {Object.entries(allCoins)
          .filter(el => WALLET_COINS.concat('WIRE_EUR').includes(el[0]))
          .map(([, coin]) => {
            return (
              <InvestCurrencyBuyOptionsItem key={coin.symbol} coin={coin} isMember={isMember} />
            );
          })}
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 6 }}>
        <CustomButtonPrimary
          variant="contained"
          size="large"
          className={
            disabledAllCurrencies ? classes.button : clsx(classes.button, classes.activeButton)
          }
          fullWidth
          onClick={handleContinue}
          disabled={disabledAllCurrencies}
        >
          Continue
        </CustomButtonPrimary>
      </Grid>
    </Grid>
  );
};

export default InvestCurrencyBuyOptions;
