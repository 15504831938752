import { connect } from 'react-redux';
import { AppState } from '../../../../state/initialState';
import { getWalletTransfersPaginated } from '../../Transactions/state/actions';
import { getAllCoinsSelector } from '../../Wallet/state/selectors';
import { clearAllEurFilters, toggleFilter } from '../state/actions';
import { getAppliedFiltersSelector } from '../state/selectors';
import EurTransactionsFilterBar from './EurTransactionsFilterBar';

const mapStateToProps = (state: AppState) => {
  const allCoins = getAllCoinsSelector(state);
  const appliedFilters = getAppliedFiltersSelector(state);
  return { allCoins, appliedFilters };
};

const dispatchStateToProps = {
  getWalletTransfersPaginated: getWalletTransfersPaginated.request,
  toggleFilter,
  clearAllEurFilters: clearAllEurFilters,
};

export default connect(mapStateToProps, dispatchStateToProps)(EurTransactionsFilterBar);
