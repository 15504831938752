import { orderPaymentInfoObject } from '../../../Settings/Membership/state/initialState';

export type ProductCurrencyRequestState = {
  amount?: string;
  get?: string;
  step: any;
  isBtnDisabled?: boolean;
  rerender?: number;
  transaction_hash?: string;
  ether_address?: string;
  beneficiary?: string;
  beneficiary_address?: string;
  iban?: string;
  bic?: string;
  time?: number;
  eth_price?: number;
  sellFee?: number;
  other_info?: string;
};

export type ProductCurrencyState = ProductCurrencyRequestState & {
  inProgress: boolean;
  two_factor: string;
  packagesInProgress: boolean;
  fail: boolean;
  error: Error | null;
  selectedOption: string;
  selectedTab: number;
  packageName: string;
  order_proof: string;
  membershipState?: any;
  order_payment_info: orderPaymentInfoObject;
  fetchMembershipProgress: boolean;
  isDialogOpen: boolean;
};

export const initialState: ProductCurrencyState = {
  isDialogOpen: false,
  two_factor: '',
  time: 0,
  inProgress: false,
  transaction_hash: '',
  packagesInProgress: false,
  fail: false,
  error: null,
  step: 0,
  amount: '',
  get: '',
  ether_address: '',
  beneficiary: '',
  beneficiary_address: '',
  sellFee: 0,
  iban: '',
  bic: '',
  selectedOption: 'ETH',
  selectedTab: 0,
  isBtnDisabled: false,
  rerender: 0,
  packageName: '',
  order_proof: '',
  other_info: '',
  membershipState: {
    created_at: '',
    key: '',
    name: '',
    buy_limit_low: 0,
    buy_limit_high: 0,
  },
  order_payment_info: {
    id: '',
    account_id: '',
    ether_address: {
      String: '',
      Valid: false,
    },
    beneficiary: {
      String: '',
      Valid: false,
    },
    beneficiary_address: {
      String: '',
      Valid: false,
    },
    iban: {
      String: '',
      Valid: false,
    },
    bic: {
      String: '',
      Valid: false,
    },
    created_at: '',
    updated_at: {
      Time: '',
      Valid: false,
    },
    other_info: {
      String: '',
      Valid: false,
    },
  },
  fetchMembershipProgress: false,
};
