import { Button, Chip, Grid, Theme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { createStyles, makeStyles } from '@material-ui/styles';
import React from 'react';
import { Colors } from '../../../../../../ui/style/colors';

const styles = (theme: Theme) =>
  createStyles({
    filter: {
      minWidth: 200,
      '&:not(:first-child)': { paddingLeft: 15 },
      '&:not(:last-child)': { paddingRight: 15 },
    },
    filterDate: {
      minWidth: 270,
      '&:not(:first-child)': { paddingLeft: 15 },
      '&:not(:last-child)': { paddingRight: 15 },
    },
    valueSlider: {},
    clearAll: {
      textTransform: 'none',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      color: theme.palette.primary.light,
    },
    clearAllIcon: {
      fontSize: 14,
      padding: 5,
      color: theme.palette.primary.light,
    },
    chip: {
      backgroundColor: `${Colors.spunPearl}`,
      '&:hover': {
        backgroundColor: `${Colors.ghost}`,
      },
      color: `${Colors.white}`,
      margin: 10,
      padding: 5,
    },
    clearChipIcon: {
      fontSize: 14,
      padding: 5,
      color: `${Colors.white}`,
    },
  });

const useStyles = makeStyles(styles);

type Props = {
  clearAllFilters: any;
  clearError: any;
  appliedFiltersIds: string[];
  stateAppliedFilters: Map<string, string[]>;
  toggleFilter: any;
  areFiltersApplied: boolean;
  asyncTableDataPaginated: any;
};

type Chip = {
  value: string;
  type: string;
};
const renderType = (type: any) => {
  switch (type) {
    case 'airdrop_reward':
      return 'Airdrop';
    case 'new_user_reward':
      return 'Registration reward';
    case 'new_merchant_reward':
      return 'Became Partner';
    case 'buy':
      return 'Buy';
    case 'deposit':
      return 'Deposit';
    case 'withdraw':
      return 'Withdraw';
    case 'transfer':
      return 'Wallet Transfer';
    case 'exchange':
      return 'Exchange';
    case 'merchant_parent_reward':
      return 'Affiliate became Partner';
    case 'new_user_affiliate_reward':
      return 'New Affiliate';
    case 'payment_sent':
      return 'Purchase';
    case 'merchant_income':
      return 'Sale';
    case 'pool_reward':
      return 'Pool reward';
    case 'platform_pool_reward':
      return 'Platform pool reward';
    case 'premium_user_reward':
      return 'Affiliate package purchase';
    case 'premium_user_affiliate_reward':
      return 'Affiliate purchased Affiliate package';
    case 'affiliate_percentage_reward':
      return 'BV';
    case 'sell':
      return 'Sell';
    case 'VAT':
      return 'VAT';
    case 'timed_escrow':
      return 'Timed Escrow';
    case 'waiting_for_approval':
      return 'Waiting for approval';
    case 'pending_escrow':
      return 'Pending Escrow';
    case 'escrow':
      return 'Escrow';
    case 'term_saving':
      return 'HODL';
    case 'invested':
      return 'In HODL';
    case 'completed':
      return 'Completed';
    case 'pending':
      return 'Pending';
    default:
      return type;
  }
};
const AppliedFiltersChips = ({
  clearAllFilters,
  clearError,
  appliedFiltersIds,
  stateAppliedFilters,
  toggleFilter,
  areFiltersApplied,
  asyncTableDataPaginated,
}: Props) => {
  const classes = useStyles();
  const chips: Chip[] = [];

  for (const index in appliedFiltersIds) {
    const filter = stateAppliedFilters.get(appliedFiltersIds[index]);

    const pushData = (value: any) => {
      chips.push({ value, type: appliedFiltersIds[index] });
    };
    switch (appliedFiltersIds[index]) {
      case 'status':
      case 'transactionType':
      case 'transferType':
      case 'userToUserTransferTypes':
        if (filter) {
          for (const i in filter) {
            pushData(filter[i]);
          }
        }
        break;
      case 'valueRange':
      case 'dateFilter':
        if (filter) {
          pushData(
            `${filter[0]} - ${filter[1]} ${appliedFiltersIds[index] == 'valueRange' ? 'EUR' : ''}`,
          );
        }
        break;
      case 'coinSymbol':
        if (filter && Array.isArray(filter)) {
          for (const i in filter) {
            pushData(filter[i]);
          }
        } else if (filter) {
          pushData(filter);
        }
        break;
      case 'searchByEmailInput':
      case 'searchByEmail':
        if (filter) {
          pushData(filter[0]);
        }
    }
  }
  const onDelete = (chip: Chip) => {
    let newFilterValue;
    if (chip.type != 'valueRange' && chip.type != 'dateFilter') {
      const filter = stateAppliedFilters.get(chip.type);
      if (filter) {
        newFilterValue = filter.filter(f => f != chip.value);
        newFilterValue = newFilterValue.length > 0 ? newFilterValue : undefined;
      }
    }
    const object: any = {};
    stateAppliedFilters.forEach((value, key) => {
      object[key] = value;
    });

    if (!newFilterValue && stateAppliedFilters.size == 1) {
      asyncTableDataPaginated({ page: 0, perPage: 10, appliedFilters: null });
      clearAllFilters();
    } else {
      asyncTableDataPaginated({
        page: 0,
        perPage: 10,
        appliedFilters: { ...object, [chip.type]: newFilterValue },
      });
      toggleFilter({ filterType: chip.type, value: newFilterValue });
    }
    clearError(chip.type);
  };

  return (
    <Grid item xs={12}>
      {chips.map((chip: Chip) => (
        <Chip
          key={chip.value}
          size="small"
          clickable={true}
          deleteIcon={<CloseIcon className={classes.clearChipIcon} />}
          label={renderType(chip.value)}
          onDelete={() => onDelete(chip)}
          className={classes.chip}
        />
      ))}
      {areFiltersApplied && (
        <Button
          size="small"
          className={classes.clearAll}
          onClick={() => {
            asyncTableDataPaginated({ page: 0, perPage: 10, appliedFilters: null });
            clearAllFilters();
          }}
        >
          <CloseIcon className={classes.clearAllIcon} />
          Clear all
        </Button>
      )}
    </Grid>
  );
};

export default AppliedFiltersChips;
