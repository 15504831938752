import { connect } from 'react-redux';
import { AppState } from '../../state/initialState';
import { ExchangeAuthCode } from './ExchangeAuthCode';
import { exchangeAuthCodeStateSelector } from './state/selectors';

const mapStateToProps = (state: AppState) => {
  const exchangeAuthCodeState = exchangeAuthCodeStateSelector(state);
  return exchangeAuthCodeState;
};

export default connect(mapStateToProps, null)(ExchangeAuthCode);
