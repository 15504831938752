import { all, put, takeEvery } from 'redux-saga/effects';
import LocalStorage from '../../../../../../data/LocalStorage';
import api from '../../../../../../data/repositories/product';
import { USER_ID } from '../../../../../../utils/constants/localStorageKeys';
import {
  asyncProductCreate,
  asyncProductPublish,
  asyncProductUpdate,
  asyncGetProduct,
  GET_PRODUCT,
  PRODUCT_CREATE,
  PRODUCT_PUBLISH,
  PRODUCT_UPDATE,
} from './actions';
import { AnyAction } from 'redux';
import { push } from 'connected-react-router';
import {
  EDIT_PRODUCT,
  INSERT_PRODUCT,
  PUBLISH_PRODUCT,
  GET_SINGLE_PRODUCT,
} from '../../../../../../utils/constants/api';
import { ProductDetail } from '../../../../../../data/repositories/product/typedef';
import { asyncGetProductsPaginated } from '../../ProductsTable/state/actions';

export function* createProduct$(action: AnyAction) {
  try {
    const userId = LocalStorage.get(USER_ID);
    const response = yield api.insertProduct(action.payload, INSERT_PRODUCT(userId || ''));

    yield put(push('/products'));
    yield put(
      asyncGetProductsPaginated.request({
        page: 0,
        perPage: 10,
        appliedFilters: null,
      }),
    );
    yield put(asyncProductUpdate.success(response));
  } catch (error) {
    yield put(asyncProductUpdate.failure(error));
  }
}

export function* updateProduct$(action: AnyAction) {
  try {
    const userId = LocalStorage.get(USER_ID);
    const response = yield api.editProduct(
      action.payload,
      EDIT_PRODUCT(userId || '', action.payload.id),
    );
    yield put(
      asyncGetProductsPaginated.request({
        page: 0,
        perPage: 10,
        appliedFilters: null,
      }),
    );
    yield put(push('/products'));
    yield put(asyncProductCreate.success(response));
  } catch (error) {
    yield put(asyncProductCreate.failure(error));
  }
}

export function* publishProduct$(action: AnyAction) {
  try {
    const userId = LocalStorage.get(USER_ID);
    yield api.publishProduct(PUBLISH_PRODUCT(userId || '', action.payload));

    yield put(asyncProductPublish.success({ status: 'published' }));
  } catch (error) {
    yield put(asyncProductPublish.failure(error));
  }
}

export function* getProduct$(action: AnyAction) {
  const prepareProductData = (data: any) => {
    const bigData = ['soft_cap', 'hard_cap'];
    const goObjects = ['photo', 'website', 'white_paper', 'icon'];
    const cents = ['pre_ico_price', 'ico_price', 'buy_limit_low', 'buy_limit_high'];

    Object.keys(data).forEach(key => {
      if (bigData.includes(key)) data[key] = data[key] / 100;
      else if (cents.includes(key)) data[key] /= 100;
      else if (goObjects.includes(key)) data[key] = data[key].String;
    });

    return data;
  };
  try {
    const userId = LocalStorage.get(USER_ID);
    const { product }: { product: ProductDetail } = yield api.singleProduct(
      GET_SINGLE_PRODUCT(userId ? userId : '', action.payload.id),
    );
    yield put(asyncGetProduct.success(prepareProductData(product)));
  } catch (error) {
    yield put(
      asyncGetProduct.failure(
        error && error.message ? error.message : 'Cannot fetch product data.',
      ),
    );
  }
}

export default function* () {
  yield all([
    takeEvery(PRODUCT_CREATE, createProduct$),
    takeEvery(PRODUCT_UPDATE, updateProduct$),
    takeEvery(PRODUCT_PUBLISH, publishProduct$),
    takeEvery(GET_PRODUCT, getProduct$),
  ]);
}
