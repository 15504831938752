import { InviteData } from '../../../App/Home/HomeContent/Invites/InvitesModal/state/initialState';
import { SEND_INVITES } from '../../../utils/constants/api';
import Http from '../../Http';
import { GetFriendsInvitationsPaginatedRequest } from './typedef';

export const getAllFriendsInvitations = async (
  endpoint: string,
): Promise<GetFriendsInvitationsPaginatedRequest> => {
  return (await Http.get(endpoint)).data;
};
const sendInvites = async (accountId: string, data: InviteData) => {
  await Http.post(SEND_INVITES(accountId), data);
};
export default {
  getAllFriendsInvitations,
  sendInvites,
};
