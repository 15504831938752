import { createReducer } from 'typesafe-actions';
import { GET_PROFILE_INFO, GET_PROFILE_INFO_SUCCESS } from '../../../../state/actions';
import {
  BUY_SLC_PACKAGE,
  BUY_SLC_PACKAGE_FAIL,
  BUY_SLC_PACKAGE_SUCCESS,
  EXCHANGE_CURRENCY,
  EXCHANGE_CURRENCY_FAIL,
  EXCHANGE_CURRENCY_SAVE_STATE,
  EXCHANGE_CURRENCY_SUCCESS,
  SET_SELECTED_OPTION,
  SET_SELECTED_TAB,
  SLC_PACKAGES,
  SLC_PACKAGES_FAIL,
  SLC_PACKAGES_SUCCESS,
} from './actions';
import { initialState } from './initialState';

export default createReducer(initialState, {
  [SET_SELECTED_OPTION]: (state, { payload }) => ({
    ...state,
    selectedOption: payload,
  }),
  [SET_SELECTED_TAB]: (state, { payload }) => {
    return {
      ...state,
      selectedTab: payload,
    };
  },
  [EXCHANGE_CURRENCY_SAVE_STATE]: (state, { payload }) => ({
    ...state,
    ...payload,
  }),
  [EXCHANGE_CURRENCY]: state => ({
    ...state,
    inProgress: true,
  }),
  [EXCHANGE_CURRENCY_SUCCESS]: state => ({
    ...state,
    inProgress: false,
    fail: false,
  }),
  [EXCHANGE_CURRENCY_FAIL]: state => ({
    ...state,
    inProgress: false,
    fail: true,
  }),
  [SLC_PACKAGES]: state => ({
    ...state,
    packagesInProgress: true,
  }),
  [SLC_PACKAGES_SUCCESS]: (state, { payload }) => ({
    ...state,
    ...payload,
    packagesInProgress: false,
  }),
  [SLC_PACKAGES_FAIL]: state => ({
    ...state,
    packagesInProgress: false,
    fail: true,
  }),
  [BUY_SLC_PACKAGE]: state => ({
    ...state,
    inProgress: true,
  }),
  [BUY_SLC_PACKAGE_SUCCESS]: (state, { payload }) => ({
    ...state,
    ...payload,
    inProgress: false,
  }),
  [BUY_SLC_PACKAGE_FAIL]: (state, { payload }) => ({
    ...state,
    inProgress: false,
    fail: payload,
  }),
  [GET_PROFILE_INFO]: state => ({
    ...state,
    fetchMembershipProgress: false,
  }),
  [GET_PROFILE_INFO_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      fetchMembershipProgress: true,
      membershipState: payload.affiliate_package_info,
      order_payment_info: payload.order_payment_info,
    };
  },
});
