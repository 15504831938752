import {
  ExchangeCurrencyState,
  initialState as exchangeCurrency,
} from '../ExchangeCurrency/state/initialState';

export type ExchangeState = {
  exchangeCurrency: ExchangeCurrencyState;
};

export const initialState: ExchangeState = {
  exchangeCurrency,
};
