/* eslint-disable */
import { createStyles, Grid, Theme, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect } from 'react';
import { EmptyAC, PayloadAC } from 'typesafe-actions';
import CustomButtonPrimary from '../../../../../../components/CustomButtons/CustomButtonPrimary';
import LocalStorage from '../../../../../../data/LocalStorage';
import { Colors } from '../../../../../../ui/style/colors';
import balanceUtils from '../../../../../../utils/balanceUtils';
import { ROLE } from '../../../../../../utils/constants/localStorageKeys';
import useExchangeCurrencyPrices from '../../../Exchange/ExchangeCurrency/useExchangeCurrencyPrices';
import InvestDialog from '../../InvestDialog';
import { GET_ENTIRE_WALLET_BALANCES, IS_INVEST_DIALOG_OPEN } from '../../state/actions';
import { entireWalletBalanceObject } from '../../state/initialState';

const styles = (theme: Theme) =>
  createStyles({
    totalBalanceLabel: {
      fontSize: 14,
      fontWeight: 800,
      letterSpacing: 1,
      color: theme.palette.secondary.light,
    },
    numberOfCoins: {
      fontSize: 18,
      fontWeight: 600,
      color: theme.palette.primary.light,
    },
    balance: {
      fontSize: 26,
      marginTop: 14,
      fontWeight: 'bold',
      color: `${Colors.blueCharcoal}`,
      [theme.breakpoints.down('sm')]: {
        fontSize: 20,
      },
    },
    depositButton: {
      textTransform: 'none',
      marginTop: 24,
    },
    depositIcon: {
      marginRight: '17px',
      fontSize: 20,
    },

    avatar: {
      backgroundColor: 'blue[100]',
      color: 'blue[600]',
    },
    balanceLabel: {
      fontSize: 14,
      fontWeight: 800,
      letterSpacing: 1,
      color: theme.palette.secondary.light,
      marginBottom: 10,
    },
    slcBalance: {
      fontSize: 18,
      fontWeight: 'bold',
      color: theme.palette.secondary.light,
    },
    pendingBalance: {
      fontSize: 18,
      color: theme.palette.secondary.light,
    },
    investButton: {
      textTransform: 'none',
      fontSize: 14,
      marginTop: 30,
      height: 48,
      fontWeight: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },

    eurLine: {
      borderTop: `2px solid ${theme.palette.secondary.light}`,
      width: 'auto',
      marginTop: 30,
      marginBottom: 15,
      color: theme.palette.secondary.light,
      '&:hover': {
        borderTop: `2px solid ${Colors.denim}`,
        transition: 'border 500ms ease-out',
        cursor: 'pointer',
      },
    },
    slcLine: {
      borderTop: `2px solid ${theme.palette.secondary.light}`,
      width: 'auto',
      marginTop: 30,
      marginBottom: 15,
      color: theme.palette.secondary.light,
      '&:hover': {
        borderTop: `2px solid ${Colors.mediumTurquoise}`,
        transition: 'border 500ms ease-out',
        cursor: 'pointer',
      },
    },
    currencyWrapper: {
      width: '100%',
      textAlign: 'center',
      lineHeight: '0.1em',
      margin: '0',
    },
    currencyheading: {
      background: `${Colors.white}`,
      padding: '0 10px',
      fontSize: 18,
      fontWeight: 600,
      color: theme.palette.secondary.light,
      verticalAlign: 'top',
    },
    escrow: {
      marginTop: 30,
      textAlign: 'center',
      backgroundColor: `${Colors.whiteLilac}`,
    },
  });

const useStyles = makeStyles(styles);

type Props = {
  asyncGetEntiretWalletBalances: EmptyAC<typeof GET_ENTIRE_WALLET_BALANCES>;
  entireWalletBalance: entireWalletBalanceObject;
  isInvestDialogOpen: PayloadAC<typeof IS_INVEST_DIALOG_OPEN, boolean>;
};
let role = LocalStorage.get(ROLE) || 'user';
const WalletInfoTotalBalance: React.FC<Props> = ({
  asyncGetEntiretWalletBalances,
  entireWalletBalance: {
    wallet: { balance, pending_balance, escrow_balance, invested_balance },
    currency: { decimals },
  },
  isInvestDialogOpen,
}: Props): JSX.Element => {
  const classes = useStyles();

  useEffect(() => {
    asyncGetEntiretWalletBalances();
  }, []);

  const handleInvestOpen = () => {
    isInvestDialogOpen(true);
  };
  const { getSelectedCoinMarketVal } = useExchangeCurrencyPrices();
  const slcEurVal = getSelectedCoinMarketVal('SLC');

  return (
    <Grid item xs={12}>
      <Typography component="p" className={classes.balanceLabel}>
        WALLET BALANCE
      </Typography>

      <Grid item xs={'auto'} className={classes.eurLine}>
        <h2 className={classes.currencyWrapper}>
          <span className={classes.currencyheading}>EUR</span>
        </h2>
        <Tooltip
          title={<div style={{ fontSize: 16 }}>Your current wallet balance in euro</div>}
          arrow={true}
          placement="bottom-start"
        >
          <Typography component="p" className={classes.balance}>
            <span className={classes.balanceLabel}>TOTAL : </span>{' '}
            {balance ? balanceUtils.calculateValue(balance, decimals, slcEurVal) : '0.00'}{' '}
          </Typography>
        </Tooltip>
        <Tooltip
          title={<div style={{ fontSize: 16 }}>Your pending wallet balance in euro</div>}
          arrow={true}
          placement="bottom-start"
        >
          <Typography component="p" className={classes.pendingBalance}>
            <span className={classes.balanceLabel}> PENDING : </span>
            {pending_balance
              ? balanceUtils.calculateValue(pending_balance, decimals, slcEurVal)
              : '0.00'}{' '}
          </Typography>
        </Tooltip>
      </Grid>

      <Grid item xs={'auto'} className={classes.slcLine}>
        <h2 className={classes.currencyWrapper}>
          <span className={classes.currencyheading}>SLC</span>
        </h2>
        <Tooltip
          title={<div style={{ fontSize: 16 }}>Your current wallet balance in SLC</div>}
          arrow={true}
          placement="bottom-start"
        >
          <Typography component="p" className={classes.balance}>
            <span className={classes.balanceLabel}>TOTAL : </span>{' '}
            {balance ? balanceUtils.formatBalanceToString(balance, decimals, 2, 2) : '0.00'}
          </Typography>
        </Tooltip>
        <Tooltip
          title={<div style={{ fontSize: 16 }}>Your pending wallet balance in SLC</div>}
          arrow={true}
          placement="bottom-start"
        >
          <Typography component="p" className={classes.pendingBalance}>
            <span className={classes.balanceLabel}> PENDING : </span>
            {pending_balance
              ? balanceUtils.formatBalanceToString(pending_balance, decimals, 2, 2)
              : '0.00'}{' '}
          </Typography>
        </Tooltip>
      </Grid>
      <Grid className={classes.escrow}>
        {escrow_balance != 0 ? (
          <Typography component="p">
            Escrow:{' '}
            <span>{balanceUtils.formatBalanceToString(escrow_balance, decimals, 2, 2)} SLC</span>
          </Typography>
        ) : null}
        <Typography component="p">
          In HODL:{' '}
          <span>
            {invested_balance
              ? balanceUtils.formatBalanceToString(invested_balance, decimals, 2, 2)
              : '0.00'}{' '}
            SLC
          </span>
        </Typography>
      </Grid>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <CustomButtonPrimary
          variant="contained"
          size="large"
          className={classes.investButton}
          onClick={handleInvestOpen}
        >
          HODL SLC
        </CustomButtonPrimary>
        <InvestDialog
          totalEscrow={String(escrow_balance)}
          walletBalance={String(balance)}
          walletDecimals={decimals}
        />
      </Grid>
    </Grid>
  );
};

export default WalletInfoTotalBalance;
