import { CircularProgress, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { EmptyAC, PayloadAC } from 'typesafe-actions';
import CustomButtonPrimary from '../../../../../components/CustomButtons/CustomButtonPrimary';
import SacretError from '../../../../../components/SacretError';
import UsersEarningsTable from '../../../../../components/UsersEarningsTable';
import {
  ExportUserEarningsRequest,
  Filter,
  GetPaginatedDataRequest,
  HeaderProps,
} from '../../../../../data/repositories/admin/typedefs';
import {
  CLEAR_ALL_FILTERS,
  EXPORT_USERS_EARNINGS,
  GET_USER_EARNINGS_TABLE_PAGINATED,
} from './state/actions';
import { UserEarningsTableState } from './state/initialState';
import UserEarningsFilterBar from './UserEarningsFilterBar';

type Props = UserEarningsTableState & {
  asyncGetUserEarningsTablePaginated: PayloadAC<
    typeof GET_USER_EARNINGS_TABLE_PAGINATED,
    GetPaginatedDataRequest
  >;
  asyncExportUsersEarning: PayloadAC<typeof EXPORT_USERS_EARNINGS, ExportUserEarningsRequest>;
  inProgress: boolean;
  appliedFilters?: any;
  error: string | null;
  clearAllFilters: EmptyAC<typeof CLEAR_ALL_FILTERS>;
};

const headers: HeaderProps[] = [
  { name: 'Status', align: 'left' },
  { name: 'Type', align: 'left' },
  { name: 'User email', align: 'left' },
  { name: 'Affiliate email', align: 'left' },
  { name: 'Date & Time', align: 'left' },
  { name: 'Amount', align: 'right' },
  { name: 'Value(EUR)', align: 'right' },
];

const UserEarningsTable: React.FC<Props> = (props: Props): JSX.Element => {
  const {
    asyncGetUserEarningsTablePaginated,
    asyncExportUsersEarning,
    inProgress,
    users,
    stats: { totalEarningInfoCount },
    page,
    error,
    appliedFilters,
    clearAllFilters,
    exportInProgress,
  } = props;
  useEffect(() => {
    clearAllFilters();
    asyncGetUserEarningsTablePaginated({ page: 0, perPage: 10 });
  }, []);
  if (error) {
    return <SacretError errorMessage={error} />;
  }

  const exportData = () => {
    const getFilters = (appliedFilters: Filter) => {
      const object: any = {};
      appliedFilters.forEach((value, key) => {
        object[key] = value;
      });
      return object;
    };
    asyncExportUsersEarning({
      page: 0,
      perPage: 10,
      appliedFilters: getFilters(appliedFilters),
    });
  };

  return (
    <div>
      <Grid container justifyContent="space-between">
        <Grid item sm={10}>
          <Grid container>
            <UserEarningsFilterBar />
          </Grid>
        </Grid>

        <Grid item>
          {exportInProgress ? (
            <CircularProgress size={34} color="secondary" />
          ) : (
            <CustomButtonPrimary variant="outlined" size="medium" onClick={exportData}>
              Export
            </CustomButtonPrimary>
          )}
        </Grid>
      </Grid>
      <UsersEarningsTable
        users={users}
        headers={headers}
        totalUsers={totalEarningInfoCount ? totalEarningInfoCount : 0}
        page={page}
        inProgress={inProgress}
        filter={appliedFilters}
      />
    </div>
  );
};

export default UserEarningsTable;
