import { initialState as inviteModal, InviteModalState } from '../InvitesModal/state/initialState';
import {
  initialState as invitesTable,
  MyInvitesTableState,
} from '../MyInvitesTable/state/initialState';

export type InvitesState = {
  invitesTable: MyInvitesTableState;
  inviteModal: InviteModalState;
};

export const initialState = {
  invitesTable,
  inviteModal,
};
