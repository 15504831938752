import { connect } from 'react-redux';
import { AppState } from '../../state/initialState';
import { hasCompletedKYCSelector } from '../HomeContent/Settings/state/selectors';
import { twoFactorAuthEnableSelector } from '../HomeContent/Settings/SettingsTwoFactorAuth/state/selectors';
import { isTwoFactorAuthEnabled } from '../HomeContent/Settings/state/actions';
import { curentHomePageSelector, getCoinPrices } from '../state/common/selectors';
import HomeDrawer from './HomeDrawer';
import { calcIfIsMobileDevice, setDrawerHover, setHomeDrawerMenu } from './state/actions';
import { getDrawerMenuStateSelector } from './state/selectors';

const mapStateToProps = (state: AppState) => {
  const curentHomePage = curentHomePageSelector(state);
  const coinPrices = getCoinPrices(state);
  const drawerState = getDrawerMenuStateSelector(state);
  const hasCompletedKYC = hasCompletedKYCSelector(state);
  const is2FaEnabled = twoFactorAuthEnableSelector(state);

  return {
    curentHomePage,
    coinPrices: coinPrices,
    drawerState,
    hasCompletedKYC,
    is2FaEnabled,
  };
};
const mapDispatchToProps = {
  setHomeDrawerMenu,
  calcIfIsMobileDevice,
  setDrawerHover,
  isTwoFactorAuthEnabled,
};
export default connect(mapStateToProps, mapDispatchToProps)(HomeDrawer);
