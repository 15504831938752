import { isEmpty } from 'lodash';
import { createSelector } from 'reselect';
import { Coin, Wallet } from '../../../../../data/repositories/wallet/typedefs';
import { Colors } from '../../../../../ui/style/colors';
import balanceUtils from '../../../../../utils/balanceUtils';
import { AppState } from './../../../../state/initialState';

/* eslint-disable */
const Big = require('big.js');

const getDashboardState = ({ home: { dashboard } }: AppState) => dashboard;

const getDashboardWalletsState = ({
  home: {
    dashboard: { wallets },
  },
}: AppState) => wallets;

const getComponentState = ({
  home: {
    dashboard: { componentState },
  },
}: AppState) => componentState;

const getDashboardInvestDialogState = ({
  home: {
    dashboard: { investDialogState },
  },
}: AppState) => investDialogState;

const getDashboardCurrenciesSymbols = ({
  home: {
    dashboard: { wallets },
  },
}: AppState) => Object.keys(wallets);

const getDashboardCryptoChartState = ({
  home: {
    dashboard: { chart },
  },
}: AppState) => chart;

const getCommonState = ({ home: { common } }: AppState) => common;

const getTableChartState = ({
  home: {
    dashboard: { tableChart },
  },
}: AppState) => tableChart;

export const getDashboardCurrenciesSymbolsSelector = createSelector(
  getDashboardCurrenciesSymbols,
  currencies => currencies,
);

export const dashboardWalletStateSelector = createSelector(
  getDashboardWalletsState,
  wallets => wallets,
);

export const getCryptoChartsStateSelector = createSelector(
  getDashboardCryptoChartState,
  chart => chart,
);

export const getComponentStateSelector = createSelector(
  getComponentState,
  componentState => componentState,
);

export const getDashboardInvestDialogStateSelector = createSelector(
  getDashboardInvestDialogState,
  investDialogState => investDialogState,
);

export const dashboardTotalBalanceSelector = createSelector(getDashboardWalletsState, wallets => {
  let totalBalance = Big(0);
  Object.entries(wallets).map(([key, value]) => {
    if (value && value.balance && value.price_eur) {
      totalBalance = totalBalance.plus(
        balanceUtils
          .formatBalanceToBigWithDecimals(value.price_eur, value.decimals)
          .times(value.balance),
      );
    }
  });
  return totalBalance;
});

export const getDashboardCoinDecimalsSelector = (currencySymbol: string) => {
  return createSelector(getDashboardWalletsState, wallets => {
    let allWallets = new Map<string, Wallet>(Object.entries(wallets));
    const coin = allWallets.get(currencySymbol);
    return coin && coin.decimals;
  });
};

export const getSLCPriceSelector = createSelector(getDashboardWalletsState, wallets => {
  let allWallets = new Map<string, Wallet>(Object.entries(wallets));
  const slcCoin = allWallets.get('SLC');
  return slcCoin ? slcCoin.price_eur || '0.5' : '0.5';
});

export const getAllCoinsSelector = createSelector(getCommonState, common => common.allCoins);

export const getCoinColorSelector = (symbol: string) => {
  return createSelector(getCommonState, common => {
    let allCoins = new Map<string, Coin>(Object.entries(common.allCoins));
    const coin = allCoins.get(symbol);
    return coin && coin.color;
  });
};

export const expensiveGetWalletForCurrency = (currencySymbol: string) => {
  return createSelector(
    dashboardWalletStateSelector,
    getAllCoinsSelector,
    dashboardTotalBalanceSelector,
    (wallets, allCoins, totalBalance) => {
      const w = new Map<string, Wallet>(Object.entries(wallets));
      const ac = new Map<string, Coin>(Object.entries(allCoins));

      const wallet = w.get(currencySymbol);
      const coin = ac.get(currencySymbol);

      let percentage = Big(0);
      if (wallet && wallet.balance && wallet.price_eur && totalBalance != 0) {
        percentage = Big(
          balanceUtils
            .formatBalanceToBigWithDecimals(wallet.balance, wallet.decimals)
            .times(parseFloat(wallet.price_eur)),
        )
          .div(totalBalance)
          .times(100);
      }
      const wac = {
        ...(wallet
          ? wallet
          : {
              address: 'string',
              aliases: [],
              balance: 'string',
              pending_balance: 'string',
              symbol: 'string',
              name: 'string',
              network: 'string',
              decimals: 18,
              price_eur: '1',
            }),
        ...(coin
          ? coin
          : {
              icon: '',
              color: `${Colors.white}`,
            }),
        percentage: `${percentage}%`,
      };
      return isEmpty(wac)
        ? {
            address: 'string',
            aliases: [],
            balance: 'string',
            pending_balance: 'string',
            symbol: 'string',
            name: 'string',
            network: 'string',
            decimals: 0,
            icon: '',
            color: `${Colors.white}`,
            percentage: '0',
            price_eur: '1',
          }
        : wac;
    },
  );
};

export const expensiveGetWalletWithMetadataBySymbol = (walletSymbol: string) => {
  return createSelector(dashboardWalletStateSelector, getAllCoinsSelector, (wallets, allcoins) => {
    const w = new Map<string, Wallet>(Object.entries(wallets));
    const ac = new Map<string, Coin>(Object.entries(allcoins));
    const wallet = w.get(walletSymbol);
    const coin = ac.get(walletSymbol);
    const wac = {
      ...(wallet
        ? wallet
        : {
            address: 'string',
            aliases: [],
            balance: 'string',
            pending_balance: 'string',
            symbol: 'string',
            name: 'string',
            network: 'string',
            decimals: 18,
            price_eur: '1',
          }),
      ...(coin
        ? coin
        : {
            icon: '',
            color: `${Colors.white}`,
          }),
      ...{ percentage: `0%` },
    };
    return isEmpty(wac)
      ? {
          address: 'string',
          aliases: [],
          balance: 'string',
          pending_balance: 'string',
          symbol: 'string',
          name: 'string',
          network: 'string',
          decimals: 0,
          icon: '',
          color: `${Colors.white}`,
          percentage: '0',
          price_eur: '1',
        }
      : wac;
  });
};

export const getSelectedCoinSelector = createSelector(
  getCommonState,
  common => common.selectedCoin,
);

export const getWalletBySymbolSelectorSelector = createSelector(
  dashboardWalletStateSelector,
  getSelectedCoinSelector,
  (wallets, selectedCoin) => {
    const w: Wallet[] = Object.values(wallets);
    return w.find(wal => {
      if (wal.symbol === selectedCoin) return wal;
    });
  },
);

export const getComponentError = createSelector(
  getComponentState,
  componentState => componentState.error,
);

export const entireWalletBalanceSelector = createSelector(
  getDashboardState,
  wallet => wallet.entireWalletBalance,
);

export const getTableChartStateSelector = createSelector(
  getTableChartState,
  tableChart => tableChart,
);

export const getChartStateSelector = createSelector(getDashboardCryptoChartState, chart => chart);
export const getSelectedCoin = createSelector(
  getDashboardCryptoChartState,
  chart => chart.selectedCoin,
);
