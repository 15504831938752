import { find } from 'lodash';
import { AnyAction } from 'redux';
import { all, put, select, takeEvery } from 'redux-saga/effects';
import LocalStorage from '../../../../../data/LocalStorage';
import apiAccount from '../../../../../data/repositories/account';
import api from '../../../../../data/repositories/wallet';
import {
  Coin,
  CreateWalletForCurrencyResponse,
  GetAllCoinsResponse,
  GetWalletBalanceResponse,
  Wallet,
  WithdrawCurrencyResponse,
  WithdrawEurRequest,
} from '../../../../../data/repositories/wallet/typedefs';
import {
  GET_WALLET_ENDPOINT,
  WALLET_FOR_CURRENCY_ENDPOINT,
  WITHDRAW_CURRENCY_ENDPOINT,
} from '../../../../../utils/constants/api';
import { USER_ID } from '../../../../../utils/constants/localStorageKeys';
import { getUserAccount } from '../../../../state/user/actions';
import {
  clearCommonState,
  getCoinPricesFail,
  getCoinPricesSuccess,
  GET_COIN_PRICES,
  saveDepositWalletAddress,
  saveWithdrawAddress,
  setCoinPricesInProgress,
} from '../../../state/common/actions';
import { getWithdrawAmount } from '../../../state/common/selectors';
import { GET_WALLET_BALANCE as GET_DASHBOARD_WALLET_BALANCE } from '../../Dashboard/state/actions';
import { setInfoDialogFields } from '../../InfoDialog/state/actions';
import { getProfileInfoFail, getProfileInfoSuccess } from '../../Settings/Membership/state/actions';
import {
  asyncBuyWallet,
  asyncCreateRevolutCounterparty,
  asyncDepositTermSaving,
  asyncGetEntiretWalletBalances,
  asyncGetRevolutCounterparty,
  asyncGetTermSavingTypes,
  asyncSwapToSLC,
  asyncWireDepositEur,
  asyncWithdrawEur,
  BUY_WALLET,
  createWalletForCurrency,
  CREATE_REVOLUT_COUNTERPARTY,
  CREATE_REVOLUT_COUNTERPARTY_SUCCESS,
  CREATE_WALLET_FOR_CURRENCY,
  DEPOSIT_TERM_SAVING,
  getWalletBalance,
  getWalletBalanceFail,
  getWalletBalanceSuccess,
  GET_ALL_COINS,
  GET_ENTIRE_WALLET_BALANCES,
  GET_REVOLUT_COUNTERPARTY,
  GET_TERM_SAVING_TYPES,
  GET_WALLET_BALANCE,
  saveAllCoins,
  SET_DEPOSIT_MODAL_STEP,
  SWAP_TO_SLC,
  WIRE_DEPOSIT_EUR,
  withdrawCurrency,
  withdrawCurrencyFail,
  withdrawCurrencySuccess,
  WITHDRAW_CURRENCY,
} from './actions';
import { getRevolutCounterpartyDataSelector, getSelectedCoinSelector } from './selectors';

export function* getWalletBalance$() {
  try {
    const userId = LocalStorage.get(USER_ID);
    const response: GetWalletBalanceResponse = yield api.getWallet(
      GET_WALLET_ENDPOINT(userId ? userId : ''),
    );

    const result = response.reduce(
      (accumulator, wallet) => ({
        ...accumulator,
        [wallet.name === 'Euro' ? 'WALLET_EUR' : wallet.symbol]: wallet,
      }),
      new Map<string, Wallet>(),
    );

    // yield put(saveWalletBalance(result));
    yield put(getWalletBalanceSuccess(result));
  } catch (error) {
    yield put(getWalletBalanceFail(error));
  }
}

export function* createWalletForCurrency$(action: ReturnType<typeof createWalletForCurrency>) {
  try {
    yield put(saveDepositWalletAddress(''));
    const userId = LocalStorage.get(USER_ID);
    const response: CreateWalletForCurrencyResponse = yield api.createWalletForCurrency(
      WALLET_FOR_CURRENCY_ENDPOINT(userId ? userId : '', action.payload.symbol),
    );
    const {
      wallet: { address },
    } = response;

    yield put(saveDepositWalletAddress(address));
  } catch (error) {}
}

export function* getAllCoins$() {
  try {
    const response: GetAllCoinsResponse = api.allCoins;

    // Remove all coins except ETH, SLC, BTC
    const result = response.reduce(
      (accumulator, coin) => ({ ...accumulator, [coin.symbol]: coin }),
      new Map<string, Coin>(),
    );

    yield put(saveAllCoins(result));
  } catch (error) {}
}

export function* getRevolutCounterparty$() {
  try {
    const userId = LocalStorage.get(USER_ID);

    const response = yield api.getRevolutCounterparty(userId || '');
    yield put(saveWithdrawAddress(response.revolut_data.accounts[0]));

    yield put(asyncGetRevolutCounterparty.success(response.revolut_data));
  } catch (error) {
    yield put(asyncGetRevolutCounterparty.failure(error));
  }
}

export function* withdrawCurrency$(action: ReturnType<typeof withdrawCurrency>) {
  try {
    const userId = LocalStorage.get(USER_ID);
    const selectedCoin: string = yield select(getSelectedCoinSelector);

    const response: WithdrawCurrencyResponse = yield api.withdrawCurrency(
      WITHDRAW_CURRENCY_ENDPOINT(userId ? userId : '', selectedCoin),
      action.payload,
    );

    yield put(withdrawCurrencySuccess(response));
    yield put(getWalletBalance());
    yield put(clearCommonState());
  } catch (error) {
    yield put(withdrawCurrencyFail(error));
  }
}

//TODO@all this is the main cause of problems... refactor...
export function* getCoinPrices$() {
  yield put(setCoinPricesInProgress(true));
  try {
    const userId = LocalStorage.get(USER_ID);
    if (userId) {
      const response2 = yield api.getUser(userId);
      yield put(getUserAccount(response2));
    }
    const response = yield api.getCoinPrices();
    yield put(getCoinPricesSuccess(response));
  } catch (error) {
    yield put(getCoinPricesFail(error));
  }
  try {
    const userId = LocalStorage.get(USER_ID);
    const response = yield apiAccount.getProfileInfo(userId || '');

    yield put(getProfileInfoSuccess(response));
  } catch (error) {
    yield put(getProfileInfoFail(error.message));
  }
  yield put(setCoinPricesInProgress(false));
}

export function* getEntireWalletBalance$() {
  try {
    const userId = yield LocalStorage.get(USER_ID);
    if (userId) {
      // const entireBalance = yield api.getEntireWalletBalances(userId);
      const response: GetWalletBalanceResponse = yield api.getWallet(
        GET_WALLET_ENDPOINT(userId ? userId : ''),
      );
      const result = response.reduce(
        (accumulator, wallet) => ({ ...accumulator, [wallet.symbol]: wallet }),
        new Map<string, Wallet>(),
      );
      // yield put(saveWalletBalance(result));
      yield put(getWalletBalanceSuccess(result));
      const slcResponse = find(response, ({ symbol }) => symbol === 'SLC');
      const { decimals, name, network, symbol, ...wallet } = slcResponse;
      yield put(
        asyncGetEntiretWalletBalances.success({
          currency: { decimals, name, network, symbol },
          wallet,
        }),
      );
    } else {
      throw new Error('No user id found!');
    }
  } catch (error) {
    yield put(asyncGetEntiretWalletBalances.failure(error));
  }
}

export function* getTermSavingTypes$() {
  try {
    const accountId = yield LocalStorage.get(USER_ID);
    const response = yield api.getTermSavingTypes(accountId);

    yield put(asyncGetTermSavingTypes.success(response));
  } catch (error) {
    yield put(asyncGetTermSavingTypes.failure(error));
  }
}

export function* depositTermSaving$(action: ReturnType<typeof asyncDepositTermSaving.request>) {
  try {
    const accountId = yield LocalStorage.get(USER_ID);
    const { amount, term_saving_type_name, balance_status } = action.payload;

    yield api.depositTermSaving(accountId, {
      balance_status,
      amount,
      term_saving_type_name,
    });
    yield put({ type: GET_ENTIRE_WALLET_BALANCES });
    yield put(asyncDepositTermSaving.success());
  } catch (error) {
    if (error.response.status == 409) {
      yield put(asyncDepositTermSaving.failure('Insufficient funds'));
    } else {
      yield put(asyncDepositTermSaving.failure('Something went wrong'));
    }
  }
}

export function* swapToSLC$() {
  try {
    const accountId = yield LocalStorage.get(USER_ID);
    yield api.swapSLTtoSLC(accountId);

    yield put(setInfoDialogFields.success('Successfully performed action!'));
    yield put(asyncSwapToSLC.success());

    const root = document.getElementById('root');
    root && root.classList.remove('lock');

    //Refresh wallet balances
    yield put({ type: GET_DASHBOARD_WALLET_BALANCE });
    yield put(setInfoDialogFields.request({ showDialogContent: false, hideCloseButton: false }));
  } catch (error) {
    yield put(setInfoDialogFields.failure('Converting SLT to SLC failed!'));
    yield put(asyncSwapToSLC.failure(error));
    yield put(setInfoDialogFields.request({ hideCloseButton: true }));
  }
}

export function* asyncWireDepositEur$(action: ReturnType<typeof asyncWireDepositEur.request>) {
  try {
    const accountId = yield LocalStorage.get(USER_ID);
    const { eur_price, ...rest } = action.payload;

    const order = yield api.createDepositOrder(accountId, {
      eur_price: eur_price ? eur_price * 100 : 0,
      ...rest,
    });

    yield put({ type: SET_DEPOSIT_MODAL_STEP, payload: 1 });
    yield put(asyncWireDepositEur.success(order));
  } catch (error) {
    yield put(asyncWireDepositEur.failure(error.message));
  }
}

/**Create Revolut counterparty to get data for withdrawEur request */
export function* createRevolutCounterparty$(
  action: ReturnType<typeof asyncCreateRevolutCounterparty.request>,
) {
  try {
    const userId = LocalStorage.get(USER_ID);
    const counterpartyAccount = yield select(getRevolutCounterpartyDataSelector);
    const { code } = action.payload;

    let response: WithdrawEurRequest;
    if (counterpartyAccount.id) {
      response = yield api.updateRevolutCounterparty(userId || '', action.payload);
    } else {
      response = yield api.createRevolutCounterparty(userId || '', {
        ...action.payload,
      });
    }

    yield put(asyncCreateRevolutCounterparty.success({ ...response, code }));
  } catch (error) {
    yield put(withdrawCurrencyFail(error));
  }
}

export function* withdrawEur$() {
  try {
    const userId = LocalStorage.get(USER_ID);
    const amount = yield select(getWithdrawAmount);
    const counterpartyAccount = yield select(getRevolutCounterpartyDataSelector);

    const response = yield api.withdrawEur(userId || '', {
      amount_in_eurocents: amount * 100,
      code: counterpartyAccount.code,
    });
    yield put(asyncWithdrawEur.success(response));
  } catch (error) {
    yield put(withdrawCurrencyFail(error));
  }
}

export function* buyWallet$(action: AnyAction) {
  try {
    const accountId = yield LocalStorage.get(USER_ID);
    yield api.buyWallet(accountId || '', action.payload);

    yield put(asyncBuyWallet.success());
  } catch (error) {
    yield put(asyncBuyWallet.failure(error));
  }
}

export default function* () {
  yield all([
    takeEvery(GET_WALLET_BALANCE, getWalletBalance$),
    takeEvery(CREATE_WALLET_FOR_CURRENCY, createWalletForCurrency$),
    takeEvery(GET_ALL_COINS, getAllCoins$),
    takeEvery(WITHDRAW_CURRENCY, withdrawCurrency$),
    takeEvery(GET_COIN_PRICES, getCoinPrices$),
    takeEvery(GET_ENTIRE_WALLET_BALANCES, getEntireWalletBalance$),
    takeEvery(GET_TERM_SAVING_TYPES, getTermSavingTypes$),
    takeEvery(DEPOSIT_TERM_SAVING, depositTermSaving$),
    takeEvery(SWAP_TO_SLC, swapToSLC$),
    takeEvery(GET_REVOLUT_COUNTERPARTY, getRevolutCounterparty$),
    takeEvery(CREATE_REVOLUT_COUNTERPARTY, createRevolutCounterparty$),
    takeEvery(CREATE_REVOLUT_COUNTERPARTY_SUCCESS, withdrawEur$),
    takeEvery(WIRE_DEPOSIT_EUR, asyncWireDepositEur$),
    takeEvery(BUY_WALLET, buyWallet$),
  ]);
}
