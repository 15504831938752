import { connect } from 'react-redux';
import { AppState } from '../../../../../state/initialState';
import { isTwoFactorAuthEnabled } from '../../../../state/actions';
import { getWithdrawAddress, getWithdrawAmount } from '../../../../state/common/selectors';
import { twoFactorAuthEnableSelector } from '../../../Settings/state/selectors';
import {
  asyncCreateRevolutCounterparty,
  clearWithdrawState,
  openWithdrawDialog,
  withdrawCurrency,
} from '../../state/actions';
import {
  getSelectedCoinSelector,
  getWalletBySymbolSelectorSelector,
  stepSelector,
  withdrawErrorSelector,
  withdrawInProgressSelector,
} from '../../state/selectors';
import WithdrawCoinsConfirmDialog from './WithdrawCoinsConfirmDialog';

const mapDispatchToProps = {
  openWithdrawDialog: openWithdrawDialog,
  isTwoFactorAuthEnabled: isTwoFactorAuthEnabled,
  withdrawCurrency: withdrawCurrency,
  clearWithdrawState: clearWithdrawState,
  asyncCreateRevolutCounterparty: asyncCreateRevolutCounterparty.request,
};
const mapStateToProps = (state: AppState) => {
  const enabled = twoFactorAuthEnableSelector(state);
  const amount = getWithdrawAmount(state);
  const address = getWithdrawAddress(state);
  const step = stepSelector(state);
  const selectedCoin = getSelectedCoinSelector(state);
  const wallet = getWalletBySymbolSelectorSelector(state);
  const withdrawInProgress = withdrawInProgressSelector(state);
  const withdrawError = withdrawErrorSelector(state);

  return {
    enabled,
    amount,
    address,
    wallet,
    step,
    selectedCoin,
    withdrawError,
    withdrawInProgress,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawCoinsConfirmDialog);
