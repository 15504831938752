export type Invite = {
  account_id: string;
  created_at: string;
  id: string;
  invite_email: string;
  last_emailed_at: string;
  status: string;
  updated_at: {
    Time: string;
    Valid: boolean;
  };
};

export type MyInvitesTableState = {
  invites: Invite[] | null;
  count: number;
  inProgress: boolean;
  fail: boolean;
  error: Error | null;
  page: number;
  perPage: number;
  appliedFilters: Map<string, string[]>;
  totalPending: number | undefined;
  totalAccepted: number | undefined;
  totalInvited: number | undefined;
};

export const initialState: MyInvitesTableState = {
  invites: null,
  count: 0,
  inProgress: false,
  fail: false,
  error: null,
  appliedFilters: new Map<string, string[]>(),
  page: 0,
  perPage: 10,
  totalInvited: 0,
  totalPending: 0,
  totalAccepted: 0,
};
