/* eslint-disable */
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import React, { useState } from 'react';
import DateRangePicker, { OnSelectCallbackParam } from 'react-daterange-picker';
import 'react-daterange-picker/dist/css/react-calendar.css';
import '../SacretDaterangePicker/style.css';

type Props = {
  onChange: Function;
  stateFilterValue: string[] | undefined;
};
const SacretDaterangePicker = ({ onChange, stateFilterValue }: Props) => {
  const moment = extendMoment(Moment);
  const today = moment();
  const stateFilterValueMomentRange = stateFilterValue
    ? moment.range(
        moment(stateFilterValue[0], 'DD/MM/YYYY'),
        moment(stateFilterValue[1], 'DD/MM/YYYY'),
      )
    : null;
  const [value, setValue] = useState(
    stateFilterValueMomentRange ? stateFilterValueMomentRange : undefined,
  );
  const onSelect = (value: any) => {
    onChange([value.start.format('DD/MM/YYYY'), value.end.format('DD/MM/YYYY')]);

    setValue(value);
  };

  return (
    <DateRangePicker
      value={value}
      onSelect={(value: OnSelectCallbackParam) => onSelect(value)}
      singleDateRange={true}
      maximumDate={new Date()}
      firstOfWeek={1}
    />
  );
};

export default SacretDaterangePicker;
