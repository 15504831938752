import { Grid } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { EmptyAC, PayloadAC } from 'typesafe-actions';
import InvitesFilterBar from './InvitesFilterBar';
import InvitesInfo from './InvitesInfo';
import MyInvitesTable from './MyInvitesTable';

const styles = () =>
  createStyles({
    navTabs: {
      marginBottom: 30,
    },
    root: {
      margin: 24,
      marginTop: 30,
    },
  });

type Props = {
  setCurrentHomePage: PayloadAC<'home/set_current_home_page', string>;
  clearAllFilters: EmptyAC<'invites/clear_all_filters'>;
};

const Invites: React.FC<Props> = ({ setCurrentHomePage, clearAllFilters }: Props): JSX.Element => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  useEffect(() => {
    clearAllFilters();
    setCurrentHomePage('invites');
  }, []);
  return (
    <Grid item className={classes.root}>
      <InvitesInfo />
      <InvitesFilterBar />
      <MyInvitesTable />
    </Grid>
  );
};

export default Invites;
