/* eslint-disable */
import { createAsyncAction } from 'typesafe-actions';
import {
  GetAffiliateCodeResponse,
  GetRefferalsResponse,
} from '../../../../../../data/repositories/affiliate/typedefs';
import { affiliateIncomeObject } from './initialState';

export const GET_AFFILIATE_CODE = 'affiliate/get_affiliate_code';
export const GET_AFFILIATE_CODE_SUCCESS = 'affiliate/get_affiliate_code_success';
export const GET_AFFILIATE_CODE_FAIL = 'affiliate/get_affiliate_code_fail';

export const GET_REFERRALS = 'affiliate/get_referrals';
export const GET_REFERRALS_SUCCESS = 'affiliate/get_referrals_success';
export const GET_REFERRALS_FAIL = 'affiliate/get_referrals_fail';

export const GET_INCOME_BALANCES = 'affiliate/get_income_balances';
export const GET_INCOME_BALANCES_SUCCESS = 'affiliate/get_income_balances_success';
export const GET_INCOME_BALANCES_FAIL = 'affiliate/get_income_balances_fail';

export const asyncGetAffiliateCode = createAsyncAction(
  GET_AFFILIATE_CODE,
  GET_AFFILIATE_CODE_SUCCESS,
  GET_AFFILIATE_CODE_FAIL,
)<undefined, GetAffiliateCodeResponse, Error>();

export const asyncGetReferrals = createAsyncAction(
  GET_REFERRALS,
  GET_REFERRALS_SUCCESS,
  GET_REFERRALS_FAIL,
)<undefined, GetRefferalsResponse, Error>();

export const asyncGetIncomeBalances = createAsyncAction(
  GET_INCOME_BALANCES,
  GET_INCOME_BALANCES_SUCCESS,
  GET_INCOME_BALANCES_FAIL,
)<undefined, affiliateIncomeObject, Error>();
