import { CircularProgress, Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { EmptyAC, PayloadAC } from 'typesafe-actions';
import CustomButtonSecondary from '../../../../components/CustomButtons/CustomButtonSecondary';
import { NavTab, NavTabContent, NavTabs } from '../../../../components/NavTabs';
import { Coin } from '../../../../data/repositories/wallet/typedefs';
import { roles } from '../../../../utils/constants/roles';
import { isUserMember } from '../../../../utils/helper/utils';
import AffiliatePremiumTable from './AffiliatePremiumTable';
import { TransactionsState } from './state/initialState';
import TransactionsFilterBar from './TransactionsFilterBar';
import WalletTransfersFilterBar from './TransfersFilterBar';
import TransfersTable from './TransfersTable';

const styles = (theme: Theme) =>
  createStyles({
    transactions: {
      margin: '30px 24px',
    },
    tabPanel: {
      marginBottom: 30,
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
    circularProgress: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%,-50%)',
    },

    exportButton: {
      marginLeft: '10px',
    },
    exportLoaderWrappers: {
      width: '230px',
      height: '36px',
      display: 'inline-block',
      verticalAlign: 'middle',
      textAlign: 'center',
    },
    exportButtonWrapper: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        marginTop: 20,
        flexDirection: 'column',
        '& button': {
          marginTop: 10,
        },
      },
    },
  });

const useStyles = makeStyles(styles);
type Props = {
  setCurrentHomePage: PayloadAC<'home/set_current_home_page', string>;
  getAllCoins: EmptyAC<'wallet/get_all_coins'>;
  getTransfers: EmptyAC<'transactions/get_transfers'>;
  filteredTransactionsIds: string[];
  allCoins: Map<string, Coin>;
  getWalletBalance: EmptyAC<'wallet/get_wallet_balance'>;
  walletsSymbols: string[];
  transactionsIds: string[];
  transactionsState: TransactionsState;
  getTransferSetLoading: PayloadAC<'transactions/get_transfer_set_loading', boolean>;
  asyncExportUsers: EmptyAC<'csv-export/users'>;
  asyncExportTransactions: EmptyAC<'csv-export/transactions'>;
  user: any;
  location: any;
  membershipLevel?: any;
  clearAllFilters: EmptyAC<'transactions/clear_all_filters'>;
  hasCompletedKYC: boolean;
};

const Transactions: React.FC<Props> = ({
  getWalletBalance,
  walletsSymbols,
  setCurrentHomePage,
  getAllCoins,
  filteredTransactionsIds,
  getTransfers,
  allCoins,
  transactionsState,
  getTransferSetLoading,
  asyncExportUsers,
  asyncExportTransactions,
  user,
  location,
  membershipLevel,
  clearAllFilters,
  hasCompletedKYC,
}: Props): JSX.Element => {
  const isMember = isUserMember(membershipLevel);
  const classes = useStyles({ isUserMember: isMember });
  const { inProgress, exportInProgress } = transactionsState;
  const [activeTab, setActiveTab] = useState((location.state && location.state.activeTab) || 0);
  useEffect(() => {
    allCoins.size === 0 && getAllCoins();
    walletsSymbols.length === 0 && getWalletBalance();
    filteredTransactionsIds.length === 0 && getTransferSetLoading(true) && getTransfers();

    setCurrentHomePage('transactions');
  }, []);

  const handleTabChange = (event: React.ChangeEvent<Record<string, unknown>>, newValue: number) => {
    clearAllFilters();
    setActiveTab(newValue);
  };

  return (
    <div>
      {inProgress ? (
        <div className={classes.circularProgress}>
          <CircularProgress color="secondary" size={60} />
        </div>
      ) : (
        <div className={classes.transactions}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.tabPanel}
          >
            <Grid item>
              <NavTabs
                variant="scrollable"
                value={activeTab}
                onChange={handleTabChange}
                aria-label="Transactions"
              >
                <NavTab label="SLC transactions" />
                <NavTab
                  label="Wallet transfers"
                  tooltip={
                    !hasCompletedKYC
                      ? {
                          variant: 'kyc',
                          walletType: 'Revolut',
                        }
                      : undefined
                  }
                  disabled={!hasCompletedKYC}
                />
              </NavTabs>
            </Grid>
            {roles.isOwner(user.roles) && (
              <Grid item className={classes.exportButtonWrapper}>
                Export:
                {exportInProgress ? (
                  <div className={classes.exportLoaderWrappers}>
                    <CircularProgress size={34} color="secondary" />
                  </div>
                ) : (
                  <div>
                    <CustomButtonSecondary
                      variant="outlined"
                      size="medium"
                      className={classes.exportButton}
                      onClick={() => asyncExportUsers()}
                    >
                      Users
                    </CustomButtonSecondary>
                    <CustomButtonSecondary
                      variant="outlined"
                      size="medium"
                      className={classes.exportButton}
                      onClick={() => asyncExportTransactions()}
                    >
                      Transactions
                    </CustomButtonSecondary>
                  </div>
                )}
              </Grid>
            )}
          </Grid>
          <NavTabContent value={activeTab} index={0}>
            <TransactionsFilterBar />
            <AffiliatePremiumTable />
          </NavTabContent>
          <NavTabContent value={activeTab} index={1}>
            <WalletTransfersFilterBar />
            <TransfersTable />
          </NavTabContent>
        </div>
      )}
    </div>
  );
};

export default Transactions;
