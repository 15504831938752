import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import { InviteData } from './initialState';

export const SEND_INVITES = 'invites/send_invites';
export const SEND_INVITES_SUCCESS = 'invites/send_invites/success';
export const SEND_INVITES_FAIL = 'invites/send_invites/fail';

export const asyncSendInvites = createAsyncAction(
  SEND_INVITES,
  SEND_INVITES_SUCCESS,
  SEND_INVITES_FAIL,
)<InviteData, any, Error>();

export const RETURN_TO_DEFAULT_STATE = 'invites/return_to_default_state';
export const returnTodefaultState = createStandardAction(RETURN_TO_DEFAULT_STATE)<InviteData>();
