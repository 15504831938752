import { createSelector } from 'reselect';
import { AppState } from '../../../../../state/initialState';

const getFeeSettingsState = ({
  home: {
    settings: { feeState },
  },
}: AppState) => feeState;

export const getFeeSettingsStateSelector = createSelector(
  getFeeSettingsState,
  feeState => feeState,
);
