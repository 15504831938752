import {
  AffiliateInfoState,
  initialState as affiliateInfo,
} from '../AffiliateInfo/state/initialState';
import {
  AffiliateTableState,
  initialState as affiliateTable,
} from '../AffiliateTable/state/initialState';
import {
  AffiliateMerchantsTableState,
  initialState as affiliateMerchantsTable,
} from '../AffiliateMerchantsTable/state/initialState';
import {
  AffiliatePremiumTableState,
  initialState as affiliatePremiumTable,
} from '../AffiliatePremiumTable/state/initialState';

export type AffiliateState = {
  affiliateInfo: AffiliateInfoState;
  affiliateTable: AffiliateTableState;
  affiliateMerchantsTable: AffiliateMerchantsTableState;
  affiliatePremiumTable: AffiliatePremiumTableState;
};

export const initialState = {
  affiliateInfo,
  affiliateTable,
  affiliateMerchantsTable,
  affiliatePremiumTable,
};
