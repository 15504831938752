import { Button, Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { EmptyAC } from 'typesafe-actions';
import { Colors } from '../../../../../ui/style/colors';
import { SUPPORT_EMAIL } from '../../../../../utils/constants/info';
import { REDIRECT_TO_KYC } from '../../../../../utils/constants/routes';
import { GET_USER_INFO } from '../../../../state/actions';
import { InfoDialogState } from '../state/initialState';
import { ACCESS_TOKEN } from '../../../../../utils/constants/localStorageKeys';
import LocalStorage from '../../../../../data/LocalStorage';
import { LOGOUT_ENDPOINT } from '../../../../../utils/constants/api';
type Props = {
  infoDialogState: InfoDialogState;
  getUserInfo: EmptyAC<typeof GET_USER_INFO>;
};

export const styles = (theme: Theme) =>
  createStyles({
    wrapper: {
      width: '100%',
      height: '100%',

      '@media(max-width:600px)': {
        padding: '0 20px',
      },
    },
    label: {
      fontWeight: 'bold',
      fontSize: '1.2rem',
      color: theme.palette.primary.light,
    },
    switched: {
      color: `${Colors.mediumTurquoise}`,
    },
    textLight: {
      color: theme.palette.primary.light,
      margin: '30px 0 20px 0',
      fontWeight: 'bold',
    },
    buttonSuccess: {
      backgroundColor: `${Colors.havelockBlue}`,
      color: `${Colors.white}`,
      '&:hover ': {
        opacity: 0.9,
        backgroundColor: `${Colors.havelockBlue}`,
      },
    },
    buttonsWrapper: {
      alignSelf: 'flex-end',
      padding: '10px',
    },
    contentWrapper: {
      margin: '0 30px',
      textAlign: 'center',
      '@media(max-width: 600px)': {
        margin: 0,
      },
    },
    content: {
      fontSize: '1rem',
      marginBottom: 10,
      '@media(max-width: 600px)': {
        fontSize: '0.75rem',
      },
    },
    logoutBtn: {
      color: `${Colors.mediumTurquoise}`,
      backgroundColor: `${Colors.white}`,
    },
  });
const useStyles = makeStyles(styles);

const KycRedirectionDialog: React.FC<Props> = () => {
  const classes = useStyles();
  const token = LocalStorage.get(ACCESS_TOKEN);

  return (
    <Grid id="KycRedirectionDialog" container alignItems="center" className={classes.wrapper}>
      <Grid container className={classes.contentWrapper}>
        <Grid item xs={12} className={classes.content}>
          Congratulations! You have become a valuable member of the SLAFF platform! Please complete
          KYC to start using BitGo and Revolut wallet. If you need any assistance, please contact us
          at{' '}
          <address>
            <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
          </address>
        </Grid>
      </Grid>

      <Grid container justifyContent="space-evenly" className={classes.buttonsWrapper}>
        <Grid item xs={12} sm={5}>
          <Button
            onClick={() => {
              LocalStorage.clear();
              window.location.href = `${process.env.REACT_APP_SL_GROUP_HOST}${LOGOUT_ENDPOINT}?from_url=${window.location.origin}`;

            }}
            variant="contained"
            size="large"
            fullWidth
            className={classes.logoutBtn}
          >
            Logout
          </Button>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Button
  onClick={() =>
    (window.location.href = `${REDIRECT_TO_KYC}?token=${token}&from_url=${window.location.origin}`)
    }            className={classes.buttonSuccess}
            variant="contained"
            size="large"
            fullWidth
          >
            Let’s get started
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default KycRedirectionDialog;
