import { connect } from 'react-redux';
import { AppState } from '../../../../../../state/initialState';
import { disableTwoFactorAuth } from '../../state/actions';
import { twoFactorAuthStateSelector } from '../../state/selectors';
import Settings2faCodeDisableDialog from './Settings2faCodeDisableDialog';

const mapDispatchToProps = {
  disableTwoFactorAuth: disableTwoFactorAuth,
};
const mapStateToProps = (state: AppState) => ({ ...twoFactorAuthStateSelector(state) });

export default connect(mapStateToProps, mapDispatchToProps)(Settings2faCodeDisableDialog);
