import { createSelector } from 'reselect';
import { AppState } from '../../../state/initialState';

export const getCommonState = ({ home: { common } }: AppState) => common;

export const curentHomePageSelector = createSelector(
  getCommonState,
  common => common.currentHomePage,
);

export const getCoinsInProgress = createSelector(
  getCommonState,
  common => common.coinPricesInProgress,
);

export const getSelectedCoin = createSelector(getCommonState, common => common.selectedCoin);

export const getDepositWalletAddressSelector = createSelector(
  getCommonState,
  common => common.depositWalletAddress,
);

export const getWithdrawAddress = createSelector(
  getCommonState,
  commonState => commonState.withdrawAddress,
);

export const getWithdrawAmount = createSelector(
  getCommonState,
  commonState => commonState.withdrawAmount,
);

export const getCoinPrices = createSelector(getCommonState, commonState => commonState.coinPrices);

export const getRefreshTokenState = createSelector(getCommonState, common => common.refreshToken);
