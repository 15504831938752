import { createReducer } from 'typesafe-actions';
import { handleMinMaxRange } from '../../../../../../utils/helper/utils';
import {
  EXPORT_ALL_TRANSACTIONS,
  EXPORT_ALL_TRANSACTIONS_FAIL,
  EXPORT_ALL_TRANSACTIONS_SUCCESS,
  EXPORT_WALLET_TRANSFERS,
  EXPORT_WALLET_TRANSFERS_FAIL,
  EXPORT_WALLET_TRANSFERS_SUCCESS,
  GET_ALL_TRANSACTIONS_PAGINATED,
  GET_ALL_TRANSACTIONS_PAGINATED_FAILED,
  GET_ALL_TRANSACTIONS_PAGINATED_SUCCESS,
  GET_ALL_TRANSACTIONS_STATS,
  GET_ALL_TRANSACTIONS_STATS_FAIL,
  GET_ALL_TRANSACTIONS_STATS_SUCCESS,
  GET_WALLET_TRANSFERS_PAGINATED,
  GET_WALLET_TRANSFERS_PAGINATED_FAILED,
  GET_WALLET_TRANSFERS_PAGINATED_SUCCESS,
  SET_ALL_TRANSACTIONS_TABLE_PAGE,
  SET_TRANSACTION_HISTORY_ACTIVE_TAB,
  SET_TRANSACTION_HISTORY_SELETCED_USER,
  SET_WALLET_TRANSFERS_TABLE,
  USER_TRANSACTION_HISTORY_CLEAR_ALL_FILTERS,
  USER_TRANSACTION_HISTORY_TOGGLE_FILTER,
} from './actions';
import { initialState } from './initialState';

export default createReducer(initialState, {
  [SET_TRANSACTION_HISTORY_ACTIVE_TAB]: (state, { payload }) => ({
    ...state,
    activeTab: payload,
  }),
  [SET_TRANSACTION_HISTORY_SELETCED_USER]: (state, { payload }) => ({
    ...state,
    selectedUser: payload,
  }),
  [GET_ALL_TRANSACTIONS_STATS]: state => ({
    ...state,
    allTransactions: {
      ...state.allTransactions,
      inProgress: true,
    },
  }),
  [GET_ALL_TRANSACTIONS_STATS_SUCCESS]: (state, { payload }) => ({
    ...state,
    allTransactions: {
      ...state.allTransactions,
      stats: payload,
      inProgress: false,
      fail: false,
      error: null,
    },
  }),
  [GET_ALL_TRANSACTIONS_STATS_FAIL]: (state, { payload }) => ({
    ...state,
    allTransactions: {
      ...state.allTransactions,
      inProgress: false,
      fail: true,
      error: payload,
    },
  }),
  [GET_ALL_TRANSACTIONS_PAGINATED]: state => ({
    ...state,
    allTransactions: {
      ...state.allTransactions,
      inProgress: true,
      fail: false,
    },
  }),
  [GET_ALL_TRANSACTIONS_PAGINATED_SUCCESS]: (state, { payload }) => ({
    ...state,
    allTransactions: {
      ...state.allTransactions,
      referrals: payload,
      inProgress: false,
      fail: false,
      error: null,
    },
  }),
  [GET_ALL_TRANSACTIONS_PAGINATED_FAILED]: (state, { payload }) => ({
    ...state,
    allTransactions: {
      ...state.allTransactions,
      inProgress: false,
      fail: true,
      error: payload,
    },
  }),
  [SET_ALL_TRANSACTIONS_TABLE_PAGE]: (state, { payload }) => ({
    ...state,
    allTransactions: {
      ...state.allTransactions,
      page: payload,
    },
  }),
  [USER_TRANSACTION_HISTORY_TOGGLE_FILTER]: (state, { payload }) => {
    let objectRange: any = {};
    if (payload.filterType === 'valueRange') {
      objectRange = handleMinMaxRange(state, payload);
      if (!objectRange['maxValue'] && !objectRange['minValue']) {
        payload.value = undefined;
      }
    }

    return {
      ...state,
      appliedFilters: {
        ...state.appliedFilters,
        [payload.filterType]: payload.value,
        ...objectRange,
      },
    };
  },
  [USER_TRANSACTION_HISTORY_CLEAR_ALL_FILTERS]: state => ({
    ...state,
    appliedFilters: new Map(),
  }),
  [EXPORT_ALL_TRANSACTIONS]: state => ({
    ...state,
    exportInProgress: true,
  }),
  [EXPORT_ALL_TRANSACTIONS_SUCCESS]: state => ({
    ...state,
    exportInProgress: false,
    allTransactions: {
      ...state.allTransactions,
      error: null,
    },
  }),
  [EXPORT_ALL_TRANSACTIONS_FAIL]: (state, { payload }) => ({
    ...state,
    exportInProgress: false,
    allTransactions: {
      ...state.allTransactions,
      error: payload,
    },
  }),
  [SET_WALLET_TRANSFERS_TABLE]: (state, { payload }) => ({
    ...state,
    walletTransfers: {
      ...state.walletTransfers,
      page: payload,
    },
  }),

  [GET_WALLET_TRANSFERS_PAGINATED]: state => ({
    ...state,
    walletTransfers: {
      ...state.walletTransfers,
      inProgress: true,
      error: null,
    },
  }),

  [GET_WALLET_TRANSFERS_PAGINATED_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      walletTransfers: {
        ...state.walletTransfers,
        inProgress: false,
        totalRows: payload.bitgo_tx_count,
        totalSum: { ...payload.bitgo_tx_sums },
        walletTransfers: { ...payload.bitgo_tx_logs },
        error: null,
      },
    };
  },

  [GET_WALLET_TRANSFERS_PAGINATED_FAILED]: (state, { payload }) => ({
    ...state,
    walletTransfers: {
      ...state.walletTransfers,
      inProgress: false,
      error: payload,
    },
  }),
  [EXPORT_WALLET_TRANSFERS]: state => ({
    ...state,
    exportInProgress: true,
  }),
  [EXPORT_WALLET_TRANSFERS_SUCCESS]: state => ({
    ...state,
    exportInProgress: false,
    walletTransfers: {
      ...state.walletTransfers,
      error: null,
    },
  }),
  [EXPORT_WALLET_TRANSFERS_FAIL]: (state, { payload }) => ({
    ...state,
    exportInProgress: false,
    walletTransfers: {
      ...state.walletTransfers,
      error: payload,
    },
  }),
});
