import { connect } from 'react-redux';
import { AppState } from '../../../../state/initialState';
import SettingsChangePassword from './SettingsChangePassword';
import {
  changePassword,
  changePasswordClearState,
  changePasswordFail,
  changePasswordSuccess,
} from './state/actions';
import { changePasswordStateSelector } from './state/selectors';

const mapDispatchToProps = {
  changePassword: changePassword,
  changePasswordSuccess: changePasswordSuccess,
  changePasswordFail: changePasswordFail,
  changePasswordClearState: changePasswordClearState,
};

const mapStateToProps = (state: AppState) => ({ ...changePasswordStateSelector(state) });

export default connect(mapStateToProps, mapDispatchToProps)(SettingsChangePassword);
