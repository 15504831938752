import { Cycle, Referral } from '../../../../../../data/repositories/affiliate/typedefs';

export type PremiumReferralStats = {
  totalReferrals: number | null;
  directReferrals: number | null;
};

export type AffiliatePremiumTableState = {
  referrals: Map<string, Referral> | null;
  inProgress: boolean;
  fail: boolean;
  error: Error | null;
  stats: PremiumReferralStats;
  page: number;
  perPage: number;
  cycles: Cycle[];
  filter?: any;
};

export const initialState: AffiliatePremiumTableState = {
  referrals: null,
  inProgress: false,
  fail: false,
  error: null,
  page: 0,
  perPage: 10,
  cycles: [],
  stats: {
    totalReferrals: null,
    directReferrals: null,
  },
};
