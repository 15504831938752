import { all, put, takeEvery } from 'redux-saga/effects';
import LocalStorage from '../../../../../../data/LocalStorage';
import api from '../../../../../../data/repositories/invites';
import { USER_ID } from '../../../../../../utils/constants/localStorageKeys';
import { asyncSendInvites, SEND_INVITES } from './actions';

export function* asyncSendInvites$(action: ReturnType<typeof asyncSendInvites.request>) {
  try {
    const accountId = yield LocalStorage.get(USER_ID);
    const order = yield api.sendInvites(accountId, action.payload);

    yield put(asyncSendInvites.success(order));
  } catch (error) {
    yield put(asyncSendInvites.failure(error));
  }
}

export default function* () {
  yield all([takeEvery(SEND_INVITES, asyncSendInvites$)]);
}
