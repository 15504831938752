import { Button, createStyles, Grid, makeStyles } from '@material-ui/core';
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace';
import clsx from 'clsx';
import { Form, Formik, FormikProps } from 'formik';
import React, { FC } from 'react';
import { connect, useDispatch } from 'react-redux';
import { PayloadAC } from 'typesafe-actions';
import * as yup from 'yup';
import CustomButtonPrimary from '../../../../../components/CustomButtons/CustomButtonPrimary';
import { Colors } from '../../../../../ui/style/colors';
import { AppState } from '../../../../state/initialState';
import { investCurrencySaveState, INVEST_CURRENCY_SAVE_STATE } from './state/actions';
import { ProductCurrencyRequestState, ProductCurrencyState } from './state/initialState';
import { getInvestCurrencySelector } from './state/selectors';
import useInvestCurrencyState from './useInvestCurrencyState';

const styles = createStyles({
  button: {
    boxSizing: 'border-box',
    fontSize: 16,
    letterSpacing: 1,
    height: 48,
    padding: 0,
    position: 'relative',
    textTransform: 'none',
    marginBottom: 28,
  },
  activeButton: {
    fontWeight: 600,
  },
  backButton: {
    background: 'none',
    border: 'none',
    fontSize: 14,
    textTransform: 'none',
    padding: 0,
    marginBottom: 20,
    height: 19,
  },
  buttonsContainer: {
    textAlign: 'center',
  },
});

const useStyles = makeStyles(styles);

export const useClasses = makeStyles(
  createStyles({
    inputFields: {
      marginTop: '10px',
    },
    labels: {
      marginTop: '40px',
      color: `${Colors.wildBlueYonder}`,
    },
    topLabel: {
      color: `${Colors.wildBlueYonder}`,
    },
    linkGroup: {
      marginTop: '40px',
    },
    linkText: {
      textDecoration: 'none',
      fontWeight: 'bold',
      textAlign: 'center',
    },
    linkLabel: {
      fontWeight: 'bold',
      color: `${Colors.wildBlueYonder}`,
      textAlign: 'center',
    },
    inputError: {
      color: `${Colors.red}`,
      marginTop: '5px',
    },
  }),
);

export type Props = ProductCurrencyState & {
  initValues: ProductCurrencyRequestState;
  submitText: string;
  backBtnText?: string;
  sellBtnText?: string;
  isBtnDisabled?: boolean;
  validationSchema?: {
    firstName?: yup.StringSchema<string>;
    lastName?: yup.StringSchema<string>;
    creditNumber?: yup.NumberSchema<number>;
    expDate?: yup.NumberSchema<number>;
    cvc?: yup.NumberSchema<number>;
    amount?: yup.NumberSchema<number>;
    get?: yup.NumberSchema<number>;
    eth_address?: yup.StringSchema<string>;
    transaction_hash?: yup.StringSchema<string>;
  };
  onChange?: void;
  onInvestCurrencyStep: PayloadAC<'invest/save_state', any>;
};

type StepFormProps = FormikProps<ProductCurrencyRequestState> & {
  exchangeCurrency?: ProductCurrencyState;
};

const withInvestCurrencyStep = (StepForm: FC<StepFormProps>) => {
  const InvestCurrencyStep: FC<Props> = ({
    initValues,
    submitText,
    backBtnText,
    sellBtnText,
    step,
    onInvestCurrencyStep,
    validationSchema,
    ...props
  }: Props): JSX.Element => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { investCurrency } = useInvestCurrencyState();
    const { selectedTab } = investCurrency;

    const submit = (data: ProductCurrencyRequestState) => {
      dispatch({
        type: INVEST_CURRENCY_SAVE_STATE,
        payload: {
          ...data,
        },
      });
    };
    const handleButtonClick = () => {
      if (step === 2) {
        onInvestCurrencyStep({ step: step - 1 });
      } else
        onInvestCurrencyStep({
          step: 0,
        });
    };

    return (
      <Formik
        enableReinitialize
        initialValues={initValues}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          submit({ ...values, step: step === 4 ? 4 : step + 1 });
        }}
        validationSchema={validationSchema && yup.object().shape(validationSchema)}
      >
        {formProps => (
          <Form>
            <StepForm {...formProps} {...props} />
            <Grid container className={classes.buttonsContainer} justifyContent="center">
              <Grid item xs={12}>
                {selectedTab === 1 && step === 0 ? null : (
                  <Button
                    type="button"
                    classes={{ label: classes.backButton }}
                    className={classes.backButton}
                    onClick={handleButtonClick}
                  >
                    <KeyboardBackspace style={{ height: 19 }} />
                    {selectedTab === 0 ? backBtnText || 'Back' : sellBtnText || 'Back'}&nbsp;
                  </Button>
                )}
              </Grid>
              <Grid item xs={12}>
                {step === 1 && (
                  <CustomButtonPrimary
                    type="submit"
                    variant="contained"
                    size="large"
                    fullWidth={true}
                    className={
                      formProps.values.isBtnDisabled
                        ? classes.button
                        : clsx(classes.button, classes.activeButton)
                    }
                    style={selectedTab === 1 ? { marginBottom: 34 } : {}}
                    disabled={formProps.values.isBtnDisabled}
                  >
                    {selectedTab === 1 && step === 1 ? 'Request order' : submitText}
                  </CustomButtonPrimary>
                )}
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    );
  };

  const mapDispatchToProps = {
    onInvestCurrencyStep: investCurrencySaveState,
  };

  const mapStateToProps = (state: AppState) => {
    return getInvestCurrencySelector(state);
  };

  const ConnectedStep = connect(mapStateToProps, mapDispatchToProps)(InvestCurrencyStep);

  return ConnectedStep;
};

export default withInvestCurrencyStep;
