import { CircularProgress, Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { size } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { EmptyAC, PayloadAC } from 'typesafe-actions';
import Satelite from '../../../../assets/satelite.svg';
import CustomButtonSecondary from '../../../../components/CustomButtons/CustomButtonSecondary';
import InvestDialog from '../../../../components/InvestDialog';
import { NavTab, NavTabContent, NavTabs } from '../../../../components/NavTabs';
import SacretEmpty from '../../../../components/SacretEmpty';
import {
  GetProductsPaginatedRequest,
  SaveProductTab,
} from '../../../../data/repositories/product/typedef';
import { User } from '../../../../data/typedefs/user';
import { roles } from '../../../../utils/constants/roles';
import { PRODUCT_ROUTE } from '../../../../utils/constants/routes';
import ProductCards from './ProductCards';
import { initialState, ProductFormState } from './ProductForm/state/initialState';
import InvestCurrency from './ProductInvest/';
import ProductFilterBar from './ProductsFilterBar';
import ProductsTable from './ProductsTable';
import { SET_TABLE_PAGE } from './ProductsTable/state/actions';
import { ProductTableState } from './ProductsTable/state/initialState';
import { EXPORT_PRODUCTS } from './state/actions';
const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 24,
      marginTop: 30,
    },
    circularProgress: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%,-50%)',
    },

    createProductWrapper: {
      float: 'right',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        marginTop: 10,
      },
    },
    exportLoaderWrappers: {
      width: 176,
      height: '36px',
      display: 'inline-block',
      verticalAlign: 'middle',
      textAlign: 'center',
    },
    tabPanel: {
      marginBottom: 30,
    },
  });

const useStyles = makeStyles(styles);

type Props = ProductTableState & {
  setCurrentHomePage: PayloadAC<'home/set_current_home_page', string>;
  clearAllFilters: any;
  user: User;
  setProductForm: PayloadAC<'product_form/set_initial_values', ProductFormState>;
  exportInProgress: boolean;
  asyncGetProductsPaginated: PayloadAC<
    'product/get_products_paginated',
    GetProductsPaginatedRequest
  >;
  toggleTab: PayloadAC<'product/toggle_tab', SaveProductTab>;
  getWalletBalance: EmptyAC<'wallet/get_wallet_balance'>;
};

const Product = ({
  setCurrentHomePage,
  clearAllFilters,
  user,
  setProductForm,
  exportInProgress,
  toggleTab,
  asyncGetProductsPaginated,
  getWalletBalance,
  products,
  inProgress,
  totalProducts,
  appliedFilters,
}: Props): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);

  const handleData = (value: string | null = null) => {
    dispatch({ type: SET_TABLE_PAGE, payload: 0 });
    asyncGetProductsPaginated({
      page: 0,
      perPage: 10,
      appliedFilters: { product: value },
      appliedTab: { product: value },
    });
    toggleTab({ type: 'product', value });
    clearAllFilters();
  };
  const handleTabChange = (event: React.ChangeEvent<Record<string, unknown>>, newValue: number) => {
    setActiveTab(newValue);
    const value = newValue === 1 ? 'investedProducts' : null;
    handleData(value);
  };

  useEffect(() => {
    handleData();
    getWalletBalance();
    setCurrentHomePage('product');
  }, []);

  //If user is admin fetch all products (with status published & created)
  useEffect(() => {
    if (user.roles) asyncGetProductsPaginated({ page: 0, perPage: 10 });
  }, [user]);

  const isEmpty = size(products) === 0;
  return (
    <div className={classes.root}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className={classes.tabPanel}
      >
        <Grid item>
          <NavTabs
            variant="scrollable"
            value={activeTab}
            onChange={handleTabChange}
            aria-label="Products"
          >
            <NavTab label="Products" />
            <NavTab label="My purchased products" />
          </NavTabs>
        </Grid>
        {roles.isOwner(user.roles) && (
          <div className={classes.createProductWrapper}>
            <CustomButtonSecondary
              variant="outlined"
              size="medium"
              onClick={() => {
                setProductForm(initialState);
                history.push(PRODUCT_ROUTE);
              }}
            >
              New product
            </CustomButtonSecondary>
            {exportInProgress ? (
              <div className={classes.exportLoaderWrappers}>
                <CircularProgress size={34} color="secondary" />
              </div>
            ) : (
              <CustomButtonSecondary
                variant="outlined"
                size="medium"
                style={{ marginLeft: 10 }}
                onClick={() =>
                  dispatch({
                    type: EXPORT_PRODUCTS,
                  })
                }
              >
                Export products
              </CustomButtonSecondary>
            )}
          </div>
        )}
      </Grid>
      {totalProducts <= 0 && appliedFilters.size === 0 ? null : <ProductFilterBar />}
      <InvestDialog>
        <InvestCurrency />
      </InvestDialog>

      {inProgress ? (
        <div className={classes.circularProgress}>
          <CircularProgress color="secondary" size={60} />
        </div>
      ) : isEmpty ? (
        <SacretEmpty
          image={Satelite}
          title="Yikes, you don't have any products"
          text=""
          hasButton={false}
        />
      ) : (
        <div>
          <NavTabContent value={activeTab} index={0}>
            <ProductCards />
          </NavTabContent>
          <NavTabContent value={activeTab} index={1}>
            <ProductsTable />
          </NavTabContent>
        </div>
      )}
    </div>
  );
};

export default Product;
