import moment from 'moment';
import { all, put, takeEvery } from 'redux-saga/effects';
import LocalStorage from '../../../../../../data/LocalStorage';
import api from '../../../../../../data/repositories/admin';
import {
  EXPORT_PRODUCTS_PURCHASE_HISTORY_ENDPOINT,
  GET_PRODUCTS_PURCHASE_HISTORY_ENDPOINT,
} from '../../../../../../utils/constants/api';
import { USER_ID } from '../../../../../../utils/constants/localStorageKeys';
import {
  asyncExportProductsPurchaseHistory,
  asyncGetProductsPurchaseHistory,
  EXPORT_PRODUCTS_PURCHASE_HISTORY,
  GET_PRODUCTS_PURCHASE_HISTORY_PAGINATED,
  setProductsPurchaseHistoryTablePage,
} from './actions';
import { ProductsPurchaseHistoryTableType } from './initialState';
import { saveAs } from 'file-saver';

const mapURL = (params: any) => {
  let newParams = {};
  Object.keys(params).forEach(key => {
    if (params[key] !== null) newParams = { ...newParams, [key]: params[key].toString() };
  });
  return new URLSearchParams(newParams).toString();
};

const getfilter = (action: any) => {
  return {
    date_from:
      action.payload.appliedFilters &&
      action.payload.appliedFilters.dateFilter &&
      action.payload.appliedFilters.dateFilter.length
        ? moment(action.payload.appliedFilters.dateFilter[0], 'DD/MM/YYYY').format(
            'YYYY-MM-DD HH:mm:ss',
          )
        : null,
    date_to:
      action.payload.appliedFilters &&
      action.payload.appliedFilters.dateFilter &&
      action.payload.appliedFilters.dateFilter.length
        ? moment(action.payload.appliedFilters.dateFilter[1], 'DD/MM/YYYY').endOf('day').toDate() >
          moment().toDate()
          ? moment().format('YYYY-MM-DD HH:mm:ss')
          : moment(action.payload.appliedFilters.dateFilter[1], 'DD/MM/YYYY')
              .endOf('day')
              .format('YYYY-MM-DD HH:mm:ss')
        : null,
    slc_from:
      action.payload.appliedFilters && action.payload.appliedFilters.valueRange
        ? action.payload.appliedFilters.valueRange[0] !== ''
          ? action.payload.appliedFilters.valueRange[0] * 10000000
          : -100000 * 10000000
        : null,
    slc_to:
      action.payload.appliedFilters && action.payload.appliedFilters.valueRange
        ? action.payload.appliedFilters.valueRange[1] !== ''
          ? action.payload.appliedFilters.valueRange[1] * 10000000
          : 100000 * 10000000
        : null,
    order_status:
      action.payload.appliedFilters && action.payload.appliedFilters.status
        ? action.payload.appliedFilters.status
        : null,
    email:
      action.payload.appliedFilters && action.payload.appliedFilters.searchInput
        ? action.payload.appliedFilters.searchInput
        : null,
    product_name:
      action.payload.appliedFilters && action.payload.appliedFilters.productName
        ? action.payload.appliedFilters.productName
        : null,
  };
};

export function* getProductsPurchaseHistoryPaginated$(
  action: ReturnType<typeof asyncGetProductsPurchaseHistory.request>,
) {
  try {
    const userId = LocalStorage.get(USER_ID);
    const filter = getfilter(action);
    const productsPurchaseHistory: ProductsPurchaseHistoryTableType[] =
      yield api.getProductsPurchaseHistoryPaginated(
        GET_PRODUCTS_PURCHASE_HISTORY_ENDPOINT(
          userId ? userId : '',
          action.payload.page,
          action.payload.perPage,
          mapURL(filter) && '&' + mapURL(filter),
        ),
      );

    yield put(setProductsPurchaseHistoryTablePage(action.payload.page));
    yield put(asyncGetProductsPurchaseHistory.success(productsPurchaseHistory));
  } catch (error) {
    yield put(
      asyncGetProductsPurchaseHistory.failure(
        error && error.message ? error.message : 'Cannot fetch product data.',
      ),
    );
  }
}

export function* exportProductsPurchaseHistory$(action: any) {
  try {
    const userId = LocalStorage.get(USER_ID);

    const filter = getfilter(action);

    const response = yield api.exportProductsPurchaseHistory(
      EXPORT_PRODUCTS_PURCHASE_HISTORY_ENDPOINT(
        userId ? userId : '',
        action.payload.page,
        action.payload.perPage,
        mapURL(filter) && '&' + mapURL(filter),
      ),
    );

    const csvData = new Blob([response], { type: 'text/csv;charset=utf-8;' });

    saveAs(csvData, 'UsersEarnings.csv');

    yield put(asyncExportProductsPurchaseHistory.success());
  } catch (error) {
    yield put(asyncExportProductsPurchaseHistory.failure(error));
  }
}

export default function* () {
  yield all([
    takeEvery(GET_PRODUCTS_PURCHASE_HISTORY_PAGINATED, getProductsPurchaseHistoryPaginated$),
    takeEvery(EXPORT_PRODUCTS_PURCHASE_HISTORY, exportProductsPurchaseHistory$),
  ]);
}
