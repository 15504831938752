import { connect } from 'react-redux';
import { AppState } from '../../../../../state/initialState';
import { getCoinPrices } from '../../../../state/common/selectors';
import { asyncGetCryptoChartData, setSelectedCoin } from '../../state/actions';
import { expensiveGetWalletWithMetadataBySymbol, getSelectedCoin } from '../../state/selectors';
import WalletCoinsTableRow from './WalletCoinTableRow';

type OwnProps = {
  walletSymbol: string;
};

const mapStateToProps = (state: AppState, { walletSymbol }: OwnProps) => {
  const wallet = expensiveGetWalletWithMetadataBySymbol(walletSymbol)(state);
  const coinPrices = getCoinPrices(state);
  const selectedCoin = getSelectedCoin(state);
  return { wallet, selectedCoin, coinPrices };
};
const mapDispatchToProps = {
  setSelectedCoin,
  asyncGetCryptoChartData: asyncGetCryptoChartData.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(WalletCoinsTableRow);
