import { UPDATE_KYC_APPLICANTS_ENDPOINT } from '../../../utils/constants/api';
import Http from '../../Http';
import { KycUpdateAplicantRequest } from './typedefs';

const kycUpdateAplicant = async (
  accountId: string,
  data: KycUpdateAplicantRequest,
): Promise<any> => {
  return await Http.post(UPDATE_KYC_APPLICANTS_ENDPOINT(accountId), data);
};

export default {
  kycUpdateAplicant,
};
