import {
  ChangePasswordState,
  initialState as changePassword,
} from '../SettingsChangePassword/state/initialState';
import {
  initialState as twoFactorAuth,
  TwoFactorAuthState,
} from '../SettingsTwoFactorAuth//state/initialState';
import { initialState as profileInfo, ProfileInfoState } from '../Membership/state/initialState';
import {
  initialState as currencyRate,
  UpdateCurrencyRateState,
} from '../CurrencySettings/state/initialState';
import { initialState as feeState, FeeSettingsState } from '../FeeSettings/state/initialState';
import {
  initialState as fiatDepositState,
  FiatDepositState,
} from '../FiatDeposit/state/initialState';

export type SettingsState = {
  changePassword: ChangePasswordState;
  twoFactorAuth: TwoFactorAuthState;
  profileInfo: ProfileInfoState;
  currencyRate: UpdateCurrencyRateState;
  feeState: FeeSettingsState;
  fiatDepositState: FiatDepositState;
};

export const initialState = {
  changePassword,
  twoFactorAuth,
  profileInfo,
  currencyRate,
  feeState,
  fiatDepositState,
};
