import { Chip, Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect } from 'react';
import { EmptyAC, PayloadAC } from 'typesafe-actions';
import UserIcon from '../../../../../assets/user-icon-modified.svg';
import ChangePasswordForm from '../../../../../components/ChangePasswordForm';
import CustomButtonPrimary from '../../../../../components/CustomButtons/CustomButtonPrimary';
import ProfileInfoShort from '../../../../../components/ProfileInfoShort';
import SacretEmpty from '../../../../../components/SacretEmpty';
import {
  ChangeUserPasswordRequest,
  SelectedUser,
} from '../../../../../data/repositories/admin/typedefs';
import { Colors } from '../../../../../ui/style/colors';
import { SET_INFO_DIALOG_FIELDS } from '../../InfoDialog/state/actions';
import {
  CHANGE_USER_PASSWORD,
  CHANGE_USER_PASSWORD_CLEAR_STATE,
  SET_CHANGE_USER_PASSWORD_SELETCED_USER,
} from './state/actions';
import { ChangeUserPasswordState, selectedUserInitialState } from './state/initialState';

const styles = () =>
  createStyles({
    selectedUser: {
      color: `${Colors.blueCharcoal}`,
      fontSize: 20,
      fontWeight: 800,
      textTransform: 'uppercase',
    },
    clearChipIcon: {
      fontSize: 14,
      padding: 5,
      color: `${Colors.white}`,
    },
    chip: {
      backgroundColor: `${Colors.spunPearl}`,
      '&:hover': {
        backgroundColor: `${Colors.ghost}`,
      },
      color: `${Colors.white}`,
      padding: 5,
    },
    sacretEmpty: {
      '& img': {
        width: 60,
      },
    },
    mt30: {
      marginTop: 30,
    },
    dialog: {
      display: 'block',
      paddingLeft: 20,
      '& p': {
        margin: 0,
      },
    },
  });

const useStyles = makeStyles(styles);
type Props = ChangeUserPasswordState & {
  setInfoDialogFields: PayloadAC<typeof SET_INFO_DIALOG_FIELDS, any>;
  setChangeUserPasswordSelectedUser: PayloadAC<
    typeof SET_CHANGE_USER_PASSWORD_SELETCED_USER,
    SelectedUser
  >;
  changePassword: PayloadAC<typeof CHANGE_USER_PASSWORD, ChangeUserPasswordRequest>;
  changeUserPasswordClearState: EmptyAC<typeof CHANGE_USER_PASSWORD_CLEAR_STATE>;
};

const ChangeUserPassword: React.FC<Props> = (props: Props): JSX.Element => {
  const {
    setInfoDialogFields,
    setChangeUserPasswordSelectedUser,
    changeUserPasswordClearState,
    changePassword,
    selectedUser,
    inProgress,
    success,
    error,
  } = props;
  const isUserSelected = selectedUser.id !== '';
  const classes = useStyles();
  const { firstName, lastName, email } = selectedUser;

  useEffect(() => {
    setChangeUserPasswordSelectedUser(selectedUserInitialState);
  }, []);

  const openModal = () => {
    setInfoDialogFields({
      dialogProps: {
        maxWidth: 'md',
        dialogTittleWrapperClass: classes.dialog,
        activeTab: 'change_user_password',
      },
      dialogType: 'SeacrhUserDialog',
      title: 'User search',
    });
  };

  const submit = (data: any) => {
    const payload = {
      user_identifier: selectedUser.id,
      new_password: data.new_password,
    };
    changePassword(payload);
  };

  return (
    <div>
      <CustomButtonPrimary
        variant="outlined"
        size="medium"
        onClick={() => {
          openModal();
          setChangeUserPasswordSelectedUser(selectedUserInitialState);
        }}
      >
        Select User
      </CustomButtonPrimary>
      {isUserSelected ? (
        <>
          <Grid container justifyContent="space-between">
            <Grid item className={classes.mt30}>
              <Chip
                size="small"
                clickable={true}
                deleteIcon={<CloseIcon className={classes.clearChipIcon} />}
                label={selectedUser.email}
                onDelete={() => setChangeUserPasswordSelectedUser(selectedUserInitialState)}
                className={classes.chip}
              />
            </Grid>
            <Grid item className={classes.mt30}>
              <Typography className={classes.selectedUser}>
                {selectedUser.firstName} {selectedUser.lastName}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid className={classes.mt30} item xs={12} md={6} style={{ paddingRight: 12 }}>
              <ChangePasswordForm
                onSubmit={submit}
                changePasswordClearState={changeUserPasswordClearState}
                showCurrentPassword={false}
                changePasswordState={{ inProgress, success, error }}
              />
            </Grid>

            <Grid className={classes.mt30} item xs={12} md={6}>
              <ProfileInfoShort lastName={lastName} firstName={firstName} email={email} />
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <SacretEmpty
            image={UserIcon}
            title="No user selected!"
            text="Please select user to change his password"
            className={classes.sacretEmpty}
          />
        </>
      )}
    </div>
  );
};

export default ChangeUserPassword;
