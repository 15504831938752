import { createAsyncAction, createStandardAction } from 'typesafe-actions';

export const GET_WALLET_TRANSFERS = 'transactions/get_wallet_tranfers';
export const GET_WALLET_TRANSFERS_SUCCESS = 'transactions/get_wallet_tranfers/success';
export const GET_WALLET_TRANSFERS_FAILED = 'transactions/get_wallet_tranfers/failed';

export const SET_TRANSFERS_TABLE = 'transfers/set_table';

export const getWalletTransfersPaginated = createAsyncAction(
  GET_WALLET_TRANSFERS,
  GET_WALLET_TRANSFERS_SUCCESS,
  GET_WALLET_TRANSFERS_FAILED,
)<any, any, Error>();

export const setTransfersTable = createStandardAction(SET_TRANSFERS_TABLE)<number>();
