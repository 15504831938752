import { CircularProgress, Grid, IconButton, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { ReactComponent as ErrorIconSvg } from '../../assets/error-icon.svg';
import { ReactComponent as SuccessIconSvg } from '../../assets/success-icon.svg';
import { ReactComponent as WarningIconSvg } from '../../assets/warning.svg';
import { Colors } from '../../ui/style/colors';
import { SUPPORT_EMAIL } from '../../utils/constants/info';
import CustomButtonPrimary from '../CustomButtons/CustomButtonPrimary';

const styles = (theme: Theme) =>
  createStyles({
    wrapper: {
      padding: '47px 32px',
      maxWidth: 450,
    },
    closeButton: {
      position: 'absolute',
      right: 30,
      top: 20,
      color: theme.palette.primary.light,
    },
    inProgress: {
      height: '100%',
      width: '100%',
      minWidth: 300,
      minHeight: 250,
    },
    icon: {
      width: 60,
      marginBottom: 24,
      marginTop: 24,
    },
    title: {
      fontWeight: 700,
      fontSize: 20,
      lineHeight: '24px',
      color: `${Colors.outerSpace}`,
      marginBottom: 16,
    },
    success: {
      color: theme.palette.success.light,
    },
    failed: {
      color: theme.palette.error.light,
    },
    warning: {
      color: theme.palette.warning.light,
    },
    message: {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '24px',
      color: `${Colors.stormGray}`,
      letterSpacing: '0.2px',
      marginBottom: 16,
      textAlign: 'center',
    },
    buttons: {
      fontWeight: 700,
      fontSize: 16,
      lineHeight: '16px',
      letterSpacing: '0.2px',
      height: 48,
      textTransform: 'none',
    },
    child: {
      marginBottom: 16,
    },
  });
const useStyles = makeStyles(styles);

type Props = {
  onExit?: React.MouseEventHandler<HTMLButtonElement>;
  variant?: 'success' | 'warning' | 'failed';
  inProgress?: boolean;
  message?: string | Error;
  className?: string;
  showCloseButton?: boolean;
  showMainButton?: boolean;
  children?: React.ReactChild | null;
  buttonText?: string;
};

const ModalState: React.FC<Props> = ({
  onExit,
  variant = 'success',
  inProgress = false,
  message = `Something went wrong! For more datails please contact our support ${SUPPORT_EMAIL}`,
  className,
  showCloseButton = true,
  showMainButton = true,
  children,
  buttonText,
}: Props) => {
  const classes = useStyles();

  const renderVariant = () => {
    switch (variant) {
      case 'success':
        return (
          <>
            <SuccessIconSvg className={classes.icon} />
            <Typography className={`${classes.title} ${classes.success}`} component="p">
              Done!
            </Typography>
          </>
        );
      case 'warning':
        return (
          <>
            <WarningIconSvg className={classes.icon} />
            <Typography className={`${classes.title} ${classes.warning}`} component="p">
              Notice
            </Typography>
          </>
        );
      case 'failed':
        return (
          <>
            <ErrorIconSvg className={classes.icon} />
            <Typography className={`${classes.title} ${classes.failed}`} component="p">
              Failed
            </Typography>
          </>
        );
      default:
        break;
    }
  };
  return (
    <div className={className ? className : classes.wrapper}>
      {showCloseButton && (
        <IconButton className={classes.closeButton} onClick={onExit}>
          <CloseIcon />
        </IconButton>
      )}
      {inProgress ? (
        <Grid container justifyContent="center" alignItems="center" className={classes.inProgress}>
          <CircularProgress color="secondary" size={60} />
        </Grid>
      ) : (
        <Grid container justifyContent="center" alignItems="center" direction="column">
          {renderVariant()}
          <Typography className={classes.message} component="p">
            {message}
          </Typography>
          <Typography className={classes.child} component="div">
            {children}
          </Typography>
          {showMainButton && (
            <CustomButtonPrimary
              variant="outlined"
              size="large"
              fullWidth
              className={classes.buttons}
              onClick={onExit}
            >
              {buttonText ? buttonText : 'Ok'}
            </CustomButtonPrimary>
          )}
        </Grid>
      )}
    </div>
  );
};

export default ModalState;
