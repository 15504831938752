import {
  Transaction,
  TransactionsFilter,
} from '../../../../../data/repositories/transaction/typedef';

export type EarningsState = {
  transactions: Map<string, Transaction>;
  singleCoinTransactions: Map<string, Transaction>;
  appliedFilters: TransactionsFilter;
  inProgress: boolean;
  fail: boolean;
  error: Error | null;
  loadingDone: boolean;
};
export const initialState: EarningsState = {
  transactions: new Map<string, Transaction>(),
  singleCoinTransactions: new Map<string, Transaction>(),
  appliedFilters: new Map<string, string[]>(),
  inProgress: false,
  fail: false,
  error: null,
  loadingDone: false,
};
