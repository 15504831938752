import { connect } from 'react-redux';
import { AppState } from '../../../../../../state/initialState';
import { isTwoFactorAuthDialogOpen, openTwoFactorAuth } from '../../state/actions';
import { twoFactorAuthStateSelector } from '../../state/selectors';
import Settings2faConfirmDisableDialog from './Settings2faConfirmDisableDialog';

const mapDispatchToProps = {
  openTwoFactorAuth: openTwoFactorAuth,
  isTwoFactorAuthDialogOpen: isTwoFactorAuthDialogOpen,
};

const mapStateToProps = (state: AppState) => ({ ...twoFactorAuthStateSelector(state) });

export default connect(mapStateToProps, mapDispatchToProps)(Settings2faConfirmDisableDialog);
