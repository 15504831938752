/* eslint-disable dot-notation */
/* eslint-disable no-param-reassign */
import { GET_TOKEN_ENDPOINT } from '../../utils/constants/api';
import { ACCESS_TOKEN } from '../../utils/constants/localStorageKeys';
import LocalStorage from '../LocalStorage';

export default config => {
  // Don't attach access token on refresh-token endpoint since it will fail with any token
  if (config.url === GET_TOKEN_ENDPOINT) {
    return config;
  }

  const token = LocalStorage.get(ACCESS_TOKEN);

  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  return config;
};
