import { connect } from 'react-redux';
import { AppState } from '../../../../state/initialState';
import {
  asyncGetMerchantsReferralsPaginated,
  clearAllFilters,
  toggleFilter,
} from '../state/actions';
import {
  getAppliedFiltersSelector,
  affiliateMerchantsTableStateSelector,
} from '../state/selectors';
import TransactionsFilterBar from './TransactionsFilterBar';

const mapStateToProps = (state: AppState) => {
  const appliedFilters = getAppliedFiltersSelector(state);
  const { order_type } = affiliateMerchantsTableStateSelector(state);

  return { appliedFilters, order_type };
};

const dispatchStateToProps = {
  asyncGetMerchantsReferralsPaginated: asyncGetMerchantsReferralsPaginated.request,
  toggleFilter,
  clearAllFilters: clearAllFilters,
};

export default connect(mapStateToProps, dispatchStateToProps)(TransactionsFilterBar);
