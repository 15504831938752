import { combineReducers } from 'redux';
import productTable from '../ProductsTable/state/reducer';
import productInvest from '../ProductInvest/state/reducer';
import productForm from '../ProductForm/state/reducer';

export default combineReducers({
  productTable,
  productInvest,
  productForm,
});
