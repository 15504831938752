/* eslint-disable @typescript-eslint/indent */
import { CircularProgress } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { size } from 'lodash';
import React, { useEffect } from 'react';
import { PayloadAC } from 'typesafe-actions';
import Satelite from '../../../../../assets/satelite.svg';
import AffiliatesMerchantsTable, {
  HeaderProps,
} from '../../../../../components/AffiliatesMerchantsTable/';
import SacretEmpty from '../../../../../components/SacretEmpty';
import {
  GetReferralsPaginatedRequest,
  Referral,
} from '../../../../../data/repositories/affiliate/typedefs';
import { AffiliateMerchantsTableState } from './state/initialState';

const styles = (theme: Theme) =>
  createStyles({
    myAffiliatesList: {
      color: theme.palette.secondary.light,
      fontSize: 18,
      fontWeight: 800,
      paddingBottom: 30,
    },
    root: {},
    circularProgress: {
      position: 'absolute',
      left: '50%',
      top: '50%',
    },
  });

const useStyles = makeStyles(styles);

type Props = AffiliateMerchantsTableState & {
  asyncGetMerchantsReferralsPaginated: PayloadAC<
    'affiliate/get_merchants_referrals_paginated',
    GetReferralsPaginatedRequest
  >;
  inProgress: boolean;
  appliedFilters?: any;
};

const headers: HeaderProps[] = [
  { name: 'Status', align: 'left' },
  { name: 'Type', align: 'left' },
  { name: 'Affiliate email', align: 'left' },
  { name: 'Date & Time', align: 'left' },
  { name: 'Amount', align: 'right' },
  { name: 'Value(EUR)', align: 'right' },
];

const AffiliateMerchantsTable: React.FC<Props> = (props: Props): JSX.Element => {
  const classes = useStyles();
  const {
    referrals,
    asyncGetMerchantsReferralsPaginated,
    inProgress,
    stats: { totalReferrals, totalEarned, totalAmount },
    page,
    appliedFilters,
  } = props;

  useEffect(() => {
    asyncGetMerchantsReferralsPaginated({ page: page, perPage: 10 });
  }, []);

  const isEmpty = size(referrals) == 0;
  return (
    <div>
      {inProgress && !referrals ? (
        <CircularProgress color="secondary" className={classes.circularProgress} size={60} />
      ) : (
        <div>
          {!isEmpty ? (
            <div className={classes.root}>
              <AffiliatesMerchantsTable
                referrals={referrals ? referrals : new Map<string, Referral>()}
                headers={headers}
                totalReferrals={totalReferrals ? totalReferrals : 0}
                totalEarned={totalEarned || 0}
                totalAmount={totalAmount || 0}
                page={page}
                inProgress={inProgress}
                filter={appliedFilters}
              />
            </div>
          ) : (
            <SacretEmpty
              image={Satelite}
              title="Yikes, You don't have any earnings"
              text="Feel free to share your affiliate code with friends and earn reward for each purchase they make through the system. That's not all, you will also earn reward when they recommend SLAFF to their friends. Lifetime commission, for all their future payments, no strings attached."
              hasButton={false}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default AffiliateMerchantsTable;
