import {
  CircularProgress,
  createStyles,
  InputAdornment,
  makeStyles,
  Theme,
} from '@material-ui/core';
import React from 'react';
import { ReactComponent as SearchIconSvg } from '../../assets/search.svg';
import { Colors } from '../../ui/style/colors';
import SacretInput from '../SacretInput';

const styles = (theme: Theme) =>
  createStyles({
    input: {
      height: 55,
      //padding: '8px 26px 8px 12px',
      color: `${Colors.yellow}`,
      fontSize: 12,
    },
    root: {
      root: { lineHeight: '1.1875em' },
    },
    icon: {
      height: 12,
      fill: theme.palette.primary.light,
      width: 12,
      // marginTop: 4,
      // marginRight: 7,
      marginRight: 10,
      color: `${Colors.white}`,
    },
    iconPosition: {
      background: `${Colors.white}`,
      boxShadow: '0 10px 30px 0 rgba(132,141,182,0.25)',
    },
    searchButton: {
      cursor: 'pointer',
      borderStyle: 'none',
      borderRadius: '0 4px 4px 0',
      paddingLeft: '12px',
      outline: 'none',
      backgroundColor: `${Colors.havelockBlue}`,
      color: `${Colors.white}`,
      height: 38,
      whiteSpace: 'nowrap',
      textTransform: 'none',
      '&:hover': {
        boxShadow: '0 10px 30px 0 rgba(132,141,182,0.25)',
      },
      transition: 'all .4s',
    },
    disabled: {
      backgroundColor: `${Colors.cadetBlue}`,
    },
  });
const useStyles = makeStyles(styles);

type Props = {
  onChange: (value: any) => void;
  stateFilterValue: string[] | undefined;
  loading?: boolean;
  placeholder?: string;
  endAdornment?: React.ReactNode;
  searchHandler?: () => void;
};

const SacretSearchInput: React.FC<Props> = ({
  onChange,
  loading = false,
  stateFilterValue,
  placeholder = 'Search by affiliate email',
  endAdornment,
  searchHandler,
}: Props): JSX.Element => {
  const classes = useStyles();

  const handleChange = (e: any) => {
    let value = e.target.value;
    const isPasted = e.nativeEvent.inputType.startsWith('insertFromPaste');
    // Delete extra blank spaces if value is pasted
    if (isPasted) {
      value = value.trim();
    }
    onChange(value == '' ? '' : [value]);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key == 'Enter' && stateFilterValue && stateFilterValue[0] !== '' && searchHandler) {
      searchHandler();
    }
  };

  const isSearchButtonDisabled = stateFilterValue && stateFilterValue[0] !== '' ? false : true;

  return (
    <div style={{ boxShadow: '0 10px 30px 0 rgba(132,141,182,0.25)' }}>
      <SacretInput
        placeholder={placeholder}
        inputProps={{
          classes: { icon: classes.icon, input: classes.input },
        }}
        disableUnderline={true}
        value={stateFilterValue ? stateFilterValue[0] : ''}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        endAdornment={
          <InputAdornment position="end" className={classes.iconPosition}>
            {/* <SearchIcon className={classes.icon} /> */}
            {loading ? (
              <CircularProgress color="secondary" size={12} />
            ) : (
              <>
                {endAdornment}
                <button
                  disabled={isSearchButtonDisabled}
                  className={
                    !isSearchButtonDisabled
                      ? classes.searchButton
                      : `${classes.searchButton} ${classes.disabled}`
                  }
                  onClick={searchHandler}
                >
                  <SearchIconSvg className={classes.icon} />
                </button>
              </>
            )}
          </InputAdornment>
        }
      />
    </div>
  );
};

export default SacretSearchInput;
