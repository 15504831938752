import { DialogContent, Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { PayloadAC } from 'typesafe-actions';
import CustomButtonPrimary from '../../../../../../../components/CustomButtons/CustomButtonPrimary';
import CustomButtonSecondary from '../../../../../../../components/CustomButtons/CustomButtonSecondary';

const styles = (theme: Theme) =>
  createStyles({
    dialog: {
      height: 'center',
      width: 'center',
      borderRadius: 6,
      backgroundColor: 'transparent',
      boxShadow: '0 10px 30px 0 rgba(132,141,182,0.25)',
      paddingLeft: 30,
      paddingRight: 30,
    },
    infoLabel: {
      color: theme.palette.primary.light,
      fontSize: 12,
      fontWeight: 600,
      marginRight: 57,
    },
    button: {
      boxSizing: 'border-box',
      fontSize: 14,
    },
    keepItButton: {
      fontSize: 14,
    },
  });
const useStyles = makeStyles(styles);

type Props = {
  openTwoFactorAuth: PayloadAC<'settings/open_dialog', number>;
  isTwoFactorAuthDialogOpen: PayloadAC<'settings/is_two_factor_auth_dialog_open', boolean>;
};

const Settings2faConfirmDisableDialog: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { openTwoFactorAuth, isTwoFactorAuthDialogOpen } = props;

  const handleClick = () => {
    isTwoFactorAuthDialogOpen(false);
  };

  const handleDeactivateTwoFactorAuth = () => {
    openTwoFactorAuth(4);
  };

  return (
    <DialogContent className={classes.dialog}>
      <Typography className={classes.infoLabel} component="p" style={{ marginTop: 30 }}>
        Two-factor authentication adds an extra layer of security.
      </Typography>
      <Typography className={classes.infoLabel} component="p" style={{ marginBottom: 24 }}>
        Are you sure you want to deactivate it?
      </Typography>

      <Grid container alignContent="center" spacing={3} style={{ marginBottom: 20 }}>
        <Grid item xs={6}>
          <CustomButtonPrimary
            variant="contained"
            size="large"
            className={classes.keepItButton}
            fullWidth
            onClick={handleClick}
          >
            Keep it
          </CustomButtonPrimary>
        </Grid>
        <Grid item xs={6}>
          <CustomButtonSecondary
            variant="outlined"
            size="large"
            className={classes.button}
            fullWidth
            onClick={handleDeactivateTwoFactorAuth}
          >
            Deactivate
          </CustomButtonSecondary>
        </Grid>
      </Grid>
    </DialogContent>
  );
};

export default Settings2faConfirmDisableDialog;
