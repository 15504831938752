import { connect } from 'react-redux';
import { getUserInfo } from '../../../../state/actions';
import KycRedirectionDialog from './KycRedirectionDialog';

const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {
  getUserInfo: getUserInfo.request,
};
export default connect(mapStateToProps, mapDispatchToProps)(KycRedirectionDialog);
