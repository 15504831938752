import { Button, CircularProgress, createStyles, Grid, makeStyles } from '@material-ui/core';
import React, { ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, useHistory } from 'react-router';
import SacretError from '../../../components/SacretError';
import { DASHBOARD_ROUTE } from '../../../utils/constants/routes';
import Dashboard from '../../Dashboard';
import { SET_INFO_DIALOG_FIELDS } from '../../Home/HomeContent/InfoDialog/state/actions';
import { EXCHANGE_AUTH_CODE } from './state/actions';
import { ExchangeAuthCodeState } from './state/initialState';

const styles = () =>
  createStyles({
    wrapper: {
      height: '100vh',
    },
  });

const useStyles = makeStyles(styles);

export const ExchangeAuthCode = ({
  fail,
  inProgress,
  error,
}: ExchangeAuthCodeState): ReactElement => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);

  const code = params.get('code');
  const status = params.get('status');

  useEffect(() => {
    if (code) {
      dispatch({ type: EXCHANGE_AUTH_CODE, payload: { code, status } });
    }
  }, [code, status]);

  if (fail && code) {
    return (
      <>
        <SacretError
          title="Login proccess failed!"
          errorMessage={error ? error.message : ''}
          showBackButton={false}
        />
        <Grid container justifyContent="center">
          <Button onClick={() => history.push(DASHBOARD_ROUTE)}> Go Back</Button>
        </Grid>
      </>
    );
  }
  //if we get success status from sleo when buying aff package then open success dialog
  // since this action is triggered before any other infoDialog, this modal won't be shown if any other opens after this one foe KycRedirectionDialog
  if (status) {
    dispatch({
      type: SET_INFO_DIALOG_FIELDS,
      payload: {
        dialogType: 'AffiliatePackageStatusDialog',
        status,
      },
    });
  }

  return code || inProgress ? (
    <Grid container justifyContent="center" alignItems="center" className={classes.wrapper}>
      <CircularProgress color="secondary" size={60} />
    </Grid>
  ) : (
    <Route path={DASHBOARD_ROUTE} component={Dashboard} />
  );
};
