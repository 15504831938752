import { connect } from 'react-redux';
import { AppState } from '../../../../../state/initialState';
import {
  clearTwoFactorAuthState,
  enableTwoFactorAuth,
  openTwoFactorAuth,
  setDialogTitle,
} from '../state/actions';
import { twoFactorAuthStateSelector } from '../state/selectors';
import SettingsTwoFactorAuthDialog from './SettingsTwoFactorAuthDialog';

const mapDispatchToProps = {
  openTwoFactorAuth: openTwoFactorAuth,
  enableTwoFactorAuth: enableTwoFactorAuth,
  clearTwoFactorAuthState: clearTwoFactorAuthState,
  setDialogTitle: setDialogTitle,
};

const mapStateToProps = (state: AppState) => ({ ...twoFactorAuthStateSelector(state) });

export default connect(mapStateToProps, mapDispatchToProps)(SettingsTwoFactorAuthDialog);
