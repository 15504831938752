import {
  Card,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Visibility } from '@material-ui/icons';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { EmptyAC } from 'typesafe-actions';
import * as yup from 'yup';
import {
  CHANGE_PASSWORD_CLEAR_STATE,
  CHANGE_USER_PASSWORD_CLEAR_STATE,
} from '../../App/state/actions';
import { Colors } from '../../ui/style/colors';
import { passwordRegex } from '../../utils/helper/utils';
import CustomButtonPrimary from '../CustomButtons/CustomButtonPrimary';
import TextInput from '../TextInput';

const styles = (theme: Theme) =>
  createStyles({
    label: {
      color: theme.palette.secondary.light,
      fontSize: 18,
      fontWeight: 800,
      float: 'left',
      marginBottom: 30,
      cursor: 'pointer',
    },
    card: {
      boxShadow: '0 10px 30px 0 rgba(132,141,182,0.25)',
      verticalAlign: 'sub',
      padding: 20,
    },
    inputFields: {
      marginTop: 5,
      height: 44,
      boxSizing: 'border-box',
      border: `1px solid ${theme.palette.secondary.light}`,
      borderRadius: 4,
      backgroundColor: `${Colors.white}`,
      paddingLeft: 12,
    },
    labels: {
      fontWeight: 'bold',
      color: theme.palette.primary.light,
      marginTop: 30,
      marginBottom: 8,
    },
    changePasswordLabel: {
      color: theme.palette.secondary.light,
      fontSize: 14,
      fontWeight: 800,
      letterSpacing: 1,
      textTransform: 'uppercase',
    },
    button: {
      marginTop: 40,
      textTransform: 'none',
      fontSize: 16,
      fontWeight: 600,
      letterSpacing: 1,
      textAlign: 'center',
    },
    buttonSuccess: {
      backgroundColor: `${Colors.havelockBlue}`,
      marginTop: 40,
      '&:hover': {
        backgroundColor: `${Colors.havelockBlue}`,
      },
    },
    inputError: {
      color: `${Colors.red}`,
      marginTop: 10,
    },
    circularProgress: {
      marginTop: 40,
    },
  });

const useStyles = makeStyles(styles);

type ChangePasswordState = {
  inProgress: boolean;
  success: boolean;
  error: Error | null;
};

type Props = {
  onSubmit: (data: any) => void;
  changePasswordState: ChangePasswordState;
  changePasswordClearState:
    | EmptyAC<typeof CHANGE_USER_PASSWORD_CLEAR_STATE>
    | EmptyAC<typeof CHANGE_PASSWORD_CLEAR_STATE>;
  showCurrentPassword?: boolean;
};

const ChangePasswordForm: React.FC<Props> = (props: Props): JSX.Element => {
  const {
    changePasswordClearState,
    onSubmit,
    changePasswordState: { inProgress = false, success = false, error = null },
    showCurrentPassword = true,
  } = props;
  const classes = useStyles();

  useEffect(() => {
    changePasswordClearState();
  }, []);

  useEffect(() => {
    success && setTimeout(() => changePasswordClearState(), 3000);
  }, [success]);

  const initValues = {
    old_password: '',
    new_password: '',
    confirm_new_password: '',
    showCurrentPassword,
  };

  const [passwordHidden, setPasswordHidden] = useState(true);
  const [newPasswordHidden, setNewPasswordHidden] = useState(true);
  const [confirmNewPasswordHidden, setConfirmNewPasswordHidden] = useState(true);

  const togglePassword = (passwordType: number) => {
    switch (passwordType) {
      case 0:
        setPasswordHidden(!passwordHidden);
        break;
      case 1:
        setNewPasswordHidden(!newPasswordHidden);
        break;
      case 2:
        setConfirmNewPasswordHidden(!confirmNewPasswordHidden);
        break;
    }
  };

  const validationSchema = yup.object({
    showCurrentPassword: yup.boolean(),
    old_password: yup.string().when('showCurrentPassword', {
      is: true,
      then: yup.string().required('Password required'),
    }),
    new_password: yup
      .string()
      .required('New password is required')
      .matches(
        passwordRegex,
        'New password must be minimum 8 characters long, at least 1 uppercase, lowercase, digit, and a symbol.',
      ),
    confirm_new_password: yup
      .string()
      .required('Confirm new password required')
      .oneOf([yup.ref('new_password'), null], 'Password must match'),
  });

  const renderCurrentPasswordField = (errors: any, touched: any) => (
    <>
      <InputLabel className={classes.labels}>Current Password</InputLabel>
      <Field
        id="old_password"
        type={passwordHidden ? 'password' : 'text'}
        name="old_password"
        component={TextInput}
        className={classes.inputFields}
        disableUnderline
        autoComplete="on"
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={() => togglePassword(0)}>
              <Visibility />
            </IconButton>
          </InputAdornment>
        }
      />
      {errors && touched ? <div className={classes.inputError}>{errors}</div> : null}
    </>
  );
  return (
    <Card className={classes.card}>
      <CardContent>
        <Formik
          initialValues={initValues}
          onSubmit={(values, actions) => {
            actions.setSubmitting(false);
            onSubmit(values);
          }}
          validationSchema={validationSchema}
        >
          {({ errors, touched, isValid }) => {
            return (
              <Form>
                <Typography className={classes.changePasswordLabel} component="p">
                  Change password
                </Typography>
                {showCurrentPassword &&
                  renderCurrentPasswordField(errors.old_password, touched.old_password)}

                <InputLabel className={classes.labels}>New Password</InputLabel>
                <Field
                  id="new_password"
                  type={newPasswordHidden ? 'password' : 'text'}
                  name="new_password"
                  component={TextInput}
                  className={classes.inputFields}
                  disableUnderline
                  autoComplete="on"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={() => togglePassword(1)}>
                        <Visibility />
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <ErrorMessage className={classes.inputError} name="new_password" component="div" />
                <InputLabel className={classes.labels}>Confirm Password</InputLabel>
                <Field
                  id="confirm_new_password"
                  type={confirmNewPasswordHidden ? 'password' : 'text'}
                  name="confirm_new_password"
                  component={TextInput}
                  className={classes.inputFields}
                  disableUnderline
                  autoComplete="on"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={() => togglePassword(2)}>
                        <Visibility />
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <ErrorMessage
                  className={classes.inputError}
                  name="confirm_new_password"
                  component="div"
                />
                <Grid container alignItems="center" justifyContent="center">
                  {error != null ? (
                    <Grid item xs={12} className={classes.inputError}>
                      Password change failed!
                    </Grid>
                  ) : null}
                  {inProgress ? (
                    <CircularProgress className={classes.circularProgress} />
                  ) : (
                    <CustomButtonPrimary
                      type={success ? 'button' : 'submit'}
                      variant="contained"
                      size="large"
                      fullWidth
                      className={success ? classes.buttonSuccess : classes.button}
                      disabled={!isValid}
                    >
                      {success ? 'Succesfully changed!' : 'Save'}
                    </CustomButtonPrimary>
                  )}
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </CardContent>
    </Card>
  );
};
export default ChangePasswordForm;
