import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import moment from 'moment';
import React, { FC } from 'react';
import { connect } from 'react-redux';
import { getCoinDecimalsSelector } from '../../../App/Home/HomeContent/Wallet/state/selectors';
import { AppState } from '../../../App/state/initialState';
import { Transaction } from '../../../data/repositories/admin/typedefs';
import { AllCoinDecimals } from '../../../data/repositories/wallet/typedefs';
import { Colors } from '../../../ui/style/colors';
import balanceUtils from '../../../utils/balanceUtils';
import {
  ALL_COINS,
  CRYPTO_MAX_FRACTION,
  CRYPTO_MIN_FRACTION,
} from '../../../utils/constants/amounts';
import Amount from '../../Amount';
import CoinIconCell from '../../CoinIconCell';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    statusSuccess: {
      color: `${Colors.havelockBlue}`,
      fontSize: 14,
      textTransform: 'capitalize',
    },
    statusPending: {
      color: `${Colors.blueViolet}`,
      fontSize: 14,
      textTransform: 'capitalize',
    },
    link: {
      color: `${Colors.blueBell}`,
      cursor: 'pointer',
      textDecoration: 'none',
    },
    colorGreen: {
      color: theme.palette.success.light,
    },
    colorRed: {
      color: theme.palette.error.light,
    },
    iconCell: {
      width: 50,
    },
    arrowIcon: {
      verticalAlign: 'middle',
    },
  }),
);

type Props = {
  transaction: Transaction;
  allCoinDecimals: AllCoinDecimals;
};

const renderType = (type: any) => {
  switch (type) {
    case 'deposit':
      return 'Deposit';
    case 'withdraw':
      return 'Withdraw';
    case 'transfer':
      return 'Wallet Transfer';
  }
};

const statusRenderType = (type: string) => {
  switch (type) {
    case 'pending_escrow':
      return 'Pending Escrow';
    case 'timed_escrow':
      return 'Timed Escrow';
    default:
      return type;
  }
};

const TransactionGroupingTableRow: FC<Props> = ({ transaction, allCoinDecimals }: Props) => {
  const classes = useStyles();

  const prepareValue = (coinSymbol: string, value: number) => {
    const getFormatedCrypto = (decimals: number) => {
      return balanceUtils.formatBalanceToString(
        value,
        decimals,
        CRYPTO_MIN_FRACTION,
        CRYPTO_MAX_FRACTION,
      );
    };
    if (ALL_COINS.includes(coinSymbol)) {
      return getFormatedCrypto(allCoinDecimals[coinSymbol]);
    } else {
      return value;
    }
  };

  const getIcon =
    transaction.amount > 0 ? (
      <ArrowDownwardIcon className={`${classes.arrowIcon} ${classes.colorGreen}`} />
    ) : (
      <ArrowUpwardIcon className={`${classes.arrowIcon} ${classes.colorRed}`} />
    );

  return (
    <TableRow>
      <TableCell className={classes.iconCell} align="center">
        {getIcon}
      </TableCell>
      <TableCell>{transaction.sender_email}</TableCell>
      <TableCell>
        <div>{transaction.receiver_email}</div>
      </TableCell>
      <TableCell
        className={
          transaction.transaction_status === 'completed'
            ? classes.statusSuccess
            : classes.statusPending
        }
      >
        {statusRenderType(transaction.transaction_status)}
      </TableCell>
      <TableCell>{renderType(transaction.origin)}</TableCell>

      <TableCell>
        <div> {moment(transaction.created_at).format('DD/MM/YYYY HH:mm')}</div>
      </TableCell>
      <TableCell align="right">
        <CoinIconCell
          symbol={transaction.symbol}
          containerGridSpacing={2}
          containerJustfy="flex-end"
        />
      </TableCell>
      <TableCell align="right">
        <Amount
          amount={prepareValue(transaction.symbol, Number(transaction.amount))}
          condition={transaction.amount > 0}
        />
      </TableCell>
    </TableRow>
  );
};

const mapStateToProps = (state: AppState) => {
  const allCoinDecimals = ALL_COINS.reduce(function (acc: any, coin: string) {
    acc[coin] = getCoinDecimalsSelector(coin)(state);
    return acc;
  }, {});
  return { allCoinDecimals };
};

export default connect(mapStateToProps, null)(TransactionGroupingTableRow);
