import { all, put, takeEvery } from 'redux-saga/effects';
import LocalStorage from '../../../../../../data/LocalStorage';
import api from '../../../../../../data/repositories/affiliate';
import {
  GetAffiliateCodeResponse,
  GetRefferalsResponse,
} from '../../../../../../data/repositories/affiliate/typedefs';
import {
  GET_AFFILIATE_CODE_ENDPOINT,
  GET_REFERRALS_ENDPOINT,
} from '../../../../../../utils/constants/api';
import { USER_ID } from '../../../../../../utils/constants/localStorageKeys';
import {
  asyncGetAffiliateCode,
  asyncGetReferrals,
  asyncGetIncomeBalances,
  GET_AFFILIATE_CODE,
  GET_REFERRALS,
  GET_INCOME_BALANCES,
} from './actions';

export function* getAffiliateCode$() {
  try {
    const userId = LocalStorage.get(USER_ID);
    const affiliateCodeResponse: GetAffiliateCodeResponse = yield api.getAffiliateCode(
      GET_AFFILIATE_CODE_ENDPOINT(userId ? userId : ''),
    );

    yield put(asyncGetAffiliateCode.success(affiliateCodeResponse));
  } catch (error) {
    yield put(asyncGetAffiliateCode.failure(error));
  }
}

export function* getRefferals$() {
  try {
    const userId = LocalStorage.get(USER_ID);

    const getReferralsResponse: GetRefferalsResponse = yield api.getReferrals(
      GET_REFERRALS_ENDPOINT(userId ? userId : ''),
    );
    yield put(asyncGetReferrals.success(getReferralsResponse));
  } catch (error) {
    yield put(asyncGetReferrals.failure(error));
  }
}

export function* getAccountBalances$() {
  try {
    const userId = yield LocalStorage.get(USER_ID);
    const response = yield api.getAffiliateBalances(userId);

    yield put(asyncGetIncomeBalances.success(response));
  } catch (error) {
    yield put(asyncGetIncomeBalances.failure(error));
  }
}

export default function* () {
  yield all([
    takeEvery(GET_AFFILIATE_CODE, getAffiliateCode$),
    takeEvery(GET_REFERRALS, getRefferals$),
    takeEvery(GET_INCOME_BALANCES, getAccountBalances$),
  ]);
}
