import { Grid, ListItemText, MenuItem, Select } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import { maxBy } from 'lodash';
import React, { FC, useEffect } from 'react';
import { PayloadAC } from 'typesafe-actions';
import { SET_TERM_SAVING_PACKAGE as SET_TERM_SAVING_PACKAGE_DASHBOARD } from '../../App/Home/HomeContent/Dashboard/state/actions';
import { termPackageObject as termPackageObjectDashboard } from '../../App/Home/HomeContent/Dashboard/state/initialState';
import { SET_TERM_SAVING_PACKAGE } from '../../App/Home/HomeContent/Wallet/state/actions';
import {
  initialState,
  termPackageObject,
} from '../../App/Home/HomeContent/Wallet/state/initialState';
import { Colors } from '../../ui/style/colors';
import SacretBaseInput from '../SacretBaseInput';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      fill: theme.palette.secondary.light,
      width: 15,
      height: 15,
      marginTop: 4,
      marginRight: 7,
    },
    label: {
      float: 'left',
      padding: '2px 0',
      fontSize: 12,
      color: theme.palette.primary.light,
    },
    item: {
      minHeight: 0,
      lineHeight: 1,
      fontSize: 12,
      color: theme.palette.primary.light,
      '&:hover': {
        backgroundColor: `${Colors.athensGray} !important`,
        fontWeight: 'bold',
      },
    },
    selectedItem: {
      backgroundColor: `${Colors.white} !important`,
      color: theme.palette.secondary.main,
    },
    inputBorder: {
      border: `1px solid ${theme.palette.secondary.light}`,
      boxShadow: '0 0px 0px 0 rgba(132,141,182,0.25)',
      '&:hover': {
        border: `1px solid ${Colors.blueCharcoal}`,
      },
      [theme.breakpoints.down('sm')]: {
        whiteSpace: 'break-spaces',
      },
    },
  }),
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const AnchorProps: {
  horizontal: number | 'left' | 'right' | 'center';
  vertical: number | 'bottom' | 'top' | 'center';
} = {
  vertical: 'bottom',
  horizontal: 'left',
};

const TransformProps: {
  horizontal: number | 'left' | 'right' | 'center';
  vertical: number | 'bottom' | 'top' | 'center';
} = {
  vertical: 'top',
  horizontal: 'left',
};

const borderColor = (theme: Theme) => theme.palette.secondary.light;
const MenuProps = {
  PaperProps: {
    style: {
      borderRadius: 4,
      border: `1px solid ${borderColor}`,
      borderTop: 0,
      //borderWidth: '0 0 0 5px',
      boxShadow: '0 10px 30px 0 rgba(132,141,182,0.25)',
      marginTop: -6,
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 150,
    },
  },
  anchorOrigin: AnchorProps,
  transformOrigin: TransformProps,
  getContentAnchorEl: null,
};

type Props = {
  allPackages: termPackageObject[] | termPackageObjectDashboard[];
  setTermSavingPackage:
    | PayloadAC<typeof SET_TERM_SAVING_PACKAGE, termPackageObject>
    | PayloadAC<typeof SET_TERM_SAVING_PACKAGE_DASHBOARD, termPackageObjectDashboard>;
};

const SelectPackage: FC<Props> = ({ allPackages, setTermSavingPackage }: Props) => {
  const classes = useStyles();
  const [selectedItem, setSelectedItem] = React.useState<string>(
    initialState.investDialogState.selectedPackage.name,
  );
  const [selectDropdownOpened, setSelectDropdownOpened] = React.useState<boolean>(false);

  useEffect(() => {
    const bestPackage = maxBy(allPackages, 'interest_rate');

    if (bestPackage) {
      setTermSavingPackage(bestPackage);
      setSelectedItem(bestPackage.name);
    }
  }, [allPackages]);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedPackage = allPackages.find(
      termPackage => termPackage.name === event.target.value,
    );

    if (selectedPackage) {
      setTermSavingPackage(selectedPackage);
      setSelectedItem(selectedPackage.name);
    }
  };
  const onSelectOpen = () => {
    setSelectDropdownOpened(true);
  };
  const onSelectClose = () => {
    setSelectDropdownOpened(false);
  };
  return (
    <Select
      variant="outlined"
      value={selectedItem}
      onChange={handleChange}
      input={<SacretBaseInput value="Amount" />}
      MenuProps={MenuProps}
      inputProps={{
        classes: { icon: classes.icon },
        className: classes.inputBorder,
      }}
      displayEmpty={true}
      IconComponent={selectDropdownOpened ? KeyboardArrowUp : KeyboardArrowDown}
      onOpen={onSelectOpen}
      onClose={onSelectClose}
    >
      {allPackages.map(value => (
        <MenuItem
          key={value.id}
          value={value.name}
          dense
          disableRipple
          classes={{ selected: classes.selectedItem, root: classes.item }}
        >
          <Grid container>
            <ListItemText id={`checkbox-list-label-${value.id}`} primary={value.name} />
          </Grid>
        </MenuItem>
      ))}
    </Select>
  );
};

export default SelectPackage;
