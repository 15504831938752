import { connect } from 'react-redux';
import { AppState } from '../../../../../state/initialState';
import EurEarningsTable from './EurEarningsTable';

import { getAppliedFiltersSelector } from '../../../Earnings/state/selectors';
import { getWalletTransfersPaginated } from '../../../Transactions/state/actions';
import { getWalletTransfersStateSelector } from '../../../Transactions/state/selectors';

const mapDispatchToProps = {
  getWalletTransfersPaginated: getWalletTransfersPaginated.request,
};

const mapStateToProps = (state: AppState) => {
  const appliedFilters = getAppliedFiltersSelector(state);

  return {
    appliedFilters,
    ...getWalletTransfersStateSelector(state),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EurEarningsTable);
