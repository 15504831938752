import { createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import DepositButtonWithModal from '../DepositButtonWithModal';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      marginTop: 100,
    },
    img: {
      boxSizing: 'border-box',
      textAlign: 'center',
      width: '100%',
    },
    title: {
      fontSize: 24,
      color: theme.palette.primary.light,
      fontWeight: 'bold',
    },
    answer: {
      fontSize: 14,
      color: theme.palette.primary.light,
      marginTop: 15,
    },
  });
const useStyles = makeStyles(styles);
type Props = {
  image: any;
  title: string;
  text?: string;
  hasButton?: boolean;
  className?: string;
};

const SacretEmpty = ({ image, title, text = '', hasButton = false, className = '' }: Props) => {
  const classes = useStyles();
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
      className={`${classes.container} ${className}`}
    >
      <Grid item xs={4}>
        <img src={image} alt="ilustration" className={classes.img} />
      </Grid>

      <Grid item xs={12} sm={4} style={{ marginTop: 50, marginBottom: 30 }}>
        <Typography variant="h5" align="center" className={classes.title}>
          {title}
        </Typography>
        <Typography gutterBottom align="center" className={classes.answer}>
          {text}
        </Typography>
      </Grid>
      {hasButton ? (
        <Grid item xs={3}>
          <DepositButtonWithModal />
        </Grid>
      ) : null}
    </Grid>
  );
};

export default SacretEmpty;
