import { Tab, Tabs } from '@material-ui/core';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import React from 'react';
import { Colors } from '../../ui/style/colors';
import LightTooltip, { LightTooltipProps } from '../LightTooltip';

type NavTabsProps = {
  value: number;
  variant?: 'scrollable' | 'standard' | 'fullWidth' | undefined;
  onChange: (event: React.ChangeEvent<Record<string, unknown>>, newValue: number) => void;
};

type NavTabProps = {
  label: string;
  tooltip?: LightTooltipProps;
  disabled?: boolean;
  className?: string;
  classes?: any;
};

type NavTabContentProps = {
  children?: React.ReactNode;
  index: any;
  value: any;
};

export const NavTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      width: '100%',
      backgroundColor: `${Colors.mediumTurquoise}`,
    },
  },
})((props: NavTabsProps) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

export const NavTabContent = (props: NavTabContentProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

export const NavTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      color: theme.palette.secondary.light,
      fontSize: 18,
      fontWeight: 800,
      cursor: 'pointer',
      marginRight: theme.spacing(1),
      '&:hover': {
        color: `${Colors.mediumTurquoise}`,
        opacity: 1,
      },
      '&$selected': {
        color: `${Colors.mediumTurquoise}`,
      },
      '&:focus': {
        color: `${Colors.mediumTurquoise}`,
      },
    },
    selected: {},
    tooltip: {
      position: 'absolute',
      left: 'auto',
      right: 0,
      top: '50%',
      transform: 'translateY(-53%)',
      fontSize: 18,
    },
    disabled: {
      opacity: '1 !important',
    },
  }),
)(({ tooltip, classes, ...other }: NavTabProps) => {
  return !!tooltip ? (
    <>
      <Tab classes={{ ...classes, tooltip: null }} {...other} disableRipple />{' '}
      <LightTooltip {...tooltip} className={classes.tooltip} interactive />{' '}
    </>
  ) : (
    <Tab classes={{ ...classes, tooltip: null }} {...other} disableRipple />
  );
});
