import { createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import {
  ADMIN_PANEL_ROUTE,
  AFFILIATE_ROUTE,
  APP_DASHBOARD_ROUTE,
  EARNINGS_ROUTE,
  EDIT_PRODUCT_ROUTE,
  EXCHANGE_ROUTE,
  MY_INVITES,
  ORDERS_ROUTE,
  PRODUCTS_ROUTE,
  PRODUCT_ROUTE,
  SETTINGS_ROUTE,
  TRANSACTIONS_ROUTE,
  WALLET_ROUTE,
} from '../../../utils/constants/routes';
import { DrawerState } from '../HomeDrawer/state/initialState';
import Admin from './Admin';
import Affiliate from './Affiliate';
import Dashboard from './Dashboard';
import Earnings from './Earnings';
import Exchange from './Exchange';
import Invites from './Invites';
import Orders from './Orders';
import Product from './Product';
import ProductForm from './Product/ProductForm';
import Settings from './Settings';
import Transactions from './Transactions';
import Wallet from './Wallet';
const styles = () =>
  createStyles({
    content: {
      marginLeft: 240,
      marginTop: 20,
      height: 'calc(100% - 64px )',
      transition: 'all 0.2s ease-in-out',
    },
    closedDrawer: {
      marginLeft: 80,
      '@media (max-width: 1280px)': {
        marginLeft: 0,
      },
    },
  });
type Props = {
  drawerState: DrawerState;
};
const useStyles = makeStyles(styles);

const HomeContent: React.FC<Props> = ({ drawerState }) => {
  const classes = useStyles();

  return (
    <main className={`${classes.content} ${!drawerState.isOpen && classes.closedDrawer}`}>
      <Switch>
        <Route path={APP_DASHBOARD_ROUTE} component={Dashboard} />
        <Route exact path={WALLET_ROUTE} component={Wallet} />
        <Route path={TRANSACTIONS_ROUTE} component={Transactions} />
        <Route path={EXCHANGE_ROUTE} component={Exchange} />
        <Route path={AFFILIATE_ROUTE} component={Affiliate} />
        <Route path={`${SETTINGS_ROUTE}/:auth?`} component={Settings} />
        <Route path={EARNINGS_ROUTE} component={Earnings} />
        <Route path={ORDERS_ROUTE} component={Orders} />
        <Route path={PRODUCT_ROUTE} component={ProductForm} />
        <Route path={EDIT_PRODUCT_ROUTE} component={ProductForm} />
        <Route path={PRODUCTS_ROUTE} component={Product} />
        <Route path={MY_INVITES} component={Invites} />
        <Route path={ADMIN_PANEL_ROUTE} component={Admin} />
        <Route render={() => <Redirect to={APP_DASHBOARD_ROUTE} />} />
      </Switch>
    </main>
  );
};

export default HomeContent;
