/* eslint-disable */
import { CircularProgress } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React, { useEffect } from 'react';
import { PayloadAC } from 'typesafe-actions';
import {
  GetCryptoChartRequest,
  TableChart,
} from '../../../../../data/repositories/dashboard/typedefs';
import { Colors } from '../../../../../ui/style/colors';
import { ALL_COINS, COINS_ORDER } from '../../../../../utils/constants/amounts';
import { sortArrByPredefinedOrder } from '../../../../../utils/helper/utils';
import { GET_TABLE_CHART } from '../state/actions';
import WalletCoinsTableRow from './WalletCoinTableRow';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      position: 'relative',
      minHeight: 300,
      [theme.breakpoints.down('lg')]: {
        overflowX: 'scroll',
        scrollbarWidth: 'thin',
        scrollbarColor: `${Colors.cadetBlue}`,
        /* Scrollbar on Chrome, Edge, and Safari */
        '&::-webkit-scrollbar': {
          width: 5,
          height: 5,
        },
        '&::-webkit-scrollbar-track': {
          background: `${Colors.seashell}`,
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: `${Colors.blueHaze}`,
          borderRadius: 20,
        },
        /* Scrollbar on hover */
        '&::-webkit-scrollbar-thumb:hover': {
          background: `${Colors.cadetBlue}`,
        },
      },
    },
    table: { border: 0, borderCollapse: 'collapse' },
    tableBody: {
      paddingRight: 30,
    },
    header: {
      color: theme.palette.primary.light,
      fontSize: 12,
      fontWeight: 600,
      whiteSpace: 'nowrap',
    },
    inputError: {
      color: `${Colors.red}`,
      marginTop: '10px',
    },
    circularProgress: {
      position: 'absolute',
      left: '50%',
      top: '50%',
    },
  }),
);
const headers = [
  { name: 'Currency', value: 'name' },
  { name: 'Amount', value: 'balance' },
  { name: 'Value(EUR)', value: 'price_eur' },
  { name: 'Last price', value: 'price_eur' },
  { name: '24h Change', value: 'price_eur' },
  { name: 'Last 7 Days', value: 'price_eur' },
];

type Props = {
  walletsSymbols: string[];
  componentError: Error | null;
  isMember: boolean;
  asyncGetTableChart: PayloadAC<typeof GET_TABLE_CHART, GetCryptoChartRequest>;
  tableChartState: TableChart;
};

const WalletCoinsTable = ({
  walletsSymbols,
  componentError,
  isMember,
  tableChartState,
  asyncGetTableChart,
}: Props) => {
  const classes = useStyles();
  useEffect(() => {
    asyncGetTableChart(tableChartState.selectedPeriodFilter);
  }, []);
  //Don't display EUR Wallet on the chart table
  const walletCoins = ALL_COINS.filter(coin => coin !== 'WALLET_EUR');

  // If there is any missing wallet, place dummy data in order to notify him
  // to buy membership or contact support
  if (walletsSymbols.length !== ALL_COINS.length) {
    walletsSymbols = [...new Set([...walletsSymbols, ...walletCoins])];
  }
  walletsSymbols = sortArrByPredefinedOrder(walletsSymbols, COINS_ORDER);
  return (
    <div className={classes.root}>
      {tableChartState.inProgress ? (
        <CircularProgress color="secondary" size={60} className={classes.circularProgress} />
      ) : componentError == null ? (
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {headers.map(header =>
                tableChartState.isFallback && header.name === 'Last 7 Days' ? null : (
                  <TableCell key={header.name} className={classes.header}>
                    {header.name}
                  </TableCell>
                ),
              )}
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {walletCoins.map(walletSymbol => {
              return (
                <WalletCoinsTableRow
                  key={walletSymbol}
                  tableChartState={tableChartState}
                  isMember={isMember}
                  walletSymbol={walletSymbol}
                />
              );
            })}
          </TableBody>
        </Table>
      ) : (
        <div className={classes.inputError}>{componentError}</div>
      )}
    </div>
  );
};

export default WalletCoinsTable;
