import { createSelector } from 'reselect';
import { AppState } from '../../../../../state/initialState';

const getExchangeCurrencyState = ({
  home: {
    exchange: { exchangeCurrency },
  },
}: AppState) => exchangeCurrency;

export const getExchangeCurrencySelector = createSelector(
  getExchangeCurrencyState,
  exchangeCurrency => exchangeCurrency,
);

const getMemberShipInfoState = ({
  home: {
    settings: {
      profileInfo: { affiliate_package_info },
    },
  },
}: AppState) => affiliate_package_info;

export const membershipStateSelector = createSelector(
  getMemberShipInfoState,
  affiliate_package_info => affiliate_package_info,
);
