import { all, put, takeEvery } from 'redux-saga/effects';
import LocalStorage from '../../../../../../data/LocalStorage';
import api from '../../../../../../data/repositories/account';
import {
  EnableTwoFactorAuthResponse,
  IsTwoFactorAuthEnabledResponse,
} from '../../../../../../data/repositories/account/typedefs';
import { USER_ID } from '../../../../../../utils/constants/localStorageKeys';
import {
  confirmTwoFactorAuth,
  confirmTwoFactorAuthFail,
  confirmTwoFactorAuthSuccess,
  CONFIRM_TWO_FACTOR_AUTH,
  disableTwoFactorAuthFail,
  disableTwoFactorAuthSuccess,
  DISABLE_TWO_FACTOR_AUTH,
  enableTwoFactorAuthFail,
  enableTwoFactorAuthSuccess,
  ENABLE_TWO_FACTOR_AUTH,
  isTwoFactorAuthEnabledFail,
  isTwoFactorAuthEnabledSuccess,
  IS_TWO_FACTOR_AUTH_ENABLED,
} from './actions';

export function* enableTwoFactorAuth$() {
  try {
    const userId = LocalStorage.get(USER_ID);
    const response: EnableTwoFactorAuthResponse = yield api.enableTwoFactorAuth(
      userId ? userId : '',
    );
    yield put(enableTwoFactorAuthSuccess(response));
  } catch (error) {
    yield put(enableTwoFactorAuthFail(error));
  }
}

export function* confirmTwoFactorAuth$(action: ReturnType<typeof confirmTwoFactorAuth>) {
  try {
    const userId = LocalStorage.get(USER_ID);
    yield api.confirmTwoFactorAuth(action.payload, userId ? userId : '');

    yield put(confirmTwoFactorAuthSuccess());
  } catch (error) {
    yield put(confirmTwoFactorAuthFail(error));
  }
}

export function* disableTwoFactorAuth$(action: ReturnType<typeof confirmTwoFactorAuth>) {
  try {
    const userId = LocalStorage.get(USER_ID);
    yield api.disableTwoFactorAuth(action.payload, userId ? userId : '');

    yield put(disableTwoFactorAuthSuccess());
  } catch (error) {
    yield put(disableTwoFactorAuthFail(error));
  }
}

export function* isTwoFactorAuthEnabled$() {
  try {
    const userId = LocalStorage.get(USER_ID);
    const response: IsTwoFactorAuthEnabledResponse = yield api.getUserInfo(userId ? userId : '');
    yield put(isTwoFactorAuthEnabledSuccess(response));
  } catch (error) {
    yield put(isTwoFactorAuthEnabledFail());
  }
}

export default function* () {
  yield all([
    takeEvery(ENABLE_TWO_FACTOR_AUTH, enableTwoFactorAuth$),
    takeEvery(CONFIRM_TWO_FACTOR_AUTH, confirmTwoFactorAuth$),
    takeEvery(DISABLE_TWO_FACTOR_AUTH, disableTwoFactorAuth$),
    takeEvery(IS_TWO_FACTOR_AUTH_ENABLED, isTwoFactorAuthEnabled$),
  ]);
}
