export const roles = {
  isUser(roles: string[] = []) {
    return roles.includes('user');
  },
  isAdmin(roles: string[] = []) {
    return roles.includes('admin');
  },
  isOwner(roles: string[] = []) {
    return roles.includes('owner');
  },
  isPremium(roles: string[] = []) {
    return roles.includes('premium_user');
  },
};
