import { createStandardAction } from 'typesafe-actions';
import {
  ConfirmTwoFactorAuthRequest,
  EnableTwoFactorAuthResponse,
  IsTwoFactorAuthEnabledResponse,
} from '../../../../../../data/repositories/account/typedefs';
import { TwoFactorAuthState } from './initialState';

export const OPEN_TWO_FACTOR_AUTH_DIALOG = 'settings/open_dialog';
export const SET_DIALOG_TITLE = 'settings/set_dialog_title';
export const ENABLE_TWO_FACTOR_AUTH = 'settings/enable_two_factor_auth';
export const ENABLE_TWO_FACTOR_SUCCESS = 'settings/enable_two_factor_auth_success';
export const ENABLE_TWO_FACTOR_FAIL = 'settings/enable_two_factor_auth_fail';
export const CONFIRM_TWO_FACTOR_AUTH = 'settings/confirm_two_factor_auth';
export const CONFIRM_TWO_FACTOR_AUTH_SUCCESS = 'settings/confirm_two_factor_auth_success';
export const CONFIRM_TWO_FACTOR_AUTH_FAIL = 'settings/confirm_two_factor_auth_fail';
export const DISABLE_TWO_FACTOR_AUTH = 'settings/disable_two_factor_auth';
export const DISABLE_TWO_FACTOR_AUTH_SUCCESS = 'settings/disable_two_factor_auth_success';
export const DISABLE_TWO_FACTOR_AUTH_FAIL = 'settings/disable_two_factor_auth_fail';
export const IS_TWO_FACTOR_AUTH_ENABLED = 'settings/is_two_factor_auth_enabled';
export const IS_TWO_FACTOR_AUTH_ENABLED_FAIL = 'settings/is_two_factor_auth_enabled_fail';
export const IS_TWO_FACTOR_AUTH_ENABLED_SUCCESS = 'settings/is_two_factor_auth_enabled_success';
export const TWO_FACTOR_AUTH_SAVE_STATE = 'settings/two_factor_auth_save_state';
export const CLEAR_TWO_FACTOR_AUTH_STATE = 'settings/clear_two_factor_auth_state';
export const IS_TWO_FACTOR_AUTH_DIALOG_OPEN = 'settings/is_two_factor_auth_dialog_open';

export const openTwoFactorAuth = createStandardAction(OPEN_TWO_FACTOR_AUTH_DIALOG)<number>();
export const setDialogTitle = createStandardAction(SET_DIALOG_TITLE)<string>();
export const enableTwoFactorAuth = createStandardAction(ENABLE_TWO_FACTOR_AUTH)<undefined>();
export const enableTwoFactorAuthSuccess =
  createStandardAction(ENABLE_TWO_FACTOR_SUCCESS)<EnableTwoFactorAuthResponse>();
export const enableTwoFactorAuthFail = createStandardAction(ENABLE_TWO_FACTOR_FAIL)<Error>();
export const confirmTwoFactorAuth =
  createStandardAction(CONFIRM_TWO_FACTOR_AUTH)<ConfirmTwoFactorAuthRequest>();
export const confirmTwoFactorAuthSuccess = createStandardAction(
  CONFIRM_TWO_FACTOR_AUTH_SUCCESS,
)<undefined>();
export const confirmTwoFactorAuthFail = createStandardAction(CONFIRM_TWO_FACTOR_AUTH_FAIL)<Error>();
export const disableTwoFactorAuth =
  createStandardAction(DISABLE_TWO_FACTOR_AUTH)<ConfirmTwoFactorAuthRequest>();
export const disableTwoFactorAuthSuccess = createStandardAction(
  DISABLE_TWO_FACTOR_AUTH_SUCCESS,
)<undefined>();
export const disableTwoFactorAuthFail = createStandardAction(DISABLE_TWO_FACTOR_AUTH_FAIL)<Error>();
export const twoFactorAuthSaveState = createStandardAction(
  TWO_FACTOR_AUTH_SAVE_STATE,
)<TwoFactorAuthState>();
export const isTwoFactorAuthEnabled = createStandardAction(IS_TWO_FACTOR_AUTH_ENABLED)<undefined>();
export const isTwoFactorAuthEnabledFail = createStandardAction(
  IS_TWO_FACTOR_AUTH_ENABLED_FAIL,
)<undefined>();
export const isTwoFactorAuthEnabledSuccess = createStandardAction(
  IS_TWO_FACTOR_AUTH_ENABLED_SUCCESS,
)<IsTwoFactorAuthEnabledResponse>();
export const clearTwoFactorAuthState = createStandardAction(
  CLEAR_TWO_FACTOR_AUTH_STATE,
)<undefined>();
export const isTwoFactorAuthDialogOpen = createStandardAction(
  IS_TWO_FACTOR_AUTH_DIALOG_OPEN,
)<boolean>();
