import { connect } from 'react-redux';
import { AppState } from '../../../../state/initialState';
import { asyncKycUpdateAplicant } from '../state/actions';
import KycDialog from './KycDialog';
import { profileInfoStateSelector } from '../../Settings/Membership/state/selectors';

const mapDispatchToProps = {
  asyncKycUpdateAplicant: asyncKycUpdateAplicant.request,
};

const mapStateToProps = (state: AppState) => {
  const profileInfo = profileInfoStateSelector(state);
  return { profileInfo };
};

export default connect(mapStateToProps, mapDispatchToProps)(KycDialog);
