import { CircularProgress, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { EmptyAC, PayloadAC } from 'typesafe-actions';
import CustomButtonPrimary from '../../../../../components/CustomButtons/CustomButtonPrimary';
import SacretError from '../../../../../components/SacretError';
import TransactionGroupingTable from '../../../../../components/TransactionGroupingTable';
import {
  ExportTransactionGroupingRequest,
  Filter,
  GetPaginatedDataRequest,
  HeaderProps,
} from '../../../../../data/repositories/admin/typedefs';
import {
  EXPORT_TRANSACTION_GROUPING,
  GET_TRANSACTION_GROUPING_PAGINATED,
  TRANSACTION_GROUPING_CLEAR_ALL_FILTERS,
} from './state/actions';
import { TransactionGroupingState } from './state/initialState';
import TransactionGroupingFilterBar from './TransactionGroupingFilterBar';

type Props = TransactionGroupingState & {
  asyncGetTransactionGroupingPaginated: PayloadAC<
    typeof GET_TRANSACTION_GROUPING_PAGINATED,
    GetPaginatedDataRequest
  >;
  transactionGroupingClearAllFilters: EmptyAC<typeof TRANSACTION_GROUPING_CLEAR_ALL_FILTERS>;
  asyncExportTransactionGrouping: PayloadAC<
    typeof EXPORT_TRANSACTION_GROUPING,
    ExportTransactionGroupingRequest
  >;
  exportInProgress: boolean;
  inProgress: boolean;
  appliedFilters?: Filter;
  error: string | null;
};

const headers: HeaderProps[] = [
  { name: '', align: 'center' },
  { name: 'Sender', align: 'left' },
  { name: 'Receiver', align: 'left' },
  { name: 'Status', align: 'left' },
  { name: 'Transaction type', align: 'left' },
  { name: 'Date & Time', align: 'left' },
  { name: 'Currency', align: 'right' },
  { name: 'Amount', align: 'right' },
];

const TransactionGrouping: React.FC<Props> = (props: Props): JSX.Element => {
  const {
    asyncGetTransactionGroupingPaginated,
    transactionGroupingClearAllFilters,
    asyncExportTransactionGrouping,
    inProgress,
    transactions,
    stats: { totalRecords },
    page,
    error,
    appliedFilters,
    exportInProgress,
  } = props;

  useEffect(() => {
    transactionGroupingClearAllFilters();
    asyncGetTransactionGroupingPaginated({ page: 0, perPage: 10 });
  }, []);
  if (error) {
    return <SacretError errorMessage={error} />;
  }
  const exportData = () => {
    const getFilters = (appliedFilters: Filter) => {
      const object: any = {};
      appliedFilters.forEach((value, key) => {
        object[key] = value;
      });
      return object;
    };
    asyncExportTransactionGrouping({
      page: 0,
      perPage: 10,
      appliedFilters: getFilters(appliedFilters),
    });
  };

  return (
    <div>
      <Grid container justifyContent="space-between">
        <Grid item sm={10}>
          <TransactionGroupingFilterBar />
        </Grid>

        <Grid item>
          {exportInProgress ? (
            <CircularProgress size={34} color="secondary" />
          ) : (
            <CustomButtonPrimary variant="outlined" size="medium" onClick={exportData}>
              Export
            </CustomButtonPrimary>
          )}
        </Grid>
      </Grid>

      <TransactionGroupingTable
        transactions={transactions}
        headers={headers}
        totalRecords={totalRecords ? totalRecords : 0}
        page={page}
        inProgress={inProgress}
        filter={appliedFilters}
      />
    </div>
  );
};

export default TransactionGrouping;
