import {
  Card,
  CardContent,
  CircularProgress,
  Grid,
  InputLabel,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import CustomButtonPrimary from '../../../../../components/CustomButtons/CustomButtonPrimary';
import TextInput from '../../../../../components/TextInput';
import { Colors } from '../../../../../ui/style/colors';
import { ProfileInfoState } from './state/initialState';

const styles = (theme: Theme) =>
  createStyles({
    card: {
      boxShadow: '0 10px 30px 0 rgba(132,141,182,0.25)',
      verticalAlign: 'sub',
      padding: 20,
    },
    inputFields: {
      marginTop: 5,
      height: 44,
      boxSizing: 'border-box',
      border: `1px solid ${theme.palette.secondary.light}`,
      borderRadius: 4,
      backgroundColor: `${Colors.white}`,
      paddingLeft: 12,
    },
    labels: {
      fontWeight: 'bold',
      color: theme.palette.primary.light,
      marginTop: 20,
      marginBottom: 8,
    },
    values: {
      color: theme.palette.primary.main,
      marginTop: 10,
      marginBottom: 8,
    },
    changePasswordLabel: {
      color: theme.palette.secondary.light,
      fontSize: 14,
      fontWeight: 800,
      letterSpacing: 1,
      textTransform: 'uppercase',
    },
    button: {
      marginTop: 40,
      textTransform: 'none',
      fontSize: 16,
      fontWeight: 600,
      letterSpacing: 1,
      textAlign: 'center',
    },
    buttonSuccess: {
      backgroundColor: `${Colors.havelockBlue}`,
      marginTop: 40,
      '&:hover': {
        backgroundColor: `${Colors.havelockBlue}`,
      },
    },
    inputError: {
      color: `${Colors.red}`,
      marginTop: 10,
    },
    tooltip: {
      position: 'relative',
      top: 5,
      left: 5,
      fontSize: 20,
    },
  });

const useStyles = makeStyles(styles);

type Props = {
  profileInfo: ProfileInfoState;
};

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 16,
  },
}))(Tooltip);

const Membership: React.FC<Props> = ({ profileInfo }: Props): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: 'profile_info' });
  }, []);

  const submit = (data: any) => {
    dispatch({
      type: 'update_payment_info',
      payload: { ...data },
    });
  };

  const resetState = () => {
    setTimeout(() => {
      dispatch({
        type: 'profile_info/reset_state',
      });
    }, 4000);
  };
  const initValues = {
    beneficiary: profileInfo.order_payment_info
      ? profileInfo.order_payment_info.beneficiary.String
      : '',
    iban: profileInfo.order_payment_info ? profileInfo.order_payment_info.iban.String : '',
    bic: profileInfo.order_payment_info ? profileInfo.order_payment_info.bic.String : '',
    beneficiary_address: profileInfo.order_payment_info
      ? profileInfo.order_payment_info.beneficiary_address.String
      : '',
    other_info: profileInfo.order_payment_info
      ? profileInfo.order_payment_info.other_info.String
      : '',
  };

  return (
    <Card className={classes.card}>
      <CardContent>
        <Formik
          enableReinitialize
          initialValues={initValues}
          onSubmit={(values, actions) => {
            actions.setSubmitting(false);
            submit(values);
          }}
          validationSchema={yup.object({
            etherium_address: yup.string(),
            beneficiary: yup.string(),
            iban: yup.string(),
            bic: yup.string(),
            beneficiary_address: yup.string(),
            other_info: yup.string(),
          })}
        >
          {({ isSubmitting }) => (
            <Form>
              <ErrorMessage
                className={classes.inputError}
                name="etherium_address"
                component="div"
              />
              <Typography className={classes.changePasswordLabel} component="p">
                Wire transfer details
              </Typography>
              <InputLabel className={classes.labels}>Beneficiary</InputLabel>
              <Field
                id="beneficiary"
                name="beneficiary"
                component={TextInput}
                className={classes.inputFields}
                disableUnderline
              />
              <ErrorMessage className={classes.inputError} name="beneficiary" component="div" />
              <InputLabel className={classes.labels}>IBAN</InputLabel>
              <Field
                id="iban"
                name="iban"
                component={TextInput}
                className={classes.inputFields}
                disableUnderline
              />
              <ErrorMessage className={classes.inputError} name="iban" component="div" />
              <InputLabel className={classes.labels}>BIC</InputLabel>
              <Field
                id="bic"
                name="bic"
                component={TextInput}
                className={classes.inputFields}
                disableUnderline
              />
              <ErrorMessage className={classes.inputError} name="bic" component="div" />
              <InputLabel className={classes.labels}>
                Beneficiary address
                <LightTooltip
                  className={classes.tooltip}
                  title={
                    <div>
                      You can use this field to provide details of the beneficiary bank branch for
                      payments to countries that don&apos;t use bank identification codes (BICs).
                    </div>
                  }
                >
                  <HelpOutlineIcon />
                </LightTooltip>
              </InputLabel>
              <Field
                id="beneficiary_address"
                name="beneficiary_address"
                component={TextInput}
                className={classes.inputFields}
                disableUnderline
              />
              <ErrorMessage
                className={classes.inputError}
                name="beneficiary_address"
                component="div"
              />
              <InputLabel className={classes.labels}>Other information</InputLabel>
              <Field
                id="other_info"
                name="other_info"
                component={TextInput}
                className={classes.inputFields}
                disableUnderline
              />
              <ErrorMessage className={classes.inputError} name="other_info" component="div" />

              <Grid container alignItems="center" justifyContent="center">
                {profileInfo.progressState.error != null ? (
                  <Grid item xs={12} className={classes.inputError}>
                    {profileInfo.progressState && profileInfo.progressState.error}
                  </Grid>
                ) : null}
                {profileInfo.progressState.inProgress ? (
                  <CircularProgress />
                ) : (
                  <CustomButtonPrimary
                    type="submit"
                    variant="contained"
                    size="large"
                    fullWidth
                    onClick={resetState}
                    className={
                      profileInfo.progressState.success ? classes.buttonSuccess : classes.button
                    }
                    disabled={isSubmitting}
                  >
                    {profileInfo.progressState.success ? 'Succesfully changed!' : 'Save'}
                  </CustomButtonPrimary>
                )}
              </Grid>
            </Form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
};

export default Membership;
