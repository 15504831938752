import { Chip, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { createStyles, makeStyles } from '@material-ui/styles';
import React from 'react';
import { Colors } from '../../ui/style/colors';

type Props = {
  chipsArray: string[];
  onDelete: (chip: string) => void;
  scrollChips?: boolean;
};

const styles = () =>
  createStyles({
    chip: {
      backgroundColor: `${Colors.spunPearl}`,
      '&:hover': {
        backgroundColor: `${Colors.ghost}`,
      },
      color: `${Colors.white}`,
      margin: 10,
      padding: 5,
    },
    clearChipIcon: {
      fontSize: 14,
      padding: 2,
      color: `${Colors.white}`,
    },
    scroll: {
      height: 200,
      overflowY: 'scroll',
    },
  });

const useStyles = makeStyles(styles);

const Chips: React.FC<Props> = ({ chipsArray, onDelete, scrollChips }: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <Grid item xs={12} className={scrollChips && chipsArray.length > 7 ? classes.scroll : ' '}>
      {chipsArray.map((chip, index) => (
        <Chip
          key={index}
          size="small"
          clickable={true}
          deleteIcon={<CloseIcon className={classes.clearChipIcon} />}
          label={chip}
          onDelete={() => onDelete(chip)}
          className={classes.chip}
        />
      ))}
    </Grid>
  );
};

export default Chips;
