import { connect } from 'react-redux';
import { AppState } from '../../../../../App/state/initialState';
import {
  asyncGetSwapInfo,
  isTransactionHashDialogOpen,
  setTransactionHashDialogTxId,
} from '../state/actions';
import { getTransactionHashDialogStateSelector } from '../state/selectors';
import TransactionHashDialog from './TransactionHashDialog';

const mapDispatchToProps = {
  asyncGetSwapInfo: asyncGetSwapInfo.request,
  isTransactionHashDialogOpen: isTransactionHashDialogOpen,
  setTransactionHashDialogTxId: setTransactionHashDialogTxId,
};

const mapStateToProps = (state: AppState) => {
  const transactionHashDialogState = getTransactionHashDialogStateSelector(state);
  return { transactionHashDialogState };
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionHashDialog);
