import { connect } from 'react-redux';
import AppliedFiltersChips from './AppliedFiltersChips';
import { AppState } from '../../../../../../../state/initialState';
import { getAppliedFiltersIdsSelector, getAreAnyFiltersApplied } from '../../../state/selectors';
import { asyncGetAllTransactionsPaginated } from '../../../state/actions';

const mapStateToProps = (state: AppState) => {
  const appliedFiltersIds = getAppliedFiltersIdsSelector(state);
  const areFiltersApplied = getAreAnyFiltersApplied(state);
  return { appliedFiltersIds, areFiltersApplied };
};

const mapDispatchToProps = {
  asyncGetAllTransactionsPaginated: asyncGetAllTransactionsPaginated.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppliedFiltersChips);
