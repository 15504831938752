export type DrawerState = {
  isOpen: boolean;
  isMobileDevice: boolean;
  isHovering: boolean;
};

export const initialState: DrawerState = {
  isOpen: true,
  isMobileDevice: false,
  isHovering: false,
};
