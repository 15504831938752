import { InputAdornment, Slide, Snackbar, TextField } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import React from 'react';
import Shape from '../../assets/Shape.svg';
import { Colors } from '../../ui/style/colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '100px',
        marginTop: '10px',
      },
    },
    input: {
      fontSize: 12,
      '-webkit-inner-spin-button': '-webkit-appearance: none;',
      '-webkit-outer-spin-button': '-webkit-appearance: none',
      padding: 0,
    },
    inputWrapper: {
      marginLeft: 10,
      position: 'relative',
      '&:hover, &:focus': {
        cursor: 'pointer',
        border: 'none',
        outline: 'none',
      },
    },
    snackbar: {
      background: `${Colors.apple}`,
    },
    copyIconWrapper: {
      position: 'absolute',
      zIndex: 9,
      right: 5,
      top: 15,
    },
  }),
);

type Props = {
  text: string;
  customClass?: string;
};

const OutlinedTextField: React.FC<Props> = ({ text, customClass = '' }: Props): JSX.Element => {
  const classes = useStyles();
  const TransitionUp = (props: TransitionProps) => <Slide {...props} direction="up" />;

  const [snackbarOpen, setsnackbarOpen] = React.useState(false);
  const [transition, setTransition] = React.useState<
    React.ComponentType<TransitionProps> | undefined
  >(undefined);

  const handleSnackbarClose = () => setsnackbarOpen(false);

  const handleSnackbarOpen = () => {
    setTransition(() => TransitionUp);
    setsnackbarOpen(true);
  };

  const copyClipboard = () => {
    const copyText = document.getElementById('textField') as HTMLInputElement;
    copyText.select();
    document.execCommand('Copy');
    copyText.blur();
    handleSnackbarOpen();
  };

  return (
    <div>
      <div className={classes.inputWrapper}>
        <TextField
          className={customClass}
          inputProps={{
            style: {
              height: '10px',
              padding: '10px 25px 10px 10px',
            },
          }}
          id="textField"
          variant="outlined"
          value={
            text !== '0x'
              ? text
              : 'It takes some time to generate the address, please try again later'
          }
        />
        <InputAdornment className={classes.copyIconWrapper} position="end" onClick={copyClipboard}>
          <img src={Shape} />
        </InputAdornment>
      </div>
      <Snackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        TransitionComponent={transition}
        ContentProps={{
          'aria-describedby': 'message-id',
          classes: {
            root: classes.snackbar,
          },
        }}
        message={<span id="message-id">Copied</span>}
        autoHideDuration={1000}
      />
    </div>
  );
};

export default OutlinedTextField;
