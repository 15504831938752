import {
  Card,
  CardContent,
  createStyles,
  Grid,
  makeStyles,
  MenuItem,
  Typography,
} from '@material-ui/core';
import React from 'react';
import SVG from 'react-inlinesvg';
import { useDispatch, useSelector } from 'react-redux';
import { PayloadAC } from 'typesafe-actions';
import LightTooltip from '../../../../../../../components/LightTooltip';
import { Coin } from '../../../../../../../data/repositories/wallet/typedefs';
import { Colors } from '../../../../../../../ui/style/colors';
import {
  getCurrencySymbolName,
  getWalletNameBySymbol,
  isAllowedCurrencyForNonMembers,
  isMissignWallet,
} from '../../../../../../../utils/helper/utils';
import { AppState } from '../../../../../../state/initialState';
import { isRestrictedCoinWithoutKYC } from '../../../../Settings/state/selectors';
import { INVEST_CURRENCY_SAVE_STATE } from '../../state/actions';
import { ProductCurrencyState } from '../../state/initialState';

const styles = createStyles({
  container: {
    '&:nth-child(1)': { paddingLeft: 0 },
    '&:nth-child(3)': { paddingRight: 0 },
    '&:nth-child(4)': { paddingLeft: 0, paddingTop: 9, paddingBottom: 0 },
    '&:nth-child(5)': { paddingTop: 9, paddingBottom: 0 },
    position: 'relative',
  },
  card: {
    borderRadius: 4,
    backgroundColor: `${Colors.whiteLilac2}`,
    boxShadow: 'none',
    padding: 8,
  },
  coinName: {
    color: 'currentColor',
    fontSize: 16,
    fontWeight: 600,
    textAlign: 'center',
    width: '100%',
  },
  coinSymbol: {
    color: 'currentColor',
    fontSize: 14,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  listItem: {
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  menuItemSelected: {
    boxSizing: 'border-box',
    height: 90,
    border: `1px solid ${Colors.mediumTurquoise}`,
    color: `${Colors.mediumTurquoise} !important`,
    backgroundColor: 'transparent !important',
    boxShadow: '0 10px 30px 0 rgba(132,141,182,0.25) !important',
  },
  menuListItem: {
    padding: 0,
    borderRadius: 4,
    color: `${Colors.logan}`,
    boxShadow: 'none',
  },
  icon: {
    width: 30,
    height: 30,
  },
  disabledPointer: {
    cursor: 'default',
  },
  tooltip: {
    position: 'absolute',
    left: 'auto',
    right: 20,
    bottom: 20,
    top: 'auto',
  },
  unavailableLink: {
    color: `${Colors.gray}`,
    '&:hover': {
      color: `${Colors.black}`,
    },
  },
});

const useStyles = makeStyles(styles);

type Props = ProductCurrencyState & {
  coin: Coin;
  setSelectedOption: PayloadAC<'invest/set_selected_option', string>;
  isMember: boolean;
  hasCompletedKYC: boolean;
};

const InvestCurrencyBuyOptionsItem = ({
  coin,
  setSelectedOption,
  selectedOption,
  selectedTab,
  isMember = false,
  hasCompletedKYC,
}: Props): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const setSelectedOptionn = (symbol: string) => {
    if (selectedTab) {
      dispatch({
        type: INVEST_CURRENCY_SAVE_STATE,
        payload: {
          selectedOption: symbol === 'WIRE_EUR' ? 'SLC' : symbol,
        },
      });
    } else setSelectedOption(symbol);
  };

  const userNotAllowed =
    !isMember &&
    !isAllowedCurrencyForNonMembers(hasCompletedKYC, coin.symbol) &&
    coin.symbol !== 'WIRE_EUR';
  const missingWallet = isMissignWallet(coin.symbol);

  const isRestricted = useSelector((state: AppState) =>
    isRestrictedCoinWithoutKYC(coin.symbol)(state),
  );

  const getTooltip = () => {
    const walletType = getWalletNameBySymbol(coin.symbol);
    if (isRestricted) {
      return (
        <LightTooltip
          variant="kyc"
          interactive={true}
          className={classes.tooltip}
          walletType={walletType}
        />
      );
    }
    if (userNotAllowed) {
      return (
        <LightTooltip
          variant="membership"
          interactive={true}
          className={classes.tooltip}
          walletType={walletType}
        />
      );
    }
    if (missingWallet) {
      return (
        <LightTooltip
          variant="buyAddress"
          symbolName={coin.symbol}
          interactive={true}
          className={classes.tooltip}
        />
      );
    }
  };

  return (
    <Grid item xs={4} className={classes.container}>
      <MenuItem
        button
        key={coin.symbol}
        selected={
          coin.symbol === selectedOption ||
          (selectedTab === 1 && coin.symbol === 'WIRE_EUR' && selectedOption === 'SLC')
        }
        classes={{ selected: classes.menuItemSelected }}
        className={`${classes.menuListItem} ${userNotAllowed && classes.disabledPointer}`}
        onClick={() => setSelectedOptionn(coin.symbol)}
        disabled={userNotAllowed || missingWallet || isRestricted}
      >
        <Card className={classes.card} style={{ width: '100%', color: 'currentColor' }}>
          <CardContent style={{ width: '100%', padding: 0 }}>
            <div className={classes.listItem} style={{ width: '100%' }}>
              <SVG
                className={classes.icon}
                src={coin.icon}
                preProcessor={code => code.replace(/fill="#A3AACB"/g, 'fill="currentColor"')}
              />
            </div>
            <Typography className={classes.coinName}>{coin.type || coin.name}</Typography>
            <div style={{ position: 'relative' }}>
              <Typography className={classes.coinSymbol}>
                {getCurrencySymbolName(coin.symbol)}
              </Typography>
            </div>
          </CardContent>
        </Card>
      </MenuItem>
      {getTooltip()}
    </Grid>
  );
};
export default InvestCurrencyBuyOptionsItem;
