import { connect } from 'react-redux';
import { AppState } from '../../../../state/initialState';
import { asyncGetTableChart } from '../state/actions';
import { getComponentError, getTableChartStateSelector } from '../state/selectors';
import WalletCoinsTable from './WalletCoinsTable';

const mapStateToProps = (state: AppState) => {
  const componentError = getComponentError(state);
  const tableChartState = getTableChartStateSelector(state);
  return { componentError, tableChartState };
};
const mapDispatchToProps = {
  asyncGetTableChart: asyncGetTableChart.request,
};
export default connect(mapStateToProps, mapDispatchToProps)(WalletCoinsTable);
