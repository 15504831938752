import AffiliateAddDeduct2fa from './AffiliateAddDeduct2fa';
import { connect } from 'react-redux';
import { AppState } from '../../../../state/initialState';
import { twoFactorAuthEnableSelector } from '../../Settings/SettingsTwoFactorAuth/state/selectors';
import { getDialogStateSelector } from '../state/selectors';
import { asyncTradeSLC } from '../state/actions';

const mapDispatchToProps = {
  asyncTradeSLC: asyncTradeSLC.request,
};
const mapStateToProps = (state: AppState) => ({
  is2FaEnabled: twoFactorAuthEnableSelector(state),
  dialogState: getDialogStateSelector(state),
});
export default connect(mapStateToProps, mapDispatchToProps)(AffiliateAddDeduct2fa);
