import { connect } from 'react-redux';
import { AppState } from '../../../../../state/initialState';
import { getWalletTransfersPaginated } from '../../../Transactions/state/actions';

import { getAppliedFiltersIdsSelector, getAreAnyFiltersApplied } from '../../state/selectors';
import AppliedFiltersChips from './AppliedFiltersChips';

const mapStateToProps = (state: AppState) => {
  const appliedFiltersIds = getAppliedFiltersIdsSelector(state);
  const areFiltersApplied = getAreAnyFiltersApplied(state);
  return { appliedFiltersIds, areFiltersApplied };
};

const mapDispatchToProps = {
  getWalletTransfersPaginated: getWalletTransfersPaginated.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppliedFiltersChips);
