import { Grid } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { EmptyAC, PayloadAC } from 'typesafe-actions';
import SacretDaterangePickerSelect from '../../../../../components/SacrateDaterangePickerSelect';
import SacretSearchInput from '../../../../../components/SacretSearchInput';
import SacretSelect from '../../../../../components/SacretSelect';
import LocalStorage from '../../../../../data/LocalStorage';
import { GetFriendsInvitationsPaginatedRequest } from '../../../../../data/repositories/invites/typedef';
import { USER_ID } from '../../../../../utils/constants/localStorageKeys';
import InvitesFiltersChips from './InvitesFiltersChips';

const styles = () =>
  createStyles({
    root: {
      marginTop: '10px',
    },
    filter: {
      minWidth: 200,
      marginRight: 10,
    },
    minWidth: {
      minWidth: 236,
    },
  });

const useStyles = makeStyles(styles);

type Props = {
  toggleFilter: PayloadAC<'invites/toggle_filters', any>;
  appliedFilters: Map<string, string[]>;
  clearAllFilters: EmptyAC<'invites/clear_all_filters'>;
  asyncGetFriendsInvitationsPaginated: PayloadAC<
    'invites/get_friends_invitations',
    GetFriendsInvitationsPaginatedRequest
  >;
};

const statuses = [
  { name: 'Completed', value: 'completed' },
  { name: 'Pending', value: 'pending' },
];

const InvitesFilterBar: React.FC<Props> = ({
  toggleFilter,
  appliedFilters,
  clearAllFilters,
  asyncGetFriendsInvitationsPaginated,
}: Props): JSX.Element => {
  const [emailSearchValue, setEmailSearchValue]: any = useState('');
  const classes = useStyles();

  const accountId = LocalStorage.get(USER_ID);

  const appliedEmailFilter = (value: string) => {
    const object: any = {};
    appliedFilters.forEach((value, key) => {
      object[key] = value;
    });
    accountId &&
      asyncGetFriendsInvitationsPaginated({
        accountId: accountId,
        page: 0,
        perPage: 10,
        appliedFilters: { ...object, searchInput: value },
      });
    toggleFilter({ filterType: 'searchInput', value });
    setEmailSearchValue('');
  };

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12} sm="auto" className={`${classes.filter} ${classes.minWidth}`}>
        <SacretSearchInput
          onChange={(value: string[]) => setEmailSearchValue(value)}
          stateFilterValue={[emailSearchValue]}
          searchHandler={() => appliedEmailFilter(emailSearchValue)}
        />
      </Grid>
      <Grid xs={12} sm="auto" item className={`${classes.filter} ${classes.minWidth}`}>
        <SacretDaterangePickerSelect
          label="Choose invitation date"
          onChange={(value: string[]) => {
            const object: any = {};
            appliedFilters.forEach((value, key) => {
              object[key] = value;
            });
            accountId &&
              asyncGetFriendsInvitationsPaginated({
                accountId: accountId,
                page: 0,
                perPage: 10,
                appliedFilters: { ...object, dateFilter: value },
              });
            toggleFilter({ filterType: 'dateFilter', value });
          }}
          stateFilterValue={appliedFilters.get('dateFilter')}
        />
      </Grid>
      <Grid xs={12} sm="auto" item className={classes.filter}>
        <SacretSelect
          items={statuses}
          label="Status"
          onChange={(value: string[]) => {
            const object: any = {};
            appliedFilters.forEach((value, key) => {
              object[key] = value;
            });
            accountId &&
              asyncGetFriendsInvitationsPaginated({
                accountId: accountId,
                page: 0,
                perPage: 10,
                appliedFilters: { ...object, status: value },
              });
            toggleFilter({ filterType: 'status', value });
          }}
          stateFilterValue={appliedFilters.get('status')}
        />
      </Grid>

      <InvitesFiltersChips
        accountId={accountId}
        clearAllFilters={clearAllFilters}
        stateAppliedFilters={appliedFilters}
        toggleFilter={toggleFilter}
      />
    </Grid>
  );
};

export default InvitesFilterBar;
