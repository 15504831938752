import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Big from 'big.js';
import React, { useEffect, useState } from 'react';
import { EmptyAC, PayloadAC } from 'typesafe-actions';
import CustomButtonPrimary from '../../../../../components/CustomButtons/CustomButtonPrimary';
import IOSSwitch from '../../../../../components/IOSSwitch';
import ModalState from '../../../../../components/ModalState';
import SelectPackage from '../../../../../components/SelectPackage';
import { Colors } from '../../../../../ui/style/colors';
import balanceUtils from '../../../../../utils/balanceUtils';
import numberUtils from '../../../../../utils/numberUtils';
import {
  DEPOSIT_TERM_SAVING,
  GET_TERM_SAVING_TYPES,
  IS_INVEST_DIALOG_OPEN,
  SET_TERM_SAVING_PACKAGE,
} from '../state/actions';
import { DepositTermRequest, InvestDialogState, termPackageObject } from '../state/initialState';

const styles = (theme: Theme) =>
  createStyles({
    dialog: {
      height: 'center',
      width: 'center',
      borderRadius: 6,
      backgroundColor: 'transparent',
      paddingLeft: 30,
      paddingRight: 30,
    },
    label: {
      color: `${Colors.blueCharcoal}`,
      fontSize: 18,
      fontWeight: 600,
      letterSpacing: 1.13,
    },
    button: {
      marginTop: 30,
      marginBottom: 30,
    },
    closeButton: {
      position: 'absolute',
      right: 5,
      top: 5,
      color: theme.palette.primary.light,
    },
    dialogTitle: {
      marginTop: 25,
      marginLeft: 25,
      marginRight: 25,
      padding: 0,
    },
    dialogTittleText: {
      color: `${Colors.blueCharcoal}`,
      fontSize: 18,
      fontWeight: 600,
      letterSpacing: 1.13,
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        position: 'relative',
        left: '-5px',
        bottom: '1px',
      },
    },
    inputLabel: {
      color: theme.palette.primary.light,
      fontSize: 12,
      fontWeight: 600,
      marginBottom: 10,
    },
    disabledInputLabel: {
      color: `${Colors.blueHaze}`,
      fontSize: 12,
      fontWeight: 600,
      marginBottom: 10,
    },
    value: {
      color: `${Colors.blueCharcoal}`,
      textAlign: 'center',
      marginBottom: 10,
      fontSize: 14,
    },
    additionalInfoLabel: {
      '& p': {
        fontSize: '16px',
        color: `${Colors.black}`,
        fontWeight: 600,
      },
    },
    input: {
      '&::placeholder': {
        color: theme.palette.secondary.light,
      },
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'none',
      },
      '&[type=number]': {
        '-webkit-appearance': 'textfield',
        '-moz-appearance': 'textfield',
      },
    },
    inputError: {
      color: `${Colors.red}`,
      marginTop: '5px',
    },
    currencyBalance: {
      fontSize: 12,
      fontWeight: 600,
      marginLeft: 3,
    },
    currencyLabel: {
      color: theme.palette.primary.light,
      fontSize: 12,
      fontWeight: 600,
    },
    switchLabel: {
      color: theme.palette.primary.light,
      fontSize: 14,
      marginLeft: 16,
    },
    escrow: {
      paddingTop: '2rem',
    },
  });
const useStyles = makeStyles(styles);

type Props = {
  isInvestDialogOpen: PayloadAC<typeof IS_INVEST_DIALOG_OPEN, boolean>;
  investDialogState: InvestDialogState;
  asyncGetTermSavingTypes: EmptyAC<typeof GET_TERM_SAVING_TYPES>;
  setTermSavingPackage: PayloadAC<typeof SET_TERM_SAVING_PACKAGE, termPackageObject>;
  asyncDepositTermSaving: PayloadAC<typeof DEPOSIT_TERM_SAVING, DepositTermRequest>;
  walletBalance?: string;
  walletDecimals?: number;
  totalEscrow?: string;
};

const displayPaymentFrequencyType = (paymentType: string) => {
  switch (paymentType) {
    case 'end_of_period':
      return 'End Of Period';
    case 'monthly_payment':
      return 'Monthly';
    default:
      return paymentType;
  }
};

const InvestDialog: React.FC<Props> = ({
  isInvestDialogOpen,
  investDialogState,
  asyncGetTermSavingTypes,
  setTermSavingPackage,
  asyncDepositTermSaving,
  walletBalance,
  walletDecimals,
  totalEscrow,
}: Props) => {
  const classes = useStyles();
  const [amount, setAmount] = useState(0);
  const [interestAmount, setInterestAmount] = useState(0);
  const [isEscrow, setIsEscrow] = useState(false);
  const [isMsgModalOpen, setIsMsgModalOpen] = useState(false);
  const [shouldOpen, setShouldOpen] = useState(false);
  useEffect(() => {
    asyncGetTermSavingTypes();
  }, []);

  const onExit = () => {
    isInvestDialogOpen(false);
  };

  const walletBalanceBig = Big(
    walletBalance
      ? walletBalance
        ? balanceUtils.formatBalanceToBigWithDecimals(walletBalance, walletDecimals)
        : '0'
      : '0',
  );
  const walletBalanceString = balanceUtils.formatBigToStringWithCommasAndRound(
    walletBalanceBig,
    2,
    2,
  );

  const escrowBalanceBig = Big(
    totalEscrow
      ? totalEscrow
        ? balanceUtils.formatBalanceToBigWithDecimals(totalEscrow, walletDecimals)
        : '0'
      : '0',
  );

  const escrowBalanceString = balanceUtils.formatBigToStringWithCommasAndRound(
    escrowBalanceBig,
    2,
    2,
  );

  const isInvestAllowed = investDialogState.selectedPackage && amount > 0;

  const handleSubmit = () => {
    asyncDepositTermSaving({
      amount: balanceUtils.parseNumberToBig(amount),
      term_saving_type_name: investDialogState.selectedPackage.name,
      balance_status: isEscrow ? 'escrow' : 'completed',
    });
    setShouldOpen(true);
  };

  const handleAmountChange = (investAmount: any) => {
    setAmount(investAmount);

    const interestRate = investDialogState.selectedPackage.interest_rate / 100;
    const period = investDialogState.selectedPackage.time_period;

    setInterestAmount(((interestRate / 12) * period * Number(investAmount)) / 100);
  };

  useEffect(() => {
    handleAmountChange(Number(isEscrow ? escrowBalanceString : walletBalanceString));
  }, [isEscrow]);

  useEffect(() => {
    if (amount === 0) {
      handleAmountChange(Number(isEscrow ? escrowBalanceString : walletBalanceString));
    } else {
      handleAmountChange(amount);
    }
  }, [investDialogState.selectedPackage]);

  if (
    shouldOpen &&
    !investDialogState.errorMessage &&
    !investDialogState.investDialogOpened &&
    !investDialogState.inProgress
  ) {
    setIsMsgModalOpen(true);
    setShouldOpen(false);
  }
  return (
    <>
      <Dialog open={isMsgModalOpen} className={classes.dialog}>
        <ModalState
          onExit={() => setIsMsgModalOpen(false)}
          message="Transaction completed!"
          variant="success"
          showCloseButton={true}
        />
      </Dialog>
      <Dialog
        open={investDialogState.investDialogOpened}
        className={classes.dialog}
        TransitionProps={{ onExited: onExit }}
      >
        <DialogTitle disableTypography className={classes.dialogTitle}>
          <Grid container item xs={12} justifyContent="space-between">
            <Typography className={classes.dialogTittleText}>
              HODL SLC FROM {isEscrow ? 'ESCROW' : 'TOTAL BALANCE'}
            </Typography>

            {onExit ? (
              <IconButton className={classes.closeButton} onClick={onExit}>
                <CloseIcon />
              </IconButton>
            ) : null}
          </Grid>
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={4}>
            {+escrowBalanceString > 0 && (
              <Grid className={classes.escrow} item xs={12}>
                <IOSSwitch
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setIsEscrow(event.target.checked)
                  }
                  checked={isEscrow}
                />
                <Typography className={classes.switchLabel} display="inline" component="p">
                  Switch to HODL from {isEscrow ? 'balance' : 'escrow'}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <Typography className={classes.inputLabel}>SLC amount:</Typography>
              <TextField
                variant="outlined"
                placeholder="0.00"
                fullWidth
                value={amount}
                onKeyPress={event => numberUtils.allowOnlyFloatNumbers(event)}
                onChange={event => handleAmountChange(event.target.value)}
                InputProps={{
                  style: {
                    height: 50,
                    paddingRight: 0,
                  },
                }}
              />

              {!!investDialogState.errorMessage && (
                <Typography className={classes.inputError}>
                  {investDialogState.errorMessage}
                </Typography>
              )}
              <Grid container item direction="row" style={{ marginTop: 10 }}>
                <Typography className={classes.currencyLabel}>
                  {isEscrow ? 'Total escrow:' : 'SLC Balance:'}
                </Typography>
                <Typography color="secondary" className={classes.currencyBalance}>
                  {isEscrow ? escrowBalanceString : walletBalanceString}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className={classes.disabledInputLabel}>SLC interest amount:</Typography>
              <TextField
                variant="outlined"
                placeholder="0.00"
                fullWidth
                disabled
                value={interestAmount}
                InputProps={{
                  style: {
                    height: 50,
                    paddingRight: 0,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.inputLabel}>Select HODL option:</Typography>
              <SelectPackage
                setTermSavingPackage={setTermSavingPackage}
                allPackages={investDialogState.termPackageTypes}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Typography className={classes.disabledInputLabel}>Period (months):</Typography>
              <TextField
                disabled
                variant="outlined"
                placeholder="0.00"
                fullWidth
                value={investDialogState.selectedPackage.time_period}
                InputProps={{
                  style: {
                    height: 50,
                    paddingRight: 0,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography className={classes.disabledInputLabel}>Interest payment:</Typography>
              <TextField
                disabled
                variant="outlined"
                placeholder="0.00"
                fullWidth
                value={displayPaymentFrequencyType(
                  investDialogState.selectedPackage.payment_frequency_type,
                )}
                InputProps={{
                  style: {
                    height: 50,
                    paddingRight: 0,
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Typography className={classes.disabledInputLabel}>Yearly rate:</Typography>
              <TextField
                disabled
                variant="outlined"
                placeholder="0.00"
                fullWidth
                value={`${investDialogState.selectedPackage.interest_rate / 100}%`}
                InputProps={{
                  style: {
                    height: 50,
                    paddingRight: 0,
                  },
                }}
              />
            </Grid>
          </Grid>
          <CustomButtonPrimary
            type="submit"
            className={classes.button}
            variant="contained"
            fullWidth
            onClick={handleSubmit}
            disabled={
              !isInvestAllowed ||
              amount > Number(isEscrow ? escrowBalanceString : walletBalanceString) ||
              investDialogState.inProgress
            }
          >
            {investDialogState.inProgress ? 'Loading' : 'Confirm'}
          </CustomButtonPrimary>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default InvestDialog;
