import { createSelector } from 'reselect';
import { isUserMember } from '../../../../../../utils/helper/utils';
import { AppState } from '../../../../../state/initialState';

const getInvestCurrencyState = ({
  home: {
    product: { productInvest },
  },
}: AppState) => productInvest;

export const getInvestCurrencySelector = createSelector(
  getInvestCurrencyState,
  productInvest => productInvest,
);

export const getInvestDialog = createSelector(
  getInvestCurrencySelector,
  ({ isDialogOpen }) => isDialogOpen,
);

const getMemberShipInfoState = ({
  home: {
    settings: {
      profileInfo: { affiliate_package_info },
    },
  },
}: AppState) => affiliate_package_info;

const getProfileInfoState = ({
  home: {
    settings: { profileInfo },
  },
}: AppState) => profileInfo;

export const membershipStateSelector = createSelector(
  getMemberShipInfoState,
  affiliate_package_info => affiliate_package_info,
);

/** If user has no affiliate package and has no KYC, he is not allowed to use any currency until he passes KYC*/
export const shouldDisableAllCurrencies = createSelector(
  getProfileInfoState,
  profileInfo =>
    !isUserMember(profileInfo.affiliate_package_info) &&
    profileInfo.account_info.kyc_status.toLowerCase() !== 'completed',
);

export const getProductsSelectedPaymentOption = createSelector(
  getInvestCurrencyState,
  ({ selectedOption }) => selectedOption,
);
