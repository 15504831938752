import React from 'react';
import { SUPPORT_EMAIL } from '../../../utils/constants/info';
import '../Dashboard.scss';
import DashboardFooter from '../DashboardFooter';
import DashboardHeader from '../DashboardHeader';
import '../index.css';
import '../js/scripts.js';

const TermsOfUse = (): JSX.Element => (
  <div className="dashboard">
    <DashboardHeader />
    <div className="pages">
      <div className="header-wrapper">
        <div className="content">
          <h1>Terms &#38; Conditions</h1>
        </div>
      </div>
      <div className="content-wrapper">
        <div className="content">
          <p>The General Terms and Conditions apply from: 15.04.2020</p>
          <p>
            These General Terms and Conditions apply to all activities enabled on the Sacret Life
            Online Platform that can be accessed at:{' '}
            <a href="https://sleo.io" target="_blank" rel="noopener noreferrer">
              https://sleo.io{' '}
            </a>
            (hereinafter Platform or Web Platform), such as the registration of a user account, the
            use of the online store, the use of a Video Platform, event logging, etc. (hereinafter
            Services).
          </p>
          <p>
            The General Terms and Conditions are binding for all Users, and the General Terms and
            Conditions apply to all services offered on the Platform. However, in addition to the
            General Terms and Conditions, the specific Service may also be subject to specific
            provisions that are clearly indicated in each training program or module.
          </p>
          <p>
            By using the Services, the User confirms that he/she is familiar with, understands and
            accepts the entire content of these General Terms and Conditions.
          </p>
          <p>
            The Web Platform and all the available Services are provided by SL EOOD, with its
            registered office at Opalchenska str. 46-48, fl. 7, Sofia 1303, Bulgaria. EN EOOD
            provides the Services in cooperation with Partners within the meaning of these Terms and
            Conditions.
          </p>
          <p>
            Please read the Terms and Conditions carefully. If You do not agree to our Terms and
            Conditions, in whole or in part, You cannot use our Platform and/or Services. The mere
            use of the Platform shall be deemed consent to these Terms and Conditions. The General
            Terms and Conditions constitute an Agreement between You and SL EOOD.
          </p>
          <p>
            If You would like to use our platform as a Partner we invite You to register here and
            apply to become a partner using &quot;Become a partner&quot; link. The prices of
            Partnership packages can be seen here
          </p>
          <p>
            In order to become a Partner, it is necessary to conclude a Business Cooperation
            Agreement.
          </p>
          <p>
            If You would like to use our Platform as an Affiliate, we invite you to apply here. The
            status of an Affiliate depends on the acceptance of the General Terms and Conditions for
            enrolment in the Affiliate Program.
          </p>
          <p>
            These General Terms and Conditions are binding on all Users, including those who receive
            Partner and/or Affiliate status. In the event that these General Terms and Conditions
            are inconsistent with the Business Cooperation Agreement or the General Conditions for
            Enrolment in the Affiliation Program, the latter documents shall prevail.
          </p>
          <p>
            The Web Platform is available in the “as is” form, SL EOOD makes no direct or indirect
            warranties or guarantees with respect to ownership, marketing, or suitability for any
            purpose for the Services provided on the Web Platform.
          </p>
          <h2>Definitions</h2>
          <p>
            &quot;SL EOOD&quot; is the owner and controller of the Sacret Life Web Platform, which
            can be accessed at{' '}
            <a href="https://sleo.io" target="_blank" rel="noopener noreferrer">
              https://sleo.io
            </a>
            .
          </p>
          <p>
            The &quot;Web Platform&quot; or &quot;Platform&quot; is a website accessible at{' '}
            <a href="https://sleo.io" target="_blank" rel="noopener noreferrer">
              https://sleo.io
            </a>
            , managed by SL EOOD, where Partners offer their Services related to a healthy lifestyle
            and spirituality. The Platform is a marketing platform where Services are provided by
            third parties working in Partnership Agreement with SL EOOD.
          </p>
          <p>
            &quot;User&quot;, &quot;You&quot; or &quot;Your&quot; means any natural or legal person
            who uses the Services on the Platform or obtains Partner or Affiliate status under the
            Terms and Conditions applicable to obtaining individual status.
          </p>
          <p>
            Privacy Policy is a document in which all information about the processing of personal
            data within the framework od the Platform is available. It is available here.
          </p>
          <p>
            User Account is an account created by a User on the Platform, through which he/she can
            use the Services enabled on the Platform.
          </p>
          <p>
            Partner is a natural or legal person who offers its Services on the Platform. Partners
            are third-party providers who lease their own website on the Platform.
          </p>
          <p>
            Partner Website is a website on the Platform on which the Partner offers and presents
            its Services. You can also make purchases online using the Partner Website.
          </p>
          <p>
            Profile Page is part of a User Account created by an individual on the Platform and
            contains User&apos;s personal information, as well as a history of past and current
            orders.
          </p>
          <p>
            Total Amount represents the price of the selected Service, including all costs
            associated with the purchase (e.g. shipping costs, costs associated with the selected
            Payment Method). All costs will be broken down and presented before the purchase is
            completed.
          </p>

          <h2>1.1 User registration and account management</h2>
          <h3>1.1 User registration and account management</h3>

          <p>
            The use of certain Services (e.g. purchase in an online store) requires registration of
            a User Account on the Web Platform. By registering on the Web Platform, You receive a
            personalised Profile Page, where You have the opportunity to edit Your personal
            information and view the services You ordered. You can create a User Account following
            this link.
          </p>
          <p>
            We can only create a User Account if You provide Us with certain personal information.
            All information on the processing of personal data can be found in Our Privacy Policy.
            At this point, We would like to remind You that providing Your personal information is
            completely voluntary, but We cannot create a User Account unless You provide Us with the
            information.
          </p>
          <p>
            The registration and use of a User Account is limited to fully legally competent
            persons. You can only register if You have reached the age of full legal capacity in
            Your country. In Slovenia, such an age is reached when a person reaches the age of 18
            years. If You are a minor, You can invite Your parents or legal guardians to sign up for
            an account and help You choose the content that is suitable for You. You agree and are
            aware that the Services of this Platform are not suitable for individuals under the age
            of 15. In the event that We determine that a person under the age of 15 is using Our
            Services, We will immediately and without notice remove the User Account with such
            activity. We reserve the right to verify Your identity before using Our Services.
          </p>
          <p>
            We reserve the right to refuse registration. We will decline registration if the e-mail
            address You wish to register with or the username You wish to use already exists in Our
            database. We also reserve the right to refuse the use of user names that impersonate
            others, are abusive, discriminatory, violate intellectual property, or encourage
            violence. It is Our sole discretion to determine which username meets these criteria. We
            also reserve the right to block user names that We consider inappropriate, even after
            registration. In the event of such suspension, the person using such user name shall be
            obliged to change the name within 14 days of receipt of the notice of suspension of the
            account. If the change in the account does not occur within the scheduled time, we Will
            remove the account.
          </p>
          <p>
            Each Individual is solely responsible for all activities on the Web Platform that occur
            under their User Account, regardless of whether a third party has accessed the User
            Account or not. It is the responsibility of each Individual to ensure that all their
            activities on the Web Platform are legal.
          </p>
          <p>
            You are also responsible for the security of the password with which You access the User
            Account on the Platform. You agree that You will not disclose Your password to any third
            party, nor will You make the password known to third parties. SL EOOD shall not be
            liable for any damage resulting from a third party&apos;s access to Your password.
          </p>
          <p>
            You agree to notify SL EOOD immediately of any unauthorized access to Your password or
            user name or any other interference with Your User Account that may constitute a
            security threat. You must ensure that You Log Off of your User Account before leaving
            the Web Platform. SL EOOD shall not be liable for any damages resulting from failure to
            Log Off.
          </p>
          <p>
            You are obliged to provide suitable hardware and software as well as an Internet
            connection that enables you to use the Web Platform and/or websites of our Affiliated
            without interruption. Maintenance of such equipment is Your sole responsibility, and SL
            EOOD disclaims all liability for any damage that may result from this.
          </p>
          <p>
            You have an account for an indefinite period of time until You decide not to use the
            account anymore. The User can unsubscribe at any time in writing by e-mail to
            {SUPPORT_EMAIL} or by clicking on the Delete Account button. If the User wants to delete
            the User account, the e-mail should contain the following information:
          </p>
          <p>- the message title should be &quot;Delete user account&quot;;</p>
          <p>
            - the content of the e-mail must contain the following: the first and last name of the
            User, the address and e-mail address with which the User has registered;
          </p>
          <p>
            - the e-mail must be sent from the e-mail address with which the User is using as a
            registered member.
          </p>

          <h3>1.1.2 Prohibition of posting inappropriate content</h3>
          <p>
            You agree to use the Web Platform solely for lawful purposes, so that the rights of SL
            EOOD or other users of our Platform, our Partners, Affiliates, or any third parties
            associated with SL EOOD are not infringed.
          </p>
          <p>
            The User is obligated to respect the applicable law and the rights and dignity of third
            parties when using the Platform. By using our Platform (by registering a User Account),
            You agree that You will not:
          </p>
          <p>
            - post, e-mail, or otherwise make available, through the Platform, or through the
            Partners’ or Affiliates’ Websites:
          </p>
          <p>
            content (regardless of form) that is violent, discriminatory, degrading, disrespectful,
            obscene, pornographic, or otherwise objectionable,
          </p>
          <p>
            content (regardless of format) that contains threats or is posted for fraudulent
            purposes,
          </p>
          <p>
            content (regardless of form) that violates intellectual property rights and/or
            copyright, trade secrets, or any other SL EOOD or third party rights,
          </p>
          <p>
            content (regardless of format) that may give rise to criminal or civil liability for SL
            EOOD.
          </p>
          <p>
            - publish or otherwise make available advertising and/or promotion-oriented material,
            not explicitly approved by SL EOOD.
          </p>
          <p>
            - post any personal information or information sufficient to make the individual
            identifiable.
          </p>
          <p>- se the Platform for illegal or fraudulent purposes.</p>
          <p>
            - use the Platform for humiliation, abuse, harassment, impersonation, threats, or any
            other violation of individual rights.
          </p>
          <p>
            - use the Platform or Affiliates’ websites for their own advertising or promotion
            purposes in any way whatsoever, without the express consent of SL EOOD.
          </p>
          <p>
            If you find any inappropriate content on the Platform, you can warn Us by writing to
            contact us.
          </p>

          <h3>1.1.3 SL EOOD&apos;s right to use the Content shared by the User on the Website</h3>
          <p>
            Each User is the owner and author of content that he/she posts on the Platform. SL EOOD
            has the right to use such a contribution for advertising of the Platform and/or its
            Partners, Affiliates, and third parties associated with SL EOOD.
          </p>
          <p>
            The content, posted by Partners and Affiliates is specifically regulated in the Business
            Cooperation Agreement and General Terms and Conditions for Enrollment in the Affiliation
            Program.
          </p>
          <p>
            By posting comments, opinions, questions, or suggestions for improvement (hereinafter
            Your content), You agree that SL EOOD may use and share Your content with third parties,
            through any medium or communication channel. SL EOOD has the right to change and amend
            any post. By posting, you grant Us a non-exclusive, territorial, and time-limited right
            to use, copy, reproduce, transform, publish, transmit, display, and distribute Your
            content, posted on the Platform. This also includes sharing Your content with the
            aforementioned third parties. You expressly agree that You are not entitled to any
            compensation for the use of Your content. You expressly warrant that You have the right
            and ability to assign the above rights.
          </p>

          <h2>1.2 The nature of SL as a mediator and direct purchase via third parties</h2>
          <h3>1.2.1 Promotional Platform and online shopping</h3>
          <p>
            You are aware and expressly agree that the Platform is a promotional platform that
            represents various Third Party Services (hereinafter referred to as Partners). As a
            result, these Services, presented in the online store and in advertising, are not SL
            EOOD Services, but Partner Services to which SL EOOD provides Platform Services.
            Although the Partners’ websites can be accessed by the User via the Platform, may be
            protected by trademark and owned by SL EOOD, this does not mean that they are a part of
            SL EOOD Services. You expressly acknowledge that you are aware of the nature of such
            designations and agree that SL EOOD shall have no liability for any consequences arising
            out of Your misconceptions.
          </p>
          <p>
            SL EOOD carefully selects Partners who receive a place to sell their Services on the
            Platform but in no way bears responsibility for the quality of the Services offered and
            does not guarantee that the Services match with the advertised characteristics.
            Likewise, SL EOOD does not guarantee that the delivery, organized by the Partner, will
            actually be made.
          </p>
          <p>
            SL EOOD, as the owner and manager of the Platform, acts as an intermediary and
            facilitates the connection between buyers and sellers (i.e. Platform Partners). SL EOOD
            runs the Platform&apos;s online store as an intermediary. You can access the Platform’s
            online store by selecting a particular Service and clicking the ADD TO CART button. We
            further clarify that each Service presented on the Platform is a Partner Service and not
            the SL EOOD Service. SL EOOD is solely responsible for the technical execution of the
            purchase of a particular Service in an online store. You can read more about making
            payments and making a purchase from the online store in point 3 below.
          </p>
          <p>
            Partners may provide purchases subject to their Terms and Conditions (Partner Terms and
            Conditions), which You are required to read and act accordingly. Partner Terms and
            Conditions will be clearly accessible at the Partner’s website.
          </p>
          <p>
            Please carefully review all Partner documents before making Your purchase. If the
            Partner offer the purchase subject to its Terms and Conditions and Privacy Policy, these
            documents are also made available to You in the online store before the purchase.
          </p>
          <p>
            Any purchase requiring the delivery of goods will be made by the Partner from whom the
            purchase was made. The partner can make a delivery via third parties.
          </p>
          <p>
            The function of SL EOOD as an intermediary and the limitations of liability are
            explained in more detail in the two points below.
          </p>

          <h3>1.2.2 Limitation of Liability for Partner content</h3>
          <p>
            SL EOOD encourages individuals to provide their Services on the Platform. In accordance
            with the established business model, SL EOOD does not conduct detailed Service reviews
            and does not verify the origin or existence of attributes of particular Services offered
            by Partners. Similarly, SL EOOD does not review the Services in the light of legal
            errors. Since We do not control Partners Services, We cannot guarantee the features of
            the Services, such as reliability, validity, professionalism, and efficiency. SL EOOD
            assumes no responsibility for the content of Partner Sites as editing of these sites is
            the sole responsibility of each individual Partner. When ordering any of the Services,
            You rely solely on the information provided to You by the Partner providing the Service.
          </p>
          <p>
            You understand and agree that SL EOOD is not a party to any contract between Users and
            Partners. SL EOOD has no control over the conduct of Users, Partners, Affiliates, and
            third parties who may use the Platform and/or the Services and disclaims any liability
            in this respect to the extent permitted by law.
          </p>
          <p>
            When using the Services, you may come across content that is offensive, obscene, or
            otherwise objectionable to You. SL EOOD assumes no responsibility for limiting access to
            such content.
          </p>
          <p>
            The services on our Platform are related to healthy lifestyle, spirituality, and mental
            health. You expressly agree to understand that the use of such Services poses a certain
            risk as these Services are of a sensitive nature. By ordering and/or using these
            Services, You agree that You are aware of risks, which may include the risk of injury,
            disability, or death. You fully accept responsibility for any consequences that may
            arise from ordering and/or using these Services.
          </p>
          <p>
            SL EOOD does not employ Partners who provide their Services on the Platform. These are
            independent legal or natural persons who are in a business relationship with Us. SL EOOD
            disclaims all responsibility for any interaction between you and our Partners, nor shall
            We be liable for any disputes, claims, losses, injuries, or damages arising out of such
            interaction. All legal persons, entities and/or natural persons with whom SL EOOD
            cooperates, are completely independent with respect to SL EOOD. EOOD is not responsible
            for the statements, actions or omissions of such third parties, including Partners and
            Affiliates.
          </p>
          <p>
            SL EOOD assumes no responsibility for the content of third party websites that appear on
            the Platform as advertisements or are otherwise linked or quoted on this Platform.
            Likewise, SL EOOD assumes no responsibility for the websites published on the Partner
            Website by individual Partners. SL EOOD assumes no responsibility for practices or
            content of those websites. Please, carefully read the General Terms and Conditions,
            Privacy Policy and other documents available on these websites.
          </p>

          <h2>1.3 Operation of the online store</h2>

          <p>
            SL EOOD runs the Platform&apos;s online store. SL EOOD uses an external payment service
            provider to make payments.
          </p>
          <p>
            Due to the maintenance and updating of the online store, it is possible that the online
            store is temporarily unavailable or that your payments will be temporarily disabled. SL
            EOOD shall not be responsible for any damage that may occur to You during maintenance
            and/or updates on the online store.
          </p>
          <p>
            All prices in the price lists or websites are in Euro and include value added tax (VAT).
          </p>

          <h3>1.3.1 Making a purchase from an online store</h3>
          <p>
            Making a purchase on the Platform is subject to registration of a User Account on the
            Platform. If Your account is closed for any reason, You will no longer be able to use
            the online store.
          </p>
          <h3>1.3.1.1 Placing an order</h3>
          <p>
            Placing an order via the Platform is considered to be the conclusion of the Agreement.
            To place an order, click on the “ADD TO CART” button on a particular Partner Website.
            After clicking, you will be redirected to an online store where you can select quantity,
            enter discount codes, and similar. By clicking on the button to proceed with Your order,
            you will be redirected to a page where you enter the information required for the order
            (first and last name, address, telephone number, e-mail address, possibility of
            additional notes). On the same page you will find the selection of offered payment
            methods and the order summary. The order will be confirmed when you agree to the General
            Terms and Conditions applicable to Your purchase.
          </p>
          <p>
            After placing the order, You will be redirected to the last page of the order summary,
            and at the same time You will receive an e-mail informing You that the order is in
            progress and the order summary. After payment has been received, You will receive an
            e-mail informing You of your completed order.
          </p>
          <h3>1.3.1.2 Payment</h3>
          <p>
            All payments for the ordered Services are made via a payment service provider appointed
            by SL EOOD.
          </p>
          <p>The following payment methods are enabled on the Platform:</p>
          <p>
            - payment to the Partner&apos;s transaction account. At https://sleo.io, certain
            Services (most one-off services) are ordered via the individual service order form.
            After completing the order form and placing the order, the User is redirected to the
            payment information page and, at the same time, receives an order confirmation on their
            e-mail, with added payment information.
          </p>
          <p>
            - By credit card (Activa, Mastercard, Visa, Visa Electron, American Express, BA Maestro)
            - via Paywiser - Cebasek Denmark IVS (hereinafter Payment Methods).
          </p>
          <p>
            You agree that you may be charged the entire amount of the selected Service, including
            any delivery or other charges that apply to the selected Service. You will be informed
            of any additional costs before making Your purchase. The selected Payment Method may
            also entail additional costs.
          </p>
          <p>
            SL EOOD reserves the right to verify the selected Payment Method by authentication by a
            payment service provider appointed by SL EOOD. We also reserve the right to further
            verify the Payment Method by asking You to provide us with a payment confirmation.
          </p>
          <p>
            You agree to pay the costs of the selected Services (including any transaction-related
            costs) in accordance with the selected Payment Method. You guarantee that you have the
            ability and the right to conduct credit card transactions or whichever payment method
            you choose.
          </p>
          <p>
            If We incur additional charges as a result of your non-payment or your payment has been
            declined, We reserve the right to charge You for such costs. You have to pay such costs.
          </p>
          <p>
            If you wish to make a purchase for a particular Service offered on the Platform, We will
            need to ask You for additional information so that We can carry out the purchase. This
            in particular includes Your credit card details and Your address. Any information You
            provide to us will be treated in accordance with the Privacy Policy published here.
          </p>
          <p>
            The selected Payment Method can be stored in Your account, making it easier for You to
            make further purchases. The Payment Method will be stored in Your account, if you agree
            with such a method.
          </p>
          <p>
            If you fail to pay your debts to us within sixty (60) days of the due date, We will
            initiate appropriate procedures for recovering those debts. You agree that all
            communication about amounts due will be made via the e-mail address with which You
            registered Your account or the e-mail address You later provided to Us. You agree that
            you may also be contacted by third parties appointed by SL EOOD to collect the amounts
            due.
          </p>
          <p>
            After placing the order, You will be redirected to the last page of the order summary,
            and at the same time You will receive an e-mail informing You that the order is in
            progress and the order summary. After payment has been received, You will receive an
            e-mail informing You of your completed order.
          </p>

          <h3>1.3.1.3 Service prices and promotional codes</h3>

          <p>
            The prices of the Services offered on the Platform are determined by the Partners who
            provide individual Services on the Platform. SL EOOD does not set prices for the
            Services and has no effect on the Pricing Policy of individual Partners.
          </p>
          <p>
            The price for each Service indicated on the Partner Website includes VAT. However, this
            price does not include shipping costs. The shipping can be seen in the online store,
            where you have the choice of delivery. Different shipping methods may also have
            different prices, so please check all quoted prices before You choose. At the end of the
            order, you will receive an overview of the price, which is divided into the cost of
            Services, delivery costs, and the total price.
          </p>
          <p>
            Promotion campaigns are also running on the Platform in order to lower the prices of the
            Services. Each promotion is offered for a certain (limited) period of time at a reduced
            price, which is defined individually for each promotion. The discounted price applies to
            purchases made during the promotion period.
          </p>
          <p>
            Please note that certain promotions are limited to new Users who do not yet have a
            registered User Account. In this case, the discounted price does not apply to existing
            Users. Any attempt to duplicate user accounts in order to use these promotions will be
            immediately suspended and user accounts will be deleted without notice.
          </p>

          <h3>1.3.1.4 Handling complaints, cancellations, and returns</h3>
          <p>
            User may exercise their rights resulting from a material defect on the condition that
            they notify the Partner of the defect within two months of the discovery of the defect.
            The User should describe the defect in more detail in the error notification. Error
            notification can be communicated personally by the User to the Partner, and the Partner
            must issue a written acknowledgement.
          </p>
          <p>
            The User, who correctly informed the seller of a defect, has the right to require the
            Partner to:
          </p>
          <p>- correct the defect in the educational program or material, or</p>
          <p>- reimburse part of the amount paid in proportion to the defect, or</p>
          <p>- reimburse the entire payment.</p>
          <p>
            A User who is a natural person and purchases the Product Via the Platform for purposes
            outside his/her professional or commercial activity (User) may withdraw from the
            contract within 14 days of the conclusion of the contract.
          </p>
          <p>
            The User must notify SL EOOD of the cancellation of the contract by e-mail (
            {SUPPORT_EMAIL}). SL EOOD will forward the request to the Partner, who will initiate the
            cancellation process if all conditions are met. The Partner is obliged to reimburse the
            amount of the purchase price, which is done within 14 days from the date of receipt of
            the notice of withdrawal.
          </p>
          <p>
            When ordering digital content (webinars, etc.), the User has the right to withdraw from
            the contract, in which case special arrangements apply. The User acquires limited access
            to digital content upon order. Such limited access lasts for 14 days and allows the User
            to test the ordered digital content. After 14 days, the User gains full access to the
            subscribed digital content. The User may gain full access before the expiry of the
            14-day period, provided that he/she gives explicit consent to waive the right to
            withdraw from the contract. Such consent may be given by the User via the form provided
            to him by the Organizer. The User forwards the completed form to: {SUPPORT_EMAIL}.
          </p>
          <p>
            Customers who are not users do not have the right to a refund in the event of
            cancellation of the ordered services or contracts after the Partner has already received
            their payment, in any case and at any time.
          </p>
          <p>
            In the event of a change to the information provided by the User at the time of ordering
            the Service, the User must notify SL EOOD in writing no later than 8 days after the
            change. In addition to notifying SL EOOD, the User is obliged to change the information
            on the User pages and other applications provided by SL EOOD. The User suffers all
            adverse consequences of the omission of change notification, even if the deadline from
            the previous sentence has not yet expired.
          </p>
          <p>
            If You wish to return the ordered Service and the Partner does not respond, You can
            contact Us with a refund request. SL EOOD will, in its sole discretion, contact a
            Partner whose Service is the subject of a refund complaint. SL EOOD will not consider a
            refund request unless it is accompanied by an invoice proving the purchase. SL EOOD is
            not responsible for the refusal of a refund by a Partner whose Service is the subject of
            a complaint.
          </p>

          <h3>1.3.2 Customer support</h3>
          <p>
            Support is provided to Users, as a rule, via e-mail or telephone numbers published to
            the Platform.
          </p>
          <p>
            SL EOOD is only obliged to fulfil with support requests, which are made via the Partner
            Website or e-mailed to the SL EOOD e-mail addresses listed on the Platform for such
            cases.
          </p>
          <p>
            You can always contact us at: <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
          </p>
          <h2>1.4 General provisions</h2>
          <h3>1.4.1 Intellectual property</h3>
          <p>
            Information, images, text, and any other material (e.g. video content, graphics,
            sketches, etc.) contained on the Platform are copyright work and are as such protected
            by copyright and/or intellectual property rights.
          </p>
          <p>
            By purchasing the Services and/or using the Platform, the User does not acquire any
            copyright, ownership, or intellectual property rights to the Services and/or the
            Platform. Under no circumstances may the User acquire a copyright to educational
            programs or modules developed by SL EOOD or for which SL EOOD holds the copyright. SL
            EOOD may at any time change the manner in which copyright or material rights are
            claimed. The same applies to the intellectual property (including copyrights) of
            Partners and Affiliates, if any. The User may only use the materials for his/her own,
            non-commercial purposes.
          </p>

          <h3>1.4.2 Limitation of liability</h3>
          <p>
            Considering the limitations of liability presented in these General Terms and
            Conditions, SL EOOD further clarifies as follows.
          </p>
          <p>
            If You choose to use the Services offered on the Platform, You agree that You have done
            so voluntarily and have assumed a risk for Yourself. The Platform and all Services are
            provided &quot;as is&quot; without warranty, direct or indirect. All limitations of
            liability set forth in this chapter or elsewhere in these General Terms and Conditions
            shall apply to the fullest extent permitted by law.
          </p>
          <p>
            SL EOOD neither guarantees the operation of the Platform and its features, nor that the
            Platform and/or the Services function faultlessly, virus-free or with virus-like
            malware. Likewise, SL EOOD does not guarantee that the delivery, organized by the
            Partner, will actually take place. SL EOOD does not warrant any damages, including but
            not limited to: direct, indirect or consequential damages, caused or likely to be caused
            by or in connection with the use of the Platform.
          </p>
          <p>
            If You choose to use the online store and make a payment through this store, You
            expressly agree that use is at Your own risk and that You bear all risks associated with
            payments made through the online store, including, but not limited to, failed payments
            on the website User, payment errors and errors in refunding payments in the event of a
            complaint. This exclusion shall apply within the limits permitted by lay.
          </p>
          <p>
            SL EOOD, enables contact between Partners and Users via the Platform, but under no
            circumstances becomes a Contracting Party for individual Services. The contract is
            concluded directly between the Partner and the User who orders a particular Service. SL
            EOOD assumes no responsibility for the actions and/or omissions of the Partners and
            shall not be liable for any damages that the User may suffer as a result of the actions
            and/or omissions of the Partners.
          </p>
          <p>
            In the event of damage that may result from the use of the Services, you are obliged to
            contact the Partner who provides the Service directly. SL EOOD shall not be liable for
            any damages that may result from using the Service.
          </p>
          <p>
            In the event that You choose to use the Platform and/or Services or You choose to
            provide Services or act as an Affiliate, You do so at Your own risk. SL EOOD makes no
            assurances regarding Partners, Affiliates, quality of advertisements, Services or the
            accuracy, timeliness, sincerity, or reliability of any information obtained from the
            Platform.
          </p>

          <h3>
            1.5 Final provisions (application of law, dispute settlement, language version, changes
            in Terms and Conditions)
          </h3>
          <p>
            Signed contract. The General Terms and Conditions, together with the order of the
            Services via the website (https://sleo.io) and all sub-pages of this website, have the
            character of a contract concluded between the Partner and the Costumer. If the Partner
            and the Costumer conclude the contract in writing, the General Terms and Conditions
            supplement the contractual conditions. In case of disagreement, individual contractual
            conditions shall take precedence over the conditions of the General Terms and
            Conditions.
          </p>
          <p>
            Independence of provisions. If any provision of these General Terms and Conditions (in
            whole or in part) is found to be unlawful, void or otherwise invalid, such provision
            shall be deemed deleted (in whole or in part) and the remainder of the General Terms and
            Conditions shall remain in full force and effect.
          </p>
          <p>
            Full legal capacity. You are able to fully assume the rights and obligations under these
            General Terms and Conditions. You warrant that You do not require a consent or approval
            of any third party to perform Your obligations under these Terms and Conditions.
          </p>
          <p>
            Acquaintance with the Terms and Conditions. You have read and are fully aware of these
            General Terms and Conditions before accepting them, in particular the applicable
            limitations of liability.
          </p>
          <p>
            Right of agreement. These General Terms and Conditions are subject to the law of the
            Republic of Slovenia. Any disputes arising from these General Terms and Conditions shall
            be subject to the exclusive jurisdiction of the courts of the Republic of Slovenia.
          </p>
          <p>
            Changes to the General Terms and Conditions. You have no right to modify or waive the
            provisions of these General Terms and Conditions in whole or in part. SL EOOD has the
            right to change these Terms and Conditions at any time. Any changes will be posted on
            the Platform. You are deemed to agree to the changes made as long as you continue to use
            the Platform and/or the Services despite the changes being made. If you do not agree
            with the changes, You have the right to withdraw.
          </p>
          <p>
            Overall agreement. These General Terms and Conditions constitute the overall agreement
            in force between the Contracting parties. All prior agreements or negotiations, whether
            written or oral, are superseded by these Terms and Conditions.
          </p>
          <p>
            Language versions. These General Terms and Conditions are drawn up in the Slovenian
            language. Any version of these General Terms and Conditions in another language is
            intended to provide easier access to the General Terms and Conditions. You agree and
            fully understand that in the event of any dispute, the Slovenian version shall prevail.
          </p>
          <p>
            Definitions of Terms. All capitalized terms used in these Terms and Conditions shall
            have the meaning as defined at the beginning of these General Terms and Conditions, the
            Privacy Policy, and General Terms and Conditions for Enrolment in the Affiliate Program.
          </p>
        </div>
      </div>
    </div>
    <DashboardFooter />
  </div>
);
export default TermsOfUse;
