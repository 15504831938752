/* eslint-disable */
import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import {
  GetCryptoChartRequest,
  GetCryptoChartResponse,
  GetTableChartResponse,
  GetWalletForCurrencyRequest,
  SaveAllCoinsRequest,
  SaveWalletBalanceRequest,
} from '../../../../../data/repositories/dashboard/typedefs';
import { DepositTermRequest, termPackageObject } from './initialState';

export const GET_WALLET_BALANCE = 'dashboard/get_wallet_balance';
export const GET_WALLET_BALANCE_SUCCESS = 'dashboard/get_wallet_balance_success';
export const GET_WALLET_BALANCE_FAIL = 'dashboard/get_wallet_balance_fail';
export const GET_ALL_COINS = 'dashboard/get_all_coins';
export const GET_WALLET_FOR_CURRENCY = 'dashboard/get_wallet_for_currency';
export const GET_ENTIRE_WALLET_BALANCES = 'dashboard/get_entire_wallet_ballances';
export const GET_ENTIRE_WALLET_BALANCES_SUCCESS = 'dashboard/get_entire_wallet_ballances_success';
export const GET_ENTIRE_WALLET_BALANCES_FAIL = 'dashboard/get_entire_wallet_ballances_fail';
export const IS_INVEST_DIALOG_OPEN = 'dashboard/is_invest_dialog_open';

export const GET_TERM_SAVING_TYPES = 'dashboard/get_term_saving_types';
export const GET_TERM_SAVING_TYPES_SUCCESS = 'dashboard/get_term_saving_types_success';
export const GET_TERM_SAVING_TYPES_FAIL = 'dashboard/get_term_saving_types_fail';
export const SET_TERM_SAVING_PACKAGE = 'dashboard/set_term_saving_package';
export const DEPOSIT_TERM_SAVING = 'dashboard/deposit_term_saving';
export const DEPOSIT_TERM_SAVING_SUCCESS = 'dashboard/deposit_term_saving_success';
export const DEPOSIT_TERM_SAVING_FAIL = 'dashboard/deposit_term_saving_fail';
export const SAVE_ALL_COINS = 'dashboard/save_all_coins';

export const GET_CRYPTO_CHART_DATA = 'dashboard/get_crypto_chart_data';
export const GET_CRYPTO_CHART_DATA_SUCCESS = 'dashboard/get_crypto_chart_data_success';
export const GET_CRYPTO_CHART_DATA_FAIL = 'dashboard/get_crypto_chart_data_fail';
export const SET_SELECTED_COIN = 'dashboard/set_selected_coin';

export const GET_TABLE_CHART = 'dashboard/get_table_chart';
export const GET_TABLE_CHART_SUCCESS = 'dashboard/get_table_chart_success';
export const GET_TABLE_CHART_FAIL = 'dashboard/get_table_chart_fail';

export const getWalletBalance = createStandardAction(GET_WALLET_BALANCE)<undefined>();
export const getWalletBalanceSuccess = createStandardAction(GET_WALLET_BALANCE_SUCCESS)<
  SaveWalletBalanceRequest
>();
export const getWalletBalanceFail = createStandardAction(GET_WALLET_BALANCE_FAIL)<Error>();
export const getAllCoins = createStandardAction(GET_ALL_COINS)<undefined>();

export const getWalletForCurrency = createStandardAction(GET_WALLET_FOR_CURRENCY)<
  GetWalletForCurrencyRequest
>();
export const isInvestDialogOpen = createStandardAction(IS_INVEST_DIALOG_OPEN)<boolean>();
export const setSelectedCoin = createStandardAction(SET_SELECTED_COIN)<string>();

export const asyncGetCryptoChartData = createAsyncAction(
  GET_CRYPTO_CHART_DATA,
  GET_CRYPTO_CHART_DATA_SUCCESS,
  GET_CRYPTO_CHART_DATA_FAIL,
)<GetCryptoChartRequest, GetCryptoChartResponse, Error>();

export const asyncGetEntiretWalletBalances = createAsyncAction(
  GET_ENTIRE_WALLET_BALANCES,
  GET_ENTIRE_WALLET_BALANCES_SUCCESS,
  GET_ENTIRE_WALLET_BALANCES_FAIL,
)<undefined, any, Error>();

export const asyncGetTermSavingTypes = createAsyncAction(
  GET_TERM_SAVING_TYPES,
  GET_TERM_SAVING_TYPES_SUCCESS,
  GET_TERM_SAVING_TYPES_FAIL,
)<undefined, any, Error>();

export const setTermSavingPackage = createStandardAction(SET_TERM_SAVING_PACKAGE)<
  termPackageObject
>();

export const asyncDepositTermSaving = createAsyncAction(
  DEPOSIT_TERM_SAVING,
  DEPOSIT_TERM_SAVING_SUCCESS,
  DEPOSIT_TERM_SAVING_FAIL,
)<DepositTermRequest, undefined, string>();

export const asyncGetTableChart = createAsyncAction(
  GET_TABLE_CHART,
  GET_TABLE_CHART_SUCCESS,
  GET_TABLE_CHART_FAIL,
)<GetCryptoChartRequest, GetTableChartResponse, Error>();

export const saveAllCoins = createStandardAction(SAVE_ALL_COINS)<SaveAllCoinsRequest>();
