import {
  Checkbox,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import React from 'react';
import { SacretSelectItem } from '../../data/repositories/transaction/typedef';
import { Colors } from '../../ui/style/colors';
import { sortArrayAlphabetically } from '../../utils/helper/utils';
import LightTooltip from '../LightTooltip';
import SacretBaseInput from '../SacretBaseInput';

type StyleProps = {
  className: string | undefined;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      fill: theme.palette.primary.light,
      width: 15,
      height: 15,
      marginTop: 4,
      marginRight: 7,
    },
    content: {},
    label: {
      float: 'left',
      color: theme.palette.primary.light,
      padding: ({ className }: StyleProps) => (className ? '6px 6px 6px 0' : '2px 0'),
      fontSize: ({ className }: StyleProps) => (className ? '14px' : '12px'),
    },
    countChip: {
      fontSize: 12,
      float: 'right',
      marginRight: 3,
      color: `${Colors.white}`,
      background: `${Colors.bombay}`,
      borderRadius: 20,
      height: 19,
      width: 19,
      textAlign: 'center',
      verticalAalign: 'middle',
      margin: '2px 0',
    },
    checkbox: {
      minWidth: 0,
      minHeight: 0,
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 3,
      paddingRight: 3,
      color: theme.palette.primary.light,
    },
    item: {
      minHeight: 0,
      lineHeight: 1,
      fontSize: 12,
      color: theme.palette.primary.light,
      '&:hover': {
        backgroundColor: `${Colors.white} !important`,
        fontWeight: 'bold',
      },
    },
    selectedItem: {
      backgroundColor: `${Colors.white} !important`,
      color: theme.palette.primary.main,
    },
    tooltip: {
      left: -40,
      top: 0,
    },
    invisible: {
      display: 'none',
    },
    large: {
      width: 285,
    },
    iconPosition: {
      background: `${Colors.white}`,
      marginRight: 5,
      boxShadow: '0 10px 30px 0 rgba(132,141,182,0.25)',
    },
  }),
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const AnchorProps: {
  horizontal: number | 'left' | 'right' | 'center';
  vertical: number | 'bottom' | 'top' | 'center';
} = {
  vertical: 'bottom',
  horizontal: 'left',
};

const TransformProps: {
  horizontal: number | 'left' | 'right' | 'center';
  vertical: number | 'bottom' | 'top' | 'center';
} = {
  vertical: 'top',
  horizontal: 'left',
};

const MenuProps = {
  PaperProps: {
    style: {
      borderRadius: 0,
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
      boxShadow: '0 10px 30px 0 rgba(132,141,182,0.25)',
      marginTop: -3,
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 150,
    },
  },
  anchorOrigin: AnchorProps,
  transformOrigin: TransformProps,
  getContentAnchorEl: null,
};

type Props = {
  label: string;
  items: SacretSelectItem[];
  onChange: (value: unknown) => void;
  stateFilterValue: string | string[] | undefined;
  multiple?: boolean;
  disabled?: boolean;
  disabledTextExplanation?: string;
  size?: 'large' | 'medium' | 'small';
  className?: string;
  endAdornment?: React.ReactNode;
  onlyShowAdornmentFor?: string;
};

const SacretSelect = ({
  label,
  items,
  onChange,
  stateFilterValue,
  multiple = true,
  disabled = false,
  disabledTextExplanation,
  className,
  size,
  endAdornment,
  onlyShowAdornmentFor,
}: Props) => {
  const classes = useStyles({ className });

  let selectedItem: string | string[] | '';

  if (!stateFilterValue && multiple) {
    selectedItem = [];
  } else if (!stateFilterValue && !multiple) {
    selectedItem = '';
  } else {
    selectedItem = stateFilterValue;
  }

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    selectedItem = event.target.value as string[];
    onChange(event.target.value);
  };
  const [selectDropdownOpened, setSelectDropdownOpened] = React.useState<boolean>(false);

  const onSelectOpen = () => {
    setSelectDropdownOpened(true);
  };
  const onSelectClose = () => {
    setSelectDropdownOpened(false);
  };
  const isArrowVisible = !disabled ? classes.icon : classes.invisible;
  const isTooltipVisible = disabled ? classes.tooltip : classes.invisible;

  const inputWidth = size === 'large' ? classes.large : '';

  return (
    <Select
      multiple={multiple}
      disabled={disabled}
      variant="outlined"
      value={selectedItem}
      onChange={handleChange}
      input={<SacretBaseInput value="Amount" />}
      MenuProps={MenuProps}
      inputProps={{
        classes: { icon: isArrowVisible },
      }}
      displayEmpty={true}
      endAdornment={
        disabled && (
          <InputAdornment position="end">
            <LightTooltip className={isTooltipVisible} title={disabledTextExplanation} />
          </InputAdornment>
        )
      }
      className={`${className} ${inputWidth}`}
      renderValue={selected => (
        <div className={classes.content}>
          <div className={classes.label}>{label}</div>
          {Array.isArray(selected) && selected.length > 0 && (
            <div className={classes.countChip}>{selected.length}</div>
            // <Chip
            //   className={classes.countChip}
            //   size="small"
            //   color="default"
            //   label={selected.length}
            // />
          )}
        </div>
      )}
      IconComponent={selectDropdownOpened ? KeyboardArrowUp : KeyboardArrowDown}
      onOpen={onSelectOpen}
      onClose={onSelectClose}
    >
      {sortArrayAlphabetically(items).map((item: any) => (
        <MenuItem
          key={item.value}
          value={item.value}
          dense
          disableRipple
          classes={{ selected: classes.selectedItem, root: classes.item }}
        >
          <ListItemIcon className={classes.checkbox}>
            <Checkbox
              className={classes.checkbox}
              edge="start"
              checked={selectedItem.indexOf(item.value) !== -1}
              value={item}
              disableRipple
              inputProps={{ 'aria-labelledby': `checkbox-list-label-${item}` }}
            />
          </ListItemIcon>
          <ListItemText id={`checkbox-list-label-${item}`} primary={item.name} />
          {endAdornment && item.value === onlyShowAdornmentFor && (
            <InputAdornment position="end" className={classes.iconPosition}>
              {endAdornment}
            </InputAdornment>
          )}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SacretSelect;
