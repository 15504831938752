/* eslint-disable */
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { EmptyAC, PayloadAC } from 'typesafe-actions';
import * as yup from 'yup';
import {
  changePassword,
  changePasswordClearState,
  changePasswordFail,
  changePasswordSuccess,
} from '../../App/Home/HomeContent/Settings/SettingsChangePassword/state/actions';
import { ChangePasswordState } from '../../App/Home/HomeContent/Settings/SettingsChangePassword/state/initialState';
import { changePasswordStateSelector } from '../../App/Home/HomeContent/Settings/SettingsChangePassword/state/selectors';
import { AppState } from '../../App/state/initialState';
import { ChangePasswordRequest } from '../../data/repositories/account/typedefs';
import { Colors } from '../../ui/style/colors';
import { passwordRegex } from '../../utils/helper/utils';
import CustomButtonPrimary from '../CustomButtons/CustomButtonPrimary';
import TextInput from '../TextInput';

const styles = (theme: Theme) =>
  createStyles({
    dialogTittleText: {
      color: `${Colors.blueCharcoal}`,
      fontSize: 18,
      fontWeight: 600,
      letterSpacing: 1.13,
      display: 'flex',
      alignItems: 'center',
    },
    dialogTitle: {
      marginTop: 25,
      marginLeft: 25,
      marginRight: 25,
      padding: 0,
    },
    depositIcon: {
      marginRight: 20,
    },
    dialogPaper: {
      height: 'center',
      width: 'center',
      borderRadius: 6,
      backgroundColor: 'transparent',
      boxShadow: '0 10px 30px 0 rgba(132,141,182,0.25)',
    },
    closeButton: {
      float: 'right',
      verticalAlign: 'text-top',
    },
    currencyLabel: {
      color: theme.palette.primary.light,
      fontSize: 12,
      fontWeight: 600,
      marginBottom: 10,
    },
    currencyDropdown: {
      height: 40,
      //border: `1px solid ${Colors.blueHaze}`,
      borderRadius: 6,
      backgroundColor: `${Colors.white}`,
      boxShadow: '0 2px 10px 0 rgba(132,141,182,0.25)',
    },
    currencyDropdownOption: {
      height: 40,
      borderRadius: 6,
      backgroundColor: `${Colors.white}`,
    },
    buttonOLD: {
      backgroundColor: `${Colors.blueCharcoal}`,
      color: `${Colors.white}`,
      fontSize: 14,
      marginTop: 30,
      marginBottom: 20,
      textTransform: 'none',
    },
    currencyAddress: {
      borderRadius: 6,
      marginBottom: 30,
      height: 50,
    },
    qrCode: {
      border: `2px solid ${Colors.white}`,
      borderRadius: 6,
      backgroundColor: `${Colors.white}`,
      textAlign: 'center',
    },
    dropdownStyle: {
      border: `1px solid ${theme.palette.secondary.light}`,
      borderRadius: 6,
    },
    snackbar: {
      background: `${Colors.apple}`,
    },
    inputFields: {
      marginTop: 5,
      height: 44,
      boxSizing: 'border-box',
      border: `1px solid ${theme.palette.secondary.light}`,
      borderRadius: 4,
      backgroundColor: `${Colors.white}`,
      paddingLeft: 12,
    },
    labels: {
      fontWeight: 'bold',
      color: theme.palette.primary.light,
      marginTop: 30,
      marginBottom: 8,
    },
    changePasswordLabel: {
      color: theme.palette.secondary.light,
      fontSize: 14,
      fontWeight: 800,
      letterSpacing: 1,
    },
    button: {
      marginTop: 40,
      marginBottom: 16,
      textTransform: 'none',
      fontSize: 16,
      fontWeight: 600,
      letterSpacing: 1,
      textAlign: 'center',
    },
    buttonSuccess: {
      backgroundColor: `${Colors.havelockBlue}`,
      marginTop: 40,
      '&:hover': {
        backgroundColor: `${Colors.havelockBlue}`,
      },
    },
    inputError: {
      color: `${Colors.red}`,
    },
  });
const useStyles = makeStyles(styles);

type Props = ChangePasswordState & {
  open: boolean;
  onClose: () => void;
  changePassword: PayloadAC<'change_password', ChangePasswordRequest>;
  changePasswordClearState: EmptyAC<'change_password/clear_state'>;
};

const DepositModal = ({
  onClose,
  open,
  inProgress,
  success,
  error,
  changePassword,
  changePasswordClearState,
}: Props): JSX.Element => {
  const classes = useStyles();
  useEffect(() => {
    changePasswordClearState();
  }, []);

  useEffect(() => {
    if (success) onClose();
  }, [success]);

  const submit = (data: ChangePasswordRequest) => {
    const payload = {
      old_password: data.old_password,
      // eslint-disable-next-line @typescript-eslint/camelcase
      new_password: data.new_password,
    };
    changePassword(payload);
  };

  // eslint-disable-next-line @typescript-eslint/camelcase
  const initValues = { old_password: '', new_password: '', confirm_new_password: '' };
  return (
    <Dialog
      disableEscapeKeyDown
      fullWidth
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose;
        }
      }}
      open={open}
      className={classes.dialogPaper}
    >
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Grid container item xs={12} justifyContent="space-between">
          <Typography className={classes.dialogTittleText}>Change password</Typography>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initValues}
          onSubmit={(values, actions) => {
            actions.setSubmitting(false);
            submit(values);
          }}
          validationSchema={yup.object({
            password: yup.string().required('Password required'),
            // eslint-disable-next-line @typescript-eslint/camelcase
            new_password: yup
              .string()
              .required('New password is required')
              .matches(
                passwordRegex,
                'New password must be minimum 8 characters long, at least 1 uppercase, lowercase, digit, and a symbol.',
              ),
            // eslint-disable-next-line @typescript-eslint/camelcase
            confirm_new_password: yup
              .string()
              .required('Confirm new password required')
              .oneOf([yup.ref('new_password'), null], 'Password must match'),
          })}
        >
          {({ isSubmitting, errors, touched }) => (
            <Form>
              <Typography className={classes.changePasswordLabel} component="p">
                Password policy changed, update your password.
              </Typography>
              <InputLabel className={classes.labels}>Current Password</InputLabel>
              <Field
                id="password"
                type="password"
                name="password"
                component={TextInput}
                className={classes.inputFields}
                disableUnderline
              />
              {errors.old_password && touched.old_password ? (
                <div className={classes.inputError}>{errors.old_password}</div>
              ) : null}
              <InputLabel className={classes.labels}>New Password</InputLabel>
              <Field
                id="new_password"
                type="password"
                name="new_password"
                component={TextInput}
                className={classes.inputFields}
                disableUnderline
              />
              <ErrorMessage className={classes.inputError} name="new_password" component="div" />
              <InputLabel className={classes.labels}>Confirm Password</InputLabel>
              <Field
                id="confirm_new_password"
                type="password"
                name="confirm_new_password"
                component={TextInput}
                className={classes.inputFields}
                disableUnderline
              />
              <ErrorMessage
                className={classes.inputError}
                name="confirm_new_password"
                component="div"
              />
              <Grid container alignItems="center" justifyContent="center">
                {error != null ? (
                  <Grid item xs={12} className={classes.inputError}>
                    {error}
                  </Grid>
                ) : null}
                {inProgress ? (
                  <CircularProgress />
                ) : (
                  <CustomButtonPrimary
                    type="submit"
                    variant="contained"
                    size="large"
                    fullWidth
                    className={success ? classes.buttonSuccess : classes.button}
                    disabled={isSubmitting}
                  >
                    {success ? 'Succesfully changed!' : 'Save'}
                  </CustomButtonPrimary>
                )}
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

const mapDispatchToProps = {
  changePassword: changePassword,
  changePasswordSuccess: changePasswordSuccess,
  changePasswordFail: changePasswordFail,
  changePasswordClearState: changePasswordClearState,
};

const mapStateToProps = (state: AppState) => ({ ...changePasswordStateSelector(state) });

export default connect(mapStateToProps, mapDispatchToProps)(DepositModal);
