import { connect } from 'react-redux';
import { AppState } from '../../../../../state/initialState';
import {
  isInvestDialogOpen,
  asyncGetTermSavingTypes,
  setTermSavingPackage,
  asyncDepositTermSaving,
} from '../../state/actions';
import { getInvestDialogStateSelector } from '../../state/selectors';
import InvestDialog from './InvestDialog';
import { InvestDialogState } from '../../state/initialState';

const mapDispatchToProps = {
  isInvestDialogOpen: isInvestDialogOpen,
  asyncGetTermSavingTypes: asyncGetTermSavingTypes.request,
  setTermSavingPackage: setTermSavingPackage,
  asyncDepositTermSaving: asyncDepositTermSaving.request,
};

const mapStateToProps = (state: AppState) => {
  const investDialogState: InvestDialogState = getInvestDialogStateSelector(state);

  return { investDialogState };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvestDialog);
