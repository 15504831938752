import { createReducer } from 'typesafe-actions';
import {
  INVEST_CURRENCY_SAVE_STATE,
  SET_SELECTED_OPTION,
  SET_SELECTED_TAB,
  PRODUCT_INVEST,
  PRODUCT_INVEST_FAIL,
  PRODUCT_INVEST_SUCCESS,
  SET_INVEST_OPEN,
  SET_INVEST_INITIAL,
} from './actions';
import { initialState, ProductCurrencyRequestState, ProductCurrencyState } from './initialState';

//TODO@all types
export default createReducer(initialState, {
  [SET_SELECTED_OPTION]: (state, { payload }) => ({
    ...state,
    selectedOption: payload,
  }),
  [SET_INVEST_OPEN]: (state, { payload }: { payload: boolean }) => ({
    ...state,
    isDialogOpen: payload,
  }),
  [SET_INVEST_INITIAL]: (state, { payload }: { payload: any }) => ({
    ...state,
    membershipState: payload,
  }),
  [SET_SELECTED_TAB]: (state, { payload }) => {
    return {
      ...state,
      selectedTab: payload,
    };
  },
  [INVEST_CURRENCY_SAVE_STATE]: (state, { payload }: { payload: ProductCurrencyRequestState }) => ({
    ...state,
    ...payload,
  }),
  [PRODUCT_INVEST]: state => ({
    ...state,
    inProgress: true,
    fail: false,
    error: null,
  }),
  [PRODUCT_INVEST_SUCCESS]: (state: ProductCurrencyState, { payload }: { payload: any }) => ({
    ...state,
    ...payload,
    inProgress: false,
  }),
  [PRODUCT_INVEST_FAIL]: (state: ProductCurrencyState, { payload }: { payload: any }) => ({
    ...state,
    inProgress: false,
    fail: payload,
  }),
});
