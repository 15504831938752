import { connect } from 'react-redux';
import { AppState } from '../../../../../App/state/initialState';
import { isTwoFactorAuthEnabled } from '../../Settings/state/actions';
import { getSLCPriceSelector } from '../../Wallet/state/selectors';
import { setUserForDialog, clearDialogState } from '../state/actions';
import { getDialogStateSelector } from '../state/selectors';
import AffiliateChangeSlcLimitModal from './AffiliateChangeSlcLimit';

const mapDispatchToProps = {
  isTwoFactorAuthEnabled: isTwoFactorAuthEnabled,
  setUserForDialog: setUserForDialog,
  clearDialogState: clearDialogState,
};
const mapStateToProps = (state: AppState) => {
  const dialogState = getDialogStateSelector(state);
  const slcEurPrice = getSLCPriceSelector(state);
  return { dialogState, slcEurPrice };
};

export default connect(mapStateToProps, mapDispatchToProps)(AffiliateChangeSlcLimitModal);
