/* eslint-disable */
import { createStandardAction } from 'typesafe-actions';
import { ChangePasswordRequest } from '../../../../../../data/repositories/account/typedefs';

export const CHANGE_PASSWORD = 'change_password';
export const CHANGE_PASSWORD_SUCCESS = 'change_password/success';
export const CHANGE_PASSWORD_FAIL = 'change_password/fail';
export const CHANGE_PASSWORD_CLEAR_STATE = 'change_password/clear_state';

export const changePassword = createStandardAction(CHANGE_PASSWORD)<ChangePasswordRequest>();
export const changePasswordSuccess = createStandardAction(CHANGE_PASSWORD_SUCCESS)<undefined>();
export const changePasswordFail = createStandardAction(CHANGE_PASSWORD_FAIL)<Error>();
export const changePasswordClearState = createStandardAction(CHANGE_PASSWORD_CLEAR_STATE)<
  undefined
>();
