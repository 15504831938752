/* eslint-disable */
import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import {
  GetPaginatedDataRequest,
  SaveFilter,
  TransactionGroupingStats,
  TransactionGrouping,
  ExportTransactionGroupingRequest,
} from '../../../../../../data/repositories/admin/typedefs';

export const GET_TRANSACTION_GROUPING_STATS = 'admin/transaction_grouping/get_stats';
export const GET_TRANSACTION_GROUPING_STATS_SUCCESS =
  'admin/transaction_grouping/get_stats_success';
export const GET_TRANSACTION_GROUPING_STATS_FAIL = 'admin/transaction_grouping/get_stats_fail';

export const GET_TRANSACTION_GROUPING_PAGINATED = 'admin/transaction_grouping/get_paginated';
export const GET_TRANSACTION_GROUPING_PAGINATED_SUCCESS =
  'admin/transaction_grouping/get_paginated_success';
export const GET_TRANSACTION_GROUPING_PAGINATED_FAIL =
  'admin/transaction_grouping/get_paginated_fail';

export const EXPORT_TRANSACTION_GROUPING = 'csv-export/transaction_grouping';
export const EXPORT_TRANSACTION_GROUPING_SUCCESS = 'csv-export/transaction_grouping_success';
export const EXPORT_TRANSACTION_GROUPING_FAIL = 'csv-export/transaction_grouping_fail';

export const TRANSACTION_GROUPING_TOGGLE_FILTER = 'admin/transaction_grouping/toggle_filter';
export const TRANSACTION_GROUPING_CLEAR_ALL_FILTERS =
  'admin/transaction_grouping/clear_all_filters';

export const SET_TRANSACTION_GROUPING_PAGE = 'admin/transaction_grouping/set_table_page';

export const asyncGetTransactionGroupingStats = createAsyncAction(
  GET_TRANSACTION_GROUPING_STATS,
  GET_TRANSACTION_GROUPING_STATS_SUCCESS,
  GET_TRANSACTION_GROUPING_STATS_FAIL,
)<undefined, TransactionGroupingStats, Error>();

export const asyncGetTransactionGroupingPaginated = createAsyncAction(
  GET_TRANSACTION_GROUPING_PAGINATED,
  GET_TRANSACTION_GROUPING_PAGINATED_SUCCESS,
  GET_TRANSACTION_GROUPING_PAGINATED_FAIL,
)<GetPaginatedDataRequest, TransactionGrouping, Error>();

export const asyncExportTransactionGrouping = createAsyncAction(
  EXPORT_TRANSACTION_GROUPING,
  EXPORT_TRANSACTION_GROUPING_SUCCESS,
  EXPORT_TRANSACTION_GROUPING_FAIL,
)<ExportTransactionGroupingRequest, undefined, Error>();

export const transactionGroupingToggleFilter = createStandardAction(
  TRANSACTION_GROUPING_TOGGLE_FILTER,
)<SaveFilter>();
export const transactionGroupingClearAllFilters = createStandardAction(
  TRANSACTION_GROUPING_CLEAR_ALL_FILTERS,
)<undefined>();
export const setTransactionGroupingPage = createStandardAction(SET_TRANSACTION_GROUPING_PAGE)<
  number
>();
