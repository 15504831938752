import { useSelector } from 'react-redux';
import { AppState } from '../../../../state/initialState';

const useExchangeCurrencyPrices = () => {
  const coinPrices = useSelector((state: AppState) => state.home.common.coinPrices);

  // Needs to be rewritten
  const getSelectedCoinMarketVal = (selectedOption: string) => {
    const coinList = coinPrices ? Object.entries(coinPrices) : undefined;
    const filtered = coinPrices
      ? (coinList as any).filter((key: string[]) => key[0] === selectedOption)
      : undefined;
    const arr = filtered ? filtered[0] : undefined;
    const values = arr ? arr[1] : undefined;
    const eurVal = values ? values.EUR : 0;

    return eurVal;
  };

  return { getSelectedCoinMarketVal };
};

export default useExchangeCurrencyPrices;
