import { Grid, ListItemIcon, TableCell, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import { PayloadAC } from 'typesafe-actions';
import CoinIconCell from '../../../../../../components/CoinIconCell';
import LightTooltip from '../../../../../../components/LightTooltip';
import {
  GetCryptoChartRequest,
  TableChart,
} from '../../../../../../data/repositories/dashboard/typedefs';
import {
  GetCoinPricesResponse,
  WalletMetadata,
} from '../../../../../../data/repositories/wallet/typedefs';
import { Colors } from '../../../../../../ui/style/colors';
import balanceUtils from '../../../../../../utils/balanceUtils';
import {
  CRYPTO_MAX_FRACTION,
  CRYPTO_MIN_FRACTION,
  WIRE_EUR_MAX_FRACTION,
  WIRE_EUR_MIN_FRACTION,
} from '../../../../../../utils/constants/amounts';
import numberUtils from '../../../../../../utils/numberUtils';
import CryptoChart from '../../CryptoChart/CryptoChart';
import DailyChange from '../../CryptoChart/DailyChange';
import { GET_CRYPTO_CHART_DATA, SET_SELECTED_COIN } from '../../state/actions';
import { initialState } from '../../state/initialState';

const useStyles = makeStyles(() =>
  createStyles({
    row: {
      border: `5px solid ${Colors.white}`,
      cursor: 'pointer',
      '&:nth-child(odd)': {
        backgroundColor: `${Colors.whiteLilac}`,
      },
      '&:hover': {
        backgroundColor: `${Colors.alto}`,
      },
      '& > td': {
        whiteSpace: 'nowrap',
      },
    },
    walletName: {
      fontSize: 14,
      fontWeight: 'bold',
    },
    walletBalance: {
      fontSize: 14,
    },
    link: {
      textDecoration: 'none',
      color: `${Colors.blueCharcoal}`,
      fontSize: 14,
      fontWeight: 600,
      letterSpacing: 1,
    },
    imageDiv: {
      minWidth: 28,

      marginRight: 20,
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    commingSoon: {
      color: `${Colors.mediumTurquoise}`,
      fontSize: 14,
      marginTop: 10,
      minWidth: 85,
    },
    dailyChange: {
      '& span': {
        fontSize: 14,
      },
    },
    errorMsg: {
      color: `${Colors.red}`,
      fontSize: 14,
    },
    selected: {
      backgroundColor: `${Colors.barleyWhite}`,
      '&:nth-child(odd)': {
        backgroundColor: `${Colors.barleyWhite}`,
      },
      '&:hover': {
        backgroundColor: `${Colors.barleyWhite}`,
      },
    },
    pointerNone: {
      pointerEvents: 'none',
    },
    chartCell: { padding: 0, cursor: 'pointer', width: 100 },
    unavailableLink: {
      color: `${Colors.gray}`,
      '&:hover': {
        color: `${Colors.black}`,
      },
    },
  }),
);

type Props = {
  wallet: WalletMetadata;
  isMember: boolean;
  tableChartState: TableChart;
  setSelectedCoin: PayloadAC<typeof SET_SELECTED_COIN, string>;
  selectedCoin: string;
  coinPrices?: GetCoinPricesResponse;
  walletSymbol: string;
  asyncGetCryptoChartData: PayloadAC<typeof GET_CRYPTO_CHART_DATA, GetCryptoChartRequest>;
};

const WalletCoinsTableRow: React.FC<Props> = ({
  wallet,
  isMember,
  tableChartState,
  selectedCoin,
  setSelectedCoin,
  coinPrices,
  walletSymbol,
  asyncGetCryptoChartData,
}: Props): JSX.Element => {
  const classes = useStyles();

  const isCommingSoon = false; ///wallet.symbol === 'BCH';

  if (wallet.balance === 'string') wallet.balance = '0';
  const balance = balanceUtils.formatBalanceToBigWithDecimals(
    wallet.balance || '0',
    wallet.decimals,
  );
  const value = Number(wallet.price_eur ? wallet.price_eur : 0) * Number(balance);
  const graphColorHandler = () => {
    setSelectedCoin(wallet.symbol);
    asyncGetCryptoChartData(initialState.chart.selectedPeriodFilter);
  };

  const lastPrice = coinPrices && coinPrices[walletSymbol] ? coinPrices[walletSymbol]['EUR'] : '';
  const isMissingWallet = wallet.address === 'string';
  return (
    <TableRow
      key={wallet.name}
      className={`${classes.row} ${wallet.symbol === selectedCoin && classes.selected}`}
      onClick={graphColorHandler}
    >
      <TableCell key={wallet.icon}>
        {!isCommingSoon ? (
          <CoinIconCell symbol={wallet.symbol} imgGridSize={6} containerGridSpacing={3} />
        ) : (
          <Grid container style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
            <ListItemIcon style={{ minWidth: 28 }}>
              <div className={classes.imageDiv}>
                <img src={wallet.icon} alt="icon" />
              </div>
            </ListItemIcon>
            <Typography className={classes.walletName}>{wallet.name}</Typography>
          </Grid>
        )}
      </TableCell>
      {isMember || wallet.symbol === 'SLC' ? (
        isMissingWallet ? (
          <TableCell colSpan={2} align="right">
            <LightTooltip variant="buyAddress" symbolName={walletSymbol} interactive={true} />
          </TableCell>
        ) : (
          <>
            <TableCell key={wallet.balance}>
              <Typography className={classes.walletBalance}>
                {!isCommingSoon &&
                  `${balanceUtils.formatBalanceToString(
                    wallet.balance || '0',
                    wallet.decimals,
                    CRYPTO_MIN_FRACTION,
                    CRYPTO_MAX_FRACTION,
                  )} ${wallet.symbol}`}
              </Typography>
            </TableCell>

            <TableCell key={wallet.price_eur}>
              {!isCommingSoon ? (
                <Typography className={classes.walletBalance}>
                  {`${numberUtils.formatNumberDigits(
                    parseFloat(String(value) || '0'),
                    WIRE_EUR_MIN_FRACTION,
                    WIRE_EUR_MAX_FRACTION,
                  )} EUR`}
                </Typography>
              ) : (
                <Typography className={classes.commingSoon}>Coming soon</Typography>
              )}
            </TableCell>
          </>
        )
      ) : (
        <TableCell colSpan={2} align="right">
          {isCommingSoon ? (
            <Typography className={classes.commingSoon}>Coming soon</Typography>
          ) : (
            <LightTooltip variant="membership" interactive={true} />
          )}
        </TableCell>
      )}
      <TableCell>
        <Typography className={classes.walletBalance}>{`€ ${lastPrice}`}</Typography>
      </TableCell>
      {tableChartState.fail ? (
        <TableCell className={classes.errorMsg}>Cannot load data</TableCell>
      ) : (
        <TableCell className={classes.dailyChange}>
          {<DailyChange symbol={wallet.symbol} />}
        </TableCell>
      )}
      {tableChartState.fail ? (
        <TableCell className={classes.errorMsg}>Cannot load data</TableCell>
      ) : !tableChartState.isFallback ? (
        <TableCell className={classes.chartCell}>
          <CryptoChart
            cryptoChartData={{ ...tableChartState, selectedCoin: wallet.symbol }}
            cartesianGrid={{ vertical: false, horizontal: false }}
            hideXAxis={true}
            hideYAxis={true}
            height={50}
            showTooltip={false}
            fullWidth={false}
            stroke={wallet.color}
          />
        </TableCell>
      ) : null}
    </TableRow>
  );
};

export default WalletCoinsTableRow;
