import { all, put, takeEvery } from 'redux-saga/effects';
import LocalStorage from '../../../../../../data/LocalStorage';
import api from '../../../../../../data/repositories/admin';
import {
  GET_TRANSACTION_GROUPING_PAGINATED_ENDPOINT,
  EXPORT_TRANSACTION_GROUPING_ENDPOINT,
} from '../../../../../../utils/constants/api';
import { USER_ID } from '../../../../../../utils/constants/localStorageKeys';
import {
  asyncGetTransactionGroupingPaginated,
  asyncGetTransactionGroupingStats,
  GET_TRANSACTION_GROUPING_PAGINATED,
  setTransactionGroupingPage,
  EXPORT_TRANSACTION_GROUPING,
  asyncExportTransactionGrouping,
} from './actions';
import {
  TransactionGroupingStats,
  GetTransactionGroupingResponse,
} from '../../../../../../data/repositories/admin/typedefs';
import moment from 'moment';
import { saveAs } from 'file-saver';

const mapURL = (params: any) => {
  let newParams = {};
  Object.keys(params).forEach(key => {
    if (params[key] !== null) newParams = { ...newParams, [key]: params[key].toString() };
  });
  return new URLSearchParams(newParams).toString();
};

const getfilter = (appliedFilters: any) => {
  const filtersAvailable = ['deposit', 'withdraw'];
  return {
    tx_side:
      appliedFilters && appliedFilters.transferType && appliedFilters.transferType.length > 0
        ? appliedFilters.transferType.filter((type: any) => filtersAvailable.includes(type))
        : '',
    tx_status: appliedFilters && appliedFilters.status ? appliedFilters.status : null,
    date_from:
      appliedFilters && appliedFilters.dateFilter && appliedFilters.dateFilter.length
        ? moment(appliedFilters.dateFilter[0], 'DD/MM/YYYY').format('YYYY-MM-DD HH:mm:ss')
        : null,
    date_to:
      appliedFilters && appliedFilters.dateFilter && appliedFilters.dateFilter.length
        ? moment(appliedFilters.dateFilter[1], 'DD/MM/YYYY').endOf('day').toDate() >
          moment().toDate()
          ? moment().format('YYYY-MM-DD HH:mm:ss')
          : moment(appliedFilters.dateFilter[1], 'DD/MM/YYYY')
              .endOf('day')
              .format('YYYY-MM-DD HH:mm:ss')
        : null,

    user_email:
      appliedFilters && appliedFilters.searchByEmail ? appliedFilters.searchByEmail : null,
    symbol: appliedFilters && appliedFilters.coinSymbol ? appliedFilters.coinSymbol : null,
  };
};

export function* getTransactionGroupingPaginated$(
  action: ReturnType<typeof asyncGetTransactionGroupingPaginated.request>,
) {
  try {
    const userId = LocalStorage.get(USER_ID);
    const filter = getfilter(action.payload.appliedFilters);
    const result: GetTransactionGroupingResponse = yield api.getTransactionGroupingPaginated(
      GET_TRANSACTION_GROUPING_PAGINATED_ENDPOINT(
        userId ? userId : '',
        action.payload.page,
        action.payload.perPage,
        mapURL(filter) && '&' + mapURL(filter),
      ),
    );

    const stats: TransactionGroupingStats = {
      totalRecords: result.transaction_count,
    };

    yield put(asyncGetTransactionGroupingStats.success(stats));

    yield put(setTransactionGroupingPage(action.payload.page));
    yield put(asyncGetTransactionGroupingPaginated.success(result.user_to_user_transactions));
  } catch (error) {
    yield put(
      asyncGetTransactionGroupingPaginated.failure(
        error && error.message ? error.message : 'Cannot fetch transactions data.',
      ),
    );
  }
}

export function* exportTransactionGrouping$(action: any) {
  try {
    const userId = LocalStorage.get(USER_ID);
    const filter = getfilter(action.payload.appliedFilters);

    const response = yield api.exportTransactionGrouping(
      EXPORT_TRANSACTION_GROUPING_ENDPOINT(
        userId ? userId : '',
        action.payload.page,
        action.payload.perPage,
        mapURL(filter) && '&' + mapURL(filter),
      ),
    );

    const csvData = new Blob([response], { type: 'text/csv;charset=utf-8;' });

    saveAs(csvData, 'TransactionGrouping.csv');

    yield put(asyncExportTransactionGrouping.success());
  } catch (error) {
    yield put(
      asyncExportTransactionGrouping.failure(
        error && error.message ? error.message : 'Cannot export transactions data.',
      ),
    );
  }
}

export default function* () {
  yield all([
    takeEvery(GET_TRANSACTION_GROUPING_PAGINATED, getTransactionGroupingPaginated$),
    takeEvery(EXPORT_TRANSACTION_GROUPING, exportTransactionGrouping$),
  ]);
}
