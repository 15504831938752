import { Button, CircularProgress, Collapse, FormLabel, Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { termSavingsDetailsRequest } from '../../../App/Home/HomeContent/Transactions/state/initialState';
import { getTransactionHashDialogStateSelector } from '../../../App/Home/HomeContent/Transactions/state/selectors';
import {
  IS_TRANSACTION_HASH_DIALOG_OPEN,
  SET_TRANSACTION_HASH_DIALOG_TX_ID,
} from '../../../App/state/actions';
import { AppState } from '../../../App/state/initialState';
import ExternalLink from '../../../assets/external-link.svg';
import { Colors } from '../../../ui/style/colors';
import balanceUtils from '../../../utils/balanceUtils';
import {
  CRYPTO_MAX_FRACTION,
  CRYPTO_MIN_FRACTION,
  WIRE_EUR_DECIMALS,
  WIRE_EUR_MAX_FRACTION,
  WIRE_EUR_MIN_FRACTION,
} from '../../../utils/constants/amounts';
import Amount from '../../Amount';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    claimReward: {
      color: `${Colors.mediumTurquoise}`,
      fontSize: 14,
      fontWeight: 600,
      textAlign: 'right',
      cursor: 'pointer',
    },
    statusSuccess: {
      color: `${Colors.havelockBlue}`,
      fontSize: 14,
      textTransform: 'capitalize',
    },
    statusPending: {
      color: `${Colors.blueViolet}`,
      fontSize: 14,
      textTransform: 'capitalize',
    },
    expandButton: {
      textTransform: 'none',
      marginLeft: 'auto',
      color: theme.palette.primary.light,
    },
    cell: {
      borderBottom: 'none',
    },
    expandableCell: { borderTop: 'none' },
    collapse: {
      padding: 40,
    },
    labels: {
      fontSize: 14,
      color: theme.palette.secondary.light,
    },
    transactionDetailsValues: {
      marginTop: 10,
      fontSize: 14,
      color: theme.palette.primary.light,
    },
    transactionStatusValue: {
      marginTop: 10,
      fontSize: 16,
      color: `${Colors.ceruleanBLue}`,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    capitalFirst: {
      textTransform: 'capitalize',
    },
    viewDetailsButton: {},
    flex: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    textOverflow: {
      width: 100,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      cursor: 'pointer',
      textDecoration: 'underline',
      color: theme.palette.primary.light,
    },
  }),
);

type Props = {
  referral: any;
  expanded: boolean;
  setExpanded: (transactionId: string) => void;
  termSavingsDetails?: termSavingsDetailsRequest;
};

const AffiliatesPremiumTableRow: FC<Props> = ({
  referral,
  expanded,
  setExpanded,
  termSavingsDetails,
}: Props) => {
  const classes = useStyles();
  const renderType = (type: any) => {
    switch (type) {
      case 'airdrop_reward':
        return 'Airdrop';
      case 'new_user_reward':
        return 'Registration reward';
      case 'new_merchant_reward':
        return 'Became Partner';
      case 'buy':
        return 'Buy';
      case 'deposit':
        return 'Deposit';
      case 'withdraw':
        return 'Withdraw';
      case 'exchange':
        return 'Exchange';
      case 'merchant_parent_reward':
        return 'Affiliate became Partner';
      case 'new_user_affiliate_reward':
        return 'New Affiliate';
      case 'payment_sent':
        return 'Purchase';
      case 'merchant_income':
        return 'Sale';
      case 'pool_reward':
        return 'Pool reward';
      case 'platform_pool_reward':
        return 'Platform pool reward';
      case 'premium_user_reward':
        return 'Affiliate package purchase';
      case 'premium_user_affiliate_reward':
        return 'Affiliate purchased Affiliate package';
      case 'affiliate_percentage_reward':
        return 'BV';
      case 'sell':
        return 'Sell';
      case 'VAT':
        return 'VAT';
      case 'timed_escrow':
        return 'Timed Escrow';
      case 'waiting_for_approval':
        return 'Waiting for approval';
      case 'pending_escrow':
        return 'Pending Escrow';
      case 'term_saving':
        return 'BV';
      case 'invested':
        return 'In HODL';
      case 'transfer':
        return 'Transfer';
      case 'bonus':
        return 'Bonus';
      case 'reward_correction':
        return 'Reward transfer';
      case 'buy_product':
        return 'Buy product';
      case 'sell_product':
        return 'Sell product';
      case 'event_reward':
        return 'Event reward';
      case 'bonus_reward':
        return 'Bonus reward';
      case 'interest_payment':
        return 'HODL Interested payment';
      case 'term_saving_finished':
        return 'Term saving payout';
      default:
        return type;
    }
  };
  const dispatch = useDispatch();
  const balance = balanceUtils.formatBalanceToString(
    referral.amount || 0,
    7,
    CRYPTO_MIN_FRACTION,
    CRYPTO_MAX_FRACTION,
  );
  const swapInfo = useSelector((state: AppState) => getTransactionHashDialogStateSelector(state));

  const openTxHashModal = () => {
    dispatch({ type: IS_TRANSACTION_HASH_DIALOG_OPEN, payload: true });
    dispatch({ type: SET_TRANSACTION_HASH_DIALOG_TX_ID, payload: referral.txHash.String });
  };

  const getTxHashLink = () => {
    if (referral.txHash.String) {
      //New registered users do not have swap info and we display to them only hash link.
      return swapInfo.isNewRegisteredUser || swapInfo.swapDate < referral.created_at ? (
        <div className={classes.textOverflow}>
          <a
            target="_blank"
            rel="noreferrer"
            href={`${process.env.REACT_APP_HYPERLEDGER_EXPLORER_URL}?tab=transactions&transId=${referral.txHash.String}`}
          >
            {referral.txHash.String}
          </a>
        </div>
      ) : (
        <div className={classes.textOverflow}>
          <span onClick={openTxHashModal}>{referral.txHash.String}</span>
        </div>
      );
    } else {
      return <span>Coming soon</span>;
    }
  };

  return (
    <>
      <TableRow>
        <TableCell
          className={
            referral.transaction_status === 'completed'
              ? classes.statusSuccess
              : classes.statusPending
          }
        >
          {renderType(referral.transaction_status)}
        </TableCell>
        <TableCell>{getTxHashLink()}</TableCell>
        <TableCell>
          <div className={classes.flex}>
            <span>{renderType(referral.origin)}</span>
            {(referral.origin === 'buy' || referral.origin === 'buy_product') &&
              referral.transaction_status !== 'waiting_for_approval' &&
              referral.txHash.String && (
                <Button
                  className={classes.expandButton}
                  style={expanded ? { fontWeight: 'bold', color: `${Colors.blueCharcoal}` } : {}}
                  onClick={() => setExpanded('')}
                >
                  {expanded ? 'Hide' : 'View'} Details
                </Button>
              )}

            {referral.transaction_status === 'invested' && (
              <Button
                className={classes.expandButton}
                style={expanded ? { fontWeight: 'bold', color: `${Colors.blueCharcoal}` } : {}}
                onClick={() => setExpanded(referral.id)}
              >
                {expanded ? 'Hide' : 'View'} Details
              </Button>
            )}
          </div>
        </TableCell>
        <TableCell align="right">
          {moment(referral.created_at).format('DD/MM/YYYY HH:mm')}
        </TableCell>
        <TableCell align="right">
          <Amount amount={balance} condition={referral.amount > 0} currency={referral.symbol} />
        </TableCell>
        <TableCell align="right">
          <Amount
            amount={balanceUtils.formatBalanceToString(
              referral.eur_amount || 0,
              WIRE_EUR_DECIMALS,
              WIRE_EUR_MIN_FRACTION,
              WIRE_EUR_MAX_FRACTION,
            )}
            condition={referral.amount > 0}
            currency="EUR"
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          className={classes.expandableCell}
          colSpan={6}
        >
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            {referral.transaction_status === 'invested' && termSavingsDetails ? (
              termSavingsDetails.start_date === '' ? (
                <div style={{ padding: 83, textAlign: 'center' }}>
                  <CircularProgress size={34} color="secondary" />
                </div>
              ) : (
                <Grid container spacing={3} className={classes.collapse}>
                  <Grid item xs={4} zeroMinWidth>
                    <FormLabel className={classes.labels}>Earned interest so far:</FormLabel>
                    <Typography noWrap component="p" className={classes.transactionDetailsValues}>
                      {balanceUtils.formatBalanceToString(
                        termSavingsDetails.earned_interest || 0,
                        7,
                        2,
                        2,
                      )}{' '}
                      SLC
                    </Typography>
                  </Grid>
                  <Grid item xs={4} zeroMinWidth>
                    <FormLabel className={classes.labels}>Start date:</FormLabel>
                    <Typography noWrap component="p" className={classes.transactionDetailsValues}>
                      {moment(termSavingsDetails.start_date).format('DD/MM/YYYY HH:mm')}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} zeroMinWidth>
                    <FormLabel className={classes.labels}>Maturity date:</FormLabel>
                    <Typography noWrap component="p" className={classes.transactionDetailsValues}>
                      {moment(termSavingsDetails.maturity_date).format('DD/MM/YYYY HH:mm')}
                    </Typography>
                  </Grid>
                  {termSavingsDetails.description !== '' && (
                    <Grid item xs={8} zeroMinWidth>
                      <FormLabel className={classes.labels}>Description:</FormLabel>
                      <Typography noWrap component="p" className={classes.transactionDetailsValues}>
                        {termSavingsDetails.description}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              )
            ) : (
              <Grid container spacing={3} className={classes.collapse}>
                <Grid item xs={8} zeroMinWidth>
                  <FormLabel className={classes.labels}>Transaction ID</FormLabel>
                  <Typography noWrap component="p" className={classes.transactionDetailsValues}>
                    {referral.txHash.String ? referral.txHash.String : 'Coming soon'}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <FormLabel className={classes.labels}>Status</FormLabel>
                  <Typography component="p">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`${
                        process.env.REACT_APP_HYPERLEDGER_EXPLORER_URL
                      }?tab=transactions&transId=${referral.txHash && referral.txHash.String}`}
                      className={classes.transactionStatusValue}
                    >
                      confirmed <img src={ExternalLink} alt="externalLink" />
                    </a>
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default AffiliatesPremiumTableRow;
