import Swiper, { Grid, Navigation } from 'swiper';
import AOS from 'aos';

AOS.init();
export const toggleMenu = () => {
  const nav = document.querySelector('.o-nav');
  const toggle = document.querySelector('.o-nav__collaps-btn');
  const header = document.querySelector('.o-header');
  if (toggle && nav && header) {
    toggle.classList.toggle('active');
    nav.classList.toggle('active');
    if (!header.classList.contains('u-bg-light')) {
      header.classList.toggle('u-bg-light');
    }
  }
};
export const showMore = () => {
  var dots = document.getElementById('dots');
  var moreText = document.getElementById('more');
  var btnText = document.getElementById('slaff-info-btn-name');
  if (dots.style.display === 'none') {
    dots.style.display = 'inline';
    btnText.innerHTML = 'Show more';
    moreText.style.display = 'none';
  } else {
    dots.style.display = 'none';
    btnText.innerHTML = 'Show less';
    moreText.style.display = 'inline';
  }
};

window.addEventListener('load', () => {
  const typingText = document.querySelector('.JS-typing');
  if (typingText) {
    let dataText = ['Reenvisioned', 'Blockchain', 'Trustworthy'];
    const typeWriter = (text, i, fnCallback) => {
      if (i < text.length) {
        typingText.innerHTML = text.substring(0, i + 1);
        setTimeout(function () {
          typeWriter(text, i + 1, fnCallback);
        }, 150);
      } else if (typeof fnCallback == 'function') {
        setTimeout(fnCallback, 1000);
      }
    };
    const StartTextAnimation = i => {
      if (typeof dataText[i] == 'undefined') {
        setTimeout(function () {
          StartTextAnimation(0);
        }, 2000);
      }
      if (i < dataText.length) {
        typeWriter(dataText[i], 0, function () {
          StartTextAnimation(i + 1);
        });
      }
    };
    StartTextAnimation(0);
  }

  function faq() {
    const faqContainer = document.querySelector('.JS-faq-container');
    const switchers = document.querySelectorAll('.s-faq__switcher-item');
    const faqBlokcs = document.querySelectorAll('.JS-faq-block');
    let accordion = document.getElementsByClassName('JS-accordion');
    let i;

    let siblings = function (el) {
      if (el.parentNode === null) return [];

      return Array.prototype.filter.call(el.parentNode.children, function (child) {
        return child !== el;
      });
    };

    switchers.forEach(function (elem) {
      let sibBtns = siblings(elem);
      let target = elem.classList.contains('active') && elem.dataset.faq;
      let containerTarget = faqContainer.querySelector(`[data-faq-target="${target}"]`);
      containerTarget !== null && containerTarget.classList.remove('d-none');

      elem.addEventListener('click', function () {
        target = elem.dataset.faq;
        containerTarget = faqContainer.querySelector(`[data-faq-target="${target}"]`);
        let sibTarget = containerTarget !== null && siblings(containerTarget);
        if (!elem.classList.contains('active')) {
          elem.classList.add('active');
          containerTarget.classList.remove('d-none');
          sibBtns.forEach(function (el) {
            el.classList.remove('active');
          });
          sibTarget.forEach(function (el) {
            !el.classList.contains('d-none') && el.classList.add('d-none');
          });
        }
      });
    });

    for (i = 0; i < accordion.length; i++) {
      accordion[i].addEventListener('click', function () {
        this.classList.toggle('active');
        let panel = this.querySelector('.s-faq__info-text');
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = `${panel.scrollHeight}px`;
        }
      });
    }

    faqBlokcs.forEach(block => {
      let items = block.querySelectorAll('.JS-accordion');
      const btn = block.querySelector('.JS-faq-more');
      let text = btn.querySelectorAll('span');

      if (items.length <= 6) {
        btn.classList.add('d-none');
      }

      for (let i = 6; i < items.length; i++) {
        items[i].style.display = 'none';
      }

      btn.addEventListener('click', e => {
        e.preventDefault();
        btn.classList.toggle('open');
        text.forEach(el => {
          el.classList.toggle('d-none');
        });
        if (btn.classList.contains('open')) {
          for (let i = 0; i < items.length; i++) {
            items[i].style = '';
          }
        } else {
          for (let i = 6; i < items.length; i++) {
            items[i].style.display = 'none';
          }
        }
      });
    });
  }

  faq();

  function form() {
    const show = document.querySelector('.c-form__icon-show');
    const dropArea = document.querySelector('.c-form__control-drop');
    if (show !== null) {
      const showImg = show.querySelectorAll('img');
      show.addEventListener('click', () => showImg.forEach(el => el.classList.toggle('d-none')));
    }

    if (dropArea !== null) {
      ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
        dropArea.addEventListener(eventName, preventDefaults, false);
      });
      const preventDefaults = e => {
        e.preventDefault();
        e.stopPropagation();
      };

      ['dragenter', 'dragover'].forEach(eventName => {
        dropArea.addEventListener(eventName, highlight, false);
      });

      ['dragleave', 'drop'].forEach(eventName => {
        dropArea.addEventListener(eventName, unhighlight, false);
      });

      const highlight = () => dropArea.classList.add('highlight');
      const unhighlight = () => dropArea.classList.remove('highlight');

      dropArea.addEventListener('drop', handleDrop, false);

      const handleDrop = e => {
        let dt = e.dataTransfer;
        let files = dt.files;
        handleFiles(files);
      };

      const handleFiles = files => alert(files + 'upload');
    }
  }

  form();
  //-----------nav
  function menu() {
    const nav = document.querySelector('.o-nav');
    const toggle = document.querySelector('.o-nav__collaps-btn');
    const navLink = document.querySelectorAll('.o-nav__link');
    if (toggle) {
      navLink.forEach(link => {
        link.addEventListener('click', () => {
          toggle.classList.remove('active');
          nav.classList.remove('active');
        });
      });
    }
    window.addEventListener('resize', () => {
      const header = document.querySelector('.o-header');
      if (window.clientWidth > 992) {
        toggle.classList.remove('active');
        nav.classList.remove('active');
        if (header.offsetTop == 0) {
          header.classList.remove('u-bg-light');
        }
      }
    });

    const header = document.querySelector('.o-header');
    header &&
      document.addEventListener('scroll', function () {
        if (header.offsetTop !== 0) {
          header.classList.add('u-bg-light');
        } else {
          header.classList.remove('u-bg-light');
        }
      });
  }

  menu();
  let container = document.querySelector('.JS-hero');

  if (container !== null) {
    let phone = document.querySelector('.o-hero__illustration-phone');
    let vector = document.querySelector('.o-hero__illustration-vector');
    let chart = document.querySelector('.o-hero__illustration-chart');

    if (window.innerWidth > 992 && container !== 0) {
      window.addEventListener('mousemove', function (e) {
        let x = e.clientX / window.innerWidth;
        let y = e.clientY / window.innerHeight;

        phone.style.transform = `translate(${x * 50}px, ${y * 50}px) scale(${1 + x * 0.1})`;
        chart.style.transform = `translate(${x * 70}px, 0px) scale(${1 + x * 0.1})`;
        vector.style.transform = `translate(${x * 80}px, ${y * 80}px)`;
      });
    }

    window.addEventListener('resize', () => {
      if (window.outerWidth < 992) {
        phone.removeAttribute('style');
        chart.removeAttribute('style');
        vector.removeAttribute('style');
      }
    });
  }

  const sliders = document.querySelectorAll('.JS-slider');
  sliders.forEach(slider => {
    if (slider.classList.contains('s-blog__slider')) {
      const nextEl = slider.querySelector('.swiper-button-next');
      const prevEl = slider.querySelector('.swiper-button-prev');
      const sliderPagination = slider.querySelector('.swiper-pagination');
      const container = slider.querySelector('.swiper');
      const swiper = new Swiper(container, {
        modules: [Navigation, Grid],
        slidesPerView: 1,
        spaceBetween: 15,
        navigation: {
          nextEl: nextEl,
          prevEl: prevEl,
        },

        grid: {
          rows: 2,
          fill: 'row',
        },

        breakpoints: {
          992: {
            slidesPerView: 3,
          },
        },

        pagination: {
          el: sliderPagination,
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="' + className + '">' + (index + 1) + '</span>';
          },
        },
      });
      swiper.on('resize', function () {
        swiper.update();
      });
    } else {
      const nextEl = slider.querySelector('.swiper-button-next');
      const prevEl = slider.querySelector('.swiper-button-prev');
      const container = slider.querySelector('.swiper');
      const swiper = new Swiper(container, {
        modules: [Navigation, Grid],

        navigation: {
          nextEl: nextEl,
          prevEl: prevEl,
        },
        slidesPerView: 1,
        grid: {
          rows: 2,
          fill: 'row',
        },
        spaceBetween: 30,
        breakpoints: {
          760: {
            slidesPerView: 2,
            grid: {
              rows: 2,
              fill: 'row',
            },
          },
          992: {
            slidesPerView: 3,
            grid: {
              rows: 2,
              fill: 'row',
            },
          },
        },
      });
      swiper.on('resize', function () {
        swiper.update();
      });
    }
  });
});
