import axios from 'axios';
import {
  ACCESS_TOKEN,
  KYC_STATUS,
  REFRESH_TOKEN,
  USER_EMAIL,
  USER_ID,
  AFFILIATE_PACKAGE,
} from '../../utils/constants/localStorageKeys';
import LocalStorage from '../LocalStorage';
import authInterceptor from './authInterceptor';
import { default as config } from './config';
import { GET_TOKEN_ENDPOINT, LOGOUT_ENDPOINT } from '../../utils/constants/api';
import api from '../../data/repositories/account';

export const parseArgs = args => {
  let [url, data, opts] = args;

  if (typeof args[0] !== 'string') {
    const d = args[0];
    ({ url, data, opts } = d);
  }

  return { url, data, opts };
};

export default class Http {
  constructor() {
    this.http = axios.create(config);
    this.http.interceptors.request.use(authInterceptor, error => Promise.reject(error));
    this.http.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        const refresh_token = LocalStorage.get(REFRESH_TOKEN);
        const originalRequest = error.config;
        const status = error.response.status;

        const logout = () => {
          LocalStorage.clear();
          window.location.href = `${process.env.REACT_APP_SL_GROUP_HOST}${LOGOUT_ENDPOINT}?from_url=${window.location.origin}`;
        };

        const refreshToken = async refresh_token => {
          originalRequest._retry = true;
          try {
            const response = await api.refreshToken(refresh_token);
            LocalStorage.set(USER_ID, response.id);
            LocalStorage.set(USER_EMAIL, response.email);
            LocalStorage.set(KYC_STATUS, response.kyc_status);
            LocalStorage.set(ACCESS_TOKEN, response.access_token);
            LocalStorage.set(REFRESH_TOKEN, response.refresh_token);
            LocalStorage.set(AFFILIATE_PACKAGE, !!response.affiliate_package);

            return this.http.request(originalRequest);
          } catch (error) {
            logout();
            return Promise.reject(error);
          }
        };

        /** If refresh token failed logout user */
        if ((status === 400 || status === 401) && originalRequest.url === GET_TOKEN_ENDPOINT) {
          return logout();
        }

        /**If user is unauthorized or has expired access token */
        /**IAM reuturns 403 for invalied access token */
        if ((status === 401 || status === 403) && !originalRequest._retry) {
          return refreshToken(refresh_token);
        }

        return Promise.reject(error);
      },
    );
  }

  request(method, url, data, opts) {
    return new Promise((resolve, reject) => {
      let options = {};

      if (typeof method === 'string') {
        options = { method, url, ...opts };
        const key = options.method === 'get' ? 'params' : 'data';
        options[key] = data;
      } else {
        options = method;
      }

      this.http(options).then(resolve).catch(reject);
    });
  }

  get(...args) {
    const { url, data, opts } = parseArgs(args);

    return this.request('get', url, data, opts);
  }

  post(...args) {
    const { url, data, opts } = parseArgs(args);

    return this.request('post', url, data, opts);
  }

  put(...args) {
    const { url, data, opts } = parseArgs(args);

    return this.request('put', url, data, opts);
  }

  delete(...args) {
    const { url, data, opts } = parseArgs(args);

    return this.request('delete', url, data, opts);
  }
}
