/* eslint-disable */
import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import {
  Cycle,
  GetReferralsPaginatedRequest,
  GetUnclaimedSLCResponse,
  Referral,
} from '../../../../../../data/repositories/affiliate/typedefs';
import { termSavingsDetailsRequest } from '../../state/initialState';
import { PremiumReferralStats } from './initialState';

export const GET_PREMIUM_REFERRALS_STATS = 'affiliate/get_premium_referrals_stats';
export const GET_PREMIUM_REFERRALS_STATS_SUCCESS = 'affiliate/get_premium_referrals_stats_success';
export const GET_PREMIUM_REFERRALS_STATS_FAIL = 'affiliate/get_premium_referrals_stats_fail';

export const GET_PREMIUM_REFERRALS_PAGINATED = 'affiliate/get_premium_referrals_paginated';
export const GET_PREMIUM_REFERRALS_PAGINATED_SUCCESS =
  'affiliate/get_premium_referrals_paginated/success';
export const GET_PREMIUM_REFERRALS_PAGINATED_FAILED =
  'affiliate/get_premium_referrals_paginated/fail';

export const GET_PREMIUM_UNCLAIMED_SLC = 'affiliate/get_premium_unclaimed_slc';
export const GET_PREMIUM_UNCLAIMED_SLC_SUCCESS = 'affiliate/get_premium_unclaimed_slc/success';
export const GET_PREMIUM_UNCLAIMED_SLC_FAIL = 'affiliate/get_premium_unclaimed_slc/fail';

export const CLAIM_PREMIUM_SLC = 'affiliate/claim_premium_slc';
export const CLAIM_PREMIUM_SLC_SUCCESS = 'affiliate/claim_premium_slc/success';
export const CLAIM_PREMIUM_SLC_FAIL = 'affiliate/claim_premium_slc/fail';

export const SET_PREMIUM_TABLE_PAGE = 'affiliate/set_premium_table_page';

export const GET_TERM_SAVINGS_DETAILS = 'account/get_term_savings_details';
export const GET_TERM_SAVINGS_DETAILS_SUCCESS = 'account/get_term_savings_details/success';
export const GET_TERM_SAVINGS_DETAILS_FAIL = 'account/get_term_savings_details/fail';

export const asyncGetPremiumReferralsStats = createAsyncAction(
  GET_PREMIUM_REFERRALS_STATS,
  GET_PREMIUM_REFERRALS_STATS_SUCCESS,
  GET_PREMIUM_REFERRALS_STATS_FAIL,
)<undefined, PremiumReferralStats, Error>();

export const asyncGetPremiumReferralsPaginated = createAsyncAction(
  GET_PREMIUM_REFERRALS_PAGINATED,
  GET_PREMIUM_REFERRALS_PAGINATED_SUCCESS,
  GET_PREMIUM_REFERRALS_PAGINATED_FAILED,
)<GetReferralsPaginatedRequest, { [id: string]: Referral }, Error>();

export const asyncGetPremiumUnclaimedSLC = createAsyncAction(
  GET_PREMIUM_UNCLAIMED_SLC,
  GET_PREMIUM_UNCLAIMED_SLC_SUCCESS,
  GET_PREMIUM_UNCLAIMED_SLC_FAIL,
)<undefined, GetUnclaimedSLCResponse, Error>();

export const asyncClaimPremiumSLC = createAsyncAction(
  CLAIM_PREMIUM_SLC,
  CLAIM_PREMIUM_SLC_SUCCESS,
  CLAIM_PREMIUM_SLC_FAIL,
)<Cycle[], undefined, Error>();

export const asyncGetTermSavingsDetails = createAsyncAction(
  GET_TERM_SAVINGS_DETAILS,
  GET_TERM_SAVINGS_DETAILS_SUCCESS,
  GET_TERM_SAVINGS_DETAILS_FAIL,
)<string, termSavingsDetailsRequest, Error>();

export const setPremiumTablePage = createStandardAction(SET_PREMIUM_TABLE_PAGE)<number>();
