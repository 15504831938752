import { createStyles, makeStyles, Select, Theme } from '@material-ui/core';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import React, { FC } from 'react';
import SacretBaseInput from '../SacretBaseInput';
import SacretDaterangePicker from './SacretDaterangePicker';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      fill: theme.palette.secondary.light,
      width: 15,
      height: 15,
      marginTop: 4,
      marginRight: 7,
    },
    content: {},
    label: {
      float: 'left',
      padding: '2px 0',
      fontSize: 12,
      color: theme.palette.primary.light,
    },
    listItem: {
      margin: 0,
      padding: 0,
    },
  }),
);
type Props = {
  onChange: (value: string[]) => void;
  label: string;
  stateFilterValue?: string[] | undefined;
  className?: string;
};

const SacretDaterangePickerSelect: FC<Props> = ({
  onChange,
  label,
  stateFilterValue,
  className,
}: Props) => {
  const classes = useStyles();

  const AnchorProps: {
    horizontal: number | 'left' | 'right' | 'center';
    vertical: number | 'bottom' | 'top' | 'center';
  } = {
    vertical: 'bottom',
    horizontal: 'left',
  };

  const TransformProps: {
    horizontal: number | 'left' | 'right' | 'center';
    vertical: number | 'bottom' | 'top' | 'center';
  } = {
    vertical: 'top',
    horizontal: 'left',
  };
  const MenuProps = {
    PaperProps: {
      style: {
        borderRadius: 4,
        boxShadow: '0 10px 30px 0 rgba(132,141,182,0.25)',
        marginTop: -3,
        //maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 206,
        height: 'auto',
      },
    },
    anchorOrigin: AnchorProps,
    transformOrigin: TransformProps,
    getContentAnchorEl: null,
  };

  const selectedDatesLabel = stateFilterValue
    ? `${stateFilterValue[0]} - ${stateFilterValue[1]}`
    : label;

  const [selectDropdownOpened, setSelectDropdownOpened] = React.useState<boolean>(false);

  const onSelectOpen = () => {
    setSelectDropdownOpened(true);
    setTimeout(() => {
      const weekdaysLabels = document.getElementsByClassName('DateRangePicker__WeekdayHeading');
      for (const index in weekdaysLabels) {
        const weekdayLabel = weekdaysLabels[index].firstChild as HTMLElement;
        if (weekdayLabel) {
          weekdayLabel.innerText = weekdayLabel.title.charAt(0);
        }
      }
    }, 1);
  };
  const onSelectClose = () => {
    setSelectDropdownOpened(false);
  };

  return (
    <Select
      multiple
      value={[]}
      variant="outlined"
      input={<SacretBaseInput value="Amount" />}
      MenuProps={MenuProps}
      inputProps={{
        classes: { icon: classes.icon },
      }}
      displayEmpty={true}
      renderValue={() => (
        <div className={classes.content}>
          <div className={classes.label}>{selectedDatesLabel}</div>
        </div>
      )}
      IconComponent={selectDropdownOpened ? KeyboardArrowUp : KeyboardArrowDown}
      onOpen={onSelectOpen}
      onClose={onSelectClose}
      className={className}
    >
      {/* we need div tag here because of error: https://github.com/mui/material-ui/issues/15903#issuecomment-723807594 */}
      <div>
        <SacretDaterangePicker onChange={onChange} stateFilterValue={stateFilterValue} />
      </div>
    </Select>
  );
};

export default SacretDaterangePickerSelect;
