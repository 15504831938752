import { isEmpty, pick } from 'lodash';
import moment from 'moment';
import { all, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import LocalStorage from '../../../../../../data/LocalStorage';
import api from '../../../../../../data/repositories/affiliate';
import {
  GetUnclaimedSLCResponse,
  Referral,
} from '../../../../../../data/repositories/affiliate/typedefs';
import {
  CLAIM_SLC_ENDPOINT,
  GET_REFERRALS_PAGINATED_ENDPOINT,
  GET_REFERRALS_STATS_ENDPOINT,
  GET_REFERRALS_TREE_ENDPOINT,
  GET_REFERRALS_TREE_SEARCH_ENDPOINT,
  GET_UNCLAIMED_SLC_ENDPOINT,
} from '../../../../../../utils/constants/api';
import { USER_ID } from '../../../../../../utils/constants/localStorageKeys';
import { setInfoDialogFields } from '../../../InfoDialog/state/actions';
import {
  asyncChangeUserStatus,
  asyncClaimSLC,
  asyncGetBalanceFromAffiliateUser,
  asyncGetReferralDetail,
  asyncGetReferralsPaginated,
  asyncGetReferralsStats,
  asyncGetReferralTree,
  asyncGetUnclaimedSLC,
  asyncPrepareFullProfileForAffiliate,
  asyncResetUserKYCStatus,
  asyncSaveSlcChangeLimit,
  asyncToggleAdminRole,
  asyncTradeSLC,
  asyncUserHasAffiliatePackageCheck,
  CHANGE_USER_STATUS,
  CHANGE_USER_STATUS_SUCCESS,
  CLAIM_SLC,
  GET_BALANCE_FROM_AFFILIATE_USER,
  GET_REFERRALS_PAGINATED,
  GET_REFERRALS_STATS,
  GET_REFERRALS_TREE,
  GET_UNCLAIMED_SLC,
  PREPARE_FULL_PROFILE_FOR_AFFILIATE,
  REFERRAL_DETAIL,
  RESET_USER_KYC_STATUS,
  RESET_USER_KYC_STATUS_SUCCESS,
  SAVE_SLC_CHANGE_LIMIT,
  setDialogStep,
  setTablePage,
  TOGGLE_ADMIN_ROLE,
  TRADE_SLC,
  USER_HAS_AFFILIATE_PACKAGE_CHECK,
} from './actions';
import { ReferralStats, SellSlcInfoResponse } from './initialState';
import { affiliateTableStateSelector } from './selectors';

export function* getReferralsStats$() {
  try {
    const userId = LocalStorage.get(USER_ID);
    const response = yield api.getReferralsStats(
      GET_REFERRALS_STATS_ENDPOINT(userId ? userId : ''),
    );

    const stats: ReferralStats = {
      totalReferrals: response.referral_count,
      directReferrals: response.direct_referral_count,
    };

    yield put(asyncGetReferralsStats.success(stats));
  } catch (error) {
    yield put(asyncGetReferralsStats.failure(error));
  }
}

export function* getReferralDetail$(action: ReturnType<typeof asyncGetReferralDetail.request>) {
  try {
    const userId = LocalStorage.get(USER_ID);

    let nextTree = [];
    const referral = yield api.getReferralTree(
      GET_REFERRALS_TREE_SEARCH_ENDPOINT(userId ? userId : '', action.payload.email),
    );

    if (referral.full_referral_info.shallow_children_count) {
      nextTree = yield api.getReferralTree(
        GET_REFERRALS_TREE_ENDPOINT(userId ? userId : '', action.payload.id),
      );
    }
    // yield put(asyncGetReferralsPaginated.success(result));

    let path;
    if (referral.full_referral_info.mail_path) {
      const mailPath = referral.full_referral_info.mail_path.split(' -> ');
      const refPath = referral.full_referral_info.reference_path.split(' -> ');
      if (mailPath.length >= 1)
        path = mailPath.reduce(
          (p: { [x: string]: { email: any; id: any } }, c: any, index: number) => {
            p[index + 1] = { email: c, id: refPath[index] };
            return p;
          },
          {},
        );
    }
    const result = { ...referral, path, children: nextTree };
    yield put(asyncGetReferralDetail.success({ referralDetail: result }));
  } catch (e) {
    yield put(asyncGetReferralDetail.failure(e));
  }
}

export function* getReferralTree$(action: ReturnType<typeof asyncGetReferralTree.request>) {
  try {
    const userId = LocalStorage.get(USER_ID);
    if (action.payload.email && !action.payload.referralId) {
      const referrals: any = yield api.getReferralTree(
        GET_REFERRALS_TREE_SEARCH_ENDPOINT(userId ? userId : '', action.payload.email),
      );
      const result: { [s: string]: Referral } = {};
      if (Array.isArray(referrals))
        referrals.map(referral => {
          result[referral.full_referral_info.referral_id] = referral;
        });
      else {
        result[referrals.full_referral_info.referral_id] = referrals.full_referral_info;
      }
      yield put(asyncGetReferralsStats.success({ paginatedReferrals: 1 }));
      yield put(asyncGetReferralsPaginated.success(result));
    } else {
      const nextTree = yield api.getReferralTree(
        GET_REFERRALS_TREE_ENDPOINT(userId ? userId : '', action.payload.referralId),
      );
      const { referralTree, breadcrumbs } = yield select(affiliateTableStateSelector);

      const result = {
        referralTree: action.payload.path
          ? {
              ...Object.values(action.payload.path).reduce(
                (p: any, c: any, index: number) => {
                  p[index] = [
                    {
                      referral_id: c.id,
                      email: c.email,
                      has_children: true,
                    },
                  ];
                  return p;
                },

                {},
              ),
              [action.payload.level]: nextTree,
            }
          : action.payload.level === 1 && isEmpty(referralTree)
          ? {
              0: [
                {
                  referral_id: action.payload.referralId,
                  email: action.payload.email,
                  has_children: true,
                },
              ],
              [action.payload.level]: nextTree,
            }
          : Object.keys(referralTree).length - 1 > action.payload.level
          ? {
              ...pick(
                referralTree,
                [...Array(action.payload.level).keys()].filter(k => k < action.payload.level),
              ),
              [action.payload.level]: nextTree,
            }
          : { ...referralTree, [action.payload.level]: nextTree },
        level: action.payload.level,
        breadcrumbs: action.payload.path
          ? {
              ...action.payload.path,
              [action.payload.level]: {
                email: action.payload.email,
                id: action.payload.referralId,
              },
            }
          : Object.keys(referralTree).length - 1 > action.payload.level
          ? {
              ...pick(
                breadcrumbs,
                [...Array(action.payload.level).keys()].filter(k => k < action.payload.level),
              ),
              [action.payload.level]: {
                email: action.payload.email,
                id: action.payload.referralId,
              },
            }
          : {
              ...breadcrumbs,
              [action.payload.level]: {
                email: action.payload.email,
                id: action.payload.referralId,
              },
            },
      };

      yield put(asyncGetReferralTree.success(result));
    }
  } catch (error) {
    yield put(asyncGetReferralTree.failure(error));
  }
}

const getEnabledStatus = (status: string) => {
  switch (status) {
    case 'blacklisted':
      return false;
    case 'confirmed':
    case 'whitelisted':
    default:
      return true;
  }
};

const getConfirmedStatus = (status: string) => {
  switch (status) {
    case 'blacklisted':
      return null;
    case 'whitelisted':
      return false;
    case 'confirmed':
    default:
      return true;
  }
};

export function* getReferralsPaginated$(
  action: ReturnType<typeof asyncGetReferralsPaginated.request>,
) {
  try {
    const userId = LocalStorage.get(USER_ID);

    const filter = {
      dateFrom:
        action.payload.appliedFilters &&
        action.payload.appliedFilters.dateFilter &&
        action.payload.appliedFilters.dateFilter.length
          ? moment(action.payload.appliedFilters.dateFilter[0], 'DD/MM/YYYY').format(
              'YYYY-MM-DD HH:mm:ss',
            )
          : null,
      dateTo:
        action.payload.appliedFilters &&
        action.payload.appliedFilters.dateFilter &&
        action.payload.appliedFilters.dateFilter.length
          ? moment(action.payload.appliedFilters.dateFilter[1], 'DD/MM/YYYY')
              .endOf('day')
              .toDate() > moment().toDate()
            ? moment().format('YYYY-MM-DD HH:mm:ss')
            : moment(action.payload.appliedFilters.dateFilter[1], 'DD/MM/YYYY')
                .endOf('day')
                .format('YYYY-MM-DD HH:mm:ss')
          : null,
      enabled:
        action.payload.appliedFilters && action.payload.appliedFilters.status
          ? getEnabledStatus(action.payload.appliedFilters.status)
          : null,
      confirmed:
        action.payload.appliedFilters && action.payload.appliedFilters.status
          ? getConfirmedStatus(action.payload.appliedFilters.status)
          : null,
      has_bitgo:
        action.payload.appliedFilters && action.payload.appliedFilters.has_bitgo
          ? action.payload.appliedFilters.has_bitgo
          : null,
      kyc_status:
        action.payload.appliedFilters && action.payload.appliedFilters.kyc_status
          ? action.payload.appliedFilters.kyc_status
          : null,
      email:
        action.payload.appliedFilters && action.payload.appliedFilters.searchInput
          ? action.payload.appliedFilters.searchInput
          : null,
    };
    const mapURL = params => {
      let newParams = {};
      Object.keys(params).forEach(key => {
        if (params[key] !== null) newParams = { ...newParams, [key]: params[key].toString() };
      });
      return new URLSearchParams(newParams).toString();
    };
    const referrals: any = yield api.getReferralsPaginated(
      GET_REFERRALS_PAGINATED_ENDPOINT(
        userId ? userId : '',
        action.payload.page,
        action.payload.perPage,
        mapURL(filter) && '&' + mapURL(filter),
      ),
    );

    const result: { [s: string]: Referral } = {};
    referrals.referrals &&
      referrals.referrals.map((referral: any) => {
        result[referral.referral_details.id] = {
          ...referral.referral_details,
          blockchain_tx_hash: referral.blockchain_tx_hash,
        };
      });

    // yield put(asyncGetUnclaimedSLC.request());
    yield put(asyncGetReferralsStats.success({ paginatedReferrals: referrals.referral_count }));
    yield put(setTablePage(action.payload.page));
    yield put(asyncGetReferralsPaginated.success(result));
  } catch (error) {
    yield put(asyncGetReferralsPaginated.failure(error));
  }
}

export function* getUnclaimedSLC$() {
  try {
    const userId = LocalStorage.get(USER_ID);
    const response: GetUnclaimedSLCResponse = yield api.getUnclaimedSLC(
      GET_UNCLAIMED_SLC_ENDPOINT(userId ? userId : ''),
    );

    yield put(asyncGetUnclaimedSLC.success(response));
  } catch (error) {
    yield put(asyncGetUnclaimedSLC.failure(error));
  }
}

export function* claimAllSLC$(action: ReturnType<typeof asyncClaimSLC.request>) {
  try {
    const userId = LocalStorage.get(USER_ID);

    yield Promise.all(
      action.payload.map(cycle =>
        api.claimSLC(CLAIM_SLC_ENDPOINT(userId ? userId : '', cycle.cycle)),
      ),
    );

    yield put(asyncGetReferralsPaginated.request({ page: 1, perPage: 10 }));
  } catch (error) {
    yield put(asyncClaimSLC.failure(error));
  }
}

export function* TradeSLC$(action: ReturnType<typeof asyncTradeSLC.request>) {
  try {
    const ownerAccountId = LocalStorage.get(USER_ID);

    yield api.tradeSLC({ ownerAccountId, payload: action.payload });
    yield put(asyncTradeSLC.success());
    yield put(setDialogStep(2));
  } catch (error) {
    yield put(asyncTradeSLC.failure(error));
  }
}

export function* ChangeUserStatus$(action: ReturnType<typeof asyncChangeUserStatus.request>) {
  try {
    const ownerAccountId = LocalStorage.get(USER_ID);

    yield api.changeUserStatus({ ownerAccountId, payload: action.payload });

    const userStatus = action.payload.enabled ? 'locked' : 'unlocked';

    yield put(setInfoDialogFields.success(`User successfully ${userStatus}`));
    yield put(setInfoDialogFields.request({ showDialogContent: false }));
    yield put(asyncChangeUserStatus.success());
  } catch (error) {
    const status = error.response.status || error.status;
    let errorMessage = 'Something went wrong';

    if (status === 404) {
      errorMessage = 'Request failed! User not found.';
    }
    if (status === 409 || status === 406) {
      errorMessage = `Request failed! ${error.response.data.message}`;
    }

    yield put(setInfoDialogFields.failure(errorMessage));
    yield put(setInfoDialogFields.request({ showDialogContent: false }));
    yield put(asyncChangeUserStatus.failure(error));
  }
}

export function* userHasAffiliatePackageCheck$(
  action: ReturnType<typeof asyncUserHasAffiliatePackageCheck.request>,
) {
  try {
    const adminId = LocalStorage.get(USER_ID);
    const response = yield api.getCheckIfUserHasAffiliate(adminId ? adminId : '', action.payload);

    const {
      allowed_amount_to_sell_in_eurocents,
      allowed_amount_to_sell_in_slc,
      total_amount_to_sell_in_eurocents,
      total_amount_to_sell_in_slc,
    } = response.data;

    const updatedData: SellSlcInfoResponse = {
      ...response.data,
      total_amount_to_sell_in_eurocents: Number(total_amount_to_sell_in_eurocents) / 100,
      total_amount_to_sell_in_slc: Number(total_amount_to_sell_in_slc) / 100,
      allowed_amount_to_sell_in_eurocents: Number(allowed_amount_to_sell_in_eurocents) / 100,
      allowed_amount_to_sell_in_slc: Number(allowed_amount_to_sell_in_slc) / 100,
    };
    yield put(asyncUserHasAffiliatePackageCheck.success(updatedData));
  } catch (error) {
    const status = error.response.status || error.status;
    let errorMessage = 'Something went wrong';

    if (status === 406) {
      errorMessage = 'Affiliate package that allows selling is not found for this account.';
    }

    yield put(asyncUserHasAffiliatePackageCheck.failure(errorMessage));
  }
}

export function* ResetUserKYCStatus$(action: ReturnType<typeof asyncResetUserKYCStatus.request>) {
  try {
    const ownerAccountId = LocalStorage.get(USER_ID);

    yield api.resetUserKYCStatus({ ownerAccountId, userId: action.payload });

    yield put(setInfoDialogFields.success(`User KYC status was successfully reset`));
    yield put(setInfoDialogFields.request({ showDialogContent: false }));
    yield put(asyncChangeUserStatus.success());
  } catch (error) {
    const status = error.response.status;
    let errorMessage = 'Something went wrong';

    if (status === 404) {
      errorMessage = 'Request failed! User not found.';
    }
    if (status === 409) {
      errorMessage = `Request failed! ${error.response.data.message}`;
    }
    yield put(setInfoDialogFields.failure(errorMessage));
    yield put(setInfoDialogFields.request({ showDialogContent: false }));
    yield put(asyncChangeUserStatus.failure(error));
  }
}

export function* ToggleAdminRole$(action: ReturnType<typeof asyncToggleAdminRole.request>) {
  try {
    const ownerAccountId = LocalStorage.get(USER_ID);
    yield api.toggleAdminRole({ ownerAccountId, payload: action.payload });
    yield put(asyncToggleAdminRole.success());
  } catch (error) {
    yield put(asyncToggleAdminRole.failure(error));
  }
}

export function* getBalanceFromAffiliateUser$(
  action: ReturnType<typeof asyncGetBalanceFromAffiliateUser.request>,
) {
  try {
    const ownerAccountId = LocalStorage.get(USER_ID);

    const response = yield api.getBalanceFromAffiliateUser({
      ownerAccountId,
      payload: {
        user_id: action.payload.user_id,
        balanceSymbol: action.payload.balanceSymbol,
      },
    });

    yield put(asyncGetBalanceFromAffiliateUser.success(response.wallet_balance));
  } catch (error) {
    yield put(asyncGetBalanceFromAffiliateUser.failure(error));
  }
}

export function* getAffiliateFullProfileInfo$(
  action: ReturnType<typeof asyncPrepareFullProfileForAffiliate.request>,
) {
  try {
    const ownerAccountId = LocalStorage.get(USER_ID);
    const response = yield api.getFullAffiliateProfile({
      ownerAccountId,
      affiliateId: action.payload || '',
    });

    yield put(asyncPrepareFullProfileForAffiliate.success(response));
  } catch (error) {
    yield put(asyncPrepareFullProfileForAffiliate.failure(error));
  }
}

export function* refreshTable$() {
  try {
    const { appliedFilters, page, perPage } = yield select(affiliateTableStateSelector);

    yield put(
      asyncGetReferralsPaginated.request({
        page,
        perPage,
        appliedFilters,
      }),
    );
  } catch (error) {
    yield put(asyncGetReferralsPaginated.failure(error));
  }
}

export function* asyncSaveSlcChangeLimit$(
  action: ReturnType<typeof asyncSaveSlcChangeLimit.request>,
) {
  try {
    const adminId = LocalStorage.get(USER_ID);
    const { id, sell_limit_in_eurocents } = action.payload;

    yield api.saveChangeSlcLimit(adminId ? adminId : '', id, {
      sell_limit_in_eurocents: Number((sell_limit_in_eurocents * 100).toFixed()),
    });
    yield put(asyncSaveSlcChangeLimit.success());
  } catch (error) {
    yield put(asyncSaveSlcChangeLimit.failure(error));
  }
}

export default function* () {
  yield all([
    takeEvery(GET_REFERRALS_STATS, getReferralsStats$),
    takeEvery(GET_REFERRALS_PAGINATED, getReferralsPaginated$),
    takeLatest(GET_REFERRALS_TREE, getReferralTree$),
    takeEvery(GET_UNCLAIMED_SLC, getUnclaimedSLC$),
    takeLatest(CLAIM_SLC, claimAllSLC$),
    takeEvery(TRADE_SLC, TradeSLC$),
    takeEvery(PREPARE_FULL_PROFILE_FOR_AFFILIATE, getAffiliateFullProfileInfo$),
    takeEvery(CHANGE_USER_STATUS, ChangeUserStatus$),
    takeEvery(TOGGLE_ADMIN_ROLE, ToggleAdminRole$),
    takeEvery(REFERRAL_DETAIL, getReferralDetail$),
    takeEvery(GET_BALANCE_FROM_AFFILIATE_USER, getBalanceFromAffiliateUser$),
    takeEvery(RESET_USER_KYC_STATUS, ResetUserKYCStatus$),
    takeEvery(CHANGE_USER_STATUS_SUCCESS, refreshTable$),
    takeEvery(RESET_USER_KYC_STATUS_SUCCESS, refreshTable$),
    takeEvery(USER_HAS_AFFILIATE_PACKAGE_CHECK, userHasAffiliatePackageCheck$),
    takeEvery(SAVE_SLC_CHANGE_LIMIT, asyncSaveSlcChangeLimit$),
  ]);
}
