/* eslint-disable @typescript-eslint/indent */
import { saveAs } from 'file-saver';
import { all, put, select, takeEvery } from 'redux-saga/effects';
import LocalStorage from '../../../../../data/LocalStorage';
import api from '../../../../../data/repositories/transaction';
import {
  GetTransfersResponse,
  Transaction,
} from '../../../../../data/repositories/transaction/typedef';
import balanceUtils from '../../../../../utils/balanceUtils';
import {
  GET_TRANSFERS_ENDPOINT,
  GET_TRANSFERS_FOR_SYMBOL_ENDPOINT,
} from '../../../../../utils/constants/api';
import { USER_ID } from '../../../../../utils/constants/localStorageKeys';
import { getSelectedCoin } from '../../../state/common/selectors';
import { walletStateSelector } from '../../Wallet/state/selectors';
import {
  asyncGetTermSavingsDetails,
  GET_TERM_SAVINGS_DETAILS,
} from '../AffiliatePremiumTable/state/actions';
import walletTransfers from '../TransfersTable/state/saga';
import {
  asyncExportTransactions,
  asyncExportUsers,
  asyncGetSwapInfo,
  EXPORT_TRANSACTIONS,
  EXPORT_USERS,
  getTransfersFail,
  getTransfersForCoinFail,
  getTransfersForCoinSuccess,
  getTransfersSuccess,
  GET_SWAP_INFO,
  GET_TRANSFERS,
  GET_TRANSFERS_FOR_COIN,
  saveTransfers,
} from './actions';

export function* getTransfers$() {
  try {
    const userId = LocalStorage.get(USER_ID);
    const selectedCoin = yield select(getSelectedCoin);

    const response: GetTransfersResponse = yield api.getTransfers(
      GET_TRANSFERS_ENDPOINT(userId ? userId : '', selectedCoin, 1, 10),
    );
    const wallets = yield select(walletStateSelector);
    const result = response.reduce((accumulator, transfer) => {
      const wallet = wallets[transfer.transfers[0].symbol];
      const value = !!wallet
        ? balanceUtils.calculateValue(
            transfer.transfers[0].amount,
            wallet.decimals,
            wallet.price_eur || '0',
          )
        : '-';

      const newTransfer = { ...transfer.transfers[0], value };
      transfer.transfers[0] = newTransfer;
      return { ...accumulator, [transfer.id]: transfer };
    }, new Map<string, Transaction>());

    yield put(saveTransfers(result));
    yield put(getTransfersSuccess());
  } catch (error) {
    yield put(getTransfersFail(error));
  }
}

export function* getTransfersForSingleCoin$() {
  try {
    const userId = LocalStorage.get(USER_ID);
    const selectedCoin = yield select(getSelectedCoin);
    const response: GetTransfersResponse = yield api.getTransfers(
      GET_TRANSFERS_FOR_SYMBOL_ENDPOINT(userId ? userId : '', selectedCoin, 1, 10),
    );
    const wallets = yield select(walletStateSelector);
    const result = response.reduce((accumulator, transfer) => {
      const wallet = wallets[transfer.transfers[0].symbol];
      const value = !!wallet
        ? balanceUtils.calculateValue(
            transfer.transfers[0].amount,
            wallet.decimals,
            wallet.price_eur || '0',
          )
        : '-';

      const newTransfer = { ...transfer.transfers[0], value };
      transfer.transfers[0] = newTransfer;
      return { ...accumulator, [transfer.id]: transfer };
    }, new Map<string, Transaction>());

    yield put(getTransfersForCoinSuccess(result));
  } catch (error) {
    yield put(getTransfersForCoinFail(error));
  }
}

export function* exportUsers$() {
  try {
    const accountId = LocalStorage.get(USER_ID);
    const response = yield api.exportUsers(accountId || '');
    const csvData = new Blob([response], { type: 'text/csv;charset=utf-8;' });

    saveAs(csvData, 'Users.csv');

    yield put(asyncExportUsers.success());
  } catch (error) {
    yield put(asyncExportUsers.failure(error));
  }
}

export function* exportTransactions$() {
  try {
    const accountId = LocalStorage.get(USER_ID);
    const response = yield api.exportTransactions(accountId || '');
    const csvData = new Blob([response], { type: 'text/csv;charset=utf-8;' });

    saveAs(csvData, 'Transactions.csv');

    yield put(asyncExportTransactions.success());
  } catch (error) {
    yield put(asyncExportTransactions.failure(error));
  }
}

export function* getTermSavingsDetails$(action: ReturnType<any>) {
  try {
    const userId = LocalStorage.get(USER_ID);
    const response = yield api.getTermSavingsDetails(userId ? userId : '', action.payload);

    yield put(asyncGetTermSavingsDetails.success(response));
  } catch (error) {
    yield put(asyncGetTermSavingsDetails.failure(error));
  }
}

export function* getSwapInfo$() {
  try {
    const userId = LocalStorage.get(USER_ID);
    const response = yield api.getSwapInfo(userId ? userId : '');

    const { blockchain_tx_id, swap_rate, created_at, swap_pair } = response;
    const data = {
      swapTXHashId: blockchain_tx_id,
      swapDate: created_at,
      swapRate: swap_rate,
      swapPair: swap_pair,
    };
    yield put(asyncGetSwapInfo.success(data));
  } catch (error) {
    yield put(asyncGetSwapInfo.failure(error));
  }
}

export default function* () {
  yield all([
    takeEvery(GET_TRANSFERS, getTransfers$),
    takeEvery(GET_TRANSFERS_FOR_COIN, getTransfersForSingleCoin$),
    takeEvery(EXPORT_USERS, exportUsers$),
    takeEvery(EXPORT_TRANSACTIONS, exportTransactions$),
    takeEvery(GET_TERM_SAVINGS_DETAILS, getTermSavingsDetails$),
    takeEvery(GET_SWAP_INFO, getSwapInfo$),
    walletTransfers(),
  ]);
}
