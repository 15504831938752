import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { useDispatch } from 'react-redux';
import { ProductsPurchaseHistoryTableType } from '../../App/Home/HomeContent/Admin/ProductsPurchaseHistory/state/initialState';
import {
  GET_PRODUCTS_PURCHASE_HISTORY_PAGINATED,
  SET_PRODUCTS_PURCHASE_HISTORY_TABLE_PAGE,
} from '../../App/Home/HomeContent/Admin/state/actions';
import Satelite from '../../assets/satelite.svg';
import { HeaderProps } from '../../data/repositories/admin/typedefs';
import { Colors } from '../../ui/style/colors';
import SacretEmpty from '../SacretEmpty';
import TablePaginationActions from '../TablePaginationActions';
import ProductsPurchaseHistoryTableRow from './ProductsPurchaseHistoryTableRow';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      overflowY: 'auto',
      boxShadow: '0 10px 30px 0 rgba(132,141,182,0.25)',
      backgroundColor: `${Colors.white}`,
      borderRadius: '3px',
    },
    table: {
      minWidth: 650,
      borderCollapse: 'collapse',
      borderRadius: 6,
    },
    head: {
      backgroundColor: 'rgba(242, 243, 247, 0.7)',
    },
    headerText: {
      color: theme.palette.primary.light,
      fontSize: 12,
      fontWeight: 600,
      letterSpacing: 0.86,
    },
    pagination: { flexWrap: 'wrap', justifyContent: 'space-between', borderRadius: 5 },
    spacer: { flex: '0 0 100%' },
    sacretEmpty: {
      marginTop: 50,
    },
  }),
);

type Props = {
  productsPurchaseHistory: ProductsPurchaseHistoryTableType[];
  headers: HeaderProps[];
  page: number;
  inProgress: boolean;
  filter: any;
  totalCount: number;
};

const ProductsPurchaseHistoryTable: React.FC<Props> = ({
  filter,
  headers,
  inProgress,
  productsPurchaseHistory,
  page,
  totalCount,
}) => {
  const classes = useStyles();
  const dispath = useDispatch();

  const handleChangePage = (event: unknown, newPage: number) => {
    const object: any = {};
    filter.forEach((value: any, key: React.ReactText) => {
      object[key] = value;
    });

    dispath({ type: SET_PRODUCTS_PURCHASE_HISTORY_TABLE_PAGE, payload: newPage });
    dispath({
      type: GET_PRODUCTS_PURCHASE_HISTORY_PAGINATED,
      payload: {
        page: newPage,
        perPage: 10,
        appliedFilters: {
          ...object,
        },
      },
    });
  };

  const rowsPerPage = 10;
  const emptyRows = rowsPerPage - productsPurchaseHistory.length;

  if (!inProgress && totalCount === 0) {
    return (
      <SacretEmpty
        image={Satelite}
        title="Yikes, no product purchase history found!"
        className={classes.sacretEmpty}
      />
    );
  }

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead className={classes.head}>
          <TableRow>
            {headers.map(header => (
              <TableCell
                className={classes.headerText}
                key={header.name}
                align={header.align}
                component={'th'}
              >
                {header.name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {inProgress || !productsPurchaseHistory.length ? (
          <TableBody style={{ minHeight: 530 }}>
            <TableRow>
              <TableCell colSpan={7} style={{ textAlign: 'center' }}>
                <CircularProgress color="secondary" size={60} />
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <TableBody style={{ minHeight: 530, borderTop: '1px solid black' }}>
            {productsPurchaseHistory.map(
              (productPurchaseHistory: ProductsPurchaseHistoryTableType, index: number) => (
                <ProductsPurchaseHistoryTableRow
                  key={index}
                  productPurchaseHistory={productPurchaseHistory}
                />
              ),
            )}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        )}
      </Table>

      <TablePagination
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[]}
        page={page}
        labelDisplayedRows={({ from, to, count }: { from: number; to: number; count: number }) => {
          return `Showing ${from} to ${to} of ${count}`;
        }}
        backIconButtonProps={{
          'aria-label': 'previous page',
        }}
        nextIconButtonProps={{
          'aria-label': 'next page',
        }}
        onPageChange={handleChangePage}
        ActionsComponent={TablePaginationActions}
        classes={{
          toolbar: classes.pagination,
          spacer: classes.spacer,
        }}
      />
    </Paper>
  );
};

export default ProductsPurchaseHistoryTable;
