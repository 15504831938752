import React from 'react';
import InvestCurrencySuccess from './InvestCurrencySuccess';

const InvestCurrencySuccessStep = () => (
  <InvestCurrencySuccess
    initValues={{ step: 3 }}
    submitText="Go to transactions"
    backBtnText="Invest Again"
    sellBtnText="Invest Again"
  />
);

export default InvestCurrencySuccessStep;
