/* eslint-disable */
import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import {
  Cycle,
  GetReferralsPaginatedRequest,
  GetUnclaimedSLCResponse,
  Referral,
} from '../../../../../../data/repositories/affiliate/typedefs';
import { changeOrderStatusRequest } from '../../../../../../data/repositories/orders/typedef';
import { SaveTransactionFilter } from '../../../../../../data/repositories/transaction/typedef';
import { MerchantsReferralStats } from './initialState';
// import {
//   CHANGE_USER_STATUS,
//   CHANGE_USER_STATUS_FAIL,
//   CHANGE_USER_STATUS_SUCCESS
// } from "../../../Affiliate/AffiliateTable/state/actions";

export const GET_MERCHANTS_REFERRALS_STATS = 'orders/get_merchants_referrals_stats';
export const GET_MERCHANTS_REFERRALS_STATS_SUCCESS = 'orders/get_merchants_referrals_stats_success';
export const GET_MERCHANTS_REFERRALS_STATS_FAIL = 'orders/get_merchants_referrals_stats_fail';

export const GET_MERCHANTS_REFERRALS_PAGINATED = 'orders/get_merchants_referrals_paginated';
export const GET_MERCHANTS_REFERRALS_PAGINATED_SUCCESS =
  'orders/get_merchants_referrals_paginated/success';
export const GET_MERCHANTS_REFERRALS_PAGINATED_FAILED =
  'orders/get_merchants_referrals_paginated/fail';

export const GET_MERCHANTS_UNCLAIMED_SLC = 'orders/get_merchants_unclaimed_slc';
export const GET_MERCHANTS_UNCLAIMED_SLC_SUCCESS = 'orders/get_merchants_unclaimed_slc/success';
export const GET_MERCHANTS_UNCLAIMED_SLC_FAIL = 'orders/get_merchants_unclaimed_slc/fail';

export const CLAIM_MERCHANTS_SLC = 'orders/claim_merchants_slc';
export const CLAIM_MERCHANTS_SLC_SUCCESS = 'orders/claim_merchants_slc/success';
export const CLAIM_MERCHANTS_SLC_FAIL = 'orders/claim_merchants_slc/fail';

export const SET_MERCHANTS_TABLE_PAGE = 'orders/set_merchants_table_page';

export const CHANGE_STATUS = 'orders/change_status';
export const CHANGE_STATUS_SUCCESS = 'orders/change_status/success';
export const CHANGE_STATUS_FAIL = 'orders/change_status/fail';

export const TOGGLE_FILTER = 'orders/toggle_filter';
export const CLEAR_ALL_FILTERS = 'orders/clear_all_filters';

export const TOGGLE_ORDER_TYPE = 'orders/toggle_order_type';

export const CLOSE_ERROR_MODAL = 'orders/close_error_modal'

export const asyncGetMerchantsReferralsStats = createAsyncAction(
  GET_MERCHANTS_REFERRALS_STATS,
  GET_MERCHANTS_REFERRALS_STATS_SUCCESS,
  GET_MERCHANTS_REFERRALS_STATS_FAIL,
)<undefined, MerchantsReferralStats, Error>();

export const asyncGetMerchantsReferralsPaginated = createAsyncAction(
  GET_MERCHANTS_REFERRALS_PAGINATED,
  GET_MERCHANTS_REFERRALS_PAGINATED_SUCCESS,
  GET_MERCHANTS_REFERRALS_PAGINATED_FAILED,
)<GetReferralsPaginatedRequest, { [id: string]: Referral }, Error>();

export const asyncGetMerchantsUnclaimedSLC = createAsyncAction(
  GET_MERCHANTS_UNCLAIMED_SLC,
  GET_MERCHANTS_UNCLAIMED_SLC_SUCCESS,
  GET_MERCHANTS_UNCLAIMED_SLC_FAIL,
)<undefined, GetUnclaimedSLCResponse, Error>();

export const asyncClaimMerchantsSLC = createAsyncAction(
  CLAIM_MERCHANTS_SLC,
  CLAIM_MERCHANTS_SLC_SUCCESS,
  CLAIM_MERCHANTS_SLC_FAIL,
)<Cycle[], undefined, Error>();

export const setMerchantsTablePage = createStandardAction(SET_MERCHANTS_TABLE_PAGE)<number>();

export const asyncChangeStatus = createAsyncAction(
  CHANGE_STATUS,
  CHANGE_STATUS_SUCCESS,
  CHANGE_STATUS_FAIL,
)<changeOrderStatusRequest, undefined, Error>();

export const toggleFilter = createStandardAction(TOGGLE_FILTER)<SaveTransactionFilter>();

export const clearAllFilters = createStandardAction(CLEAR_ALL_FILTERS)<undefined>();

export const toggleOrderType = createStandardAction(TOGGLE_ORDER_TYPE)<string>();
