import { createStyles, InputLabel, makeStyles } from '@material-ui/core';
import { Field, FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import Timer from 'react-compound-timer';
import { useDispatch, useSelector } from 'react-redux';
import InvestInput from '../../../../../../components/InvestInput';
import { WalletMetadata } from '../../../../../../data/repositories/wallet/typedefs';
import { Colors } from '../../../../../../ui/style/colors';
import balanceUtils from '../../../../../../utils/balanceUtils';
import {
  CRYPTO_TRANSACTION_FEE,
  WALLET_COINS,
  WIRE_EUR_DECIMALS,
  WIRE_EUR_TRANSACTION_FEE,
} from '../../../../../../utils/constants/amounts';
import { getCurrencyFraction } from '../../../../../../utils/helper/numbers';
import { getCurrencySymbolName } from '../../../../../../utils/helper/utils';
import { walletBalance } from '../../../../../../utils/walletBalanceUtils';
import { AppState } from '../../../../../state/initialState';
import { expensiveGetWalletWithMetadataBySymbol } from '../../../Wallet/state/selectors';
import { ProductCurrencyRequestState } from '../state/initialState';
import useInvestCurrencyPrices from '../useInvestCurrencyPrices';
import useInvestCurrencyState from '../useInvestCurrencyState';
import withInvestCurrencyStep from '../withInvestCurrencyStep';

const styles = createStyles({
  containerWithError: {
    marginBottom: 35,
  },
  inputLabel: {
    color: `${Colors.wildBlueYonder}`,
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: 1.5,
    marginBottom: 8,
  },
  sellInputLabel: {
    paddingTop: 39,
  },
  balance: {
    fontSize: 14,
    color: `${Colors.wildBlueYonder}`,
    fontWeight: 600,
    fontFamily: 'Manrope3',
  },
  addBalanceBtn: {
    fontSize: 12,
    color: `${Colors.mediumTurquoise}`,
    fontWeight: 500,
    marginLeft: 12,
    border: 'none',
    padding: 0,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  error: {
    color: `${Colors.red}`,
    fontSize: 14,
    marginTop: 10,
  },
  fee: {
    color: `${Colors.wildBlueYonder}`,
    fontWeight: 600,
    textAlign: 'center',
    marginTop: 20,
    marginBottom: 17,
    lineHeight: 1.75,
    fontSize: 14,
  },
  feeForEur: {
    marginTop: 20,
    marginBottom: 49,
  },
  specialSymbol: {
    fontFamily: 'Lucida Grande',
    fontWeight: 600,
  },
  price: {
    fontSize: 28,
    color: `${Colors.blueCharcoal}`,
    fontWeight: 600,
    margin: 0,
    lineHeight: 1.35,
  },
  value: {
    color: `${Colors.blueCharcoal}`,
    width: '100%',
    textAlign: 'center',
    fontSize: 14,
  },
  desc: {
    color: `${Colors.nepal}`,
    marginBottom: 30,
  },
  input: {
    width: '100%',
    '&::placeholder': {
      color: `${Colors.blueHaze}`,
    },
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
    },
    '&[type=number]': {
      '-webkit-appearance': 'textfield',
      '-moz-appearance': 'textfield',
    },
  },
  strongText: {
    fontSize: 26,
    color: `${Colors.blueCharcoal}`,
    fontWeight: 600,
    margin: 0,
    marginBottom: 2,
    lineHeight: '40px',
    textAlign: 'center',
  },
  text: {
    fontSize: 16,
    color: `${Colors.wildBlueYonder}`,
    fontFamily: 'Manrope3',
    fontWeight: 600,
    margin: 0,
    textAlign: 'center',
    marginBottom: 4,
    '& span': {
      color: `${Colors.blueCharcoal}`,
      fontWeight: 600,
    },
  },
  inputWrapper: {
    height: 60,
  },
});
const useClasses = makeStyles(styles);

type Props = FormikProps<ProductCurrencyRequestState> & Record<string, never>;

const SomeComponent = (props: any) => {
  useEffect(() => {
    if (props.time) props.reset();
  }, [props.time]);
  return <span />;
};

const InvestCurrencyDetails: React.FC<Props> = ({
  errors,
  touched,
  values,
  setFieldValue,
}: Props) => {
  const classes = useClasses();
  const dispatch = useDispatch();
  const { investCurrency, walletState } = useInvestCurrencyState();
  const {
    selectedOption,
    amount,
    rerender,
    step,
    membershipState: { buy_limit_low },
  } = investCurrency;
  const { calculateValueFromEurToCrypto, formatBalanceToString } = balanceUtils;
  const { walletBalanceNum } = walletBalance({
    exchangeCurrency: investCurrency,
    walletState,
  });
  const { getSelectedCoinMarketVal } = useInvestCurrencyPrices();
  const slcMarketValue = getSelectedCoinMarketVal('SLC');
  const selectedOptionMarketValue = getSelectedCoinMarketVal(selectedOption);
  const buyLimitLowInEur = buy_limit_low / 100;
  const [exceededAmount, setExceededAmount] = useState(false);

  const selectedWallet: WalletMetadata = useSelector((state: AppState) =>
    expensiveGetWalletWithMetadataBySymbol(selectedOption)(state),
  );
  const { balance }: any = selectedWallet;

  const selectedWalletBalance =
    selectedWallet.balance !== 'string'
      ? balanceUtils.formatBalanceToString(
          selectedWallet.balance || '0',
          selectedWallet.decimals,
          getCurrencyFraction(selectedWallet.symbol, 'min'),
          getCurrencyFraction(selectedWallet.symbol, 'max'),
        )
      : 0;

  useEffect(() => {
    setExceededAmount(
      (Number(values.get) > selectedWalletBalance || Number(values.get) > Number(balance / 100)) &&
        selectedOption != 'WIRE_EUR',
    );

    if (errors.amount || errors.get) {
      setFieldValue('isBtnDisabled', true);
    } else if (Number(values.get) > selectedWalletBalance && selectedOption != 'WIRE_EUR') {
      setFieldValue('isBtnDisabled', true);
    } else if (selectedOption === 'WALLET_EUR' && Number(values.get) > Number(balance / 100)) {
      setFieldValue('isBtnDisabled', true);
    } else if (selectedOption === 'WALLET_EUR' && buyLimitLowInEur > Number(values.get)) {
      setFieldValue('isBtnDisabled', true);
    } else setFieldValue('isBtnDisabled', false);
  }, [errors, values.amount]);

  useEffect(() => {
    setFieldValue('amount', amount);
    populateField('get', amount);
  }, [amount]);

  useEffect(() => {
    setFieldValue('amount', amount);
    populateField('get', amount);
  }, [rerender]);

  useEffect(() => {
    setFieldValue('amount', '');
    populateField('get', '');
  }, [step]);

  const checkForError = () => {
    if (selectedOption === 'WIRE_EUR') {
      return investCurrency &&
        investCurrency.amount &&
        Number(investCurrency.amount) > 0 &&
        (touched.amount || touched.get)
        ? false
        : true;
    }

    return investCurrency &&
      investCurrency.amount &&
      Number(investCurrency.amount) > walletBalanceNum &&
      (touched.amount || touched.get)
      ? true
      : false;
  };

  type EventProps = React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

  const calcValueForEur = (name: string, value: any) => {
    if (name === 'amount') {
      return Number(value) / slcMarketValue;
    } else {
      return calculateValueFromEurToCrypto(
        investCurrency.membershipState.eur_price,
        Number(value) - WIRE_EUR_TRANSACTION_FEE,
      );
    }
  };

  const calcValueForWalletEur = (name: string, value: any) => {
    if (name === 'amount') {
      return Number(value) / slcMarketValue;
    } else {
      const getValue =
        (Number(value) * investCurrency.membershipState.eur_price) /
        Math.pow(10, WIRE_EUR_DECIMALS);

      const sellFee = (getValue * 2) / 100;
      setFieldValue('sellFee', sellFee);
      return +getValue + +sellFee;
    }
  };

  const calcValueForSlc = (name: string, value: any) => {
    if (name === 'amount') {
      return Number(value) / slcMarketValue;
    } else {
      return Number(value) * slcMarketValue;
    }
  };

  const calcValueForOther = (name: string, value: any) => {
    if (value === '') return '';
    if (name === 'get') {
      const calculatedValue =
        (Number(value) / selectedOptionMarketValue) *
        (investCurrency.membershipState.eur_price / 100);
      const getValue = Number(
        formatBalanceToString(calculatedValue || 0, 0, 2, 8)
          .replace(/,/g, '')
          .trim(),
      );

      if (WALLET_COINS.includes(getCurrencySymbolName(selectedOption))) {
        const sellFee = (getValue * 2) / 100;
        setFieldValue('sellFee', sellFee);
        return +getValue + +sellFee;
      } else {
        return (Number(value) * selectedOptionMarketValue) / slcMarketValue;
      }
    }
  };

  const populateField = (name: string, value: any) => {
    if (selectedOption === 'WIRE_EUR') {
      setFieldValue(name, value === '' ? '' : calcValueForEur(name, value));
    } else if (selectedOption === 'WALLET_EUR') {
      setFieldValue(name, value === '' ? '' : calcValueForWalletEur(name, value));
    } else if (selectedOption === 'SLC') {
      setFieldValue('sellFee', WIRE_EUR_TRANSACTION_FEE);
      setFieldValue(
        name,
        value === '' ? '' : Number(calcValueForSlc(name, value)) - WIRE_EUR_TRANSACTION_FEE,
      );
    } else {
      value === ''
        ? setFieldValue(name, value)
        : setFieldValue(name, Number(calcValueForOther(name, value)));
    }
  };

  const handleInputChange = (e: EventProps) => {
    const { name, value } = e.target;
    if (name === 'amount') {
      setFieldValue('amount', value);
      populateField('get', value);
    } else {
      setFieldValue('get', value);
      populateField('amount', value);
    }
  };

  return (
    <div style={{ paddingTop: 30 }} className={checkForError() ? classes.containerWithError : ''}>
      {selectedOption === 'WIRE_EUR' ? (
        <>
          <InputLabel className={classes.inputLabel} style={{ marginTop: 24 }}>
            To pay
          </InputLabel>
          <div className={classes.inputWrapper}>
            <Field
              label="Amount"
              id="outlined-amount-input"
              placeholder="0"
              name="amount"
              component={InvestInput}
              onChange={handleInputChange}
            />

            {touched.amount && errors.amount && (
              <div className={classes.error}>{errors.amount}</div>
            )}
          </div>
          <InputLabel className={classes.inputLabel} style={{ marginTop: 24 }}>
            Amount
          </InputLabel>
          <div className={classes.inputWrapper}>
            <Field
              id="outlined-get-input"
              placeholder="0"
              name="get"
              fee={values.sellFee}
              component={InvestInput}
              onChange={handleInputChange}
            />
            {errors.get && <div className={classes.error}>{errors.get}</div>}
          </div>
        </>
      ) : (
        <>
          <p className={classes.text}>This offer will expire in</p>
          <h2 className={classes.strongText}>
            <Timer
              initialTime={300000}
              direction="backward"
              lastUnit={'m'}
              formatValue={value => {
                return `${(value < 10 && '0') || ''}${value}`;
              }}
              checkpoints={[
                {
                  time: 0,
                  callback: () =>
                    dispatch({
                      type: 'invest/save_state',
                      payload: { step: 0, rerender: 1 },
                    }),
                },
              ]}
            >
              {({ reset }: any) => {
                return (
                  <React.Fragment>
                    <SomeComponent reset={reset} time={investCurrency.time} />
                    <Timer.Minutes /> : <Timer.Seconds />
                  </React.Fragment>
                );
              }}
            </Timer>
          </h2>
          <p className={classes.text} style={{ marginBottom: 20 }}>
            because of the fluctuating price of{' '}
            {selectedOption === 'WALLET_EUR' ? 'SLC' : selectedOption}
          </p>

          <InputLabel className={classes.inputLabel}>Amount calculator</InputLabel>
          <div className={classes.inputWrapper}>
            <Field
              label="Amount"
              id="outlined-amount-input"
              placeholder="0"
              name="amount"
              component={InvestInput}
              onChange={handleInputChange}
            />

            {((touched.amount && errors.amount) || exceededAmount) && (
              <div className={classes.error}>
                {exceededAmount ? 'You do not have enough funds for this order' : errors.amount}
              </div>
            )}
          </div>
          <InputLabel className={classes.inputLabel} style={{ marginTop: 24 }}>
            To pay
          </InputLabel>
          <div className={classes.inputWrapper}>
            <Field
              id="outlined-get-input"
              placeholder="0"
              name="get"
              fee={values.sellFee}
              component={InvestInput}
              onChange={handleInputChange}
            />
            {errors.get && <div className={classes.error}>{errors.get}</div>}
          </div>
        </>
      )}
      <div style={{ marginTop: 27, lineHeight: 1.5, textAlign: 'right' }}>
        <span className={classes.balance}>Transaction fee = </span>
        <span className={classes.balance} style={{ color: `${Colors.blueCharcoal}` }}>
          {selectedOption === 'WIRE_EUR'
            ? `${WIRE_EUR_TRANSACTION_FEE} EUR`
            : `${CRYPTO_TRANSACTION_FEE * 100}%`}
        </span>
      </div>
      <p style={{ marginTop: 20 }} />
    </div>
  );
};

export default withInvestCurrencyStep(InvestCurrencyDetails);
