import { connect } from 'react-redux';
import { AppState } from '../../../state/initialState';
import { setCurrentHomePage } from '../../state/common/actions';
import { getAllCoins, getWalletBalance } from '../Wallet/state/actions';
import { getAllCoinsSelector, getCurrenciesSymbolsSelector } from '../Wallet/state/selectors';
import {
  getTransfers,
  getTransferSetLoading,
  asyncExportUsers,
  asyncExportTransactions,
  clearAllFilters,
} from './state/actions';
import {
  filteredTransactionsIdsSelector,
  getTransactionsIdsSelector,
  getTransfersStateSelector,
} from './state/selectors';
import Transactions from './Transactions';
import { userStateSelector } from '../../../state/selectors';
import { membershipStateSelector } from '../Exchange/state/selectors';
import { hasCompletedKYCSelector } from '../Settings/state/selectors';

const mapDispatchToProps = {
  setCurrentHomePage: setCurrentHomePage,
  getAllCoins: getAllCoins,
  getTransfers: getTransfers,
  getWalletBalance: getWalletBalance,
  getTransferSetLoading: getTransferSetLoading,
  asyncExportUsers: asyncExportUsers.request,
  asyncExportTransactions: asyncExportTransactions.request,
  clearAllFilters,
};
const mapStateToProps = (state: AppState) => {
  const filteredTransactionsIds = filteredTransactionsIdsSelector(state);
  const transactionsIds = getTransactionsIdsSelector(state);
  const allCoins = getAllCoinsSelector(state);
  const walletsSymbols = getCurrenciesSymbolsSelector(state);
  const transactionsState = getTransfersStateSelector(state);
  const user = userStateSelector(state);
  const membershipLevel = membershipStateSelector(state);
  const hasCompletedKYC = hasCompletedKYCSelector(state);

  return {
    filteredTransactionsIds,
    allCoins,
    walletsSymbols,
    transactionsIds,
    transactionsState,
    user,
    membershipLevel,
    hasCompletedKYC,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Transactions);
