import { Grid, Hidden } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { PayloadAC } from 'typesafe-actions';
import sacretLifeLogo from '../../../../../../assets/coins/sacretLifeCoin.svg';
import crowdfunding from '../../../../../../assets/crowdfunding.png';
import CustomButtonPrimary from '../../../../../../components/CustomButtons/CustomButtonPrimary';
import ProductMainInfo from '../../../../../../components/ProductsTable/ProductMainInfo';
import { Product, ProductDetail } from '../../../../../../data/repositories/product/typedef';
import { User } from '../../../../../../data/typedefs/user';
import { roles } from '../../../../../../utils/constants/roles';
import { PRODUCTS_ROUTE } from '../../../../../../utils/constants/routes';
import { SET_INFO_DIALOG_FIELDS } from '../../../InfoDialog/state/actions';
import { SET_INVEST_INITIAL, SET_INVEST_OPEN } from '../../ProductInvest/state/actions';
import { ProductTableState } from '../../ProductsTable/state/initialState';

type StyleProps = {
  showViewDetailsButton: boolean;
};

const styles = () =>
  createStyles({
    wrapper: {
      marginBottom: 16,
    },
    root: {
      cursor: 'pointer',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    media: {
      height: 160,
    },
    avatar: {
      height: 30,
    },
    fallbackImage: {
      width: '100%',
      objectFit: 'contain',
    },
    cardActionsRoot: {
      justifyContent: 'center',
      padding: 0,
      paddingBottom: 16,
      '& button:not(:last-child)': {
        marginRight: 10,
      },
    },
    imageOverlay: {
      width: '100%',
      height: '100%',
      background: 'white',
      opacity: ({ showViewDetailsButton }: StyleProps) => (showViewDetailsButton ? 0.5 : 0),
      position: 'absolute',
      transition: 'all 1s',
    },
    viewMoreButton: {
      opacity: ({ showViewDetailsButton }: StyleProps) => (showViewDetailsButton ? 1 : 0),
      position: 'absolute',
      transition: 'all 1s',
    },
    imageWrapper: {
      position: 'relative',
    },
    productName: {
      fontSize: 14,
    },
    cardContentRoot: {
      '&:last-child': {
        paddingBottom: 16,
      },
    },
  });

const useStyles = makeStyles(styles);

type Props = ProductTableState & {
  setInvestDialogOpen: PayloadAC<typeof SET_INVEST_OPEN, boolean>;
  setInitialInvestValues: PayloadAC<typeof SET_INVEST_INITIAL, any>;
  setInfoDialogFields: PayloadAC<typeof SET_INFO_DIALOG_FIELDS, any>;
  user: User;
  product: Product;
};

const ProductCard: React.FC<Props> = (props: Props): JSX.Element => {
  const [showViewDetailsButton, setShowViewDetailsButton] = useState(false);
  const classes = useStyles({ showViewDetailsButton });
  const history = useHistory();
  const { product, setInitialInvestValues, setInvestDialogOpen, setInfoDialogFields, user } = props;

  const { icon, photo, name, status, id, buy_limit_low, buy_limit_high, pre_ico_price, ico_price } =
    product.product;

  const getStatus = (status: ProductDetail['status']) => {
    switch (status) {
      case 'created':
        return 'Created';
      case 'published':
        return 'Published';
      default:
        return status;
    }
  };

  const handleImageError = (e: React.ChangeEvent<HTMLImageElement>) => {
    e.target.onerror = null;
    const fallbackImage = e.target.id.includes('avatar') ? sacretLifeLogo : crowdfunding;
    e.target.src = fallbackImage;
  };

  const handleViewMoreButtonClick = () => {
    setInfoDialogFields({
      dialogProps: {
        selectedProduct: product,
      },
      dialogType: 'ProductDetailsDialog',
      title: 'Product details',
    });
  };

  const handleBuyButtonClick = () => {
    setInvestDialogOpen(true);
    setInitialInvestValues({
      product_id: id,
      buy_limit_low,
      buy_limit_high,
      eur_price: product.product_phase === 'pre-ico' ? pre_ico_price : ico_price,
    });
  };

  const handleEditButtonClick = () => history.push(`${PRODUCTS_ROUTE}/${id}`);

  return (
    <Grid item xs={12} md={6} lg={3} className={classes.wrapper}>
      <Card
        className={classes.root}
        onMouseEnter={() => setShowViewDetailsButton(true)}
        onMouseLeave={() => setShowViewDetailsButton(false)}
      >
        <Grid item>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            className={classes.imageWrapper}
          >
            <div className={classes.imageOverlay}></div>
            <CustomButtonPrimary
              size={'small'}
              onClick={handleViewMoreButtonClick}
              variant="contained"
              className={classes.viewMoreButton}
            >
              View more
            </CustomButtonPrimary>
            <CardMedia
              component="img"
              className={classes.media}
              image={photo.String ? photo.String : crowdfunding}
              title="Product icon"
              onError={handleImageError}
            />
          </Grid>

          <CardContent classes={{ root: classes.cardContentRoot }}>
            <Typography className={classes.productName}>{name}</Typography>
            <Grid container justifyContent="space-between">
              <img
                id={`avatar_${name}`}
                src={icon.String}
                onError={handleImageError}
                alt="Logo"
                className={classes.avatar}
              />
              {roles.isOwner(user.roles) && <Typography>{getStatus(status)}</Typography>}
            </Grid>

            <ProductMainInfo product={product} />
          </CardContent>
        </Grid>

        <Grid item>
          <CardActions classes={{ root: classes.cardActionsRoot }}>
            {status === 'published' &&
              (product.product_phase === 'pre-ico' || product.product_phase === 'ico') && (
                <CustomButtonPrimary
                  size={'small'}
                  onClick={handleBuyButtonClick}
                  variant="outlined"
                >
                  Buy
                </CustomButtonPrimary>
              )}
            <Hidden mdUp>
              <CustomButtonPrimary
                size={'small'}
                onClick={handleViewMoreButtonClick}
                variant="contained"
              >
                View more
              </CustomButtonPrimary>
            </Hidden>
            {roles.isOwner(user.roles) && (
              <CustomButtonPrimary
                size={'small'}
                onClick={handleEditButtonClick}
                variant="outlined"
              >
                Edit
              </CustomButtonPrimary>
            )}
          </CardActions>
        </Grid>
      </Card>
    </Grid>
  );
};

export default ProductCard;
