import { all } from 'redux-saga/effects';
import getAffiliateCode from '../AffiliateInfo/state/saga';
import getReferralsTree from '../AffiliateTable/state/saga';
import getMerchantsReferralsTree from '../AffiliateMerchantsTable/state/saga';
import getPremiumReferralsTree from '../AffiliatePremiumTable/state/saga';

export default function* () {
  yield all([
    getAffiliateCode(),
    getReferralsTree(),
    getMerchantsReferralsTree(),
    getPremiumReferralsTree(),
  ]);
}
