import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import VerificationCodeInput from '../../../../../../components/VerificationCodeInput';
import withInvestCurrencyStep from '../withInvestCurrencyStep';
const styles = (theme: Theme) =>
  createStyles({
    dialog: {
      height: 'center',
      width: 'center',
      borderRadius: 6,
      backgroundColor: 'transparent',
      boxShadow: '0 10px 30px 0 rgba(132,141,182,0.25)',
      paddingLeft: 30,
      paddingRight: 30,
    },
    infoLabel: {
      color: theme.palette.primary.light,
      fontSize: 12,
      fontWeight: 600,
      marginLeft: 10,
    },
    circularProgress: {
      marginTop: 30,
      marginBottom: 30,
      justifyContent: 'center',
      display: 'flex',
    },
  });
const useStyles = makeStyles(styles);
const Invest2fa = ({ is2FaEnabled, is2FaInProgress }: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  return (
    <>
      <Typography className={classes.infoLabel} component="p" style={{ marginBottom: 24 }}>
        Please, enter your two-factor authentication code once more
      </Typography>
      {is2FaInProgress ? (
        <Grid container className={classes.circularProgress} alignContent="center">
          <CircularProgress color="secondary" size={60} />
        </Grid>
      ) : is2FaEnabled ? (
        <div>
          <VerificationCodeInput
            onComplete={(two_factor: any) =>
              dispatch({
                type: 'invest/save_state',
                payload: { step: 3, two_factor },
              })
            }
          />
        </div>
      ) : (
        <Typography className={classes.infoLabel} component="p" style={{ marginBottom: 24 }}>
          First enable 2FA authentication{' '}
          <Link
            to={{
              pathname: '/settings',
              search: '?auth=true',
            }}
          >
            2FA
          </Link>
        </Typography>
      )}
    </>
  );
};

export default withInvestCurrencyStep(Invest2fa);
