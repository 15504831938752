import React from 'react';
import { EmptyAC } from 'typesafe-actions';
import logo from '../../../assets/dashboard/logo.svg';
import vectorHeader from '../../../assets/dashboard/vector-header.svg';
import { LOGIN_ENDPOINT } from '../../../utils/constants/api';
import { DASHBOARD_ROUTE, IAM_REGISTER_ROUTE } from '../../../utils/constants/routes';
import { toggleMenu } from '../js/scripts.js';
type Props = {
  login: EmptyAC<'login'>;
  logout: EmptyAC<'logout'>;
};
const DashboardHeader = ({ login }: Props) => {
  // const path = `${process.env.REACT_APP_IAM_ROUTE}${LOGIN_ENDPOINT}?response_type=code&client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${window.location.origin}&scope=any`;
  const path = `${process.env.REACT_APP_IDENTITY_API_URL}${LOGIN_ENDPOINT}?response_type=code&client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${window.location.origin}&scope=any`;

  const APP_DASHBOARD = process.env.REACT_APP_HOST;

  const getReferralCode = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get('referral_code') || urlParams.get('ref'); 
  };

  const referralCode = getReferralCode();

  return (
    <header className="o-header">
      <div className="o-header__bg">
        <img src={vectorHeader} alt="" />
      </div>
      <div className="o-header__wrap">
        <div className="container">
          <div className="row">
            <div className="col-12 d-flex align-items-center justify-content-start">
              <a href={APP_DASHBOARD} className="o-header__logo-img">
                <img src={logo} alt="logo" />
              </a>
              <nav className="o-nav">
                <ul className="o-nav__list d-lg-flex align-items-center justify-content-end">
                  <li className="o-nav__item o-nav__item--subnav">
                    <a href="#" className="o-nav__link">
                      Our Projects
                    </a>
                    <div className="o-nav__subnav-wrap">
                      <ul className="o-nav__subnav">
                        <li className="o-nav__subnav-item">
                          <a
                            href={process.env.REACT_APP_SACRET_LIFE_LINK}
                            className="o-nav__subnav-link"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Sleo
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className="o-nav__item">
                    <a
                      href={`${APP_DASHBOARD}${DASHBOARD_ROUTE}#aboutus`}
                      className="o-nav__link active"
                    >
                      About us
                    </a>
                  </li>
                  <li className="o-nav__item">
                    <a
                      href={`${process.env.REACT_APP_GRAFANA_LOGIN}`}
                      className="o-nav__link active"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Community Node Verification
                    </a>
                  </li>
                  <li className="o-nav__item o-nav__item--log d-flex align-items-center">
                    <a className="c-btn c-btn--outline" href={path} onClick={() => login()}>
                      <svg
                        className="c-btn__icon"
                        width="16"
                        height="22"
                        viewBox="0 0 16 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M7.84455 20.2619C4.15273 20.2619 1 19.6874 1 17.3866C1 15.0859 4.13273 12.9619 7.84455 12.9619C11.5364 12.9619 14.6891 15.0653 14.6891 17.3661C14.6891 19.6659 11.5564 20.2619 7.84455 20.2619Z"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M7.83652 9.77364C10.2592 9.77364 12.2229 7.81 12.2229 5.38727C12.2229 2.96455 10.2592 1 7.83652 1C5.41379 1 3.44924 2.96455 3.44924 5.38727C3.44106 7.80182 5.39106 9.76546 7.80561 9.77364C7.81652 9.77364 7.82652 9.77364 7.83652 9.77364Z"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      Log in
                    </a>
                    <a
                      // href={`${IAM_REGISTER_ROUTE}/register?returnUrl=${window.location.origin}/autologin`}
                      href={`${IAM_REGISTER_ROUTE}/register?returnUrl=${window.location.origin}${referralCode ? `&referral_code=${referralCode}` : ''}`}
                      className="c-btn c-btn--outline"
                    >
                      <svg
                        className="c-btn__icon"
                        width="18"
                        height="21"
                        viewBox="0 0 18 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.2206 7.65335V5.50435C13.1896 2.98535 11.1216 0.969353 8.60361 1.00035C6.13661 1.03135 4.14161 3.01735 4.09961 5.48435V7.65335"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8.66016 12.4062V14.6272"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M8.66 7.07422C2.915 7.07422 1 8.64222 1 13.3452C1 18.0492 2.915 19.6172 8.66 19.6172C14.405 19.6172 16.321 18.0492 16.321 13.3452C16.321 8.64222 14.405 7.07422 8.66 7.07422Z"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      Sign up
                    </a> 
                  </li>
                </ul>
              </nav>
              <div className="o-header__signin d-flex d-lg-none align-items-center justify-content-end">
                <a
                  href={`${IAM_REGISTER_ROUTE}/register?returnUrl=${window.location.origin}${referralCode ? `&referral_code=${referralCode}` : ''}`}
                  className="c-btn"
                >
                  Register
                </a>
                <button onClick={toggleMenu} className="o-nav__collaps-btn d-lg-none">
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default DashboardHeader;
