import CookieConsent from 'react-cookie-consent';
import background from '../../assets/dashboard/cookie-bg.png';
import React from 'react';
import { Colors } from '../../ui/style/colors';
import { createStyles, Grid, makeStyles } from '@material-ui/core';
import { PRIVACY_POLICY_ROUTE } from '../../utils/constants/routes';

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      color: Colors.mineShaft,
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 600,
      marginBottom: 4,
    },
    text: {
      color: `${Colors.muledVine}`,
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 300,
      marginBottom: 4,
    },
    button: {
      background: Colors.white,
      color: Colors.mineShaft,
      fontSize: '13px',
      lineHeight: '16px',
      borderRadius: '8px',
      height: '36px',
      border: 'none',
      width: '120px',
      margin: 0,
      '@media (max-width:460px)': {
        margin: 15,
      },
    },
    containerWrapper: {
      padding: ' 0 120px',
      alignItems: 'center!important',
      '@media (max-width:460px)': {
        flexDirection: 'column',
        padding: 30,
      },
      '@media (max-width:960px)': {
        padding: '20px 30px',
      },
    },
    contentWrapper: {
      '@media (max-width:460px)': {
        flexDirection: 'column',
      },
    },
    contentClass: {
      '@media (max-width:460px)': {
        flex: 'auto!important',
        margin: 0,
      },
    },
  }),
);

const CookieComponent = () => {
  const classes = useStyles();
  const APP_DASHBOARD = process.env.REACT_APP_HOST;
  return (
    <CookieConsent
      style={{ backgroundImage: `url(${background})` }}
      buttonText="Accept"
      containerClasses={classes.containerWrapper}
      buttonClasses={classes.button}
      contentClasses={classes.contentClass}
      buttonStyle={{
        background: Colors.white,
        color: Colors.mineShaft,
        borderRadius: '8px',
        margin: 0,
      }}
    >
      <Grid
        container
        className={classes.contentWrapper}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={'auto'} sm={8} md={10}>
          <p className={classes.title}>
            We use cookies and similar technologies to help personalize content and provide better
            experiences.
          </p>
          <p className={classes.text}>
            By clicking accept, you agree to this, as outlined in our Cookie and Privacy policy. For
            more information, click on learn more.
          </p>
        </Grid>
        <Grid item>
          <button
            onClick={() => (window.location.href = `${APP_DASHBOARD}${PRIVACY_POLICY_ROUTE}`)}
            className={classes.button}
          >
            Learn more
          </button>
        </Grid>{' '}
      </Grid>
    </CookieConsent>
  );
};

export default CookieComponent;
