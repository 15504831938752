import { connect } from 'react-redux';
import { AppState } from '../../../../state/initialState';
import ProductsPurchaseHistory from './ProductsPurchaseHistory';
import {
  asyncGetProductsPurchaseHistory,
  productsPurchaseHistoryClearAllFilters,
  asyncExportProductsPurchaseHistory,
} from './state/actions';
import { getAppliedFiltersSelector, productsPurchaseHistoryStateSelector } from './state/selectors';

const mapStateToProps = (state: AppState) => {
  const appliedFilters = getAppliedFiltersSelector(state);
  return { ...productsPurchaseHistoryStateSelector(state), appliedFilters };
};

const mapDispatchToProps = {
  productsPurchaseHistoryClearAllFilters,
  asyncGetProductsPurchaseHistory: asyncGetProductsPurchaseHistory.request,
  asyncExportProductsPurchaseHistory: asyncExportProductsPurchaseHistory.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsPurchaseHistory);
