import { connect } from 'react-redux';
import { AppState } from '../../../../state/initialState';
import Membership from './Membership';
import { getProfileInfo } from './state/actions';
import { profileInfoStateSelector } from './state/selectors';

const mapStateToProps = (state: AppState) => ({
  profileInfo: profileInfoStateSelector(state),
});

const mapDispatchToProps = {
  getProfileInfo: getProfileInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(Membership);
