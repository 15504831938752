import { Box, CircularProgress, Grid, Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { EmptyAC, PayloadAC } from 'typesafe-actions';
import EmptyWallet from '../../../../assets/wallet.svg';
import SacretEmpty from '../../../../components/SacretEmpty';
import TabPanel from '../../../../components/TabPanel';
import { Colors } from '../../../../ui/style/colors';
import { COINS_ORDER } from '../../../../utils/constants/amounts';
import { sortArrByPredefinedOrder } from '../../../../utils/helper/utils';
import BuySellModal from './BuySellModal/BuySellModal';
import { getEuroBalance } from '../../../../utils/walletBalanceUtils';
import KycPage from './KYCpage';
import { GET_WALLET_BALANCE, IS_WITHDRAW_DIALOG_OPEN } from './state/actions';
import { ComponentState } from './state/initialState';
import WalletCoin from './WalletCoin';
import UnavailableCoin from './WalletCoin/UnavailableCoin/UnavailableCoin';
import WithdrawDialog from './WalletCoin/WithdrawDialog';
import WalletDrawer from './WalletDrawer';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      overflowY: 'auto',
      width: '100%',
      height: '100%',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    progressRoot: {
      display: 'flex',
      height: '100vh',
      alignItems: 'center',
      justifyContent: 'center',
    },
    pageCircularProgress: {
      margin: 'auto',
    },
    walletPaper: {
      borderRadius: '3px',
      backgroundColor: `${Colors.white}`,
      boxShadow: '0 10px 30px 0 rgba(132,141,182,0.25)',
      overflow: 'hidden',
      margin: 24,
      minWidth: 240,
      [theme.breakpoints.down('sm')]: {
        margin: 0,
      },
    },

    tabPanel: {
      width: '100%',
      marginLeft: 12,
      overflowY: 'auto',
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',
      overflow: 'scroll',
      [theme.breakpoints.down('sm')]: {
        margin: 0,
      },
    },
    walletDrawer: {
      marginRight: 12,
      height: 'calc(100% - 48px)',
      margin: 24,
      minWidth: 300,
      [theme.breakpoints.down('sm')]: {
        margin: 0,
        height: 'fit-content',
      },
    },
    circularProgress: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%,-50%)',
    },
  });

const useStyles = makeStyles(styles);

type Props = {
  getWalletBalance: EmptyAC<typeof GET_WALLET_BALANCE>;
  walletsSymbols: string[];
  getAllCoins: EmptyAC<'wallet/get_all_coins'>;
  setCurrentHomePage: PayloadAC<'home/set_current_home_page', string>;
  componentState: ComponentState;
  wallets: any;
  location?: any;
  hasCompletedKYC: boolean;
};

const Wallet = ({
  getWalletBalance,
  walletsSymbols,
  getAllCoins,
  setCurrentHomePage,
  componentState,
  wallets,
  location,
  hasCompletedKYC,
}: Props): JSX.Element => {
  const { coinsTableInProgress } = componentState;

  const isEmpty: boolean = walletsSymbols.length == 0;
  const classes = useStyles();
  const allWallets = sortArrByPredefinedOrder(wallets, COINS_ORDER, 'symbol');
  const coin = location.state && location.state.coin;
  const selectedCoinIndex = allWallets.findIndex(
    wallet => wallet.symbol === coin || wallet.name === coin,
  );
  const [value, setValue] = useState(0);
  const dispatch = useDispatch();
  const handleChange = (event: React.ChangeEvent<Record<string, unknown>>, newValue: number) => {
    setValue(newValue);
    const coinInfoDiv: any = document.getElementById('scrollable');
    coinInfoDiv.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    getAllCoins();
    getWalletBalance();
    setCurrentHomePage('wallet');
    dispatch({ type: IS_WITHDRAW_DIALOG_OPEN, payload: false });
  }, []);

  useEffect(() => {
    if (allWallets.length > 1 && selectedCoinIndex !== value && coin) {
      setValue(selectedCoinIndex);
    }
  }, [coinsTableInProgress]);

  if (!hasCompletedKYC) {
    return <KycPage />;
  }
  if (isEmpty && !coinsTableInProgress) {
    return (
      <SacretEmpty
        image={EmptyWallet}
        title="Yikes, your wallet is empty!"
        text="Feel free to deposit the first assets to your wallet."
        hasButton={false}
      />
    );
  }
  const allCoins = allWallets.map(wallet => {
    return {
      symbol: wallet.symbol,
      icon: wallet.icon,
    };
  });

  const selectedWalletCoin = allWallets.find((wallet, index) => {
    if (index === value) {
      return {
        symbol: wallet.symbol,
        icon: wallet.icon,
      };
    }
  });
  return (
    <>
      {!allWallets[0].wallet_platform ? (
        <Box className={classes.circularProgress}>
          <CircularProgress color="secondary" size={60} />
        </Box>
      ) : (
        <>
          <WithdrawDialog />
          <BuySellModal allCoins={allCoins} selectedWalletCoin={selectedWalletCoin} />
          {coinsTableInProgress ? (
            <div className={classes.progressRoot}>
              <CircularProgress
                color="secondary"
                size={60}
                className={classes.pageCircularProgress}
              />
            </div>
          ) : (
            <Grid container className={classes.root} wrap="nowrap">
              <div className={`${classes.walletDrawer}`}>
                <WalletDrawer wallets={allWallets} handleChange={handleChange} value={value} />
              </div>
              <Paper id="scrollable" className={`${classes.walletPaper} ${classes.tabPanel}`}>
                {allWallets.map((wallet: any, index: any) => {
                  return (
                    <TabPanel key={index} value={value} index={index}>
                      {wallet.balance === 'string' ? (
                        <UnavailableCoin
                          icon={wallet.icon}
                          symbol={wallet.symbol}
                          euroBalance={getEuroBalance(wallets)}
                        />
                      ) : (
                        <WalletCoin wallet={wallet} />
                      )}
                    </TabPanel>
                  );
                })}
              </Paper>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default Wallet;
