import { createReducer } from 'typesafe-actions';
import {
  GET_USER_EARNINGS_TABLE_PAGINATED,
  GET_USER_EARNINGS_TABLE_PAGINATED_FAILED,
  GET_USER_EARNINGS_TABLE_PAGINATED_SUCCESS,
  GET_USER_EARNINGS_STATS,
  GET_USER_EARNINGS_STATS_FAIL,
  GET_USER_EARNINGS_STATS_SUCCESS,
  SET_USER_EARNINGS_TABLE_PAGE,
  TOGGLE_FILTER,
  CLEAR_ALL_FILTERS,
  EXPORT_USERS_EARNINGS,
  EXPORT_USERS_EARNINGS_SUCCESS,
  EXPORT_USERS_EARNINGS_FAIL,
} from './actions';
import { initialState } from './initialState';

export default createReducer(initialState, {
  [GET_USER_EARNINGS_STATS]: state => ({
    ...state,
    inProgress: true,
  }),
  [GET_USER_EARNINGS_STATS_SUCCESS]: (state, { payload }) => ({
    ...state,
    stats: payload,
    inProgress: false,
    fail: false,
  }),
  [GET_USER_EARNINGS_STATS_FAIL]: (state, { payload }) => ({
    ...state,
    inProgress: false,
    fail: true,
    error: payload,
  }),
  [GET_USER_EARNINGS_TABLE_PAGINATED]: state => ({
    ...state,
    inProgress: true,
    fail: false,
  }),
  [GET_USER_EARNINGS_TABLE_PAGINATED_SUCCESS]: (state, { payload }) => ({
    ...state,
    users: payload,
    inProgress: false,
    fail: false,
  }),
  [GET_USER_EARNINGS_TABLE_PAGINATED_FAILED]: (state, { payload }) => ({
    ...state,
    inProgress: false,
    fail: true,
    error: payload,
  }),
  [SET_USER_EARNINGS_TABLE_PAGE]: (state, { payload }) => ({
    ...state,
    page: payload,
  }),
  [TOGGLE_FILTER]: (state, { payload }) => {
    return {
      ...state,
      appliedFilters: { ...state.appliedFilters, [payload.filterType]: payload.value },
    };
  },
  [CLEAR_ALL_FILTERS]: state => ({
    ...state,
    appliedFilters: new Map(),
  }),
  [EXPORT_USERS_EARNINGS]: state => ({
    ...state,
    exportInProgress: true,
  }),
  [EXPORT_USERS_EARNINGS_SUCCESS]: state => ({
    ...state,
    exportInProgress: false,
  }),
  [EXPORT_USERS_EARNINGS_FAIL]: (state, { payload }) => ({
    ...state,
    exportInProgress: false,
    error: payload,
  }),
});
