// import { asyncGetPremiumReferralsStats } from '../../AffiliatePremiumTable/state/actions';
import moment from 'moment';
import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import LocalStorage from '../../../../../../data/LocalStorage';
import api from '../../../../../../data/repositories/affiliate';
import { GetUnclaimedSLCResponse } from '../../../../../../data/repositories/affiliate/typedefs';
import {
  CLAIM_MERCHANTS_SLC_ENDPOINT,
  GET_MERCHANTS_REFERRALS_STATS_ENDPOINT,
  GET_MERCHANTS_UNCLAIMED_SLC_ENDPOINT,
  GET_ORDERS_PAGINATED_ENDPOINT,
  REVOLUT_WIDTHDRAWAL,
} from '../../../../../../utils/constants/api';
import { USER_ID } from '../../../../../../utils/constants/localStorageKeys';
import {
  asyncChangeStatus,
  asyncClaimMerchantsSLC,
  asyncGetMerchantsReferralsPaginated,
  asyncGetMerchantsReferralsStats,
  asyncGetMerchantsUnclaimedSLC,
  CHANGE_STATUS,
  CLAIM_MERCHANTS_SLC,
  GET_MERCHANTS_REFERRALS_PAGINATED,
  GET_MERCHANTS_REFERRALS_STATS,
  GET_MERCHANTS_UNCLAIMED_SLC,
  setMerchantsTablePage,
} from './actions';
import { MerchantsReferralStats } from './initialState';
// import {asyncChangeUserStatus} from "../../../Affiliate/AffiliateTable/state/actions";

export function* changeStatus$(action: ReturnType<typeof asyncChangeStatus.request>) {
  try {
    const ownerAccountId = LocalStorage.get(USER_ID);
    yield api.changeOrderStatus({ ownerAccountId, payload: action.payload });

    yield put(asyncChangeStatus.success());

    action.payload.getOrders();
    // Reload page
    // window.location.reload(false);
  } catch (error) {
    yield put(asyncChangeStatus.failure(error));
  }
}

export function* getMerchantsReferralsStats$() {
  try {
    const userId = LocalStorage.get(USER_ID);
    const response = yield api.getReferralsStats(
      GET_MERCHANTS_REFERRALS_STATS_ENDPOINT(userId ? userId : ''),
    );
    const stats: MerchantsReferralStats = {
      totalReferrals: response.referral_count,
      directReferrals: response.direct_referral_count,
    };

    yield put(asyncGetMerchantsReferralsStats.success(stats));
  } catch (error) {
    yield put(asyncGetMerchantsReferralsStats.failure(error));
  }
}

export function* getMerchantsReferralsPaginated$(
  action: ReturnType<typeof asyncGetMerchantsReferralsPaginated.request>,
) {
  try {
    const userId = LocalStorage.get(USER_ID);

    const isHashNumberFilter = () => {
      const str =
        action.payload.order_type === 'revolutWithdrawal'
          ? action.payload.appliedFilters.paymentHash[0]
          : action.payload.appliedFilters.refOrHashNumber[0];
      return str.includes('-') || str.includes('x') || str.length > 8;
    };

    const filter = {
      payment_type:
        action.payload.appliedFilters && action.payload.appliedFilters.transactionType
          ? action.payload.appliedFilters.transactionType
          : null,
      date_from:
        action.payload.appliedFilters &&
        action.payload.appliedFilters.dateFilter &&
        action.payload.appliedFilters.dateFilter.length
          ? moment(action.payload.appliedFilters.dateFilter[0], 'DD/MM/YYYY').format(
              'YYYY-MM-DD HH:mm:ss',
            )
          : null,
      date_to:
        action.payload.appliedFilters &&
        action.payload.appliedFilters.dateFilter &&
        action.payload.appliedFilters.dateFilter.length
          ? moment(action.payload.appliedFilters.dateFilter[1], 'DD/MM/YYYY')
              .endOf('day')
              .toDate() > moment().toDate()
            ? moment().format('YYYY-MM-DD HH:mm:ss')
            : moment(action.payload.appliedFilters.dateFilter[1], 'DD/MM/YYYY')
                .endOf('day')
                .format('YYYY-MM-DD HH:mm:ss')
          : null,
      order_status:
        action.payload.appliedFilters && action.payload.appliedFilters.status
          ? action.payload.appliedFilters.status
          : null,
      email:
        action.payload.appliedFilters && action.payload.appliedFilters.searchInput
          ? action.payload.appliedFilters.searchInput
          : null,
      order_name:
        action.payload.appliedFilters && action.payload.appliedFilters.order_name
          ? action.payload.appliedFilters.order_name
          : null,
      reference_number:
        action.payload.appliedFilters &&
        action.payload.appliedFilters.refOrHashNumber &&
        !isHashNumberFilter()
          ? action.payload.appliedFilters.refOrHashNumber
          : null,
      order_proof:
        action.payload.appliedFilters &&
        action.payload.appliedFilters.refOrHashNumber &&
        isHashNumberFilter()
          ? action.payload.appliedFilters.refOrHashNumber
          : null,
      order_type: action.payload.order_type ? action.payload.order_type : null,

      status:
        action.payload.appliedFilters && action.payload.appliedFilters.withdrawStatus
          ? action.payload.appliedFilters.withdrawStatus
          : null,
      revolut_payment_id:
        action.payload.appliedFilters &&
        action.payload.appliedFilters.paymentHash &&
        isHashNumberFilter()
          ? action.payload.appliedFilters.paymentHash
          : null,
    };
    const mapURL = params => {
      let newParams = {};
      Object.keys(params).forEach(key => {
        if (params[key] !== null) newParams = { ...newParams, [key]: params[key].toString() };
      });
      return new URLSearchParams(newParams).toString();
    };

    const referrals: any =
      action.payload.order_type === 'revolutWithdrawal'
        ? yield api.getReferralsPaginated(
            REVOLUT_WIDTHDRAWAL(
              userId ? userId : '',
              action.payload.page,
              action.payload.perPage,
              mapURL(filter) && '&' + mapURL(filter),
            ),
          )
        : yield api.getReferralsPaginated(
            GET_ORDERS_PAGINATED_ENDPOINT(
              userId ? userId : '',
              action.payload.page,
              action.payload.perPage,
              mapURL(filter) && '&' + mapURL(filter),
            ),
          );

    const stats: MerchantsReferralStats = {
      totalReferrals: referrals.order_count || referrals.revolut_withdrawals_count,
      directReferrals: null,
    };

    yield put(asyncGetMerchantsReferralsStats.success(stats));

    const result: any = {};
    if (referrals.orders) {
      referrals.orders.map((referral: { id: string | number }) => {
        result[referral.id] = referral;
      });
      yield put(setMerchantsTablePage(action.payload.page));
    } else if (referrals.revolut_withdrawals) {
      referrals.revolut_withdrawals.map((referral: { id: string | number }) => {
        result[referral.id] = referral;
      });
      yield put(setMerchantsTablePage(action.payload.page));
    }

    // yield put(asyncGetMerchantsUnclaimedSLC.request());

    yield put(asyncGetMerchantsReferralsPaginated.success(result));
  } catch (error) {
    yield put(asyncGetMerchantsReferralsPaginated.failure(error));
  }
}

export function* getMerchantsUnclaimedSLC$() {
  try {
    const userId = LocalStorage.get(USER_ID);
    const response: GetUnclaimedSLCResponse = yield api.getUnclaimedSLC(
      GET_MERCHANTS_UNCLAIMED_SLC_ENDPOINT(userId ? userId : ''),
    );

    yield put(asyncGetMerchantsUnclaimedSLC.success(response));
  } catch (error) {
    yield put(asyncGetMerchantsUnclaimedSLC.failure(error));
  }
}

export function* claimAllMerchantsSLC$(action: ReturnType<typeof asyncClaimMerchantsSLC.request>) {
  try {
    const userId = LocalStorage.get(USER_ID);

    yield Promise.all(
      action.payload.map(cycle =>
        api.claimSLC(CLAIM_MERCHANTS_SLC_ENDPOINT(userId ? userId : '', cycle.cycle)),
      ),
    );

    yield put(asyncGetMerchantsReferralsPaginated.request({ page: 1, perPage: 10 }));
  } catch (error) {
    yield put(asyncClaimMerchantsSLC.failure(error));
  }
}

export default function* () {
  yield all([
    takeEvery(GET_MERCHANTS_REFERRALS_STATS, getMerchantsReferralsStats$),
    takeEvery(GET_MERCHANTS_REFERRALS_PAGINATED, getMerchantsReferralsPaginated$),
    takeEvery(GET_MERCHANTS_UNCLAIMED_SLC, getMerchantsUnclaimedSLC$),
    takeLatest(CLAIM_MERCHANTS_SLC, claimAllMerchantsSLC$),
    takeEvery(CHANGE_STATUS, changeStatus$),
  ]);
}
