import React from 'react';
import { ProductTableState } from './state/initialState';
import ProductsTable, { HeaderProps } from '../../../../../components/ProductsTable';

type Props = ProductTableState & {
  inProgress: boolean;
  appliedFilters: any;
  appliedTab: any;
};

const headers: HeaderProps[] = [
  { name: 'Name', align: 'left' },
  { name: 'SLC purchased', align: 'right' },
  { name: 'SLC purchased (EUR)', align: 'right' },
  { name: 'Current value of purchased SLC(EUR)', align: 'right' },
  { name: '', align: 'right' },
];

const ProductTable: React.FC<Props> = (props: Props): JSX.Element => {
  const { products, inProgress, totalProducts, page } = props;

  return (
    <ProductsTable
      products={products ? products : {}}
      headers={headers}
      totalProducts={totalProducts}
      page={page}
      inProgress={inProgress}
    />
  );
};

export default ProductTable;
