import { connect } from 'react-redux';
import { AppState } from '../../../../../App/state/initialState';
import { ALL_COINS } from '../../../../../utils/constants/amounts';
import { getCoinDecimalsSelector } from '../../Wallet/state/selectors';
import { isFullProfileDialogOpen, asyncPrepareFullProfileForAffiliate } from '../state/actions';
import { getDialogStateSelector } from '../state/selectors';
import AffiliateFullProfileDialog from './AffiliateFullProfileDialog';

const mapDispatchToProps = {
  isFullProfileDialogOpen,
  asyncPrepareFullProfileForAffiliate: asyncPrepareFullProfileForAffiliate.request,
};

const mapStateToProps = (state: AppState) => {
  const dialogState = getDialogStateSelector(state);
  const allCoinDecimals = ALL_COINS.reduce(function (acc: any, coin: string) {
    acc[coin] = getCoinDecimalsSelector(coin)(state);
    return acc;
  }, {});
  return { dialogState, allCoinDecimals };
};

export default connect(mapStateToProps, mapDispatchToProps)(AffiliateFullProfileDialog);
