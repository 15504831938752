import { CircularProgress, DialogContent, Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { EmptyAC, PayloadAC } from 'typesafe-actions';
import CopyToClipboard from '../../../../../../components/CopyToClipboard';
import CustomButtonPrimary from '../../../../../../components/CustomButtons/CustomButtonPrimary';
import LocalStorage from '../../../../../../data/LocalStorage';
import { EnableTwoFactorAuthResponse } from '../../../../../../data/repositories/account/typedefs';
import { Colors } from '../../../../../../ui/style/colors';
import { USER_EMAIL } from '../../../../../../utils/constants/localStorageKeys';

const styles = (theme: Theme) =>
  createStyles({
    dialog: {
      height: 'center',
      width: 'center',
      borderRadius: 6,
      backgroundColor: 'transparent',
      paddingLeft: 30,
      paddingRight: 30,
    },
    infoLabel: {
      color: theme.palette.primary.light,
      fontSize: 12,
      fontWeight: 600,
      marginBottom: 8,
      width: 400,

      '@media (max-width:64rem)': {
        width: '100%',
      },
    },
    button: {
      marginTop: 30,
      marginBottom: 30,
      textTransform: 'none',
    },
    qrCode: {
      border: `2px solid ${Colors.white}`,
      borderRadius: 6,
      backgroundColor: `${Colors.white}`,
      textAlign: 'center',
      paddingTop: 22,
      paddingBottom: 30,
      '@media (max-width:64rem)': {
        ['& img']: {
          width: '100%',
        },
      },
    },
    circularProgress: {
      marginTop: 30,
      marginBottom: 30,
      justifyContent: 'center',
      display: 'flex',
    },
    inputError: {
      color: `${Colors.red}`,
      marginTop: 10,
      fontSize: 14,
    },
  });
const useStyles = makeStyles(styles);

type Props = EnableTwoFactorAuthResponse & {
  enableTwoFactorAuth: EmptyAC<'settings/enable_two_factor_auth'>;
  openTwoFactorAuth: PayloadAC<'settings/open_dialog', number>;
  setDialogTitle: PayloadAC<'settings/set_dialog_title', string>;
  qrCode: string;
  step: number;
  inProgress: boolean;
  error: Error | null;
  fail: boolean;
  secret: string;
};

const SettingsTwoFactorAuthDialog: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const {
    qrCode,
    step,
    openTwoFactorAuth,
    inProgress,
    error,
    secret,
    enableTwoFactorAuth,
    setDialogTitle,
  } = props;
  const email = LocalStorage.get(USER_EMAIL);
  useEffect(() => {
    if (!qrCode) enableTwoFactorAuth();
  }, []);
  return (
    <DialogContent className={classes.dialog}>
      <Typography className={classes.infoLabel} component="p">
        Scan the QR code and click on CONTINUE button to enter the resulting code from your auth app
      </Typography>
      {inProgress ? (
        <Grid container className={classes.circularProgress} alignContent="center">
          <CircularProgress color="secondary" size={60} />
        </Grid>
      ) : (
        <div
          className={classes.qrCode}
          style={
            qrCode != null && email != null
              ? qrCode === '' || email === ''
                ? { visibility: 'hidden' }
                : {}
              : { visibility: 'hidden' }
          }
        >
          <img src={qrCode} />
        </div>
      )}
      <Typography className={classes.infoLabel} component="p">
        Your code
      </Typography>
      <CopyToClipboard code={secret} />
      {error != null ? <div className={classes.inputError}>{error}</div> : null}
      <CustomButtonPrimary
        variant="contained"
        size="large"
        className={classes.button}
        onClick={() => {
          openTwoFactorAuth(step + 1);
          setDialogTitle('Two-factor authentication(2FA)');
        }}
        fullWidth
        disabled={error ? true : false}
      >
        Continue
      </CustomButtonPrimary>
    </DialogContent>
  );
};

export default SettingsTwoFactorAuthDialog;
