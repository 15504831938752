import { CircularProgress, Paper, TablePagination } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  GET_USER_EARNINGS_TABLE_PAGINATED,
  SET_USER_EARNINGS_TABLE_PAGE,
} from '../../App/Home/HomeContent/Admin/state/actions';
import { getCoinDecimalsSelector } from '../../App/Home/HomeContent/Wallet/state/selectors';
import { AppState } from '../../App/state/initialState';
import Satelite from '../../assets/satelite.svg';
import {
  HeaderProps,
  UserDetails,
  UserEarningsTable,
} from '../../data/repositories/admin/typedefs';
import { Colors } from '../../ui/style/colors';
import balanceUtils from '../../utils/balanceUtils';
import {
  CRYPTO_MAX_FRACTION,
  CRYPTO_MIN_FRACTION,
  WIRE_EUR_DECIMALS,
  WIRE_EUR_MAX_FRACTION,
  WIRE_EUR_MIN_FRACTION,
} from '../../utils/constants/amounts';
import SacretEmpty from '../SacretEmpty';
import TablePaginationActions from '../TablePaginationActions';
import UsersEarningsTableRow from './UsersEarningsTableRow';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      overflowY: 'auto',
      boxShadow: '0 10px 30px 0 rgba(132,141,182,0.25)',
      backgroundColor: `${Colors.white}`,
      borderRadius: '3px',
    },
    table: {
      minWidth: 650,
      borderCollapse: 'collapse',
      borderRadius: 6,
    },
    head: {
      backgroundColor: 'rgba(242, 243, 247, 0.7)',
    },
    headerText: {
      color: theme.palette.primary.light,
      fontSize: 12,
      fontWeight: 600,
      letterSpacing: 0.86,
    },
    pagination: { flexWrap: 'wrap', justifyContent: 'space-between', borderRadius: 5 },
    spacer: { flex: '0 0 100%' },
    sacretEmpty: {
      marginTop: 50,
    },
  }),
);

type Props = {
  users: UserEarningsTable;
  headers: HeaderProps[];
  totalUsers: number;
  page: number;
  inProgress: boolean;
  filter: any;
};

const UsersEarningsTable = ({
  users: { usersEarningsInfo, totalAmount, totalEarned },
  headers,
  page,
  totalUsers,
  inProgress,
  filter,
}: Props) => {
  const classes = useStyles();
  const dispath = useDispatch();
  const slcDecimals: any = useSelector((state: AppState) => getCoinDecimalsSelector('SLC')(state));

  const handleChangePage = (event: unknown, newPage: number) => {
    const object: any = {};
    filter.forEach((value: any, key: React.ReactText) => {
      object[key] = value;
    });

    dispath({ type: SET_USER_EARNINGS_TABLE_PAGE, payload: newPage });
    dispath({
      type: GET_USER_EARNINGS_TABLE_PAGINATED,
      payload: {
        page: newPage,
        perPage: 10,
        appliedFilters: {
          ...object,
        },
      },
    });
  };

  const rowsPerPage = 10;
  const emptyRows = usersEarningsInfo && rowsPerPage - Object.keys(usersEarningsInfo).length;

  if (!inProgress && totalUsers === 0) {
    return (
      <SacretEmpty
        image={Satelite}
        title="Yikes, no earnings found!"
        className={classes.sacretEmpty}
      />
    );
  }

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead className={classes.head}>
          <TableRow>
            {headers.map(header => (
              <TableCell
                className={classes.headerText}
                key={header.name}
                align={header.align}
                component={'th'}
              >
                {header.name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {inProgress || !usersEarningsInfo ? (
          <TableBody style={{ minHeight: 530 }}>
            <TableRow>
              <TableCell colSpan={7} style={{ textAlign: 'center' }}>
                <CircularProgress color="secondary" size={60} />
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <TableBody style={{ minHeight: 530, borderTop: '1px solid black' }}>
            {usersEarningsInfo.map((user: UserDetails, key: string) => (
              <UsersEarningsTableRow key={`user-earnings-row-${key}`} user={user} />
            ))}
            <TableRow style={{ borderTop: '2px solid black' }}>
              <TableCell colSpan={5} />
              <TableCell colSpan={1} align="right">
                Total Amount <br />{' '}
                {balanceUtils.formatBalanceToString(
                  totalAmount || 0,
                  slcDecimals,
                  CRYPTO_MIN_FRACTION,
                  CRYPTO_MAX_FRACTION,
                )}{' '}
                SLC
              </TableCell>
              <TableCell colSpan={1} align="right">
                Total Earned <br />{' '}
                {balanceUtils.formatBalanceToString(
                  totalEarned,
                  WIRE_EUR_DECIMALS,
                  WIRE_EUR_MIN_FRACTION,
                  WIRE_EUR_MAX_FRACTION,
                )}{' '}
                EUR
              </TableCell>
            </TableRow>
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        )}
      </Table>

      <TablePagination
        component="div"
        count={totalUsers}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[]}
        page={page}
        labelDisplayedRows={({ from, to, count }: { from: number; to: number; count: number }) => {
          return `Showing ${from} to ${to} of ${count}`;
        }}
        backIconButtonProps={{
          'aria-label': 'previous page',
        }}
        nextIconButtonProps={{
          'aria-label': 'next page',
        }}
        onPageChange={handleChangePage}
        ActionsComponent={TablePaginationActions}
        classes={{
          toolbar: classes.pagination,
          spacer: classes.spacer,
        }}
      />
    </Paper>
  );
};

export default UsersEarningsTable;
