import moment from 'moment';
import { all, put, takeEvery } from 'redux-saga/effects';
import api from '../../../../../../data/repositories/invites';
import {
  GetFriendsInvitationsSuccessPayload,
  GetTotalFriendsInvitationsSuccessPayload,
} from '../../../../../../data/repositories/invites/typedef';
import {
  GET_ALL_FRIENDS_INVITATIONS,
  GET_ALL_FRIENDS_INVITATIONS_PAGINATED,
} from '../../../../../../utils/constants/api';
import {
  asyncGetFriendsInvitationsPaginated,
  asyncGetTotalFriendsInvitationsPaginated,
  GET_FRIENDS_INVITATIONS,
  GET_TOTAL_FRIENDS_INVITATIONS,
  setInvitesTablePage,
} from './actions';

const getFilters = (action: any) => ({
  status:
    action.payload.appliedFilters && action.payload.appliedFilters.status
      ? action.payload.appliedFilters.status
      : null,
  invite_email:
    action.payload.appliedFilters && action.payload.appliedFilters.searchInput
      ? action.payload.appliedFilters.searchInput
      : null,
  date_from:
    action.payload.appliedFilters &&
    action.payload.appliedFilters.dateFilter &&
    action.payload.appliedFilters.dateFilter.length
      ? moment(action.payload.appliedFilters.dateFilter[0], 'DD/MM/YYYY').format(
          'YYYY-MM-DD HH:mm:ss',
        )
      : null,
  date_to:
    action.payload.appliedFilters &&
    action.payload.appliedFilters.dateFilter &&
    action.payload.appliedFilters.dateFilter.length
      ? moment(action.payload.appliedFilters.dateFilter[1], 'DD/MM/YYYY').endOf('day').toDate() >
        moment().toDate()
        ? moment().format('YYYY-MM-DD HH:mm:ss')
        : moment(action.payload.appliedFilters.dateFilter[1], 'DD/MM/YYYY')
            .endOf('day')
            .format('YYYY-MM-DD HH:mm:ss')
      : null,
});

const mapURL = (params: any) => {
  let newParams = {};
  Object.keys(params).forEach(key => {
    if (params[key] !== null) newParams = { ...newParams, [key]: params[key].toString() };
  });
  return new URLSearchParams(newParams).toString();
};

export function* getFriendsInvitationsPaginated$(
  action: ReturnType<typeof asyncGetFriendsInvitationsPaginated.request>,
) {
  const { page, perPage } = action.payload;
  const filter = getFilters(action);

  try {
    const invites: GetFriendsInvitationsSuccessPayload = yield api.getAllFriendsInvitations(
      GET_ALL_FRIENDS_INVITATIONS_PAGINATED(
        action.payload.accountId,
        page,
        perPage,
        mapURL(filter) && '&' + mapURL(filter),
      ),
    );

    yield put(asyncGetFriendsInvitationsPaginated.success(invites));
    yield put(setInvitesTablePage(action.payload.page));
  } catch (error) {
    yield put(asyncGetFriendsInvitationsPaginated.failure(error));
  }
}

export function* getPendingFriendsInvitationsCount$(
  action: ReturnType<typeof asyncGetTotalFriendsInvitationsPaginated.request>,
) {
  try {
    const invited: GetTotalFriendsInvitationsSuccessPayload = yield api.getAllFriendsInvitations(
      GET_ALL_FRIENDS_INVITATIONS(action.payload.accountId),
    );
    const totalInvited = invited.count;
    const pending: GetTotalFriendsInvitationsSuccessPayload = yield api.getAllFriendsInvitations(
      GET_ALL_FRIENDS_INVITATIONS(action.payload.accountId, 'pending'),
    );
    const totalPending = pending.count;
    const accepted: GetTotalFriendsInvitationsSuccessPayload = yield api.getAllFriendsInvitations(
      GET_ALL_FRIENDS_INVITATIONS(action.payload.accountId, 'completed'),
    );
    const totalAccepted = accepted.count;

    const total = { totalPending, totalAccepted, totalInvited };

    yield put(asyncGetTotalFriendsInvitationsPaginated.success(total));
  } catch (error) {
    yield put(asyncGetTotalFriendsInvitationsPaginated.failure(error));
  }
}
export default function* () {
  yield all([
    takeEvery(GET_FRIENDS_INVITATIONS, getFriendsInvitationsPaginated$),
    takeEvery(GET_TOTAL_FRIENDS_INVITATIONS, getPendingFriendsInvitationsCount$),
  ]);
}
