import { WalletTransfer } from '../../../../../../data/repositories/transaction/typedef';

export type WalletTransfersTableState = {
  walletTransfers?: Map<string, WalletTransfer> | null;
  inProgress: boolean;
  fail?: boolean;
  error?: Error | null;
  page: number;
  perPage?: number;
  totalRows: number;
  totalSum: {
    sum_amount: number;
  } | null;
};

export const initialState: WalletTransfersTableState = {
  walletTransfers: new Map<string, WalletTransfer>(),
  inProgress: false,
  fail: false,
  error: null,
  page: 0,
  perPage: 10,
  totalRows: 0,
  totalSum: null,
};
