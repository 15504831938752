import { createReducer } from 'typesafe-actions';
import {
  GET_PRODUCTS_PAGINATED,
  GET_PRODUCTS_PAGINATED_FAILED,
  GET_PRODUCTS_PAGINATED_SUCCESS,
  CLEAR_ALL_FILTERS,
  TOGGLE_FILTER,
  TOGGLE_TAB,
  UPDATE_ROW,
  SET_TABLE_PAGE,
  EXPORT_PRODUCTS,
  EXPORT_PRODUCTS_SUCCESS,
  EXPORT_PRODUCTS_FAIL,
} from './actions';
import { GetProductsSuccessPayload } from '../../../../../../data/repositories/product/typedef';
import { initialState, ProductTableState } from './initialState';

export default createReducer(initialState, {
  [GET_PRODUCTS_PAGINATED]: (state): ProductTableState => ({
    ...state,
    fail: false,
    inProgress: true,
  }),
  [GET_PRODUCTS_PAGINATED_SUCCESS]: (
    state: ProductTableState,
    { payload }: { payload: GetProductsSuccessPayload },
  ) => ({
    ...state,
    ...payload,
    inProgress: false,
  }),
  [GET_PRODUCTS_PAGINATED_FAILED]: (state: ProductTableState) => ({
    ...state,
    fail: true,
    inProgress: false,
  }),
  [TOGGLE_FILTER]: (state, { payload }) => {
    return {
      ...state,
      appliedFilters: { ...state.appliedFilters, [payload.filterType]: payload.value },
    };
  },
  [TOGGLE_TAB]: (state, { payload }) => {
    return {
      ...state,
      appliedTab: { ...state.appliedTab, [payload.type]: payload.value },
    };
  },
  [SET_TABLE_PAGE]: (state, { payload }: { payload: number }) => {
    return {
      ...state,
      page: payload,
    };
  },
  [CLEAR_ALL_FILTERS]: state => ({
    ...state,
    appliedFilters: new Map(),
  }),
  [UPDATE_ROW]: (state, { payload }) => {
    const product = { ...payload, product: payload };
    if (state.products) {
      if (state.products[payload.id])
        state.products[payload.id] = { ...state.products[payload.id], ...product, product };
      else state.products = { [payload.id]: { ...product, product }, ...state.products };
    }
    return { ...state };
  },
  [EXPORT_PRODUCTS]: state => ({
    ...state,
    error: null,
    exportInProgress: true,
  }),
  [EXPORT_PRODUCTS_SUCCESS]: state => ({
    ...state,
    exportInProgress: false,
  }),
  [EXPORT_PRODUCTS_FAIL]: (state, { payload }) => ({
    ...state,
    exportInProgress: false,
    error: payload,
  }),
});
