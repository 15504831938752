import { Button, Chip, Grid, Theme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { createStyles, makeStyles } from '@material-ui/styles';
import React from 'react';
import { EmptyAC, PayloadAC } from 'typesafe-actions';
import {
  GetPaginatedUserTransactionHistoryRequest,
  SaveFilter,
} from '../../../../../../../../data/repositories/admin/typedefs';
import { Colors } from '../../../../../../../../ui/style/colors';
import {
  GET_ALL_TRANSACTIONS_PAGINATED,
  USER_TRANSACTION_HISTORY_CLEAR_ALL_FILTERS,
  USER_TRANSACTION_HISTORY_TOGGLE_FILTER,
} from '../../../state/actions';

const styles = (theme: Theme) =>
  createStyles({
    clearAll: {
      textTransform: 'none',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      color: theme.palette.primary.light,
    },
    clearAllIcon: {
      fontSize: 14,
      padding: 5,
      color: theme.palette.primary.light,
    },
    chip: {
      backgroundColor: `${Colors.spunPearl}`,
      '&:hover': {
        backgroundColor: `${Colors.ghost}`,
      },
      color: `${Colors.white}`,
      margin: 10,
      padding: 5,
    },
    clearChipIcon: {
      fontSize: 14,
      padding: 5,
      color: `${Colors.white}`,
    },
  });

const useStyles = makeStyles(styles);

type Props = {
  clearAllFilters: EmptyAC<typeof USER_TRANSACTION_HISTORY_CLEAR_ALL_FILTERS>;
  appliedFiltersIds: string[];
  stateAppliedFilters: Map<string, string[]>;
  toggleFilter: PayloadAC<typeof USER_TRANSACTION_HISTORY_TOGGLE_FILTER, SaveFilter>;
  areFiltersApplied: boolean;
  asyncGetAllTransactionsPaginated: PayloadAC<
    typeof GET_ALL_TRANSACTIONS_PAGINATED,
    GetPaginatedUserTransactionHistoryRequest
  >;
  logAccountId: string;
};

type Chip = {
  value: string;
  type: string;
};

const AppliedFiltersChips = ({
  clearAllFilters,
  appliedFiltersIds,
  stateAppliedFilters,
  toggleFilter,
  areFiltersApplied,
  asyncGetAllTransactionsPaginated,
  logAccountId,
}: Props) => {
  const classes = useStyles();
  const chips = [];
  for (const index in appliedFiltersIds) {
    const filter = stateAppliedFilters.get(appliedFiltersIds[index]);
    switch (appliedFiltersIds[index]) {
      case 'status':
        if (filter) {
          for (const i in filter) {
            chips.push({ value: filter[i], type: appliedFiltersIds[index] });
          }
        }
        break;
      case 'transactionType':
        if (filter) {
          for (const i in filter) {
            chips.push({ value: filter[i], type: appliedFiltersIds[index] });
          }
        }
        break;
      case 'valueRange':
        if (filter) {
          chips.push({
            value: `${filter[0] === '-100000' || filter[0] === '' ? 'Minimum' : filter[0]} to ${
              filter[1] === '100000' || filter[1] === '' ? 'Maximum' : filter[1]
            } SLC`,
            type: appliedFiltersIds[index],
          });
        }
        break;
      case 'dateFilter':
        if (filter) {
          chips.push({
            value: `${filter[0]} - ${filter[1]}`,
            type: appliedFiltersIds[index],
          });
        }
        break;
      case 'searchInput':
        if (filter) {
          chips.push({ value: filter[0], type: appliedFiltersIds[index] });
        }
    }
  }
  const onDelete = (chip: Chip) => {
    let newFilterValue;
    let isRange = false;
    if (chip.type !== 'valueRange' && chip.type !== 'dateFilter') {
      const filter = stateAppliedFilters.get(chip.type);
      if (filter) {
        newFilterValue = filter.filter(f => f != chip.value);
        newFilterValue = newFilterValue.length > 0 ? newFilterValue : undefined;
      }
    }
    const object: any = {};
    stateAppliedFilters.forEach((value, key) => {
      object[key] = value;
    });

    if (!newFilterValue && stateAppliedFilters.size == 1) {
      asyncGetAllTransactionsPaginated({
        logAccountId,
        page: 0,
        perPage: 10,
        appliedFilters: null,
      });
      clearAllFilters();
    } else {
      if (chip.type === 'valueRange') {
        isRange = true;
      }
      asyncGetAllTransactionsPaginated({
        logAccountId,
        page: 0,
        perPage: 10,
        appliedFilters: { ...object, [chip.type]: newFilterValue },
      });
      toggleFilter({ filterType: chip.type, value: newFilterValue, isRange });
    }
  };
  const renderType = (type: any) => {
    switch (type) {
      case 'airdrop_reward':
        return 'Airdrop';
      case 'new_user_reward':
        return 'Registration reward';
      case 'new_merchant_reward':
        return 'Became Partner';
      case 'bonus':
        return 'Bonus';
      case 'buy':
        return 'Buy';
      case 'deposit':
        return 'Deposit';
      case 'withdraw':
        return 'Withdraw';
      case 'exchange':
        return 'Exchange';
      case 'merchant_parent_reward':
        return 'Affiliate became Partner';
      case 'new_user_affiliate_reward':
        return 'New Affiliate';
      case 'payment_sent':
        return 'Purchase';
      case 'merchant_income':
        return 'Sale';
      case 'pool_reward':
        return 'Pool reward';
      case 'platform_pool_reward':
        return 'Platform pool reward';
      case 'premium_user_reward':
        return 'Affiliate package purchase';
      case 'premium_user_affiliate_reward':
        return 'Affiliate purchased Affiliate package';
      case 'affiliate_percentage_reward,term_saving,term_saving_finished&exclude_tx_status=invested':
        return 'BV';
      case 'VAT':
        return 'VAT';
      case 'timed_escrow':
        return 'Timed Escrow';
      case 'waiting_for_approval':
        return 'Waiting for approval';
      case 'pending_escrow':
        return 'Pending Escrow';
      case 'invested':
        return 'In HODL';
      case 'term_saving':
        return 'HODL';
      case 'transfer':
        return 'Transfer';
      case 'completed':
        return 'Completed';
      case 'escrow':
        return 'Escrow';
      case 'pending':
        return 'Pending';
      case 'sell':
        return 'Sell';
      case 'reward_correction':
        return 'Reward transfer';
      case 'buy_product':
        return 'Buy product';
      case 'sell_product':
        return 'Sell product';
      case 'event_reward':
        return 'Event reward';
      case 'bonus_reward':
        return 'Bonus reward';
      case 'interest_payment':
        return 'HODL Interested payment';
      case 'term_saving_finished':
        return 'Term saving payout';
      default:
        return type;
    }
  };
  return (
    <Grid item xs={12}>
      {chips.map(chip => (
        <Chip
          key={chip.value}
          size="small"
          clickable={true}
          deleteIcon={<CloseIcon className={classes.clearChipIcon} />}
          label={renderType(chip.value)}
          onDelete={() => onDelete(chip)}
          className={classes.chip}
        />
      ))}
      {areFiltersApplied && (
        <Button
          size="small"
          className={classes.clearAll}
          onClick={() => {
            asyncGetAllTransactionsPaginated({
              logAccountId,
              page: 0,
              perPage: 10,
              appliedFilters: null,
            });
            clearAllFilters();
          }}
        >
          <CloseIcon className={classes.clearAllIcon} />
          Clear all
        </Button>
      )}
    </Grid>
  );
};

export default AppliedFiltersChips;
