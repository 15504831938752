import { connect } from 'react-redux';
import { AppState } from '../../../../state/initialState';
import { userStateSelector } from '../../../../state/selectors';
import { membershipStateSelector } from '../../Exchange/state/selectors';
import { clearAllFilters, getWalletTransfersPaginated, toggleFilter } from '../state/actions';
import { getAppliedFiltersSelector } from '../state/selectors';
import WalletTransfersFilterBar from './TransfersFilterBar';

const mapStateToProps = (state: AppState) => {
  const membershipLevel = membershipStateSelector(state);
  const appliedFilters = getAppliedFiltersSelector(state);
  const user = userStateSelector(state);
  return { appliedFilters, membershipLevel, user };
};

const dispatchStateToProps = {
  getWalletTransfersPaginated: getWalletTransfersPaginated.request,
  toggleFilter,
  clearAllFilters,
};

export default connect(mapStateToProps, dispatchStateToProps)(WalletTransfersFilterBar);
