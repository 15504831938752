import { createReducer } from 'typesafe-actions';
import {
  GET_PROFILE_INFO,
  GET_PROFILE_INFO_FAIL,
  GET_PROFILE_INFO_SUCCESS,
  UPDATE_PAYMENT_INFO,
  UPDATE_PAYMENT_INFO_FAIL,
  UPDATE_PAYMENT_INFO_SUCCESS,
  RESET_STATE,
} from './actions';
import { initialState } from './initialState';

export default createReducer(initialState, {
  [GET_PROFILE_INFO]: state => ({
    ...state,
  }),
  [GET_PROFILE_INFO_SUCCESS]: (state, { payload }) => {
    return { ...state, ...payload };
  },
  [GET_PROFILE_INFO_FAIL]: state => ({
    ...state,
  }),
  [UPDATE_PAYMENT_INFO]: state => ({
    ...state,
    progressState: {
      ...state.progressState,
      inProgress: true,
    },
  }),
  [UPDATE_PAYMENT_INFO_SUCCESS]: state => {
    return {
      ...state,
      progressState: {
        success: true,
        fail: false,
        error: null,
        inProgress: false,
      },
    };
  },
  [UPDATE_PAYMENT_INFO_FAIL]: (state, { payload }) => {
    return {
      ...state,
      progressState: {
        success: false,
        fail: true,
        error: payload,
        inProgress: false,
      },
    };
  },
  [RESET_STATE]: state => {
    return {
      ...state,
      progressState: {
        ...state.progressState,
        success: false,
      },
    };
  },
});
