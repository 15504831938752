import { connect } from 'react-redux';
import { AppState } from '../../../../../state/initialState';
import { confirmTwoFactorAuth, disableTwoFactorAuth, openTwoFactorAuth } from '../state/actions';
import { twoFactorAuthStateSelector } from '../state/selectors';
import SettingsTwoFactorAuthConfirmDialog from './SettingsTwoFactorAuthConfirmDialog';

const mapDispatchToProps = {
  openTwoFactorAuth: openTwoFactorAuth,
  confirmTwoFactorAuth: confirmTwoFactorAuth,
  disableTwoFactorAuth: disableTwoFactorAuth,
};
const mapStateToProps = (state: AppState) => ({ ...twoFactorAuthStateSelector(state) });
export default connect(mapStateToProps, mapDispatchToProps)(SettingsTwoFactorAuthConfirmDialog);
