export type InviteData = {
  invite_emails: { email: string }[];
  text: string;
};

export type InviteModalState = {
  inProgress: boolean;
  success: boolean;
  fail: boolean;
  error: Error | null;
  inviteData: null | InviteData;
};

export const initialState: InviteModalState = {
  inProgress: false,
  success: false,
  fail: false,
  error: null,
  inviteData: null,
};
