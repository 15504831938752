import { EnableTwoFactorAuthResponse } from '../../../../../../data/repositories/account/typedefs';

export type TwoFactorAuthState = EnableTwoFactorAuthResponse & {
  dialogTitle: string;
  step: number;
  enabled: boolean | undefined;
  inProgress: boolean;
  fail: boolean;
  error: Error | null;
  confirmInProgress: boolean;
  confirmFail: boolean;
  confirmError: Error | null;
  disableInProgress: boolean;
  disableFail: boolean;
  disableError: Error | null;
  isDialogOpen: boolean;
  isEnabledInProgress: boolean;
};

export const initialState: TwoFactorAuthState = {
  isEnabledInProgress: false,
  dialogTitle: '',
  step: 0,
  enabled: undefined,
  qrCode: '',
  secret: '',
  inProgress: false,
  fail: false,
  error: null,
  confirmInProgress: false,
  confirmFail: false,
  confirmError: null,
  disableInProgress: false,
  disableFail: false,
  disableError: null,
  isDialogOpen: false,
};
