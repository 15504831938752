import { createStyles, makeStyles } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import React, { FC } from 'react';
import { ProductsPurchaseHistoryTableType } from '../../../App/Home/HomeContent/Admin/ProductsPurchaseHistory/state/initialState';
import { Colors } from '../../../ui/style/colors';
import balanceUtils from '../../../utils/balanceUtils';
import {
  CRYPTO_MAX_FRACTION,
  CRYPTO_MIN_FRACTION,
  WIRE_EUR_DECIMALS,
  WIRE_EUR_MAX_FRACTION,
  WIRE_EUR_MIN_FRACTION,
} from '../../../utils/constants/amounts';

type Props = {
  productPurchaseHistory: ProductsPurchaseHistoryTableType;
};

const useStyles = makeStyles(() =>
  createStyles({
    statusSuccess: {
      color: `${Colors.havelockBlue}`,
      fontSize: 14,
      textTransform: 'capitalize',
    },
    statusPending: {
      color: `${Colors.blueViolet}`,
      fontSize: 14,
      textTransform: 'capitalize',
    },
    statusRejected: { color: `${Colors.blueHaze}`, fontSize: 14, textTransform: 'capitalize' },
  }),
);

const statusRenderType = (type: string) => {
  switch (type) {
    case 'rejected':
      return 'Rejected';
    case 'created':
      return 'Pending';
    case 'accepted':
      return 'Accepted';
    default:
      return type;
  }
};

const ProductsPurchaseHistoryTableRow: FC<Props> = ({ productPurchaseHistory }: Props) => {
  const classes = useStyles();

  return (
    <TableRow>
      <TableCell
        className={
          productPurchaseHistory.order_status === 'accepted'
            ? classes.statusSuccess
            : productPurchaseHistory.order_status === 'created'
            ? classes.statusPending
            : classes.statusRejected
        }
      >
        {statusRenderType(productPurchaseHistory.order_status)}
      </TableCell>
      <TableCell>{productPurchaseHistory.product_name}</TableCell>
      <TableCell>{productPurchaseHistory.email}</TableCell>
      <TableCell>
        {moment(productPurchaseHistory.order_created_at).format('DD/MM/YYYY HH:mm')}
      </TableCell>
      <TableCell align="right">
        {balanceUtils.formatBalanceToString(
          productPurchaseHistory.slc_amount || 0,
          7,
          CRYPTO_MIN_FRACTION,
          CRYPTO_MAX_FRACTION,
        )}{' '}
        SLC
      </TableCell>
      <TableCell align="right">{productPurchaseHistory.slc_rate / 100}</TableCell>
      <TableCell align="right">
        {balanceUtils.formatBalanceToString(
          productPurchaseHistory.eur_amount,
          WIRE_EUR_DECIMALS,
          WIRE_EUR_MIN_FRACTION,
          WIRE_EUR_MAX_FRACTION,
        )}{' '}
        EUR
      </TableCell>

      <TableCell align="right">
        {balanceUtils.formatBalanceToString(
          productPurchaseHistory.current_eur_amount,
          WIRE_EUR_DECIMALS,
          WIRE_EUR_MIN_FRACTION,
          WIRE_EUR_MAX_FRACTION,
        )}{' '}
        EUR
      </TableCell>
    </TableRow>
  );
};

export default ProductsPurchaseHistoryTableRow;
