import { CircularProgress, Grid, Paper, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { PayloadAC } from 'typesafe-actions';
import CustomButtonPrimary from '../../../../../components/CustomButtons/CustomButtonPrimary';
// import Gmail from '../../../../../assets/gmail.svg';
// import Outlook from '../../../../../assets/outlook.svg';
// import Yahoo from '../../../../../assets/yahoo.svg';
import LocalStorage from '../../../../../data/LocalStorage';
import { TotalInvitesRequest } from '../../../../../data/repositories/invites/typedef';
import { Colors } from '../../../../../ui/style/colors';
import { USER_ID } from '../../../../../utils/constants/localStorageKeys';
import InviteFriendsModal from '../InvitesModal';
import { MyInvitesTableState } from '../MyInvitesTable/state/initialState';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: '3px',
      backgroundColor: `${Colors.white}`,
      boxShadow: '0 10px 30px 0 rgba(132,141,182,0.25)',
    },
    label: {
      color: theme.palette.secondary.light,
      fontSize: 14,
      fontWeight: 800,
      letterSpacing: 1,
      textTransform: 'uppercase',
      marginBottom: 12,
    },
    affiliatesLabel: {
      color: `${Colors.blueCharcoal}`,
      fontSize: 26,
      fontWeight: 'bold',
      [theme.breakpoints.down('sm')]: {
        fontSize: 20,
      },
    },
    container: {
      '@media (max-width:770px)': {
        justifyContent: 'center',
        paddingBottom: '10px',
      },
    },
    item: {
      padding: 20,
      flex: '0 0 auto',
      maxWidth: '100%',
      minWidth: 160,
      '@media (max-width:900px)': {
        minWidth: 120,
      },
      '@media (max-width: 580px)': {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'center',
        textAlign: 'center',
      },
    },
    buttonItem: {
      display: 'flex',
      marginTop: 'auto',
      marginBottom: 'auto',
      gridGap: '20px',
      '@media (max-width: 580px)': {
        display: 'block',
        flex: '0 0 auto',
        margin: 'auto auto 20px auto',
        maxWidth: 'fit-content',
      },
    },
    inviteButton: {
      margin: 'auto',
      display: 'flex',
      maxWidth: '220px',
      height: '42px',
      lineHeight: 'normal',
      '@media (max-width: 580px)': {
        width: '300px',
      },
    },
    sponge: {
      justifyContent: 'space-evenly',
      '@media (max-width: 580px)': {
        marginTop: '10px',
        display: 'flex',
      },
    },
    inviteVia: {
      textAlign: 'center',
    },
    links: {
      display: 'flex',
    },
  }),
);

type Props = MyInvitesTableState & {
  asyncGetTotalFriendsInvitationsPaginated: PayloadAC<
    'invites/get_total_friends_invitations',
    TotalInvitesRequest
  >;
};

const InvitesInfo: React.FC<Props> = (props: Props): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();
  const accountId = LocalStorage.get(USER_ID);
  const {
    asyncGetTotalFriendsInvitationsPaginated,
    totalPending,
    totalAccepted,
    totalInvited,
    inProgress,
  } = props;

  useEffect(() => {
    accountId &&
      asyncGetTotalFriendsInvitationsPaginated({
        accountId: accountId,
      });
  }, []);

  return (
    <Paper className={classes.root}>
      <Grid item container className={classes.container}>
        <Grid item xs={2} className={classes.item}>
          <Typography className={classes.label} component="p">
            Invited
          </Typography>
          {inProgress && !totalInvited ? (
            <CircularProgress color="secondary" size={30} />
          ) : (
            <Typography className={classes.affiliatesLabel} component="p">
              {totalInvited}
            </Typography>
          )}
        </Grid>

        <Grid item xs={2} className={classes.item}>
          <Typography className={classes.label} component="p">
            Accepted
          </Typography>
          {inProgress && !totalAccepted ? (
            <CircularProgress color="secondary" size={30} />
          ) : (
            <Typography className={classes.affiliatesLabel} component="p">
              {totalAccepted}
            </Typography>
          )}
        </Grid>
        <Grid item xs={2} className={classes.item}>
          <Typography className={classes.label} component="p">
            Pending
          </Typography>
          {inProgress && !totalPending ? (
            <CircularProgress color="secondary" size={30} />
          ) : (
            <Typography className={classes.affiliatesLabel} component="p">
              {totalPending}
            </Typography>
          )}
        </Grid>

        <Grid item className={classes.buttonItem}>
          <CustomButtonPrimary
            variant="contained"
            size="large"
            className={classes.inviteButton}
            onClick={() => setIsOpen(true)}
          >
            Invite Friends
          </CustomButtonPrimary>

          <Grid item className={classes.sponge}>
            <InviteFriendsModal
              isOpen={isOpen}
              closeModal={() => {
                setIsOpen(false);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default InvitesInfo;
