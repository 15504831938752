import {
  ALLOWED_CRYPTO_FOR_NON_MEMBERS,
  ALL_COINS,
  CRYPTO_CHART_INFO_DECIMALS,
  ALLOWED_CRYPTO_FOR_NO_KYC_USERS,
} from '../constants/amounts';
import configureStore from '../../store';
import jwt_decode from 'jwt-decode';

export const sortArrayAlphabetically = unsortedArray =>
  unsortedArray.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

export const handleMinMaxRange = (state, payload) => {
  const objectRange = {
    minValue: !payload.isRange
      ? !payload.minValue
        ? state.appliedFilters.minValue
        : payload.minValue
      : null,
    maxValue: !payload.isRange
      ? !payload.maxValue
        ? state.appliedFilters.maxValue
        : payload.maxValue
      : null,
  };
  if (payload.minValue === '') objectRange['minValue'] = null;
  if (payload.maxValue === '') objectRange['maxValue'] = null;

  return objectRange;
};

export const isMissignWallet = symbol => {
  const { store } = configureStore;
  const state = store.getState();
  const wallets = Object.keys(state.home.wallets.wallets);

  return wallets.length > 1 && ALL_COINS.includes(symbol) && !wallets.includes(symbol);
};

/**
 * special_condition - in case there are deviations from the general permits. E.g. WALLET EUR is allowed on Wallet page only
 */
export const isAllowedCurrencyForNonMembers = (kyc_info, symbol, special_condition) => {
  if (special_condition) {
    return special_condition;
  }
  if (!kyc_info) {
    return ALLOWED_CRYPTO_FOR_NO_KYC_USERS.includes(symbol);
  }
  return ALLOWED_CRYPTO_FOR_NON_MEMBERS.includes(symbol);
};

export const getAllowedCurrenciesForNonMembers = kyc_info => {
  if (!!kyc_info) {
    return ALLOWED_CRYPTO_FOR_NON_MEMBERS;
  }
  return ALLOWED_CRYPTO_FOR_NO_KYC_USERS;
};

export const isUserMember = (membershipLevel, checkWallet = true) => {
  const { store } = configureStore;
  const state = store.getState();
  const wallets = Object.keys(state.home.wallets.wallets);

  const hasUserWallet = checkWallet && wallets.length > ALLOWED_CRYPTO_FOR_NON_MEMBERS.length;
  const isMember =
    membershipLevel &&
    (membershipLevel.affiliate_package_level === 6 ||
      membershipLevel.affiliate_package_level === 5 ||
      membershipLevel.affiliate_package_level);
  if (!isMember && checkWallet) {
    return hasUserWallet;
  }
  return isMember;
};

// break down an arr into elements that exist in a predefinedOrder array and those that do not exist
export const refactorArrBasedOnPredefinedOrder = (arr, predefinedOrder, sortProp = '') => {
  const notExistInPredefinedOrder = [];

  const toSort = arr.filter(item => {
    const element = sortProp ? item[sortProp] : item;
    if (!predefinedOrder.includes(element)) {
      notExistInPredefinedOrder.push(item);
    }
    return predefinedOrder.includes(element);
  });

  return { toSort, notExistInPredefinedOrder };
};

export const sortArrByPredefinedOrder = (arr, predefinedOrder, sortProp = '') => {
  let { toSort, notExistInPredefinedOrder } = refactorArrBasedOnPredefinedOrder(
    arr,
    predefinedOrder,
    sortProp,
  );

  let result = [...toSort].sort(
    (firstEl, secondEl) =>
      predefinedOrder.indexOf(sortProp ? firstEl[sortProp] : firstEl) -
      predefinedOrder.indexOf(sortProp ? secondEl[sortProp] : secondEl),
  );

  return [...result, ...notExistInPredefinedOrder];
};

export const bytesToSize = bytes => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
};

export const convertToInternationalCurrencySystem = labelValue => {
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(CRYPTO_CHART_INFO_DECIMALS) + 'B'
    : Math.abs(Number(labelValue)) >= 1.0e6
    ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(CRYPTO_CHART_INFO_DECIMALS) + 'M'
    : Math.abs(Number(labelValue)) >= 1.0e3
    ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(CRYPTO_CHART_INFO_DECIMALS) + 'K'
    : Math.abs(Number(labelValue));
};

export const passwordRegex =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*'~`{}()|?.,<>_+=])[0-9a-zA-Z!@#$%^&*'~`{}()|?.,<>_+=]{8,}$/;

//For symbols with underscore (e.g. WIRE_EUR) return the string after underscore
export const getCurrencySymbolName = symbol => {
  let name = symbol;
  const splited = symbol.split('_');
  if (splited.length > 1) {
    name = splited[1];
  }
  return name;
};

export const jwtDecode = jwtToken => {
  return jwt_decode(jwtToken);
};
export const getWalletNameBySymbol = symbol => {
  switch (symbol) {
    case 'WALLET_EUR':
      return 'Revolut';
    case 'WIRE_EUR':
      return 'Wire transfer';
    default:
      return 'BitGo';
  }
};
