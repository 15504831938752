import { ProductsPurchaseHistoryTableType } from '../../../App/Home/HomeContent/Admin/ProductsPurchaseHistory/state/initialState';
import { UpdateFeeRateRequest } from '../../../App/Home/HomeContent/Settings/FeeSettings/state/initialState';
import {
  CHANGE_USER_PASSWORD_ENDPOINT,
  DEPOSIT_FIAT_ENDPOINT,
  GET_STORE_ITEMS_ENDPOINT,
  UPDATE_CURRENCY_RATE_ENDPOINT,
  UPDATE_STORE_ITEM_ENDPOINT,
  USERS_ENDPOINT,
} from '../../../utils/constants/api';
import Http from '../../Http';
import {
  ChangeUserPasswordRequest,
  GetStoreItemsResponse,
  GetAffiliateLevelsResponse,
  GetTransactionGroupingResponse,
  GetUserEarningsResponse,
  GetUserTransactionHistoryResponse,
} from './typedefs';
const identityURL = process.env.REACT_APP_IDENTITY_API_URL;
const getAffiliateLevels = async (): Promise<GetAffiliateLevelsResponse> => {
  //return (await Http.get(endpoint)).data;
  return {
    level: '7',
    levels: [
      {
        level: '12',
        percent: '3',
      },
      {
        level: '432',
        percent: '7',
      },
      {
        level: '3',
        percent: '10',
      },
      {
        level: '4',
        percent: '10',
      },
      {
        level: '5',
        percent: '40',
      },
      {
        level: '6',
        percent: '13',
      },
      {
        level: '7',
        percent: '7',
      },
    ],
  };
};

const updateCurrencyRate = async (accountId: string, rate: string, selectedCurrency: string) => {
  return (
    await Http.post(UPDATE_CURRENCY_RATE_ENDPOINT(accountId, selectedCurrency), {
      rate: rate.toString(),
    })
  ).data;
};

const updateFeeRate = async (storeItem: UpdateFeeRateRequest, id: string) => {
  await Http.put(UPDATE_STORE_ITEM_ENDPOINT(id), storeItem);
};

const getStoreItems = async (userId: string): Promise<GetStoreItemsResponse> => {
  return (await Http.get(GET_STORE_ITEMS_ENDPOINT(userId))).data.store_item;
};

const depositFiat = async (userId: string, symbol: string, amount: number) => {
  await Http.post(DEPOSIT_FIAT_ENDPOINT(userId, symbol), {
    amount: amount,
  });
};

export const getUserEarningsPaginated = async (
  endpoint: string,
): Promise<GetUserEarningsResponse> => {
  return (await Http.get(endpoint)).data;
};

export const getProductsPurchaseHistoryPaginated = async (
  endpoint: string,
): Promise<ProductsPurchaseHistoryTableType[]> => {
  return (await Http.get(endpoint)).data;
};

const exportUsersEarnings = async (endpoint: string): Promise<GetUserEarningsResponse> => {
  return (await Http.get(endpoint)).data;
};

const exportProductsPurchaseHistory = async (
  endpoint: string,
): Promise<ProductsPurchaseHistoryTableType> => {
  return (await Http.get(endpoint)).data;
};

const getTransactionGroupingPaginated = async (
  endpoint: string,
): Promise<GetTransactionGroupingResponse> => {
  return (await Http.get(endpoint)).data;
};

const exportTransactionGrouping = async (
  endpoint: string,
): Promise<GetTransactionGroupingResponse> => {
  return (await Http.get(endpoint)).data;
};

export const getUserTransactionHistoryPaginated = async (
  endpoint: string,
): Promise<GetUserTransactionHistoryResponse> => {
  return (await Http.get(endpoint)).data;
};

const exportTransactions = async (endpoint: string): Promise<string> => {
  return (await Http.get(endpoint)).data;
};

const getWalletTransfersPaginated = async (endpoint: string) => (await Http.get(endpoint)).data;

const changeUserPassword = async (data: ChangeUserPasswordRequest, accountId: string) =>
  Http.put(`${identityURL}${USERS_ENDPOINT}/${accountId}${CHANGE_USER_PASSWORD_ENDPOINT}`, data, {
    headers: { User: accountId },
  });

export default {
  getAffiliateLevels,
  updateCurrencyRate,
  getUserEarningsPaginated,
  getProductsPurchaseHistoryPaginated,
  exportUsersEarnings,
  exportProductsPurchaseHistory,
  getTransactionGroupingPaginated,
  exportTransactionGrouping,
  getUserTransactionHistoryPaginated,
  exportTransactions,
  getWalletTransfersPaginated,
  changeUserPassword,
  getStoreItems,
  updateFeeRate,
  depositFiat,
};
