/* eslint-disable */
import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import {
  Cycle,
  GetReferralsPaginatedRequest,
  GetUnclaimedSLCResponse,
  Referral,
} from '../../../../../../data/repositories/affiliate/typedefs';
import { MerchantsReferralStats } from './initialState';

export const GET_MERCHANTS_REFERRALS_STATS = 'affiliate/get_merchants_referrals_stats';
export const GET_MERCHANTS_REFERRALS_STATS_SUCCESS =
  'affiliate/get_merchants_referrals_stats_success';
export const GET_MERCHANTS_REFERRALS_STATS_FAIL = 'affiliate/get_merchants_referrals_stats_fail';

export const GET_MERCHANTS_REFERRALS_PAGINATED = 'affiliate/get_merchants_referrals_paginated';
export const GET_MERCHANTS_REFERRALS_PAGINATED_SUCCESS =
  'affiliate/get_merchants_referrals_paginated/success';
export const GET_MERCHANTS_REFERRALS_PAGINATED_FAILED =
  'affiliate/get_merchants_referrals_paginated/fail';

export const GET_MERCHANTS_UNCLAIMED_SLC = 'affiliate/get_merchants_unclaimed_slc';
export const GET_MERCHANTS_UNCLAIMED_SLC_SUCCESS = 'affiliate/get_merchants_unclaimed_slc/success';
export const GET_MERCHANTS_UNCLAIMED_SLC_FAIL = 'affiliate/get_merchants_unclaimed_slc/fail';

export const CLAIM_MERCHANTS_SLC = 'affiliate/claim_merchants_slc';
export const CLAIM_MERCHANTS_SLC_SUCCESS = 'affiliate/claim_merchants_slc/success';
export const CLAIM_MERCHANTS_SLC_FAIL = 'affiliate/claim_merchants_slc/fail';

export const SET_MERCHANTS_TABLE_PAGE = 'affiliate/set_merchants_table_page';

export const asyncGetMerchantsReferralsStats = createAsyncAction(
  GET_MERCHANTS_REFERRALS_STATS,
  GET_MERCHANTS_REFERRALS_STATS_SUCCESS,
  GET_MERCHANTS_REFERRALS_STATS_FAIL,
)<undefined, MerchantsReferralStats, Error>();

export const asyncGetMerchantsReferralsPaginated = createAsyncAction(
  GET_MERCHANTS_REFERRALS_PAGINATED,
  GET_MERCHANTS_REFERRALS_PAGINATED_SUCCESS,
  GET_MERCHANTS_REFERRALS_PAGINATED_FAILED,
)<GetReferralsPaginatedRequest, { [id: string]: Referral }, Error>();

export const asyncGetMerchantsUnclaimedSLC = createAsyncAction(
  GET_MERCHANTS_UNCLAIMED_SLC,
  GET_MERCHANTS_UNCLAIMED_SLC_SUCCESS,
  GET_MERCHANTS_UNCLAIMED_SLC_FAIL,
)<undefined, GetUnclaimedSLCResponse, Error>();

export const asyncClaimMerchantsSLC = createAsyncAction(
  CLAIM_MERCHANTS_SLC,
  CLAIM_MERCHANTS_SLC_SUCCESS,
  CLAIM_MERCHANTS_SLC_FAIL,
)<Cycle[], undefined, Error>();

export const setMerchantsTablePage = createStandardAction(SET_MERCHANTS_TABLE_PAGE)<number>();
