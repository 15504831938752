import {
  Transaction,
  TransactionsFilter,
} from '../../../../../data/repositories/transaction/typedef';
import {
  initialState as walletTransfersState,
  WalletTransfersTableState,
} from '../TransfersTable/state/initialState';

export type termSavingsDetailsRequest = {
  amount: number;
  description: string;
  maturity_date: string;
  start_date: string;
  earned_interest: number;
};

export const transactionHashDialogInitailState = {
  open: false,
  selectedTXHashId: '',
  swapTXHashId: '',
  swapDate: '',
  swapRate: '',
  swapPair: '',
  inProgress: false,
  success: false,
  fail: false,
  isNewRegisteredUser: false,
};

export type TransactionHashDialogState = {
  open: boolean;
  selectedTXHashId: string;
  swapTXHashId: string;
  swapDate: string;
  swapRate: string;
  swapPair: string;
  inProgress: boolean;
  success: boolean;
  fail: boolean;
  isNewRegisteredUser: boolean;
};

export type TransactionsState = {
  transactions: Map<string, Transaction>;
  singleCoinTransactions: Map<string, Transaction>;
  appliedFilters: TransactionsFilter;
  inProgress: boolean;
  fail: boolean;
  error: Error | null;
  loadingDone: boolean;
  exportInProgress: boolean;
  termSavingsDetails?: termSavingsDetailsRequest;
  walletTransfersState: WalletTransfersTableState;
  transactionHashDialog: TransactionHashDialogState;
};

export const initialState: TransactionsState = {
  transactions: new Map<string, Transaction>(),
  singleCoinTransactions: new Map<string, Transaction>(),
  appliedFilters: new Map<string, string[]>(),
  inProgress: false,
  fail: false,
  error: null,
  loadingDone: false,
  exportInProgress: false,
  termSavingsDetails: {
    description: '',
    maturity_date: '',
    earned_interest: 0,
    amount: 0,
    start_date: '',
  },
  walletTransfersState: walletTransfersState,
  transactionHashDialog: transactionHashDialogInitailState,
};
