import { Button, styled } from '@material-ui/core';
import { Colors } from '../../ui/style/colors';

const CustomButtonPrimary = styled(Button)(() => ({
  color: `${Colors.white}`,
  borderRadius: '18px',
  backgroundColor: `${Colors.havelockBlue}`,
  transition: 'none',
  border: 'none',
  boxShadow: 'none',

  '&:hover': {
    backgroundImage: `linear-gradient(to right,${Colors.havelockBlue}, ${Colors.mediumTurquoise});`,
    boxShadow: 'none',

    // transition: 'all .3s ease-in-out,background .3s',
  },
}));

export default CustomButtonPrimary;
