import { CircularProgress, Grid, Paper, Tooltip, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { EmptyAC } from 'typesafe-actions';
import { Colors } from '../../../../../ui/style/colors';
import utils from '../../../../../utils/balanceUtils';
import { AffiliateInfoState } from '../../Affiliate/AffiliateInfo/state/initialState';
import { AffiliateTableState } from '../../Affiliate/AffiliateTable/state/initialState';
import useExchangeCurrencyPrices from '../../Exchange/ExchangeCurrency/useExchangeCurrencyPrices';
import { GET_WALLET_BALANCE } from '../../Wallet/state/actions';

const styles = (theme: Theme) =>
  createStyles({
    transactions: {
      margin: '6px 6px auto 12px',
    },
    root: {
      borderRadius: '3px',
      backgroundColor: `${Colors.white}`,
      boxShadow: '0 10px 30px 0 rgba(132,141,182,0.25)',
      padding: 20,
    },
    item: {
      flex: '0 0 auto',
      maxWidth: '100%',
      minWidth: 160,
      '@media (max-width:1600px)': {
        minWidth: 'auto',
      },
    },
    balanceLabel: {
      marginBottom: 10,
      fontSize: 14,
      fontWeight: 800,
      letterSpacing: 1,
      color: theme.palette.secondary.light,
    },
    balance: {
      fontSize: 26,
      marginTop: 14,
      fontWeight: 'bold',
      color: `${Colors.blueCharcoal}`,
    },

    pendingBalance: {
      fontSize: 18,
      color: theme.palette.secondary.light,
    },
    icon: {
      height: 25,
      marginTop: 6,
      marginRight: 6,
    },
    label: {
      color: theme.palette.secondary.light,
      fontSize: 14,
      fontWeight: 800,
      letterSpacing: 1,
      textTransform: 'uppercase',
    },
    affiliatesLabel: {
      color: `${Colors.blueCharcoal}`,
      fontSize: 26,
      fontWeight: 'bold',
    },
    affiliateContainer: {
      marginTop: 30,
    },
    eurLine: {
      width: 'auto',
      marginTop: 30,
      marginBottom: 15,
      borderTop: `2px solid ${theme.palette.secondary.light}`,
      color: theme.palette.secondary.light,
      '&:hover': {
        borderTop: `2px solid ${Colors.denim}`,
        transition: 'border 500ms ease-out',
        cursor: 'pointer',
      },
    },
    slcLine: {
      width: 'auto',
      marginTop: 30,
      color: theme.palette.secondary.light,
      borderTop: `2px solid ${theme.palette.secondary.light}`,
      '&:hover': {
        borderTop: `2px solid ${Colors.mediumTurquoise}`,
        transition: 'border 500ms ease-out',
        cursor: 'pointer',
      },
    },
    currencyWrapper: {
      width: '100%',
      textAlign: 'center',
      lineHeight: '0.1em',
      margin: '0',
    },
    currencyheading: {
      background: `${Colors.white}`,
      padding: '0 10px',
      fontSize: 18,
      fontWeight: 600,
      color: theme.palette.secondary.light,
      verticalAlign: 'top',
    },
  });

const useStyles = makeStyles(styles);

type Props = {
  asyncGetIncomeBalances: EmptyAC<'affiliate/get_income_balances'>;
  asyncGetReferralsStats: EmptyAC<'affiliate/get_referrals_stats'>;
  affiliateTableState: AffiliateTableState;
  getWalletBalance: EmptyAC<typeof GET_WALLET_BALANCE>;
  affiliateInfoState: AffiliateInfoState;
  className?: string;
};

const EarningsHeader: React.FC<Props> = ({
  asyncGetIncomeBalances,
  affiliateInfoState: { incomeBalances },
  getWalletBalance,
  asyncGetReferralsStats,
  affiliateTableState: {
    inProgress,
    stats: { totalReferrals, directReferrals },
  },
  className,
}: Props): JSX.Element => {
  useEffect(() => {
    asyncGetIncomeBalances();
    getWalletBalance();
    asyncGetReferralsStats();
  }, []);

  const classes = useStyles();
  const { getSelectedCoinMarketVal } = useExchangeCurrencyPrices();
  const slcEurVal = getSelectedCoinMarketVal('SLC');

  let completed, pending;
  if (incomeBalances.length) {
    completed = incomeBalances.find(income => income.transaction_status === 'completed');
    pending = incomeBalances.find(income => income.transaction_status === 'pending');
  }

  return (
    <Grid item className={`${classes.transactions} ${className}`}>
      <Paper className={classes.root}>
        <Typography component="p" className={classes.balanceLabel}>
          EARNINGS INCOME
        </Typography>

        <Grid item xs={'auto'} className={classes.eurLine}>
          <h2 className={classes.currencyWrapper}>
            <span className={classes.currencyheading}>EUR</span>
          </h2>
          <Tooltip
            title={<div style={{ fontSize: 16 }}>Your current earnings income in euro</div>}
            arrow={true}
            placement="bottom-start"
          >
            <Typography component="p" className={classes.balance}>
              <span className={classes.balanceLabel}>TOTAL : </span>{' '}
              {completed
                ? utils.calculateValue(Number(completed.aggregated_value), 7, slcEurVal)
                : '0.00'}{' '}
            </Typography>
          </Tooltip>
          <Tooltip
            title={<div style={{ fontSize: 16 }}>Your pending earnings income in euro</div>}
            arrow={true}
            placement="bottom-start"
          >
            <Typography component="p" className={classes.pendingBalance}>
              <span className={classes.balanceLabel}> PENDING : </span>
              {pending
                ? utils.calculateValue(Number(pending.aggregated_value), 7, slcEurVal)
                : '0.00'}{' '}
            </Typography>
          </Tooltip>
        </Grid>
        <Grid item xs={'auto'} className={classes.slcLine}>
          <h2 className={classes.currencyWrapper}>
            <span className={classes.currencyheading}>SLC</span>
          </h2>
          <Tooltip
            title={<div style={{ fontSize: 16 }}>Your current earnings income in SLC</div>}
            arrow={true}
            placement="bottom-start"
          >
            <Typography component="p" className={classes.balance}>
              <span className={classes.balanceLabel}>TOTAL : </span>{' '}
              {completed
                ? utils.formatBalanceToString(Number(completed.aggregated_value), 7, 2, 2)
                : '0.00'}
            </Typography>
          </Tooltip>
          <Tooltip
            title={<div style={{ fontSize: 16 }}>Your pending earnings income in SLC</div>}
            arrow={true}
            placement="bottom-start"
          >
            <Typography component="p" className={classes.pendingBalance}>
              <span className={classes.balanceLabel}> PENDING : </span>
              {pending
                ? utils.formatBalanceToString(Number(pending.aggregated_value), 7, 2, 2)
                : '0.00'}{' '}
            </Typography>
          </Tooltip>
        </Grid>

        <Grid item container spacing={4} className={classes.affiliateContainer}>
          <Grid item className={classes.item}>
            <Typography className={classes.label} component="p">
              Network
            </Typography>
            <Tooltip
              title={
                <div style={{ fontSize: 16 }}>Number of total affiliates in your downline</div>
              }
              arrow={true}
              placement="bottom-start"
            >
              {inProgress && !totalReferrals ? (
                <CircularProgress color="secondary" size={30} />
              ) : (
                <Typography className={classes.affiliatesLabel} component="p">
                  {totalReferrals}
                </Typography>
              )}
            </Tooltip>
          </Grid>

          <Grid item className={classes.item}>
            <Typography className={classes.label} component="p">
              Direct Downline affiliates
            </Typography>
            <Tooltip
              title={<div style={{ fontSize: 16 }}>Number of affiliates you invited to SLAFF</div>}
              arrow={true}
              placement="bottom-start"
            >
              {inProgress && !totalReferrals ? (
                <CircularProgress color="secondary" size={30} />
              ) : (
                <Typography className={classes.affiliatesLabel} component="p">
                  {directReferrals}
                </Typography>
              )}
            </Tooltip>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default EarningsHeader;
