import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { EmptyAC } from 'typesafe-actions';
import slcCoin from '../../../../../assets/coins/sacretLifeCoin.svg';
import CustomButtonPrimary from '../../../../../components/CustomButtons/CustomButtonPrimary';
import ModalState from '../../../../../components/ModalState';
import { Colors } from '../../../../../ui/style/colors';
import balanceUtils from '../../../../../utils/balanceUtils';
import { SUPPORT_EMAIL } from '../../../../../utils/constants/info';
import { getCurrencyFraction } from '../../../../../utils/helper/numbers';
import numberUtils from '../../../../../utils/numberUtils';
import { dialogState } from '../AffiliateTable/state/initialState';
import {
  CLEAR_DIALOG_STATE,
  IS_CHANGE_SELL_LIMIT_DIALOG_OPEN,
  SAVE_SLC_CHANGE_LIMIT,
} from '../state/actions';

const styles = (theme: Theme) =>
  createStyles({
    dialog: {
      height: 'center',
      width: 'center',
      borderRadius: 6,
      backgroundColor: 'transparent',
      paddingLeft: 30,
      paddingRight: 30,
      minWidth: 400,
    },
    wrapper: {
      padding: '47px 32px',
      maxWidth: 450,
      margin: '0 auto',
      height: 412,
    },
    wrapperSpinner: {
      height: 385,
    },
    closeButton: {
      position: 'absolute',
      right: 5,
      top: 5,
      color: theme.palette.primary.light,
    },
    dialogTittleText: {
      color: `${Colors.blueCharcoal}`,
      fontSize: 18,
      fontWeight: 600,
      letterSpacing: 1.13,
      display: 'flex',
      alignItems: 'center',
    },
    dialogTitle: {
      marginTop: 25,
      marginLeft: 25,
      marginRight: 25,
      padding: 0,
    },
    slcIcon: {
      height: 40,
      marginRight: 6,
    },
    currencyLabel: {
      color: theme.palette.primary.light,
      fontSize: 12,
      fontWeight: 600,
      marginBottom: 10,
    },
    inputSymbol: {
      width: 51,
      height: 50,
      borderRadius: '0 4px 4px 0',
      backgroundColor: `${theme.palette.secondary.light}`,
      color: `${Colors.white}`,
      fontSize: 12,
      fontWeight: 600,
      display: ' flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    value: {
      color: `${Colors.blueCharcoal}`,
      textAlign: 'center',
      marginBottom: 10,
      fontSize: 14,
    },
    input: {
      '&::placeholder': {
        color: theme.palette.secondary.light,
      },
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'none',
      },
      '&[type=number]': {
        '-webkit-appearance': 'textfield',
        '-moz-appearance': 'textfield',
      },
    },
    inputWrapperSlc: {
      paddingRight: 5,
    },
    inputWrapperEur: {
      paddingLeft: 5,
      display: 'flex',
      alignItems: 'flex-end',
    },
    saveButton: {
      textTransform: 'none',
      width: '100%',
      margin: '16px 0',
    },
    cancelButton: {
      textTransform: 'none',
      width: '100%',
      background: `${Colors.white}`,
      color: `${Colors.mirage}`,
    },
    userContainer: {
      margin: '20px 0',
      display: 'flex',
      justifyContent: 'space-between',
    },
    box: {
      padding: '8px 16px',
      width: 270,
      height: 72,
      border: '1px solid #D9DBE9',
      boxSizing: 'border-box',
      borderRadius: 12,
      display: 'flex',
      '@media(max-width:540px)': {
        width: '100%',
      },
    },
    userBox: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      '@media(max-width:540px)': {
        marginBottom: 20,
      },
    },
    limitBox: {
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    boxHeading: {
      fontWeight: 600,
      fontSize: 14,
      color: `${Colors.muledVine}`,
    },
    boxSubtitle: {
      fontWeight: 400,
      fontSize: 14,
      color: `${Colors.stormGray}`,
    },
    notification: {
      margin: 0,
      color: `${Colors.wildBlueYonder}`,
    },
    minValue: {
      color: `${Colors.mediumTurquoise}`,
    },
  });
const useStyles = makeStyles(styles);

type Props = {
  clearDialogState: EmptyAC<typeof CLEAR_DIALOG_STATE>;
  dialogState: dialogState;
  slcEurPrice: string;
};

const AffiliateChangeSlcLimitModal: React.FC<Props> = ({
  dialogState: {
    referralUser: { email, first_name, last_name, id },
    isChangeSellLimitDialogOpen,
    inProgress,
    success,
    errorMessage,
    userSellSlcInfo: { total_amount_to_sell_in_eurocents, total_amount_to_sell_in_slc },
  },
  clearDialogState,
  slcEurPrice,
}: Props) => {
  const [enteredAmount, setEnteredAmount] = useState(0);
  const [isSubmited, setIsSubmited] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();

  const closeModal = () => {
    clearDialogState();
    dispatch({ type: IS_CHANGE_SELL_LIMIT_DIALOG_OPEN, payload: false });
  };

  const transformAmountInEur = (amount: number) =>
    numberUtils
      .formatNumberDigits(
        parseFloat(String(amount) || '0'),
        getCurrencyFraction('EUR', 'min'),
        getCurrencyFraction('EUR', 'max'),
      )
      .replace(/,/g, '')
      .trim();

  const enteredAmountInEur = transformAmountInEur(Number(slcEurPrice) * enteredAmount);

  const slcLimitDisplay = balanceUtils.formatBalanceToString(
    total_amount_to_sell_in_slc,
    5,
    getCurrencyFraction('EUR', 'min'),
    getCurrencyFraction('EUR', 'max'),
  );

  useEffect(() => {
    setEnteredAmount(0);
    setIsSubmited(false);
  }, [isChangeSellLimitDialogOpen]);

  const setChangeSlcSellLimit = () => {
    dispatch({
      type: SAVE_SLC_CHANGE_LIMIT,
      payload: { id, sell_limit_in_eurocents: Number(enteredAmountInEur) },
    });
    setIsSubmited(true);
  };
  return (
    <Dialog open={isChangeSellLimitDialogOpen} className={classes.dialog} fullWidth>
      {inProgress ? (
        <ModalState inProgress onExit={closeModal} className={classes.wrapper} />
      ) : isSubmited ? (
        <ModalState
          variant={success ? 'success' : 'failed'}
          onExit={closeModal}
          className={classes.wrapper}
          message={
            success
              ? `SLC sell amount has been changed to ${enteredAmountInEur} euros`
              : `Something went wrong! For more datails please contact our support ${SUPPORT_EMAIL}`
          }
        />
      ) : !success && isChangeSellLimitDialogOpen ? (
        <ModalState
          variant="failed"
          onExit={closeModal}
          message={errorMessage}
          className={classes.wrapper}
        />
      ) : isChangeSellLimitDialogOpen ? (
        <>
          <DialogTitle disableTypography className={classes.dialogTitle}>
            <Typography className={classes.dialogTittleText}>Change SLC sell limit</Typography>
            <IconButton className={classes.closeButton} onClick={closeModal}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Grid container className={classes.userContainer}>
              <Grid item className={`${classes.box} ${classes.userBox}`}>
                <Typography className={classes.boxHeading}>
                  {first_name} {last_name}
                </Typography>
                <Typography className={classes.boxSubtitle}>{email}</Typography>
              </Grid>
              <Grid item className={`${classes.box} ${classes.limitBox}`}>
                <img src={slcCoin} alt="Slc icon" className={classes.slcIcon} />
                <div>
                  <Typography className={classes.boxHeading}>Current SLC sell limit</Typography>
                  <Typography className={classes.boxSubtitle}>
                    {slcLimitDisplay} SLC ({transformAmountInEur(total_amount_to_sell_in_eurocents)}{' '}
                    EUR)
                  </Typography>
                </div>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6} className={classes.inputWrapperSlc}>
                <Typography className={classes.currencyLabel}>New sell limit amount</Typography>
                <TextField
                  variant="outlined"
                  type="number"
                  className={classes.value}
                  placeholder={String(enteredAmount)}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setEnteredAmount(Number(e.target.value))
                  }
                  fullWidth
                  InputProps={{
                    classes: {
                      input: classes.input,
                    },
                    style: {
                      height: 50,
                      paddingRight: 0,
                    },
                    endAdornment: <div className={classes.inputSymbol}>SLC</div>,
                  }}
                />
              </Grid>
              <Grid item xs={6} className={classes.inputWrapperEur}>
                <TextField
                  disabled
                  variant="outlined"
                  className={classes.value}
                  placeholder="0.00"
                  value={enteredAmountInEur}
                  fullWidth
                  InputProps={{
                    style: {
                      height: 50,
                      paddingRight: 0,
                    },
                    classes: {
                      input: classes.input,
                    },
                    endAdornment: <div className={classes.inputSymbol}>EUR</div>,
                  }}
                />
              </Grid>
              <Typography className={classes.currencyLabel}>
                Minimum sell limit: <span className={classes.minValue}>10 EUR</span>
              </Typography>
            </Grid>
            <CustomButtonPrimary
              variant="contained"
              size="large"
              className={classes.saveButton}
              disabled={!(Number(enteredAmountInEur) >= 10)}
              onClick={setChangeSlcSellLimit}
            >
              Save
            </CustomButtonPrimary>
            <Button
              variant="contained"
              size="large"
              className={classes.cancelButton}
              onClick={closeModal}
            >
              Cancel
            </Button>
          </DialogContent>
        </>
      ) : null}
    </Dialog>
  );
};

export default AffiliateChangeSlcLimitModal;
