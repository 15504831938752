import { connect } from 'react-redux';
import { AppState } from '../../../../../state/initialState';
import { getAllCoins, investCurrencySaveState } from '../../../../state/actions';
import { isTwoFactorAuthEnabled } from '../../../Settings/state/actions';
import { twoFactorAuthEnableSelector } from '../../../Settings/state/selectors';
import { getAllCoinsSelector } from '../../../Wallet/state/selectors';
import { getProductsSelectedPaymentOption, shouldDisableAllCurrencies } from '../state/selectors';
import InvestCurrencyBuyOptions from './InvestCurrencyBuyOptions';

const mapDispatchToProps = {
  getAllCoins: getAllCoins,
  onInvestStep: investCurrencySaveState,
  isTwoFactorAuthEnabled: isTwoFactorAuthEnabled,
};

const mapStateToProps = (state: AppState) => {
  const allCoins = getAllCoinsSelector(state);
  const disabledAllCurrencies = shouldDisableAllCurrencies(state);
  const selectedOption = getProductsSelectedPaymentOption(state);
  const is2FaEnabled = twoFactorAuthEnableSelector(state);

  return { allCoins, disabledAllCurrencies, selectedOption, is2FaEnabled };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvestCurrencyBuyOptions);
