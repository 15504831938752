import {
  CardContent,
  CircularProgress,
  InputLabel,
  LinearProgress,
  makeStyles,
  MenuItem,
  Select,
  Grid,
  TextField,
} from '@material-ui/core';
import { useFormik } from 'formik';
import React from 'react';
import { Colors } from '../../../../../../ui/style/colors';
import theme from '../../../../../theme';
import * as yup from 'yup';
import CustomButtonPrimary from '../../../../../../components/CustomButtons/CustomButtonPrimary';
import { Coin } from '../BuySellModal';

const useStyles = makeStyles({
  card: {
    paddingLeft: 25,
    paddingRight: 25,
  },
  inputFields: {
    marginTop: 5,
    height: 33,
    backgroundColor: `${Colors.white}`,
    width: '70%',
    border: 'none',
    outline: 'none',
    fontSize: 'medium',
  },
  selectItem: {
    marginTop: 4,
    height: 33,
    width: '50%',
    textAlign: 'center',
    boxSizing: 'border-box',
    borderRadius: 4,
    backgroundColor: `${Colors.white}`,
  },
  button: {
    marginTop: 40,
    textTransform: 'none',
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 1,
    textAlign: 'center',
  },
  labels: {
    fontWeight: 'bold',
    color: theme.palette.primary.light,
    marginTop: 30,
    marginBottom: 8,
  },
  inputError: {
    color: `${Colors.red}`,
    marginTop: 10,
  },

  coinIcon: {
    height: 25,
    marginRight: 6,
    marginBottom: 2,
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  progressNoAnimation: {
    animation: 'none',
  },
});

type BuySellFormProps = {
  activeTab: number;
  inProgress: boolean;
  allCoins: Coin[];
  selectedWalletCoin: Coin;
};

const BuySellForm = ({ activeTab, inProgress, allCoins, selectedWalletCoin }: BuySellFormProps) => {
  const classes = useStyles();

  const changeCoinEurName = (coin: string) => {
    if (coin === 'WALLET_EUR') {
      return 'EUR';
    }
    return coin;
  };

  const coinsWithoutEur = allCoins.filter(coin => coin.symbol !== 'WALLET_EUR');
  const eurCoin = allCoins.filter(coin => coin.symbol === 'WALLET_EUR');

  const validationSchema = yup.object({
    amountToPay: yup
      .number()
      .positive(`Entered ammount must be a positive number!`)
      .required(`This field is required!`),
    amountToReceive: yup
      .number()
      .positive(`Entered ammount must be a positive number!`)
      .required(`This field is required!`),
  });

  const formik = useFormik({
    initialValues: {
      amountToPay: '',
      amountToReceive: '',
      coinToPay: 'EUR',
      coinToReceive: selectedWalletCoin.symbol,
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      // submit(values);
      values;
    },
  });
  const renderCoins = (coins: Coin[]) =>
    coins.map(coin => {
      return (
        <MenuItem
          key={coin.symbol}
          value={changeCoinEurName(coin.symbol)}
          style={{ textAlign: 'center' }}
        >
          <img src={coin.icon} className={classes.coinIcon} />
          {changeCoinEurName(coin.symbol)}
        </MenuItem>
      );
    });

  return (
    <CardContent className={classes.card}>
      <form id="buySellForm" onSubmit={formik.handleSubmit}>
        <InputLabel className={classes.labels}> You {!activeTab ? 'Pay' : 'Send'}</InputLabel>
        <Grid style={{ display: 'flex' }}>
          <TextField
            id="amountToPay"
            type="number"
            name="amountToPay"
            placeholder="Enter amount"
            defaultValue={formik.values.amountToPay}
            onChange={formik.handleChange}
            className={classes.inputFields}
            InputProps={{ disableUnderline: true }}
          />
          <Select
            id={`${activeTab ? 'coinToReceive' : 'coinToPay'}`}
            name={`${activeTab ? 'coinToReceive' : 'coinToPay'}`}
            variant="standard"
            value={activeTab ? formik.values.coinToReceive : formik.values.coinToPay}
            onChange={formik.handleChange}
            className={classes.selectItem}
            disableUnderline
          >
            {renderCoins(activeTab ? coinsWithoutEur : eurCoin)}
          </Select>
        </Grid>
        <LinearProgress
          style={{ height: 3 }}
          classes={!inProgress ? { bar: classes.progressNoAnimation } : {}}
        />

        {formik.errors.amountToPay && formik.touched.amountToPay ? (
          <Grid className={classes.inputError}>{formik.errors.amountToPay}</Grid>
        ) : null}

        <InputLabel className={classes.labels}>You Receive</InputLabel>
        <Grid style={{ display: 'flex' }}>
          <TextField
            id="amountToReceive"
            type="number"
            name="amountToReceive"
            placeholder="Enter amount"
            defaultValue={formik.values.amountToReceive}
            onChange={formik.handleChange}
            className={classes.inputFields}
            InputProps={{ disableUnderline: true }}
          />
          <Select
            id={`${!activeTab ? 'coinToReceive' : 'coinToPay'}`}
            name={`${!activeTab ? 'coinToReceive' : 'coinToPay'}`}
            variant="standard"
            value={!activeTab ? formik.values.coinToReceive : formik.values.coinToPay}
            onChange={formik.handleChange}
            className={classes.selectItem}
            disableUnderline
          >
            {renderCoins(!activeTab ? coinsWithoutEur : eurCoin)}
          </Select>
        </Grid>
        <LinearProgress
          style={{ height: 3 }}
          classes={!inProgress ? { bar: classes.progressNoAnimation } : {}}
        />

        {formik.errors.amountToReceive && formik.touched.amountToReceive ? (
          <Grid className={classes.inputError}>{formik.errors.amountToReceive}</Grid>
        ) : null}

        <Grid container alignItems="center" justifyContent="center">
          {inProgress ? (
            <CircularProgress style={{ marginTop: 40 }} />
          ) : (
            <CustomButtonPrimary
              form="buySellForm"
              type="submit"
              variant="contained"
              size="large"
              className={classes.button}
            >
              Create Order
            </CustomButtonPrimary>
          )}
        </Grid>
      </form>
    </CardContent>
  );
};

export default BuySellForm;
