import { Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';
import Amount from '../../../../../../components/Amount';
import ConditionalWrapper from '../../../../../../components/ConditionalWrapper';
import { TableChart } from '../../../../../../data/repositories/dashboard/typedefs';
import { Colors } from '../../../../../../ui/style/colors';

const styles = () =>
  createStyles({
    fullHeight: {
      height: '100%',
    },
    label: {
      fontSize: 14,
      textTransform: 'uppercase',
      color: `${Colors.wildBlueYonder}`,
    },
    value: {
      fontSize: 20,
      fontWeight: 'bold',
      marginBottom: 1,
    },
  });

const useStyles = makeStyles(styles);

type Props = {
  tableChartState: TableChart;
  symbol: string;
  showTitle?: boolean;
  className?: string;
};

const DailyChange: React.FC<Props> = ({
  tableChartState,
  symbol,
  showTitle = false,
}: Props): JSX.Element => {
  const classes = useStyles();

  if (!tableChartState.data || !tableChartState.data[symbol]) {
    return <></>;
  }
  const dailyChange = tableChartState.data[symbol].dailyChange;

  return (
    <ConditionalWrapper
      condition={showTitle}
      wrapper={(children: any) => (
        <Grid
          container
          className={classes.fullHeight}
          direction="column"
          justifyContent="space-between"
        >
          <Typography className={classes.label} component="p">
            24h Change
          </Typography>
          {children}
        </Grid>
      )}
    >
      <span className={classes.value}>
        {dailyChange || dailyChange === 0 ? (
          <Amount
            amount={`${Math.abs(dailyChange).toFixed(2)}%`}
            condition={dailyChange >= 0}
            showOperator={dailyChange !== 0}
          />
        ) : null}
      </span>
    </ConditionalWrapper>
  );
};

export default DailyChange;
