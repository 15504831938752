import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import {
  GetProductsPaginatedRequest,
  GetProductsSuccessPayload,
  ProductDetail,
  SaveProductFilter,
  SaveProductTab,
} from '../../../../../../data/repositories/product/typedef';

export const GET_PRODUCTS_PAGINATED = 'product/get_products_paginated';
export const GET_PRODUCTS_PAGINATED_SUCCESS = `${GET_PRODUCTS_PAGINATED}/success`;
export const GET_PRODUCTS_PAGINATED_FAILED = `${GET_PRODUCTS_PAGINATED}/fail`;
export const EXPORT_PRODUCTS = 'csv-export/products';
export const EXPORT_PRODUCTS_SUCCESS = 'csv-export/products_success';
export const EXPORT_PRODUCTS_FAIL = 'csv-export/products_fail';
export const CLEAR_ALL_FILTERS = 'product/clear_all_filters';
export const SET_TABLE_PAGE = 'product/set_table_page';
export const TOGGLE_FILTER = 'product/toggle_filter';
export const TOGGLE_TAB = 'product/toggle_tab';
export const UPDATE_ROW = 'product/update_row';
export const asyncGetProductsPaginated = createAsyncAction(
  GET_PRODUCTS_PAGINATED,
  GET_PRODUCTS_PAGINATED_SUCCESS,
  GET_PRODUCTS_PAGINATED_FAILED,
)<GetProductsPaginatedRequest, GetProductsSuccessPayload | any, Error>();

export const clearAllFilters = createStandardAction(CLEAR_ALL_FILTERS)<undefined>();
export const setTablePage = createStandardAction(SET_TABLE_PAGE)<number>();
export const updateRow = createStandardAction(UPDATE_ROW)<ProductDetail>();
export const toggleFilter = createStandardAction(TOGGLE_FILTER)<SaveProductFilter>();
export const toggleTab = createStandardAction(TOGGLE_TAB)<SaveProductTab>();
export const asyncExportProducts = createAsyncAction(
  EXPORT_PRODUCTS,
  EXPORT_PRODUCTS_SUCCESS,
  EXPORT_PRODUCTS_FAIL,
)<undefined, undefined, Error>();
