import { connect } from 'react-redux';
import { AppState } from '../../../../state/initialState';
import { setSelectedCoin } from '../../../state/common/actions';
import { getDepositWalletAddressSelector } from '../../../state/common/selectors';
import { createWalletForCurrency } from '../state/actions';
import {
  getAllCoinsSelector,
  getSelectedCoinSelector,
  expensiveGetWalletForCurrency,
  depositModalStepSelector,
} from '../state/selectors';

import DepositModal from './DepositModal';
import { membershipStateSelector } from '../../Exchange/state/selectors';
import { hasCompletedKYCSelector } from '../../Settings/state/selectors';

const mapStateToProps = (state: AppState) => {
  const depositWalletAddress = getDepositWalletAddressSelector(state);
  const allCoins = getAllCoinsSelector(state);
  const selectedCoin = getSelectedCoinSelector(state);
  const membershipLevel = membershipStateSelector(state);
  const wallet = expensiveGetWalletForCurrency(selectedCoin)(state);
  const step = depositModalStepSelector(state);
  const hasCompletedKYC = hasCompletedKYCSelector(state);
  return {
    depositWalletAddress,
    allCoins,
    selectedCoin,
    membershipLevel,
    wallet,
    step,
    hasCompletedKYC,
  };
};

const mapDispatchToProps = {
  setSelectedCoin: setSelectedCoin,
  createWalletForCurrency: createWalletForCurrency,
};

export default connect(mapStateToProps, mapDispatchToProps)(DepositModal);
