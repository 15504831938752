import { Button, Chip, Grid, Theme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { createStyles, makeStyles } from '@material-ui/styles';
import React from 'react';
import { EmptyAC, PayloadAC } from 'typesafe-actions';
import { GetFriendsInvitationsPaginatedRequest } from '../../../../../../data/repositories/invites/typedef';
import { Colors } from '../../../../../../ui/style/colors';

const styles = (theme: Theme) =>
  createStyles({
    clearAll: {
      textTransform: 'none',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      color: theme.palette.primary.light,
    },
    clearAllIcon: {
      fontSize: 14,
      padding: 5,
      color: theme.palette.primary.light,
    },
    chip: {
      backgroundColor: `${Colors.spunPearl}`,
      '&:hover': {
        backgroundColor: `${Colors.ghost}`,
      },
      color: `${Colors.white}`,
      margin: 10,
      padding: 5,
    },
    clearChipIcon: {
      fontSize: 14,
      padding: 5,
      color: `${Colors.white}`,
    },
  });

const useStyles = makeStyles(styles);

type Props = {
  clearAllFilters: EmptyAC<'invites/clear_all_filters'>;
  appliedFiltersIds: string[];
  stateAppliedFilters: Map<string, string[]>;
  toggleFilter: PayloadAC<'invites/toggle_filters', any>;
  areFiltersApplied: boolean;
  asyncGetFriendsInvitationsPaginated: PayloadAC<
    'invites/get_friends_invitations',
    GetFriendsInvitationsPaginatedRequest
  >;
  accountId: string | null;
};

type Chip = {
  value: string;
  type: string;
};

const AppliedFiltersChips = ({
  clearAllFilters,
  appliedFiltersIds,
  stateAppliedFilters,
  toggleFilter,
  areFiltersApplied,
  asyncGetFriendsInvitationsPaginated,
  accountId,
}: Props) => {
  const classes = useStyles();

  const chips = [];
  for (const index in appliedFiltersIds) {
    const filter = stateAppliedFilters.get(appliedFiltersIds[index]);

    switch (appliedFiltersIds[index]) {
      case 'status':
        if (filter) {
          for (const i in filter) {
            chips.push({ value: filter[i], type: appliedFiltersIds[index] });
          }
        }
        break;
      case 'searchInput':
        if (filter) {
          chips.push({ value: `Affiliate email: ${filter[0]}`, type: appliedFiltersIds[index] });
        }
        break;
      case 'dateFilter':
        if (filter) {
          chips.push({
            value: `${filter[0]} - ${filter[1]}`,
            type: appliedFiltersIds[index],
          });
        }
        break;
    }
  }

  const onDelete = (chip: Chip) => {
    let newFilterValue;
    if (chip.type != 'valueRange' && chip.type != 'dateFilter') {
      const filter = stateAppliedFilters.get(chip.type);
      if (filter) {
        if (!Array.isArray(filter) || filter.length) {
          newFilterValue = undefined;
        } else {
          newFilterValue = filter.filter(f => f != chip.value);
          newFilterValue = newFilterValue.length ? newFilterValue : undefined;
        }
      }
    }
    const object: any = {};
    stateAppliedFilters.forEach((value, key) => {
      object[key] = value;
    });

    if (!newFilterValue && stateAppliedFilters.size == 1) {
      accountId &&
        asyncGetFriendsInvitationsPaginated({
          accountId: accountId,
          page: 0,
          perPage: 10,
          appliedFilters: null,
        });
      clearAllFilters();
    } else {
      accountId &&
        asyncGetFriendsInvitationsPaginated({
          accountId: accountId,
          page: 0,
          perPage: 10,
          appliedFilters: { ...object, [chip.type]: newFilterValue },
        });
      toggleFilter({ filterType: chip.type, value: newFilterValue });
    }
  };
  const renderType = (type: any) => {
    switch (type) {
      case 'completed':
        return 'Completed';
      case 'pending':
        return 'Pending';
      default:
        return type;
    }
  };

  return (
    <Grid item xs={12}>
      {chips.map(chip => (
        <Chip
          key={chip.value}
          size="small"
          clickable={true}
          deleteIcon={<CloseIcon className={classes.clearChipIcon} />}
          label={renderType(chip.value)}
          onDelete={() => onDelete(chip)}
          className={classes.chip}
        />
      ))}
      {areFiltersApplied && (
        <Button
          size="small"
          className={classes.clearAll}
          onClick={() => {
            accountId &&
              asyncGetFriendsInvitationsPaginated({
                accountId: accountId,
                page: 0,
                perPage: 10,
                appliedFilters: null,
              });
            clearAllFilters();
          }}
        >
          <CloseIcon className={classes.clearAllIcon} />
          Clear all
        </Button>
      )}
    </Grid>
  );
};

export default AppliedFiltersChips;
