import { connect } from 'react-redux';
import { asyncGetReferralsStats } from '../../../../state/actions';
import { AppState } from '../../../../state/initialState';
import { asyncGetIncomeBalances } from '../../Affiliate/state/actions';
import {
  affiliateInfoStateSelector,
  affiliateTableStateSelector,
} from '../../Affiliate/state/selectors';
import { getWalletBalance } from '../../Wallet/state/actions';
import EarningsAndAffiliates from './EarningsAndAffiliates';

const mapStateToProps = (state: AppState) => {
  const affiliateInfoState = affiliateInfoStateSelector(state);
  const affiliateTableState = affiliateTableStateSelector(state);

  return { affiliateInfoState, affiliateTableState };
};

const mapDispatchToProps = {
  asyncGetIncomeBalances: asyncGetIncomeBalances.request,
  getWalletBalance: getWalletBalance,
  asyncGetReferralsStats: asyncGetReferralsStats.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(EarningsAndAffiliates);
