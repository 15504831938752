import { connect } from 'react-redux';
import { AppState } from '../../../../../state/initialState';
import ProductCard from './ProductCard';
import {
  productTableStateSelector,
  getAllAppliedFiltersSelector,
  getAllAppliedTabSelector,
} from '../../ProductsTable/state/selectors';
import { userStateSelector } from '../../../../../state/selectors';
import { setDialogOpen, setInitialInvestValues } from '../../state/actions';
import { setInfoDialogFields } from '../../../InfoDialog/state/actions';

const mapDispatchToProps = {
  setInvestDialogOpen: setDialogOpen,
  setInitialInvestValues: setInitialInvestValues,
  setInfoDialogFields: setInfoDialogFields.request,
};

const mapStateToProps = (state: AppState) => ({
  ...productTableStateSelector(state),
  appliedFilters: getAllAppliedFiltersSelector(state),
  appliedTab: getAllAppliedTabSelector(state),
  user: userStateSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductCard);
