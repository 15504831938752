import { createReducer } from 'typesafe-actions';
import {
  IS_INFO_DIALOG_OPEN,
  SET_INFO_DIALOG_FIELDS,
  SET_INFO_DIALOG_FAIL,
  SET_INFO_DIALOG_SUCCESS,
  KYC_UPDATE_APLICANT,
  KYC_UPDATE_APLICANT_SUCCESS,
  KYC_UPDATE_APLICANT_FAIL,
} from './actions';
import { initialState } from './initialState';

export default createReducer(initialState, {
  [IS_INFO_DIALOG_OPEN]: (state, { payload }) => {
    if (payload) {
      return {
        ...state,
        open: payload,
      };
    } else {
      return initialState;
    }
  },
  [SET_INFO_DIALOG_FIELDS]: (
    state,
    {
      payload: {
        dialogProps,
        dialogType,
        title,
        hideCloseButton,
        showDialogContent = true,
        status,
      },
    },
  ) => ({
    ...state,
    open: true,
    status,
    dialogProps,
    dialogType,
    title,
    hideCloseButton,
    showDialogContent,
  }),
  [SET_INFO_DIALOG_FAIL]: (state, { payload }) => ({
    ...state,
    open: true,
    fail: true,
    success: false,
    errorMessage: payload && payload,
  }),
  [SET_INFO_DIALOG_SUCCESS]: (state, { payload }) => ({
    ...state,
    open: true,
    dialogProps: {},
    fail: false,
    errorMessage: '',
    success: true,
    successMessage: payload,
    hideCloseButton: false,
  }),
  [KYC_UPDATE_APLICANT]: state => ({
    ...state,
    inProgress: true,
    fail: false,
  }),
  [KYC_UPDATE_APLICANT_SUCCESS]: (state, { payload }) => ({
    ...state,
    inProgress: false,
    fail: false,
    status: payload,
    appError: null,
  }),
  [KYC_UPDATE_APLICANT_FAIL]: (state, { payload }) => ({
    ...state,
    inProgress: false,
    fail: true,
    ...(payload && payload.response ? { appError: payload.response.data.error } : {}),
  }),
});
