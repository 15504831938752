import { connect } from 'react-redux';
import { logout, createWalletForCurrency } from '../../state/actions';
import HomeHeader from './HomeHeader';
import { AppState } from '../../state/initialState';
import { userStateSelector } from '../../state/selectors';
import { getDepositWalletAddressSelector } from '../state/common/selectors';
import { loginSaveUserDetails } from '../../state/user/actions';
import {
  profileInfoStateSelector,
  hasCompletedKYCSelector,
} from '../HomeContent/Settings/Membership/state/selectors';
const mapDispatchToProps = {
  logout: logout,
  createWallet: createWalletForCurrency,
  loginSaveUserDetails,
};

const mapStateToProps = (state: AppState) => {
  const user = userStateSelector(state);
  const depositWalletAddress = getDepositWalletAddressSelector(state);
  const profileInfo = profileInfoStateSelector(state);
  const hasCompletedKYC = hasCompletedKYCSelector(state);

  return { user, depositWalletAddress, profileInfo, hasCompletedKYC };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeHeader);
