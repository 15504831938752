//openWithdrawDialog
import { connect } from 'react-redux';
import { AppState } from '../../../../../state/initialState';
import { isWithdrawDialogOpen, openWithdrawDialog } from '../../state/actions';
import { withdrawErrorSelector, withdrawInProgressSelector } from '../../state/selectors';
import WalletStatusMessage from './WalletStatusMessage';

const mapDispatchToProps = {
  openWithdrawDialog: openWithdrawDialog,
  isWithdrawDialogOpen: isWithdrawDialogOpen,
};
const mapStateToProps = (state: AppState) => {
  const withdrawInProgress = withdrawInProgressSelector(state);
  const withdrawError = withdrawErrorSelector(state);

  return {
    withdrawError,
    withdrawInProgress,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WalletStatusMessage);
