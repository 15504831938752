/* eslint-disable */
import { termSavingsDetailsRequest } from '../../../App/Home/HomeContent/Transactions/state/initialState';
import {
  EXPORT_TRANSACTIONS,
  EXPORT_USERS,
  GET_SWAP_INFO,
  GET_TERM_SAVINGS_DETAILS,
} from '../../../utils/constants/api';
import Http from '../../Http';
import { Transaction } from './typedef';

const getTransfers = async (endpoint: string): Promise<Transaction[]> => {
  return (await Http.get(endpoint)).data;
};

const exportUsers = async (accountId: string): Promise<string> => {
  return (await Http.get(EXPORT_USERS(accountId))).data;
};

const exportTransactions = async (accountId: string): Promise<string> => {
  return (await Http.get(EXPORT_TRANSACTIONS(accountId))).data;
};

const getTermSavingsDetails = async (
  accountId: string,
  transaction_id: string,
): Promise<termSavingsDetailsRequest> => {
  return (await Http.get(GET_TERM_SAVINGS_DETAILS(accountId), { transaction_id })).data;
};

const getWalletTransfersPaginated = async (endpoint: string) => (await Http.get(endpoint)).data;

const getSwapInfo = async (accountId: string): Promise<string> => {
  return (await Http.get(GET_SWAP_INFO(accountId))).data;
};

export default {
  getTransfers,
  exportUsers,
  exportTransactions,
  getTermSavingsDetails,
  getWalletTransfersPaginated,
  getSwapInfo,
};
