export type FeeSettingsState = {
  inProgress: boolean;
  fail: boolean;
  success: boolean;
  error: Error | null;
  storeItems: StoreItem[];
};

export type StoreItem = {
  id: string;
  name: string;
  description: string;
  code: string;
  price_in_eurocents: number;
  created_at: string;
  updated_at: {
    Time: string;
    Valid: boolean;
  };
};

export const initialState: FeeSettingsState = {
  inProgress: false,
  fail: false,
  success: false,
  error: null,
  storeItems: [],
};

export type UpdateFeeRateRequest = Omit<StoreItem, 'id' | 'created_at' | 'updated_at'>;
