import { createReducer } from 'typesafe-actions';
import { GET_USER_ACCOUNT, LOGIN_SAVE_USER_DETAILS } from './actions';
import { initialState } from './initialState';

export default createReducer(initialState, {
  [LOGIN_SAVE_USER_DETAILS]: (state, { payload }) => ({
    ...state,
    ...payload,
  }),
  [GET_USER_ACCOUNT]: (state, { payload }) => ({
    ...state,
    account: payload && payload.account && payload.account,
    roles: payload.roles,
  }),
});
