/* eslint-disable */
import { Grid, TextField, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Big from 'big.js';
import React, { useEffect } from 'react';
import { EmptyAC, PayloadAC } from 'typesafe-actions';
import CustomButtonPrimary from '../../../../../components/CustomButtons/CustomButtonPrimary';
import IOSSwitch from '../../../../../components/IOSSwitch';
import { getBalanceFromAffiliateUserRequest } from '../../../../../data/repositories/affiliate/typedefs';
import { Colors } from '../../../../../ui/style/colors';
import utils from '../../../../../utils/balanceUtils';
import useExchangeCurrencyPrices from '../../Exchange/ExchangeCurrency/useExchangeCurrencyPrices';
import { entireWalletBalanceObject } from '../../Wallet/state/initialState';
import { dialogState } from '../AffiliateTable/state/initialState';
import { SET_DIALOG_STEP, SET_USER_FOR_DIALOG } from '../state/actions';

const styles = (theme: Theme) =>
  createStyles({
    closeButton: {
      float: 'right',
      verticalAlign: 'text-top',
    },
    currencyLabel: {
      color: theme.palette.primary.light,
      fontSize: 12,
      fontWeight: 600,
      marginBottom: 10,
    },
    button: {
      fontSize: 14,
      marginTop: 30,
      marginBottom: 20,
      textTransform: 'none',
    },
    inputSymbol: {
      width: 51,
      height: 50,
      borderRadius: '0 4px 4px 0',
      backgroundColor: `${theme.palette.secondary.light}`,
      color: `${Colors.white}`,
      fontSize: 12,
      fontWeight: 600,
      display: ' flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    inputSymbolValue: {
      position: 'relative',
      float: 'left',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    value: {
      color: `${Colors.blueCharcoal}`,
      textAlign: 'center',
      marginBottom: 10,
      fontSize: 14,
    },
    currencyBalance: {
      fontSize: 12,
      fontWeight: 600,
      marginLeft: 3,
    },
    input: {
      '&::placeholder': {
        color: theme.palette.secondary.light,
      },
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'none',
      },
      '&[type=number]': {
        '-webkit-appearance': 'textfield',
        '-moz-appearance': 'textfield',
      },
    },
    escrow: {
      display: 'flex',
      paddingTop: '2rem',
    },
    switchLabel: {
      color: theme.palette.primary.light,
      fontSize: 14,
      marginLeft: 16,
    },
    box: {
      padding: '8px 16px',
      width: 260,
      height: 72,
      border: '1px solid #D9DBE9',
      boxSizing: 'border-box',
      borderRadius: 12,
      display: 'flex',
      '@media(max-width:540px)': {
        width: '100%',
      },
    },
    userBox: {
      marginRight: '16px',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      '@media(max-width:540px)': {
        marginBottom: 20,
      },
    },
    boxHeading: {
      fontWeight: 600,
      fontSize: 14,
      color: `${Colors.muledVine}`,
    },
    boxSubtitle: {
      fontWeight: 400,
      fontSize: 14,
      color: `${Colors.stormGray}`,
    },
    switch: {
      margin: 'auto auto auto 16px',
    },
  });
const useStyles = makeStyles(styles);

type Props = {
  asyncTradeSLC: PayloadAC<'affiliate/trade_slc', any>;
  asyncGetEntiretWalletBalances: EmptyAC<'walllet/get_entire_wallet_ballances'>;
  asyncGetBalanceFromAffiliateUser: PayloadAC<
    'affiliate/get_balance_from_affiliate_user',
    getBalanceFromAffiliateUserRequest
  >;
  setDialogStep: PayloadAC<typeof SET_DIALOG_STEP, number>;
  setUserForDialog: PayloadAC<typeof SET_USER_FOR_DIALOG, any>;
  entireWalletBalance: entireWalletBalanceObject;
  dialogState: dialogState;
  onChange: Function;
  isEscrow: boolean;
};

const AffilaiteAddDeduct: React.FC<Props> = ({
  dialogState,
  asyncTradeSLC,
  asyncGetEntiretWalletBalances,
  asyncGetBalanceFromAffiliateUser,
  setDialogStep,
  setUserForDialog,
  onChange,
  isEscrow,
  entireWalletBalance: {
    wallet: { balance, escrow_balance },
    currency: { decimals },
  },
}: Props) => {
  const classes = useStyles();
  const [amount, setAmount] = React.useState<number>(0);
  const [total, setTotal] = React.useState<number>(0);
  const [canTrade, setCanTrade] = React.useState<boolean>(true);
  const [comment, setComment] = React.useState<string>('');

  const { getSelectedCoinMarketVal } = useExchangeCurrencyPrices();
  const slcMarketValue = getSelectedCoinMarketVal('SLC');

  const handleSubmit = () => {
    setUserForDialog({
      amount: amount * 10000000,
      comment,
      isEscrow,
    });
    setDialogStep(1);
  };

  const handleInputChange = (amount: any) => {
    setAmount(Number(amount));
    setTotal(amount === '' ? 0 : Number(amount) * Number(slcMarketValue));

    if (dialogState.referralUser.send_to_user) {
      const walletBalanceBig = Big(
        decimals ? (balance ? utils.formatBalanceToBigWithDecimals(balance, decimals) : '0') : '0',
      );
      const walletBalanceString = utils.formatBigToStringWithCommasAndRound(walletBalanceBig, 2, 4);
      const amountToWithdrawBig = Big(amount !== '' ? amount : '0');
      const isWithdrawAllowed =
        amountToWithdrawBig.gt(0) && Big(walletBalanceString).gte(amountToWithdrawBig);

      setCanTrade(isWithdrawAllowed);
    } else {
      const amountToDeductBig = Big(amount !== '' ? amount : '0');
      const affiliateWalletBalanceBig = Big(
        dialogState.referralUser.slcBalance
          ? utils.formatBalanceToBigWithDecimals(dialogState.referralUser.slcBalance, 7)
          : '0',
      );
      const affiliateWalletBalanceBigString = utils.formatBigToStringWithCommasAndRound(
        affiliateWalletBalanceBig,
        2,
        4,
      );
      const isDeductAllowed =
        amountToDeductBig.gt(0) && Big(affiliateWalletBalanceBigString).gte(amountToDeductBig);

      setCanTrade(isDeductAllowed);
    }
  };

  useEffect(() => {
    if (dialogState.referralUser.send_to_user) {
      asyncGetEntiretWalletBalances();
    } else {
      asyncGetBalanceFromAffiliateUser({
        balanceSymbol: 'slc',
        user_id: dialogState.referralUser.id
          ? dialogState.referralUser.id
          : dialogState.referralUser.referral_id || '',
      });
    }
  }, []);
  const {
    referralUser: { first_name, last_name, email },
  } = dialogState;
  return (
    <>
      <Grid container spacing={4}>
        <Grid className={classes.escrow} item xs={12}>
          <Grid item className={`${classes.box} ${classes.userBox}`}>
            <Typography className={classes.boxHeading}>
              {first_name} {last_name}
            </Typography>
            <Typography className={classes.boxSubtitle}>{email}</Typography>
          </Grid>
          {dialogState.referralUser.send_to_user && (
            <Grid item className={classes.switch}>
              <IOSSwitch
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  onChange(event.target.checked)
                }
                checked={isEscrow}
              />
              <Typography className={classes.switchLabel} display="inline" component="p">
                Switch to add SLC to {isEscrow ? 'balance' : 'escrow'}
              </Typography>
            </Grid>
          )}
        </Grid>

        <Grid item xs={6}>
          <Typography className={classes.currencyLabel}>Enter amount</Typography>
          <TextField
            variant="outlined"
            type="number"
            className={classes.value}
            placeholder="0"
            fullWidth
            onChange={event => handleInputChange(event.target.value)}
            InputProps={{
              classes: {
                input: classes.input,
              },
              style: {
                height: 50,
                paddingRight: 0,
              },
              endAdornment: <div className={classes.inputSymbol}>SLC</div>,
            }}
          />

          <Grid container item direction="row">
            <Typography className={classes.currencyLabel}>SLC Balance:</Typography>

            {dialogState.referralUser.send_to_user ? (
              <Typography color="secondary" className={classes.currencyBalance}>
                {balance ? utils.formatBalanceToString(balance, decimals, 2, 2) : '0.00'}
              </Typography>
            ) : (
              <Typography color="secondary" className={classes.currencyBalance}>
                {dialogState.referralUser.slcBalance
                  ? utils.formatBalanceToString(dialogState.referralUser.slcBalance, 7, 2, 2)
                  : '0.00'}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.currencyLabel}>Total</Typography>
          <TextField
            disabled
            variant="outlined"
            className={classes.value}
            placeholder="0.00"
            fullWidth
            value={total > 0 ? total : ''}
            InputProps={{
              style: {
                height: 50,
                paddingRight: 0,
              },
              classes: {
                input: classes.input,
              },
              endAdornment: <div className={classes.inputSymbol}>EUR</div>,
            }}
          />
        </Grid>
        {!dialogState.referralUser.send_to_user && (
          <Grid item xs={12} style={{ paddingTop: 0 }}>
            <Typography className={classes.currencyLabel}>Comment</Typography>
            <TextField
              variant="outlined"
              className={classes.value}
              fullWidth
              value={comment}
              onChange={event => setComment(event.target.value)}
            />
          </Grid>
        )}
      </Grid>
      <CustomButtonPrimary
        type="submit"
        className={classes.button}
        variant="contained"
        fullWidth
        onClick={handleSubmit}
        disabled={!canTrade || dialogState.inProgress || amount <= 0}
      >
        Confirm
      </CustomButtonPrimary>
    </>
  );
};

export default AffilaiteAddDeduct;
