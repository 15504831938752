import { connect } from 'react-redux';
import { AppState } from '../../../../state/initialState';
import { asyncGetProductsPaginated, clearAllFilters, toggleFilter } from '../state/actions';
import {
  getAllAppliedFiltersSelector,
  getAllAppliedTabSelector,
  getBackerMax,
  getTimeLeftMax,
} from '../state/selectors';
import ProductFilterBar from './ProductFilterBar';
import { userStateSelector } from '../../../../state/selectors';

const mapStateToProps = (state: AppState) => {
  const appliedFilters = getAllAppliedFiltersSelector(state);
  const appliedTab = getAllAppliedTabSelector(state);
  const backerMax = getBackerMax(state);
  const timeLeftMax = getTimeLeftMax(state);
  return { appliedFilters, appliedTab, backerMax, timeLeftMax, user: userStateSelector(state) };
};

const dispatchStateToProps = {
  asyncGetProductsPaginated: asyncGetProductsPaginated.request,
  clearAllFilters: clearAllFilters,
  toggleFilter,
};

export default connect(mapStateToProps, dispatchStateToProps)(ProductFilterBar);
