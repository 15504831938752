import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import LocalStorage from '../../../../../../data/LocalStorage';
import api from '../../../../../../data/repositories/admin';
import { USER_ID } from '../../../../../../utils/constants/localStorageKeys';
import {
  asyncGetStoreItems,
  asyncUpdateFeeRate,
  GET_STORE_ITEMS,
  UPDATE_FEE_RATE,
} from './actions';

export function* getStoreItems$() {
  try {
    const userId = LocalStorage.get(USER_ID);
    const result = yield api.getStoreItems(userId);

    yield put(asyncGetStoreItems.success(result));
  } catch (error) {
    yield put(asyncGetStoreItems.failure(error));
  }
}

export function* updateFeeRate$(action) {
  try {
    const response = yield api.updateFeeRate(action.payload.storeItem, action.payload.id);

    yield put(asyncUpdateFeeRate.success(response));
    yield getStoreItems$();
  } catch (error) {
    yield put(asyncUpdateFeeRate.failure(error));
  }
}

export default function* () {
  yield all([takeEvery(UPDATE_FEE_RATE, updateFeeRate$)]);
  yield all([takeLatest(GET_STORE_ITEMS, getStoreItems$)]);
}
