import {
  TransactionGrouping,
  Filter,
  TransactionGroupingStats,
} from '../../../../../../data/repositories/admin/typedefs';

export type TransactionGroupingState = {
  transactions: TransactionGrouping;
  inProgress: boolean;
  fail: boolean;
  error: string | null;
  stats: TransactionGroupingStats;
  page: number;
  perPage: number;
  appliedFilters: Filter;
  exportInProgress: boolean;
};

export const initialState: TransactionGroupingState = {
  transactions: [],
  inProgress: false,
  fail: false,
  error: null,
  page: 0,
  perPage: 10,
  stats: {
    totalRecords: null,
  },
  appliedFilters: new Map<string, string[]>(),
  exportInProgress: false,
};
