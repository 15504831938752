import { LOGIN_ENDPOINT } from './api';

export const ROOT_ROUTE = '/';
export const AUTH_ROUTE = '/auth';
export const LOGIN_ROUTE = `${AUTH_ROUTE}/login`;
export const LOGIN_ROUTE_TWO_FACTOR_AUTH = `${LOGIN_ROUTE}/2fa`;
export const LOGIN_ROUTE_SINGLE = `${LOGIN_ROUTE}/single`;
export const LOGIN_ROUTE_SINGLE_INFO = `${LOGIN_ROUTE_SINGLE}/info`;
export const LOGIN_ROUTE_SINGLE_2FA = `${LOGIN_ROUTE_SINGLE}/2fa`;
export const REGISTER_ROUTE = `${AUTH_ROUTE}/register`;
export const REGISTER_INFO_ROUTE = `${REGISTER_ROUTE}/info`;
export const REGISTER_PASSWORD_ROUTE = `${REGISTER_ROUTE}/password`;
export const REGISTER_AFFILIATE_ROUTE = `${REGISTER_ROUTE}/affiliate`;
export const CALLBACK_URL_QUERY_PARAM = 'callback_url';
export const APP_DASHBOARD_ROUTE = '/dashboard';
export const WALLET_ROUTE = '/wallet';
export const TRANSACTIONS_ROUTE = '/transactions';
export const AFFILIATE_ROUTE = '/affiliate';
export const ADMIN_PANEL_ROUTE = '/admin';
export const SETTINGS_ROUTE = '/settings';
export const EARNINGS_ROUTE = '/earnings';
export const ORDERS_ROUTE = '/orders';
export const PRODUCTS_ROUTE = '/products';
export const EDIT_PRODUCT_ROUTE = `${PRODUCTS_ROUTE}/:id`;
export const PRODUCT_ROUTE = `${PRODUCTS_ROUTE}/product`;
export const EXCHANGE_ROUTE = '/exchange';
export const EXCHANGE_DETAILS_ROUTE = `${EXCHANGE_ROUTE}/details`;
export const EXCHANGE_CREDIT_CARD_ROUTE = `${EXCHANGE_ROUTE}/card`;
export const EXCHANGE_CONFIRMATION_ROUTE = `${EXCHANGE_ROUTE}/confirmation`;
export const EXCHANGE_SUCCESS_ROUTE = `${EXCHANGE_ROUTE}/success`;
export const WALLET_COIN_ROUTE = `${WALLET_ROUTE}/:coin`;
export const RESET_PASSWORD_ROUTE = `${AUTH_ROUTE}/reset-passsword`;
export const RESET_PASSWORD_INFO_ROUTE = `${RESET_PASSWORD_ROUTE}/info`;
export const RESET_PASSWORD_SUBMITTED_ROUTE = `${RESET_PASSWORD_ROUTE}/submitted`;
export const RESET_PASSWORD_NEW_PASSWORD_ROUTE = `${RESET_PASSWORD_ROUTE}/new-password`;
export const EMAIL_CONFIRMATION = `${AUTH_ROUTE}/email-confirmation`;
export const EMAIL_SUCCESS = `${AUTH_ROUTE}/email-status`;
export const DASHBOARD_ROUTE = `/`;
export const TERMS_OF_USE_ROUTE = '/terms-of-use';
export const PRIVACY_POLICY_ROUTE = '/privacy-policy';
export const TERMS_OF_USE_ROUTE_SL_GROUP = '/terms';
export const PRIVACY_POLICY_ROUTE_SL_GROUP = '/privacy';
export const MY_INVITES = '/invites';
export const PROXY = '/proxy';
export const AUTOLOGIN = '/autologin';

export const KYC_ROUTE = `${AUTH_ROUTE}/kyc`;
export const KYC_START_VERIFICATION_ROUTE = `${KYC_ROUTE}/verification`;
export const KYC_PROFILE_ROUTE = `${KYC_ROUTE}/profile`;
export const KYC_ADDRESS_ROUTE = `${KYC_ROUTE}/address`;
export const KYC_TAX_ROUTE = `${KYC_ROUTE}/tax`;
export const KYC_VERIFY_ID_ROUTE = `${KYC_ROUTE}/verify`;
export const KYC_STATUS_ROUTE = `${KYC_ROUTE}/status`;

export const IAM_REGISTER_ROUTE = process.env.REACT_APP_IAM_ROUTE as string;

export const REACT_APP_SACRET_LIFE_LINK =
  process.env.REACT_APP_SACRET_LIFE_LINK || 'https://v3.sacret-life.com';

  
export const SLAFF_URL = `${process.env.REACT_APP_HOST}/autologin`;

//Redirection urls
const IAM_AUTH_URL = `${process.env.REACT_APP_IAM_ROUTE}/auth`;
const REAL_ESTATE_AUTH_URL = `${process.env.REACT_APP_REAL_ESTATE_ROUTE}/auth`;
export const REDIRECT_TO_KYC = `${process.env.REACT_APP_IAM_ROUTE}/kyc`;
// export const REDIRECT_TO_REAL_ESTATE = `${process.env.REACT_APP_REAL_ESTATE_ROUTE}${LOGIN_ENDPOINT}?response_type=code&client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${REAL_ESTATE_AUTH_URL}&scope=any`;
export const REDIRECT_TO_REAL_ESTATE = `${process.env.REACT_APP_IDENTITY_API_URL}${LOGIN_ENDPOINT}?response_type=code&client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${REAL_ESTATE_AUTH_URL}&scope=any`;

export const BECOME_AN_AFFILIATE_URL = `${REACT_APP_SACRET_LIFE_LINK}/pricing/slaff/?redirect_url=${SLAFF_URL}`;
