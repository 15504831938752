import React from 'react';
import { SUPPORT_EMAIL } from '../../../utils/constants/info';
import '../Dashboard.scss';
import DashboardFooter from '../DashboardFooter';
import DashboardHeader from '../DashboardHeader';
import '../index.css';
import '../js/scripts.js';

const PrivacyPolicy = (): JSX.Element => (
  <div className="dashboard">
    <DashboardHeader />
    <div className="pages">
      <div className="header-wrapper">
        <div className="content">
          <h1>Personal Data Protection Policy</h1>
        </div>
      </div>
      <div className="content-wrapper">
        <div className="content">
          <div className="version">
            <p>Version: 1.0</p>
            <p>Valid from: 1 October 2018</p>
          </div>
          <h2>1. INTRODUCTION</h2>
          <p>
            SL enterprises respects your right to privacy and takes your personal data protection
            extremely seriously. We undertake to process your personal data with care and
            responsibility.
          </p>
          <p>
            This Personal Data Protection Policy (hereinafter referred to as: Policy) defines how
            your data is collected, purposes for which we collect it, parties we share your data
            with, and your rights regarding the protection of personal data.
          </p>
          <h2>2. CONTROLLER OF PERSONAL DATA</h2>
          <p>
            This Policy applies to all personal data collected and stored by SL enterprises EOOD,
            Opalchenska str. 46–48, fl.7, Sofia 1303, Bulgaria (hereinafter referred to as SL
            enterprises, We, Us).
          </p>
          <p>
            As a data controller, SL enterprises is responsible for processing and storage of your
            personal data.
          </p>
          <p>
            If you have any questions regarding data privacy or this Policy, or you wish to enforce
            any of your data protection rights, please contact us at the email listed below.
          </p>
          <p>
            Please contact us at: <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
          </p>
          <h2>3. POLICY APPLICATION</h2>
          <p>This Policy applies to:</p>
          <ol>
            <li>
              all users of our <a href="#">website</a> (hereinafter referred to as: website)
            </li>
            <li>registration on our website and use of your personal account</li>
            <li>
              any enquiries you make, regardless of the communication channel, such as e-mail,
              online form or telephone
            </li>
            <li>use of our online store (whether as a guest or as a registered user)</li>
            <li>use of our video platform, available on our website</li>
            <li>registration and participation on our webinars</li>
            <li>use of social media plug-ins, available on our website</li>
          </ol>
          <h2>4. DEFINITIONS</h2>
          <p>This section explains the basic terms used throughout our Policy.</p>
          <p>
            <strong>Personal data</strong> means any information that refers to a specific or
            identifiable individual (for example, name, surname, e-mail address, telephone number
            and identifiers that are specific to the individual’s physical, physiological, genetic,
            economic, mental, cultural or social identity, etc.).
          </p>
          <p>
            <strong>Controller</strong> means a legal entity that determines the purposes and means
            of processing of your personal data.
          </p>
          <p>
            <strong>Processor</strong> means a legal or natural person who processes personal data
            on behalf of the controller.
          </p>
          <p>
            <strong>Processing</strong> means collecting, storing, accessing and all other forms of
            use of personal data.
          </p>
          <p>
            <strong>Security incident</strong> means any intentional or unintentional interference,
            alteration, destruction, erasure or any other unauthorised access to personal data
            processed by the Data Controller.
          </p>
          <p>
            <strong>EEA</strong> means the European Economic Area, which includes all Member States
            of the European Union, Iceland, Norway and Liechtenstein.
          </p>
          <h2>
            5. PROCESSING OF PERSONAL DATA
            <br />
            (category of personal data, processing purposes and legal basis)
          </h2>
          <h3>5.1. General information regarding personal data processing</h3>
          <h4>Data collecting and processing purposes</h4>
          <p>
            SL enterprises processes your personal data for clearly stated and legitimate purposes,
            explained in detail below. Your personal data will not be processed for any purposes not
            defined in this Policy. Additionally, we will not process your personal data in a manner
            not compatible with the processing purposes below.
          </p>
          <p>
            We collect your personal data when you provide it to us (for example, using our website,
            signing up for our webinar, enquiring by e-mail or telephone).
          </p>
          <p>
            We also obtain your personal data through your use of the website. In order to gain this
            data, we use cookies and related technologies, described in detail in Section 9 below
            [JGI1]. This also applies for data (email addresses) collected through Google, Microsoft
            and Yahoo services.
          </p>
          <p>Categories of data we gather from you:</p>
          <ol>
            <li>identification data (first name, last name, company name, VAT number)</li>
            <li>contact data (telephone number, e-mail, address)</li>
            <li>additional information you provide (birth date, interests, etc.)</li>
            <li>data needed for processing of your purchase (data on payment instrument etc.)</li>
            <li>
              Categories of data collected through your use of services includes different
              categories related to your activity on our website and/or online store.
            </li>
          </ol>
          <p>
            If there is a need to process personal data for any additional purposes, we shall notify
            you about such processing in advance. Should a consent be necessary for such processing,
            we shall notify you and ask for your consent before processing personal data.
          </p>
          <h4>Legal basis for data processing</h4>
          <h4>We may process your personal data on these legal bases:</h4>
          <p>
            <strong>Contractual relationship.</strong> We process your personal data on the basis of
            a contractual relationship that you enter into with us (such as ordering a product from
            our online store).
          </p>
          <p>
            <strong>Legitimate interest.</strong> We process your personal data when SL enterprises
            has a legitimate interest in processing. You can find more information regarding
            legitimate interest in Section 5.2 below.
          </p>
          <p>
            <strong>Consent.</strong> We process your personal data when you have given consent for
            the specific purposes of processing. You are always entitled to withdraw that consent.
          </p>
          <p>
            <strong>Legal obligation.</strong> We process your personal data when we are obliged to
            do so by law.
          </p>
          <p>
            Providing personal data is obligatory only when providing personal data is demanded by
            law. Providing personal data on any other legal basis (see above) is voluntary.
          </p>
          <p>
            At this point, we wish to inform you that if you refuse to provide certain personal
            data, we may be unable to provide you with some services we offer (e.g., if you refuse
            to provide us with your e-mail, we can not send you the newsletter; if you fail to
            provide us with your address, we can not deliver the ordered goods).
          </p>
          <h3>5.2. Information on processing of your personal data</h3>
          <p>
            We process your personal data for the purposes explained below. SL enterprises may
            process your personal data for one or several of the processing purposes.
          </p>
          <p>
            For further clarification we divided the processing purposes into three sections: the
            first section includes general processing purposes related to the use of our website;
            the second section refers to the processing purposes related to account registration on
            our website; and the third section presents processing purposes related to the online
            store.
          </p>
          <p>a. General processing purposes [JGI2]</p>
          <p>
            <strong>Notifications about our products and services (sending newsletters).</strong> In
            order to send these notifications, we process the following categories of personal data:
            first name, last name, e-mail, categories of interest. We send these notifications on
            the basis of our legitimate interest to ensure a successful and longstanding
            relationship with our clients, and to optimise our business activities. When sending
            notifications, we use certain marketing tools, allowing us to personalise the content
            and extract statistical data from sent newsletters (such as checking if the recipient
            opened the newsletter and/or read any of the contents, etc.). We will use these
            marketing tools only if you consent.
          </p>
          <p>
            <strong>
              personalised notifications about products and services (sending personalised
              newsletters).
            </strong>{' '}
            When sending notifications, we will be using certain marketing tools, enabling us to get
            statistical data and also personalise content of the newsletter in order to make our
            newsletter more relevant for you. We will use personalised notification only on the
            basis of your prior and explicit consent.
          </p>
          <p>
            <strong>
              Communication with individuals on the basis of your enquiries, regardless of the
              communication channel (e-mail, telephone number, web form).
            </strong>{' '}
            During communication, we may process different categories of personal data, as this
            depends on the type of the enquiry. However, we always process these categories:
            communication channel used by the individual to contact us and identification data (such
            as name, surname). We collect and process personal data on the basis of our legitimate
            interest to guarantee quality information about our products and services. This also
            applies for data (email addresses) collected through Google, Microsoft and Yahoo
            services. On your request, we use email address from your contact lists and text forms
            you have created to send emails. Data gathered is stored and visible to you only for
            statistical purposes.
          </p>
          <p>
            <strong>Providing free digital materials.</strong> When [JGI3] providing digital
            materials to individuals, we collect these categories of personal data: e-mail address.
          </p>
          <p>
            <strong>Conducting user satisfaction surveys.</strong> Categories of personal data
            processed differ depending on the subject of the survey; however, we always process your
            e-mail address. We conduct user satisfaction surveys on the basis of our legitimate
            interest to improve and optimise our business activities.
          </p>
          <p>
            <strong>Conducting digital marketing.</strong> We use different marketing tools to
            promote our products and services, such as remarketing and tracking. We also gather data
            about your activity on our website. We use digital marketing solely upon your explicit
            and prior consent.
          </p>
          <p>
            <strong>Gathering data for statistical analyses.</strong> In order to conduct
            statistical analyses, we gather data about activities of users of our website, such as
            number of visits, dates of visits, etc. We process and store this data in aggregated and
            anonymous way. Statistical analyses are conducted on the basis of our legitimate
            interest to provide an efficient and optimised website.
          </p>
          <p>
            <strong>Transmission of personal data to third parties.</strong> We transmit your
            personal data solely to third parties listed in Section 7 of this Policy.
          </p>
          <p>
            <strong>To enforce any legal claims and to settle disputes.</strong> In order to protect
            our business activities and/or protect our rights, we may disclose your personal data.
            We disclose your personal data only in accordance with the law.
          </p>
          <p>b. Processing purposes, regarding registration of personal account on our website</p>
          <p>
            <strong>Automatic registration of your personal account.</strong>
          </p>
          <p>
            When you buy something in our online store, buy (or gain) access to our webinars or
            seminars on our video platform, a personal account will be automatically created for you
            in order to enable you to use our services quickly and easily. You can view and manage
            your account at this link. The automatically created account will be deleted after
            twelve months of non-use.
          </p>
          <p>
            However, you can choose to register before making a purchase, using this link. In order
            to create an account for you, we will process these categories of personal data: first
            name, last name, e-mail address, password, date of birth*. The data marked with a [*] is
            voluntary and you can submit it if you choose to do so.
          </p>
          <p>
            Registration is still possible if you do not provide this data. We will process your
            data on the basis of your prior and explicit consent.
          </p>
          <p>
            If you choose to provide your date of birth, we will be able to send you a small
            birthday present each year as a token of our gratitude for being our member. We process
            your personal data for registration and use of your personal account on the basis of
            your explicit and prior consent. We also process data about your date of birth on the
            basis of your prior and explicit consent.
          </p>
          <p>
            <strong>Enabling the use your personal account.</strong>
          </p>
          <p>
            After the registration (see point above), you will be able to use your account in order
            to change your data or add new data if you choose to do so. You can add these categories
            of new data: [vstaviti katere kategorije lahko dodajo ko urejajo svoj profil]. We will
            process this data on the basis of our legitimate interest to ensure our clients can use
            their accounts effectively. We will process this data for the purpose of digital
            marketing solely with your consent (see section “Conducting digital marketing” above).
          </p>
          <p>
            <strong>Use of our video platform, including payments.</strong> When you use our video
            platform, a personal account is automatically generated for you. For information on
            processing personal data, please see the detailed explanation in section “Automatic
            registration of your personal account” above. We process your personal data on the basis
            of a contractual relationship.
          </p>
          <p>
            <strong>
              Processing your applications to access our webinars, including payments.
            </strong>{' '}
            When you buy access to our webinars, a personal account is automatically generated for
            you. For information on processing personal data, please see the detailed explanation in
            section “Automatic registration of your personal account” above. We process your
            personal data on the basis of a contractual relationship. In order to access a webinar,
            you need to provide the following data: [vstaviti tiste osebne podatke, ki jih
            posameznik vpiše, da lahko gleda webinar (torej po nakupu]. We process this data to
            verify authenticity of the user on a legal basis of our legitimate interest to prevent
            frauds and payment defaults. We provide webinars for you in cooperation with external
            processors.
          </p>
          <p>c. Processing purposes, regarding online store</p>
          <p>
            <strong>
              Enabling the use of the online store accessible on our website (using online store
              with personal account).
            </strong>{' '}
            In order to fulfil an online store order made by an individual with a personal account,
            we process these categories of personal data: first name, last name, company name (when
            applicable), e-mail, address, country, city, postal code, mobile number, as well as
            personal data you provided for your personal account. We process your personal data on
            the basis of a contractual relationship.
          </p>
          <p>
            <strong>
              Conducting statistical analyses regarding customer data, orders and potential buyers.
            </strong>{' '}
            When conducting statistical analyses, we gather different categories of personal data:
            date and number of visits, cart history, etc. We conduct statistical analyses on the
            basis of our legitimate interest to ensure effective and optimal online store for our
            users.
          </p>
          <h3>5.3. Storage period</h3>
          <p>We will store your personal data:</p>
          <ol>
            <li>
              only as long as it is absolutely necessary to fulfil the purposes of processing,
            </li>
            <li>for a period prescribed by the law,</li>
            <li>
              for the period necessary for the fulfilment of the contract, including any guarantees
              and/or assurance periods, and any period in which it is possible to enforce any claims
              on the basis of a concluded contract.
            </li>
            <li>
              Personal data gathered on the basis of your consent is stored permanently, until you
              withdraw your consent. We will delete your personal data even if you don’t withdraw
              your consent if the processing purpose is fulfilled.
            </li>
          </ol>
          <p>
            After the expiration of the retention period, we will delete or anonymised the personal
            data so that the reconstruction of personal data will no longer be possible.
          </p>
          <p>Retention periods for each category of personal data are defined in Annex 1 below.</p>
          <p>
            Should you have any questions, please contact us using any of the contact details listed
            in Section 2 of this Policy.
          </p>
          <h2>6. PROTECTION OF YOUR PERSONAL DATA</h2>
          <p>
            SL enterprises protects your personal data against (intentional or unintentional)
            destruction, damage, erasure and any other form of unauthorised access and/or
            processing. In order to ensure safety of your personal data, we have implemented several
            technical and organisational measures, related to our technological capabilities and
            your privacy rights.
          </p>
          <p>Amongst others, we use technical and organisational measures described below:</p>
          <ol>
            <li>restricting access to personal data</li>
            <li>
              regular maintenance and updates of our hardware and software, where the personal data
              is stored
            </li>
            <li>making safety copies of personal data to prevent loss of data</li>
            <li>supervising all persons that have access to personal data</li>
            <li>
              establishing protocols to prevent or minimise damage in the event of potential
              security incidents
            </li>
            <li>
              In case of a security incident, we shall report such an incident immediately to the
              competent supervisory authority.
            </li>
          </ol>
          <p>
            In the event of a suspected security incident that might constitute a criminal offence,
            we will notify the police and competent state prosecutor’s office.
          </p>
          <p>
            In the event of a violation of data protection that may represent a high risk to the
            rights and freedoms of individuals, we will inform you of such an event without undue
            delay.
          </p>
          <h2>7. TRANSFER OF PERSONAL DATA</h2>
          <p>
            In order to fulfil the processing purposes (the list of processing purposes is available
            here [JGI5]), we transmit data to third parties. We transmit your data solely to third
            parties listed below. These parties are not allowed to process your personal data for
            any other purposes other than those that the data was gathered for.
          </p>
          <p>
            All third parties that we transmit personal data to are obligated to respect data
            protection legislation and this Policy. Our relationship with these third parties is
            further defined by contractual agreements.
          </p>
          <p>We transmit your personal data to: [JGI6]</p>
          <ol>
            <li>
              <strong>Our external processors.</strong> They are responsible for the needs of SL
              enterprises (such as accountants, law firms, marketing companies, platforms enabling
              access to our webinars, etc.)
            </li>
            <li>
              <strong>
                Our partners, who are selling products and services on our online store.
              </strong>{' '}
              We transmit to our partners only the categories of personal data necessary for them to
              execute orders.
            </li>
            <li>
              Our affiliates, who are involved in marketing activities of the web site and online
              store. We transmit to our affiliates only the categories of personal data necessary to
              fulfil obligations of contractual relationship with us.
            </li>
            <li>
              When transmission of data is obligatory under the law (such as tax bodies, courts of
              law, etc.).
            </li>
          </ol>
          <p>
            In certain circumstances, we transmit your personal data outside of the European
            Economic Area. When executing such transfers, we ensure that the security level of your
            personal data stays the same.
          </p>
          <h2>8. WEB PLUG-INS AND ACCESS TO SOCIAL NETWORKS</h2>
          <p>
            On our website we provide you with options to use buttons of the following social
            networks: Facebook, LinkedIn, Google+, Instagram, Twitter. [JGI7]
          </p>
          <p>
            All above-mentioned social networks operate in accordance with their terms of use and
            privacy policies, which define the use of personal data for each social network.
          </p>
          <p>Privacy policies are available at the links provided below:</p>
          <ol>
            <li>
              <a target="_blank" rel="noreferrer" href="https://www.facebook.com/about/privacy/">
                Facebook
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/legal/privacy-policy"
              >
                LinkedIn
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://policies.google.com/privacy?hl=en&gl=ZZ"
              >
                Google (Google+)
              </a>
            </li>
          </ol>
          <p>
            Any use of social media enabled on our website is in the sole responsibility of each
            individual. Every individual is obligated to address any questions and/or claims
            directly to the relevant social media.
          </p>
          <p>SL enterprises does not assume any responsibility for use of social networks.</p>
          <h2>9. COOKIES</h2>
          <p>
            Our website uses cookies. Cookies are simple computer files containing text. The
            information stored in cookies can be used to personalise the experience when using a
            website. You can change your cookie settings in your browser.
          </p>
          <p>Our website uses several types of cookies, as listed below:</p>
          <ol>
            <li>
              Necessary cookies. Necessary cookies let you navigate the website and login to your
              secure account. These cookies don&apos;t gather any information about you that could
              be used to identify you or for marketing or remembering where you&apos;ve been on the
              internet.
            </li>
            <li>
              Performance cookies. Performance cookies collect information about how you use the
              website. We use performance cookies to provide statistics on how our website is used,
              and help us improve the website by logging any errors that occur and test different
              designs of our website.
            </li>
            <li>
              Functionality cookies. Functionality cookies are used to provide services or to
              remember settings to improve your visit. We use functionality cookies to remember
              settings you&apos;ve applied, provide proactive live chat sessions, and to show you
              when you&apos;re logged in to the website.
            </li>
            <li>
              Marketing cookies. Marketing cookies are used to show you personalised ads that are
              relevant for you. We also use marketing cookies to analyse effectiveness of the ads.
              We use first-party marketing cookies (our own) and third party-marketing cookies
              installed by other parties.
            </li>
          </ol>
          <p>
            SL enterprises respects your privacy. Accordingly, we will only use cookies (except for
            necessary cookies, as they are needed to ensure proper functioning of the website) if
            you consent to their use. You will receive a request for consent when you first visit
            our website. You can always withdraw your consent by contacting us at{' '}
            <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>.
          </p>
          <h2>10. RIGHTS OF INDIVIDUALS</h2>
          <p>You have the following rights regarding personal data processing:</p>
          <p>
            a. Access to personal data: On your request, we will provide you with a notification
            whether we process personal data about you and, should you request it, enable you to
            access your personal data and provide information about the processing (which data is
            processed and from where this data originated).
          </p>
          <p>
            b. Correction of personal data: On your request, we will correct or complete your
            incomplete or inaccurate data being processed.
          </p>
          <p>
            c. Restriction of personal data processing: On your request, we will restrict processing
            of your personal data. This right can be exercised only in the following situations:
          </p>
          <ul>
            <li>When you contest the accuracy of the personal data we process.</li>
            <li>
              When you oppose the erasure of the personal data, despite the fact the data was
              processed unlawfully.
            </li>
            <li>When you need the personal data in order to exercise a legal claim.</li>
            <li>When you object to the processing of personal data (see point f below).</li>
          </ul>
          <p>
            d. Deletion of personal data: On your request, we will delete your personal data (we can
            not delete the personal data we keep on the basis of legal requests or contractual
            relations).
          </p>
          <p>
            e. Printout of personal data: On your request, we will provide you with the personal
            data that you have provided us with in a structured, commonly used and machine-readable
            form.
          </p>
          <p>
            f. Revocation of consent: You have the right to withdraw your consent for processing of
            your personal data, which we collect and process on the basis of consent, at any time.
            The consent may be withdrawn by contacting us using the contact information provided in
            Section 2 of this Policy. A withdrawal of consent will have no negative consequences;
            however, SL enterprises might no longer be able to provide you with certain services.
          </p>
          <p>
            g. Objection to personal data processing: You have the right to object to the processing
            of your personal data, when processing is for direct marketing purposes or if your
            personal information is transmitted to third parties for the purposes of direct
            marketing. You can also object to processing when your data is used for direct marketing
            purposes using customised or individual offers (“profiling”). You can submit an
            objection in any manner defined in Section 2 of this Policy.
          </p>
          <p>
            h. The right to data transmission: You have the right to request the printout of
            personal data that you have provided to us. We will provide you with the information in
            a structured, widely used and machine-readable form. You are entitled to provide this
            data to another controller of your choice. Where technically feasible, you may request
            that your personal data be transmitted directly to another controller.
          </p>
          <h3>Contacts for exercising your rights:</h3>
          <p>
            If you have any questions regarding the application of this Policy or regarding
            exercising your rights arising from this Policy, please contact us at{' '}
            <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>.
          </p>
          <p>
            You have the right to file a complaint against us with the Information Commissioner, who
            is the competent authority for the protection of personal data.
          </p>
          <p>
            Please notify us of any changes of your personal data using the above contact
            information. We will take care of the correction of your personal data as soon as
            possible.
          </p>
          <p>
            If you are exercising any of the above rights, we may require additional personal data
            (such as name, surname, e-mail address) for identification purposes. We will only need
            additional information when the information you provide is not sufficient for reliable
            identification (we want to prevent your personal data from being transmitted to a third
            party due to unreliable identification).
          </p>
          <h2>11. FINAL PROVISIONS</h2>
          <p>
            At SL enterprises, we reserve the right to change this Policy at any time. We shall
            notify you of the change of the Policy on our website. If you continue to use our
            website and other services defined by this Policy after the new version enters into
            force, we shall consider that you agree with the new version of this Policy.
          </p>
          <p>
            The current version of this Policy is available on our website at this{' '}
            <a href="#">link</a>.
          </p>
          <p>Annex 1: Definition of retention periods</p>
          <table>
            <thead>
              <tr>
                <th>Processing purpose</th>
                <th>Legal basis</th>
                <th>Retention period</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Notifications about our products and services (sending newsletters)</td>
                <td>Legitimate interest</td>
                <td>Until revoked</td>
              </tr>
              <tr>
                <td>
                  Sending personalised notifications about products and services (sending
                  personalised newsletters)
                </td>
                <td>Consent</td>
                <td>Until revoked</td>
              </tr>
              <tr>
                <td>Communication to provide quality responses to your enquiries</td>
                <td>Legitimate interest</td>
                <td>1 month[JGI8] after the end of communication</td>
              </tr>
              <tr>
                <td>Providing free digital materials</td>
                <td>Consent</td>
                <td>Until revoked</td>
              </tr>
              <tr>
                <td>Conducting user satisfaction surveys</td>
                <td>Legitimate interest</td>
                <td>12 months[JGI9]</td>
              </tr>
              <tr>
                <td>Conducting digital marketing</td>
                <td>Consent</td>
                <td>Until revoked</td>
              </tr>
              <tr>
                <td>Gathering data for statistical analyses</td>
                <td>Legitimate interest</td>
                <td>12 months[JGI10]</td>
              </tr>
              <tr>
                <td>Enabling registration and use of your personal account</td>
                <td>Consent</td>
                <td>Until revoked</td>
              </tr>
              <tr>
                <td>Use of our video platform, including payments</td>
                <td>Contractual relationship</td>
                <td>/</td>
              </tr>
              <tr>
                <td>Processing your applications to our webinars, including payments</td>
                <td>Contractual relationship</td>
                <td>/</td>
              </tr>
              <tr>
                <td>
                  Enabling the use of the online store accessible on our <a href="#">website</a>
                </td>
                <td>Contractual relationship</td>
                <td>/</td>
              </tr>
              <tr>
                <td>
                  Conducting statistical analyses regarding customer data, orders and potential
                  buyers
                </td>
                <td>Legitimate interest</td>
                <td>12 months [JGI11]</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <DashboardFooter />
  </div>
);

export default PrivacyPolicy;
