/* eslint-disable */
import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import {
  SelectedUser,
  User,
  SaveFilter,
  ExportUserTransactionHistoryRequest,
  GetPaginatedUserTransactionHistoryRequest,
  AllTransactionsStats,
} from '../../../../../../data/repositories/admin/typedefs';

export const SET_TRANSACTION_HISTORY_ACTIVE_TAB = 'admin/user_transaction_history/set_active_tab';

export const SET_TRANSACTION_HISTORY_SELETCED_USER =
  'admin/user_transaction_history/set_selected_user';

export const GET_ALL_TRANSACTIONS_STATS =
  'admin/user_transaction_history/get_all_transactions_stats';
export const GET_ALL_TRANSACTIONS_STATS_SUCCESS =
  'admin/user_transaction_history/get_all_transactions_stats_success';
export const GET_ALL_TRANSACTIONS_STATS_FAIL =
  'admin/user_transaction_history/get_all_transactions_stats_fail';

export const GET_ALL_TRANSACTIONS_PAGINATED =
  'admin/user_transaction_history/get_all_transactions_paginated';
export const GET_ALL_TRANSACTIONS_PAGINATED_SUCCESS =
  'admin/user_transaction_history/get_all_transactions_paginated_success';
export const GET_ALL_TRANSACTIONS_PAGINATED_FAILED =
  'admin/user_transaction_history/get_all_transactions_paginated_failed';

export const SET_ALL_TRANSACTIONS_TABLE_PAGE =
  'admin/user_transaction_history/set_all_transaction_table_page';

export const USER_TRANSACTION_HISTORY_TOGGLE_FILTER =
  'admin/user_transaction_history/toggle_filter';
export const USER_TRANSACTION_HISTORY_CLEAR_ALL_FILTERS =
  'admin/user_transaction_history/clear_all_filters';

export const EXPORT_ALL_TRANSACTIONS = 'admin/csv-export/all-transactions';
export const EXPORT_ALL_TRANSACTIONS_SUCCESS = 'admin/csv-export/all-transactions_success';
export const EXPORT_ALL_TRANSACTIONS_FAIL = 'admin/csv-export/all-transactions_fail';

export const GET_WALLET_TRANSFERS_PAGINATED = 'admin/user_transaction_history/get_wallet_tranfers';
export const GET_WALLET_TRANSFERS_PAGINATED_SUCCESS =
  'admin/user_transaction_history/get_wallet_tranfers/success';
export const GET_WALLET_TRANSFERS_PAGINATED_FAILED =
  'admin/user_transaction_history/get_wallet_tranfers/failed';

export const SET_WALLET_TRANSFERS_TABLE = 'admin/user_transaction_history/set_transfers_table';

export const EXPORT_WALLET_TRANSFERS = 'admin/csv-export/wallet_transfers_history';
export const EXPORT_WALLET_TRANSFERS_SUCCESS = 'admin/csv-export/wallet_transfers_history_success';
export const EXPORT_WALLET_TRANSFERS_FAIL = 'admin/csv-export/wallet_transfers_history_fail';

export const setTransactionHistoryActiveTab = createStandardAction(
  SET_TRANSACTION_HISTORY_ACTIVE_TAB,
)<string>();
export const setTransactionHistorySelectedUser = createStandardAction(
  SET_TRANSACTION_HISTORY_SELETCED_USER,
)<SelectedUser>();

export const asyncGetAllTransactionsStats = createAsyncAction(
  GET_ALL_TRANSACTIONS_STATS,
  GET_ALL_TRANSACTIONS_STATS_SUCCESS,
  GET_ALL_TRANSACTIONS_STATS_FAIL,
)<undefined, AllTransactionsStats, Error>();

export const asyncGetAllTransactionsPaginated = createAsyncAction(
  GET_ALL_TRANSACTIONS_PAGINATED,
  GET_ALL_TRANSACTIONS_PAGINATED_SUCCESS,
  GET_ALL_TRANSACTIONS_PAGINATED_FAILED,
)<GetPaginatedUserTransactionHistoryRequest, { [id: string]: User }, Error>();

export const setAllTransactionsTablePage = createStandardAction(SET_ALL_TRANSACTIONS_TABLE_PAGE)<
  number
>();

export const userTransactionHistoryToggleFilter = createStandardAction(
  USER_TRANSACTION_HISTORY_TOGGLE_FILTER,
)<SaveFilter>();
export const userTransactionHistoryClearAllFilters = createStandardAction(
  USER_TRANSACTION_HISTORY_CLEAR_ALL_FILTERS,
)<undefined>();

export const asyncExportAllTransactions = createAsyncAction(
  EXPORT_ALL_TRANSACTIONS,
  EXPORT_ALL_TRANSACTIONS_SUCCESS,
  EXPORT_ALL_TRANSACTIONS_FAIL,
)<ExportUserTransactionHistoryRequest, undefined, Error>();

export const getWalletTransfersPaginated = createAsyncAction(
  GET_WALLET_TRANSFERS_PAGINATED,
  GET_WALLET_TRANSFERS_PAGINATED_SUCCESS,
  GET_WALLET_TRANSFERS_PAGINATED_FAILED,
)<GetPaginatedUserTransactionHistoryRequest, any, Error>();

export const setWalletTransfersTable = createStandardAction(SET_WALLET_TRANSFERS_TABLE)<number>();

export const asyncExportWalletTransfers = createAsyncAction(
  EXPORT_WALLET_TRANSFERS,
  EXPORT_WALLET_TRANSFERS_SUCCESS,
  EXPORT_WALLET_TRANSFERS_FAIL,
)<ExportUserTransactionHistoryRequest, undefined, Error>();
