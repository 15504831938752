/* eslint-disable */
import { DialogContent, Grid, InputAdornment, TextField, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Big from 'big.js';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { EmptyAC, PayloadAC } from 'typesafe-actions';
import CustomButtonPrimary from '../../../../../../components/CustomButtons/CustomButtonPrimary';
import LightTooltip from '../../../../../../components/LightTooltip';
import SacretCoinSelect from '../../../../../../components/SacretCoinSelect';
import { Coin, Wallet } from '../../../../../../data/repositories/wallet/typedefs';
import { Colors } from '../../../../../../ui/style/colors';
import balanceUtils from '../../../../../../utils/balanceUtils';
import {
  ALLOWED_CRYPTO_FOR_MEMBERS,
  ONLY_LEGACY_ADDRESS_SUPPORTED_COINS,
} from '../../../../../../utils/constants/amounts';
import {
  getAllowedCurrenciesForNonMembers,
  isUserMember,
} from '../../../../../../utils/helper/utils';
import numberUtils from '../../../../../../utils/numberUtils';
import { AppState } from '../../../../../state/initialState';
import { EURWithdrawAddressType } from '../../../../state/common/initialState';
import { filterAvailableCoinsSelector } from '../../state/selectors';
import WithdrawEURDialogContent from '../WithdrawEURDialogContent';

const styles = (theme: Theme) =>
  createStyles({
    dialogTittleText: {
      color: `${Colors.blueCharcoal}`,
      fontSize: 18,
      fontWeight: 600,
      letterSpacing: 1.13,
      display: 'flex',
      alignItems: 'center',
    },
    dialogTitle: {
      marginTop: 25,
      marginLeft: 25,
      marginRight: 25,
      padding: 0,
    },
    depositIcon: {
      marginRight: 20,
    },
    dialogPaper: {
      height: 'center',
      width: 'center',
      borderRadius: 6,
      backgroundColor: 'transparent',
      boxShadow: '0 10px 30px 0 rgba(132,141,182,0.25)',
    },
    closeButton: {
      float: 'right',
      verticalAlign: 'text-top',
    },
    currencyLabel: {
      color: theme.palette.primary.light,
      fontSize: 12,
      fontWeight: 600,
      marginBottom: 10,
    },
    currencyDropdown: {
      height: 40,
      borderRadius: 6,
      backgroundColor: `${Colors.white}`,
      boxShadow: '0 2px 10px 0 rgba(132,141,182,0.25)',
    },
    currencyDropdownOption: {
      height: 40,
      borderRadius: 6,
      backgroundColor: `${Colors.white}`,
    },
    button: {
      fontSize: 14,
      marginTop: 30,
      marginBottom: 20,
      textTransform: 'none',
    },
    currencyAddress: {
      borderRadius: 6,
      marginBottom: 24,
      marginTop: 24,
      height: 50,
    },
    dropdownStyle: {
      border: `1px solid ${theme.palette.secondary.light}`,
      borderRadius: 6,
    },
    snackbar: {
      background: `${Colors.apple}`,
    },
    withdrawInput: {
      border: `1px solid ${theme.palette.secondary.light}`,
      borderRadius: 6,
      backgroundColor: `${Colors.white}`,
      display: 'flex',
      alignContent: 'center',
      marginTop: 8,
      marginBottom: 10,
    },
    inputSymbol: {
      width: 51,
      height: 50,
      borderRadius: '0 4px 4px 0',
      backgroundColor: `${theme.palette.secondary.light}`,
      color: `${Colors.white}`,
      fontSize: 12,
      fontWeight: 600,
    },
    inputSymbolValue: {
      position: 'relative',
      float: 'left',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    value: {
      color: `${Colors.blueCharcoal}`,
      textAlign: 'center',
      marginBottom: 10,
      fontSize: 14,
    },
    currencyBalance: {
      fontSize: 12,
      fontWeight: 600,
      marginLeft: 3,
    },
    input: {
      '&::placeholder': {
        color: theme.palette.secondary.light,
      },
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'none',
      },
      '&[type=number]': {
        '-webkit-appearance': 'textfield',
        '-moz-appearance': 'textfield',
      },
    },
    noMargin: {
      margin: 0,
    },
    tooltip: {
      position: 'relative',
      left: 'auto',
      top: 'auto',
    },
    selectCoin: {
      marginBottom: 10,
    },
  });
const useStyles = makeStyles(styles);

type Props = {
  wallet?: Wallet;
  allCoins: Map<string, Coin>;
  setSelectedCoin: PayloadAC<'wallet/set_selected_coin', string>;
  selectedCoin: string;
  saveWithdrawAddress: PayloadAC<'wallet/save_withdraw_address', string | EURWithdrawAddressType>;
  saveWithdrawAmount: PayloadAC<'wallet/save_withdraw_amount', string>;
  openWithdrawDialog: PayloadAC<'wallet/open_withdraw_dialog', number>;
  clearCommonState: EmptyAC<'wallet/clear_common_state'>;
  withdrawAmount: string;
  withdrawAddress: string | EURWithdrawAddressType;
  membershipLevel: any;
  hasCompletedKYC: boolean;
};

const WithdrawCoinsDialog = ({
  allCoins,
  wallet,
  setSelectedCoin,
  selectedCoin,
  saveWithdrawAmount,
  saveWithdrawAddress,
  withdrawAmount,
  withdrawAddress,
  openWithdrawDialog,
  clearCommonState,
  membershipLevel,
  hasCompletedKYC,
}: Props): JSX.Element => {
  const classes = useStyles();
  const isMember = isUserMember(membershipLevel);

  useEffect(() => {
    clearCommonState();
  }, []);

  useEffect(() => handleInputChange(withdrawAmount), [wallet]);

  // const pasteClipboard = async () => {
  //   try {
  //     const clipboardText = await navigator.clipboard.readText();
  //     setPasteValue(clipboardText);
  //     saveWithdrawAddress(clipboardText);
  //     const code = document.getElementById('address') as HTMLInputElement;
  //     code.value = clipboardText;
  //   } catch (err) {
  //     console.error('Failed to read clipboard contents: ', err);
  //   }
  // };

  const [value, setValue] = React.useState<number>(0);

  const handleInputChange = (inputValue: string) => {
    saveWithdrawAmount(inputValue);
    setValue(inputValue === '' ? 0 : Number(inputValue) * Number(wallet ? wallet.price_eur : 1));
  };

  const handleChangeAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
    saveWithdrawAddress(event.target.value);
  };

  const handleSubmit = () => {
    openWithdrawDialog(2);
  };
  const walletBalanceBig = Big(
    wallet
      ? wallet.balance
        ? balanceUtils.formatBalanceToBigWithDecimals(wallet.balance, wallet.decimals)
        : '0'
      : '0',
  );
  const walletBalanceString = balanceUtils.formatBigToStringWithCommasAndRound(
    walletBalanceBig,
    2,
    4,
  );
  const amountToWithdrawBig = Big(withdrawAmount !== '' ? withdrawAmount : '0');
  const isWithdrawAllowed =
    amountToWithdrawBig.gt(0) &&
    Big(walletBalanceString).gte(amountToWithdrawBig) &&
    withdrawAddress;

  const filterAvailableCoins = (availableCoins: string[]) =>
    useSelector((state: AppState) => {
      return filterAvailableCoinsSelector(availableCoins)(state);
    });

  return (
    <DialogContent>
      <div className={classes.selectCoin}>
        <Typography className={classes.currencyLabel}>Choose currency</Typography>
        <SacretCoinSelect
          label="labela"
          allCoins={allCoins}
          selectedCoin={selectedCoin ? selectedCoin : 'BTC'}
          setSelectedCoin={setSelectedCoin}
          allowedCoins={filterAvailableCoins(
            isMember
              ? ALLOWED_CRYPTO_FOR_MEMBERS
              : getAllowedCurrenciesForNonMembers(hasCompletedKYC),
          )}
        />
      </div>
      {selectedCoin === 'WALLET_EUR' ? (
        <WithdrawEURDialogContent />
      ) : (
        <>
          <TextField
            id="address"
            variant="outlined"
            fullWidth
            className={classes.currencyAddress}
            placeholder="Paste, scan or select destination"
            InputProps={{
              style: {
                height: 50,
              },
              classes: {
                input: classes.input,
              },
              endAdornment: ONLY_LEGACY_ADDRESS_SUPPORTED_COINS.includes(selectedCoin) && (
                <InputAdornment position="end">
                  <LightTooltip
                    title="Only legacy address is supported"
                    className={classes.tooltip}
                  />
                </InputAdornment>
              ),
              // endAdornment: (
              //   <InputAdornment position="end" onClick={pasteClipboard}>
              //     <img src={Shape} />
              //   </InputAdornment>
              // ),
            }}
            onChange={handleChangeAddress}
          >
            {/* {pasteValue} */}
          </TextField>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Typography className={classes.currencyLabel}>Amount to send</Typography>

              <TextField
                variant="outlined"
                type="number"
                className={classes.value}
                onKeyPress={event => numberUtils.allowOnlyFloatNumbers(event)}
                onChange={event => handleInputChange(event.target.value)}
                placeholder="0"
                fullWidth
                InputProps={{
                  classes: {
                    input: classes.input,
                  },
                  style: {
                    height: 50,
                    paddingRight: 0,
                  },
                  endAdornment: (
                    <div className={classes.inputSymbol}>
                      <div className={classes.inputSymbolValue}>
                        {selectedCoin === 'WALLET_EUR' ? 'EUR' : selectedCoin}
                      </div>
                    </div>
                  ),
                }}
              />
              <Grid container item direction="row">
                <Typography className={`${classes.currencyLabel} ${classes.noMargin}`}>
                  {selectedCoin === 'WALLET_EUR' ? 'EUR' : selectedCoin} Balance:
                </Typography>
                <Typography color="secondary" className={classes.currencyBalance}>
                  {walletBalanceString}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className={classes.currencyLabel}>Total</Typography>
              <TextField
                disabled
                variant="outlined"
                className={classes.value}
                onChange={event => handleInputChange(event.target.value)}
                placeholder="0.00"
                fullWidth
                value={value > 0 ? value.toFixed(4) : ''}
                InputProps={{
                  style: {
                    height: 50,
                    paddingRight: 0,
                  },
                  classes: {
                    input: classes.input,
                  },
                  endAdornment: (
                    <div className={classes.inputSymbol}>
                      <div className={classes.inputSymbolValue}>EUR</div>
                    </div>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <CustomButtonPrimary
            type="submit"
            className={classes.button}
            disabled={!isWithdrawAllowed}
            variant="contained"
            fullWidth
            onClick={handleSubmit}
          >
            Send {selectedCoin === 'WALLET_EUR' ? 'EUR' : selectedCoin}
          </CustomButtonPrimary>
        </>
      )}
    </DialogContent>
  );
};

export default WithdrawCoinsDialog;
