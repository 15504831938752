import {
  Card,
  CardContent,
  CircularProgress,
  Grid,
  InputLabel,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { EmptyAC, PayloadAC } from 'typesafe-actions';
import * as yup from 'yup';
import CustomButtonPrimary from '../../../../../components/CustomButtons/CustomButtonPrimary';
import SacretSelect from '../../../../../components/SacretSelect';
import { Colors } from '../../../../../ui/style/colors';
import useExchangeCurrencyPrices from '../../Exchange/ExchangeCurrency/useExchangeCurrencyPrices';
import { UPDATE_CURRENCY_RATE, UPDATE_CURRENCY_RATE_CLEAR_STATE } from './state/actions';
import { UpdateCurrencyRateRequest, UpdateCurrencyRateState } from './state/initialState';

const styles = (theme: Theme) =>
  createStyles({
    card: {
      boxShadow: '0 10px 30px 0 rgba(132,141,182,0.25)',
      verticalAlign: 'sub',
      padding: 20,
    },
    inputFields: {
      marginTop: 5,
      height: 44,
      boxSizing: 'border-box',
      border: `1px solid ${theme.palette.secondary.light}`,
      borderRadius: 4,
      backgroundColor: `${Colors.white}`,
      paddingLeft: 12,
      width: '100%',
    },
    labels: {
      fontWeight: 'bold',
      color: theme.palette.primary.light,
      marginTop: 30,
      marginBottom: 8,
    },
    updateCurrencyLabel: {
      color: theme.palette.secondary.light,
      fontSize: 14,
      fontWeight: 800,
      letterSpacing: 1,
      textTransform: 'uppercase',
      marginBottom: 30,
    },
    button: {
      marginTop: 40,
      textTransform: 'none',
      fontSize: 16,
      fontWeight: 600,
      letterSpacing: 1,
      textAlign: 'center',
    },
    buttonSuccess: {
      backgroundColor: `${Colors.havelockBlue}`,
      marginTop: 40,
      '&:hover': {
        backgroundColor: `${Colors.havelockBlue}`,
      },
    },
    inputError: {
      color: `${Colors.red}`,
      marginTop: 10,
    },
  });

const useStyles = makeStyles(styles);

type Props = UpdateCurrencyRateState & {
  updateCurrencyRate: PayloadAC<typeof UPDATE_CURRENCY_RATE, UpdateCurrencyRateRequest>;
  updateCurrencyRateClearState: EmptyAC<typeof UPDATE_CURRENCY_RATE_CLEAR_STATE>;
};

const CurrencySettings: React.FC<Props> = ({
  inProgress,
  success,
  error,
  fail,
  updateCurrencyRate,
  updateCurrencyRateClearState,
}: Props): JSX.Element => {
  const classes = useStyles();
  const [selectedCurrency, setSelectedCurrency] = useState('SLT');

  useEffect(() => {
    updateCurrencyRateClearState();
  }, []);

  const submit = (rate: string) => {
    updateCurrencyRate({ rate, selectedCurrency });
  };
  const initValues = { rate: '' };
  const { getSelectedCoinMarketVal } = useExchangeCurrencyPrices();
  const eurValue = getSelectedCoinMarketVal(selectedCurrency);

  const currencies = [
    { name: 'SLT', value: 'SLT' },
    { name: 'SLC', value: 'SLC' },
  ];

  return (
    <Card className={classes.card}>
      <CardContent>
        <Formik
          initialValues={initValues}
          onSubmit={(value, actions) => {
            actions.setSubmitting(false);
            submit(value.rate);
          }}
          validationSchema={yup.object({
            rate: yup
              .number()
              .positive(`${selectedCurrency} - EUR must be a positive number!`)
              .required(`${selectedCurrency} - EUR rate is required`),
          })}
        >
          {({ isSubmitting, errors, touched }) => {
            return (
              <Form>
                <Typography className={classes.updateCurrencyLabel} component="p">
                  Change {selectedCurrency}-EUR rate
                </Typography>

                <Grid xs={12} sm="auto" item>
                  <SacretSelect
                    multiple={false}
                    items={currencies}
                    label={selectedCurrency}
                    onChange={(currency: string) => setSelectedCurrency(currency)}
                    stateFilterValue={selectedCurrency}
                  />
                </Grid>

                <InputLabel className={classes.labels}>
                  Current rate: 1 {selectedCurrency} = {eurValue} EUR
                </InputLabel>

                <InputLabel className={classes.labels}>New rate</InputLabel>
                <Field
                  id="slt_eur_rate"
                  type="number"
                  name="rate"
                  className={classes.inputFields}
                />

                {errors.rate && touched.rate ? (
                  <div className={classes.inputError}>{errors.rate}</div>
                ) : null}

                <Grid container alignItems="center" justifyContent="center">
                  {inProgress ? (
                    <CircularProgress />
                  ) : (
                    <CustomButtonPrimary
                      type="submit"
                      variant="contained"
                      size="large"
                      fullWidth
                      className={success ? classes.buttonSuccess : classes.button}
                      disabled={isSubmitting}
                    >
                      {success ? 'Succesfully changed!' : 'Save'}
                    </CustomButtonPrimary>
                  )}
                </Grid>
                {fail && (
                  <div className={classes.inputError}>
                    {error ? error : 'Change currency rate failed'}
                  </div>
                )}
              </Form>
            );
          }}
        </Formik>
      </CardContent>
    </Card>
  );
};

export default CurrencySettings;
