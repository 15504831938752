/* eslint-disable */
import { createStandardAction } from 'typesafe-actions';
import { UpdateCurrencyRateRequest } from './initialState';

export const UPDATE_CURRENCY_RATE = 'update_currency_rate';
export const UPDATE_CURRENCY_RATE_SUCCESS = 'update_currency_rate/success';
export const UPDATE_CURRENCY_RATE_FAIL = 'update_currency_rate/fail';
export const UPDATE_CURRENCY_RATE_CLEAR_STATE = 'update_currency_rate/clear_state';

export const updateCurrencyRate = createStandardAction(UPDATE_CURRENCY_RATE)<
  UpdateCurrencyRateRequest
>();
export const updateCurrencyRateSuccess = createStandardAction(UPDATE_CURRENCY_RATE_SUCCESS)<
  undefined
>();
export const updateCurrencyRateFail = createStandardAction(UPDATE_CURRENCY_RATE_FAIL)<Error>();
export const updateCurrencyRateClearState = createStandardAction(UPDATE_CURRENCY_RATE_CLEAR_STATE)<
  undefined
>();
