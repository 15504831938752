import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import auth from '../Auth/state/reducer';
import home from '../Home/state/reducers';
import user from './user/reducers';

export default history =>
  combineReducers({
    router: connectRouter(history),
    auth,
    user,
    home,
  });
