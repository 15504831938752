import { connect } from 'react-redux';
import { AppState } from '../../../../state/initialState';
import AffiliateMerchantsTable from './AffiliateMerchantsTable';
import { asyncGetMerchantsReferralsPaginated } from './state/actions';
import { affiliateMerchantsTableStateSelector } from './state/selectors';
import { getAppliedFiltersSelector } from '../../Earnings/state/selectors';

const mapDispatchToProps = {
  asyncGetMerchantsReferralsPaginated: asyncGetMerchantsReferralsPaginated.request,
};

const mapStateToProps = (state: AppState) => {
  const appliedFilters = getAppliedFiltersSelector(state);

  return { appliedFilters, ...affiliateMerchantsTableStateSelector(state) };
};
export default connect(mapStateToProps, mapDispatchToProps)(AffiliateMerchantsTable);
