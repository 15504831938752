export type ProductsPurchaseHistoryFilter = Map<string, string[]>;

export type ProductsPurchaseHistoryTableType = {
  product_name: string;
  email: string;
  payment_type: string;
  slc_amount: number;
  eur_amount: number;
  order_created_at: string;
  slc_rate: number;
  current_eur_amount: number;
  order_status: string;
};

export type ProductsPurchaseHistoryState = {
  productsPurchaseHistory: ProductsPurchaseHistoryTableType[];
  inProgress: boolean;
  fail: boolean;
  error: string | null;
  page: number;
  perPage: number;
  appliedFilters: ProductsPurchaseHistoryFilter;
  exportInProgress: boolean;
  totalCount: number;
};

export const initialState: ProductsPurchaseHistoryState = {
  productsPurchaseHistory: [],
  totalCount: 0,
  inProgress: false,
  fail: false,
  error: null,
  page: 0,
  perPage: 10,
  appliedFilters: new Map<string, string[]>(),
  exportInProgress: false,
};
