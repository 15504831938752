import { createReducer } from 'typesafe-actions';
import { earningsTransactionTypeBV } from '../EurEarnings/EurTransactionsFilterBar';
import {
  CLEAR_ALL_EUR_FILTERS,
  CLEAR_ALL_FILTERS,
  GET_TRANSFERS,
  GET_TRANSFERS_FAIL,
  GET_TRANSFERS_FOR_COIN,
  GET_TRANSFERS_FOR_COIN_SUCCESS,
  GET_TRANSFERS_SET_LOADING,
  GET_TRANSFERS_SUCCESS,
  SAVE_TRANSFERS,
  TOGGLE_FILTER,
} from './actions';
import { initialState } from './initialState';

export default createReducer(initialState, {
  [GET_TRANSFERS]: state => ({
    ...state,
    inProgress: true,
    loadingDone: true,
  }),
  [GET_TRANSFERS_SUCCESS]: state => ({
    ...state,
    fail: false,
    inProgress: false,
  }),
  [GET_TRANSFERS_FAIL]: (state, { payload }) => ({
    ...state,
    inProgress: false,
    fail: true,
    ...(payload && payload.response ? { error: payload.response.data } : {}),
  }),
  [GET_TRANSFERS_FOR_COIN]: state => ({
    ...state,
    inProgress: true,
  }),
  [GET_TRANSFERS_FOR_COIN_SUCCESS]: (state, { payload }) => ({
    ...state,
    fail: false,
    inProgress: false,
    singleCoinTransactions: { ...payload },
  }),
  [GET_TRANSFERS_FAIL]: (state, { payload }) => ({
    ...state,
    inProgress: false,
    fail: true,
    ...(payload && payload.response ? { error: payload.response.data } : {}),
  }),
  [SAVE_TRANSFERS]: (state, { payload }) => ({
    ...state,
    transactions: { ...payload },
  }),
  [GET_TRANSFERS_SET_LOADING]: (state, { payload }) => ({
    ...state,
    inProgress: payload,
  }),
  [TOGGLE_FILTER]: (state, { payload }) => {
    return {
      ...state,
      appliedFilters: { ...state.appliedFilters, [payload.filterType]: payload.value },
    };
  },
  [CLEAR_ALL_FILTERS]: state => ({
    ...state,
    appliedFilters: new Map(),
  }),
  [CLEAR_ALL_EUR_FILTERS]: state => ({
    ...state,
    appliedFilters: () => {
      new Map();
      return { coinSymbol: 'EUR', transferType: earningsTransactionTypeBV };
    },
  }),
});
