import Big from 'big.js';
import numberUtil from './numberUtils';
import {
  CRYPTO_MIN_FRACTION,
  CRYPTO_MAX_FRACTION,
  WIRE_EUR_MAX_FRACTION,
  WIRE_EUR_MIN_FRACTION,
  WIRE_EUR_DECIMALS,
} from './constants/amounts';

const formatBalanceToString = (value, decimals, minFraction, maxFraction) => {
  value = parseFloat(value || 0);
  const calculatedValue = Big(value) / Math.pow(10, decimals);
  const result = numberUtil.formatNumberDigits(calculatedValue, minFraction, maxFraction);

  const partials = result.toString().split('.');
  // Split the result by comma and check left and right value
  if (Math.abs(partials[0]) === 0 && Number(partials[1]) === 0) {
    const updatedResult = numberUtil.formatNumberDigits(
      calculatedValue,
      minFraction,
      maxFraction + 2,
    );

    if (Number(updatedResult.split('.')[1]) >= 1) return updatedResult;

    return numberUtil.formatNumberDigits(calculatedValue, minFraction, 7);
  }

  return result;
};
const formatBigToStringWithCommasAndRound = (value, minFraction, maxFraction) => {
  value = value.round(maxFraction, 0);
  return numberUtil.formatNumberDigits(value.toString(), minFraction, maxFraction);
};
const formatBalanceToBigWithDecimals = (value, decimals) => {
  if (value) {
    return Big(value).div(Math.pow(10, decimals));
  }
  return Big(0);
};

const calculateValue = (
  balance,
  decimals,
  price,
  minFraction = WIRE_EUR_MIN_FRACTION,
  maxFraction = WIRE_EUR_MAX_FRACTION,
) => {
  Big.NE = -18;
  return numberUtil.formatNumberDigits(
    parseFloat(Big(balance).div(Math.pow(10, decimals)).times(Big(price)).toString()),
    minFraction,
    maxFraction,
  );
};

const strToNumber = str => str.replace(/,/g, '').trim();

const calculateValueFromEurToCrypto = (balance, price) =>
  strToNumber(
    calculateValue(
      Math.pow(10, WIRE_EUR_DECIMALS) / balance,
      0,
      price,
      CRYPTO_MIN_FRACTION,
      CRYPTO_MAX_FRACTION,
    ),
  );

const getSlcValueFromEurocents = (
  valueInEuroCents,
  slcEurRate = process.env.REACT_APP_DEFAULT_SLC_EUR_RATE,
  yupValidationEqualValue = 0,
) => {
  const valueInEuro = valueInEuroCents / 100;

  return Number(valueInEuro / slcEurRate + yupValidationEqualValue).toFixed(2);
};

// Used for sending float values to BE without decimals
const parseNumberToBig = (amount, crytoDecimals = 10000000) => {
  return parseFloat(Big(amount).times(crytoDecimals));
};

export default {
  formatBalanceToString,
  formatBalanceToBigWithDecimals,
  calculateValue,
  formatBigToStringWithCommasAndRound,
  getSlcValueFromEurocents,
  strToNumber,
  calculateValueFromEurToCrypto,
  parseNumberToBig,
};
