import { createReducer } from 'typesafe-actions';
import {
  CLEAR_WITHDRAW_STATE,
  GET_WALLET_BALANCE,
  GET_WALLET_BALANCE_FAIL,
  GET_WALLET_BALANCE_SUCCESS,
  IS_WITHDRAW_DIALOG_OPEN,
  OPEN_WITHDRAW_DIALOG,
  WITHDRAW_CURRENCY,
  WITHDRAW_CURRENCY_FAIL,
  WITHDRAW_CURRENCY_SUCCESS,
  GET_ENTIRE_WALLET_BALANCES_SUCCESS,
  GET_ENTIRE_WALLET_BALANCES_FAIL,
  IS_INVEST_DIALOG_OPEN,
  GET_TERM_SAVING_TYPES_SUCCESS,
  GET_TERM_SAVING_TYPES_FAIL,
  SET_TERM_SAVING_PACKAGE,
  DEPOSIT_TERM_SAVING_FAIL,
  DEPOSIT_TERM_SAVING,
  DEPOSIT_TERM_SAVING_SUCCESS,
  SWAP_TO_SLC_FAIL,
  SWAP_TO_SLC_SUCCESS,
  SWAP_TO_SLC,
  WITHDRAW_EUR_FAIL,
  WITHDRAW_EUR_SUCCESS,
  WITHDRAW_EUR,
  CREATE_REVOLUT_COUNTERPARTY_FAIL,
  CREATE_REVOLUT_COUNTERPARTY,
  CREATE_REVOLUT_COUNTERPARTY_SUCCESS,
  GET_REVOLUT_COUNTERPARTY,
  GET_REVOLUT_COUNTERPARTY_SUCCESS,
  GET_REVOLUT_COUNTERPARTY_FAIL,
  SET_DEPOSIT_MODAL_DATA,
  SET_DEPOSIT_MODAL_STEP,
  CLEAR_DEPOSIT_MODAL_STATE,
  SET_DEPOSIT_MODAL_OPEN,
  WIRE_DEPOSIT_EUR,
  WIRE_DEPOSIT_EUR_SUCCESS,
  WIRE_DEPOSIT_EUR_FAIL,
  OPEN_BUY_SELL_MODAL,
  CLEAR_BUY_SELL_MODAL_STATE,
  IS_BUY_SELL_MODAL_OPEN,
  BUY_WALLET,
  BUY_WALLET_SUCCESS,
  BUY_WALLET_FAIL,
  OPEN_BUY_WALLET_MODAL,
  CLOSE_BUY_WALLET_MODAL,
} from './actions';
import { initialState } from './initialState';

export default createReducer(initialState, {
  [WIRE_DEPOSIT_EUR]: state => ({
    ...state,
    depositModal: {
      ...state.depositModal,
      depositOrder: { inProgress: true, fail: false, error: null, success: false },
    },
  }),
  [WIRE_DEPOSIT_EUR_SUCCESS]: (state, { payload }) => ({
    ...state,
    depositModal: {
      ...state.depositModal,
      depositOrder: {
        ...state.depositOrder,
        inProgress: false,
        success: true,
        order: payload,
      },
    },
  }),
  [WIRE_DEPOSIT_EUR_FAIL]: (state, { payload }) => ({
    ...state,
    depositModal: {
      ...state.depositModal,
      depositOrder: { ...state.depositOrder, inProgress: false, fail: true, error: payload },
    },
  }),

  [SET_DEPOSIT_MODAL_DATA]: (state, { payload }) => ({
    ...state,
    depositModal: { ...state.depositModal, data: payload },
  }),
  [SET_DEPOSIT_MODAL_STEP]: (state, { payload }) => ({
    ...state,
    depositModal: { ...state.depositModal, step: payload },
  }),
  [SET_DEPOSIT_MODAL_OPEN]: (state, { payload }) => ({
    ...state,
    depositModal: { ...state.depositModal, open: payload },
  }),
  [CLEAR_DEPOSIT_MODAL_STATE]: state => ({
    ...state,
    depositModal: initialState.depositModal,
  }),
  [GET_WALLET_BALANCE]: state => ({
    ...state,
    componentState: { ...state.componentState, coinsTableInProgress: true },
  }),
  [GET_WALLET_BALANCE_SUCCESS]: (state, { payload }) => ({
    ...state,
    componentState: { ...state.componentState, coinsTableInProgress: false },
    wallets: payload,
  }),
  [GET_WALLET_BALANCE_FAIL]: (state, { payload }) => ({
    ...state,
    componentState: {
      ...state.componentState,
      coinsTableInProgress: false,
      fail: true,
      ...(payload ? { error: payload.message } : {}),
    },
  }),

  [OPEN_WITHDRAW_DIALOG]: (state, { payload }) => ({
    ...state,
    step: payload,
  }),
  [OPEN_BUY_SELL_MODAL]: (state, { payload }) => ({
    ...state,
    buySellModal: { ...state.buySellModal, isOpen: payload },
  }),
  [CLEAR_BUY_SELL_MODAL_STATE]: state => ({
    ...state,
    withdrawError: null,
    withdrawSuccess: null,
  }),
  [IS_BUY_SELL_MODAL_OPEN]: (state, { payload }) => ({
    ...state,
    componentState: { isDialogOpen: payload },
  }),
  [WITHDRAW_CURRENCY]: state => ({
    ...state,
    withdrawInProgress: true,
  }),
  [WITHDRAW_CURRENCY_SUCCESS]: state => ({
    ...state,
    withdrawInProgress: false,
    componentState: { isDialogOpen: false },
  }),
  [WITHDRAW_CURRENCY_FAIL]: (state, { payload }) => ({
    ...state,
    withdrawInProgress: false,
    ...(payload ? { withdrawError: payload.response.data.error } : {}),
  }),
  [CREATE_REVOLUT_COUNTERPARTY]: state => ({
    ...state,
    withdrawInProgress: true,
  }),
  [CREATE_REVOLUT_COUNTERPARTY_SUCCESS]: (state, { payload }) => ({
    ...state,
    revolutCounterpartyData: {
      ...state.revolutCounterpartyData,
      ...payload,
    },
  }),
  [GET_REVOLUT_COUNTERPARTY]: state => ({
    ...state,
    ...state.getRevolutCounterparty,
    getRevolutCounterparty: { inProgress: true, fail: false, success: false },
  }),
  [GET_REVOLUT_COUNTERPARTY_SUCCESS]: (state, { payload }) => ({
    ...state,
    ...state.getRevolutCounterparty,
    getRevolutCounterparty: { inProgress: false, fail: false, success: true },
    revolutCounterpartyData: payload,
  }),
  [GET_REVOLUT_COUNTERPARTY_FAIL]: state => ({
    ...state,
    ...state.getRevolutCounterparty,
    getRevolutCounterparty: { inProgress: false, fail: true, success: false },
  }),
  [CREATE_REVOLUT_COUNTERPARTY_FAIL]: (state, { payload }) => ({
    ...state,
    withdrawInProgress: false,
    ...(payload ? { withdrawError: payload.response.data.error } : {}),
  }),
  [WITHDRAW_EUR]: state => ({
    ...state,
    withdrawInProgress: true,
  }),
  [WITHDRAW_EUR_SUCCESS]: state => ({
    ...state,
    withdrawInProgress: false,
    withdrawSuccess: true,
    componentState: { isDialogOpen: false },
  }),
  [WITHDRAW_EUR_FAIL]: (state, { payload }) => ({
    ...state,
    withdrawInProgress: false,
    ...(payload ? { withdrawError: payload.response.data.error } : {}),
  }),
  [IS_WITHDRAW_DIALOG_OPEN]: (state, { payload }) => ({
    ...state,
    componentState: { isDialogOpen: payload },
  }),
  [CLEAR_WITHDRAW_STATE]: state => ({
    ...state,
    withdrawError: null,
    withdrawSuccess: null,
  }),
  [GET_ENTIRE_WALLET_BALANCES_SUCCESS]: (state, { payload }) => ({
    ...state,
    entireWalletBalance: payload,
  }),
  [GET_ENTIRE_WALLET_BALANCES_FAIL]: state => ({
    ...state,
    fail: true,
  }),
  [IS_INVEST_DIALOG_OPEN]: (state, { payload }) => ({
    ...state,
    investDialogState: { ...state.investDialogState, investDialogOpened: payload },
  }),
  [GET_TERM_SAVING_TYPES_SUCCESS]: (state, { payload }) => ({
    ...state,
    investDialogState: { ...state.investDialogState, termPackageTypes: payload },
  }),
  [GET_TERM_SAVING_TYPES_FAIL]: state => ({
    ...state,
    componentState: { ...state.investDialogState, termPackageTypes: [] },
  }),
  [SET_TERM_SAVING_PACKAGE]: (state, { payload }) => ({
    ...state,
    investDialogState: { ...state.investDialogState, selectedPackage: payload },
  }),
  [DEPOSIT_TERM_SAVING]: state => ({
    ...state,
    investDialogState: { ...state.investDialogState, errorMessage: '', inProgress: true },
  }),
  [DEPOSIT_TERM_SAVING_SUCCESS]: state => ({
    ...state,
    investDialogState: { ...state.investDialogState, investDialogOpened: false, inProgress: false },
  }),
  [DEPOSIT_TERM_SAVING_FAIL]: (state, { payload }) => ({
    ...state,
    investDialogState: { ...state.investDialogState, errorMessage: payload, inProgress: false },
  }),
  [SWAP_TO_SLC]: state => ({
    ...state,
    swapSLTtoSLC: { ...state.swapSLTtoSLC, inProgress: true },
  }),
  [SWAP_TO_SLC_SUCCESS]: state => ({
    ...state,
    swapSLTtoSLC: {
      ...state.swapSLTtoSLC,
      inProgress: false,
      success: true,
      error: null,
      fail: false,
      completed: true,
    },
  }),
  [SWAP_TO_SLC_FAIL]: (state, { payload }) => ({
    ...state,
    swapSLTtoSLC: {
      ...state.swapSLTtoSLC,
      inProgress: false,
      success: false,
      error: payload,
      fail: true,
    },
  }),
  [OPEN_BUY_WALLET_MODAL]: state => ({
    ...state,
    buyWalletModal: { ...state.buyWalletModal, isOpen: true },
  }),
  [CLOSE_BUY_WALLET_MODAL]: state => ({
    ...state,
    buyWalletModal: {
      ...state.buyWalletModal,
      isOpen: false,
      error: null,
      fail: false,
      success: false,
    },
  }),
  [BUY_WALLET]: state => ({
    ...state,
    buyWalletModal: {
      ...state.buyWalletModal,
      inProgress: true,
    },
  }),
  [BUY_WALLET_SUCCESS]: state => ({
    ...state,
    buyWalletModal: {
      ...state.buyWalletModal,
      inProgress: false,
      success: true,
    },
  }),
  [BUY_WALLET_FAIL]: (state, { payload }) => ({
    ...state,
    buyWalletModal: {
      ...state.buyWalletModal,
      inProgress: false,
      fail: true,
      ...(payload ? { error: payload.response.data.error } : {}),
    },
  }),
});
