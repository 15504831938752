/* eslint-disable @typescript-eslint/indent */
import { CircularProgress } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { size } from 'lodash';
import React, { useEffect } from 'react';
import { EmptyAC, PayloadAC } from 'typesafe-actions';
import Satelite from '../../../../../../assets/satelite.svg';
import AffiliatesPremiumTable, {
  HeaderProps,
} from '../../../../../../components/AffiliatesPremiumTable';
import CustomButtonPrimary from '../../../../../../components/CustomButtons/CustomButtonPrimary';
import SacretEmpty from '../../../../../../components/SacretEmpty';
import SacretError from '../../../../../../components/SacretError';
import {
  AllTransactionTableState,
  ExportUserTransactionHistoryRequest,
  Filter,
  GetPaginatedUserTransactionHistoryRequest,
  SelectedUser,
  User,
} from '../../../../../../data/repositories/admin/typedefs';
import {
  EXPORT_ALL_TRANSACTIONS,
  GET_ALL_TRANSACTIONS_PAGINATED,
  SET_ALL_TRANSACTIONS_TABLE_PAGE,
  USER_TRANSACTION_HISTORY_CLEAR_ALL_FILTERS,
} from '../state/actions';
import { selectedUserInitialState } from '../state/initialState';
import AllTransactionsFilterBar from './AllTransactionsFilterBar';
const styles = (theme: Theme) =>
  createStyles({
    circularProgress: {
      position: 'absolute',
      left: '50%',
      top: '50%',
    },
    exportWrapper: {
      position: 'absolute',
      right: 0,
      top: 25,
      [theme.breakpoints.down('sm')]: {
        position: 'initial',
        marginBottom: 20,
      },
    },
    exportButton: {
      marginLeft: '10px',
    },
    exportLoaderWrappers: {
      width: '230px',
      height: '36px',
      display: 'inline-block',
      verticalAlign: 'middle',
      textAlign: 'center',
    },
  });

const useStyles = makeStyles(styles);

type Props = AllTransactionTableState & {
  clearAllFilters: EmptyAC<typeof USER_TRANSACTION_HISTORY_CLEAR_ALL_FILTERS>;
  asyncGetAllTransactionsPaginated: PayloadAC<
    typeof GET_ALL_TRANSACTIONS_PAGINATED,
    GetPaginatedUserTransactionHistoryRequest
  >;
  setAllTransactionsTablePage: PayloadAC<typeof SET_ALL_TRANSACTIONS_TABLE_PAGE, number>;
  asyncExportAllTransactions: PayloadAC<
    typeof EXPORT_ALL_TRANSACTIONS,
    ExportUserTransactionHistoryRequest
  >;
  inProgress: boolean;
  appliedFilters?: any;
  exportInProgress: boolean;
  selectedUser: SelectedUser;
};

const headers: HeaderProps[] = [
  { name: 'Status', align: 'left' },
  { name: 'TX Proof', align: 'left' },
  { name: 'Transactions type', align: 'left' },
  { name: 'Date & Time', align: 'right' },
  { name: 'Amount', align: 'right' },
  { name: 'Value(EUR)', align: 'right' },
];

const AllTransactions: React.FC<Props> = (props: Props): JSX.Element => {
  const classes = useStyles();
  const {
    referrals,
    asyncGetAllTransactionsPaginated,
    setAllTransactionsTablePage,
    asyncExportAllTransactions,
    inProgress,
    stats: { totalReferrals },
    page,
    error,
    appliedFilters,
    clearAllFilters,
    exportInProgress,
    selectedUser = selectedUserInitialState,
  } = props;
  const { id, email, firstName, lastName } = selectedUser;
  useEffect(() => {
    clearAllFilters();
    asyncGetAllTransactionsPaginated({ page: 0, perPage: 10, appliedFilters, logAccountId: id });
  }, [id]);

  const isEmpty = size(referrals) == 0;
  const exportData = () => {
    const getFilters = (appliedFilters: Filter) => {
      const object: any = {};
      appliedFilters.forEach((value, key) => {
        object[key] = value;
      });
      return object;
    };
    const data = {
      appliedFilters: getFilters(appliedFilters),
      user_email: email,
      user_name: `${firstName}_${lastName}`,
    };
    asyncExportAllTransactions(data);
  };
  if (error) {
    return <SacretError errorMessage={error} showBackButton={false} />;
  }
  const onPageChange = (newPage: number) => {
    const object: any = {};
    appliedFilters.forEach((value: any, key: React.ReactText) => {
      object[key] = value;
    });

    asyncGetAllTransactionsPaginated({
      page: newPage,
      perPage: 10,
      appliedFilters: {
        ...object,
      },
      logAccountId: id,
    });
    setAllTransactionsTablePage(newPage);
  };
  return (
    <div>
      {inProgress && !referrals ? (
        <CircularProgress color="secondary" className={classes.circularProgress} size={60} />
      ) : (
        <>
          <div className={classes.exportWrapper}>
            Export:
            {exportInProgress ? (
              <div className={classes.exportLoaderWrappers}>
                <CircularProgress size={34} color="secondary" />
              </div>
            ) : (
              <CustomButtonPrimary
                variant="outlined"
                size="medium"
                className={classes.exportButton}
                onClick={exportData}
                disabled={isEmpty}
              >
                Transactions
              </CustomButtonPrimary>
            )}
          </div>
          <AllTransactionsFilterBar logAccountId={id} />
          {!isEmpty ? (
            <AffiliatesPremiumTable
              referrals={referrals ? referrals : new Map<string, User>()}
              headers={headers}
              totalReferrals={totalReferrals || 0}
              page={page}
              filter={appliedFilters}
              inProgress={inProgress}
              appliedFilters={appliedFilters}
              onPageChange={onPageChange}
            />
          ) : (
            <SacretEmpty image={Satelite} title="Oops" text="We couldn’t find any transactions" />
          )}
        </>
      )}
    </div>
  );
};

export default AllTransactions;
