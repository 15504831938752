import { Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { PayloadAC } from 'typesafe-actions';
import {
  ChartState,
  GetCryptoChartRequest,
} from '../../../../../../data/repositories/dashboard/typedefs';
import { GetCoinPricesResponse } from '../../../../../../data/repositories/wallet/typedefs';
import { Colors } from '../../../../../../ui/style/colors';
import chartUtils from '../../../../../../utils/chartUtils';
import { GET_CRYPTO_CHART_DATA } from '../../state/actions';
import DailyChange from '../DailyChange';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginBottom: 20,
      fontFamily: 'Manrope3',
    },
    fullHeight: {
      height: '100%',
    },
    label: {
      fontSize: 14,
      textTransform: 'uppercase',
      color: `${Colors.wildBlueYonder}`,
    },
    price: {
      fontSize: 26,
      fontWeight: 'bold',
      [theme.breakpoints.down('sm')]: {
        fontSize: 20,
      },
    },
    value: {
      fontSize: 20,
      fontWeight: 'bold',
      marginBottom: 1,
    },
    periodButtonsWrapper: {
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-start',
      },
    },
    periodButtons: {
      fontSize: 14,
      fontWeight: 'bold',
      letterSpacing: 1,
      color: theme.palette.secondary.light,
      cursor: 'pointer',
      transition: 'all 0.3s',
      '&:hover': {
        color: `${Colors.black}`,
      },
    },
    activePeriodButton: {
      color: `${Colors.black}`,
      borderRadius: '50%',
      background: `${Colors.whiteLilac2}`,
    },
    dailyChange: {
      marginRight: 'auto',
    },
  });

const useStyles = makeStyles(styles);

type Props = {
  handleChange: PayloadAC<typeof GET_CRYPTO_CHART_DATA, GetCryptoChartRequest>;
  coinPrices?: GetCoinPricesResponse;
  cryptoChartData: ChartState;
};

const CryptoChartInfo: React.FC<Props> = ({
  handleChange,
  coinPrices,
  cryptoChartData,
}: Props): JSX.Element => {
  const classes = useStyles();

  const { data, selectedCurrency, selectedCoin } = cryptoChartData;
  const chartInfo = data[selectedCoin];

  const { volume, marketCap } = chartInfo;
  const price = coinPrices && coinPrices[selectedCoin][selectedCurrency.currency];
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="stretch"
      className={classes.root}
      spacing={3}
    >
      <Grid xs={6} sm="auto" item>
        <Grid
          container
          className={classes.fullHeight}
          direction="column"
          justifyContent="space-between"
        >
          <Typography className={classes.label} component="p">
            Price
          </Typography>
          <Typography className={classes.price}>
            {selectedCurrency.symbol} {price}
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={6} sm="auto" className={selectedCoin === 'SLC' ? classes.dailyChange : ''}>
        <Grid
          container
          className={classes.fullHeight}
          direction="column"
          justifyContent="space-between"
        >
          <DailyChange symbol={selectedCoin} showTitle />
        </Grid>
      </Grid>
      {selectedCoin !== 'SLC' && (
        <>
          <Grid xs={6} sm="auto" item>
            <Grid
              container
              className={classes.fullHeight}
              direction="column"
              justifyContent="space-between"
            >
              <Typography className={classes.label} component="p">
                Market Cap
              </Typography>
              <Typography className={classes.value}>
                {selectedCurrency.symbol} {marketCap}
              </Typography>
            </Grid>
          </Grid>
          <Grid xs={6} sm="auto" item>
            <Grid
              container
              className={classes.fullHeight}
              direction="column"
              justifyContent="space-between"
            >
              <Typography className={classes.label} component="p">
                Volume (24H)
              </Typography>
              <Typography className={classes.value}>
                {selectedCurrency.symbol} {volume}
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
      <Grid item xs={12} md={4}>
        <Grid
          container
          spacing={3}
          className={classes.periodButtonsWrapper}
          justifyContent="flex-end"
        >
          {selectedCoin === 'SLC' ? (
            <Grid
              key="year"
              onClick={() => handleChange('year')}
              item
              className={`${classes.periodButtons} ${
                'year' === cryptoChartData.selectedPeriodFilter && classes.activePeriodButton
              }`}
            >
              1y
            </Grid>
          ) : (
            chartUtils.CHART_PERIOD_FILTERS.map(({ name, symbol }) => (
              <Grid
                key={name}
                onClick={() => handleChange(name)}
                item
                className={`${classes.periodButtons} ${
                  name === cryptoChartData.selectedPeriodFilter && classes.activePeriodButton
                }`}
              >
                {symbol}
              </Grid>
            ))
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CryptoChartInfo;
