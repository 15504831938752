import { connect } from 'react-redux';
import { AppState } from '../../../../state/initialState';
import WalletDrawer from './WalletDrawer';
import { affiliatePremiumTableStateSelector } from '../../Transactions/AffiliatePremiumTable/state/selectors';
import { hasCompletedKYCSelector } from '../../Settings/state/selectors';

const mapStateToProps = (state: AppState) => {
  const hasCompletedKYC = hasCompletedKYCSelector(state);
  return { ...affiliatePremiumTableStateSelector(state), hasCompletedKYC };
};

export default connect(mapStateToProps, {})(WalletDrawer);
