import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  InputLabel,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import CustomButtonPrimary from '../../../../../components/CustomButtons/CustomButtonPrimary';
import SacretSelect from '../../../../../components/SacretSelect';
import { Colors } from '../../../../../ui/style/colors';
import { DepositFiatRequest, FiatDepositState } from './state/initialState';
import { connect } from 'react-redux';
import { toNumber, toUpper } from 'lodash';
import utils from '../../../../../utils/balanceUtils';
import { AppState } from '../../../../state/initialState';
import {
  getFiatDepositStateSelector,
  getDashboardWalletsStateSelector,
  getDashboardChartStateSelector,
} from './state/selectors';
import { Wallet } from '../../../../../data/repositories/dashboard/typedefs';
import { DEPOSIT_FIAT, asyncDepositFiat, DEPOSIT_FIAT_CLEAR_STATE } from './state/actions';
import { ComponentState } from '../../Dashboard/state/initialState';
import { getWalletBalance } from '../../Dashboard/state/actions';
import { EmptyAC, PayloadAC } from 'typesafe-actions';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    boxShadow: '0 10px 30px 0 rgba(132,141,182,0.25)',
    verticalAlign: 'sub',
    padding: 20,
  },
  inputFields: {
    marginTop: 5,
    height: 44,
    boxSizing: 'border-box',
    border: `1px solid ${theme.palette.secondary.light}`,
    borderRadius: 4,
    backgroundColor: `${Colors.white}`,
    paddingLeft: 12,
    width: '100%',
  },
  labels: {
    fontWeight: 'bold',
    color: theme.palette.primary.light,
    marginTop: 30,
    marginBottom: 8,
  },
  selectFiatLabel: {
    color: theme.palette.secondary.light,
    fontSize: 14,
    fontWeight: 800,
    letterSpacing: 1,
    textTransform: 'uppercase',
    marginBottom: 30,
  },
  button: {
    marginTop: 40,
    textTransform: 'none',
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 1,
    textAlign: 'center',
  },
  buttonSuccess: {
    backgroundColor: `${Colors.havelockBlue}`,
    marginTop: 40,
    '&:hover': {
      backgroundColor: `${Colors.havelockBlue}`,
    },
  },
  inputError: {
    color: `${Colors.red}`,
    marginTop: 10,
  },
}));
type Props = {
  componentState: FiatDepositState;
  wallets: Map<string, Wallet>;
  dashboardState: ComponentState;
  asyncDepositFiat: PayloadAC<typeof DEPOSIT_FIAT, DepositFiatRequest>;
  depositFiatClearState: EmptyAC<typeof DEPOSIT_FIAT_CLEAR_STATE>;
};
const FiatDeposit = ({
  componentState,
  wallets,
  dashboardState,
  asyncDepositFiat,
  depositFiatClearState,
}: Props) => {
  const classes = useStyles();
  const [selectedCurrency, setSelectedCurrency] = useState({
    name: 'EURO',
    value: 'Euro',
  });
  const { inProgress, success, error, fail } = componentState;
  const { coinsTableInProgress } = dashboardState;

  const fiatWallets = Object.values(wallets).filter(wallet => wallet.network === 'fiat');
  const selectedWallet = fiatWallets.find(wallet => wallet.name === selectedCurrency.value);

  const currencyBalance =
    !coinsTableInProgress &&
    Number(utils.formatBalanceToBigWithDecimals(selectedWallet.balance, 2));

  const dropdownFiatCurrencies = fiatWallets.map(wallet => ({
    name: toUpper(wallet.name),
    value: wallet.name,
  }));

  const getSymbolByName = (name: string) => {
    switch (name) {
      case 'Euro':
        return 'EUR';
      default:
        return name;
    }
  };

  const submit = (amount: number) => {
    asyncDepositFiat({
      symbol: getSymbolByName(selectedCurrency.value),
      amount: Number(utils.formatBalanceToBigWithDecimals(amount, -2)),
    });
  };

  const initValues = {
    amount: '',
    selectedCurrency: selectedCurrency.name,
  };

  useEffect(() => {
    depositFiatClearState();
  }, []);
  return (
    <Card className={classes.card}>
      <CardContent>
        <Formik
          initialValues={initValues}
          onSubmit={(value, actions) => {
            actions.setSubmitting(false);
            submit(toNumber(value.amount));
          }}
          validationSchema={yup.object({
            amount: yup
              .number()
              .positive(`Entered amount must be a positive number!`)
              .required(`This field is required!`),
          })}
        >
          {({ isSubmitting, errors, touched }) => {
            return (
              <Form>
                <Typography className={classes.selectFiatLabel} component="p">
                  Select fiat currency:
                </Typography>

                <Grid xs={12} sm="auto" item>
                  <SacretSelect
                    multiple={false}
                    items={dropdownFiatCurrencies}
                    label={toUpper(selectedCurrency.name)}
                    onChange={(currency: string) => {
                      setSelectedCurrency({ name: currency, value: currency });
                    }}
                    stateFilterValue={selectedCurrency.value}
                  />
                </Grid>

                <InputLabel className={classes.labels}>
                  Current balance: {currencyBalance} {getSymbolByName(selectedCurrency.value)}
                </InputLabel>

                <InputLabel className={classes.labels}>Deposit amount:</InputLabel>
                <Field id="amount" type="number" name="amount" className={classes.inputFields} />

                {errors.amount && touched.amount ? (
                  <Box className={classes.inputError}>{errors.amount}</Box>
                ) : null}

                <Grid container alignItems="center" justifyContent="center">
                  {inProgress || coinsTableInProgress ? (
                    <CircularProgress />
                  ) : (
                    <CustomButtonPrimary
                      type="submit"
                      variant="contained"
                      size="large"
                      fullWidth
                      className={success ? classes.buttonSuccess : classes.button}
                      disabled={isSubmitting}
                    >
                      {success ? 'Deposit successfully added!' : 'Add'}
                    </CustomButtonPrimary>
                  )}
                </Grid>
                {fail && (
                  <Box className={classes.inputError}>
                    {error ? error : 'Adding deposit failed.'}
                  </Box>
                )}
              </Form>
            );
          }}
        </Formik>
      </CardContent>
    </Card>
  );
};

const mapDispatchToProps = {
  asyncDepositFiat: asyncDepositFiat.request,
  depositFiatClearState: asyncDepositFiat.cancel,
  getWalletBalance,
};

const mapStateToProps = (state: AppState) => {
  const componentState = getFiatDepositStateSelector(state);
  const wallets = getDashboardWalletsStateSelector(state);
  const dashboardState = getDashboardChartStateSelector(state);

  return {
    componentState,
    wallets,
    dashboardState,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FiatDeposit);
