import { Tooltip, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';
import SVG from 'react-inlinesvg';
import award from '../../../../assets/membership/award.svg';
import awardFilled from '../../../../assets/membership/awardFilled.svg';
import basic from '../../../../assets/membership/basic.svg';
import club from '../../../../assets/membership/club.svg';
import diamond from '../../../../assets/membership/diamond.svg';
import { User } from '../../../../data/typedefs/user';
import { Colors } from '../../../../ui/style/colors';
import { roles } from '../../../../utils/constants/roles';
import { ProfileInfoState } from '../../HomeContent/Settings/Membership/state/initialState';

export const membershipTitle = (membership: string): string => {
  switch (membership) {
    case 'sl-club-affiliate':
      return 'SL club affiliate';
    case 'basic-affiliate':
      return 'Basic affiliate';
    case 'bronze-affiliate':
      return 'Bronze affiliate';
    case 'silver-affiliate':
      return 'Silver affiliate';
    case 'gold-affiliate':
      return 'Gold affiliate';
    case 'platina-affiliate':
      return 'Platina affiliate';
    case 'diamond-affiliate':
      return 'Diamond affiliate';
    default:
      return 'No affiliate package';
  }
};

const styles = () =>
  createStyles({
    link: {
      float: 'right',
      display: 'flex',
    },
    rightWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    role: {
      textTransform: 'capitalize',
    },
    blockchainWrapper: {
      marginLeft: '12px',
    },
    blockchainIcon: {
      fill: 'white',
    },
    membershipIcon: {
      height: 25,
      width: 25,
      paddingLeft: 8,
    },
  });

const useStyles = makeStyles(styles);

type Props = {
  profileInfo: ProfileInfoState;
  user: User;
};

type membershipLevel = { key: string; icon: string; color: string };

const UserEmailAndMembership: React.FC<Props> = ({ profileInfo, user }) => {
  const classes = useStyles();
  const key: string | null = profileInfo.affiliate_package_info
    ? profileInfo.affiliate_package_info.affiliate_package_identifier
    : null;

  const awailableMembershipLevels: membershipLevel[] = [
    { key: 'sl-club-affiliate', icon: club, color: `${Colors.linkWater}` },
    { key: 'basic-affiliate', icon: basic, color: `${Colors.white}` },
    { key: 'bronze-affiliate', icon: award, color: `${Colors.brandyPunch}` },
    { key: 'silver-affiliate', icon: award, color: `${Colors.linkWater}` },
    { key: 'gold-affiliate', icon: awardFilled, color: `${Colors.gold}` },
    { key: 'platina-affiliate', icon: awardFilled, color: `${Colors.westar}` },
    { key: 'diamond-affiliate', icon: diamond, color: `${Colors.frenchPass}` },
  ];
  const membership = key
    ? awailableMembershipLevels.find((level: membershipLevel) => level.key === key)
    : null;
  return (
    <>
      <Typography component="span">{user.account && user.account.email}</Typography>
      {(roles.isAdmin(user.roles) || roles.isOwner(user.roles)) && (
        <Typography component="span" className={classes.role}>
          &nbsp;({(roles.isAdmin(user.roles) && 'Admin') || 'Owner'})
        </Typography>
      )}
      {membership && (
        <Tooltip
          title={membershipTitle(profileInfo.affiliate_package_info.affiliate_package_identifier)}
        >
          <SVG
            preProcessor={code => code.replace(/fill=".*?"/g, 'fill="membership.color"')}
            src={membership.icon}
            fill={membership.color}
            className={classes.membershipIcon}
          />
        </Tooltip>
      )}
    </>
  );
};

export default UserEmailAndMembership;
