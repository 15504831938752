import { Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { EmptyAC, PayloadAC } from 'typesafe-actions';
import LightTooltip from '../../../../../components/LightTooltip';
import SacretDaterangePickerSelect from '../../../../../components/SacrateDaterangePickerSelect';
import SacretSearchInput from '../../../../../components/SacretSearchInput';
import SacretSelect from '../../../../../components/SacretSelect';
import { GetReferralsPaginatedRequest } from '../../../../../data/repositories/affiliate/typedefs';
import { SaveTransactionFilter } from '../../../../../data/repositories/transaction/typedef';
import AppliedFiltersChips from './AppliedFiltersChips';

const styles = (theme: Theme) =>
  createStyles({
    filter: {
      minWidth: 200,
      marginRight: 10,
    },
    valueSlider: {},
    clearAll: {
      textTransform: 'none',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    clearAllIcon: {
      fontSize: 12,
      padding: 5,
    },
    label: {
      float: 'left',
      padding: '2px 0',
      fontSize: 12,
      color: theme.palette.primary.light,
    },
    tooltip: {
      left: '-5px',
      top: 0,
      fontSize: 18,
    },
    minWidth: {
      minWidth: 236,
    },
  });

const useStyles = makeStyles(styles);

type Props = {
  toggleFilter: PayloadAC<'orders/toggle_filter', SaveTransactionFilter>;
  appliedFilters: Map<string, string[]>;
  clearAllFilters: EmptyAC<'orders/clear_all_filters'>;
  asyncGetMerchantsReferralsPaginated: PayloadAC<
    'orders/get_merchants_referrals_paginated',
    GetReferralsPaginatedRequest
  >;
  order_type: string;
};

const names = [
  { name: 'Ether payment', value: 'ether_payment' },
  { name: 'Wire transfer', value: 'wire_transfer' },
  { name: 'Wallet', value: 'other' },
];

const statuses = [
  { name: 'Accepted', value: 'accepted' },
  { name: 'Rejected', value: 'rejected' },
  { name: 'Pending', value: 'created' },
];

const TransactionsFilterBar: React.FC<Props> = ({
  toggleFilter,
  appliedFilters,
  clearAllFilters,
  asyncGetMerchantsReferralsPaginated,
  order_type,
}: Props): JSX.Element => {
  const [emailSearchValue, setEmailSearchValue]: any = useState('');
  const [refOrHashNumber, setRefOrHashNumber]: any = useState('');
  const [nameSearchValue, setNameSearchValue]: any = useState('');
  const classes = useStyles();

  const appliedEmailFilter = (value: string) => {
    const object: any = {};
    appliedFilters.forEach((value, key) => {
      object[key] = value;
    });
    asyncGetMerchantsReferralsPaginated({
      page: 0,
      perPage: 10,
      order_type,
      appliedFilters: { ...object, searchInput: value },
    });

    toggleFilter({ filterType: 'searchInput', value });
    setEmailSearchValue('');
  };

  const appliedRefOrHashFilter = (value: string) => {
    const object: any = {};
    appliedFilters.forEach((value, key) => {
      object[key] = value;
    });
    asyncGetMerchantsReferralsPaginated({
      page: 0,
      perPage: 10,
      order_type,
      appliedFilters: { ...object, ['refOrHashNumber']: value },
    });
    toggleFilter({ filterType: 'refOrHashNumber', value });
    setRefOrHashNumber('');
  };

  const appliedNameFilter = (value: string) => {
    const object: any = {};
    appliedFilters.forEach((value, key) => {
      object[key] = value;
    });
    asyncGetMerchantsReferralsPaginated({
      page: 0,
      perPage: 10,
      order_type,
      appliedFilters: { ...object, order_name: value },
    });

    toggleFilter({ filterType: 'order_name', value });
    setNameSearchValue('');
  };

  return (
    <Grid container spacing={2}>
      <Grid xs={12} sm="auto" item className={classes.filter}>
        <SacretSelect
          items={statuses}
          label="Status"
          onChange={(value: string[]) => {
            const object: any = {};
            appliedFilters.forEach((value, key) => {
              object[key] = value;
            });
            asyncGetMerchantsReferralsPaginated({
              page: 0,
              perPage: 10,
              order_type,
              appliedFilters: { ...object, status: value },
            });
            toggleFilter({ filterType: 'status', value });
          }}
          stateFilterValue={appliedFilters.get('status')}
        />
      </Grid>
      <Grid xs={12} sm="auto" item className={classes.filter}>
        <SacretSelect
          items={names}
          label="Payment type"
          onChange={(value: string[]) => {
            const object: any = {};
            appliedFilters.forEach((value, key) => {
              object[key] = value;
            });
            asyncGetMerchantsReferralsPaginated({
              page: 0,
              perPage: 10,
              order_type,
              appliedFilters: { ...object, transactionType: value },
            });

            toggleFilter({ filterType: 'transactionType', value });
          }}
          stateFilterValue={appliedFilters.get('transactionType')}
          onlyShowAdornmentFor="ether_payment"
          endAdornment={
            <LightTooltip
              title="Manual Ethereum transactions"
              interactive={true}
              className={classes.tooltip}
            />
          }
        />
      </Grid>
      <Grid item xs={12} sm="auto" className={`${classes.filter} ${classes.minWidth}`}>
        <SacretSearchInput
          onChange={(value: string[]) => setEmailSearchValue(value)}
          stateFilterValue={[emailSearchValue]}
          searchHandler={() => appliedEmailFilter(emailSearchValue)}
        />
      </Grid>
      {order_type === 'project_invest' && (
        <Grid item xs={12} sm="auto" className={`${classes.filter} ${classes.minWidth}`}>
          <SacretSearchInput
            placeholder="Search by real estate name"
            onChange={(value: string[]) => setNameSearchValue(value)}
            stateFilterValue={[nameSearchValue]}
            searchHandler={() => appliedNameFilter(nameSearchValue)}
          />
        </Grid>
      )}
      <Grid item xs={12} sm="auto" className={`${classes.filter} ${classes.minWidth}`}>
        <SacretSearchInput
          placeholder="Search by ref/proof number"
          onChange={(value: string[]) => setRefOrHashNumber(value)}
          stateFilterValue={[refOrHashNumber]}
          searchHandler={() => appliedRefOrHashFilter(refOrHashNumber)}
          endAdornment={
            <LightTooltip
              title="Reference number should have up to eight digits"
              interactive={true}
              className={classes.tooltip}
            />
          }
        />
      </Grid>
      <Grid xs={12} sm="auto" item className={`${classes.filter} ${classes.minWidth}`}>
        <SacretDaterangePickerSelect
          label="Choose a date"
          onChange={(value: string[]) => {
            const object: any = {};
            appliedFilters.forEach((value, key) => {
              object[key] = value;
            });
            asyncGetMerchantsReferralsPaginated({
              page: 0,
              perPage: 10,
              order_type,
              appliedFilters: { ...object, dateFilter: value },
            });
            toggleFilter({ filterType: 'dateFilter', value });
          }}
          stateFilterValue={appliedFilters.get('dateFilter')}
        />
      </Grid>
      <AppliedFiltersChips
        clearAllFilters={clearAllFilters}
        stateAppliedFilters={appliedFilters}
        toggleFilter={toggleFilter}
        order_type={order_type}
      />
    </Grid>
  );
};

export default TransactionsFilterBar;
