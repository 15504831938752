import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputLabel,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { findIndex } from 'lodash';
import moment from 'moment';
import React, { useEffect } from 'react';
import { PayloadAC } from 'typesafe-actions';
import Satellite from '../../../../../assets/satelite.svg';
import accountRepo from '../../../../../data/repositories/account';
import { AllCoinDecimals } from '../../../../../data/repositories/wallet/typedefs';
import { Colors } from '../../../../../ui/style/colors';
import { ALL_COINS } from '../../../../../utils/constants/amounts';
import { getCurrencySymbolName } from '../../../../../utils/helper/utils';
import { membershipTitle } from '../../../HomeHeader/UserEmailAndMembership/UserEmailAndMembership';
import { dialogState } from '../AffiliateTable/state/initialState';
import AffiliateWallet from './AffiliateWallet';

const styles = (theme: Theme) =>
  createStyles({
    dialog: {
      borderRadius: 6,
      backgroundColor: 'transparent',
      paddingLeft: 30,
      paddingRight: 30,
      minWidth: 500,
      '@media (max-width:64rem)': {
        minWidth: '100%',
      },
    },
    button: {
      marginTop: 30,
      marginBottom: 30,
    },
    closeButton: {
      position: 'absolute',
      right: 5,
      top: 5,
      color: theme.palette.primary.light,
    },
    dialogTittleText: {
      color: `${Colors.blueCharcoal}`,
      fontSize: 18,
      fontWeight: 600,
      letterSpacing: 1.13,
      display: 'flex',
      alignItems: 'center',
    },
    dialogTitle: {
      marginTop: 25,
      marginLeft: 25,
      marginRight: 25,
      padding: 0,
    },
    depositIcon: {
      marginRight: 20,
    },
    withdraw: {
      textAlign: 'center',
      marginBottom: 30,
    },
    dialogContent: {
      width: 600,
    },
    labels: {
      fontWeight: 'bold',
      color: theme.palette.primary.light,
      marginTop: 10,
    },
    values: {
      color: theme.palette.primary.main,
      marginTop: 10,
    },
    img: {
      boxSizing: 'border-box',
      textAlign: 'center',
      width: '100%',
    },
  });
const useStyles = makeStyles(styles);

type Props = {
  isFullProfileDialogOpen: PayloadAC<'affiliate/is_full_profile_dialog_open', boolean>;
  asyncPrepareFullProfileForAffiliate: PayloadAC<
    'affiliate/prepare_full_profile_for_affiliate',
    string
  >;
  allCoinDecimals: AllCoinDecimals;
  dialogState: dialogState;
};

const AffiliateFullProfileDialog: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const {
    allCoinDecimals,
    isFullProfileDialogOpen,
    dialogState,
    asyncPrepareFullProfileForAffiliate,
  } = props;

  useEffect(() => {
    if (
      dialogState.referralUser.id
        ? dialogState.referralUser.id
        : dialogState.referralUser.referral_id && dialogState.isFullProfileDialogOpen
    ) {
      asyncPrepareFullProfileForAffiliate(
        dialogState.referralUser.id
          ? dialogState.referralUser.id
          : dialogState.referralUser.referral_id || '',
      );
    }
  }, [dialogState.isFullProfileDialogOpen]);

  const onExit = () => {
    isFullProfileDialogOpen(false);
  };

  const findWallet = (coinSymbol: string) =>
    findIndex(dialogState.fullUserProfile.wallets, { symbol: getCurrencySymbolName(coinSymbol) });

  const getCountry = (countryAbbreviation: string) => {
    const country = accountRepo.getCountries.countries.find(
      country => country.abbreviation === countryAbbreviation,
    );

    return country ? country.name : 'none';
  };

  return (
    <Dialog
      open={dialogState.isFullProfileDialogOpen}
      className={classes.dialog}
      TransitionProps={{ onExited: onExit }}
    >
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <div className={classes.withdraw}>
          <Typography className={classes.dialogTittleText}>Affiliate full profile</Typography>

          {onExit ? (
            <IconButton className={classes.closeButton} onClick={onExit}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </div>
      </DialogTitle>

      <DialogContent>
        {dialogState.inProgress ? (
          <Grid
            container
            item
            xs={12}
            spacing={4}
            className={classes.dialogContent}
            style={{ minHeight: 500, justifyContent: 'center', alignItems: 'center' }}
          >
            <CircularProgress color="secondary" size={50} />
          </Grid>
        ) : dialogState.fail ? (
          <Box pb={3}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction="column"
              item
              xs={12}
              className={classes.dialogContent}
              spacing={2}
            >
              <Grid item>
                <img src={Satellite} alt="ilustration" className={classes.img} />
              </Grid>
              <Grid item className={classes.labels}>
                Something went wrong. Failed to fetch user data.
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Grid container item xs={12} spacing={4} className={classes.dialogContent}>
            <Grid item xs={12} sm={6}>
              <Typography className={classes.dialogTittleText}>KYC information</Typography>
              {dialogState.fullUserProfile.kyc_info ? (
                <>
                  <InputLabel className={classes.labels}>First name:</InputLabel>
                  <InputLabel className={classes.values}>
                    {dialogState.fullUserProfile.kyc_info.first_name || 'none'}
                  </InputLabel>
                  <InputLabel className={classes.labels}>Last name:</InputLabel>
                  <InputLabel className={classes.values}>
                    {dialogState.fullUserProfile.kyc_info.last_name || 'none'}
                  </InputLabel>
                  <InputLabel className={classes.labels}>Gender:</InputLabel>
                  <InputLabel className={classes.values}>
                    {dialogState.fullUserProfile.kyc_info.gender || 'none'}
                  </InputLabel>
                  <InputLabel className={classes.labels}>Date of birth:</InputLabel>
                  <InputLabel className={classes.values}>
                    {moment(dialogState.fullUserProfile.kyc_info.date_of_birth).format(
                      'DD/MM/YYYY HH:mm',
                    ) || 'none'}
                  </InputLabel>
                  <InputLabel className={classes.labels}>Phone number:</InputLabel>
                  <InputLabel className={classes.values}>
                    {dialogState.fullUserProfile.kyc_info.phone_number || 'none'}
                  </InputLabel>
                  <InputLabel className={classes.labels}>Citizenship:</InputLabel>
                  <InputLabel className={classes.values}>
                    {dialogState.fullUserProfile.kyc_info.citizenship || 'none'}
                  </InputLabel>

                  {dialogState.fullUserProfile.kyc_info.addresses ? (
                    <>
                      <InputLabel className={classes.labels}>Country:</InputLabel>
                      <InputLabel className={classes.values}>
                        {getCountry(dialogState.fullUserProfile.kyc_info.addresses.country)}
                      </InputLabel>

                      <InputLabel className={classes.labels}>City:</InputLabel>
                      <InputLabel className={classes.values}>
                        {dialogState.fullUserProfile.kyc_info.addresses.city || 'none'}
                      </InputLabel>

                      <InputLabel className={classes.labels}>Postcode:</InputLabel>
                      <InputLabel className={classes.values}>
                        {dialogState.fullUserProfile.kyc_info.addresses.postal_code || 'none'}
                      </InputLabel>

                      <InputLabel className={classes.labels}>Address:</InputLabel>
                      <InputLabel className={classes.values}>
                        {dialogState.fullUserProfile.kyc_info.addresses.address || 'none'}
                      </InputLabel>
                    </>
                  ) : (
                    <>
                      <InputLabel className={classes.labels}>Country:</InputLabel>
                      <InputLabel className={classes.values}>none</InputLabel>

                      <InputLabel className={classes.labels}>City:</InputLabel>
                      <InputLabel className={classes.values}>none</InputLabel>

                      <InputLabel className={classes.labels}>Postcode:</InputLabel>
                      <InputLabel className={classes.values}>none</InputLabel>

                      <InputLabel className={classes.labels}>Address:</InputLabel>
                      <InputLabel className={classes.values}>none</InputLabel>

                      <InputLabel className={classes.labels}>Apartment number:</InputLabel>
                      <InputLabel className={classes.values}>none</InputLabel>
                    </>
                  )}
                </>
              ) : (
                <InputLabel className={classes.values}>
                  This user doesn&apos;t have KYC info
                </InputLabel>
              )}

              <InputLabel className={classes.labels}>Membership level:</InputLabel>

              <InputLabel className={classes.values}>
                {dialogState.fullUserProfile.affiliate_package_info
                  ? membershipTitle(
                      dialogState.fullUserProfile.affiliate_package_info
                        .affiliate_package_identifier,
                    )
                  : 'none'}
              </InputLabel>
            </Grid>

            {ALL_COINS.map((coin: string) => (
              <AffiliateWallet
                key={coin}
                walletName={getCurrencySymbolName(coin)}
                walletPosition={findWallet(coin)}
                decimals={allCoinDecimals[coin]}
                dialogState={dialogState}
              />
            ))}
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AffiliateFullProfileDialog;
