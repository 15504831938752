import { CircularProgress, Paper, TablePagination } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { PayloadAC } from 'typesafe-actions';
import { getCoinDecimalsSelector } from '../../App/Home/HomeContent/Wallet/state/selectors';
import { AppState } from '../../App/state/initialState';
import { WalletTransfer } from '../../data/repositories/transaction/typedef';
import { AllCoinDecimals } from '../../data/repositories/wallet/typedefs';
import { Colors } from '../../ui/style/colors';
import { ALL_COINS } from '../../utils/constants/amounts';
import TablePaginationActions from '../TablePaginationActions';
import WalletTransfersTableRow from './WalletTransfersTableRow';

type StylesProps = {
  isWidget: boolean;
};

const useStyles = makeStyles<Theme, StylesProps>((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      overflowY: 'auto',
      boxShadow: '0 10px 30px 0 rgba(132,141,182,0.25)',
      backgroundColor: `${Colors.white}`,
      borderRadius: '3px',
    },
    table: ({ isWidget }) => ({
      minWidth: isWidget ? '100%' : 650,
      borderCollapse: 'collapse',
      borderRadius: 6,
    }),
    head: {
      backgroundColor: ({ isWidget }) => (isWidget ? 'none' : 'rgba(242, 243, 247, 0.7)'),
    },
    headerText: ({ isWidget }) => ({
      color: theme.palette.primary.light,
      fontSize: 12,
      fontWeight: isWidget ? 500 : 600,
      letterSpacing: isWidget ? 'normal' : 0.86,
    }),
    headerButton: {
      textAlign: 'right',
      padding: '0 !important',
      width: '10%',
    },
    claimReward: {
      borderRadius: ' 0 8px 0 8px',
      backgroundColor: `${Colors.mediumTurquoise}`,
    },

    pagination: { flexWrap: 'wrap', justifyContent: 'space-between', borderRadius: 5 },
    spacer: { flex: '0 0 100%' },
  }),
);

export type HeaderProps = {
  name: string;
  align?: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined;
};

type Props = {
  walletTransfers: Map<string, WalletTransfer>;
  headers: HeaderProps[];
  allCoinDecimals: AllCoinDecimals;
  totalRows: number;
  page: number;
  inProgress: boolean;
  filter: any;
  isWidget?: boolean;
  getWalletTransfersPaginated:
    | PayloadAC<'transactions/get_wallet_tranfers', any>
    | PayloadAC<'admin/user_transaction_history/get_wallet_tranfers', any>;
  onPageChange?: (newPage: number) => void;
};

const WalletTransfersTable = ({
  page,
  totalRows,
  walletTransfers,
  filter,
  headers,
  inProgress,
  isWidget = false,
  getWalletTransfersPaginated,
  onPageChange,
  allCoinDecimals,
}: Props) => {
  const styles: StylesProps = { isWidget };
  const classes = useStyles(styles);
  const dispath = useDispatch();
  const [expanded, setExpanded] = React.useState(-1);
  const { coin } = useParams<{ coin: string }>();

  useEffect(() => {
    setExpanded(-1);
  }, [walletTransfers]);

  const handleChangePage = (event: unknown, newPage: number) => {
    if (onPageChange) {
      return onPageChange(newPage);
    }
    const object: any = {};
    filter.forEach((value: any, key: React.ReactText) => {
      object[key] = value;
    });

    if (coin) {
      object['coinSymbol'] = coin;
    }

    dispath({ type: 'transfers/set_table', payload: newPage });
    getWalletTransfersPaginated({
      page: newPage,
      perPage: 10,
      appliedFilters: {
        ...object,
      },
    });
  };

  const rowsPerPage = 10;
  const emptyRows = rowsPerPage - Object.keys(walletTransfers).length;

  const table = (
    <Table className={classes.table}>
      <TableHead className={classes.head}>
        <TableRow>
          {headers.map(header => (
            <TableCell
              className={classes.headerText}
              key={header.name}
              align={header.align}
              component={'th'}
            >
              {header.name}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      {inProgress || Boolean(!allCoinDecimals['SLC']) ? (
        <TableBody style={{ minHeight: 530 }}>
          <TableRow>
            <TableCell colSpan={6} style={{ textAlign: 'center' }}>
              <CircularProgress color="secondary" size={60} />
            </TableCell>
          </TableRow>
        </TableBody>
      ) : (
        <TableBody style={{ minHeight: 530 }}>
          {Object.entries(walletTransfers).map(([key, value], index) => (
            <WalletTransfersTableRow
              key={key}
              walletTransfer={value}
              expanded={expanded === index}
              setExpanded={() => {
                setExpanded(prev => (prev === index ? -1 : index));
              }}
              isWidget={isWidget}
              allCoinDecimals={allCoinDecimals}
            />
          ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
      )}
    </Table>
  );

  return isWidget ? (
    <> {table} </>
  ) : (
    <Paper className={classes.root}>
      {table}
      <TablePagination
        component="div"
        count={totalRows}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[]}
        page={page}
        labelDisplayedRows={({ from, to, count }: { from: number; to: number; count: number }) => {
          return `Showing ${from} to ${to} of ${count}`;
        }}
        backIconButtonProps={{
          'aria-label': 'previous page',
        }}
        nextIconButtonProps={{
          'aria-label': 'next page',
        }}
        onPageChange={handleChangePage}
        ActionsComponent={TablePaginationActions}
        classes={{
          toolbar: classes.pagination,
          spacer: classes.spacer,
        }}
      />
    </Paper>
  );
};
const mapStateToProps = (state: AppState) => {
  const allCoinDecimals = ALL_COINS.reduce(function (acc: any, coin: string) {
    acc[coin] = getCoinDecimalsSelector(coin)(state);
    return acc;
  }, {});
  return { allCoinDecimals };
};
export default connect(mapStateToProps)(WalletTransfersTable);
