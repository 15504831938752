import { createSelector } from 'reselect';
import { AppState } from '../../../../../state/initialState';

const getAffiliateInfoState = ({
  home: {
    affiliate: { affiliateInfo },
  },
}: AppState) => affiliateInfo;

export const affiliateInfoStateSelector = createSelector(
  getAffiliateInfoState,
  affiliateInfo => affiliateInfo,
);
