import { connect } from 'react-redux';
import { AppState } from '../../../../../state/initialState';
import { getWithdrawAddress, getWithdrawAmount } from '../../../../state/common/selectors';
import {
  asyncCreateRevolutCounterparty,
  openWithdrawDialog,
  withdrawCurrency,
} from '../../state/actions';
import {
  getSelectedCoinSelector,
  getWalletBySymbolSelectorSelector,
  withdrawErrorSelector,
  withdrawInProgressSelector,
} from '../../state/selectors';
import WithdrawCoins2fa from './WithdrawCoins2fa';

const mapDispatchToProps = {
  withdrawCurrency: withdrawCurrency,
  asyncCreateRevolutCounterparty: asyncCreateRevolutCounterparty.request,
  openWithdrawDialog: openWithdrawDialog,
};
const mapStateToProps = (state: AppState) => {
  const amount = getWithdrawAmount(state);
  const address = getWithdrawAddress(state);
  const withdrawError = withdrawErrorSelector(state);
  const withdrawInProgress = withdrawInProgressSelector(state);
  const wallet = getWalletBySymbolSelectorSelector(state);
  const selectedCoin = getSelectedCoinSelector(state);

  return { amount, address, withdrawError, withdrawInProgress, wallet, selectedCoin };
};

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawCoins2fa);
