import { all, put, takeEvery } from 'redux-saga/effects';
import LocalStorage from '../../../../../../data/LocalStorage';
import api from '../../../../../../data/repositories/account';
import { USER_ID } from '../../../../../../utils/constants/localStorageKeys';
import {
  changePassword,
  changePasswordFail,
  changePasswordSuccess,
  CHANGE_PASSWORD,
} from './actions';

export function* changePasswor$(action: ReturnType<typeof changePassword>) {
  try {
    const userId = LocalStorage.get(USER_ID);
    yield api.changePassword(action.payload, userId ? userId : '');

    yield put(changePasswordSuccess());
  } catch (error) {
    yield put(changePasswordFail(error));
  }
}

export default function* () {
  yield all([takeEvery(CHANGE_PASSWORD, changePasswor$)]);
}
