import { CircularProgress, Paper, TablePagination } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { getCoinDecimalsSelector } from '../../App/Home/HomeContent/Wallet/state/selectors';
import { AppState } from '../../App/state/initialState';
import { Referral } from '../../data/repositories/affiliate/typedefs';
import { AllCoinDecimals } from '../../data/repositories/wallet/typedefs';
import { Colors } from '../../ui/style/colors';
import { ALL_COINS } from '../../utils/constants/amounts';
import TablePaginationActions from '../TablePaginationActions';
import OrdersTableRow from './OrdersTableRow';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      overflowY: 'auto',
      boxShadow: '0 10px 30px 0 rgba(132,141,182,0.25)',
      backgroundColor: `${Colors.white}`,
      borderRadius: '3px',
    },
    table: {
      minWidth: 650,
      borderCollapse: 'collapse',
      borderRadius: 6,
    },
    head: {
      backgroundColor: 'rgba(242, 243, 247, 0.7)',
    },
    headerText: {
      color: theme.palette.primary.light,
      fontSize: 12,
      fontWeight: 600,
      letterSpacing: 0.86,
    },
    headerButton: {
      textAlign: 'right',
      padding: '0 !important',
      width: '10%',
    },
    claimReward: {
      borderRadius: ' 0 8px 0 8px',
      backgroundColor: `${Colors.mediumTurquoise}`,
    },
    button: {
      fontSize: 14,
      fontWeight: 600,
      textTransform: 'none',
      height: 47,
    },
    pagination: { flexWrap: 'wrap', justifyContent: 'space-between', borderRadius: 5 },
    spacer: { flex: '0 0 100%' },
  }),
);

export type HeaderProps = {
  name: string;
  align?: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined;
};

type Props = {
  referrals: Map<string, Referral>;
  headers: HeaderProps[];
  totalReferrals: number;
  page: number;
  filter: any;
  inProgress: boolean;
  order_type: string;
  allCoinDecimals: AllCoinDecimals;
};

const OrdersTable = ({
  referrals,
  headers,
  page,
  totalReferrals,
  inProgress,
  filter,
  order_type,
  allCoinDecimals,
}: Props) => {
  const classes = useStyles();
  const dispath = useDispatch();

  const [expanded, setExpanded] = React.useState(-1);

  useEffect(() => {
    setExpanded(-1);
  }, [referrals]);

  const handleChangePage = (event: unknown, newPage: number) => {
    const object: any = {};
    filter &&
      filter.forEach((value: any, key: React.ReactText) => {
        object[key] = value;
      });
    dispath({ type: 'orders/set_merchants_table_page', payload: newPage });
    dispath({
      type: 'orders/get_merchants_referrals_paginated',
      payload: {
        page: newPage,
        perPage: 10,
        order_type,
        appliedFilters: {
          ...object,
        },
      },
    });
  };

  const rowsPerPage = 10;
  const emptyRows = rowsPerPage - Object.keys(referrals).length;

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead className={classes.head}>
          <TableRow>
            {headers.map(header => (
              <TableCell
                className={classes.headerText}
                key={header.name}
                align={header.align}
                component={'th'}
              >
                {header.name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {inProgress || Boolean(!allCoinDecimals['SLC']) ? (
          <TableBody style={{ minHeight: 530 }}>
            <TableRow>
              <TableCell colSpan={8} style={{ textAlign: 'center' }}>
                <CircularProgress color="secondary" size={60} />
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <TableBody style={{ minHeight: 530 }}>
            {Object.entries(referrals).map(([key, value], index) => (
              <OrdersTableRow
                expanded={expanded === index}
                setExpanded={custom => {
                  if (custom === 'close') {
                    setExpanded(-1);
                  } else setExpanded(prev => (prev === index ? -1 : index));
                }}
                key={key}
                referral={value}
                order_type={order_type}
                allCoinDecimals={allCoinDecimals}
                page={page}
                filter={filter}
              />
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        )}
      </Table>

      <TablePagination
        component="div"
        count={totalReferrals}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[]}
        page={page}
        labelDisplayedRows={({ from, to, count }: { from: number; to: number; count: number }) => {
          return `Showing ${from} to ${to} of ${count}`;
        }}
        backIconButtonProps={{
          'aria-label': 'previous page',
        }}
        nextIconButtonProps={{
          'aria-label': 'next page',
        }}
        onPageChange={handleChangePage}
        ActionsComponent={TablePaginationActions}
        classes={{
          toolbar: classes.pagination,
          spacer: classes.spacer,
        }}
      />
    </Paper>
  );
};

const mapStateToProps = (state: AppState) => {
  const allCoinDecimals = ALL_COINS.reduce(function (acc: any, coin: string) {
    acc[coin] = getCoinDecimalsSelector(coin)(state);
    return acc;
  }, {});
  return { allCoinDecimals };
};
export default connect(mapStateToProps)(OrdersTable);
