import { CircularProgress, Grid, Paper, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { EmptyAC } from 'typesafe-actions';
import CopyToClipboard from '../../../../../components/CopyToClipboard';
import { Colors } from '../../../../../ui/style/colors';
import { AffiliateTableState } from '../AffiliateTable/state/initialState';
import { AffiliateInfoState } from './state/initialState';

const baseURL = process.env.REACT_APP_IAM_ROUTE;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: '3px',
      backgroundColor: `${Colors.white}`,
      boxShadow: '0 10px 30px 0 rgba(132,141,182,0.25)',
    },
    label: {
      color: theme.palette.secondary.light,
      fontSize: 14,
      fontWeight: 800,
      letterSpacing: 1,
      textTransform: 'uppercase',
      marginBottom: 12,
    },
    affiliatesLabel: {
      color: `${Colors.blueCharcoal}`,
      fontSize: 26,
      fontWeight: 'bold',
      [theme.breakpoints.down('sm')]: {
        fontSize: 20,
      },
    },
    item: {
      padding: 20,
      flex: '0 0 auto',
      maxWidth: '100%',
      minWidth: 160,
    },
  }),
);

type Props = {
  asyncGetAffiliateCode: EmptyAC<'affiliate/get_affiliate_code'>;
  asyncGetReferralsStats: EmptyAC<'affiliate/get_referrals_stats'>;
  affiliateTableState: AffiliateTableState;
  affiliateInfoState: AffiliateInfoState;
};

const AffiliateInfo: React.FC<Props> = (props: Props): JSX.Element => {
  const classes = useStyles();
  const {
    asyncGetAffiliateCode,
    affiliateInfoState: { code, getCodeInProgress },
    asyncGetReferralsStats,
    affiliateTableState: {
      inProgress,
      stats: { totalReferrals, directReferrals },
    },
  } = props;

  useEffect(() => {
    if (code == '') {
      asyncGetAffiliateCode();
      asyncGetReferralsStats();
    }
  }, []);

  return (
    <Paper className={classes.root}>
      <Grid item container>
        <Grid item xs={3} className={classes.item}>
          <Typography className={classes.label} component="p">
            Network
          </Typography>
          {inProgress && !totalReferrals ? (
            <CircularProgress color="secondary" size={30} />
          ) : (
            <Typography className={classes.affiliatesLabel} component="p">
              {totalReferrals}
            </Typography>
          )}
        </Grid>

        <Grid item xs={3} className={classes.item}>
          <Typography className={classes.label} component="p">
            Direct downline affiliates
          </Typography>
          {inProgress && !totalReferrals ? (
            <CircularProgress color="secondary" size={30} />
          ) : (
            <Typography className={classes.affiliatesLabel} component="p">
              {directReferrals}
            </Typography>
          )}
        </Grid>
        <Grid item xs={4} className={classes.item}>
          <Typography className={classes.label} component="p">
            Affiliate code
          </Typography>
          <Grid container item xs={12}>
            {getCodeInProgress ? (
              <CircularProgress color="secondary" size={30} />
            ) : (
              <>
                <CopyToClipboard
                  // link={`${baseURL}/register?referral_code=${code}&returnUrl=${window.location.origin}/autologin`}
                  code={code}
                />
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default AffiliateInfo;
