import {
  CreateDepositOrderRequest,
  DepositTermRequest,
} from '../../../App/Home/HomeContent/Wallet/state/initialState';
import BchCoin from '../../../assets/coins/bchCoin.svg';
import Bitcoin from '../../../assets/coins/bitcoin.svg';
import Tether from '../../../assets/coins/tether.svg';
import Ethereum from '../../../assets/coins/Ethereum.svg';
import LtcCoin from '../../../assets/coins/ltcCoin.svg';
import SacretLifeCoin from '../../../assets/coins/sacretLifeCoin.svg';
import close from '../../../assets/Shape@1.5x.svg';
import { Colors } from '../../../ui/style/colors';
import {
  BUY_WALLET,
  CREATE_DEPOSIT_ORDER,
  DEPOSIT_EUR,
  DEPOSIT_TERM_SAVING,
  EXCHANGE_ENDPOINT,
  GET_ENTIRE_WALLET_BALANCES,
  GET_TERM_SAVING_TYPES,
  REVOLUT_COUNTERPARTY,
  REVOLUT_WITHDRAW,
  SWAP_SLT_TO_SLC,
  USER_ENDPOINT,
} from '../../../utils/constants/api';
import Http from '../../Http';
import {
  CreateRevolutCounterpartyData,
  CreateWalletForCurrencyResponse,
  GetCoinPricesResponse,
  GetWalletBalanceResponse,
  GetWalletForCurrencyResponse,
  Wallet,
  WithdrawCurrencyRequest,
  WithdrawCurrencyResponse,
  WithdrawEurRequest,
} from './typedefs';

/* eslint-disable */
const getWallet = async (endpoint: string): Promise<GetWalletBalanceResponse> => {
  const wallets = (await Http.get(endpoint)).data;
  const removeSLT = wallets.filter(
    (wallet: Wallet) => wallet.name.toLowerCase() !== 'sacretlifetoken',
  );
  return removeSLT.map((wallet: Wallet) => {
    switch (wallet.name.toLowerCase()) {
      case 'ether':
      case 'ethereum':
        wallet.symbol = 'ETH';
        break;
      case 'litecoin':
        wallet.symbol = 'LTC';
        break;
      case 'bitcoin cache':
        wallet.symbol = 'BCH';
        break;
      case 'bitcoin':
        wallet.symbol = 'BTC';
        break;
      case 'tether usd':
        wallet.symbol = 'USDT';
        break;
      case 'euro':
        wallet.symbol = 'WALLET_EUR';
        break;
      default:
        wallet.symbol = 'SLC';
        break;
    }
    return wallet;
  });
};

const getCoinPrices = async (): Promise<GetCoinPricesResponse> =>
  (await Http.get(EXCHANGE_ENDPOINT)).data;

const getUser = async (userId: string): Promise<any> =>
  (await Http.get(USER_ENDPOINT(userId))).data;

const getWalletForCurrency = async (endpoint: string): Promise<GetWalletForCurrencyResponse> =>
  (await Http.get(endpoint)).data;

const createWalletForCurrency = async (
  endpoint: string,
): Promise<CreateWalletForCurrencyResponse> => (await Http.post(endpoint)).data;

const withdrawCurrency = async (
  endpoint: string,
  data: WithdrawCurrencyRequest,
): Promise<WithdrawCurrencyResponse> => (await Http.post(endpoint, data)).data;

const getEntireWalletBalances = async (accountId: string) =>
  (await Http.get(GET_ENTIRE_WALLET_BALANCES(accountId))).data;

const getTermSavingTypes = async (accountId: string) =>
  (await Http.get(GET_TERM_SAVING_TYPES(accountId))).data;

const depositTermSaving = async (accountId: string, data: DepositTermRequest) =>
  (await Http.post(DEPOSIT_TERM_SAVING(accountId), data)).data;

const allCoins = [
  {
    symbol: 'BTC',
    icon: Bitcoin,
    name: 'Bitcoin',
    type: 'Wallet',
    color: `${Colors.treePoppy}`,
  },
  { symbol: 'WIRE_EUR', icon: close, name: 'Wire transfer', color: `${Colors.logan}` },
  { symbol: 'WALLET_EUR', icon: close, name: 'EURO', type: 'Wallet', color: `${Colors.logan}` },
  {
    symbol: 'LTC',
    icon: LtcCoin,
    name: 'Litecoin',
    type: 'Wallet',
    color: `${Colors.azure}`,
  },
  {
    symbol: 'ETH',
    icon: Ethereum,
    name: 'Ethereum',
    type: 'Wallet',
    color: `${Colors.gray}`,
  },
  {
    symbol: 'BCH',
    icon: BchCoin,
    name: 'Bitcoin Cash',
    type: 'Wallet',
    color: `${Colors.carrotOrange}`,
  },
  {
    symbol: 'USDT',
    icon: Tether,
    name: 'Tether',
    type: 'Wallet',
    color: `${Colors.carrotOrange}`,
  },
  {
    symbol: 'SLC',
    icon: SacretLifeCoin,
    name: 'SLC',
    color: `${Colors.mediumTurquoise}`,
  },
];

const swapSLTtoSLC = async (accountId: string) => await Http.post(SWAP_SLT_TO_SLC(accountId));

const getRevolutCounterparty = async (accountId: string) =>
  (await Http.get(REVOLUT_COUNTERPARTY(accountId))).data;

const createRevolutCounterparty = async (accountId: string, data: CreateRevolutCounterpartyData) =>
  await Http.post(REVOLUT_COUNTERPARTY(accountId), data);

const updateRevolutCounterparty = async (accountId: string, data: CreateRevolutCounterpartyData) =>
  (await Http.put(REVOLUT_COUNTERPARTY(accountId), data)).data;

const withdrawEur = async (accountId: string, data: WithdrawEurRequest) =>
  await Http.post(REVOLUT_WITHDRAW(accountId), data);

const depositEur = async (accountId: string, data: { amount_in_eurocents: number | undefined }) =>
  (await Http.post(DEPOSIT_EUR(accountId), data)).data;

const createDepositOrder = async (accountId: string, data: CreateDepositOrderRequest) =>
  (await Http.post(CREATE_DEPOSIT_ORDER(accountId), data)).data;

const buyWallet = async (accountId: string, storeItemCode: string) =>
  (await Http.post(BUY_WALLET(accountId, storeItemCode))).data;

export default {
  getWallet,
  getWalletForCurrency,
  createWalletForCurrency,
  allCoins,
  withdrawCurrency,
  getCoinPrices,
  getUser,
  getEntireWalletBalances,
  getTermSavingTypes,
  depositTermSaving,
  swapSLTtoSLC,
  getRevolutCounterparty,
  createRevolutCounterparty,
  updateRevolutCounterparty,
  withdrawEur,
  depositEur,
  createDepositOrder,
  buyWallet,
};
