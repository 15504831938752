/* eslint-disable @typescript-eslint/indent */
import { CircularProgress } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { size } from 'lodash';
import React, { useEffect } from 'react';
import { PayloadAC } from 'typesafe-actions';
import Satelite from '../../../../../assets/satelite.svg';
import { HeaderProps } from '../../../../../components/AffiliatesPremiumTable';
import SacretEmpty from '../../../../../components/SacretEmpty';
import WalletTransfersTable from '../../../../../components/WalletTransfersTable';
import { WalletTransfer } from '../../../../../data/repositories/transaction/typedef';
import { WalletTransfersTableState } from './state/initialState';

const styles = (theme: Theme) =>
  createStyles({
    myAffiliatesList: {
      color: theme.palette.secondary.light,
      fontSize: 18,
      fontWeight: 800,
      paddingBottom: 30,
    },
    root: { width: '100%', minHeight: 530, position: 'relative' },
    center: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      marginTop: 0,
    },
  });

const useStyles = makeStyles(styles);

const headers: HeaderProps[] = [
  { name: '', align: 'center' },
  { name: 'Status', align: 'left' },
  { name: 'Transfer type', align: 'left' },
  { name: 'Date & Time', align: 'right' },
  { name: 'Currency', align: 'left' },
  { name: 'Amount', align: 'right' },
];
const widgetHeaders: HeaderProps[] = [
  { name: '', align: 'center' },
  { name: 'Amount', align: 'right' },
  { name: 'Currency', align: 'right' },
  { name: 'Date & Time', align: 'right' },
];

type Props = WalletTransfersTableState & {
  getWalletTransfersPaginated: PayloadAC<'transactions/get_wallet_tranfers', any>;
  appliedFilters?: any;
  isWidget?: boolean;
  coin?: string;
  membershipLevel?: any;
};

const TransfersTable: React.FC<Props> = ({
  walletTransfers,
  inProgress,
  totalRows,
  page,
  appliedFilters,
  isWidget,
  coin,
  getWalletTransfersPaginated,
  membershipLevel,
}): JSX.Element => {
  const classes = useStyles();

  useEffect(() => {
    if (membershipLevel && coin === 'WALLET_EUR') {
      coin = 'EUR';
    }
    appliedFilters.coinSymbol = coin;

    getWalletTransfersPaginated({
      page: 0,
      perPage: 10,
      appliedFilters,
    });
  }, [coin]);

  const isEmpty = size(walletTransfers) == 0;

  return (
    <div className={classes.root}>
      {inProgress && !isEmpty ? (
        <div className={classes.center}>
          <CircularProgress color="secondary" size={60} />
        </div>
      ) : (
        <>
          {!isEmpty ? (
            <WalletTransfersTable
              filter={appliedFilters}
              headers={isWidget ? widgetHeaders : headers}
              isWidget={isWidget}
              inProgress={inProgress}
              totalRows={totalRows}
              page={page}
              walletTransfers={
                walletTransfers ? walletTransfers : new Map<string, WalletTransfer>()
              }
              getWalletTransfersPaginated={getWalletTransfersPaginated}
            />
          ) : (
            <SacretEmpty
              image={Satelite}
              title="Oops"
              text="We couldn’t find any transfers"
              hasButton={false}
              className={classes.center}
            />
          )}
        </>
      )}
    </div>
  );
};

export default TransfersTable;
