import { CircularProgress, Grid, Input } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { PayloadAC } from 'typesafe-actions';
import {
  ConfirmTwoFactorAuthPayload,
  ConfirmTwoFactorAuthRequest,
} from '../../data/repositories/account/typedefs';
import { WithdrawCurrencyRequest } from '../../data/repositories/wallet/typedefs';
import { Colors } from '../../ui/style/colors';

const styles = (theme: Theme) =>
  createStyles({
    label: {
      color: theme.palette.primary.light,
      fontSize: 16,
      fontWeight: 'bold',
      textAlign: 'center',
      marginTop: 38,
      marginBottom: 26,
      marginRight: 49,
      marginLeft: 49,
    },
    digitInput: {
      boxSizing: 'border-box',
      border: `1px solid ${theme.palette.secondary.light}`,
      borderRadius: 4,
      backgroundColor: `${Colors.white}`,
      height: 44,
      width: 48,
      marginBottom: 30,
      marginRight: 10,
      marginLeft: 10,
    },
    inputText: {
      textAlign: 'center',
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'none',
      },
      '&[type=number]': {
        '-webkit-appearance': 'textfield',
        '-moz-appearance': 'textfield',
      },
      color: `${Colors.blueCharcoal}`,
      fontSize: 20,
      fontWeight: 'bold',
    },
    inputError: {
      color: `${Colors.red}`,
      marginBottom: '10px',
      marginLeft: '10px',
    },
    paper: {
      padding: '10px',
    },
  });
const useStyles = makeStyles(styles);

type Props = {
  login2fa?: PayloadAC<'login_2fa', ConfirmTwoFactorAuthPayload>;
  disable2fa?: PayloadAC<'settings/disable_two_factor_auth', ConfirmTwoFactorAuthRequest>;
  withdrawCurrency?: PayloadAC<'wallet/withdraw_currency', WithdrawCurrencyRequest>;
  onComplete?: (codeString: string) => void;
  withdrawData?: WithdrawCurrencyRequest;
  error?: Error | null;
  inProgress?: boolean;
  single?: boolean;
  callbackUrl?: string;
};

let code = new Map<number, string>();

const VerificationCodeInput = ({
  login2fa,
  error,
  inProgress,
  disable2fa,
  withdrawCurrency,
  withdrawData,
  onComplete,
  single = false,
  callbackUrl,
}: Props) => {
  const classes = useStyles();
  const focusFirstInput = () => (document.getElementById(`input1`) as HTMLInputElement).focus();
  const isMobile = window.innerWidth < 776;

  useEffect(() => {
    if (!inProgress) {
      code = new Map<number, string>();
      focusFirstInput();
    }
  }, [inProgress]);

  const replaceNumber = (number: string, index: number) => {
    let codeString = '';
    number != '' ? code.set(index, number) : code.delete(index);
    if (code.size == 6) {
      code.forEach(digit => {
        codeString += digit;
      });
      if (login2fa) {
        login2fa({
          twoFaRequest: { code: codeString },
          single,
          callbackUrl: callbackUrl,
        });
      }
      if (disable2fa) {
        disable2fa({ code: codeString });
      }
      if (withdrawCurrency) {
        let withdrawRequest: WithdrawCurrencyRequest = {
          code: '',
          amount: 0,
          address: '',
        };
        if (withdrawData) {
          withdrawRequest = {
            ...withdrawData,
            code: codeString,
            amount: Math.round(withdrawData.amount),
            address: withdrawData.address,
          };
        }
        withdrawCurrency(withdrawRequest);
      }
      if (onComplete) {
        onComplete(codeString);
      }
      (document.getElementById(`input1`) as HTMLInputElement).focus();
    }
  };
  const handlePaste = (e: React.ClipboardEvent) => {
    e.preventDefault();

    const clipboardData = e.clipboardData;
    const pastedCode = clipboardData.getData('text').split('');

    for (let i = 1; i <= 6; i++) {
      replaceNumber(pastedCode[i - 1], i);
    }
  };

  /**handleKeyDown should work for desktop */
  const handleKeyDown = (event: any) => {
    if (isMobile) {
      return false;
    }
    const currentInput = document.activeElement as HTMLInputElement;
    const inputNumber = Number(currentInput.id.slice(-1));

    const nextInputNumber = inputNumber + 1;
    const previousInputNumber = inputNumber - 1;

    const nextInput = document.getElementById(
      `input${nextInputNumber > 6 ? 6 : nextInputNumber}`,
    ) as HTMLInputElement;

    const previousInput = document.getElementById(
      `input${previousInputNumber > 0 ? previousInputNumber : 1}`,
    ) as HTMLInputElement;

    if (currentInput) {
      if (event.keyCode == 8) {
        event.preventDefault();
        replaceNumber('', inputNumber - 1);
        currentInput.value = '';
        previousInput.focus();
      } else if (event.keyCode == 37) {
        previousInput.focus();
      } else if (event.keyCode == 39) {
        nextInput.focus();
      } else {
        !event.ctrlKey && event.preventDefault();
        if (String.fromCharCode(event.keyCode).match('[0-9]{1}'))
          currentInput.value = String.fromCharCode(event.keyCode);
        else currentInput.value = String.fromCharCode(event.keyCode - 48);
        if (currentInput.value !== '') {
          nextInput.focus();
          replaceNumber(currentInput.value, inputNumber - 1);
        }
      }
    }
  };

  /**handleChange should work for mobile */
  const handleChange = (event: any) => {
    const currentInput = document.activeElement as HTMLInputElement;
    const inputNumber = Number(currentInput.id.slice(-1));
    const nextInputNumber = inputNumber + 1;

    const nextInput = document.getElementById(
      `input${nextInputNumber > 6 ? 6 : nextInputNumber}`,
    ) as HTMLInputElement;

    nextInput.focus();
    replaceNumber(event.target.value, inputNumber - 1);
  };
  return inProgress ? (
    <CircularProgress color="secondary" style={{ marginBottom: 30 }} size={50} />
  ) : (
    <div>
      <Input
        id="input1"
        className={classes.digitInput}
        disableUnderline
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        classes={{ input: classes.inputText }}
        onPaste={handlePaste}
        type="number"
      ></Input>
      <Input
        id="input2"
        className={classes.digitInput}
        disableUnderline
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        classes={{ input: classes.inputText }}
        type="number"
        onPaste={handlePaste}
      ></Input>
      <Input
        id="input3"
        className={classes.digitInput}
        disableUnderline
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        classes={{ input: classes.inputText }}
        type="number"
        onPaste={handlePaste}
      ></Input>
      <Input
        id="input4"
        className={classes.digitInput}
        disableUnderline
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        classes={{ input: classes.inputText }}
        type="number"
        onPaste={handlePaste}
      ></Input>
      <Input
        id="input5"
        className={classes.digitInput}
        disableUnderline
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        classes={{ input: classes.inputText }}
        type="number"
        onPaste={handlePaste}
      ></Input>
      <Input
        id="input6"
        className={classes.digitInput}
        disableUnderline
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        classes={{ input: classes.inputText }}
        type="number"
        onPaste={handlePaste}
      ></Input>

      {error != null ? (
        <Grid item xs={12} className={classes.inputError}>
          {error}
        </Grid>
      ) : null}
    </div>
  );
};

export default VerificationCodeInput;
