import { Button, Collapse, FormLabel, Grid, Menu, MenuItem, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import moment from 'moment';
import React, { FC, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { PayloadAC } from 'typesafe-actions';
import { asyncChangeStatus } from '../../../App/state/actions';
import { AppState } from '../../../App/state/initialState';
import { userStateSelector } from '../../../App/state/selectors';
import ExternalLink from '../../../assets/external-link.svg';
import { changeOrderStatusRequest, orderDetails } from '../../../data/repositories/orders/typedef';
import { AllCoinDecimals } from '../../../data/repositories/wallet/typedefs';
import { Colors } from '../../../ui/style/colors';
import balanceUtils from '../../../utils/balanceUtils';
import {
  CRYPTO_MAX_FRACTION,
  CRYPTO_MIN_FRACTION,
  WIRE_EUR_DECIMALS,
} from '../../../utils/constants/amounts';
import { roles } from '../../../utils/constants/roles';
import { getCurrencyFraction } from '../../../utils/helper/numbers';
import CoinIconCell from '../../CoinIconCell';
import CustomButtonPrimary from '../../CustomButtons/CustomButtonPrimary';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    claimReward: {
      color: `${Colors.mediumTurquoise}`,
      fontSize: 14,
      fontWeight: 600,
      textAlign: 'right',
      cursor: 'pointer',
    },
    statusSuccess: {
      color: `${Colors.havelockBlue}`,
      fontSize: 14,
      textTransform: 'capitalize',
    },
    statusPending: {
      color: `${Colors.blueViolet}`,
      fontSize: 14,
      textTransform: 'capitalize',
    },
    statusRejected: { color: `${Colors.blueHaze}`, fontSize: 14, textTransform: 'capitalize' },
    moreOptionsWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '24px',
      float: 'right',
      color: `${Colors.wildBlueYonder}`,
      '&:hover, &:focus': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
        cursor: 'pointer',
        borderRadius: '4px',
        border: 0,
      },
    },
    transactionStatusValue: {
      fontSize: 14,
      color: `${Colors.ceruleanBLue}`,
      textDecoration: 'none',
      maxWidth: '100%',
      display: 'flex',
      alignItems: 'center',
      '&:hover': {
        textDecoration: 'underline',
      },
      '& span': {
        maxWidth: '100px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        display: 'block',
        paddingRight: '0.5rem',
      },
    },
    collapsedRow: {
      margin: '10px 0',
      padding: '10px',
    },
    labels: {
      fontSize: 14,
      color: `${Colors.wildBlueYonder}`,
      textTransform: 'uppercase',
    },
    transactionDetailsValues: {
      fontSize: 14,
      color: theme.palette.primary.main,
    },
    additionalWrapper: {
      borderBottom: `1px solid ${Colors.wildBlueYonder}`,
      marginBottom: 10,
      paddingBottom: 10,
      // minHeight: 59,
    },
    inputWrapper: {
      border: '1px solid rgba(224, 224, 224, 1)',
      outline: 'none',
      padding: 5,
      width: '100%',
      boxSizing: 'border-box',
    },
    actionButtonsLabels: {
      maxWidth: '168px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      display: 'block',
      paddingRight: '0.5rem',
    },
  }),
);

type Props = {
  referral: orderDetails;
  user: any;
  allCoinDecimals: AllCoinDecimals;
  asyncChangeStatus: PayloadAC<'orders/change_status', changeOrderStatusRequest>;
  order_type: string;
  expanded: boolean;
  setExpanded: (transactionId: string) => void;
  page: number;
  filter: any;
};

const AffiliatesMerchantsTableRow: FC<Props> = ({
  referral,
  user,
  allCoinDecimals,
  asyncChangeStatus,
  order_type,
  expanded,
  setExpanded,
  page,
  filter,
}: Props) => {
  const [adminComment, setAdminComment] = useState('');
  const dispatch = useDispatch();
  const classes = useStyles();
  const renderType = (type: any) => {
    switch (type) {
      case 'ether_payment':
        return 'Ether payment';

      case 'wire_transfer':
        return 'Wire transfer';
      case 'other':
        return 'Wallet';
      case 'card_payment':
        return 'Card payment';
      case 'wire_transfer':
        return 'Wire Transfer';
      default:
        return type;
    }
  };

  const statusRenderType = (type: string | undefined) => {
    switch (type) {
      case 'rejected':
        return 'Rejected';
      case 'created':
        return 'Pending';
      case 'accepted':
        return 'Accepted';
      case 'failed_to_send':
        return 'Failed to send';
      default:
        return type;
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const onExit = () => {
    setAnchorEl(null);
  };

  const getOrders = () => {
    const object: any = {};
    filter &&
      filter.forEach((value: any, key: React.ReactText) => {
        object[key] = value;
      });

    dispatch({
      type: 'orders/get_merchants_referrals_paginated',
      payload: {
        page: page,
        perPage: 10,
        order_type,
        appliedFilters: { ...object },
      },
    });
  };

  const handleOrderStatusChange = async (orderId: string, orderStatus: boolean, comment = '') => {
    asyncChangeStatus({ orderId, orderStatus, comment, getOrders });

    setExpanded('close');

    onExit();
  };
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const balance =
    referral.slc_price &&
    balanceUtils.formatBalanceToString(
      referral.slc_price + referral.bonus_slc_price || 0,
      allCoinDecimals['SLC'],
      CRYPTO_MIN_FRACTION,
      CRYPTO_MAX_FRACTION,
    );

  const additionalInfo =
    referral.order_payment_details &&
    referral.order_payment_details.String &&
    referral.payment_type !== 'other'
      ? JSON.parse(referral.order_payment_details.String)
      : {
          beneficiary: '',
          beneficiary_address: '',
          bic: '',
          iban: '',
        };
  const getProof = () => {
    if (referral.order_proof) {
      return referral.order_proof.String;
    } else if (referral.revolut_payment_id) {
      return referral.revolut_payment_id.String;
    }
    return 'invalid';
  };

  const getStatusClassName = (status: string | undefined) =>
    status === 'accepted' || status === 'completed'
      ? classes.statusSuccess
      : status === 'created'
      ? classes.statusPending
      : classes.statusRejected;

  const order_status = referral.order_status ? referral.order_status : referral.status;

  return (
    <>
      <TableRow>
        {order_type === 'project_invest' && <TableCell>{referral.name}</TableCell>}
        <TableCell className={getStatusClassName(order_status)}>
          {statusRenderType(order_status)}
        </TableCell>
        {order_type !== 'revolutWithdrawal' && (
          <TableCell>{renderType(referral.payment_type)}</TableCell>
        )}
        <TableCell>
          {/* target="_blank"
                href={`https://ropsten.etherscan.io/tx/${referral.order_proof.String}`}
                className={classes.transactionStatusValue} */}
          <div>
            {referral.payment_type === 'ether_payment' &&
            (order_type === 'buy' || order_type === 'crowdfunding') ? (
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://etherscan.io/tx/${getProof()}`}
                className={classes.transactionStatusValue}
              >
                <span>{getProof()}</span> <img src={ExternalLink} alt="externalLink" />
              </a>
            ) : (
              getProof()
            )}
          </div>
        </TableCell>
        <TableCell>
          <div>{referral.email}</div>
        </TableCell>
        <TableCell align="right">
          {moment(referral.created_at).format('DD/MM/YYYY HH:mm')}
        </TableCell>
        {order_type !== 'deposit_fiat' &&
          order_type !== 'revolutWithdrawal' &&
          order_type !== 'project_invest' && (
            <TableCell align="right">{`${balance} SLC`}</TableCell>
          )}
        <TableCell align="right">
          <CoinIconCell
            value={
              referral.payment_type === 'other'
                ? `${balanceUtils.formatBalanceToString(
                    referral.price ? referral.price : referral.amount,
                    allCoinDecimals[referral.symbol === 'EUR' ? 'WALLET_EUR' : referral.symbol],
                    getCurrencyFraction(referral.symbol, 'min'),
                    getCurrencyFraction(referral.symbol),
                  )} `
                : `${
                    balanceUtils.formatBalanceToString(
                      referral.price ? referral.price : referral.amount,
                      WIRE_EUR_DECIMALS,
                      getCurrencyFraction(referral.symbol ? referral.symbol : 'WALLET_EUR', 'min'),
                      getCurrencyFraction(referral.symbol ? referral.symbol : 'WALLET_EUR'),
                    ) || 0
                  }`
            }
            symbol={referral.payment_type === 'other' ? referral.symbol : 'EUR'}
            imgGridSize={6}
          />
        </TableCell>
        {roles.isOwner(user.roles) && (
          <TableCell align="right">
            {(referral.status === 'created' ||
              (referral.order_status === 'created' && order_type === 'buy') ||
              (referral.order_status === 'created' &&
                (order_type === 'deposit_fiat' || order_type === 'project_invest') &&
                referral.payment_type === 'wire_transfer') ||
              order_type === 'crowdfunding') && (
              <>
                <div className={classes.moreOptionsWrapper} onClick={handleClick}>
                  <MoreVertIcon />
                </div>

                <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={onExit}>
                  <div>
                    {referral.status === 'created' ||
                      (referral.order_status === 'created' && (
                        <>
                          <MenuItem onClick={() => handleOrderStatusChange(referral.id, true)}>
                            <Typography
                              noWrap={false}
                              component="p"
                              className={classes.actionButtonsLabels}
                            >
                              Accept {referral.order_proof && referral.order_proof.String}
                            </Typography>
                          </MenuItem>
                          <MenuItem onClick={() => handleOrderStatusChange(referral.id, false)}>
                            <Typography
                              noWrap={false}
                              component="p"
                              className={classes.actionButtonsLabels}
                            >
                              Reject {referral.order_proof && referral.order_proof.String}
                            </Typography>
                          </MenuItem>
                        </>
                      ))}
                    {order_type === 'crowdfunding' && (
                      <MenuItem
                        onClick={() => {
                          setExpanded(referral.id);
                        }}
                      >
                        Order details
                      </MenuItem>
                    )}
                  </div>
                </Menu>
              </>
            )}
            {order_type === 'sell' && referral.payment_type !== 'other' && (
              <>
                <div className={classes.moreOptionsWrapper} onClick={handleClick}>
                  <MoreVertIcon />
                </div>

                <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={onExit}>
                  <div>
                    <MenuItem
                      onClick={() => {
                        setExpanded(referral.id);
                      }}
                    >
                      Order details
                    </MenuItem>
                  </div>
                </Menu>
              </>
            )}
          </TableCell>
        )}
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Grid container>
              <Grid className={classes.collapsedRow} item xs={6} zeroMinWidth>
                {renderType(referral.payment_type) === 'Wire transfer' &&
                order_type !== 'crowdfunding' ? (
                  <>
                    <Grid container className={classes.additionalWrapper}>
                      <Grid item xs={6}>
                        <FormLabel className={classes.labels}>Beneficiary</FormLabel>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          noWrap
                          component="p"
                          className={classes.transactionDetailsValues}
                        >
                          {additionalInfo.beneficiary}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container className={classes.additionalWrapper}>
                      <Grid item xs={6}>
                        <FormLabel className={classes.labels}>BIC</FormLabel>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          noWrap
                          component="p"
                          className={classes.transactionDetailsValues}
                        >
                          {additionalInfo.bic}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container className={classes.additionalWrapper}>
                      <Grid item xs={6}>
                        <FormLabel className={classes.labels}>IBAN</FormLabel>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          noWrap
                          component="p"
                          className={classes.transactionDetailsValues}
                        >
                          {additionalInfo.iban}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item container className={classes.additionalWrapper}>
                      <Grid item xs={6}>
                        <FormLabel className={classes.labels}>Beneficiary address</FormLabel>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          noWrap
                          component="p"
                          className={classes.transactionDetailsValues}
                        >
                          {additionalInfo.beneficiary_address}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item container className={classes.additionalWrapper}>
                      <Grid item xs={6}>
                        <FormLabel className={classes.labels}>Other information</FormLabel>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          noWrap
                          component="p"
                          className={classes.transactionDetailsValues}
                        >
                          {additionalInfo.other_info}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                ) : order_type === 'crowdfunding' ? (
                  <>
                    <Grid container className={classes.additionalWrapper}>
                      <Grid item xs={6}>
                        <FormLabel className={classes.labels}>Product name</FormLabel>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          noWrap
                          component="p"
                          className={classes.transactionDetailsValues}
                        >
                          {referral.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid container className={classes.additionalWrapper}>
                      <Grid item xs={6}>
                        <FormLabel className={classes.labels}>Ether address</FormLabel>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          noWrap
                          component="p"
                          className={classes.transactionDetailsValues}
                        >
                          {additionalInfo.ether_address}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
              {order_type !== 'crowdfunding' && (
                <Grid className={classes.collapsedRow} item xs={6} zeroMinWidth>
                  {referral.status === 'created' || referral.order_status === 'created' ? (
                    <>
                      <Grid container>
                        <Grid item xs={6} style={{ padding: '3px 20px', textAlign: 'right' }}>
                          <FormLabel className={classes.labels}>Comment</FormLabel>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            noWrap
                            component="p"
                            className={classes.transactionDetailsValues}
                          >
                            <input
                              className={classes.inputWrapper}
                              onChange={event => setAdminComment(event.target.value)}
                              value={adminComment}
                            ></input>
                          </Typography>
                        </Grid>

                        <Grid item xs={12} style={{ textAlign: 'right' }}>
                          <Button
                            variant="contained"
                            size="small"
                            color="secondary"
                            style={{ margin: '20px 0 0 20px' }}
                            onClick={() => handleOrderStatusChange(referral.id, true, adminComment)}
                          >
                            Accept
                          </Button>
                          <CustomButtonPrimary
                            variant="contained"
                            size="small"
                            style={{ margin: '20px 0 0 20px' }}
                            onClick={() =>
                              handleOrderStatusChange(referral.id, false, adminComment)
                            }
                          >
                            Reject
                          </CustomButtonPrimary>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <>
                      {referral.order_comment && referral.order_comment.String !== '' && (
                        <Grid container>
                          <Grid item xs={6} style={{ padding: '3px 20px', textAlign: 'right' }}>
                            <FormLabel className={classes.labels}>Order comment</FormLabel>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              noWrap
                              component="p"
                              className={classes.transactionDetailsValues}
                            >
                              {referral.order_comment && referral.order_comment.String}
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                    </>
                  )}
                </Grid>
              )}
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const mapDispatchToProps = {
  asyncChangeStatus: asyncChangeStatus.request,
};

const mapStateToProps = (state: AppState) => {
  const user = userStateSelector(state);
  return { user };
};

export default connect(mapStateToProps, mapDispatchToProps)(AffiliatesMerchantsTableRow);
