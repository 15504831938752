/* eslint-disable */
import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import {
  ChangeUserPasswordRequest,
  SelectedUser,
} from '../../../../../../data/repositories/admin/typedefs';

export const SET_CHANGE_USER_PASSWORD_SELETCED_USER =
  'admin/change_user_password/set_selected_user';

export const setChangeUserPasswordSelectedUser = createStandardAction(
  SET_CHANGE_USER_PASSWORD_SELETCED_USER,
)<SelectedUser>();

export const CHANGE_USER_PASSWORD = 'admin/change_user_password';
export const CHANGE_USER_PASSWORD_SUCCESS = 'admin/change_user_password/success';
export const CHANGE_USER_PASSWORD_FAIL = 'admin/change_user_password/fail';
export const CHANGE_USER_PASSWORD_CLEAR_STATE = 'admin/change_user_password/clear_state';

export const asyncChangeUserPassword = createAsyncAction(
  CHANGE_USER_PASSWORD,
  CHANGE_USER_PASSWORD_SUCCESS,
  CHANGE_USER_PASSWORD_FAIL,
)<ChangeUserPasswordRequest, undefined, Error>();

export const changeUserPasswordClearState = createStandardAction(CHANGE_USER_PASSWORD_CLEAR_STATE)<
  undefined
>();
