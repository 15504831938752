import { ChartState, TableChart } from '../../../../../data/repositories/dashboard/typedefs';
import { Wallet } from '../../../../../data/repositories/wallet/typedefs';

export type ComponentState = {
  coinsTableInProgress: boolean;
  transactionTableInProgress: boolean;
  fail: boolean;
  error: Error | null;
  inProgress: boolean;
  isDialogOpen: boolean;
};

export type termPackageObject = {
  create_account_id: string;
  created_at: string;
  description: string;
  id: string;
  interest_rate: number;
  name: string;
  payment_frequency_type: string;
  time_period: number;
  update_account_id: {
    String: string;
    Valid: boolean;
  };
  updated_at: { Time: string; Valid: boolean };
};

export type InvestDialogState = {
  investDialogOpened: boolean;
  termPackageTypes: termPackageObject[];
  selectedPackage: termPackageObject;
  errorMessage: string;
  inProgress: boolean;
};

export type DepositTermRequest = {
  amount: number;
  term_saving_type_name: string;
  balance_status: string;
};

type walletObject = {
  address: string;
  aliases: string[];
  symbol: string;
  balance: number;
  pending_balance: number;
  escrow_balance: number;
  invested_balance: number;
};

type currencyObject = {
  symbol: string;
  name: string;
  network: string;
  decimals: number;
};

export type entireWalletBalanceObject = {
  currency: currencyObject;
  wallet: walletObject;
};

export type DashboardState = {
  wallets: Map<string, Wallet>;
  chart: ChartState;
  componentState: ComponentState;
  investDialogState: InvestDialogState;
  entireWalletBalance: entireWalletBalanceObject;
  tableChart: TableChart;
};

export const initialState: DashboardState = {
  wallets: new Map<string, Wallet>(),
  chart: {
    inProgress: false,
    fail: false,
    data: {},
    selectedCurrency: { currency: 'EUR', symbol: '€' },
    selectedCoin: 'SLC',
    selectedPeriodFilter: 'year',
  },
  componentState: {
    coinsTableInProgress: false,
    transactionTableInProgress: false,
    fail: false,
    error: null,
    inProgress: true,
    isDialogOpen: false,
  },
  investDialogState: {
    investDialogOpened: false,
    termPackageTypes: [],
    selectedPackage: {
      create_account_id: 'string',
      created_at: '',
      description: '',
      id: '',
      interest_rate: 0,
      name: '',
      payment_frequency_type: '',
      time_period: 0,
      update_account_id: {
        String: '',
        Valid: false,
      },
      updated_at: { Time: '', Valid: false },
    },
    errorMessage: '',
    inProgress: false,
  },
  entireWalletBalance: {
    currency: {
      decimals: 0,
      name: '',
      network: '',
      symbol: '',
    },
    wallet: {
      address: '',
      aliases: [],
      balance: 0,
      pending_balance: 0,
      symbol: '',
      escrow_balance: 0,
      invested_balance: 0,
    },
  },
  tableChart: {
    inProgress: false,
    fail: false,
    data: {},
    selectedCurrency: { currency: 'EUR', symbol: '€' },
    selectedPeriodFilter: 'week',
    error: '',
  },
};
