import { SelectedUser } from '../../../../../../data/repositories/admin/typedefs';

export type ChangeUserPasswordState = {
  inProgress: boolean;
  fail: boolean;
  success: boolean;
  error: Error | null;
  selectedUser: SelectedUser;
};

export const selectedUserInitialState = {
  id: '',
  email: '',
  firstName: '',
  lastName: '',
};

export const initialState: ChangeUserPasswordState = {
  inProgress: false,
  fail: false,
  success: false,
  error: null,
  selectedUser: selectedUserInitialState,
};
