import { all, put, takeEvery } from 'redux-saga/effects';
import LocalStorage from '../../../../../../data/LocalStorage';
import api from '../../../../../../data/repositories/admin';
import { USER_ID } from '../../../../../../utils/constants/localStorageKeys';
import { getWalletBalance } from '../../../Dashboard/state/actions';
import { asyncDepositFiat, DEPOSIT_FIAT } from './actions';

export function* depositFiat$(action: ReturnType<typeof asyncDepositFiat.request>) {
  try {
    const userId = LocalStorage.get(USER_ID);
    const response = yield api.depositFiat(userId, action.payload.symbol, action.payload.amount);

    yield put(asyncDepositFiat.success(response));
    yield put(getWalletBalance());
  } catch (error) {
    yield put(asyncDepositFiat.failure(error));
  }
}

export default function* () {
  yield all([takeEvery(DEPOSIT_FIAT, depositFiat$)]);
}
