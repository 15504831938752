/* eslint-disable */
const Big = require('big.js');
import { isEmpty } from 'lodash';
import { createSelector } from 'reselect';
import { Coin, Wallet } from '../../../../../data/repositories/wallet/typedefs';
import { Colors } from '../../../../../ui/style/colors';
import balanceUtils from '../../../../../utils/balanceUtils';
import { AppState } from '../../../../state/initialState';

const getWalletState = ({ home: { wallets } }: AppState) => wallets;

const getWalletsState = ({
  home: {
    wallets: { wallets },
  },
}: AppState) => wallets;

const getComponentState = ({
  home: {
    wallets: { componentState },
  },
}: AppState) => componentState;

const getDepositModalState = ({
  home: {
    wallets: { depositModal },
  },
}: AppState) => depositModal;

const getBuyWalletModalState = ({
  home: {
    wallets: { buyWalletModal },
  },
}: AppState) => buyWalletModal;

const getInvestDialogState = ({
  home: {
    wallets: { investDialogState },
  },
}: AppState) => investDialogState;

const getCurrenciesSymbols = ({
  home: {
    wallets: { wallets },
  },
}: AppState) => Object.keys(wallets);

const getCommonState = ({ home: { common } }: AppState) => common;

const getRevolutCounterparty = ({
  home: {
    wallets: { getRevolutCounterparty },
  },
}: AppState) => getRevolutCounterparty;

export const getRevolutCounterpartySelector = createSelector(
  getRevolutCounterparty,
  getRevolutCounterparty => getRevolutCounterparty,
);

const getRevolutCounterpartyData = ({
  home: {
    wallets: { revolutCounterpartyData },
  },
}: AppState) => revolutCounterpartyData;

export const getRevolutCounterpartyDataSelector = createSelector(
  getRevolutCounterpartyData,
  revolutCounterpartyData => revolutCounterpartyData,
);

export const getCurrenciesSymbolsSelector = createSelector(
  getCurrenciesSymbols,
  currencies => currencies,
);

export const walletStateSelector = createSelector(getWalletsState, wallets => wallets);

export const stepSelector = createSelector(getWalletState, wallets => wallets.step);

export const swapToSLCSelector = createSelector(getWalletState, wallets => wallets.swapSLTtoSLC);

export const withdrawErrorSelector = createSelector(
  getWalletState,
  wallets => wallets.withdrawError,
);

export const withdrawInProgressSelector = createSelector(
  getWalletState,
  wallets => wallets.withdrawInProgress,
);

export const withdrawSuccessSelector = createSelector(
  getWalletState,
  wallets => wallets.withdrawSuccess,
);

export const walletsStateSelector = createSelector(getWalletState, wallets => wallets);

export const getComponentStateSelector = createSelector(
  getComponentState,
  componentState => componentState,
);
export const getBuyModalStateSelector = createSelector(
  getWalletState,
  wallets => wallets.buySellModal,
);
export const getInvestDialogStateSelector = createSelector(
  getInvestDialogState,
  investDialogState => investDialogState,
);

export const walletTotalBalanceSelector = createSelector(getWalletsState, wallets => {
  let totalBalance = Big(0);
  Object.entries(wallets).map(([key, value]) => {
    if (value && value.balance && value.price_eur) {
      totalBalance = totalBalance.plus(
        balanceUtils
          .formatBalanceToBigWithDecimals(value.price_eur, value.decimals)
          .times(value.balance),
      );
    }
  });
  return totalBalance;
});

// export const walletDepositAddressSelector = createSelector(
//   getWalletState,
//   wallets => {
//     let totalBalance = 0;
//     for (const wallet in wallets.values) {
//       const value = wallets.get(wallet);
//       if (value && value.balance && value.price_eur) {
//         totalBalance += parseFloat(value.balance) * parseFloat(value.price_eur);
//       }
//     }
//     return totalBalance.toString();
//   },
// );

export const getCoinDecimalsSelector = (currencySymbol: string) => {
  currencySymbol = currencySymbol === 'EUR' ? 'WALLET_EUR' : currencySymbol;
  return createSelector(getWalletsState, wallets => {
    let allWallets = new Map<string, Wallet>(Object.entries(wallets));
    const coin = allWallets.get(currencySymbol);
    return coin && coin.decimals;
  });
};

export const getSLCPriceSelector = createSelector(getWalletsState, wallets => {
  let allWallets = new Map<string, Wallet>(Object.entries(wallets));
  const slcCoin = allWallets.get('SLC');
  return slcCoin ? slcCoin.price_eur || '0.5' : '0.5';
});

export const walletSLCBalanceSelector = createSelector(
  walletTotalBalanceSelector,
  getSLCPriceSelector,
  (totalBalance, price) => totalBalance.div(parseFloat(price)),
);

export const getDepositWalletSymbolSelector = createSelector(
  getCommonState,
  common => common.depositWalletSymbol,
);

// export const getDepositWalletSelector = createSelector(
//   walletStateSelector,
//   getDepositWalletSymbolSelector,
//   (wallets, symbol) => {
//     const x = new Map<string, Wallet>(Object.entries(wallets));
//     return x.get(symbol);
//   },
// );

export const getAllCoinsSelector = createSelector(getCommonState, common => common.allCoins);

//doesnt work
export const getWalletsWithMetadata = createSelector(
  walletStateSelector,
  getAllCoinsSelector,
  (wallets, allCoins) => ({ ...wallets, ...allCoins }),
);

export const expensiveGetWalletForCurrency = (currencySymbol: string) => {
  return createSelector(
    walletStateSelector,
    getAllCoinsSelector,
    walletTotalBalanceSelector,
    (wallets, allCoins, totalBalance) => {
      const w = new Map<string, Wallet>(Object.entries(wallets));
      const ac = new Map<string, Coin>(Object.entries(allCoins));

      const wallet = w.get(currencySymbol);
      const coin = ac.get(currencySymbol);

      let percentage = Big(0);
      if (wallet && wallet.balance && wallet.price_eur && totalBalance != 0) {
        percentage = Big(
          balanceUtils
            .formatBalanceToBigWithDecimals(wallet.balance, wallet.decimals)
            .times(parseFloat(wallet.price_eur)),
        )
          .div(totalBalance)
          .times(100);
      }
      const wac = {
        ...(wallet
          ? wallet
          : {
              address: 'string',
              aliases: [],
              balance: 'string',
              pending_balance: 'string',
              symbol: 'string',
              name: 'string',
              network: 'string',
              decimals: 18,
              price_eur: '1',
            }),
        ...(coin
          ? coin
          : {
              icon: '',
              color: `${Colors.white}`,
            }),
        ...{ percentage: `${percentage}%` },
      };
      return isEmpty(wac)
        ? {
            address: 'string',
            aliases: [],
            balance: 'string',
            pending_balance: 'string',
            symbol: 'string',
            name: 'string',
            network: 'string',
            decimals: 0,
            icon: '',
            color: `${Colors.white}`,
            percentage: '0',
            price_eur: '1',
          }
        : wac;
    },
  );
};

export const expensiveGetWalletWithMetadataBySymbol = (walletSymbol: string) => {
  return createSelector(walletStateSelector, getAllCoinsSelector, (wallets, allcoins) => {
    const w = new Map<string, Wallet>(Object.entries(wallets));
    const ac = new Map<string, Coin>(Object.entries(allcoins));
    const wallet = w.get(walletSymbol);
    const coin = ac.get(walletSymbol);
    const wac = {
      ...(wallet
        ? wallet
        : {
            address: 'string',
            aliases: [],
            balance: 'string',
            pending_balance: 'string',
            symbol: 'string',
            name: 'string',
            network: 'string',
            decimals: 18,
            price_eur: '1',
          }),
      ...(coin
        ? coin
        : {
            icon: '',
            color: `${Colors.white}`,
          }),
      ...{ percentage: `0%` },
    };
    return isEmpty(wac)
      ? {
          address: 'string',
          aliases: [],
          balance: 'string',
          pending_balance: 'string',
          symbol: 'string',
          name: 'string',
          network: 'string',
          decimals: 0,
          icon: '',
          color: `${Colors.white}`,
          percentage: '0',
          price_eur: '1',
        }
      : wac;
  });
};

export const getSelectedCoinSelector = createSelector(
  getCommonState,
  common => common.selectedCoin,
);

export const getWalletBySymbolSelectorSelector = createSelector(
  walletStateSelector,
  getSelectedCoinSelector,
  (wallets, selectedCoin) => {
    const w: Wallet[] = Object.values(wallets);
    return w.find(wal => {
      if (wal.symbol === selectedCoin) return wal;
    });
  },
);

export const getComponentError = createSelector(
  getComponentState,
  componentState => componentState.error,
);

export const entireWalletBalanceSelector = createSelector(
  getWalletState,
  wallet => wallet.entireWalletBalance,
);

//For the given availableCoins array argument, return only those who have valid wallet address
export const filterAvailableCoinsSelector = (availableCoins: string[]) => {
  return createSelector(getWalletsState, wallets => {
    let allWallets = new Map<string, Wallet>(Object.entries(wallets));

    return availableCoins.filter((coinSymbol: string) => {
      const coin = allWallets.get(coinSymbol);
      return coin && coin.address !== 'string';
    });
  });
};
export const depositModalStateSelector = createSelector(
  getDepositModalState,
  depositModal => depositModal,
);

export const depositModalStepSelector = createSelector(
  getDepositModalState,
  depositModal => depositModal.step,
);

export const buyWalletModalStateSelector = createSelector(
  getBuyWalletModalState,
  buyWalletModal => buyWalletModal,
);
