import { CircularProgress } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import React from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { PayloadAC } from 'typesafe-actions';
import SacretError from '../../../../../components/SacretError';
import {
  ChartState,
  GetCryptoChartRequest,
} from '../../../../../data/repositories/dashboard/typedefs';
import { Colors } from '../../../../../ui/style/colors';
import { GET_CRYPTO_CHART_DATA } from '../state/actions';
import CryptoChartInfo from './CryptoChartInfo';
import CryptoChartTooltip from './CryptoChartTooltip';

const styles = () =>
  createStyles({
    root: {
      fontFamily: 'Manrope3',
    },
    progressRoot: {
      width: '100%',
      height: '100%',
      position: 'relative',
      minHeight: 300,
    },
    circularProgress: {
      position: 'absolute',
      left: '50%',
      top: '50%',
    },
  });

const useStyles = makeStyles(styles);

type StyleProps = {
  cartesianGrid?: { vertical?: boolean; horizontal?: boolean };
  hideXAxis?: boolean;
  hideYAxis?: boolean;
  showTooltip?: boolean;
  width?: number;
  height?: number;
  fullWidth?: boolean;
  fill?: string;
  stroke?: string;
};
type Props = {
  cryptoChartData: ChartState;
  showChartInfo?: boolean;
  handleChange?: PayloadAC<typeof GET_CRYPTO_CHART_DATA, GetCryptoChartRequest>;
};

const CryptoChart = ({
  cryptoChartData,
  showChartInfo = false,
  handleChange,
  cartesianGrid = { vertical: false, horizontal: true },
  hideXAxis = false,
  hideYAxis = false,
  showTooltip = true,
  width,
  height = 300,
  fullWidth = true,
  fill = 'url(#colorUv)',
  stroke = `${Colors.lightningYellow}`,
}: Props & StyleProps): JSX.Element => {
  const gradientColor = `${Colors.goldenTainoi}`;
  const classes = useStyles();
  const { data, selectedCurrency, selectedCoin, fail, inProgress } = cryptoChartData;

  if (inProgress || !data || !data[selectedCoin]) {
    return (
      <div className={classes.progressRoot}>
        <CircularProgress color="secondary" size={60} className={classes.circularProgress} />
      </div>
    );
  }

  if (fail) {
    return (
      <div className={classes.progressRoot}>
        <SacretError showBackButton={false} />
      </div>
    );
  }
  const getYDataRange = (dataRange: number) => {
    if (selectedCoin === 'SLC' || selectedCoin === 'USDT') {
      return dataRange;
    }
    return Math.round(dataRange);
  };
  return (
    <>
      {showChartInfo && handleChange && (
        <CryptoChartInfo cryptoChartData={cryptoChartData} handleChange={handleChange} />
      )}
      <ResponsiveContainer
        width={fullWidth ? '100%' : width}
        height={height}
        className={classes.root}
      >
        <AreaChart
          data={data[selectedCoin].prices}
          margin={{
            top: 10,
            right: 10,
            left: 10,
            bottom: 0,
          }}
        >
          <CartesianGrid
            strokeDasharray="3 3"
            vertical={cartesianGrid.vertical}
            horizontal={cartesianGrid.horizontal}
          />
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={gradientColor} stopOpacity={0.3} />
              <stop offset="95%" stopColor={`${Colors.white}`} stopOpacity={0.1} />
            </linearGradient>
          </defs>
          <XAxis
            hide={hideXAxis}
            dy={10}
            tick={{ fontSize: 12, fontWeight: 700, paddingTop: 20 }}
            dataKey={selectedCoin === 'SLC' ? 'date' : 'xAxis'}
            tickFormatter={value => {
              if (selectedCoin === 'SLC') {
                return moment(value, 'MMMM Do, YYYY HH:mm:ss').format('MMM Do YY');
              }
              return value;
            }}
            axisLine={false}
            tickLine={false}
          />
          <YAxis
            hide={hideYAxis}
            dx={-10}
            tickLine={false}
            tick={{ fontSize: 12, fontWeight: 700 }}
            axisLine={false}
            type="number"
            domain={[getYDataRange, getYDataRange]}
            tickFormatter={tick => {
              return `${selectedCurrency.symbol}${tick}`;
            }}
          />
          {showTooltip && (
            <Tooltip
              content={<CryptoChartTooltip selectedCurrency={selectedCurrency} />}
              cursor={{ stroke: `${Colors.blueHaze}`, strokeWidth: 1 }}
            />
          )}
          <Area dataKey="yAxis" stroke={stroke} strokeWidth={2} fillOpacity={1} fill={fill} />
        </AreaChart>
      </ResponsiveContainer>
    </>
  );
};

export default CryptoChart;
