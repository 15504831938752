import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';
import AffiliateTable from '../../../Affiliate/AffiliateTable';
import TransactionsFilterBar from '../../../Affiliate/TransactionsFilterBar';

const styles = () =>
  createStyles({
    wrapper: {
      width: 710,
      height: 400,
      overflow: 'sroll',
      padding: '20px',
    },
  });

const useStyles = makeStyles(styles);
const SeacrhUserDialog = () => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <TransactionsFilterBar isAdminSearchWidget={true} />
      <AffiliateTable isAdminSearchWidget={true} />
    </div>
  );
};

export default SeacrhUserDialog;
