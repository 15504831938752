import { connect } from 'react-redux';
import { AppState } from '../../../../../state/initialState';
import { getCoinPrices } from '../../../../state/common/selectors';
import CryptoChartInfo from './CryptoChartInfo';

const mapStateToProps = (state: AppState) => {
  const coinPrices = getCoinPrices(state);
  return { coinPrices };
};

export default connect(mapStateToProps, {})(CryptoChartInfo);
