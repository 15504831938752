import { Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import IOSSwitch from '../../../../../components/IOSSwitch';
import { Colors } from '../../../../../ui/style/colors';
import useExchangeCurrencyPrices from '../../Exchange/ExchangeCurrency/useExchangeCurrencyPrices';
import { SWAP_TO_SLC } from '../../Wallet/state/actions';
import { SwapToSLCState } from '../../Wallet/state/initialState';
import { InfoDialogState } from '../state/initialState';

type Props = {
  infoDialogState: InfoDialogState;
  swapToSLCSate: SwapToSLCState;
};

const styles = (theme: Theme) =>
  createStyles({
    wrapper: {
      width: '100%',
      height: '100%',

      '@media(max-width:600px)': {
        padding: '0 20px',
      },
    },
    button: {
      color: theme.palette.error.light,
    },
    label: {
      fontWeight: 'bold',
      fontSize: '1.2rem',
      color: theme.palette.primary.light,
    },
    switched: {
      color: `${Colors.mediumTurquoise}`,
    },
    textLight: {
      color: theme.palette.primary.light,
      margin: '30px 0 20px 0',
      fontWeight: 'bold',
    },
    buttonSuccess: {
      backgroundColor: `${Colors.dustyGray}`,
      color: `${Colors.white}`,
      '&:hover ': {
        opacity: 0.9,
        backgroundColor: `${Colors.dustyGray}`,
      },
    },
    buttonsWrapper: {
      alignSelf: 'flex-end',
    },
    contentWrapper: {
      margin: '0 30px',
      '@media(max-width: 600px)': {
        margin: 0,
      },
    },
    content: {
      fontSize: '1rem',
      marginBottom: 10,
      '@media(max-width: 600px)': {
        fontSize: '0.75rem',
      },
    },
  });
const useStyles = makeStyles(styles);

const SwapSLTtoSLCDialog: React.FC<Props> = ({ swapToSLCSate }: Props) => {
  const { inProgress } = swapToSLCSate;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [enabled, setEnabled] = useState(false);
  const { getSelectedCoinMarketVal } = useExchangeCurrencyPrices();
  const sltEurVal = getSelectedCoinMarketVal('SLT');
  const slcEurVal = getSelectedCoinMarketVal('SLC');
  if (inProgress) {
    return (
      <Grid container justifyContent="center" alignItems="center" className={classes.wrapper}>
        <CircularProgress color="secondary" size={60} />
      </Grid>
    );
  }

  return (
    <Grid id="swapSLTtoSLCDialog" container alignItems="center" className={classes.wrapper}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className={classes.contentWrapper}
      >
        <Typography className={`${classes.label} ${!enabled && classes.switched}`}>
          SLT: {sltEurVal}
        </Typography>
        <IOSSwitch onClick={() => setEnabled(!enabled)} checked={enabled} />
        <Typography className={`${classes.label} ${enabled && classes.switched}`}>
          SLC: {slcEurVal}
        </Typography>
      </Grid>
      <Grid container className={classes.contentWrapper}>
        <Grid item xs={12} className={`${classes.content} ${classes.textLight}`}>
          The SLT currency is converting to SLC. If you would like to claim your SLC tokens, please
          select the continue button.
        </Grid>
        <Grid item xs={12} className={classes.content}>
          Dear partners,
        </Grid>
        <Grid item xs={12} className={classes.content}>
          We are changing our business solution and moving from Ethereum to SL Blockchain. This
          means that the primary token SLT is converting to SLC, which will become our official
          payment token from now on. If you would like to continue using our SLAFF business
          platform, you will need to convert from SLT to SLC payment method.
        </Grid>
        <Grid item xs={12} className={classes.content}>
          The good news is that the exchange rate will be 1:1. However, if you wait for too long, it
          may happen that this rate will change, and your SLT tokens will soon lose all their value.
          We expect that the rate will start decreasing in the next two weeks, which is why we
          recommend that you hurry up and activate the automatic conversion of your SLT tokens by
          turning on the switch and selecting the continue button.
        </Grid>
        <Grid item xs={12} className={classes.content}>
          Thank you for your trust,
          <br /> Yours, <br /> SL Team
        </Grid>
      </Grid>

      <Grid container justifyContent="space-evenly" className={classes.buttonsWrapper}>
        <Grid item xs={12} sm={5}>
          <Button
            variant="text"
            size="large"
            fullWidth
            className={classes.button}
            onClick={() => {
              localStorage.clear();
              window.location.href =
`${process.env.REACT_APP_IAM_ROUTE}/logout?from_url=${window.location.origin}`;
            }}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Button
            onClick={() => dispatch({ type: SWAP_TO_SLC })}
            className={classes.buttonSuccess}
            variant="contained"
            size="large"
            fullWidth
            disabled={!enabled}
          >
            Continue
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SwapSLTtoSLCDialog;
