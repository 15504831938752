import { Button, Collapse, FormLabel, Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import moment from 'moment';
import React, { FC } from 'react';
import ExternalLink from '../../../assets/external-link.svg';
import { AllCoinDecimals } from '../../../data/repositories/wallet/typedefs';
import { Colors } from '../../../ui/style/colors';
import balanceUtils from '../../../utils/balanceUtils';
import { getCurrencyFraction } from '../../../utils/helper/numbers';
import Amount from '../../Amount';
import CoinIconCell from '../../CoinIconCell';
const REACT_APP_BTC_HASH_LINK =
  process.env.REACT_APP_BTC_HASH_LINK || 'https://blockstream.info/testnet';
const REACT_APP_ETH_HASH_LINK = process.env.REACT_APP_ETH_HASH_LINK || 'https://etherscan.io';
const REACT_APP_LTC_HASH_LINK = process.env.REACT_APP_LTC_HASH_LINK || '';
const REACT_APP_BCH_HASH_LINK = process.env.REACT_APP_BCH_HASH_LINK || '';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    claimReward: {
      color: `${Colors.mediumTurquoise}`,
      fontSize: 14,
      fontWeight: 600,
      textAlign: 'right',
      cursor: 'pointer',
    },
    statusSuccess: {
      color: `${Colors.havelockBlue}`,
      fontSize: 14,
      textTransform: 'capitalize',
    },
    statusPending: {
      color: `${Colors.blueViolet}`,
      fontSize: 14,
      textTransform: 'capitalize',
    },
    expandButton: {
      textTransform: 'none',
      marginLeft: 'auto',
      color: theme.palette.primary.light,
    },
    cell: {
      borderBottom: 'none',
    },
    expandableCell: { borderTop: 'none' },
    collapse: {
      padding: 40,
    },
    labels: {
      fontSize: 14,
      color: theme.palette.secondary.light,
    },
    transactionDetailsValues: {
      marginTop: 10,
      fontSize: 14,
      color: theme.palette.primary.light,
    },
    transactionStatusValue: {
      marginTop: 10,
      fontSize: 16,
      color: `${Colors.ceruleanBLue}`,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    capitalFirst: {
      textTransform: 'capitalize',
    },
    flex: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    externalLinkIcon: {
      height: 15,
      marginLeft: 10,
    },
    iconCell: {
      width: 50,
    },
    widgetCell: {
      width: 'calc((100% -50)/3)',
    },
    arrowIcon: {
      verticalAlign: 'middle',
    },
    colorGreen: {
      color: theme.palette.success.light,
    },
    colorRed: {
      color: theme.palette.error.light,
    },
    disableLink: {
      pointerEvents: 'none',
    },
  }),
);

const renderType = (type: any) => {
  switch (type) {
    case 'airdrop_reward':
      return 'Airdrop';
    case 'new_user_reward':
      return 'Registration reward';
    case 'new_merchant_reward':
      return 'Became Partner';
    case 'buy':
      return 'Buy';
    case 'deposit':
      return 'Deposit';
    case 'withdraw':
      return 'Withdraw';
    case 'exchange':
      return 'Exchange';
    case 'merchant_parent_reward':
      return 'Affiliate became Partner';
    case 'new_user_affiliate_reward':
      return 'New Affiliate';
    case 'payment_sent':
      return 'Purchase';
    case 'merchant_income':
      return 'Sale';
    case 'pool_reward':
      return 'Pool reward';
    case 'platform_pool_reward':
      return 'Platform pool reward';
    case 'premium_user_reward':
      return 'Affiliate package purchase';
    case 'premium_user_affiliate_reward':
      return 'Affiliate purchased Affiliate package';
    case 'affiliate_percentage_reward':
      return 'BV';
    case 'sell':
      return 'Sell';
    case 'VAT':
      return 'VAT';
    case 'timed_escrow':
      return 'Timed Escrow';
    case 'waiting_for_approval':
      return 'Waiting for approval';
    case 'pending_escrow':
      return 'Pending Escrow';
    case 'term_saving':
      return 'BV';
    case 'invested':
      return 'In HODL';
    case 'transfer':
      return 'Transfer';
    case 'bonus':
      return 'Bonus';
    case 'reward_correction':
      return 'Reward transfer';
    case 'buy_product':
      return 'Buy product';
    case 'sell_product':
      return 'Sell product';
    case 'event_reward':
      return 'Event reward';
    case 'bonus_reward':
      return 'Bonus reward';
    case 'interest_payment':
      return 'HODL Interested payment';
    case 'term_saving_finished':
      return 'Term saving payout';
    case 'receive':
      return 'Deposit receive';
    case 'send':
      return 'Send';
    case 'store_item_buy':
      return 'Buy item';
    case 'store_item_sell':
      return 'Sell item';
    default:
      return type;
  }
};

type Props = {
  walletTransfer: any;
  expanded: boolean;
  setExpanded: (transferId: string) => void;
  isWidget?: boolean;
  allCoinDecimals: AllCoinDecimals;
};

const prepareLink = (tx_proof: string, symbol: string, origin: string) => {
  if (origin !== 'send' && origin !== 'receive') {
    return '';
  }

  switch (symbol) {
    case 'BTC':
      return `${REACT_APP_BTC_HASH_LINK}/tx/${tx_proof}`;
    case 'ETH':
      return `${REACT_APP_ETH_HASH_LINK}/tx/${tx_proof}`;
    case 'LTC':
      return `${REACT_APP_LTC_HASH_LINK}/tx/LTCTEST/${tx_proof}`;
    case 'BCH':
      return `${REACT_APP_BCH_HASH_LINK}/tbch/tx/${tx_proof}`;
    default:
      return '';
  }
};

const WalletTransfersTableRow: FC<Props> = ({
  walletTransfer,
  expanded,
  setExpanded,
  isWidget = false,
  allCoinDecimals,
}: Props) => {
  const classes = useStyles();
  const { origin, symbol, tx_proof, transaction_status, tx_status, created_at, amount, id } =
    walletTransfer;

  const preparedLink = prepareLink(tx_proof, symbol, origin);

  const prepareValue = (coinSymbol: string, value: number) => {
    const getFormatedCrypto = (decimals: number) => {
      return balanceUtils.formatBalanceToString(
        value,
        decimals,
        getCurrencyFraction(coinSymbol, 'min'),
        getCurrencyFraction(coinSymbol, 'max'),
      );
    };
    switch (coinSymbol) {
      case 'BTC':
        return getFormatedCrypto(allCoinDecimals['BTC']);
      case 'ETH':
        return getFormatedCrypto(allCoinDecimals['ETH']);
      case 'LTC':
        return getFormatedCrypto(allCoinDecimals['LTC']);
      case 'BCH':
        return getFormatedCrypto(allCoinDecimals['BCH']);
      case 'EUR':
        return getFormatedCrypto(allCoinDecimals['WALLET_EUR']);
      default:
        return value;
    }
  };
  const getIcon =
    amount > 0 ? (
      <ArrowDownwardIcon className={`${classes.arrowIcon} ${classes.colorGreen}`} />
    ) : (
      <ArrowUpwardIcon className={`${classes.arrowIcon} ${classes.colorRed}`} />
    );

  return isWidget ? (
    <TableRow>
      <TableCell className={classes.iconCell} align="center">
        {getIcon}
      </TableCell>
      <TableCell className={classes.widgetCell} align="right">
        {prepareValue(symbol, Number(amount))}
      </TableCell>
      <TableCell className={classes.widgetCell} align="right">
        <CoinIconCell symbol={symbol} containerJustfy="flex-end" imgGridSize={3} />
      </TableCell>
      <TableCell className={classes.widgetCell} align="right">
        {moment(created_at).format('DD/MM/YYYY HH:mm')}
      </TableCell>
    </TableRow>
  ) : (
    <>
      <TableRow>
        <TableCell className={classes.iconCell} align="center">
          {getIcon}
        </TableCell>
        <TableCell
          className={
            transaction_status === 'completed' ? classes.statusSuccess : classes.statusPending
          }
        >
          {renderType(tx_status)}
        </TableCell>
        <TableCell style={{ width: 200 }}>
          <div className={classes.flex}>
            {renderType(origin)}

            {preparedLink && (
              <Button
                className={classes.expandButton}
                style={expanded ? { fontWeight: 'bold', color: `${Colors.blueCharcoal}` } : {}}
                onClick={() => setExpanded(id)}
              >
                {expanded ? 'Hide' : 'View'} Details
              </Button>
            )}
          </div>
        </TableCell>
        <TableCell align="right">{moment(created_at).format('DD/MM/YYYY HH:mm')}</TableCell>
        <TableCell>
          <CoinIconCell symbol={symbol} imgGridSize={3} containerGridSpacing={3} />
        </TableCell>
        <TableCell align="right">
          <Amount amount={prepareValue(symbol, Number(amount))} condition={amount > 0} />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          className={classes.expandableCell}
          colSpan={6}
        >
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Grid container spacing={3} className={classes.collapse}>
              <Grid item xs={8} zeroMinWidth>
                <FormLabel className={classes.labels}>Transfer ID</FormLabel>
                <Typography noWrap component="p" className={classes.transactionDetailsValues}>
                  {tx_proof}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <FormLabel className={classes.labels}>Status</FormLabel>
                <Typography component="p">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={preparedLink}
                    className={`${classes.transactionStatusValue} ${
                      tx_proof === 'DISPUTED' ? classes.disableLink : ' '
                    }`}
                  >
                    confirmed <img src={ExternalLink} alt="externalLink" />
                  </a>
                </Typography>
              </Grid>
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default WalletTransfersTableRow;
