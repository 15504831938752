import { Grid } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { PayloadAC, EmptyAC } from 'typesafe-actions';
import AffiliateInfo from './AffiliateInfo';
import AffiliateTable from './AffiliateTable';
import TransactionsFilterBar from './TransactionsFilterBar';

const styles = () =>
  createStyles({
    root: {
      margin: 24,
      marginTop: 30,
    },
  });

const useStyles = makeStyles(styles);

type Props = {
  setCurrentHomePage: PayloadAC<'home/set_current_home_page', string>;
  getWalletBalance: EmptyAC<'wallet/get_wallet_balance'>;
  referralTree: any;
  clearAllFilters: any;
};

const Affiliate = ({
  setCurrentHomePage,
  getWalletBalance,
  referralTree,
  clearAllFilters,
}: Props): JSX.Element => {
  const classes = useStyles();
  useEffect(() => {
    clearAllFilters();
    getWalletBalance();
    setCurrentHomePage('affiliate');
  }, []);

  return (
    <Grid item className={classes.root}>
      {isEmpty(referralTree) && <AffiliateInfo />}
      {isEmpty(referralTree) && <TransactionsFilterBar />}
      <AffiliateTable />
    </Grid>
  );
};

export default Affiliate;
