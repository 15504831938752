import { all } from 'redux-saga/effects';
import productsPurchaseHistorySagas from '../ProductsPurchaseHistory/state/saga';
import userEarningsTable from '../UserEarningsTable/state/saga';
import transactionGrouping from '../TransactionGrouping/state/saga';
import userTransactionHistory from '../UserTransactionHistory/state/saga';
import changeUserPassword from '../ChangeUserPassword/state/saga';

export default function* () {
  yield all([
    userEarningsTable(),
    productsPurchaseHistorySagas(),
    transactionGrouping(),
    userTransactionHistory(),
    changeUserPassword(),
  ]);
}
