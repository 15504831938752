import {
  Button,
  CircularProgress,
  DialogContent,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace';
import React, { useState } from 'react';
import { PayloadAC } from 'typesafe-actions';
import CustomButtonPrimary from '../../../../../../components/CustomButtons/CustomButtonPrimary';
import { ConfirmTwoFactorAuthRequest } from '../../../../../../data/repositories/account/typedefs';
import { Colors } from '../../../../../../ui/style/colors';

const styles = (theme: Theme) =>
  createStyles({
    dialog: {
      height: 'center',
      width: 'center',
      borderRadius: 6,
      backgroundColor: 'transparent',
      boxShadow: '0 10px 30px 0 rgba(132,141,182,0.25)',
      paddingLeft: 30,
      paddingRight: 30,
    },
    label: {
      color: `${Colors.blueCharcoal}`,
      fontSize: 18,
      fontWeight: 600,
      letterSpacing: 1.13,
    },
    infoLabel: {
      color: theme.palette.primary.light,
      fontSize: 12,
      fontWeight: 600,
    },
    button: {
      borderRadius: 4,
      marginTop: 30,
      marginBottom: 30,
      textTransform: 'none',
    },
    backButton: {
      background: 'none',
      border: 'none',
      marginTop: 30,
      marginBottom: 30,
      fontSize: 14,
      textTransform: 'none',
    },
    pasteClipbord: {
      boxSizing: 'border-box',
      height: 38,
      border: `1px solid ${theme.palette.secondary.light}`,
      borderRadius: 6,
      backgroundColor: `${Colors.white}`,
      boxShadow: '0 2px 10px 0 rgba(132,141,182,0.25)',
      display: 'flex',
    },
    pasteClipboardText: {
      color: theme.palette.primary.light,
      fontSize: 14,
      paddingLeft: 12,
      textDecoration: 'none',
      display: 'flex',
      justifyContent: 'center',
    },
    pasteClipbordButton: {
      borderRadius: '0 6px 6px 0',
      backgroundColor: `${Colors.havelockBlue}`,
      boxShadow: '0 2px 10px 0 rgba(132,141,182,0.25)',
      color: `${Colors.white}`,
      height: 38,
      float: 'right',
      textTransform: 'none',
      fontSize: 12,
    },
    closeButton: {
      position: 'absolute',
      right: 5,
      top: 5,
      color: theme.palette.primary.light,
    },
    error: {
      color: `${Colors.red}`,
      fontSize: 14,
      marginTop: 10,
    },
    circularProgress: {
      marginTop: 30,
      marginBottom: 30,
      display: 'flex',
      justifyContent: 'center',
    },
  });
const useStyles = makeStyles(styles);

type Props = {
  openTwoFactorAuth: PayloadAC<'settings/open_dialog', number>;
  confirmTwoFactorAuth: PayloadAC<'settings/confirm_two_factor_auth', ConfirmTwoFactorAuthRequest>;
  step: number;
  confirmInProgress: boolean;
  confirmFail: boolean;
  confirmError: Error | null;
};

const SettingsTwoFactorAuthConfirmDialog: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { openTwoFactorAuth, confirmTwoFactorAuth, step, confirmInProgress, confirmError } = props;
  const [pasteValue, setPasteValue] = useState('');
  const [error, setError] = React.useState<string>('');

  const handleBack = () => {
    openTwoFactorAuth(step - 1);
  };

  const pasteClipboard = async () => {
    try {
      const clipboardText = await navigator.clipboard.readText();
      setPasteValue(clipboardText);
    } catch (err) {
      console.error('Failed to read clipboard contents: ', err);
    }
  };

  const handleConfirmTwoFactorAuth = () => {
    const code = document.getElementById('secret-code') as HTMLInputElement;
    if (code.value) {
      confirmTwoFactorAuth({ code: code.value });
    } else {
      setError('Please enter the confirmation code');
    }
  };

  return (
    <DialogContent className={classes.dialog}>
      <Typography className={classes.infoLabel} component="p" style={{ marginBottom: 30 }}>
        Please enter the confirmation code from your authentication app
      </Typography>
      <Typography className={classes.infoLabel} component="p" style={{ marginBottom: 8 }}>
        Confirmation code
      </Typography>
      <Paper className={classes.pasteClipbord}>
        <TextField
          id="secret-code"
          placeholder="Paste code from your auth app"
          className={classes.pasteClipboardText}
          InputProps={{ disableUnderline: true }}
          fullWidth
          onChange={e => {
            setPasteValue(e.target.value);
            setError('');
          }}
          value={pasteValue}
          autoComplete="off"
        />
        <Button
          variant="contained"
          size="small"
          className={classes.pasteClipbordButton}
          onClick={pasteClipboard}
        >
          Paste
        </Button>
      </Paper>
      {confirmError != null ? (
        <div className={classes.error}>{confirmError}</div>
      ) : (
        <div className={classes.error}>{error != null ? error : ''}</div>
      )}
      {confirmInProgress ? (
        <Grid container className={classes.circularProgress} alignContent="center">
          <CircularProgress color="secondary" size={40} />
        </Grid>
      ) : (
        <Grid container alignContent="center">
          <Grid item xs={3}>
            <Button size="large" className={classes.backButton} fullWidth onClick={handleBack}>
              <KeyboardBackspace />
              Back
            </Button>
          </Grid>
          <Grid item xs={9}>
            <CustomButtonPrimary
              variant="contained"
              size="large"
              className={classes.button}
              fullWidth
              onClick={handleConfirmTwoFactorAuth}
            >
              Done
            </CustomButtonPrimary>
          </Grid>
        </Grid>
      )}
    </DialogContent>
  );
};

export default SettingsTwoFactorAuthConfirmDialog;
