import React from 'react';
import { PayloadAC } from 'typesafe-actions';
import ModalState from '../../../../../../components/ModalState';

type Props = {
  openWithdrawDialog: PayloadAC<'wallet/open_withdraw_dialog', number>;
  isWithdrawDialogOpen: PayloadAC<'wallet/is_withdraw_dialog_open', boolean>;
  withdrawError: Error | null;
  withdrawInProgress: boolean;
};
const WalletStatusMessage = ({
  openWithdrawDialog,
  withdrawInProgress,
  withdrawError,
  isWithdrawDialogOpen,
}: Props) => {
  return (
    <ModalState
      onExit={() => {
        isWithdrawDialogOpen(false);
        openWithdrawDialog(0);
      }}
      inProgress={withdrawInProgress}
      message={withdrawError ? withdrawError : 'Transaction completed!'}
      variant={!withdrawError ? 'success' : 'failed'}
      showCloseButton={false}
    />
  );
};

export default WalletStatusMessage;
