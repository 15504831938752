import { connect } from 'react-redux';
import { AppState } from '../../../../../state/initialState';
import {
  getWalletForCurrency,
  asyncGetEntiretWalletBalances,
  isInvestDialogOpen,
} from '../../state/actions';
import { entireWalletBalanceSelector } from '../../state/selectors';
import WalletInfoTotalBalance from './WalletInfoTotalBalance';
const mapStateToProps = (state: AppState) => {
  const entireWalletBalance = entireWalletBalanceSelector(state);
  return { entireWalletBalance };
};

const mapDispatchToProps = {
  getWalletForCurrency: getWalletForCurrency,
  asyncGetEntiretWalletBalances: asyncGetEntiretWalletBalances.request,
  isInvestDialogOpen: isInvestDialogOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(WalletInfoTotalBalance);
