import { createReducer } from 'typesafe-actions';
import {
  SET_CHANGE_USER_PASSWORD_SELETCED_USER,
  CHANGE_USER_PASSWORD,
  CHANGE_USER_PASSWORD_CLEAR_STATE,
  CHANGE_USER_PASSWORD_FAIL,
  CHANGE_USER_PASSWORD_SUCCESS,
} from './actions';
import { initialState } from './initialState';

export default createReducer(initialState, {
  [SET_CHANGE_USER_PASSWORD_SELETCED_USER]: (state, { payload }) => ({
    ...state,
    selectedUser: payload,
  }),
  [CHANGE_USER_PASSWORD]: state => ({
    ...state,
    error: null,
    inProgress: true,
  }),
  [CHANGE_USER_PASSWORD_SUCCESS]: state => ({
    ...state,
    inProgress: false,
    fail: false,
    success: true,
  }),
  [CHANGE_USER_PASSWORD_CLEAR_STATE]: state => ({
    ...state,
    inProgress: false,
    fail: false,
    success: false,
    error: null,
  }),
  [CHANGE_USER_PASSWORD_FAIL]: (state, { payload }) => ({
    ...state,
    inProgress: false,
    fail: true,
    success: false,
    error: payload.message,
  }),
});
