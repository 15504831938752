import { createStandardAction } from 'typesafe-actions';

export const GET_PROFILE_INFO = 'profile_info';
export const GET_PROFILE_INFO_SUCCESS = 'profile_info/success';
export const GET_PROFILE_INFO_FAIL = 'profile_info/fail';

export const UPDATE_PAYMENT_INFO = 'update_payment_info';
export const UPDATE_PAYMENT_INFO_SUCCESS = 'update_payment_info/success';
export const UPDATE_PAYMENT_INFO_FAIL = 'update_payment_info/fail';

export const RESET_STATE = 'profile_info/reset_state';

export const getProfileInfo = createStandardAction(GET_PROFILE_INFO)<any>();
export const getProfileInfoSuccess = createStandardAction(GET_PROFILE_INFO_SUCCESS)<any>();
export const getProfileInfoFail = createStandardAction(GET_PROFILE_INFO_FAIL)<Error>();

export const updatePaymentInfo = createStandardAction(UPDATE_PAYMENT_INFO)<any>();
export const updatePaymentInfoSuccess = createStandardAction(UPDATE_PAYMENT_INFO_SUCCESS)<any>();
export const updatePaymentInfoFail = createStandardAction(UPDATE_PAYMENT_INFO_FAIL)<any>();

export const resetProfileState = createStandardAction(RESET_STATE)<undefined>();
