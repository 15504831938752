import DateFnsUtils from '@date-io/date-fns';
import { Card, CardContent, CircularProgress, Grid, InputLabel } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ErrorMessage, FastField, Field, Form, Formik } from 'formik';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { PayloadAC } from 'typesafe-actions';
import * as yup from 'yup';
import CustomButtonPrimary from '../../../../../components/CustomButtons/CustomButtonPrimary';
import CustomButtonSecondary from '../../../../../components/CustomButtons/CustomButtonSecondary';
import SacretError from '../../../../../components/SacretError';
import TextInput from '../../../../../components/TextInput';
import { GetProductRequest, ProductDetail } from '../../../../../data/repositories/product/typedef';
import { User } from '../../../../../data/typedefs/user';
import { Colors } from '../../../../../ui/style/colors';
import { roles } from '../../../../../utils/constants/roles';
import { APP_DASHBOARD_ROUTE } from '../../../../../utils/constants/routes';
import useExchangeCurrencyPrices from '../../Exchange/ExchangeCurrency/useExchangeCurrencyPrices';
import { ProductFormState } from './state/initialState';

const styles = (theme: Theme) =>
  createStyles({
    card: {
      boxShadow: '0 10px 30px 0 rgba(132,141,182,0.25)',
      verticalAlign: 'sub',
      padding: 20,
    },
    inputFields: {
      marginTop: 5,
      height: 44,
      boxSizing: 'border-box',
      border: `1px solid ${theme.palette.secondary.light}`,
      borderRadius: 4,
      backgroundColor: `${Colors.white}`,
      paddingLeft: 12,
    },
    labels: {
      fontWeight: 'bold',
      color: theme.palette.primary.light,
      marginTop: 20,
      marginBottom: 8,
    },
    values: {
      color: theme.palette.primary.main,
      marginTop: 10,
      marginBottom: 8,
    },
    changePasswordLabel: {
      color: theme.palette.secondary.light,
      fontSize: 14,
      fontWeight: 800,
      letterSpacing: 1,
      textTransform: 'uppercase',
    },
    button: {
      marginTop: 40,
      marginLeft: 20,
      marginRight: 20,
      textTransform: 'none',
      fontSize: 16,
      fontWeight: 600,
      letterSpacing: 1,
      textAlign: 'center',
    },
    value: {
      color: `${Colors.blueCharcoal}`,
      width: '100%',
      textAlign: 'center',
      fontSize: 14,
    },
    inputError: {
      color: `${Colors.red}`,
      marginTop: 10,
    },
    input: {
      width: '100%',
      '&::placeholder': {
        color: `${Colors.blueHaze}`,
      },
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'none',
      },
      '&[type=number]': {
        '-webkit-appearance': 'textfield',
        '-moz-appearance': 'textfield',
      },
    },
    circularProgress: {
      position: 'absolute',
      left: '50%',
      top: '50%',
    },
  });

const useStyles = makeStyles(styles);

type Props = {
  asyncProductCreate: PayloadAC<'product_form/create', ProductDetail>;
  asyncProductUpdate: PayloadAC<'product_form/update', ProductDetail>;
  asyncProductPublish: PayloadAC<'product_form/publish', string>;
  asyncGetProduct: PayloadAC<'product_form/get_product', GetProductRequest>;
  productForm: ProductFormState;
  setCurrentHomePage: PayloadAC<'home/set_current_home_page', string>;
  user: User;
};

const ProductForm = ({
  asyncProductPublish,
  asyncProductUpdate,
  asyncProductCreate,
  asyncGetProduct,
  productForm,
  user,
  setCurrentHomePage,
}: Props): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();

  const { getSelectedCoinMarketVal } = useExchangeCurrencyPrices();
  const slcPrice = getSelectedCoinMarketVal('SLC');
  const { inProgress, error } = productForm;
  const { id } = useParams<{ id: string }>();
  useEffect(() => {
    setCurrentHomePage('product');
    id && asyncGetProduct({ id });
  }, []);
  useEffect(() => {
    if (user.roles && !roles.isOwner(user.roles)) {
      history.push(APP_DASHBOARD_ROUTE);
    }
  }, [user.roles]);
  if (!user.roles) {
    return <CircularProgress />;
  }
  const handleData = (data: any) => {
    // Moved soft_cap and hard_cap to cents array (see SL-626) comments
    // const bigData = ['soft_cap', 'hard_cap'];
    const dates = [
      'pre_ico_period_start',
      'pre_ico_period_end',
      'ico_period_start',
      'ico_period_end',
    ];
    const cents = [
      'soft_cap',
      'hard_cap',
      'pre_ico_price',
      'ico_price',
      'buy_limit_low',
      'buy_limit_high',
    ];

    Object.keys(data).forEach(key => {
      if (dates.includes(key)) data[key] = moment(data[key]).toJSON();
      else if (cents.includes(key)) data[key] = Math.round(data[key] * 100);
    });
    delete data.inProgress;
    delete data.error;
    delete data.fail;
    return data;
  };

  const submit = (data: any) => {
    const formData = {
      ...handleData(data),
    };
    if (formData.id) asyncProductUpdate(formData);
    else asyncProductCreate(formData);
  };

  if (inProgress) {
    return <CircularProgress color="secondary" className={classes.circularProgress} size={60} />;
  }

  if (error) {
    return <SacretError errorMessage={error} />;
  }

  return (
    <Card className={classes.card}>
      <CardContent>
        <Formik
          enableReinitialize
          initialValues={productForm}
          onSubmit={(values, actions) => {
            actions.setSubmitting(false);
            submit(values);
          }}
          validationSchema={yup.object({
            name: yup
              .string()
              .matches(/^(?!\s+$).*/, 'Name cannot be blank space')
              .required('Name is required'),
            description: yup
              .string()
              .required('Description is required')
              .max(2000, 'Maximum number of characters is 2000'),
            soft_cap: yup
              .number()
              .required('Minimum goal is required')
              .min(1, "Can't be less than 1"),
            hard_cap: yup
              .number()
              .required('Maximum goal is required')
              .moreThan(
                yup.ref('soft_cap'),
                ({ more }) => `Must be more than Minimum goal (${more || 0})`,
              ),
            pre_ico_price: yup
              .number()
              .required('Pre sale price is required')
              .min(0.01, "Can't be less than 0.01")
              .max(slcPrice, `Maximum product price is ${slcPrice}`),
            ico_price: yup
              .number()
              .required('Sale price is required')
              .min(0, "Can't be less than 0")
              .max(slcPrice, `Maximum product price is ${slcPrice}`),
            buy_limit_low: yup
              .number()
              .required('Minimum buy is required')
              .min(0.01, "Can't be less than 0.01")
              .lessThan(
                yup.ref('soft_cap'),
                ({ less }) => `Must be less than Minimum goal (${less || 0})`,
              ),
            buy_limit_high: yup
              .number()
              .required('Maximum buy is required')
              .moreThan(
                yup.ref('buy_limit_low'),
                ({ more }) => `Must be more than Minimum buy (${more || 0})`,
              )
              .lessThan(
                yup.ref('soft_cap'),
                ({ less }) => `Must be less than Minimum goal (${less || 0})`,
              ),
            pre_ico_period_start: yup.date().required('Pre sale start period is required'),
            pre_ico_period_end: yup
              .date()
              .required('Pre sale end period is required')
              .min(
                yup.ref('pre_ico_period_start'),
                'Pre sale period (end) must be later than (start)',
              )
              .max(
                yup.ref('ico_period_start'),
                'Pre sale period (start) must be earlier than sale period (start)',
              ),
            ico_period_start: yup
              .date()
              .required()
              .min(
                yup.ref('pre_ico_period_end'),
                'Sale period (start) must be later than pre sale period (end)',
              ),
            ico_period_end: yup
              .date()
              .required()
              .min(yup.ref('ico_period_start'), 'Sale period (end) must be later than (start)'),
            icon: yup.string().required('Icon URL is required').url('Invalid url'),
            website: yup.string().required('Website URL is required').url('Invalid url'),
            white_paper: yup.string().required('Documentation URL is required').url('Invalid url'),
            photo: yup.string().required('Cover URL is required').url('Invalid url'),
          })}
        >
          {({ isSubmitting, values, setFieldTouched, setFieldValue }) => (
            <Form>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid spacing={3} container>
                  <Grid item xs={6}>
                    <InputLabel className={classes.labels}>Name</InputLabel>
                    <FastField
                      name="name"
                      disabled={productForm.status === 'published'}
                      component={TextInput}
                      className={classes.value}
                      inputprops={{
                        classes: {
                          input: classes.input,
                        },
                        style: {
                          height: 44,
                          paddingRight: 0,
                        },
                      }}
                    />
                    <ErrorMessage className={classes.inputError} name="name" component="div" />

                    <InputLabel className={classes.labels}>Description</InputLabel>
                    <FastField
                      name="description"
                      multiline
                      minRows={1}
                      maxRows={4}
                      disabled={productForm.status === 'published'}
                      component={TextInput}
                      className={classes.value}
                      inputprops={{
                        classes: {
                          input: classes.input,
                        },
                        style: {
                          height: 44,
                          paddingRight: 0,
                        },
                      }}
                    />
                    <ErrorMessage
                      className={classes.inputError}
                      name="description"
                      component="div"
                    />
                    <InputLabel className={classes.labels}>Minimum goal (EUR)</InputLabel>

                    <FastField
                      name="soft_cap"
                      component={TextInput}
                      disabled={productForm.status === 'published'}
                      type={'number'}
                      min={0}
                      className={classes.value}
                      inputprops={{
                        classes: {
                          input: classes.input,
                        },
                        style: {
                          height: 44,
                          paddingRight: 0,
                        },
                      }}
                    />
                    <ErrorMessage className={classes.inputError} name="soft_cap" component="div" />

                    <InputLabel className={classes.labels}>Maximum goal (EUR)</InputLabel>
                    <FastField
                      name="hard_cap"
                      component={TextInput}
                      disabled={productForm.status === 'published'}
                      type={'number'}
                      min={values.soft_cap || 0}
                      className={classes.value}
                      inputprops={{
                        classes: {
                          input: classes.input,
                        },
                        style: {
                          height: 44,
                          paddingRight: 0,
                        },
                      }}
                    />
                    <ErrorMessage className={classes.inputError} name="hard_cap" component="div" />

                    <InputLabel className={classes.labels}>Product price of 1SLC (EUR)</InputLabel>
                    <FastField
                      name="ico_price"
                      type={'number'}
                      min={0}
                      component={TextInput}
                      className={classes.value}
                      inputprops={{
                        classes: {
                          input: classes.input,
                        },
                        style: {
                          height: 44,
                          paddingRight: 0,
                        },
                      }}
                    />
                    <ErrorMessage className={classes.inputError} name="ico_price" component="div" />

                    <InputLabel className={classes.labels}>Minimum buy per user (EUR)</InputLabel>
                    <FastField
                      name="buy_limit_low"
                      component={TextInput}
                      disabled={productForm.status === 'published'}
                      type={'number'}
                      min={0}
                      className={classes.value}
                      inputprops={{
                        classes: {
                          input: classes.input,
                        },
                        style: {
                          height: 44,
                          paddingRight: 0,
                        },
                      }}
                    />
                    <ErrorMessage
                      className={classes.inputError}
                      name="buy_limit_low"
                      component="div"
                    />

                    <InputLabel className={classes.labels}>Maximum buy per user (EUR)</InputLabel>
                    <FastField
                      name="buy_limit_high"
                      disabled={productForm.status === 'published'}
                      component={TextInput}
                      type={'number'}
                      min={values.buy_limit_low || 0}
                      className={classes.value}
                      inputprops={{
                        classes: {
                          input: classes.input,
                        },
                        style: {
                          height: 44,
                          paddingRight: 0,
                        },
                      }}
                    />
                    <ErrorMessage
                      className={classes.inputError}
                      name="buy_limit_high"
                      component="div"
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <InputLabel className={classes.labels}>Website</InputLabel>
                    <FastField
                      name="website"
                      disabled={productForm.status === 'published'}
                      component={TextInput}
                      className={classes.value}
                      inputprops={{
                        classes: {
                          input: classes.input,
                        },
                        style: {
                          height: 44,
                          paddingRight: 0,
                        },
                      }}
                    />
                    <ErrorMessage className={classes.inputError} name="website" component="div" />

                    <InputLabel className={classes.labels}>Icon URL</InputLabel>
                    <FastField
                      name="icon"
                      disabled={productForm.status === 'published'}
                      component={TextInput}
                      className={classes.value}
                      inputprops={{
                        classes: {
                          input: classes.input,
                        },
                        style: {
                          height: 44,
                          paddingRight: 0,
                        },
                      }}
                    />
                    <ErrorMessage className={classes.inputError} name="icon" component="div" />

                    <InputLabel className={classes.labels}>Cover URL</InputLabel>
                    <FastField
                      name="photo"
                      disabled={productForm.status === 'published'}
                      component={TextInput}
                      className={classes.value}
                      inputprops={{
                        classes: {
                          input: classes.input,
                        },
                        style: {
                          height: 44,
                          paddingRight: 0,
                        },
                      }}
                    />
                    <ErrorMessage className={classes.inputError} name="photo" component="div" />

                    <InputLabel className={classes.labels}>Documentation URL</InputLabel>
                    <FastField
                      disabled={productForm.status === 'published'}
                      name="white_paper"
                      component={TextInput}
                      className={classes.value}
                      inputprops={{
                        classes: {
                          input: classes.input,
                        },
                        style: {
                          height: 44,
                          paddingRight: 0,
                        },
                      }}
                    />
                    <ErrorMessage
                      className={classes.inputError}
                      name="white_paper"
                      component="div"
                    />

                    <InputLabel className={classes.labels}>Pre sale period</InputLabel>
                    <Grid spacing={3} container>
                      <Grid item xs={6}>
                        <Field
                          component={DateTimePicker}
                          name="pre_ico_period_start"
                          value={values.pre_ico_period_start}
                          style={{ width: '100%' }}
                          onChange={(date: any) => {
                            setFieldTouched('pre_ico_period_start');
                            setFieldValue('pre_ico_period_start', date, true);
                          }}
                        />
                        <ErrorMessage
                          className={classes.inputError}
                          name="pre_ico_period_start"
                          component="div"
                        />
                      </Grid>
                      <Grid xs={6} item>
                        <Field
                          component={DateTimePicker}
                          name="pre_ico_period_end"
                          value={values.pre_ico_period_end}
                          keyboardbuttonprops={{
                            'aria-label': 'change date',
                          }}
                          style={{ width: '100%' }}
                          onChange={(date: any) => {
                            setFieldTouched('pre_ico_period_end');
                            setFieldValue('pre_ico_period_end', date, true);
                          }}
                        />
                        <ErrorMessage
                          className={classes.inputError}
                          name="pre_ico_period_end"
                          component="div"
                        />
                      </Grid>
                    </Grid>

                    <InputLabel className={classes.labels}>
                      Pre sale product price of 1SLC (EUR)
                    </InputLabel>
                    <FastField
                      name="pre_ico_price"
                      component={TextInput}
                      className={classes.value}
                      type={'number'}
                      min={0}
                      inputprops={{
                        classes: {
                          input: classes.input,
                        },
                        style: {
                          height: 44,
                          paddingRight: 0,
                        },
                      }}
                    />
                    <ErrorMessage
                      className={classes.inputError}
                      name="pre_ico_price"
                      component="div"
                    />

                    <InputLabel className={classes.labels}>Sale period</InputLabel>
                    <Grid spacing={3} container>
                      <Grid item xs={6}>
                        <Field
                          component={DateTimePicker}
                          name="ico_period_start"
                          value={values.ico_period_start}
                          style={{ width: '100%' }}
                          onChange={(date: any) => {
                            setFieldTouched('ico_period_start');
                            setFieldValue('ico_period_start', date, true);
                          }}
                        />
                        <ErrorMessage
                          className={classes.inputError}
                          name="ico_period_start"
                          component="div"
                        />
                      </Grid>
                      <Grid xs={6} item>
                        <Field
                          component={DateTimePicker}
                          name="ico_period_end"
                          value={values.ico_period_end}
                          keyboardbuttonprops={{
                            'aria-label': 'change date',
                          }}
                          style={{ width: '100%' }}
                          onChange={(date: any) => {
                            setFieldTouched('ico_period_end');
                            setFieldValue('ico_period_end', date, true);
                          }}
                        />
                        <ErrorMessage
                          className={classes.inputError}
                          name="ico_period_end"
                          component="div"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container alignItems="center" justifyContent="center">
                  {productForm.inProgress ? (
                    <CircularProgress />
                  ) : (
                    <>
                      <CustomButtonSecondary
                        variant="outlined"
                        className={classes.button}
                        size="large"
                        onClick={() => history.push('/products')}
                      >
                        Back
                      </CustomButtonSecondary>
                      <CustomButtonPrimary
                        type="submit"
                        variant="contained"
                        size="large"
                        className={classes.button}
                        disabled={isSubmitting}
                      >
                        {'Save'}
                      </CustomButtonPrimary>
                      {productForm.id && productForm.status === 'created' ? (
                        <CustomButtonPrimary
                          variant="contained"
                          className={classes.button}
                          size="large"
                          onClick={() => asyncProductPublish(productForm.id || '')}
                        >
                          Publish
                        </CustomButtonPrimary>
                      ) : null}
                    </>
                  )}
                </Grid>
              </MuiPickersUtilsProvider>
            </Form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
};

export default ProductForm;
