import { connect } from 'react-redux';
import { AppState } from '../../../../../state/initialState';
import {
  asyncGetProductsPurchaseHistory,
  productsPurchaseHistoryClearAllFilters,
  productsPurchaseHistoryToggleFilter,
} from '../state/actions';
import { getAppliedFiltersSelector } from '../state/selectors';
import ProductsPurchaseHistoryFilterBar from './ProductsPurchaseHistoryFilterBar';

const mapStateToProps = (state: AppState) => {
  const appliedFilters = getAppliedFiltersSelector(state);
  return { appliedFilters };
};

const dispatchStateToProps = {
  asyncGetProductsPurchaseHistory: asyncGetProductsPurchaseHistory.request,
  productsPurchaseHistoryToggleFilter,
  productsPurchaseHistoryClearAllFilters,
};

export default connect(mapStateToProps, dispatchStateToProps)(ProductsPurchaseHistoryFilterBar);
