import {
  ProductTableState,
  initialState as productTable,
} from '../ProductsTable/state/initialState';

import {
  ProductCurrencyState,
  initialState as productInvest,
} from '../ProductInvest/state/initialState';

import { initialState as productForm, ProductFormState } from '../ProductForm/state/initialState';

export type ProductState = {
  productTable: ProductTableState;
  productInvest: ProductCurrencyState;
  productForm: ProductFormState;
};

export const initialState = {
  productTable,
  productInvest,
  productForm,
};
