import { all, put, takeLatest, select } from 'redux-saga/effects';
import LocalStorage from '../../../../../../data/LocalStorage';
import { USER_ID } from '../../../../../../utils/constants/localStorageKeys';
import api from '../../../../../../data/repositories/product';
import {
  EXPORT_PRODUCTS_ENDPOINT,
  GET_PRODUCTS,
  INVESTED_PRODUCTS,
} from '../../../../../../utils/constants/api';
import { Product } from '../../../../../../data/repositories/product/typedef';
import {
  asyncExportProducts,
  asyncGetProductsPaginated,
  EXPORT_PRODUCTS,
  GET_PRODUCTS_PAGINATED,
  setTablePage,
} from './actions';
import { userStateSelector } from '../../../../../state/selectors';
import { roles } from '../../../../../../utils/constants/roles';
import { saveAs } from 'file-saver';

export function* getProductsPaginated$(
  action: ReturnType<typeof asyncGetProductsPaginated.request>,
) {
  try {
    const userId = LocalStorage.get(USER_ID);
    const user = yield select(userStateSelector);
    const filter = {
      status: !roles.isOwner(user.roles)
        ? 'published'
        : action.payload.appliedFilters && action.payload.appliedFilters.status
        ? action.payload.appliedFilters.status
        : null,
      name:
        action.payload.appliedFilters && action.payload.appliedFilters.name
          ? action.payload.appliedFilters.name
          : null,
    };

    const mapURL = (params: any) => {
      let newParams = {};
      Object.keys(params).forEach(key => {
        if (params[key] !== null) newParams = { ...newParams, [key]: params[key].toString() };
      });
      return new URLSearchParams(newParams).toString();
    };
    if (action.payload.appliedTab && action.payload.appliedTab.product) {
      const { investment_count, InvestmentInfoData } = yield api.investedProducts(
        INVESTED_PRODUCTS(
          userId ? userId : '',
          action.payload.page,
          action.payload.perPage,
          mapURL(filter) && '&' + mapURL(filter),
        ),
      );
      const result: any = {};
      InvestmentInfoData.map((product: any, index: any) => {
        result[`${product.investment.product.product_name} - ${index}`] = product;
      });
      yield put(
        asyncGetProductsPaginated.success({
          products: result,
          totalProducts: investment_count,
        }),
      );
    } else {
      const {
        crowdfunding_products,
        product_count,
      }: { crowdfunding_products: Product[]; product_count: number } = yield api.allProducts(
        GET_PRODUCTS(
          userId ? userId : '',
          action.payload.page,
          action.payload.perPage,
          mapURL(filter) && '&' + mapURL(filter),
        ),
      );

      const result: { [id: string]: Product } = {};
      crowdfunding_products.map(product => {
        result[product.product.id] = product;
      });

      yield put(setTablePage(action.payload.page));
      yield put(
        asyncGetProductsPaginated.success({
          products: result,
          totalProducts: product_count,
          backersMax: crowdfunding_products.length
            ? crowdfunding_products.sort((a, b) => b.backer_count - a.backer_count)[0].backer_count
            : 0,
          timeLeftMax: crowdfunding_products.length
            ? crowdfunding_products.sort((a, b) => b.time_left - a.time_left)[0].time_left
            : 0,
        }),
      );
    }
  } catch (error) {
    yield put(asyncGetProductsPaginated.failure(error));
  }
}

export function* exportProducts$() {
  try {
    const accountId = LocalStorage.get(USER_ID);
    const response = yield api.exportProducts(EXPORT_PRODUCTS_ENDPOINT(accountId || ''));
    const csvData = new Blob([response], { type: 'text/csv;charset=utf-8;' });

    saveAs(csvData, 'Products.csv');

    yield put(asyncExportProducts.success());
  } catch (error) {
    yield put(asyncExportProducts.failure(error));
  }
}

export default function* () {
  yield all([
    takeLatest(GET_PRODUCTS_PAGINATED, getProductsPaginated$),
    takeLatest(EXPORT_PRODUCTS, exportProducts$),
  ]);
}
